import { render, staticRenderFns } from "./resource-search-pagination.vue?vue&type=template&id=eeb107a6&scoped=true"
import script from "./resource-search-pagination.vue?vue&type=script&lang=ts"
export * from "./resource-search-pagination.vue?vue&type=script&lang=ts"
import style0 from "./resource-search-pagination.vue?vue&type=style&index=0&id=eeb107a6&prod&lang=postcss&scoped=true"
import style1 from "./resource-search-pagination.vue?vue&type=style&index=1&id=eeb107a6&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eeb107a6",
  null
  
)

export default component.exports