<template>
    <div class="blog-filters">
        <h1 class="title">
            {{ title }}
        </h1>
        <el-form ref="form" class="controls" :model="form">
            <el-form-item prop="audienceFilter">
                <el-select
                    v-model="form.audienceFilter"
                    class="filter-select"
                    name="audienceFilter"
                    :label="audienceLabel"
                    :placeholder="allLabel"
                    popper-class="blog-dropdown filter-dropdown"
                >
                    <el-option
                        v-for="(item, i) in audiences"
                        :key="i"
                        :label="item.name"
                        :value="item.id"
                    />
                </el-select>
            </el-form-item>
            <el-form-item prop="assetFilter">
                <el-select
                    v-bind="labelSelect"
                    v-model="form.assetFilter"
                    class="filter-select"
                    :label="typeOfAssetLabel"
                    name="assetFilter"
                    :placeholder="allLabel"
                    filterable
                    :hide-on-resize="isDesktop"
                    popper-class="blog-dropdown filter-dropdown"
                >
                    <el-option
                        v-for="(item, i) in typesOfAssets"
                        :key="i"
                        :label="item.title"
                        :value="item.id"
                    />
                </el-select>
            </el-form-item>
            <el-form-item class="product-form-item" prop="productFilter">
                <el-select
                    v-bind="labelSelect"
                    v-model="form.productFilter"
                    class="filter-select products"
                    :label="productsLabel"
                    name="productFilter"
                    :placeholder="allLabel"
                    filterable
                    :hide-on-resize="isDesktop"
                    popper-class="blog-dropdown filter-dropdown"
                >
                    <el-option
                        v-for="(item, i) in products"
                        :key="i"
                        :label="`Acronis ${item.name}`"
                        :value="item.id"
                    />
                </el-select>
            </el-form-item>
            <el-form-item prop="categoryFilter">
                <el-select
                    v-bind="labelSelect"
                    v-model="form.categoryFilter"
                    class="filter-select"
                    name="categoryFilter"
                    :label="categoriesLabel"
                    :placeholder="allLabel"
                    filterable
                    :hide-on-resize="isDesktop"
                    popper-class="blog-dropdown filter-dropdown"
                >
                    <el-option-group
                        v-for="group in categories"
                        :key="group.label"
                        :label="group.label"
                    >
                        <el-option
                            v-for="item in group.children"
                            :key="item.label"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-option-group>
                </el-select>
            </el-form-item>
            <a-button
                class="filter-button"
                type="main"
                :text="showStoriesLabel"
                @click="applyFilters"
            />
        </el-form>
        <div v-if="filtersApplied" class="search-results">
            <div class="search-results-title-wrap">
                <div class="search-results-title">
                    {{ searchResultsTitle }}
                </div>
                <a-link
                    class="reset-filters"
                    :text="resetFiltersLabel"
                    :has-no-href="true"
                    glyph="close"
                    @click="resetFilters"
                />
            </div>
            <div class="sorting-wrap">
                <el-select
                    v-model="selectedSorting"
                    class="sorting"
                    popper-class="blog-dropdown sorting-dropdown"
                    @change="sortingChanged"
                >
                    <el-option
                        v-for="(item, i) in sortingOptions"
                        :key="i"
                        :label="item.name"
                        :value="item.value"
                    />
                </el-select>
            </div>
            <div class="search-results-wrap">
                <div v-if="hasResults || isResultsLoading" class="search-results-list-wrap">
                    <slot name="searchResults" />
                </div>
                <div v-else class="no-results">
                    <div class="no-search-results">
                        {{ noResultsTitle }}
                        <a-dangerous-html :content="clearFiltersCaption" />
                    </div>
                    <div class="recent-title">
                        {{ recentlyPublishedLabel }}
                    </div>
                    <slot name="latestCards" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';

export default {
    name: 'BlogFilters',
    components: {
        ElForm: () => import('@uikit/ui-kit/packages/form'),
        ElFormItem: () => import('@uikit/ui-kit/packages/form-item'),
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
        ElOptionGroup: () => import('@uikit/ui-kit/packages/option-group'),
        ADangerousHtml,
        ALink,
        AButton,
    },
    mixins: [form, breakpoint],
    props: {
        title: {
            type: String,
            required: true,
        },

        audiences: {
            type: Array,
            required: true,
        },

        typesOfAssets: {
            type: Array,
            required: true,
        },

        products: {
            type: Array,
            required: true,
        },

        categories: {
            type: Array,
            required: true,
        },

        audienceLabel: {
            type: String,
            required: true,
        },

        allLabel: {
            type: String,
            required: true,
        },

        typeOfAssetLabel: {
            type: String,
            required: true,
        },

        productsLabel: {
            type: String,
            required: true,
        },

        categoriesLabel: {
            type: String,
            required: true,
        },

        showStoriesLabel: {
            type: String,
            required: true,
        },

        searchResultsTitle: {
            type: String,
            required: true,
        },

        resetFiltersLabel: {
            type: String,
            required: true,
        },

        noResultsTitle: {
            type: String,
            required: true,
        },

        clearFiltersCaption: {
            type: String,
            required: true,
        },

        recentlyPublishedLabel: {
            type: String,
            required: true,
        },

        isResultsLoading: {
            type: Boolean,
            default: false,
        },

        sortingOptions: {
            type: Array,
            required: true,
        },

        hasResults: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['updateFilterParams', 'resetFilterParams', 'sortingChanged'],
    data() {
        return {
            form: {
                audienceFilter: null,
                assetFilter: null,
                productFilter: null,
                categoryFilter: null,
            },
            filtersApplied: false,
            selectedSorting: null,
        };
    },

    mounted() {
        if (this.sortingOptions) {
            this.selectedSorting = this.sortingOptions[0].value;
        }
        document.addEventListener('click', this.handleClick);
    },

    beforeDestroy() {
        document.removeEventListener('click', this.handleClick);
    },

    methods: {
        applyFilters() {
            this.$emit('updateFilterParams', {
                audienceId: this.form.audienceFilter,
                assetId: this.form.assetFilter,
                productId: this.form.productFilter,
                categoryId: this.form.categoryFilter,
            });

            this.$nextTick(() => {
                this.filtersApplied = true;
            });
        },

        resetFilters() {
            this.filtersApplied = false;
            this.$refs.form.resetFields();
            this.$emit('resetFilterParams');
        },

        handleClick(event) {
            if (event.target.classList.contains('clearFilters')) {
                event.preventDefault();
                this.resetFilters();
            }
        },

        sortingChanged(selectedSorting) {
            this.$emit('sortingChanged', selectedSorting);
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-filters {
    .title {
        @mixin display;

        color: var(--av-nav-primary);
        font-weight: 500;
        margin-bottom: 40px;
        text-align: center;

        @media (--viewport-mobile-wide) {
            @mixin hero;

            text-align: start;
            margin-bottom: 64px;
        }
    }

    .controls {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 16px;
        row-gap: 10px;
        margin-bottom: 48px;

        @media (--viewport-mobile-wide) {
            grid-template-columns: 1fr 1fr;
            row-gap: 16px;
            margin-bottom: 64px;
        }

        @media (--viewport-tablet) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (--viewport-desktop) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            margin-bottom: 40px;
        }
    }

    .search-results-title-wrap {
        display: flex;
        justify-content: space-between;
    }

    .search-results-title {
        @mixin title-accent;

        color: var(--av-nav-primary);
    }

    .reset-filters {
        @mixin body-accent;

        text-decoration: none;

        .a-glyph {
            width: 12px;
            height: 12px;
            margin-inline-start: 10px;
        }
    }

    .search-results-wrap {
        margin-top: 8px;
        border-top: 2px solid var(--av-brand-light);
    }

    .no-search-results {
        @mixin title;

        text-align: center;
        padding: 104px 0;
        color: var(--av-fixed-secondary);
    }

    .recent-title {
        @mixin display;

        font-weight: 500;
        text-align: center;
        color: var(--av-nav-primary);
        margin-bottom: 40px;

        @media (--viewport-mobile-wide) {
            @mixin hero;

            text-align: start;
            margin-bottom: 48px;
        }
    }

    .search-results-list-wrap {
        margin-top: 72px;
        display: grid;
        column-gap: 16px;

        @media (--viewport-desktop-wide) {
            grid-template-columns: 1fr 10fr 1fr;
        }

        @media (--viewport-desktop-large) {
            grid-template-columns: 1fr 4fr 1fr;
        }
    }

    .search-results-list {
        @media (--viewport-desktop-wide) {
            grid-column-start: 2;
            grid-column-end: 3;
        }
    }

    .sorting-wrap {
        text-align: end;
    }

    .sorting {
        max-width: 100px;
        text-align: end;
        display: flex;
        align-items: center;
        float: right;
        margin-top: 30px;
    }
}
</style>

<style lang="postcss">
.blog-filters {
    .filter-select {
        background: var(--av-inversed-primary);
        color: var(--av-fixed-secondary);
        border-radius: 4px;
        height: 46px;

        &.products {
            @media (--viewport-tablet) {
                grid-column: 1;
            }

            @media (--viewport-desktop) {
                grid-column: auto;
            }
        }

        .el-input {
            height: 100%;

            &__container {
                height: 100%;
                padding: 0;
                padding-inline-end: 9px;
                padding-inline-start: 7px;
            }

            &__icon {
                color: var(--av-brand-primary) !important;
            }

            &__placeholder {
                @mixin body;

                color: var(--av-fixed-light);
            }

            &__editor--absolute-position {
                @mixin body;
                color: var(--av-nav-primary);
            }

            &__label.is-active {
                color: var(--av-fixed-light);
                padding-top: 3px;
            }
        }

        .el-input.is-active:not(.el-input--small).el-input--has-label .el-input__wrapper {
            padding-top: 19px;
        }
    }

    .el-form-item {
        padding: 0;

        &.product-form-item {
            grid-column: 1;

            @media (--viewport-desktop) {
                grid-column: auto;
            }
        }
    }

    .el-form-item__error-holder {
        display: none;
    }

    .filter-button {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        background: var(--av-brand-primary);
    }

    .sorting {
        .el-input {
            &__icon {
                color: var(--av-brand-secondary) !important;
            }

            &__editor--absolute-position {
                @mixin body-accent;
                color: var(--av-brand-secondary);
                padding-inline-end: 8px;
                text-align: end;
            }

            &__placeholder {
                display: none;
            }

            &__container {
                background: rgba(255, 255, 255, 0);
                height: auto;
                padding: 0;
                border: 0;
            }

            &__wrapper {
                padding: 0;
                margin-inline-end: -20px;
            }

            &__icon {
                margin-inline-end: -20px;
            }
        }
    }
}

.blog-dropdown.filter-dropdown.el-select-dropdown {
    padding: 0;

    .el-select-dropdown-search-form {
        height: 48px;
        margin-bottom: -5px;
    }

    .el-select-dropdown {
        &__item,
        &__item.selected {
            @mixin body;
            font-weight: 500;
            position: relative;
            color: var(--av-fixed-primary);
            min-height: 32px;
            height: auto;
            padding-top: 4px;
            padding-bottom: 4px;
            white-space: normal;

            &.is-disabled {
                border-top: 1px solid var(--av-brand-accent);
                border-bottom: 1px solid var(--av-brand-accent);
                height: 42px;
                color: var(--av-fixed-light);
                pointer-events: none;
                margin: 16px 0;
            }

            &:first-child {
                &.is-disabled {
                    border-top: 0;
                    margin-top: -13px;
                }
            }

            span {
                white-space: normal;
            }
        }

        &__list {
            padding: 16px 0;
        }

        .el-scrollbar .el-select-dropdown__item.selected,
        .el-scrollbar .el-select-dropdown__item {
            &:hover,
            &:active {
                background: var(--av-brand-lightest);
            }
        }
    }

    .el-input__suffix {
        order: 1;

        .i {
            color: var(--av-brand-primary);
        }
    }

    .el-input__placeholder {
        color: var(--av-brand-primary);
    }

    .el-input__wrapper {
        order: 2;
        color: var(--av-brand-primary);
        padding-inline-start: 0;

        .el-input__placeholder {
            inset-inline-start: 0;
        }
    }

    .el-checkbox {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        height: 100%;
        width: 100%;
        margin: 0;

        &.is-checked {
            background: var(--av-brand-lightest);
        }

        &__content {
            display: none !important;
        }
    }

    .el-scrollbar .el-select-dropdown__item.selected,
    .el-scrollbar .el-select-dropdown__item {
        &:hover,
        &:active {
            background: var(--av-brand-lightest);
        }
    }

    .el-scrollbar__thumb {
        background: var(--av-fixed-lighter);
    }

    .el-select-group__title {
        padding: 0 17px 6px 17px;
        border-bottom: 1px solid var(--av-brand-accent);
        font-weight: 500;
    }
}

.blog-dropdown.sorting-dropdown.el-select-dropdown {
    min-width: 117px !important;
    max-width: none;
    margin-inline-start: -12px;
    padding: 0;
    transform: translateY(-4px);

    .el-select-dropdown {
        &__wrap {
            height: auto !important;
            margin-bottom: 0 !important;
            overflow: auto;
        }

        &__item,
        &__item.selected {
            font-weight: normal;
        }

        &__item {
            height: 32px;

            &.selected {
                color: var(--av-nav-primary);
                pointer-events: none;
            }
        }
        &__list {
            padding: 12px 0;
        }
    }
}
</style>
