<template>
    <div class="resource-search-panel">
        <div class="search-wrapper">
            <h1 class="title">
                {{ commonTranslations['searchForResources'] }}
            </h1>
            <el-input
                :value="value"
                name="search"
                :class="{ 'search-input-filled': value }"
                :label="commonTranslations['search']"
                @input="$emit('input', $event)"
                @focus="onFocus"
                @blur="onBlur"
            />
            <a-button
                class="search-button"
                type="main"
                :text="commonTranslations['search']"
                @click="search"
            />
        </div>
    </div>
</template>

<script lang="ts">
import AButton from '@core/components/button/button.vue';
import { KEYCODE_ENTER } from '@model/const/resource-center';

export default {
    name: 'ResourceSearchPanel',

    components: {
        AButton,
        ElInput: () => import('@uikit/ui-kit/packages/input'),
    },

    props: {
        value: {
            type: String,
            default: '',
        },
        commonTranslations: {
            type: Object,
            required: true,
        },
    },

    emits: ['input', 'search'],

    methods: {
        onFocus() {
            document?.addEventListener('keypress', this.keyHandler);
        },

        onBlur() {
            document?.removeEventListener('keypress', this.keyHandler);
        },

        search() {
            this.$emit('search');
        },

        keyHandler(event) {
            if (event.keyCode !== KEYCODE_ENTER) return;
            this.search();
        },

        resetSearchRequest() {
            this.$emit('input', '');
            this.search();
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-search-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-top: 32px;
    width: 100%;

    @media (--viewport-tablet) {
        margin-top: 24px;
    }

    @media (--viewport-desktop) {
        margin-top: 28px;
    }

    @media (--viewport-desktop-wide) {
        max-width: 1312px;
        margin: 28px auto 0;
    }
}

.search-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    @media (--viewport-tablet) {
        flex-direction: row;
        align-items: center;
    }

    @media (--viewport-desktop) {
        gap: 48px;
    }
}

.title {
    @mixin title;
    text-align: center;
    color: var(--av-nav-primary);

    @media (--viewport-tablet) {
        @mixin lead;
        flex-shrink: 0;
    }

    @media (--viewport-desktop) {
        @mixin display;
    }
}

:deep(.el-input__editor),
:deep(.a-link__content) {
    font-size: 16px;
}

:deep(.a-link__content) {
    @media (--viewport-desktop) {
        @mixin caption;
        font-weight: 700;
    }
}

:deep(.a-button__content) {
    font-weight: 700;
}

.a-button {
    @media (--viewport-tablet) {
        min-width: 133px;
    }

    @media (--viewport-desktop) {
        min-width: 179px;
    }
}

:deep(.el-input__label.is-active) {
    padding-top: 4px;
}

.search-input-filled {
    &:deep(.el-input__label) {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        padding-top: 4px;
    }
}
</style>
