import { render, staticRenderFns } from "./resources.vue?vue&type=template&id=07c69a54&scoped=true"
import script from "./resources.vue?vue&type=script&lang=js"
export * from "./resources.vue?vue&type=script&lang=js"
import style0 from "./resources.vue?vue&type=style&index=0&id=07c69a54&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07c69a54",
  null
  
)

export default component.exports