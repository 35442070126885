<template>
    <div class="header-solutions">
        <header-main v-bind="$props" />

        <div ref="triggerSticky" />

        <div v-if="showSticky" class="sticky">
            <header-sticky v-bind="$props" />
        </div>
    </div>
</template>

<script>
import HeaderMain from './header-main.vue';
import HeaderSticky from './header-sticky.vue';

export default {
    name: 'SolutionsHeader',

    components: {
        HeaderMain,
        HeaderSticky,
    },

    props: {
        background: {
            type: Object,
            default: null,
        },
        productTitle: {
            type: String,
            required: true,
        },
        productSubtitle: {
            type: String,
            required: true,
        },
        productLink: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        ctaTitle: {
            type: String,
            default: '',
        },
        ctaTitleSticky: {
            type: String,
            default: '',
        },
        ctaButtons: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            showSticky: false,
            stickyObserver: undefined,
        };
    },

    mounted() {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        };

        this.stickyObserver = new IntersectionObserver(this.onHeaderScrolled, options);
        this.stickyObserver.observe(this.$refs.triggerSticky);
    },

    beforeDestroy() {
        if (!this.stickyObserver) return;
        this.stickyObserver.disconnect();
    },

    methods: {
        onHeaderScrolled(res) {
            this.showSticky = !res[0].isIntersecting;
        },
    },
};
</script>
