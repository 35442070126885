<template>
    <div class="s-slice a-container resource-ungated-asset">
        <div v-if="publishDate && !isMobile" class="publish-date">
            {{ publishDate }}
        </div>

        <a-link
            v-if="isMobile"
            class="back-button"
            :text="commonTranslations.homePageBreadcrumb"
            glyph="arrow-left"
            glyph-position="left"
            :has-no-ref="true"
            :to="backToSearchUrl"
        />
        <resource-breadcrumbs
            v-else
            :breadcrumbs="breadcrumbs"
            :common-translations="commonTranslations"
        />

        <dynamic-link class="asset-type" :to="assetType.to">
            {{ assetType.text }}
        </dynamic-link>

        <h1 class="title">
            {{ resource.title }}
        </h1>

        <div class="share-block">
            <div class="share-block-text">
                {{ commonTranslations.shareText }}
            </div>
            <a-social :social="socialsShareLinks" :event="gaSocialEvent" glyph-size="s" />
        </div>

        <resource-available-locales
            v-if="availableLocales.length"
            :locales="availableLocales"
            :common-translations="commonTranslations"
        />

        <template v-if="isVideo">
            <div v-if="ytId" class="embed-video">
                <resource-video
                    :ref="`video-${ytId}`"
                    :yt-id="ytId"
                    class="resource-video"
                />
            </div>
        </template>

        <template v-if="resource.slap_five_id">
            <s-slapfive
                class="resource-slapfive-container"
                :use-header="false"
                :board-i-d="resource.slap_five_id"
            />
        </template>

        <template v-else>
            <div v-if="pdfLink" class="download-block">
                <div class="download-block-text">
                    {{ downloadText }}
                </div>
                <a-button
                    :to="pdfLink"
                    type="action"
                    size="m"
                    :text="downloadButtonText"
                    glyph="pdf-white"
                />
            </div>
            <div v-else class="download-block-spacer" />

            <div v-if="showCoverImage" class="cover-image">
                <a-picture :link="resource.cover_image_id" :alt="resource.seo_title" fit="cover" />
            </div>

            <div class="resource-content-wrapper" :class="{ 'is-video': isVideo}">
                <resource-case-study-snippets
                    v-if="isCaseStudy && caseStudySnippets.length"
                    :snippets="caseStudySnippets"
                />
                <resource-article-body
                    v-if="body.length"
                    :class="{ 'case-study-article-fix': isCaseStudy && caseStudySnippets.length }"
                    :blocks="body"
                />
            </div>
        </template>

        <resource-cta-card
            v-if="ctaData"
            :try-now-link="ctaData.button_url"
            :try-now-text="ctaData.button_text"
            :learn-more-link="ctaData.link_url"
            :learn-more-text="ctaData.link_text"
            :title="ctaTitle"
            :subtitle="ctaData.label_text"
        />

        <resource-tags-list v-if="tags.length" :tags="tags" />
        <a-modal
            v-if="ytId"
            :id="ytId"
            is-youtube
            type="youtube"
        >
            <a-youtube :yt-id="ytId" :params="{ autoplay: 1 }" />
        </a-modal>
    </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import AButton from '@core/components/button/button.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALink from '@core/components/link/link.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import ASocial from '@core/components/social/social.vue';
import AYoutube from '@core/components/youtube/youtube.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import SSlapfive from '@core/slices/pages/slapfive/slapfive.vue';
import ResourceVideo from '@core/slices/resource-center/resource-gated-asset/components/resource-video.vue';
import ResourceArticleBody from '@core/slices/resource-center/shared-components/resource-article-body.vue';
import ResourceAvailableLocales from '@core/slices/resource-center/shared-components/resource-available-locales.vue';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { RC_CASE_STUDY_SNIPPETS, RC_LOCAL_STORAGE_SEARCH_FILTERS, RC_ROOT_URL } from '@model/const/resource-center';
import { formatToLocaleDate } from '@utils/locales';
import ResourceBreadcrumbs from '../resource-breadcrumbs/resource-breadcrumbs.vue';
import ResourceCaseStudySnippets from '../resource-case-study-snippets/resource-case-study-snippets.vue';
import ResourceCtaCard from '../resource-cta-card/resource-cta-card.vue';
import ResourceTagsList from '../resource-tags-list/resource-tags-list.vue';

export default {
    name: 'SResourceUngatedAssetsPage',

    components: {
        AButton,
        AModal,
        ALink,
        APicture,
        AYoutube,
        ASocial,
        DynamicLink,
        ResourceArticleBody,
        ResourceAvailableLocales,
        ResourceBreadcrumbs,
        ResourceCaseStudySnippets,
        ResourceCtaCard,
        ResourceTagsList,
        SSlapfive,
        ResourceVideo,
    },

    mixins: [breakpoint],

    props: {
        resource: {
            type: Object as any,
            required: true,
        },
        commonTranslations: {
            type: Object as any,
            required: true,
        },
        socialsShareLinks: {
            type: Array,
            default: () => [],
        },
        tags: {
            type: Array,
            default: () => [],
        },
        assetType: {
            type: Object as any,
            required: true,
        },
        breadcrumbs: {
            type: Array,
            required: true,
        },
        ctaTitle: {
            type: String,
            default: '',
        },
        ctaData: {
            type: Object as any,
            default: null,
        },
        pageType: {
            type: String,
            required: true,
        },
        gaSocialEvent: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapGetters('config', ['$config']),

        availableLocales() {
            return this.$store.getters['resourceCenter/getAvailableLocales'](this.locale);
        },

        locale() {
            return this.$route.params.locale || LOCALE_DEFAULT;
        },

        backToSearchUrl() {
            const url = `${RC_ROOT_URL}/search/`;

            // @ts-ignore
            if (process.env.VUE_ENV === 'server') return url;

            const savedFilters = JSON.parse(localStorage.getItem('resourceSearchFilters') || '{}');

            if (!Object.keys(savedFilters).length) return url;

            const queryParams = Object.entries(savedFilters)
                .map(([key, val]) => [key, Array.isArray(val) ? val : [val]])
                .flatMap(([key, val]: [string, string[]]) => val.map((i) => `${key}=${i}`))
                .join('&');

            localStorage.removeItem(RC_LOCAL_STORAGE_SEARCH_FILTERS);

            return `${url}?${queryParams}`;
        },

        showCoverImage() {
            return this.resource.cover_image_id && !this.isCaseStudy && !this.isVideo;
        },

        downloadText() {
            const title = this.resource?.type?.title || 'resource';
            return this.commonTranslations.downloadPdfAsset?.replace('{asset}', title);
        },

        downloadButtonText() {
            return this.commonTranslations.downloadButtonText || 'Download';
        },

        body() {
            if (!this.resource.description) return [];

            const body = JSON.parse(this.resource.description);
            return body.blocks;
        },

        publishDate() {
            return formatToLocaleDate(this.resource.published_at, this.locale);
        },

        ytId() {
            if (!this.isVideo) return null;
            return this.resource.url.split('/').pop();
        },

        pdfLink() {
            if (this.isVideo) return false;

            if (this.resource.url) return this.resource.url;

            if (!this.resource.file_id) return false;

            const storageUrl = this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE;
            return `${storageUrl}/downloads/${this.resource.file_id}`;
        },

        isCaseStudy() {
            return this.resource.type.slug === 'case-studies';
        },

        caseStudySnippets() {
            if (!this.isCaseStudy || !this.body.length) return [];
            return this.body.filter((block) => RC_CASE_STUDY_SNIPPETS.has(block.type));
        },

        isVideo() {
            return this.pageType === 'video';
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-ungated-asset {
    position: relative;
    text-align: center;
    padding-top: 0;
    padding-bottom: 80px;
    margin-top: 32px;

    /* hack to hide active page's breadcrumb, we don't want to show it */

    &:deep(.a-breadcrumbs) {
        &__item {
            &:last-child {
                display: none;
            }

            &:nth-last-child(2) {
                svg {
                    display: none;
                }
            }
        }
    }

    &:deep(.article-video) {
        height: 172px;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 24px;

        @media (--viewport-mobile-wide) {
            height: 290px;
        }
        @media (--viewport-tablet) {
            margin-top: 24px;
        }
        @media (--viewport-desktop) {
            margin-bottom: 48px;
            height: 404px;
        }
        @media (--viewport-desktop-wide) {
            margin-bottom: 24px;
            height: 430px;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

.publish-date {
    position: absolute;
    top: 0;
    inset-inline-end: 32px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--av-nav-primary);
}

.asset-type {
    display: inline-block;
    margin-top: 40px;
    padding: 0 8px;
    color: var(--av-nav-secondary);
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 700;
    background: var(--av-brand-secondary-light);
    border-radius: 4px;

    @media (--viewport-mobile-wide) {
        margin-top: 42px;
    }

    @media (--viewport-desktop) {
        margin-top: 45px;
    }
}

.title {
    margin-top: 8px;
    padding: 0 8px;
    color: var(--av-nav-primary);
    @mixin title;

    @media (--viewport-mobile-wide) {
        @mixin display;
    }

    @media (--viewport-tablet) {
        @mixin hero;
    }

    @media (--viewport-desktop-wide) {
        padding: 0 32px;
    }

    @media (--viewport-desktop-large) {
        padding: 0 64px;
    }
}

.share-block {
    margin-top: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 16px;
}

.share-block-text {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--av-nav-primary);
    mix-blend-mode: normal;
    opacity: 0.9;
}

.download-block {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
    gap: 16px;
    background: var(--av-brand-secondary-accent);
    border-radius: 4px;

    @media (--viewport-mobile-wide) {
        flex-direction: row;
    }

    @media (--viewport-tablet) {
        margin: 24px auto 24px;
        gap: 40px;
        justify-content: center;
        width: auto;
    }

    @media (--viewport-desktop) {
        margin: 24px auto 24px;
    }

    @media (--viewport-desktop-large) {
        margin-inline-start: 64px;
        margin-inline-end: 64px;
    }

    &:deep(.a-glyph) {
        margin-inline-start: 8px;
    }
}

.download-block-text {
    font-size: 16px;
    line-height: 24px;
    color: var(--av-nav-primary);

    @media (--viewport-mobile-wide) {
        text-align: start;
    }
}

.download-block .a-button {
    @media (--viewport-mobile-wide) {
        font-size: 14px;
    }
}

.download-block-spacer {
    margin: 24px 0 48px;
}

.cover-image {
    margin-top: 24px;
    width: 100%;
    min-height: 171px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--av-solid-brand-lightest);

    @media (--viewport-mobile-wide) {
        height: 368px;
    }

    @media (--viewport-tablet) {
        height: 455px;
        max-width: 871px;
        margin: 0 auto;
    }

    &:deep(.a-picture) {
        height: 100%;
    }

    &:deep(.a-picture__img) {
        height: 100%;
    }
}

.resource-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 46px;
    gap: 80px;

    &.is-video {
        margin-top: 0;
    }

    @media (--viewport-tablet) {
        flex-direction: row;
        gap: 16px;
    }

    .case-study-article-fix {
        margin-top: -16px;
    }

    &:deep(.article-body) {
        width: 100%;
    }
}

.resource-slapfive-container {
    &.s-slapfive {
        padding-top: 40px;
        padding-bottom: 0;
        text-align: center;

        @media (--viewport-tablet) {
            text-align: start;
        }

        &:deep(.s-slapfive-card__picture) {
            margin: 0 auto;

            @media (--viewport-tablet) {
                margin: 0 16px 0 0;
            }
        }
    }
}

.embed-video {
    margin: 20px 0 0;

    @media (--viewport-tablet) {
        margin: 24px 0 0;
    }

    @media (--viewport-desktop) {
        margin: 24px 0;
    }

    .resource-video {
        border: 0;
        margin: 0 auto;
        width: 328px;
        height: 178px;

        @media (--viewport-mobile-wide) {
            width: 472px;
            height: 260px;
        }

        @media (--viewport-tablet) {
            width: 704px;
            height: 372px;
        }

        @media (--viewport-desktop) {
            width: 870px;
            height: 460px;
        }
    }
}

.back-button {
    display: block;

    &:deep(.a-link__content) {
        @mixin caption-accent;
        color: var(--av-brand-primary);
    }

    &:deep(.a-glyph) {
        top: 0;
    }
}
</style>
