<template>
    <section :id="id" class="s-slice s-intro" :class="{ ...className, ...basicClassName }">
        <div class="a-container">
            <component :is="titleTag" v-if="l10n.title" class="a-slice-header__title">
                {{ l10n.title }}
            </component>
            <div v-if="l10n.lead || l10n.bodyText || l10n.link" class="s-intro__wrapper">
                <a-dangerous-html
                    v-if="l10n.lead"
                    class="a-slice-header__lead"
                    :content="l10n.lead"
                    :tag="'p'"
                />
                <a-dangerous-html v-if="l10n.bodyText" class="s-intro__body-text" :content="l10n.bodyText" />
                <div v-if="l10n.fact" class="s-intro__fact s-intro__additional-info">
                    <img
                        v-if="l10n.fact.image"
                        class="s-intro__fact-img"
                        :src="l10n.fact.image"
                        loading="lazy"
                        alt=""
                    />
                    <div class="s-intro__fact-title">
                        {{ l10n.fact.title }}
                    </div>
                    <a-dangerous-html class="s-intro__fact-text" :content="l10n.fact.text" />
                </div>
                <blockquote v-if="l10n.quote" class="s-intro__quote s-intro__additional-info">
                    <div class="s-intro__quote-text">
                        {{ l10n.quote.text }}
                    </div>
                    <cite class="s-intro__quote-cite">
                        <div class="s-intro__quote-img-container">
                            <a-picture class="s-intro__quote-img" :link="l10n.quote.image" :alt="l10n.quote.name" />
                        </div>
                        <div class="s-intro__quote-cite-text">
                            <div class="s-intro__quote-name">{{ l10n.quote.name }}</div>
                            <div class="s-intro__quote-position">{{ l10n.quote.position }}</div>
                        </div>
                    </cite>
                </blockquote>
                <div v-if="l10n.downloadFileArr?.length" class="s-intro__download-files">
                    <ul v-if="l10n.downloadFileArr?.length > 1" class="s-intro__download-file-list">
                        <li v-for="item in l10n.downloadFileArr" :key="item.link" class="s-intro__download-file-item">
                            <a-link
                                v-bind="item"
                                :to="item.link"
                                :type="item.type || 'download-file'"
                                :glyph="item.glyph || 'download-arrow'"
                                :glyph-position="item.glyphPosition || 'left'"
                                :text="item.title"
                                :desc="item.desc"
                            />
                        </li>
                    </ul>
                    <a-link
                        v-else
                        v-bind="l10n.downloadFileArr[0]"
                        :to="l10n.downloadFileArr[0].link"
                        :type="l10n.downloadFileArr[0]?.type || 'download-file'"
                        :glyph="l10n.downloadFileArr[0]?.glyph || 'download-arrow'"
                        :glyph-position="l10n.downloadFileArr[0]?.glyphPosition || 'left'"
                        :text="l10n.downloadFileArr[0].title"
                        :desc="l10n.downloadFileArr[0].desc"
                    />
                </div>
                <a-link
                    v-if="l10n.link"
                    class="s-intro__link"
                    v-bind="l10n.link"
                    :type="l10n.link.type || 'direct'"
                    :glyph="l10n.link.glyph || 'arrow'"
                />
            </div>
        </div>
    </section>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import contentMixin from '@core/mixins/content.js';
import l10n from '@core/mixins/l10n.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SIntro',

    components: {
        ALink,
        SBasicSlice,
        ADangerousHtml,
        APicture,
    },

    mixins: [l10n, contentMixin, styleMixin],

    props: {
        link: {
            type: [Object, Boolean],
            default: false,
        },
        fact: {
            type: [Object, Boolean],
            default: false,
        },
        quote: {
            type: Object,
            required: false,
            default: undefined,
        },
        downloadFileArr: {
            type: Array,
            required: false,
            default: undefined,
        },
        bodyText: {
            type: String,
            required: false,
            default: undefined,
        },
        titleTag: {
            type: String,
            default: 'div',
        },
        hugeTitle: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        className() {
            return {
                's-intro_layout_with-link': this.l10n.link && !this.l10n.downloadFileArr,
                's-intro_layout_additional-info': this.l10n.fact || this.l10n.quote,
                's-intro_layout_download-file-list': this.l10n.downloadFileArr || (this.l10n.downloadFileArr && this.l10n.link),
                's-intro_layout_only-lead': !this.l10n.title,
                's-intro_huge-title': this.l10n.hugeTitle,
            };
        },
    },
};
</script>

<style lang="postcss">
.s-intro {
    .a-slice-header {
        &__title {
            @mixin display-accent;
            margin-bottom: 24px;
            @media (--viewport-mobile-wide) {
                @mixin hero-accent;
            }
        }

        &__lead {
            margin-top: 0;
        }
    }

    &__link {
        margin-top: 24px;
    }

    &_huge-title {
        .a-slice-header {
            &__title {
                @mixin display-accent;

                @media (--viewport-mobile-wide) {
                    @mixin hero-accent;
                }

                @media (--viewport-desktop) {
                    @mixin large-accent;
                }
            }
        }
    }

    .a-picture__img {
        width: 100%;
    }

    &_layout {
        &_additional-info {
            .s-intro {
                &__additional-info {
                    margin-top: 32px;
                }

                &__fact-title {
                    @mixin title-accent;

                    margin-bottom: 8px;
                    color: var(--av-nav-primary);
                }

                &__fact-text {
                    @mixin paragraph;

                    color: var(--av-fixed-secondary);
                }

                &__fact-img {
                    margin-bottom: 16px;
                    display: block;
                    max-width: 100%;
                }

                &__quote-cite-text {
                    width: 100%;
                }

                &__quote-text {
                    margin-bottom: 24px;
                    color: var(--av-fixed-secondary);
                }

                &__quote-cite {
                    display: flex;
                    align-items: center;
                }

                &__quote-name {
                    font-weight: 700;
                    color: var(--av-nav-primary);
                }

                &__quote-position {
                    @mixin body;

                    color: var(--av-nav-primary);
                }

                &__quote-img-container {
                    margin-inline-end: 16px;
                    width: 64px;
                    height: 64px;
                    flex: 0 0 64px;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }
        }

        &_download-file-list {
            .s-intro {
                &__download-files {
                    margin-top: 40px;
                }

                &__download-file-list {
                    margin-inline-end: -16px;
                    display: flex;
                    flex-wrap: wrap;
                }

                &__download-file-item {
                    width: 50%;
                    padding-inline-end: 32px;

                    &:not(:last-of-type) {
                        margin-bottom: 48px;
                    }
                }

                &__link {
                    margin-top: 40px;
                }
            }
        }
    }

    @media (--viewport-mobile-wide) {
        &_layout {
            &_additional-info {
                .a-slice-header {
                    margin-bottom: 0;

                    &__lead {
                        -ms-grid-column: 1;
                    }
                }

                .s-intro {
                    &__wrapper {
                        display: grid;
                        grid-template-columns: 8fr 4fr;
                        -ms-grid-columns: 8fr 5% 4fr;
                        grid-column-gap: 5%;
                    }

                    &__additional-info {
                        margin-top: 0;
                        grid-row: span 2;
                        -ms-grid-column: 3;
                        -ms-grid-row-span: 2;
                    }

                    &__info-title {
                        max-width: none;
                    }

                    &__quote-cite {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    &__quote-name {
                        line-height: 1.6;
                    }

                    &__quote-img-container {
                        margin-bottom: 16px;
                        margin-inline-end: 0;
                    }

                    &__quote-position {
                        padding-inline-end: 20px;
                        line-height: 1.8;
                    }

                    &__link {
                        -ms-grid-column: 1;
                        -ms-grid-row: 2;
                    }
                }
            }

            &_download-file-list {
                .s-intro {
                    &__download-file-list {
                        margin: 40px 0 0 0;
                        margin-inline-end: -16px;
                    }

                    &__download-file-item {
                        width: calc(33.33% - 16px);
                        margin-inline-end: 16px;
                        padding-inline-end: 16px;

                        &:not(:last-of-type) {
                            margin-bottom: 0;
                        }
                    }

                    &__link {
                        position: static;
                        max-width: 100%;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop) {
        .a-slice-header {
            &__title {
                width: 66.66%;
            }

            &__lead {
                width: 66.66%;
            }
        }

        .s-intro__body-text {
            width: 75%;
        }

        &_layout {
            &_with-link {
                .s-intro {
                    &__link {
                        position: absolute;
                        top: 8px;
                        inset-inline-end: 32px;
                        margin: 0;
                    }
                }
            }

            &_additional-info {
                .a-slice-header {
                    width: 65%;

                    &__lead {
                        width: 88%;
                    }
                }

                .s-intro__body-text {
                    width: 88%;
                }

                .s-intro {
                    &__wrapper {
                        grid-column-gap: 4%;
                    }

                    &__info-title {
                        max-width: 86%;
                    }

                    &__quote-cite {
                        flex-direction: row;
                    }

                    &__quote-img-container {
                        margin-bottom: 0;
                        margin-inline-end: 16px;
                    }

                    &__quote-cite-text {
                        margin-top: 8px;
                    }
                }
            }

            &_download-file-list {
                .a-slice-header {
                    &__title {
                        max-width: 66.66%;
                    }
                }

                .s-intro {
                    &__wrapper {
                        display: grid;
                        grid-template-columns: 66.66% 24%;
                        -ms-grid-columns: 66.66% 9.34% 24%;
                        grid-template-rows: auto 1fr;
                        -ms-grid-rows: auto 1fr;
                        grid-column-gap: 9.34%;
                    }

                    &__download-files {
                        -ms-grid-column: 3;
                        grid-row: span 2;
                        -ms-grid-row-span: 2;
                        margin-top: 0;
                        padding-inline-start: 24px;
                        border-inline-start: var(--av-border);
                    }

                    &__download-file-list {
                        margin: 0;
                        flex-direction: column;
                    }

                    &__download-file-item {
                        width: 100%;
                        padding-inline-end: 16px;

                        &:not(:last-of-type) {
                            margin-bottom: 24px;
                        }
                    }

                    &__link {
                        margin-top: 40px;
                        -ms-grid-column: 1;
                        -ms-grid-row: 2;
                    }
                }

                .a-slice-header__lead {
                    max-width: 100%;
                }
            }

            &_only-lead {
                .s-intro {
                    &__link {
                        position: static;
                        margin-top: 40px;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop-wide) {
        .a-slice-header__title {
            margin-bottom: 0;
            width: 66.6%;

            + .s-intro__wrapper {
                margin-top: 24px;
            }
        }

        .a-slice-header__lead {
            width: 66.6%;
        }

        .s-intro__body-text {
            width: 66.6%;
        }

        &__link {
            top: 8px;
            inset-inline-end: 64px;
        }

        &_layout {
            &_additional-info {
                .a-slice-header__lead {
                    width: 90%;
                }

                .s-intro__body-text {
                    width: 90%;
                }

                .s-intro__additional-info {
                    padding-inline-start: 24px;
                    border-inline-start: var(--av-border);
                }

                .s-intro__quote-position {
                    padding-inline-end: 0;
                }
            }

            &_download-file-list {
                .a-slice-header__lead {
                    width: 100%;
                }

                .s-intro__body-text {
                    width: 100%;
                }

                .s-intro__download-files {
                    padding-inline-end: 24px;
                }

                .s-intro__quote-position {
                    padding-inline-end: 0;
                }

                .s-intro__link {
                    margin-top: 40px;
                    clear: none;
                }
            }
        }
    }

    @media (--viewport-desktop-large) {
        &__link {
            inset-inline-end: 0;
        }

        &_layout {
            &_additional-info {
                .s-intro {
                    &__additional-info {
                        padding-inline-end: 24px;
                    }

                    &__info-title {
                        max-width: none;
                    }
                }
            }

            &_download-file-list {
                .a-slice-header__lead {
                    width: 100%;
                }
            }
        }
    }
}
</style>
