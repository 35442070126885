<template>
    <component :is="titleTag" v-if="title" :class="className">
        <a-dangerous-html v-if="isDangerous" :content="title" />
        <template v-else>
            {{ title }}
        </template>
    </component>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';

export default {
    name: 'SMainPageTag',
    components: {
        ADangerousHtml,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        titleTag: {
            type: String,
            default: 'p',
        },
        className: {
            type: String,
            default: 'title',
        },
        isDangerous: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isVisible() {
            return this.content.title;
        },
    },
};
</script>
