<template>
    <section class="acpc-feature-cards">
        <div class="a-container">
            <h5 v-if="title" class="main-title">
                {{ title }}
            </h5>
            <div class="cards">
                <div
                    v-for="(card, i) in cards"
                    :key="`feature-cards-card_${i}`"
                    class="card"
                    :class="assignClassName(i)"
                >
                    <a-icon
                        v-if="card.icon"
                        :number="card.icon.number"
                        :icon-variant="card.icon.variant === undefined ? true : card.icon.variant"
                        class="icon"
                    />
                    <h6 class="title">
                        {{ card.title }}
                    </h6>
                    <p class="desc" v-html="card.description" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AIcon from '@core/components/icon/icon.vue';

export default {
    name: 'SProductFeatureCards',
    components: {
        AIcon,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        cards: {
            type: Array,
            required: true,
        },
    },
    methods: {
        isFull(i) {
            return i > 2;
        },
        assignClassName(i) {
            if (i === 1) return 'doubleBorder';
            if (i === 4) return 'full singleBorder';
            if (i > 2) return 'full';
            return false;
        },
    },
};
</script>

<style lang="postcss" scoped>
.acpc-feature-cards {
    padding-top: 64px;
    padding-bottom: 64px;
    background: var(--av-gradient-to-bottom-lightest);
    .main-title {
        @mixin display;
        color: var(--av-nav-primary);
        margin-bottom: 40px;
        @media (--viewport-tablet) {
            @mixin hero;
        }
    }
    .cards {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        gap: 16px;
        @media (--viewport-tablet) {
            gap: 24px 16px;
        }
        .card {
            @mixin colls 12;
            border-bottom: 1px solid var(--av-brand-accent);
            padding-bottom: 16px;
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
            @media (--viewport-tablet) {
                @mixin colls 4;
                border-bottom: none;
            }
            .icon {
                max-width: 48px;
                max-height: 48px;
                margin-bottom: 8px;
            }
            .title {
                @mixin title-accent;
                margin-bottom: 16px;
                color: var(--av-nav-primary);
            }
            .desc {
                @mixin paragraph;
                color: var(--av-fixed-secondary);
            }
            &.full {
                @media (--viewport-tablet) {
                    @mixin colls 6;
                }
            }
            &.doubleBorder {
                @media (--viewport-tablet) {
                    border-inline-start: 1px solid var(--av-brand-accent);
                    border-inline-end: 1px solid var(--av-brand-accent);
                    padding: 0 16px;
                }
            }
            &.singleBorder {
                @media (--viewport-tablet) {
                    padding-inline-start: 16px;
                    border-inline-start: 1px solid var(--av-brand-accent);
                }
            }
        }
    }
}
</style>
