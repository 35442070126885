const CLASSES = {
    activeTabParent: 'a-tabs__item_active',
    activeTab: 'a-tabs-item_active',
    activePanel: 'a-tab_active',
};

export function animate(opts, _startedAt) {
    const startedAt = _startedAt || performance.now();

    requestAnimationFrame((time) => {
        let timeFraction = (time - startedAt) / opts.duration;
        if (timeFraction > 1) {
            timeFraction = 1;
        }

        const progress = opts.timing(timeFraction);

        opts.draw(progress);

        if (timeFraction < 1) {
            requestAnimationFrame(() => animate(opts, startedAt));
        }
    });
}

export function easeInOutQuad(t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

class Tabs {
    constructor(el, options, vnode) {
        this.el = el;
        this.options = options;
        this.tabsContainer = this.el.querySelector('[data-tabs-header]');
        this.tabSwitchers = [...this.el.querySelectorAll('[data-tab-switcher]')];
        this.tabPanels = [...this.el.querySelectorAll('[data-tab-panel]')];
        this.vueRoot = vnode.context.$root;

        this.activeIndex = options && options.activeIndex ? options.activeIndex : 0;

        this.underline = el.querySelector('[data-tabs-underline]');
        this.newScrollPos = 0;
        this.animationRightShift = 48;
        this.animationLeftShift = 64;

        this.tabsClickHandler = this.tabsClickHandler.bind(this);
        this.setUnderline = this.setUnderline.bind(this);
        this.moveScroll = this.moveScroll.bind(this);
        this.init();
    }

    init() {
        this.setTabIndexes();
        this.switchActive(this.activeIndex);
        this.setUnderline();

        window.addEventListener('resize', this.setUnderline);
        this.el.addEventListener('click', this.tabsClickHandler);
        this.vueRoot.$on('tabChangeActive', this.onTabChange.bind(this));
    }

    setTabIndexes() {
        this.tabSwitchers?.forEach((tab, index) => {
            tab.parentElement?.setAttribute('style', `--data-index: ${this.tabSwitchers.length - index};`);
        });
    }

    onTabChange(e) {
        this.switchTab(e.activeTabIndex);
    }

    tabsClickHandler(event) {
        if (event.target.closest('[data-tab-switcher]')) {
            const newActiveIndex = this.tabSwitchers.indexOf(
                event.target.closest('[data-tab-switcher]'),
            );

            this.switchTab(newActiveIndex);
        }
    }

    switchTab(newActiveIndex) {
        if (this.activeIndex !== newActiveIndex) {
            this.switchActive(newActiveIndex);
            this.tabMoving();
            this.setUnderline();
        }
    }

    switchActive(newActiveIndex) {
        this.tabSwitchers[this.activeIndex].classList.remove(CLASSES.activeTab);
        this.tabSwitchers[this.activeIndex].parentElement.classList.remove(CLASSES.activeTabParent);
        this.tabPanels[this.activeIndex].classList.remove(CLASSES.activePanel);

        this.activeIndex = newActiveIndex;

        this.tabSwitchers[this.activeIndex].classList.add(CLASSES.activeTab);
        this.tabSwitchers[this.activeIndex].parentElement.classList.add(CLASSES.activeTabParent);
        this.tabPanels[this.activeIndex].classList.add(CLASSES.activePanel);
    }

    tabMoving() {
        const activeTabRect = this.tabSwitchers[this.activeIndex].getBoundingClientRect();
        const tabsContainerRect = this.tabsContainer.getBoundingClientRect();

        const tabWidth = activeTabRect.width;
        const tabLeftXCoord = Math.floor(activeTabRect.x);
        const tabRightXCoord = tabLeftXCoord + tabWidth;

        const tabsContainerWidth = tabsContainerRect.width;
        const tabsContainerLeftXCoord = Math.floor(tabsContainerRect.x);
        const tabsContainerRightXCoord = tabsContainerLeftXCoord + tabsContainerWidth;

        const tabsContainerScrollLeft = this.tabsContainer.scrollLeft;

        if (tabLeftXCoord - 32 < tabsContainerLeftXCoord) {
            this.newScrollPos =
                tabsContainerScrollLeft +
                tabLeftXCoord -
                tabsContainerLeftXCoord -
                this.animationLeftShift;
            window.requestAnimationFrame(this.moveScroll);
        }

        if (tabRightXCoord + 32 > tabsContainerRightXCoord) {
            this.newScrollPos =
                tabsContainerScrollLeft +
                (tabLeftXCoord + tabWidth) -
                tabsContainerWidth +
                this.animationRightShift;
            window.requestAnimationFrame(this.moveScroll);
        }
    }

    moveScroll() {
        animate({
            duration: 350,
            timing: easeInOutQuad,
            draw: (progress) => {
                this.tabsContainer.scrollLeft +=
                    progress * (this.newScrollPos - this.tabsContainer.scrollLeft);
            },
        });
    }

    setUnderline() {
        const activeTabRect = this.tabSwitchers[this.activeIndex].getBoundingClientRect();
        const btnWidth = activeTabRect.width;
        const btnLeftCoord = activeTabRect.left;
        const tabsContainerLeftXCoord = this.tabsContainer.getBoundingClientRect().left;
        const tabsContainerScrollLeft = this.tabsContainer.scrollLeft;

        this.underline.style.width = `${btnWidth}px`;
        this.underline.style.left = `${btnLeftCoord - tabsContainerLeftXCoord + tabsContainerScrollLeft}px`;
    }

    destroy() {
        window.removeEventListener('resize', this.setUnderline);
        this.vueRoot.$off('tabChangeActive', this.onTabChange);
        this.el.removeEventListener('click', this.tabsClickHandler);
    }
}

export default {
    inserted(el, { value: { options } }, vnode) {
        el.tabs = new Tabs(el, options, vnode);
    },

    unbind(el) {
        el.tabs.destroy();
        el.tabs = null;
    },
};
