<template>
    <div class="s-basic-html" :class="[marginClasses, paddingClasses]">
        <div class="a-container">
            <div class="s-basic-html__wrapper">
                <a-dangerous-html
                    v-if="type !== 'table' && type !== 'ol' && type !== 'ul'"
                    :tag="type"
                    :content="content"
                />
                <div v-if="type === 'table'" class="s-basic-html__table s-basic-html__item">
                    <a-table-new v-bind="content" />
                </div>
                <div v-if="type === 'ol' || type === 'ul'" class="s-basic-html__list-wrap">
                    <component :is="type" class="s-basic-html__list s-basic-html__item">
                        <li v-for="listItem in content.items" :key="listItem">
                            <a-dangerous-html :content="listItem" />
                        </li>
                    </component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import ATableNew from '@core/components/table-new/table-new.vue';
import spacingMixin from '@core/mixins/spacing-mixin.js';

export default {
    name: 'SBasicHtml',
    components: {
        ADangerousHtml,
        ATableNew,
    },
    mixins: [spacingMixin],
    props: {
        type: {
            type: String,
            default: 'p',
        },
        content: {
            type: [String, Object],
            default: '',
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-basic-html {
    margin-bottom: 32px;
    &__wrapper {
        @mixin colls 12;
        @media (--viewport-desktop-wide) {
            @mixin colls 10;
        }
        @media (--viewport-desktop-large) {
            @mixin colls 9;
        }
    }

    ::marker {
        content: initial;
    }

    h1 {
        @mixin display;
        @media (--viewport-desktop) {
            @mixin hero;
        }
    }
    h2 {
        @mixin title;
        @media (--viewport-desktop) {
            @mixin display;
        }
    }
    h3 {
        @mixin title;
    }
    h4 {
        @mixin lead;
    }
    p {
        @mixin paragraph;
    }
    i {
        font-style: italic;
    }
    b,
    strong {
        font-weight: bold;
    }
    ul,
    ol {
        @mixin paragraph;
        list-style: revert;
        padding-inline-start: 20px;
    }
    &.lead {
        p {
            @mixin title;
        }
    }

    &.subtitle {
        margin-bottom: 16px;
    }
    &.title {
        margin-bottom: 32px;
    }

    .a-dangerous-html {
        overflow-wrap: anywhere;
    }
}
</style>
