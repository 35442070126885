<template>
    <div class="blog-page blog-authors">
        <s-locale-selector-ribbon :is-shown="isLocaleSelectorShown" />
        <div class="main-content">
            <s-main-header v-bind="sliceDataHeader" />
            <blog-container class="intro">
                <blog-bcrumbs v-bind="bcrumbs" />
                <blog-experts
                    class="experts"
                    :title="translations.authorsTitleFull"
                    :link-text="translations.seePublications"
                    :authors="authors"
                />
            </blog-container>
        </div>
        <s-global-footer class="footer" v-bind="sliceDataFooter" />
    </div>
</template>

<script>
import analytics from '@core/mixins/analytics.js';
import blog from '@core/mixins/blog.js';
import localeRibbon from '@core/mixins/locale-ribbon.js';
import BlogBcrumbs from '@core/slices/blog/blog-bcrumbs/blog-bcrumbs.vue';
import BlogExperts from '@core/slices/blog/blog-experts/blog-experts.vue';
import BlogContainer from '@core/slices/blog/shared-components/blog-container/blog-container.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import SLocaleSelectorRibbon from '@core/slices/pages/locale-selector-ribbon/locale-selector-ribbon.vue';
import SMainHeader from '@core/slices/pages/main-header/main-header.vue';
import '@core/styles/sections/blog.pcss';

export default {
    name: 'BlogAuthors',

    components: {
        SGlobalFooter,
        SMainHeader,
        BlogBcrumbs,
        BlogExperts,
        BlogContainer,
        SLocaleSelectorRibbon,
    },

    mixins: [blog, analytics, localeRibbon],

    computed: {
        sliceDataHeader() {
            return {
                ...this.$store.state.slices.items['s-main-header'],
                theme: 'light',
            };
        },

        sliceDataFooter() {
            return {
                ...this.$store.state.slices.items['s-global-footer'],
                type: 'short',
                theme: 'dark',
            };
        },

        translations() {
            return this.$store.state.slices.items.blog || {};
        },

        bcrumbs() {
            const blogItem = {
                to: '/blog/',
                title: this.translations.homeTitle,
            };
            const bcrumbs = [
                blogItem, { title: this.translations.authorsTitle },
            ];

            return {
                theme: 'light',
                bcrumbs,
                mobileLink: blogItem,
            };
        },

        authors() {
            const authors = this.$store.state.blog.authors.items || [];
            return authors.map(this.getAuthorFields);
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-authors {
    &:deep(.blog-bcrumbs) {
        margin-top:48px;
        padding-bottom: 0;
    }
    .experts {
        margin-top: 8px;
    }
}
</style>
