var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a-event-card",class:{
        'a-event-card--shadow': _vm.cardShadow,
        'a-event-card--mini': _vm.miniCard,
        'a-event-card--accent': _vm.accentCard,
    }},[(_vm.isLoading)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}],staticClass:"a-event-card__info-loading",attrs:{"el-loading-size":"48"}}):[(!_vm.miniCard)?_c('a-picture',{staticClass:"a-event-card__img",attrs:{"link":_vm.image,"alt":_vm.imageAlt,"fit":"cover","position":_vm.picturePosition}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"a-event-card__info"},[(_vm.label && !_vm.hasEnded)?_c('div',{staticClass:"a-event-card__date label-wrapper"},[_c('a-glyph',{staticClass:"a-accordion__icon",attrs:{"name":_vm.label.glyph}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.label.text))])],1):_c('div',{staticClass:"a-event-card__date",class:{'a-event-card__date--ended': _vm.hasEnded}},[(_vm.hasEnded)?_c('a-glyph',{staticClass:"a-accordion__icon",attrs:{"name":"play"}}):_c('a-glyph',{staticClass:"a-accordion__icon",attrs:{"name":"calendar"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.date))])],1),_vm._v(" "),_c('div',{staticClass:"a-event-card__title"},[_vm._v("\n                "+_vm._s(_vm.title)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"a-event-card__desc"},[_vm._v("\n                "+_vm._s(_vm.desc)+"\n            ")]),_vm._v(" "),(_vm.language || _vm.duration)?_c('div',{staticClass:"a-event-card__extra-info",class:{
                    'a-event-card__extra-info--ended': _vm.hasEnded
                }},[(_vm.language)?_c('div',[_c('a-glyph',{attrs:{"name":"quote-icon"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.language))])],1):_vm._e(),_vm._v(" "),(_vm.duration)?_c('div',[_c('a-glyph',{attrs:{"name":`clock-${_vm.hasEnded ? 'blue' : 'green'}`}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.duration))])],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"a-event-card__bottom"},[(!_vm.miniCard && _vm.speakers.length)?_c('div',{staticClass:"a-event-card__speakers"},[_c('p',{staticClass:"a-event-card__speakers-title"},[_vm._v("\n                        "+_vm._s(_vm.speakersText)+"\n                    ")]),_vm._v(" "),_c('ul',{staticClass:"a-event-card__first-speakers"},_vm._l((_vm.speakers),function(speaker){return _c('li',{key:speaker.name,staticClass:"a-event-card__first-speakers-item"},[_c('span',{staticClass:"a-event-card__first-speakers-name"},[_vm._v(_vm._s(speaker.name)+",")]),_vm._v(" "),(speaker.position)?_c('span',{staticClass:"a-event-card__first-speakers-position"},[_vm._v("\n                                "+_vm._s(speaker.position)+"\n                            ")]):_vm._e()])}),0)]):_vm._e(),_vm._v(" "),(_vm.miniCard)?_c('a-link',_vm._b({attrs:{"glyph":_vm.link.glyph || 'arrow',"text":_vm.link.text || 'Go to event',"size":_vm.link.size || 'paragraph'}},'a-link',_vm.link,false)):_c('a-button',_vm._b({staticClass:"a-event-card__link",attrs:{"type":_vm.getButtonLinkType(_vm.link),"size":_vm.link.size || 's',"glyph":_vm.link.glyph || 'arrow'}},'a-button',_vm.link,false))],1)])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }