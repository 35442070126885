<template>
    <div class="a-container">
        <section class="express-signup-main-footer" :class="type">
            <template v-if="type === 'question'">
                <div class="express-signup-main-footer__copyright single">
                    © {{ currentYear }} {{ copyright }}
                </div>
            </template>
            <template v-else>
                <a-logo class="express-signup-main-footer__logo" fill="dark" />
                <div v-if="copyright" class="express-signup-main-footer__copyright">
                    © {{ currentYear }} {{ copyright }}
                </div>
                <ul v-if="links" class="express-signup-main-footer__links">
                    <li
                        v-for="(link, index) in links"
                        :key="`express-signup-footer-link-${index}`"
                        class="express-signup-main-footer__links__item"
                    >
                        <a-link v-bind="link" />
                    </li>
                </ul>
            </template>
        </section>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';

export default {
    name: 'ExpressSignupFooter',
    components: {
        ALogo,
        ALink,
    },
    props: {
        type: {
            type: String,
            validator(value) {
                return ['normal', 'question', 'landing'].includes(value);
            },
            default: 'normal',
        },
        links: {
            type: Array,
            default: undefined,
        },
        copyright: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
};
</script>

<style lang="postcss" scoped>
.express-signup-main-footer {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    @mixin body;

    @media (--viewport-tablet) {
        justify-content: flex-start;
    }
    &__logo,
    &__copyright,
    &__links {
        flex-basis: 100%;
        @media (--viewport-tablet) {
            flex-basis: auto;
        }
    }
    &__logo {
        width: 32px;
        height: 32px;
        margin-inline-end: 16px;
    }
    &__copyright {
        color: var(--av-nav-primary);
        text-align: center;

        @media (--viewport-tablet) {
            text-align: start;
            margin: 0 16px 0 12px;
        }
        &.single {
            width: 100%;
            margin: 0;
            text-align: center;
        }
    }
    &__links {
        display: flex;
        justify-content: center;
        @media (--viewport-tablet) {
            justify-content: flex-start;
        }
        &__item {
            margin-inline-end: 12px;
            &:last-child {
                margin: 0;
            }
        }
    }
    &.normal {
        .express-signup-main-footer__copyright {
            margin-inline-end: auto;
        }
    }
}
</style>
