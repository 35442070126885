<template>
    <s-basic-slice :id="id" class="s-cards-product" :class="{ ...className, ...basicClassName }">
        <div class="s-cards-product__text">
            <a-slice-header :title="getTitle" :lead="getLead" />
            <a-link
                v-if="downloadFile"
                class="s-cards-product__download-file"
                v-bind="downloadFile"
                :to="downloadFile.link"
                :type="downloadFile.type || 'download-file'"
                :glyph="downloadFile.glyph || 'download-arrow'"
                :glyph-position="downloadFile.glyphPosition || 'left'"
                :text="downloadFile.title"
                :desc="downloadFile.desc"
            />
        </div>
        <ul class="s-cards-product__list">
            <li v-for="card in activeCards" :key="card.to" class="s-cards-product__item">
                <a-product-card v-bind="card">
                    <a-dangerous-html :content="card.text" />
                </a-product-card>
            </li>
        </ul>
        <a-link
            v-if="getLink"
            class="s-cards-product__link"
            v-bind="getLink"
            :type="getLink.type || 'direct'"
            :glyph="getLink.glyph || 'arrow'"
        />
    </s-basic-slice>
</template>

<script>
import { get, omit } from 'lodash';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import ALink from '@core/components/link/link.vue';
import AProductCard from '@core/components/product-card/product-card.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SCardsProduct',

    components: {
        ADangerousHtml,
        ALink,
        AProductCard,
        ASliceHeader,
        SBasicSlice,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        /**
         * Slice cards
         */
        cards: {
            type: Array,
            default: () => [],
        },

        /**
         * Optional link
         */
        link: {
            type: Object,
            required: false,
            default: undefined,
        },

        productId: {
            type: Number,
            required: false,
            default: undefined,
        },

        products: {
            type: Array,
            required: false,
            default: () => ([]),
        },

        /**
         * Download file
         */
        downloadFile: {
            type: Object,
            required: false,
            default: undefined,
        },

        /**
         * Row Layout
         */
        isRowLayout: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        product() {
            return get(this.$store.state.products, `items[${this.productId}].parameters.common`, {});
        },

        className() {
            return {
                's-cards-product_layout_row': this.isRowLayout,
                's-cards-product_layout_2-cards ':
                    !this.isRowLayout && [2, 4].includes(this.activeCards.length),
            };
        },
        activeCards() {
            return this.getCards.filter(Boolean);
        },
        getTitle() {
            return this.title || `Acronis ${get(this.product, 'productName', '')}`;
        },
        getLead() {
            return this.lead || get(this.product, 'card.text', '');
        },
        getLink() {
            if (this.link) {
                return this.link;
            }
            const to = get(this.product, 'learnLink.to', null);
            const text = get(this.product, 'learnLink.text', 'Learn more');

            if (!to) {
                return null;
            }

            return {
                to,
                text,
            };
        },
        getCards() {
            if (this.cards.length) {
                return this.cards.filter(Boolean);
            }

            return this.products.map((id) => {
                const params = get(this.$store.state.products, `items[${id}].parameters.common`, {});

                const card = omit(params.card, 'labelTarget');

                return {
                    ...card,
                    title: `Acronis ${params.productName}`,
                    learnLink: params.learnLink,
                };
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-cards-product {
    &__list {
        margin-bottom: -16px;
    }

    &__item {
        margin-bottom: 16px;
    }

    &__link {
        margin-top: 56px;
    }

    &__download-file {
        margin-bottom: 40px;
    }

    @media (--viewport-mobile-wide) {
        &__list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin: 0 -8px -16px;
        }

        &__item {
            margin: 0 0 16px;
            width: 50%;
            padding: 0 8px;
            display: flex;
        }

        &_layout {
            &_row {
                .a-slice-header {
                    max-width: 83.33%;
                }
            }
        }
    }

    @media (--viewport-desktop) {
        .a-slice-header {
            max-width: calc(75% - 16px);
        }

        &__item {
            width: 33.33%;
        }

        &__link {
            position: absolute;
            top: 17px;
            inset-inline-end: 32px;
            margin-top: 0;
            max-width: calc(25% - 16px);
        }

        &_layout {
            &_row {
                .a-slice-header {
                    width: 100%;

                    &__lead {
                        width: 100%;
                    }
                }

                &:deep(.a-container) {
                    display: grid;
                    grid-template-columns: calc(33.33% - 16px) 66.66%;
                    -ms-grid-columns: calc(33.33% - 16px) 16px 66.66%;
                    grid-template-rows: 1fr auto;
                    -ms-grid-rows: 1fr auto;
                    grid-column-gap: 16px;
                }

                .s-cards-product {
                    &__text {
                        -ms-grid-column: 1;
                    }

                    &__list {
                        -ms-grid-column: 3;
                        grid-row: span 2;
                        -ms-grid-row: 1;
                    }

                    &__item {
                        width: 50%;
                    }

                    &__link {
                        position: static;
                        max-width: 100%;
                        -ms-grid-column: 1;
                        -ms-grid-row: 2;
                    }
                }
            }

            &_2-cards {
                .s-cards-product {
                    &__list {
                        max-width: 66.66%;
                    }

                    &__item {
                        width: 50%;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop-wide) {
        .a-slice-header {
            max-width: calc(66.66% - 16px);
        }

        &_layout {
            &_row {
                .a-slice-header {
                    max-width: 100%;
                }
            }
        }

        &__link {
            top: 16px;
            inset-inline-end: 74px;
        }
    }

    @media (--viewport-desktop-large) {
        &__link {
            top: 15px;
            inset-inline-end: 6px;
        }
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        &_layout_with-link {
            &:deep(.a-container) {
                display: block;
            }
        }
    }
}
</style>
