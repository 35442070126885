<template>
    <a-accordion ref="filters" class="filters-accordion" @changedActive="onCollapse">
        <a-accordion-item v-if="isDesktop && suggestions.length" class="suggestions" active>
            <template #title>
                <a-dangerous-html :content="dictionaryText.topSuggestions" />
                <a-glyph class="a-accordion__icon" name="chevron-down" />
            </template>

            <ul>
                <li
                    v-for="link in suggestions"
                    :key="`suggestions-filter-${link.id}`"
                >
                    <a-link
                        class="suggestion-link"
                        glyph-position="left"
                        size="paragraph"
                        :text="link.text"
                        @click="selectProduct(link.text)"
                    />
                </li>
            </ul>
        </a-accordion-item>

        <a-accordion-item class="product-groups" active>
            <template #title>
                <a-dangerous-html :content="dictionaryText.productGroups" />
                <a-glyph class="a-accordion__icon" name="chevron-down" />
            </template>

            <ul>
                <li
                    v-for="(productGroup) in productGroups"
                    :key="`group-${productGroup.id}`"
                    class="product-group"
                >
                    <el-radio
                        v-model="computedProductGroup"
                        :label="productGroup.id"
                        :disabled="!groupsWithProducts.includes(productGroup.id)"
                        @change="selectProductGroup(productGroup.id)"
                    >
                        {{ productGroup.caption }}
                    </el-radio>
                </li>
            </ul>
        </a-accordion-item>

        <a-accordion-item class="related-resources">
            <template #title>
                <a-dangerous-html :content="dictionaryText.relatedResources" />
                <a-glyph class="a-accordion__icon" name="chevron-down" />
            </template>

            <ul>
                <li
                    v-for="(link, index) in relatedResources"
                    :key="`resource-filter-${index}`"
                >
                    <a-link
                        :key="`resource-filter-link-${index}`"
                        :to="link.link"
                        :glyph="link.glyph"
                        :class="link.class || ''"
                        :glyph-position="link.glyphPosition || 'left'"
                        :size="link.size || 'paragraph'"
                        :text="link.text"
                    />
                </li>
            </ul>
        </a-accordion-item>
    </a-accordion>
</template>

<script>
import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint';

export default {
    name: 'SFilters',
    components: {
        AAccordionItem,
        AAccordion,
        ADangerousHtml,
        AGlyph,
        ALink,
        ElRadio: () => import('@uikit/ui-kit/packages/radio'),
    },
    mixins: [breakpoint],
    props: {
        productGroups: {
            type: Array,
            default: () => ([]),
        },
        suggestions: {
            type: Array,
            default: () => ([]),
        },
        relatedResources: {
            type: Array,
            default: () => ([]),
        },
        groupsWithProducts: {
            type: Array,
            default: () => ([]),
        },
        dictionary: {
            type: Object,
            required: true,
        },
    },
    emits: ['group-changed', 'product-changed', 'accordionCollapse'],
    data() {
        return {
            selectedProductGroup: '',
            selectedProduct: '',
        };
    },
    computed: {
        dictionaryText() {
            return {
                relatedResources: this.dictionary['Related Resources'] || 'Related Resources',
                topSuggestions: this.dictionary['Top Suggestions'] || 'Top Suggestions',
                productGroups: this.dictionary['Product Groups'] || 'Product Groups',
            };
        },
        computedProductGroup: {
            get() {
                const params = new URLSearchParams(this.$route.hash.slice(1));
                const group = params.get('group');
                if (!group?.length) return 'all-products';
                return params.get('group');
            },
            set(newValue) {
                this.selectedProductGroup = newValue;
            },
        },
    },
    methods: {
        selectProduct(event) {
            this.$emit('product-changed', event);
        },
        selectProductGroup(event) {
            this.$emit('group-changed', event);
        },
        onCollapse(event) {
            this.$emit('accordionCollapse', event);
        },
    },
};
</script>

<style lang="postcss" scoped>
.suggestion-link {
    @mixin paragraph;
}

.filters-accordion {
    &:deep(.title) {
        &:active {
            color: var(--av-nav-primary);
        }
        padding: 0;
        display: flex;
        align-items: center;
    }

    &:deep(.a-accordion-item) {
        border-top: none;
        &.active {
            margin-bottom: 20px;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &:deep(.a-accordion__icon) {
        height: 16px;
        width: 16px;
    }

    &:deep(.a-accordion-item.active .a-accordion__icon) {
        transform: rotate(180deg);
    }

    &:deep(.content-wrapper) {
        padding: 0 4px;
    }
}

.product-group {
    display: flex;
    align-items: center;
    .el-radio {
        margin-inline-end: 8px;
    }
}
</style>
