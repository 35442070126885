<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import structuredData from '@core/mixins/structuredData';
import { LANGUAGES_MAP } from '@model/const/events';
import * as settings from '@model/const/events';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { HttpRejection } from '@model/http/rejection';
import { assertHttpErrors } from '@utils/api-response';
import { ACRONIS_OG_IMAGE_ID, getOgImage } from '@utils/html-meta';
import EventComponent from './component.vue';

export default Vue.extend({
    name: 'EventContainer',

    mixins: [structuredData],

    async serverPrefetch(): Promise<void> {
        // ==== Init
        const countryId = parseInt(this.$ssrContext.req.cookies.events_country, 10);
        const { slug, key } = this.$route.params;

        // ==== Get event and determine if we should proceed
        await this.loadEvent({ slug, key });

        const eventState = this.$store.state.events.event;

        assertHttpErrors([{ entity: eventState, throwIfEmpty: true }]);

        const event = eventState.items[0];

        if (event.is_published && key) {
            const fixedURL = `/event/${slug}/`;
            const rejection = new HttpRejection('Premature rendering stop', StatusCodes.MOVED_PERMANENTLY);
            rejection.addHeader('Location', fixedURL);
            throw rejection;
        }

        // Get the rest of entities
        if (key) {
            this.$ssrContext.res.headers.push({ key: 'Cache-Control', val: 'no-store' });
        }

        const audiencesQuery = new ProductAPIQueryBuilder('audiences')
            .setEntityPath('/api/events/audiences/')
            .setPaginate(1, settings.AUDIENCES_COUNT)
            .toObject();

        const locale = LANGUAGES_MAP[event.language.name];
        const promises = [
            this.$store.dispatch('slices/getSyncedData', { slice: 's-preset-form-events', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'a-phone-number-input', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-list-form-elements', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-global-footer', locale: LOCALE_DEFAULT }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-product-reviews-id-map', locale: LOCALE_DEFAULT }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'events', locale: LOCALE_DEFAULT }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'events-form', locale: LOCALE_DEFAULT }),
            this.$store.dispatch('events/getEntity', { request: audiencesQuery }),
            this.$store.dispatch('countries/getCountries'),
        ];

        if (!Number.isNaN(countryId)) {
            promises.push(this.$store.dispatch('events/setCountry', { countryId, isConfirmed: true }));
        }

        // SEO schemas
        const seoLocale = 'en-us';
        promises.push(
            this.$store.dispatch('seo/getSyncedData', { key: 'routes-config', locale: seoLocale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-webpage', locale: seoLocale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-event', locale: seoLocale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-breadcrumbs', locale: seoLocale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-faq-page', locale: seoLocale }),
        );

        await Promise.all(promises);

        const eventLanguage = this.$store.state.events?.event?.items?.[0]?.language?.name || 'English';
        const eventLocale = this.$store.state.slices?.items?.['events-form']?.[eventLanguage]?.localeEvent;

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 's-list-form-elements', locale: eventLocale || LOCALE_DEFAULT }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-form-global-settings', locale: LOCALE_DEFAULT }),
        ]);

        assertHttpErrors([
            { entity: this.$store.state.events.audiences },
            { entity: this.$store.state.countries },
        ]);

        // Building page meta
        this.$ssrContext.res.meta = await this.getMeta(event);
    },

    computed: {
        ...mapGetters('config', ['$config']),
        ...mapGetters('seo', ['getRoutesConfig', 'getStaticConfigs']),
    },

    methods: {
        async getMeta(event: Record<string, any>): any {
            const canonical = `https://${this.$config.domain}${this.$route.path}`;
            const imageId = `@${event.cover?.image_id || ACRONIS_OG_IMAGE_ID}`;
            const ogImage = getOgImage(imageId, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = event.seo_title || event.name;
            const description = event.seo_description;
            const isIndexed = event.is_indexed;

            const ldJsonSchema = await this.getStructuredDataMarkup({
                siteID: this.$config.siteID,
                domain: this.$config.domain,
                schemasConfig: this.getStaticConfigs,
                routesConfig: this.getRoutesConfig,
            });

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogImage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogImage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    isIndexed ? null : { tag: 'meta', name: 'robots', content: 'noindex, nofollow' },
                    { tag: 'link', rel: 'image_src', href: ogImage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                ],
                htmlAttrs: {
                    lang: LANGUAGES_MAP[event.language.name],
                },
                ldJsonSchema,
            };
        },

        loadEvent(searchQuery: any) {
            const request = new ProductAPIQueryBuilder('event')
                .setEntityPath('/api/events/events/')
                .setCustomParam('key', searchQuery.key)
                .setCustomParam('process-macros', '1')
                .addMatchesAll('slug', '=', searchQuery.slug)
                .setPaginate(1, 1)
                .toObject();

            return this.$store.dispatch('events/getEntity', { request });
        },

        loadOtherEvents(searchQuery: Record<string, any>) {
            const hasCriteria: Record<string, any> = {
                countries: [searchQuery.countryId],
            };

            if (searchQuery.audiences?.length > 0) {
                hasCriteria.audiences = searchQuery.audiences.map((item) => item.id);
            }
            if (searchQuery.category) {
                hasCriteria.categories = [searchQuery.category];
            }

            const requestBuilder = new ProductAPIQueryBuilder('otherEvents')
                .setEntityPath('/api/events/events/')
                .addMatchesAll('is_in_person', '=', searchQuery.is_in_person ? '1' : '0')
                .addMatchesAll('is_virtual', '=', searchQuery.is_virtual ? '1' : '0')
                .addMatchesAll('is_past', '=', searchQuery.is_past ? '1' : '0')
                .addMatchesAll('is_on_demand', '=', searchQuery.is_on_demand ? '1' : '0');

            if (!searchQuery.is_on_demand && !searchQuery.is_past) {
                requestBuilder.setCustomParam('has-occurrences', { from: new Date().toISOString() });
            }

            requestBuilder
                .setCustomParam('has', hasCriteria)
                .setCustomParam('process-macros', '1')
                .addMatchesAll('slug', '<>', searchQuery.slug)
                .addMatchesAll('is_for_partners', '<>', '1')
                .addSort('is_featured', 'desc')
                .addSort('dynamic_next_occurrence', 'asc')
                .setPaginate(1, settings.OTHER_EVENTS_COUNT);

            const request = requestBuilder.toObject();

            return this.$store.dispatch('events/getEntity', { request });
        },
    },

    render(h) {
        const props = { dispatchLoadOtherEvents: this.loadOtherEvents };
        return h(EventComponent, { props });
    },
});
</script>
