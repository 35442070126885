<template>
    <div class="advantages">
        <div class="a-container advantages__wrap">
            <div class="advantages__col">
                <div class="advantages__title">
                    {{ title }}
                </div>
                <div class="advantages__text">
                    {{ text }}
                </div>
            </div>
            <a-video-card
                v-if="video"
                class="advantages__video"
                :yt-id="video.ytId"
                :time="video.time"
                :image="video.image"
            />
            <a-picture v-else class="advantages__image" :link="image" />
            <div class="advantages__subtitle">
                {{ subtitle }}
            </div>
            <div class="advantages__items">
                <div v-for="(item, index) in items" :key="index" class="advantages__item">
                    <a-glyph class="advantages__item-icon" :name="item.icon" />
                    <div class="advantages__item-name">
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <div class="advantages__links">
                <a-button
                    v-if="btn"
                    v-bind="btn"
                    :size="btn.size || 's'"
                    :type="btn.type || 'action'"
                />
                <a-link
                    v-if="link"
                    v-bind="link"
                    :glyph="link.glyph || 'arrow'"
                    :type="link.type || 'light'"
                    :size="link.size || 'lead'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import AVideoCard from '@core/components/video-card/video-card.vue';

export default {
    name: 'SSportAdvantages',
    components: {
        ALink,
        AVideoCard,
        AGlyph,
        AButton,
        APicture,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        link: {
            type: Object,
            required: true,
        },
        btn: {
            type: Object,
            required: true,
        },
        video: {
            type: Object,
            default: null,
        },
        image: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.advantages {
    padding-top: 64px;
    padding-bottom: 80px;
    background: linear-gradient(360deg, #15316f 0%, #00204d 100%);

    @media (--viewport-tablet) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (--viewport-desktop) {
        padding-bottom: 100px;
    }

    &__title {
        font-weight: 500;
        color: var(--av-inversed-primary);
        @mixin display;
        margin-bottom: 32px;
        text-align: center;

        @media (--viewport-tablet) {
            margin-bottom: 16px;
            text-align: start;
        }
        @media (--viewport-desktop) {
            @mixin hero;
            margin-bottom: 24px;
        }
    }

    &__text {
        @mixin body;
        color: var(--av-inversed-primary);
        text-align: center;
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            margin-bottom: 0;
            text-align: start;
        }

        @media (--viewport-desktop) {
            @mixin paragraph;
        }
    }

    &__video,
    &__image {
        height: 213px;
        padding: 0;
        border-radius: 4px;

        @media (--viewport-tablet) {
            max-width: 344px;
        }
        @media (--viewport-desktop) {
            max-width: 391px;
            margin-top: 45px;
        }

        @media (--viewport-desktop-wide) {
            max-width: 475px;
            margin-top: 0;
            height: 257px;
        }
    }

    &__image {
        &:deep(.a-picture__img) {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__col {
        padding-bottom: 20px;
        @media (--viewport-tablet) {
            max-width: 50%;
            margin-inline-end: 8px;
        }
        @media (--viewport-desktop) {
            max-width: 55%;
            margin-inline-end: 21px;
            padding-bottom: 40px;
        }
        @media (--viewport-desktop-large) {
            max-width: 50%;
            margin-inline-end: 100px;
        }
    }

    &__wrap {
        @media (--viewport-tablet) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        @media (--viewport-desktop-large) {
            justify-content: flex-start;
        }
    }
    &__subtitle {
        text-align: center;
        margin-top: 40px;
        @mixin nav-title-accent;
        font-weight: 500;
        color: var(--av-inversed-primary);
        width: 100%;

        @media (--viewport-tablet) {
            margin-top: 48px;
        }
        @media (--viewport-desktop) {
            text-align: start;
        }
    }
    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;
        @media (--viewport-tablet) {
            margin-top: 23px;
        }
        @media (--viewport-desktop) {
            margin-top: 40px;
        }
    }
    &__item {
        width: 50%;
        text-align: center;
        margin-bottom: 48px;
        &:last-child {
            margin-bottom: 0;
        }
        @media (--viewport-tablet) {
            width: calc(100% / 3);
            margin-bottom: 16px;
        }
        @media (--viewport-desktop) {
            width: calc(100% / 5);
            margin-bottom: 0;
        }
        &-icon {
            width: 48px;
            height: 48px;
            margin-bottom: 16px;
            @media (--viewport-desktop-wide) {
                margin-bottom: 18px;
            }
        }
        &-name {
            color: var(--av-inversed-primary);
            font-weight: 500;
            @mixin body;
            max-width: 90%;
            margin: auto;

            @media (--viewport-tablet) {
                max-width: 80%;
            }
            @media (--viewport-desktop-wide) {
                max-width: 100%;
            }
        }
    }
    &__links {
        margin-top: 56px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        flex-direction: column;

        @media (--viewport-tablet) {
            flex-direction: inherit;
        }

        @media (--viewport-desktop) {
            margin-top: 48px;
            justify-content: flex-start;
        }

        .a-button {
            min-width: 156px;
            margin-bottom: 40px;
            @media (--viewport-tablet) {
                margin-bottom: 0;
            }
        }
        .a-link {
            margin-inline-start: 0;
            @mixin lead;

            @media (--viewport-tablet) {
                margin-inline-start: 48px;
            }
        }
    }
}
</style>
