<template>
    <div class="integration-company">
        <div class="a-container cols">
            <div v-if="companyLinks" class="col">
                <h3 class="title">
                    {{ companyTitle }}
                </h3>
                <div v-for="link in companyLinks" :key="link.to" class="item">
                    <a-link
                        class="link"
                        v-bind="link"
                        :to="link.url"
                        :text="link.label"
                    />
                </div>
            </div>
            <div v-if="supportLinks" class="col">
                <h3 class="title">
                    {{ supportTitle }}
                </h3>
                <div v-for="link in supportLinks" :key="link.text" class="item">
                    <a-link
                        class="link"
                        v-bind="link"
                        :to="link.url"
                        :text="link.label"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';

export default {
    name: 'SIntegrationCompany',

    components: {
        ALink,
    },

    props: {
        companyTitle: {
            type: String,
            default: 'Company information',
        },

        supportTitle: {
            type: String,
            default: 'Support contacts',
        },

        companyLinks: {
            type: Array,
            default: null,
        },

        supportLinks: {
            type: Array,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.integration-company {
    padding-block: 24px;
}

.cols {
    width: 100%;
    display: grid;
    gap: 24px;

    @media (--viewport-tablet) {
        gap: 32px;
        grid-template-columns: 1fr 1fr;
    }
}

.title {
    @mixin title;

    margin-bottom: 16px;
}

.item {
    @mixin body;

    &:not(:last-child) {
        margin-bottom: 8px;
    }
}

.link {
    @mixin body-accent;
}

</style>
