<template>
    <resource-page class="resource-center-page--no-border">
        <div v-if="isPreview" class="draft-warning-banner">
            <a-glyph name="attention2" />
            <span>DRAFT: DO NOT publish or share externally. Content not finalized</span>
            <a-glyph name="attention2" />
        </div>
        <s-resource-gated-asset
            v-if="resource.is_gated"
            :resource="resource"
            :breadcrumbs="breadcrumbs"
            :asset-type="assetType"
            :socials-share-links="socialsShareLinks"
            :page-type="pageType"
            :common-translations="commonTranslations"
            :ga-social-event="gaSocialEvent"
        />
        <s-resource-ungated-asset
            v-else
            :resource="resource"
            :socials-share-links="socialsShareLinks"
            :tags="tags"
            :asset-type="assetType"
            :breadcrumbs="breadcrumbs"
            :cta-title="ctaTitle"
            :cta-data="ctaData"
            :page-type="pageType"
            :common-translations="commonTranslations"
            :ga-social-event="gaSocialEvent"
        />
    </resource-page>
</template>

<script lang="ts">
import AGlyph from '@core/components/glyph/glyph.vue';
import SResourceGatedAsset from '@core/slices/resource-center/resource-gated-asset/resource-gated-asset.vue';
import SResourceUngatedAsset from '@core/slices/resource-center/resource-ungated-asset/resource-ungated-asset.vue';
import ResourcePage from '@core/slices/resource-center/shared-components/resource-page.vue';
import { RC_EMPTY_BREADCRUMB, RC_ROOT_BREADCRUMB, RC_ROOT_URL, GA_SOCIAL_EVENT } from '@model/const/resource-center';
import socials from '@model/const/socials';

export default {
    name: 'RcResource',

    components: {
        AGlyph,
        SResourceGatedAsset,
        SResourceUngatedAsset,
        ResourcePage,
    },

    props: {
        dispatchSendViewBeacon: {
            type: Function,
            required: true,
        },
        isPreview: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        gaSocialEvent() {
            return GA_SOCIAL_EVENT;
        },

        locale() {
            return this.$route.params.locale;
        },

        resource() {
            return this.$store.state.resourceCenter.resource.items?.[0] || {};
        },

        commonTranslations() {
            return this.$store.state.slices.items['resource-center'] || {};
        },

        socialsShareLinks() {
            const title = '';
            const url = typeof window !== 'undefined' ? window.location : '';

            return socials.share.map((item) => ({
                name: item.name,
                link: item.link(encodeURIComponent(`${url}`), encodeURIComponent(title)),
            }));
        },

        tags() {
            return this.resource?.tags.map((tag) => ({
                to: `${RC_ROOT_URL}/tag/${tag.slug}/`,
                text: tag.title,
            }));
        },

        assetType() {
            return {
                text: this.resource.type.title,
                to: `${RC_ROOT_URL}/category/${this.resource.type.slug}/`,
            };
        },

        breadcrumbs() {
            const rootBreadcrumb = {
                ...RC_ROOT_BREADCRUMB,
                text: this.commonTranslations.homePageBreadcrumb || RC_ROOT_BREADCRUMB.text,
            };

            return [
                rootBreadcrumb,
                {
                    sublinks: true,
                },
                {
                    text: this.assetType.text,
                    to: this.assetType.to,
                },
                RC_EMPTY_BREADCRUMB,
            ];
        },

        cta() {
            return this.$store.state.resourceCenter.cta?.items?.[0];
        },

        ctaTitle() {
            const productId = this.$store.state.resourceCenter.cta.items[0]?.product_id;
            return this.$store.state.products.items[productId]?.name || '';
        },

        ctaData() {
            return this.$store.state.resourceCenter.ctaData?.items?.[0];
        },

        pageType() {
            if (this.resource.url?.includes('youtube')) return 'video';
            // otherwise it is pdf`s id or link
            return 'pdf';
        },
    },

    mounted() {
        this.dispatchSendViewBeacon();
    },
};
</script>
