import { render, staticRenderFns } from "./partners.vue?vue&type=template&id=f853636c&scoped=true"
import script from "./partners.vue?vue&type=script&lang=js"
export * from "./partners.vue?vue&type=script&lang=js"
import style0 from "./partners.vue?vue&type=style&index=0&id=f853636c&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f853636c",
  null
  
)

export default component.exports