<template>
    <section v-if="isVisible" class="main-page-header">
        <div class="a-container">
            <div class="wrapper">
                <div class="content">
                    <s-main-page-tag v-bind="tag({name: 'title'})" />
                    <s-main-page-tag v-bind="tag({name: 'description', isDangerous: true})" />
                    <div class="popup-container">
                        <s-main-page-cta :content="content.cta" @click="onClick" />
                        <template v-if="content.popup?.length">
                            <ul v-show="popup.isActive" ref="popup" :class="className">
                                <li v-for="(item, itemIndex) in content.popup" :key="itemIndex" class="popup-item">
                                    <p v-if="item.title" class="popup-title" v-html="item.title" />
                                    <p v-if="item.description" class="popup-description" v-html="item.description" />
                                    <a-link v-if="item.link" class="popup-link" v-bind="item.link" />
                                </li>
                            </ul>
                        </template>
                    </div>
                </div>
                <a-media v-if="content.media" v-bind="content.media" class="media" />
            </div>
        </div>
        <a-picture v-if="content.picture" class="background" v-bind="content.picture" />
    </section>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import AMedia from '@core/components/media/media.vue';
import APicture from '@core/components/picture/picture.vue';
import SMainPageCta from '../../components/cta.vue';
import mixin from '../../components/mixin.js';
import SMainPageTag from '../../components/tag.vue';

export default {
    name: 'SMainPageHeaderVariant3',
    components: {
        ALink,
        AMedia,
        APicture,
        SMainPageCta,
        SMainPageTag,
    },
    mixins: [mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            popup: {
                position: 'top',
                isActive: false,
            },
        };
    },
    computed: {
        isVisible() {
            return this.content?.title || this.content?.description;
        },
        className() {
            return ['popup', `popup-position-${this.popup.position}`];
        },
    },
    methods: {
        closePopup() {
            this.popup.isActive = false;
            this.popup.position = 'top';
            window.removeEventListener('resize', this.closePopup);
            window.removeEventListener('scroll', this.closePopup);
            window.removeEventListener('click', this.closePopup, true);
        },
        async onClick() {
            this.popup.position = 'top';
            this.popup.isActive = !this.popup.isActive;
            if (!this.popup.isActive) return;
            await this.$nextTick();
            const element = this.$refs.popup;
            this.popup.position = element.getBoundingClientRect().top > 0 ? 'top' : 'bottom';
            window.addEventListener('resize', this.closePopup);
            window.addEventListener('scroll', this.closePopup);
            window.addEventListener('click', this.closePopup, true);
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-header {
    position: relative;
    padding: 104px 0 0;
    @media (--viewport-desktop) {
        padding-top: 128px;
    }
    @media (--viewport-desktop-wide) {
        padding-top: 112px;
    }
    .wrapper {
        gap: 32px;
        display: flex;
        flex-direction: column;
        @media (--viewport-desktop) {
            flex-direction: row;
            align-items: center;
        }
    }
    .content {
        flex: 1 1 0;
        text-align: center;
        @media (--viewport-desktop) {
            text-align: start;
        }
        .title {
            @mixin display-accent;
            font-weight: 700;
            color: var(--av-nav-primary);
            @media (--viewport-tablet) {
                @mixin hero;
            }
            @media (--viewport-desktop-large) {
                @mixin large;
            }
        }
        .description {
            @mixin title;
            margin-top: 24px;
            color: var(--av-fixed-secondary);
            @media (--viewport-desktop-large) {
                @mixin display;
            }
        }
        .main-page-cta {
            @media (--viewport-tablet) {
                flex-direction: column;
            }
            @media (--viewport-desktop) {
                flex-direction: row;
            }
            &:deep(.a-button) {
                width: auto;
                margin-top: 24px;
                min-width: 200px;
            }
        }
        .popup-container {
            position: relative;
        }
        .popup {
            width: 248px;
            z-index: 401;
            margin: 16px 0;
            padding: 8px 0;
            text-align: start;
            position: absolute;
            border-radius: 4px;
            background: var(--av-inversed-primary);
            margin-inline-start: calc(50% - 124px);
            border: 1px solid var(--av-brand-primary);
            box-shadow: 0 10px 20px 0 rgba(36, 49, 67, 0.2);
            @media (--viewport-desktop) {
                margin-inline-start: 0;
            }
            .popup-item {
                position: relative;
                padding:  8px 16px;
            }
            .popup-title {
                @mixin paragraph-accent;
                color: #2668c5;
            }
            .popup-description {
                @mixin caption;
                color: var(--av-fixed-primary);
            }
            .popup-link {
                top: 0;
                inset-inline-start: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                &:hover {
                    background: var(--el-secondary-hover);
                }
            }
        }
        .popup-position-top {
            bottom: 48px;
        }
        .popup-position-bottom {
            bottom: auto;
        }
    }
    .media {
        @mixin colls 10;
        margin: 0 auto;
        @media (--viewport-desktop) {
            @mixin colls 7;
        }
        @media (--viewport-desktop-large) {
            width: 692px;
        }
    }
    &:deep(.a-container) {
        position: unset;
    }
    .background {
        z-index: -1;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        inset-inline-start: 0;
        @media (--viewport-desktop-large) {
            height: 912px;
        }
        &:deep(.a-picture__img) {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
