<template>
    <div class="page-container">
        <div class="page">
            <s-page-not-found />
        </div>
    </div>
</template>

<script>
import analytics from '@core/mixins/analytics.js';
import SPageNotFound from '@core/slices/trueimage/page-not-found/page-not-found.vue';

export default {
    name: 'Error404Component',

    components: {
        SPageNotFound,
    },

    mixins: [analytics],
};
</script>

<style lang="postcss" scoped>
.page {
    position: relative;
}
</style>
