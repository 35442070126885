<template>
    <div class="solutions-card" :class="{ 'solutions-card_link': link }">
        <a v-if="link" class="solutions-card__link-main" :href="link.to" />
        <div class="solutions-card__title">
            {{ title }}
        </div>
        <div class="solutions-card__text">
            {{ text }}
        </div>
        <div v-if="link" class="solutions-card__link">
            <a-link v-bind="link" :glyph="link.glyph || 'arrow'" />
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';

export default {
    name: 'SSolutionsCard',
    components: {
        ALink,
    },
    props: {
        link: {
            type: Object,
            default: undefined,
        },

        title: {
            type: String,
            required: true,
        },

        text: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.solutions-cards {
    background: var(--av-inversed-primary);
    border-radius: 4px;
    padding: 16px;
    position: relative;
    height: 100%;

    @media (--viewport-desktop) {
        padding: 24px;
    }

    &_link {
        &:hover {
            background: var(--av-brand-secondary-accent);
        }

        .solutions-card__title {
            color: var(--av-nav-secondary);
        }
    }

    &__title {
        margin-bottom: 8px;
        @mixin paragraph;
        color: var(--av-nav-primary);
        font-weight: 600;
    }
    &__text {
        @mixin body;
        color: var(--av-fixed-secondary);
    }
    &__link {
        margin-top: 24px;
        @mixin body-accent;
        &-main {
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            inset-inline-end: 0;
            bottom: 0;
        }
    }
}
</style>
