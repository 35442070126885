<template>
    <div class="event-banner-slider">
        <a-slider
            class="event-slider"
            :theme="theme"
            pagination-alignment="center"
            :options="sliderOptions"
        >
            <a-slide v-for="(banner, i) in banners" :key="i" class="event-slide">
                <event-banner v-bind="banner" />
            </a-slide>
        </a-slider>
    </div>
</template>

<script>
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import EventBanner from '../event-banner/event-banner.vue';

export default {
    name: 'EventBannerSlider',

    components: {
        ASlider,
        ASlide,
        EventBanner,
    },

    props: {
        theme: {
            type: String,
            default: 'light',
            validator: (value) => !value || ['light', 'dark'].includes(value),
        },
        swapSpeed: {
            type: Number,
            default: 550,
        },
        banners: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            sliderOptions: {
                spaceBetween: 0,
                slidesPerView: 1,
                observer: true,
                observeParents: true,
                watchOverflow: false,
                showNavigation: true,
                useAutoplay: true,
                slideChangeSeconds: 4,
                useLoop: true,
                useBarsPagination: true,
                speed: this.swapSpeed,
            },
        };
    },
};
</script>

<style lang="postcss" scoped>
.event-banner-slider {
    overflow: hidden;

    .a-slider {
        overflow: visible;

        &__nav {
            margin-top: 40px;
            margin-bottom: 0;
        }
    }

    .a-slide {
        width: 100%;

        &:not(.a-slide_active) {
            opacity: 0.8;
            cursor: pointer;
        }
    }
}
</style>
