<template>
    <div data-client-only="true">
        <slot v-if="selectedSlot === 'default'" />
        <slot v-if="selectedSlot === 'placeholder'" name="placeholder" />
    </div>
</template>

<script>
// WARN: we can't use template on the root level; it's disalowed by Vue
// we can't use <component> because it renders as is
// we can't use vue-fragment because it doesn't work well with slots
// we can only use root level div, but it implicitly adds a div in <client-only> component, which might be a problem sometimes
export default {
    name: 'ClientOnly',

    data() {
        return {
            selectedSlot: 'placeholder',
        };
    },

    mounted() {
        this.selectedSlot = 'default';
    },
};
</script>
