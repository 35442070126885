/**
 * Button types
 *
 * @return {string[]}
 */
export const TYPES = ['action', 'main', 'secondary', 'inverse'];

export const SIZES = ['m', 's'];

export default {};
