<template>
    <div class="event-resource">
        <component
            :is="locked ? 'div' : 'a'"
            class="event-link link"
            :href="link"
            download
            target="_blank"
        >
            <div v-if="locked" v-modal="'resource-access-form'" class="locked" />
            <div class="title-wrap">
                <div class="type">
                    {{ type }}
                </div>
                <div class="title">
                    {{ title }}
                </div>
            </div>
            <div class="download">
                <a-glyph class="glyph" name="download-arrow" />
                <span class="download-label">{{ downloadLabel }}</span>
                <span class="download-type">{{ downloadDetails }}</span>
            </div>
        </component>
    </div>
</template>
<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import Modal from '@core/directives/modal.js';

export default {
    name: 'EventResources',

    components: {
        AGlyph,
    },

    directives: {
        Modal,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        link: {
            type: String,
            required: true,
        },

        type: {
            type: String,
            required: true,
        },

        downloadLabel: {
            type: String,
            required: true,
        },

        downloadDetails: {
            type: String,
            default: null,
        },

        locked: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-resource {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background: var(--av-inversed-primary);
    border: 1px solid var(--av-brand-light);
    border-radius: 4px;
    min-height: 200px;

    @media (--viewport-tablet) {
        min-height: 264px;
    }

    .link {
        cursor: pointer;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;

        &:hover {
            .title {
                color: var(--av-brand-secondary);
            }
            .glyph {
                fill: var(--av-brand-secondary);
            }
        }
    }

    .locked {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        height: 100%;
        width: 100%;
    }

    .title-wrap {
        padding: 24px;
    }

    .type {
        @mixin caption;
        font-weight: 500;
        color: var(--av-fixed-light);
        margin-bottom: 8px;
        text-transform: uppercase;
    }

    .title {
        @mixin lead-strong;
        color: var(--av-nav-primary);

        @media (--viewport-tablet) {
            @mixin title-accent;
        }

        @media (--viewport-desktop) {
            @mixin title;
        }
    }

    .download {
        @mixin paragraph;
        text-decoration: none;
        border-top: 1px solid var(--av-brand-accent);
        font-size: 16px;
        line-height: 16px;
        padding: 16px 24px;
        margin-top: auto;
        color: var(--av-brand-secondary);

        @media (--viewport-tablet) {
            padding: 24px;
        }
    }

    .download-label {
        display: inline-block;
        font-weight: 700;
        margin: 0 8px;
    }

    .download-type {
        display: inline-block;
        white-space: nowrap;
        color: var(--av-fixed-light);

        @media (--viewport-tablet) {
            margin-top: 4px;
        }

        @media (--viewport-desktop) {
            margin-top: 0;
        }
    }

    .event-link .glyph {
        margin-inline-start: 0;
    }
}
</style>
