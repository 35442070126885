/**
 * Global Footer types
 * Default variant - short
 * short - only copyright + social buttons
 * copyright - only copyright
 * withNav - copyright + additional navigation
 * @return {string[]}
 */
export const TYPES = ['withNav', 'short', 'copyright'];

/**
 * Global Footer versions
 *
 * @return {string[]}
 */
export const THEMES = ['dark', 'light'];

export default {};
