<template>
    <s-basic-slice class="s-partner-logos" :class="className" v-bind="$attrs">
        <ul class="s-partner-logos__list">
            <li v-for="(item, index) in partnerLogos" :key="index" class="s-partner-logos__item">
                <a-picture
                    class="s-partner-logos__img"
                    :link="item.image"
                    :alt="item.imageAlt"
                    :class="{ 's-partner-logos__img_mono': item.mono }"
                />
            </li>
        </ul>
        <a-link
            v-if="link"
            class="s-partner-logos__link"
            v-bind="link"
            :type="link.type || 'direct'"
            :glyph="link.glyph || 'arrow'"
        />
    </s-basic-slice>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SPartnerLogos',

    components: {
        SBasicSlice,
        ALink,
        APicture,
    },

    inheritAttrs: false,

    props: {
        link: {
            type: Object,
            required: false,
            default: undefined,
        },

        partnerLogos: {
            type: Array,
            required: true,
        },
    },

    computed: {
        className() {
            return {
                's-partner-logos_layout_11-18-logos':
                    this.partnerLogos.length >= 11 && this.partnerLogos.length <= 18,
                's-partner-logos_layout_9-10-logos':
                    this.partnerLogos.length >= 9 && this.partnerLogos.length <= 10,
                's-partner-logos_layout_7-8-logos':
                    this.partnerLogos.length >= 7 && this.partnerLogos.length <= 8,
                's-partner-logos_layout_6-logos': this.partnerLogos.length === 6,
                's-partner-logos_layout_5-logos': this.partnerLogos.length === 5,
                's-partner-logos_layout_2-4-logos':
                    this.partnerLogos.length >= 2 && this.partnerLogos.length <= 4,
                's-partner-logos_link-position_bottom': !this.$attrs.title && !this.$attrs.lead,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-partner-logos {
    &__list {
        margin: 0 -20px -32px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    &__item {
        margin: 0 20px 32px;
        display: flex;
        align-items: center;
        width: 104px;
        height: 32px;
    }

    &__img {
        flex-shrink: 0;
        max-width: 100%;
        height: 100%;

        &.s-partner-logos__img_mono {
            opacity: 0.5;
        }
    }

    &__link {
        margin-top: 56px;
    }

    @media (--viewport-mobile-wide) {
        padding-top: 48px;

        &_layout_9-10-logos {
            .s-partner-logos__list {
                max-width: 725px;
            }
        }

        &_layout_7-8-logos {
            .s-partner-logos__list {
                max-width: 640px;
            }
        }

        &_layout_6-logos {
            .s-partner-logos__list {
                max-width: 640px;
            }
        }
    }

    @media (--viewport-desktop) {
        padding-top: 64px;

        &_link-position {
            &_bottom {
                .s-partner-logos {
                    &__list {
                        margin: 0 -32px -32px;
                    }

                    &__link {
                        margin-top: 56px;
                        position: static;
                    }
                }
            }
        }

        &:deep(.s-slice__header) {
            margin-bottom: 60px;
        }

        &__list {
            margin: 0 -32px -32px;
        }

        &__item {
            margin: 0 32px 56px;
            width: 156px;
            height: 48px;
        }

        &__link {
            position: absolute;
            top: 8px;
            inset-inline-end: 32px;
            margin-top: 0;
            max-width: 25%;
        }

        &_layout_9-10-logos {
            .s-partner-logos__list {
                max-width: none;
            }
        }

        &_layout_7-8-logos {
            .s-partner-logos__list {
                max-width: 880px;
            }
        }

        &_layout_6-logos {
            .s-partner-logos__list {
                max-width: 880px;
            }
        }

        &_layout_5-logos {
            .s-partner-logos__list {
                max-width: 660px;
            }
        }
    }

    @media (--viewport-desktop-wide) {
        &__link {
            top: 8px;
            inset-inline-end: 64px;
        }

        &_layout_9-10-logos {
            .s-partner-logos__list {
                max-width: 1300px;
            }
        }

        &_layout_7-8-logos {
            .s-partner-logos__list {
                max-width: 880px;
            }
        }

        &_layout_6-logos {
            .s-partner-logos__list {
                max-width: 880px;
            }
        }

        &_layout_5-logos {
            .s-partner-logos__list {
                max-width: none;
            }
        }
    }

    @media (--viewport-desktop-large) {
        &__link {
            inset-inline-end: 0;
        }

        &_layout_9-10-logos {
            .s-partner-logos__list {
                max-width: 1300px;
            }
        }

        &_layout_6-logos {
            .s-partner-logos__list {
                max-width: none;
            }
        }
    }
}
</style>
