<template>
    <div class="blog-spotlight">
        <div class="top-wrap">
            <div class="spotlight">
                <dynamic-link class="spotlight-item" :to="spotlightedCard.link" native-type="a">
                    <a-picture class="spotlight-image" :link="spotlightedCard.image_id" fit="cover" />
                    <div class="spotlight-title">
                        {{ spotlightedCard.title }}
                    </div>
                </dynamic-link>
            </div>

            <slot name="mobile-menu" />

            <div v-if="hasMenu" class="menu">
                <slot name="menu" />
            </div>
        </div>

        <div class="news">
            <div class="news-top-wrap">
                <div class="news-title">
                    {{ newsTitle }}
                </div>
                <a-link
                    v-if="newsLink"
                    class="news-link"
                    :to="newsLink.link"
                    :text="newsLink.title"
                    size="lead"
                    type="secondary"
                    glyph="news-archive"
                    glyph-position="left"
                />
            </div>
            <div class="news-bottom-wrap">
                <blog-news-item
                    v-for="(item, i) in news"
                    :key="i"
                    v-bind="item"
                    :video-label="newsVideoLabel"
                />
            </div>
        </div>
    </div>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import BlogNewsItem from '../shared-components/blog-news-item/blog-news-item.vue';

export default {
    name: 'BlogSpotlight',
    components: {
        DynamicLink,
        BlogNewsItem,
        APicture,
        ALink,
    },
    props: {
        newsTitle: {
            type: String,
            required: true,
        },

        newsVideoLabel: {
            type: String,
            required: true,
        },

        news: {
            type: Array,
            required: true,
        },

        spotlightTitle: {
            type: String,
            required: true,
        },

        spotlightedCard: {
            type: Object,
            required: true,
        },

        newsLink: {
            type: Object,
            default: null,
        },
    },
    computed: {
        hasMenu() {
            return Boolean(this.$slots.menu);
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-spotlight {
    color: var(--av-inversed-primary);
    margin-bottom: 56px;

    .news-title {
        @mixin title-accent;
        color: var(--av-inversed-secondary);

        @media (--viewport-tablet) {
            text-align: start;
        }
    }

    .news-link {
        margin: 0;

        &:deep(.a-link__content) {
            @mixin lead-accent;
            color: var(--av-link-inversed);
        }
    }

    .news-top-wrap {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        gap: 16px;
        margin-bottom: 16px;
    }

    .news-bottom-wrap {
        display: flex;
        flex-direction: column;

        @media (--viewport-tablet) {
            flex-direction: row;
            justify-content: space-between;
            gap: 24px;

            .blog-news-item {
                flex-basis: 100%;
            }
        }
    }

    .news {
        display: flex;
        flex-direction: column;
        order: 1;
        margin-bottom: 48px;
        background: rgba(0, 46, 112, 1);
        border: 2px solid var(--av-brand-secondary-accent);
        border-radius: 8px;
        padding: 16px;

        @media (--viewport-tablet) {
            margin-bottom: 32px;
        }

        @media (--viewport-desktop) {
            margin-bottom: 0;
        }
    }

    .spotlight {
        height: 420px;
        width: 100%;
        border-radius: 8px;
        background: var(--av-brand-secondary-accent);

        @media (--viewport-desktop) {
            width: 635px;
        }

        @media (--viewport-desktop-wide) {
            width: 764px;
        }

        @media (--viewport-desktop-large) {
            width: 920px;
        }
    }

    .spotlight-item {
        display: block;
        position: relative;
        height: 420px;
        width: 100%;
        border-radius: 8px;
        text-decoration: none;
        overflow: hidden;

        @media (--viewport-desktop) {
            width: 635px;
        }

        @media (--viewport-desktop-wide) {
            width: 764px;
        }

        @media (--viewport-desktop-large) {
            width: 920px;
        }
    }

    .spotlight-image {
        position: relative;
        height: 100%;
        width: 100%;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            bottom: 0;
            height: 72.5%;
            background: linear-gradient(0deg, #04142c 25.25%, rgba(5, 24, 50, 0) 80.81%);
        }
    }

    .spotlight-title {
        @mixin nav-title-accent;
        position: absolute;
        inset-inline-start: 16px;
        inset-inline-end: 16px;
        bottom: 16px;
        padding: 16px;
        background: var(--av-solid-brand-accent);
        color: var(--av-nav-primary);
        border-radius: 8px;

        @media (--viewport-tablet) {
            inset-inline-end: 24px;
            inset-inline-start: 24px;
            bottom: 24px;
        }
    }

    .top-wrap {
        @media (--viewport-tablet) {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 16px;
            margin-bottom: 16px;
        }
    }
}
</style>

<style lang="postcss">
.blog-spotlight {
    .spotlight-image {
        .a-picture__img {
            width: 100%;
            height: 100%;
            max-width: none;
        }
    }

    &:deep(.menu-item.extra) {
        border: 0;
    }

    .menu {
        flex-grow: 1;
    }
}
</style>
