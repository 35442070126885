<template>
    <div>
        <fullscreen-popup
            v-if="!isMobile && isFullscreen"
            :data="popup"
            :enabled="enabled"
            @close="onFullscreenClose"
            @submitted="onFullscreenSubmitted"
        />
        <popup
            v-else-if="!isMobile && hasEnoughSpace"
            v-bind="popup"
            :enabled="enabled"
            @closeButtonClick="onClose"
            @ctaButtonClick="closePopup"
        />
    </div>
</template>

<script lang="ts">
import breakpoint from '@core/mixins/breakpoint.js';
import FullscreenPopup from './fullscreen-popup.vue';
import Popup from './popup.vue';

const FULLSCREEN_DISPLAY_DELAY_MS = 1000 * 20; // 20 seconds
const FULLSCREEN_COOLDOWN_MS = 1000 * 60 * 60 * 24 * 15; // 15 days

export default {
    components: { Popup, FullscreenPopup },
    mixins: [breakpoint],
    props: {
        popups: {
            type: Array,
            required: true,
        },
        delay: {
            type: Number,
            default: 10000,
        },
    },
    data: () => ({
        session: {
            shown: 'acronis_pb_shown',
        },
        local: {
            visible: 'acronis_pb_visible',
            closed: 'acronis_pb_banners',
            fsReEnable: 'acronis_pb_fs_re_enable',
            fsSubmitted: 'acronis_pb_fs_submitted',
        },
        viewedBannerIds: [],
        enabled: false,
        hasEnoughSpace: true,
        timerId: null,
    }),
    computed: {
        popup() {
            return this.popups[Math.floor(Math.random() * this.popups.length)];
        },
        isFullscreen() {
            return this.popup.hasForm;
        },
        isHomePage() {
            return this.$route?.path === `/${this.$route?.params.locale}/`;
        },
        hasViewedBanner() {
            return this.viewedBannerIds.includes(this.popup.id);
        },
    },
    mounted() {
        if (typeof localStorage !== 'object') return;

        window.addEventListener('load', this.onLayoutChange);
        window.addEventListener('scroll', this.onLayoutChange, {
            capture: true,
            passive: true,
        });
        window.addEventListener('resize', this.onLayoutChange, {
            capture: true,
            passive: true,
        });

        this.viewedBannerIds = JSON.parse(localStorage.getItem(this.local.closed)) || [];

        if (this.isFullscreen) {
            const isSubmitted = localStorage.getItem(`${this.local.fsSubmitted}_${this.popup.id}`) || false;
            const allowedTime = localStorage.getItem(`${this.local.fsReEnable}_${this.popup.id}`) || 0;

            if (!isSubmitted && Date.now() >= allowedTime) {
                localStorage.removeItem(`${this.local.fsReEnable}_${this.popup.id}`);
                clearTimeout(this.timerId);
                this.timerId = setTimeout(this.displayPopup, FULLSCREEN_DISPLAY_DELAY_MS);
            }
        } else if (!this.hasViewedBanner) {
            if (this.isHomePage) {
                window.addEventListener('scroll', this.displayPopupAfterScroll);
            } else {
                clearTimeout(this.timerId);
                this.timerId = setTimeout(this.displayPopup, this.delay);
            }
        }
    },
    beforeDestroy() {
        clearTimeout(this.timerId);

        window.removeEventListener('load', this.onLayoutChange);
        window.removeEventListener('scroll', this.onLayoutChange);
        window.removeEventListener('resize', this.onLayoutChange);
        window.removeEventListener('scroll', this.displayPopupAfterScroll);
    },
    methods: {
        onLayoutChange() {
            const pageYOffset = window.pageYOffset;
            const scrollHeight = document.body.scrollHeight;
            const clientHeight = document.body.clientHeight;

            this.hasEnoughSpace = clientHeight > 480;
            if (!this.isFullscreen && pageYOffset > scrollHeight / 2) this.enable();
        },
        closePopup() {
            this.disable();
        },
        onFullscreenSubmitted() {
            this.sendEventsToGA({ action: 'Submitted', label: 'Discount' });
            localStorage.setItem(`${this.local.fsSubmitted}_${this.popup.id}`, '1');
        },
        onFullscreenClose(isSubmitted) {
            if (isSubmitted) {
                localStorage.setItem(`${this.local.fsSubmitted}_${this.popup.id}`, '1');
            } else {
                localStorage.setItem(`${this.local.fsReEnable}_${this.popup.id}`, Date.now() + FULLSCREEN_COOLDOWN_MS);
                this.sendEventsToGA({ action: 'Closed' });
            }
            this.closePopup();
        },
        onClose() {
            this.sendEventsToGA({ action: 'Closed' });
            this.closePopup();
        },
        disable() {
            this.enabled = false;
            this.viewedBannerIds.push(this.popup.id);

            localStorage.removeItem(this.local.visible);
            localStorage.setItem(this.local.closed, JSON.stringify(this.viewedBannerIds));

            clearTimeout(this.timerId);
        },
        enable() {
            if (this.hasViewedBanner) return;
            this.enabled = true;
            localStorage.setItem(this.local.visible, '1');
            clearTimeout(this.timerId);
        },
        displayPopupAfterScroll() {
            if (this.isFullscreen) {
                return;
            }

            const pageHeight = document.body.getBoundingClientRect().height;
            const halfPage = pageHeight / 2;

            if (window.scrollY >= halfPage) {
                this.displayPopup();
                window.removeEventListener('scroll', this.displayPopupAfterScroll);
            }
        },
        displayPopup() {
            sessionStorage.setItem(this.session.shown, '1');
            this.sendEventsToGA({ action: 'Shown' });
            this.enable();
        },
        sendEventsToGA(incomingGA) {
            const event = {
                event: 'Acronis',
                eventCategory: incomingGA.category || 'Popup',
                eventAction: incomingGA.action || '',
                eventLabel: incomingGA.label || this.$route.path,
                eventContext: incomingGA.context || '',
                eventContent: incomingGA.content || '',
                eventLocation: `${incomingGA.location || ''}`,
                eventButton: `${incomingGA.button || ''}`,
            };

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(event);
        },
    },
};
</script>
