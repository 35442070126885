<template>
    <section class="acpc-resources">
        <div class="a-container">
            <div class="wrapper">
                <div class="content">
                    <h6 v-if="nav.title" class="title">
                        {{ nav.title }}
                    </h6>
                    <div class="links">
                        <a-dynamic-link
                            v-for="(link, i) in nav.items"
                            :key="`acpc-resources-link_${i}`"
                            :to="link.to"
                            class="link"
                        >
                            <span class="type">{{ link.type }}</span>
                            <span v-if="link.desc" class="desc" v-html="link.desc" />
                        </a-dynamic-link>
                    </div>
                    <a-button
                        v-if="nav.button"
                        class="button"
                        v-bind="nav.button"
                    />
                </div>
                <div class="faq">
                    <h6 v-if="faq.title" class="title">
                        {{ faq.title }}
                    </h6>
                    <a-accordion class="faq-list">
                        <a-accordion-item
                            v-for="(item, index) in faq.items"
                            :key="`faq-${index}`"
                            ref="faqItem"
                            class="faq-item"
                            :title="item.question"
                            :active="item?.active || false"
                        >
                            <a-dangerous-html :content="item.answer" />
                        </a-accordion-item>
                    </a-accordion>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AAccordionItem from '@core/components/accordion/accordion-item.vue';
import AAccordion from '@core/components/accordion/accordion.vue';
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import ADynamicLink from '@core/components/dynamic-link/dynamic-link.vue';

export default {
    name: 'SProductResources',
    components: {
        AAccordionItem,
        AAccordion,
        ADynamicLink,
        AButton,
        ADangerousHtml,
    },
    props: {
        faq: {
            type: Object,
            required: true,
        },
        nav: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.acpc-resources {
    padding: 64px 0;
    background: var(--av-solid-brand-accent);
    .wrapper {
        display: flex;
        flex-flow: row wrap;
    }
    .content {
        @mixin colls 12;
        @media (--viewport-desktop) {
            @mixin colls 4;
        }
        .title {
            @mixin display;
            margin-bottom: 40px;
            color: var(--av-nav-primary);
        }
        .links {
            display:flex;
            flex-flow: row wrap;
            justify-content: space-between;
            gap: 16px;
            .link {
                display: flex;
                flex-flow: column;
                @mixin colls 6;
                padding: 16px 24px;
                background: var(--av-inversed-primary);
                border: 2px solid var(--av-brand-secondary-light);
                border-radius: 8px;
                box-shadow: var(--av-shadow-small);
                transition: all linear 0.2s;
                @media (--viewport-tablet) {
                    @mixin colls 3;
                }
                @media (--viewport-desktop) {
                    @mixin colls 12;
                }
                &:hover {
                    box-shadow: none;
                }
                .type {
                    @mixin body;
                    @mixin colls 12;
                    color: var(--av-brand-primary);
                    display: block;
                    margin-bottom: 8px;
                }
                .desc {
                    @mixin paragraph;
                    @mixin colls 12;
                    margin-top: auto;
                    color: var(--av-fixed-primary);
                }
            }
        }
        .button {
            @mixin colls 12;
            justify-content: center;
            margin-top: 40px;
            padding: 8px 0;
            border-radius: 8px;
            border: 2px solid var(--av-brand-secondary-light);
            box-shadow: none;

            &:deep(.a-button__content) {
                color: var(--av-brand-primary);
            }
            &:deep(.a-button__glyph) {
                fill: var(--av-brand-primary);
            }
        }
    }
    .faq {
        @mixin colls 12;
        margin-top: 32px;
        @media (--viewport-desktop) {
            margin-top: 0;
            max-width: 635px;
            width: 100%;
        }
        @media (--viewport-desktop) {
            padding-inline-start: 16px;
            @mixin colls 8;
        }
        @media (--viewport-desktop-wide) {
            max-width: 715px;
            width: 100%;
        }
        .title {
            @mixin display;
            color: var(--av-nav-primary);
            margin-bottom: 24px;
            text-align: center;
            @media (--viewport-tablet) {
                @mixin hero;
                text-align: start;
                margin-bottom: 48px;
            }
        }
        .faq-list {
            .faq-item {
                color: var(--av-nav-primary);
                &:first-child {
                    border-top: none;
                }

                &:deep(.title) {
                    @mixin paragraph;
                }
                &:deep(.content) {
                    @mixin body;
                }
            }
        }

    }
}
</style>
