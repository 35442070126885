<template>
    <div class="content">
        <div class="a-container">
            <div class="wrapper">
                <p v-if="content.lead" class="lead">
                    {{ content.lead }}
                </p>
                <template v-if="content.description">
                    <p
                        v-for="(item, i) in content.description"
                        :key="`description-${i}`"
                        class="description"
                        v-html="item"
                    />
                </template>
                <s-content-cta v-if="isDesktop" :content="content" />
            </div>
            <s-content-form :form="content.form" />
            <s-content-cta v-if="isMobile || isTablet" :content="content" />
        </div>
    </div>
</template>

<script>
import breakpoint from '@core/mixins/breakpoint.js';
import SContentCta from './content-cta.vue';
import SContentForm from './content-form.vue';

export default {
    name: 'SContent',

    components: {
        SContentCta,
        SContentForm,
    },

    mixins: [breakpoint],

    props: {
        /**
         * Content
         */
        content: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.content {
    .a-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.lead {
    @mixin lead;
    margin-top: 40px;
    color: var(--av-fixed-secondary);
}

.description {
    @mixin paragraph;
    margin-top: 24px;
    color: var(--av-fixed-light);
}

@media (--viewport-mobile-wide) {
    .lead {
        @mixin title;
        margin-top: 48px;
    }
}

@media (--viewport-desktop) {
    .wrapper {
        width: 40.521%;
    }
}
</style>
