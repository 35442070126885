import { render, staticRenderFns } from "./intro.vue?vue&type=template&id=8a38eb24&scoped=true"
import script from "./intro.vue?vue&type=script&lang=js"
export * from "./intro.vue?vue&type=script&lang=js"
import style0 from "./intro.vue?vue&type=style&index=0&id=8a38eb24&prod&lang=postcss&scoped=true"
import style1 from "./intro.vue?vue&type=style&index=1&id=8a38eb24&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a38eb24",
  null
  
)

export default component.exports