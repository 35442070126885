<template>
    <label v-if="item && item.text" :class="classLabel(item)">
        <div class="label-item">
            <span class="label-text" v-html="item.text" />
            <span v-if="item.subtitle" class="label-subtitle" v-html="item.subtitle" />
            <span v-if="item.promo" class="label-text-promo" v-html="item.promo" />
        </div>

        <div v-if="item.price" class="label-item">
            <span v-if="item.priceFull" class="label-price-full" v-html="item.priceFull" />
            <span class="label-price" v-html="item.price" />
        </div>

        <s-num-picker
            v-if="item.hasNumberPicker && item.quantity"
            :value="item?.quantity || 1"
            :multiplier="item.multiplier || 1"
            :value-min="item.quantityMin || 1"
            :value-max="item.quantityMax || 99"
            @changeQuantity="changeQuantity($event)"
        />
    </label>
</template>

<script>
import SNumPicker from './num-picker.vue';

export default {
    name: 'SDropdownItem',

    components: {
        SNumPicker,
    },

    props: {
        item: {
            type: Object,
            default: null,
        },
    },

    emits: ['changeQuantity'],

    methods: {
        classLabel(item) {
            return {
                label: item,
                [`label-type-${item.type}`]: item.type,
                'label-has-number-picker': item.hasNumberPicker,
            };
        },

        changeQuantity(value) {
            const quantity = Math.round(value);
            this.$emit('changeQuantity', { ...this.item, quantity, isChecked: Boolean(quantity) });
        },
    },
};
</script>

<style lang="postcss" scoped>
.label {
    @mixin body;
    width: 100%;
    display: flex;
    padding: 0 16px;
    min-height: 40px;
    align-items: center;
    color: var(--av-fixed-primary);
    justify-content: space-between;

    &:hover {
        cursor: pointer;
    }
}

.label-text {
    cursor: pointer;
    line-height: 1.2;
    padding-inline-end: 8px;
    color: var(--av-fixed-primary);
    flex-direction: column;
    align-items: start;
}

.label-item {
    width: 50%;
    gap: 0 8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &:not(:first-child) {
        justify-content: flex-end;
    }
}

.label-subtitle {
    @mixin caption;
    width: 100%;
    color: var(--av-fixed-light);
}

.label-text-promo {
    @mixin body-accent;
    line-height: 1;
    color: rgba(124, 155, 30, 1);
}

.label-price-full {
    @mixin caption;
    color: var(--av-fixed-lighter);
    text-decoration: line-through;
}

.label-type-size,
.label-type-cloud {
    .label-item {
        width: auto;
    }
}

.label-has-number-picker {
    &:hover {
        cursor: default;
    }

    .label-item {
        width: auto;
    }
}

.el-num-picker {
    inset-inline-start: 16px;
}
</style>
