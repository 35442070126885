<template>
    <div class="s-form-footer" :class="{ 's-form-footer-sem-ebook': content.isEbook }">
        <ul v-if="content.awards" class="s-form-footer__awards">
            <li v-for="(item, i) in content.awards" :key="i" class="s-form-footer__awards-item">
                <a-picture :link="item.to" :alt="item.name" />
            </li>
        </ul>
        <div class="s-form-footer__copyright">
            © 2003–{{ currentYear }} {{ content.text }}
            <a-link
                v-for="(item, i) in legalLinks"
                :key="`legal-link-${i}`"
                class="s-form-footer__item"
                v-bind="item"
                :size="item.size || 'paragraph'"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SFormFooter',

    components: {
        ALink,
        APicture,
    },

    props: {
        content: {
            type: Object,
            default: null,
        },
    },

    data: () => ({
        currentYear: new Date().getFullYear(),
    }),

    computed: {
        ...mapState({
            copyright: (state) => state.slices.items?.['s-global-footer']?.copyright || '',
            legalLinks: (state) => state.slices.items?.['s-global-footer']?.legalLinks || [],
        }),
    },
};
</script>

<style lang="postcss" scoped>
.s-form-footer__awards {
    display: flex;
    flex-wrap: wrap;
    margin: 28px 0 0;
    align-items: center;
    justify-content: center;

    &-item {
        margin: 36px 0 0;

        &:not(:first-child) {
            margin-inline-start: 40px;
        }

        &:deep(.a-picture__img) {
            max-height: 80px;
            max-width: 136px;
        }
    }
}

.s-form-footer-sem-ebook {
    .s-form-footer {
        &__copyright {
            @mixin body;
            margin: 32px 0 16px;
            color: var(--av-fixed-lighter);

            &:deep(.a-link) {
                &__content {
                    @mixin body;
                    line-height: 1;
                    vertical-align: baseline;
                    margin-inline-start: 4px;
                    color: var(--av-fixed-lighter);
                }
            }
        }
    }
}

@media (--viewport-tablet) {
    .s-form-footer__awards {
        margin: 104px 0 0;

        &-item {
            margin: 0;
            &:not(:first-child) {
                margin-inline-start: 80px;
            }
        }
    }

    .s-form-footer-sem-ebook {
        .s-form-footer {
            &__copyright {
                padding: 0 16px;
                margin: 80px 0 16px;
                text-align: center;
            }
        }
    }
}

@media (--viewport-desktop) {
    .s-form-footer__awards-item {
        &:not(:first-child) {
            margin-inline-start: 150px;
        }
    }
}
</style>
