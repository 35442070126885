<template>
    <ul class="a-accordion" :class="className">
        <slot ref="item" />
    </ul>
</template>

<script>
export default {
    name: 'AAccordion',

    props: {
        isWide: {
            type: Boolean,
            default: false,
        },

        oneOnlyMode: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['change', 'changedActive'],

    data: () => ({
        progress: 0,
        items: [],
    }),

    computed: {
        className() {
            return { 'is-wide': this.isWide };
        },
    },

    mounted() {
        this.items = this.$slots.default;
    },

    methods: {
        handleAllActives() {
            if (this.oneOnlyMode) {
                this.items.forEach((item) => item.componentInstance?.removeActive());
            }
        },

        emitActiveChanged(event) {
            this.$emit('changedActive', event);
        },

        emitChangeEvent() {
            const index = this.items
                .filter((item) => item.componentInstance?.isActive !== undefined)
                .findIndex((item) => item.componentInstance?.isActive);
            this.$emit('change', index);
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-accordion {
    &.is-wide :deep(.a-accordion-item) {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            margin-inline-start: calc(50% - 50vw);
            width: 100vw;
            height: 1px;
            background-color: var(--av-brand-accent);
        }

        &:last-of-type {
            border-bottom: 0;
        }

        &:last-of-type::after {
            content: '';
            position: absolute;
            bottom: 0;
            inset-inline-start: 0;
            margin-inline-start: calc(50% - 50vw);
            width: 100vw;
            height: 1px;
            background-color: var(--av-brand-accent);
        }

        &.active {
            background-color: var(--av-brand-lightest);
            margin: 0 var(--container-paddings-mobile);
            padding: 0 var(--container-paddings-mobile);
            box-shadow: inset 0 5px 6px rgba(138, 173, 207, 0.1865);

            @media (--viewport-mobile-wide) {
                margin: 0 var(--container-paddings-mobile-wide);
                padding: 0 var(--container-paddings-mobile-wide);
            }

            @media (--viewport-desktop-wide) {
                margin: 0 var(--container-paddings-desktop-wide);
                padding: 0 var(--container-paddings-desktop-wide);
            }

            @media (--viewport-desktop-large) {
                margin: 0 -100%;
                padding: 0 100%;
            }
        }

        .title {
            padding: 16px 0;

            @mixin lead-strong;

            &:hover {
                color: var(--av-nav-primary);
            }
        }

        .icon {
            margin-inline-end: 16px;
        }

        .content {
            @mixin paragraph;
        }

        .content-wrapper {
            padding: 8px 32px 56px;
        }
    }

    &:deep(.a-accordion-item) {
        &:last-of-type {
            border-bottom: var(--av-border);
            margin-bottom: 40px;
        }
    }
}
</style>
