<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import { SECTION_ID_CPC } from '@model/const/blog';
import { HttpRejection } from '@model/http/rejection';
import { assertHttpErrors } from '@utils/api-response';
import { getTextDirection, getOgImage, ACRONIS_OG_IMAGE_ID, getHtmlLang } from '@utils/html-meta';
import BlogCPCAboutComponent from './component.vue';

const AUTHORS_COUNT = 6;

export default Vue.extend({
    name: 'BlogCPCAboutContainer',

    async serverPrefetch(): Promise<void> {
        // Init
        const locale = this.$route.params.locale;

        // Determine if we should proceed
        await this.$store.dispatch('locales/getLocaleData', { locale });
        const cpcIsActiveForLocale = this.$store.state.locales.locale.is_active_on_blog_cpc;
        if (!cpcIsActiveForLocale) {
            throw new HttpRejection('Premature rendering stop', StatusCodes.NOT_FOUND);
        }

        const categoriesRequest = new ProductAPIQueryBuilder('categories')
            .setEntityPath('/api/blog/categories/')
            .setLocales([locale])
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .setCustomParam('process-macros', '1')
            .setOutputOnly(['id', 'section_id', 'slug', 'title'])
            .toObject();

        const authorsRequest = new ProductAPIQueryBuilder('authors')
            .setEntityPath('/api/blog/authors/')
            .addMatchesAll('translation.is_featured', '=', '1')
            .setCustomParam('has', { sections: [SECTION_ID_CPC] })
            .setCustomParam('process-macros', '1')
            .setOutputOnly(['id', 'name', 'photo', 'position', 'slug'])
            .setPaginate(1, AUTHORS_COUNT)
            .setLocales([locale])
            .toObject();

        const translationsListRequest = new ProductAPIQueryBuilder('sectionTranslationsList')
            .setEntityPath('/api/blog/sections/translations/')
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .setOutputOnly(['locale'])
            .setPaginate(1, 20)
            .toObject();

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 's-main-header', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'blog', locale }),
            this.$store.dispatch('blog/getEntity', { request: categoriesRequest }),
            this.$store.dispatch('blog/getEntity', { request: authorsRequest }),
            this.$store.dispatch('blog/getEntity', { request: translationsListRequest }),
        ]);

        assertHttpErrors([
            { entity: this.$store.state.blog.CPCNews },
            { entity: this.$store.state.blog.spotlightedCPC },
            { entity: this.$store.state.blog.categories },
            { entity: this.$store.state.blog.authors },
            { entity: this.$store.state.blog.sectionTranslationsList },
        ]);

        // Building page meta
        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
    },

    methods: {
        getMeta(): any {
            const locale = this.$route.params.locale;
            const uiStrings: any = this.$store.state.slices.items.blog || {};
            const canonical = `https://${this.$config.domain}${this.$route.path}`;
            const imageId = uiStrings.cpcAboutOgImage || ACRONIS_OG_IMAGE_ID;
            const ogImage = getOgImage(`@${imageId}`, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = uiStrings.cpcAboutMetaTitle;
            const description = uiStrings.cpcAboutMetaDescription;

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogImage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogImage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'link', rel: 'image_src', href: ogImage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: getHtmlLang(locale),
                },
            };
        },
    },

    render(h) {
        return h(BlogCPCAboutComponent);
    },
});
</script>
