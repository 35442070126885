<template>
    <main class="page-container academy-main-page">
        <s-academy-ribbon v-if="page.showRibbon && ribbon" v-bind="ribbon" />
        <s-page-header v-bind="header">
            <s-academy-menu v-bind="header" />
        </s-page-header>
        <s-courses-section
            v-if="page.coursesSection"
            v-bind="page.coursesSection"
            :translations="translations"
        />
        <s-learn-more v-bind="page.learnMoreSection" />
        <s-academy-section v-if="page.academySection" v-bind="page.academySection" />
        <s-testimonials-section v-if="page.testimonialsSection" v-bind="page.testimonialsSection" />
        <s-certification-cards v-if="page.certifications" v-bind="page.certifications" />
        <s-faq-section v-if="page.faq" v-bind="page.faq" />
        <s-academy-footer v-bind="footer" />
    </main>
</template>

<script>
import { mapState } from 'vuex';
import SAcademyFooter from '@core/slices/academy/components/academy-footer.vue';
import SAcademyMenu from '@core/slices/academy/components/academy-menu.vue';
import SAcademyRibbon from '@core/slices/academy/components/academy-ribbon.vue';
import SAcademySection from './components/academy-section.vue';
import SCertificationCards from './components/certification-cards.vue';
import SCoursesSection from './components/courses-section.vue';
import SFaqSection from './components/faq-section.vue';
import SLearnMore from './components/learn-more.vue';
import SPageHeader from './components/page-header.vue';
import STestimonialsSection from './components/testimonials-section.vue';

export default {
    name: 'SAcademyMainPage',
    components: {
        SAcademyMenu,
        SAcademyRibbon,
        SPageHeader,
        SCoursesSection,
        SLearnMore,
        SAcademySection,
        STestimonialsSection,
        SCertificationCards,
        SFaqSection,
        SAcademyFooter,
    },
    computed: {
        ...mapState({
            preset: (state) => state.slices.items['s-academy-acronis-com'] || {},
        }),
        translations() {
            return this.preset?.translation;
        },
        ribbon() {
            return this.preset?.defaults?.ribbon;
        },
        page() {
            return this.preset?.template?.['main-page'];
        },
        footer() {
            const fromDefaults = this.preset.defaults?.footer;
            const fromPage = this.page?.footer;
            return { ...fromDefaults, ...fromPage };
        },
        header() {
            const fromDefaults = this.preset.defaults?.header;
            const fromPage = this.page?.header;
            return { ...fromDefaults, ...fromPage };
        },
    },
};
</script>

<style lang="postcss" scoped>
.academy-main-page {
    overflow: hidden;
    height: auto;
}
</style>
