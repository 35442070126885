<template>
    <div class="s-company-header">
        <div class="s-company-header__wrapper">
            <a-picture :background="header.background" fit="cover" is-background />
            <s-main-header :bind="header" />
            <div class="a-container">
                <div class="s-company-header__title">
                    {{ header.title }}
                </div>
            </div>
        </div>
        <s-company-anchor-menu :anchor-menu="anchorMenu" />
    </div>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';
import SMainHeader from '@core/slices/pages/main-header/main-header.vue';
import SCompanyAnchorMenu from './company-anchor-menu.vue';

export default {
    components: {
        APicture,
        SMainHeader,
        SCompanyAnchorMenu,
    },
    props: {
        header: {
            type: Object,
            default() {
                return {
                    background: {
                        desktop: '/images/backgrounds/company-page/bg-desktop',
                        tablet: '/images/backgrounds/company-page/bg-tablet',
                        mobile: '/images/backgrounds/company-page/bg-mobile',
                    },
                    title: 'Unifying data protection and cybersecurity to protect all data, applications and systems',
                };
            },
        },
        anchorMenu: {
            type: Object,
            default: () => undefined,
        },
    },
    serverPrefetch() {
        const locale = this.$route?.params.locale;
        this.$store.dispatch('slices/getSyncedData', { slice: 's-company-header', locale });
    },
};
</script>
<style lang="postcss" scoped>
.s-company-header {
    &__wrapper {
        position: relative;
        padding: 120px 0 48px;
        @media (--viewport-tablet) {
            padding: 124px 0 48px;
        }
        @media (--viewport-desktop) {
            padding: 128px 0 48px;
        }
        @media (--viewport-desktop-wide) {
            padding: 144px 0 48px;
        }
    }
    &__title {
        @mixin display-accent;
        color: var(--av-inversed-primary);
        width: 100%;

        @media (--viewport-mobile-wide) {
            @mixin hero-accent;
        }
        @media (--viewport-desktop) {
            @mixin large-accent;
            @mixin colls 9;
        }
        @media (--viewport-desktop-large) {
            @mixin colls 8;
        }
    }
}
</style>
