import { getSearchAction, getUrlWithLocale, SITE_ID_EVENTS } from '@core/mixins/structuredData/helper';
import { LOCALE_DEFAULT } from '@model/const/locales';
import SchemaHandler from '@model/seo/schemaHandler';
import { SchemaConfig } from '@model/seo/schemas';
import ArticleHandler from './handlers/articleHandler';
import BreadcrumbListHandler from './handlers/breadcrumbListHandler';
import ContactPointHandler from './handlers/contactPointHandler';
import EventHandler from './handlers/eventHandler';
import FAQPageHandler from './handlers/faqPageHandler';
import HowToHandler from './handlers/howToHandler';
import JobPostingHandler from './handlers/jobPostingHandler';
import OfferHandler from './handlers/offerHandler';
import OrganizationHandler from './handlers/organizationHandler';
import PostalAddressHandler from './handlers/postalAddressHandler';
import ProductHandler from './handlers/productHandler';
import VideoObjectHandler from './handlers/videoObjectHandler';
import WebPageHandler from './handlers/webPageHandler';
import WebSiteHandler from './handlers/webSiteHandler';

const DEFAULT_WEB_PAGE_SCHEMAS = ['WebPage', 'Organization'];
const LOCALES_WITHOUT_SEARCH = ['zh-cn'];

export enum RouteGroup {
    Home = 'Home',
    About = 'About',
    Product = 'Product',
    Solutions = 'Solutions',
    Partners = 'Partners',
    Careers = 'Careers',
    BlogPost = 'BlogPost',
    Event = 'Event',
    PRArticle = 'PRArticle',
    RCArticle = 'RCArticle',
    DefaultWebPage = 'DefaultWebPage'
}

export enum Schema {
    WebSite = 'WebSite',
    ContactPoint = 'ContactPoint',
    WebPage = 'WebPage',
    VideoObject = 'VideoObject',
    Offer = 'Offer',
    Organization = 'Organization',
    FAQPage = 'FAQPage',
    Product = 'Product',
    BreadcrumbList = 'BreadcrumbList',
    PostalAddress = 'PostalAddress',
    Article = 'Article',
    Event = 'Event',
    HowTo = 'HowTo',
    JobPosting = 'JobPosting'
}

/**
 * Checks if the given path is a career route.
 *
 * @param {string} path - The path to check.
 * @param {string} locale - The locale to consider when checking the path.
 * @return {boolean} True if the path is a career route, false otherwise.
 */
const isCareerRoute = (path: string, locale: string): boolean => {
    const careersPaths = [
        `/${locale}/company/careers/`,
        `/${locale}/careers/`,
        `/${locale}/careers/jobs/`,
    ];

    return careersPaths.includes(path);
};

/**
 * Determines if the provided path is for a press release article route.
 *
 * @param {string} path - The path to be checked.
 * @return {boolean} True if the path is for a press release article route, false otherwise.
 */
const isPressReleaseArticleRoute = (path: string): boolean => {
    const pressReleaseArticleRegex = /\/pr\/[0-9]{4}\/.+/;
    return pressReleaseArticleRegex.test(path);
};

/**
 * Returns the RouteGroup based on the siteID, path, and locale.
 *
 * @param {string} siteID - The ID of the site.
 * @param {string} path - The path to be checked.
 * @param {string} locale - The locale to be used.
 * @return {RouteGroup | null} The RouteGroup if found, null otherwise.
 */
function getRouteGroup(siteID: string, path: string, locale: string): RouteGroup {
    if (siteID === SITE_ID_EVENTS) {
        switch (true) {
            case path === '/':
                return RouteGroup.Home;

            // Event - exact event page
            case path.startsWith('/event/'):
                return RouteGroup.Event;

            default:
                return null;
        }
    }

    switch (true) {
        // Blogs - exact blogpost page
        case path.startsWith(`/${locale}/blog/posts/`):
            return RouteGroup.BlogPost;

        // Partners - only one partner page should be affected
        case path === `/${locale}/partners/`:
            return RouteGroup.Partners;

        case path.startsWith(`/${locale}/partners/`):
            return RouteGroup.DefaultWebPage;

        // Solutions - everything under `/solutions/*`
        case path.startsWith(`/${locale}/solutions/`):
            return RouteGroup.Solutions;

        // Products
        case path.startsWith(`/${locale}/products/`):
            return RouteGroup.Product;

        // Careers Home
        case isCareerRoute(path, locale):
            return RouteGroup.Careers;

        // About - exact link
        case (path === `/${locale}/company/`):
            return RouteGroup.About;

        case path.startsWith(`/${locale}/company/`):
        case path === `/${locale}/resource-center/`:
        case path === `/${locale}/pr/`:
            return RouteGroup.DefaultWebPage;

        // Resource center article
        case path.startsWith(`/${locale}/resource-center/resource/`):
            return RouteGroup.RCArticle;

        // Press release article
        case isPressReleaseArticleRoute(path):
            return RouteGroup.PRArticle;

        // Home - exact link
        case (path === `/${locale}/`):
            return RouteGroup.Home;

        // No schemas for other routes
        default:
            return null;
    }
}

/**
 * Returns an instance of a SchemaHandler based on the provided schema.
 *
 * @param {any} ctx - The context object.
 * @param {Schema} schema - The schema type.
 * @param {SchemaConfig[]} schemasConfig - The configuration for the schema.
 * @return {SchemaHandler | null} An instance of a SchemaHandler or null if the schema is not recognized.
 */
function getSchemaHandler(ctx: any, schema: Schema, schemasConfig: SchemaConfig[]): SchemaHandler {
    switch (schema) {
        case Schema.Article:
            return new ArticleHandler(ctx, schemasConfig);
        case Schema.PostalAddress:
            return new PostalAddressHandler(ctx, schemasConfig);
        case Schema.BreadcrumbList:
            return new BreadcrumbListHandler(ctx, schemasConfig);
        case Schema.Product:
            return new ProductHandler(ctx, schemasConfig);
        case Schema.FAQPage:
            return new FAQPageHandler(ctx, schemasConfig);
        case Schema.Organization:
            return new OrganizationHandler(ctx, schemasConfig);
        case Schema.Offer:
            return new OfferHandler(ctx, schemasConfig);
        case Schema.ContactPoint:
            return new ContactPointHandler(ctx, schemasConfig);
        case Schema.VideoObject:
            return new VideoObjectHandler(ctx, schemasConfig);
        case Schema.WebPage:
            return new WebPageHandler(ctx, schemasConfig);
        case Schema.HowTo:
            return new HowToHandler(ctx, schemasConfig);
        case Schema.Event:
            return new EventHandler(ctx, schemasConfig);
        case Schema.WebSite:
            return new WebSiteHandler(ctx, schemasConfig);
        case Schema.JobPosting:
            return new JobPostingHandler(ctx, schemasConfig);
        default:
            return null;
    }
}

type BaseGetParams = {
    schemasConfig: SchemaConfig[];
    routesConfig: { type: RouteGroup, schemas: string[] }[];
    siteID: string;
}

export default {
    computed: {
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },
    },

    methods: {
        async getStructuredDataMarkup(params: BaseGetParams): Promise<SchemaConfig[]> {
            if (!params.schemasConfig?.length || !params.routesConfig?.length) return [];

            const routeGroup = getRouteGroup(params.siteID, this.$route.path, this.locale);

            if (!routeGroup) return [];

            let schemasConfig = params.schemasConfig.filter(Boolean);
            if (!schemasConfig.length) return [];

            let schemaList: string | any[];

            if (routeGroup === RouteGroup.DefaultWebPage) {
                schemaList = DEFAULT_WEB_PAGE_SCHEMAS;
            } else {
                const routeConfig = params.routesConfig.find((config) => config.type === routeGroup);
                if (!routeConfig) return [];
                schemaList = routeConfig.schemas;
            }

            schemasConfig = params.schemasConfig.filter((config) => config && schemaList.includes(config['@type']));

            if (!schemasConfig?.length) return [];

            const promises = schemasConfig.map((staticConfig) => {
                const type = staticConfig['@type'];
                const handler = getSchemaHandler(this, Schema[type], schemasConfig);

                return handler?.getSchemaData(staticConfig);
            });

            const res = await Promise.all(promises);

            // we need to push search action to every page
            if (!LOCALES_WITHOUT_SEARCH.includes(this.locale)) {
                res.push(getSearchAction(getUrlWithLocale(this)));
            }

            return res
                .flatMap(Object)
                .filter((item) => Object.keys(item).length);
        },
    },
};
