<template>
    <div class="trial-es__success">
        <div class="content">
            <h1 v-if="success.title" class="title">
                {{ success.title }}
            </h1>
            <p v-if="success.lead" class="lead" v-html="success.lead" />
            <p
                v-if="success.bodyText"
                class="bodyText"
                v-html="success.bodyText"
            />
            <a-link
                v-if="success.link"
                class="link"
                v-bind="success.link"
                :size="success.link.size || 'title'"
                :glyph="success.link.glyph || 'arrow'"
            />
        </div>
        <div class="video-wrapper">
            <a-video-card class="video" v-bind="success.video" />
        </div>
        <a-link
            v-if="success.link"
            class="mobile-link"
            v-bind="success.link"
            :size="success.link.size || 'title'"
            :glyph="success.link.glyph || 'arrow'"
        />
    </div>
</template>
<script>
import ALink from '@core/components/link/link.vue';
import AVideoCard from '@core/components/video-card/video-card.vue';

export default {
    components: {
        ALink,
        AVideoCard,
    },
    props: {
        success: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.trial-es__success {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    padding: 70px 0;
    .content {
        @mixin colls 12;
        @media (--viewport-desktop) {
            @mixin colls 6;
        }
        .title {
            @mixin hero;
            margin-bottom: 40px;
            color: var(--av-nav-primary);
        }
        .lead {
            @mixin title;
            margin-bottom: 24px;
            color: var(--av-fixed-secondary);
            @media (--viewport-desktop) {
                margin-bottom: 56px;
            }
        }
        .bodyText {
            @mixin paragraph;
            color: var(--av-fixed-secondary);
            &:deep(a) {
                color: var(--av-brand-primary);
                text-decoration: none;
            }
            @media (--viewport-desktop) {
                margin-bottom: 40px;
            }
        }
        .link {
            display: none;
            @media (--viewport-desktop) {
                display: block;
            }
        }
    }
    .video-wrapper {
        margin: 56px auto 0;
        width: 100%;
        max-width: 560px;
        @media (--viewport-tablet) {
            max-width: 464px;
        }
        @media (--viewport-desktop) {
            margin: 0;
        }
    }
    .mobile-link {
        display: block;
        margin-top: 56px;
        width: 100%;
        @media (--viewport-desktop) {
            display: none;
        }
    }
}
</style>
