<template>
    <s-basic-slice class="s-preferred-support-cards" v-bind="$attrs">
        <div class="s-preferred-support-cards__list">
            <div v-for="(card, i) in cards" :key="i" class="s-preferred-support-cards__list-item">
                <a-small-card v-bind="card" />
                <ul class="s-preferred-support-cards__links">
                    <li v-for="link in card.links" :key="link.url" class="s-preferred-support-cards__links-item">
                        <a class="s-preferred-support-cards__link" :href="link.url">{{ link.title }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </s-basic-slice>
</template>

<script>
import ASmallCard from '@core/components/small-card/small-card.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SCardsSupport',

    components: {
        ASmallCard,
        SBasicSlice,
    },

    inheritAttrs: false,

    props: {
        cards: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-preferred-support-cards {
    &__list-item {
        padding: 24px;
        display: flex;
        width: 100%;
        margin-top: 16px;
        background-color: var(--av-inversed-primary);
        border: var(--av-border);
        border-radius: 4px;
        box-shadow: 0 4px 8px rgba(56, 74, 99, 0.1);
        flex-wrap: wrap;
    }

    &__links {
        margin-top: 8px;
    }

    &__links-item {
        padding-top: 16px;
    }

    &__link {
        text-decoration: none;
        color: var(--av-link-primary);

        &:hover {
            color: var(--av-link-primary-hover);
        }

        &:active {
            color: var(--av-link-primary-active);
        }
    }

    &:deep(.a-small-card) {
        padding: 0;
        width: 100%;
        border: none;
        border-radius: 0;
        box-shadow: none;
        height: auto;

        .title {
            @mixin title;
        }

        .text {
            margin-top: 8px;
            color: rgba(56, 74, 99, 0.7);
        }
    }

    @media (--viewport-mobile-wide) {
        &__list {
            display: flex;
            margin: 0;
            margin-inline-end: -4px;
        }

        &__list-item {
            width: 50%;
            flex-wrap: nowrap;
            flex-direction: column;
            margin: 0 8px;
        }
    }

    @media (--viewport-desktop) {
        &:deep(.a-small-card) {
            padding: 0;
            padding-inline-end: 24px;
            width: 50%;
        }

        &__list-item {
            flex-direction: row;
        }

        &__links {
            width: 50%;
            margin: 0;
            padding: 0;
            padding-inline-start: 24px;
            border-inline-start: 1px solid rgba(56, 74, 99, 0.2);
        }

        &__links-item {
            &:first-child {
                padding: 0;
            }
        }
    }
}
</style>
