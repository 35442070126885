<template>
    <div class="event-resources">
        <h2 v-if="title" class="title">
            {{ title }}
        </h2>
        <div v-if="lead" class="section-lead">
            {{ lead }}
        </div>
        <div class="content">
            <event-resource
                v-for="(item, i) in resourcesToShow"
                v-bind="item"
                :key="i"
                class="resource"
                :download-label="downloadLabel"
            />
        </div>
        <a-modal id="resource-access-form" is-overlay-close>
            <slot />
        </a-modal>
    </div>
</template>
<script>
import AModal from '@core/components/modal/modal.vue';
import EventResource from '../shared-components/event-resource/event-resource.vue';

export default {
    name: 'EventResources',

    components: {
        EventResource,
        AModal,
    },

    props: {
        title: {
            type: String,
            default: null,
        },

        lead: {
            type: String,
            default: null,
        },

        resources: {
            type: Array,
            required: true,
        },

        limit: {
            type: Number,
            default: 0,
        },

        downloadLabel: {
            type: String,
            default: null,
        },
    },

    computed: {
        resourcesToShow() {
            return this.limit > 0
                ? [].concat(this.resources).splice(0, this.limit)
                : this.resources;
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-resources {
    .content {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 16px;

        @media (--viewport-tablet) {
            display: grid;
            column-gap: 16px;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media (--viewport-desktop) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }
}
</style>

<style lang="postcss">
#resource-access-form {
    .inner {
        max-width: 727px;
    }
}
</style>
