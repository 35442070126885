<template>
    <div class="a-rate">
        <div class="a-rate__back">
            <a-svg-sprite
                v-for="i in 5"
                :key="`back_rate_${i}`"
                class="a-rate__icon"
                name="star"
                title="Rate"
            />
        </div>
        <div class="a-rate__front" :style="{ width: percent }">
            <a-svg-sprite
                v-for="i in 5"
                :key="`front_rate_${i}`"
                class="a-rate__icon a-rate__icon_action"
                name="star"
                title="Rate"
            />
        </div>
    </div>
</template>

<script>
import ASvgSprite from '@core/components/svg-sprite/svg-sprite.vue';

export default {
    name: 'ARate',

    components: {
        ASvgSprite,
    },

    props: {
        /**
         * Rating
         */
        rate: {
            type: [Number, String],
            default: 5,
            validator: (value) => value <= 5,
        },
    },

    computed: {
        percent() {
            const value = this.parseRate(this.rate);
            return `${(value * 100) / 5}%`;
        },
    },

    methods: {
        parseRate(raw) {
            const n = Number(raw);
            const prohibitRounding = n % 0.5 === 0;

            return prohibitRounding ? n.toFixed(1) : Math.round(n).toFixed(1);
        },
    },
};
</script>

<style lang="postcss">
.a-rate {
    display: inline-block;
    position: relative;
    margin: 0 -2px;

    &__front,
    &__back {
        display: flex;
    }

    &__front {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        overflow: hidden;

        .a-rate__icon {
            flex-shrink: 0;
        }
    }

    &__icon {
        width: 20px;
        height: 20px;
        fill: var(--av-fixed-invisible);
        margin: 0 0;

        &_action {
            fill: var(--av-fixed-success);
        }
    }
}
</style>
