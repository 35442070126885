<template>
    <section
        :id="id"
        class="s-comparison-tables"
        :class="basicClassName"
    >
        <div class="a-container">
            <span v-if="title" class="title">{{ title }}</span>
            <div class="tables-wrapper">
                <template v-if="!isMobile">
                    <table
                        v-for="(group, i) in groups"
                        :key="`comparison_tables_table_${i}`"
                        class="table"
                    >
                        <thead>
                            <tr>
                                <th
                                    v-for="(header, j) in group.head"
                                    :key="`comparison_table_${i}_head_${j}`"
                                    :style="{ textAlign: header.align || 'start' }"
                                >
                                    {{ header.title }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, rowIndex) in group.rows" :key="`comparison_table_${i}_column${rowIndex}`">
                                <td
                                    v-for="(cell, cellIndex) in row"
                                    :key="cellIndex"
                                    :style="{ textAlign: getAlignment(group, cellIndex) }"
                                >
                                    <a-glyph v-if="cell.glyph" :name="cell.glyph" />
                                    <span v-else>{{ cell.text }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
                <template v-else>
                    <div v-for="(group, i) in mobile.groups" :key="`mobile_groups_${i}`" class="mobile-group">
                        <div class="title">
                            {{ group.title }}
                        </div>
                        <div
                            v-for="(row, j) in group.rows"
                            :key="`mobile_group_${i}_row_${j}`"
                            class="mobile-row a-row justify-center"
                        >
                            <div
                                v-for="(column, c) in row"
                                :key="`mobile_rows_group_${i}_row_${j}_column_${c}`"
                                class="mobile-column"
                                :class="[
                                    column.fontNormal ? '' : 'accent',
                                    column.fullWidth ? 'a-col-12' : 'a-col-6',
                                ]"
                            >
                                <span v-if="column.text">{{ column.text }}</span>
                                <a-glyph v-if="column.glyph" :name="column.glyph" />
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import breakpoint from '@core/mixins/breakpoint';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    components: {
        AGlyph,
    },
    mixins: [breakpoint, contentMixin, styleMixin],
    props: {
        title: {
            type: String,
            default: '',
        },
        groups: {
            type: Array,
            default: () => [],
        },
        mobile: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        getAlignment(group, columnIndex) {
            return group.head[columnIndex]?.align || 'left';
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-comparison-tables {
    padding: 48px 0 64px;
    .title {
        display: block;
        @mixin display-accent;
        text-align: start;
        color: var(--av-nav-primary);
        margin-bottom: 56px;
        @media (--viewport-tablet) {
            @mixin hero-accent;
        }
    }
    .tables-wrapper {
        display: flex;
        max-width: 100%;
        overflow: auto;
        flex-direction: column;
        gap: 32px;
        .table {
            color: var(--av-nav-primary);
            thead {
                box-shadow: 0px 8px 20px 0px #0000000F;
                border-radius: 16px;
                background: var(--av-inversed-primary);
                th {
                    position: relative;
                    @mixin lead-accent;
                    padding: 24px;
                    z-index: 2;

                    &:first-child {
                        border-top-left-radius: 16px;
                        border-bottom-left-radius: 16px;
                    }
                    &:last-child {
                        border-top-right-radius: 16px;
                        border-bottom-right-radius: 16px;
                    }
                }
            }
            tbody {
                tr {
                    border-bottom: 1px solid var(--av-brand-secondary-accent);
                }
                td {
                    @mixin paragraph;
                    padding: 24px;
                    &:deep(.a-glyph) {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
}
.s-comparison-tables {
    text-align: center;
    .mobile-group {
        color: var(--av-nav-primary);
        .title {
            @mixin lead-accent;
            padding: 16px 8px;
            background: #fff;
            margin-bottom: 16px;
            box-shadow: var(--av-shadow-small);
            border-radius: 12px;
            text-align: center;
        }
        .mobile-row {
            @mixin body;
            border-bottom: 1px solid var(--av-brand-secondary-accent);
            margin-bottom: 16px;
            padding-bottom: 16px;
            .mobile-column {
                margin-bottom: 8px;
                .a-glyph {
                    width: 24px;
                    height: 24px;
                }
                &.accent {
                    @mixin body-accent;
                }
            }

        }
    }
}
</style>
