/* eslint-disable func-names */
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import commonUtils from '@utils/common';
import formHelper from '@utils/form.js';

export default {
    data() {
        return {
            isLoading: true,
            cartRequirements: {
                dataCenter: false,
                workloads: false,
                storage: false,
            },
            expandedCart: true,
            detailedSummary: true,
            pdfInProgress: false,
            pdfError: false,
        };
    },
    watch: {
        'form.cloudMsSeats': function (val) {
            if (!val || val < 1) return this.clearRequirement('msStorage', 'cloudMsStorage');
            if ('msStorage' in this.cartRequirements) return false;

            this.$set(this.cartRequirements, 'msStorage', false);
            return this.checkRequirements();
        },
        'form.cloudGoogleWorkspaceSeats': function (val) {
            if (!val || val < 1) return this.clearRequirement('googleStorage', 'cloudGoogleWorkspaceStorage');
            if ('googleStorage' in this.cartRequirements) return false;

            this.$set(this.cartRequirements, 'googleStorage', false);
            return this.checkRequirements();
        },
        'form.globalsDataCenter': async function (val) {
            const dataCenter = this.dataCenters.find((el) => el.code === val);
            this.$store.commit('vroi/setGroup', dataCenter?.group);
            this.checkRequirements();
            await this.init(false);
        },
        requirementsMet() {
            this.dispatchEmptyResize(true, 500);
            this.changePricingMethod(this.recommendedPricingMethod);
        },
        isDiscount() {
            this.dispatchEmptyResize(true, 500);
        },
        recommendedPricingMethod(method) {
            if (this.pricingMethodChanged) return;
            this.changePricingMethod(method);
        },
        async gated(isGated) {
            if (isGated) return;
            await this.initSidebar();
            this.isLoading = false;
        },
        isMobile(isMobile) {
            this.expandedCart = !isMobile;
            if (!isMobile) commonUtils.globalOverflowProperty('auto');
            this.checkSidebar();
        },
    },
    computed: {
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },
        showOnMobile() {
            return this.isMobile && this.expandedCart;
        },
        assistanceLinkFormatted() {
            if (!this.cart.notFilled) return false;
            return this.cart.notFilled.assistance.replace('{link}', `<span>${this.cart.notFilled.linkTitle}</span>`);
        },
        bottomLinkFormatted() {
            if (!this.bottomContact) return false;
            return this.bottomContact.assistance.replace('{link}', `<span>${this.bottomContact.linkTitle}</span>`);
        },
        showMsStorage() {
            return this.hasMsSeats && 'msStorage' in this.cartRequirements;
        },
        showGoogleStorage() {
            return this.hasGoogleSeats && 'googleStorage' in this.cartRequirements;
        },
        hasMsSeats() {
            return this.form.cloudMsSeats >= 1;
        },
        hasGoogleSeats() {
            return this.form.cloudGoogleWorkspaceSeats >= 1;
        },
        isWorkloads20() {
            const keysToSum = [
                'cloudPhysicalServers',
                'cloudVirtualMachines',
                'cloudVmDirector',
                'cloudWorkstations',
                'cloudHostingServers',
                'cloudMsSeats',
                'cloudGoogleWorkspaceSeats',
                'cloudMobileDevices',
                'cloudWebsites',
            ];
            const total = this.sumKeyValues(keysToSum);
            return total >= 20;
        },
        isStorage200() {
            const keysToSum = [
                'cloudStorageAcronisCloud',
                'cloudStorageGoogleAzure',
                'cloudStorageThirdParty',
                'cloudStorageLocal',
            ];
            const total = this.sumKeyValues(keysToSum);
            return total >= 200;
        },
        requirementsMet() {
            return Object.values(this.cartRequirements).every((requirement) => Boolean(requirement));
        },
        requirementsCounter() {
            const requirements = Object.values(this.cartRequirements);
            const metRequirements = requirements.filter((el) => Boolean(el));

            return `(${metRequirements.length}/${requirements.length})`;
        },
        pdfTitle() {
            return this.cart.labels?.export[this.pdfInProgress ? 'progress' : 'generate'];
        },
        detailedSummaryTitle() {
            return this.cart.labels?.view[this.detailedSummary ? 'compact' : 'detailed'];
        },
        cartBackdropVisible() {
            if (!this.isMobile) return false;
            return this.requirementsMet && !this.isDiscount && this.expandedCart;
        },
    },
    methods: {
        findField(reference) {
            return this.fields.find((el) => el.reference === reference);
        },
        checkRequirements() {
            this.cartRequirements.dataCenter = this.form.globalsDataCenter !== null && this.form.globalsDataCenter !== '';
            this.cartRequirements.workloads = this.isWorkloads20;
            this.cartRequirements.storage = this.isStorage200;

            if (this.showMsStorage) {
                this.cartRequirements.msStorage = this.form.cloudMsStorage > 0 || this.form.cloudMsSeats < 1;
            }

            if (this.showGoogleStorage) {
                this.cartRequirements.googleStorage = this.form.cloudGoogleWorkspaceStorage > 0 || this.form.cloudGoogleWorkspaceSeats < 1;
            }
        },
        async clearRequirement(requirement, field) {
            this.$set(this.cartRequirements, requirement, true);
            delete this.cartRequirements[requirement];
            this.changeInput(field, 0);
            await this.$nextTick();
            this.checkRequirements();
        },
        async changeStorageOptionValue(reference, value) {
            this.changeInput('cloudStorageAcronisCloud', 0, false);
            this.changeInput('cloudStorageGoogleAzure', 0, false);
            this.changeInput('cloudStorageThirdParty', 0, false);
            this.changeInput('cloudStorageLocal', 0, false);

            await this.$nextTick();
            this.changeInput(reference, value);
            await this.init();
        },
        downloadPdfClick() {
            formHelper.sendDataLayer({
                event: 'Acronis',
                eventCategory: 'Interactions',
                eventAction: 'documents',
                eventLabel: 'Your_Custom_Service_Provider_Pricing_from_Acronis',
                eventContent: this.selectedPricingMethod?.title,
            });
        },
        async exportPdf() {
            if (this.pdfInProgress || this.pdfError) return;
            this.pdfInProgress = true;
            formHelper.sendDataLayer({
                event: 'Acronis',
                eventCategory: 'Interactions',
                eventAction: 'link',
                eventLabel: 'pdf export',
                eventContent: this.selectedPricingMethod?.title,
            });

            try {
                const timestamp = new Date().toISOString().slice(0, 10).replace(/-/g, '');
                const fileName = `Your_Custom_Service_Provider_Pricing_from_Acronis-${timestamp}.pdf`;

                const data = {
                    defaults: {
                        currency: this.currency,
                        pricingMethod: this.pricingMethod,
                        minimumCommit: this.minimumCommit,
                        minimumCommitAdjustmentLabel: this.cart.labels.minimumCommitAdjustment,
                        minimumCommitAdjustment: this.minimumCommitAdjustment,
                        minimumCommitWarning: this.minimumWarningComputed,
                        discountAfter: this.discountAfter,
                        monthlyTotal: this.monthlyTotal?.formatted,
                    },
                    form: { ...this.form },
                    prices: { ...this.allOutputs },
                };

                const response = await fetch('/public/integrations/vroi-calculator/generate', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data),
                });
                if (response.ok) {
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                } else {
                    this.pdfError = true;
                }
            } catch {
                this.pdfError = true;
                return;
            } finally {
                this.pdfInProgress = false;
            }
        },
        changeAdditional(field, value) {
            this.form.additional[field] = value;
            if (!value) return;

            formHelper.sendDataLayer({
                eventCategory: 'Conversions',
                eventAction: 'Add',
                eventLabel: field,
                eventContent: this.sections.additional.options[field].title,
            });
        },
        toggleDetailedSummary() {
            this.detailedSummary = !this.detailedSummary;
            this.dispatchEmptyResize(false);
        },
        toggleExpandedCart() {
            this.expandedCart = !this.expandedCart;

            if (this.expandedCart && this.cartBackdropVisible) {
                commonUtils.globalOverflowProperty('hidden');
            } else {
                commonUtils.globalOverflowProperty('auto');
            }
        },
        setEvent(item) {
            return item ? { category: 'Interactions', action: 'link', label: item.to } : {};
        },
        dispatchEmptyResize(debounce = true, timer = 150) {
            if (!debounce) return window.dispatchEvent(new Event('resize'));

            clearTimeout(this.resizeTimer);
            this.resizeTimer = setTimeout(() => window.dispatchEvent(new Event('resize')), timer);
            return this.resizeTimer;
        },
    },
};
