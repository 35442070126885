<template>
    <div class="main-header">
        <a-picture
            v-if="background"
            :background="background"
            fit="cover"
            is-background
            persist-original
        />
        <slot />
        <div class="a-container">
            <div v-if="card" class="card">
                <a-label
                    v-if="card.label"
                    v-bind="card.label"
                    class="label"
                >
                    {{ card.label.text }}
                </a-label>
                <h1 class="title">
                    {{ card.title }}
                </h1>
                <p class="description">
                    {{ card.description }}
                </p>
                <a-button
                    v-if="card.button"
                    v-bind="card.button"
                    class="button lime-button"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALabel from '@core/components/label/label.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    components: {
        APicture,
        AButton,
        ALabel,
    },
    props: {
        logo: {
            type: String,
            default: '@7178e73672ff3bb5e7ed0bec61332818',
        },
        logoLink: {
            type: String,
            default: '/',
        },
        smallLogo: {
            type: String,
            default: '@514e56f14ae2093990c379af813cdac1',
        },
        background: {
            type: Object,
            default() {},
        },
        links: {
            type: Array,
            default() {
                return [];
            },
        },
        phone: {
            type: String,
            default: '',
        },
        enrollLink: {
            type: Object,
            default() {},
        },
        card: {
            type: Object,
            default() {},
        },

    },
};
</script>

<style lang="postcss" scoped>
.lime-button {
    background: rgba(177, 237, 29, 1);
    color: var(--av-nav-primary);
    border: none;
    box-shadow: none;
    &:hover {
        background: rgba(155, 194, 37, 1);
    }
    &:active {
        background: rgba(192, 238, 81, 1);
    }
}
.main-header {
    position: relative;
    border-bottom: 2px solid var(--av-inversed-primary);
    padding-top: 380px;
    padding-bottom: 57px;
    @media (--viewport-tablet) {
        padding-top: 450px;
        padding-bottom: 73px;
    }
    @media (--viewport-desktop) {
        padding-top: 148px;
        padding-bottom: 101px;
    }
}

.card {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin: 0 auto;
    padding: 24px 8px;
    text-align: center;
    border-radius: 8px;
    border: 2px solid var(--av-brand-primary);
    background: var(--av-inversed-primary);
    box-shadow: var(--av-shadow-small);
    @media (--viewport-tablet) {
        padding: 24px;
    }
    @media (--viewport-desktop) {
        padding: 32px;
        margin: 0;
        text-align: start;
        max-width: 440px;
        min-height: 440px;
    }

    .label {
        margin: 0 auto 16px;
        padding: 4px 24px;
        margin-bottom: 16px;
        @media (--viewport-desktop) {
            margin: 0;
            margin-bottom: 16px;
        }
    }
    .title {
        @mixin hero-accent;
        color: var(--av-nav-primary);
        margin-bottom: 8px;
        @media (--viewport-desktop) {
            @mixin large-accent;
        }
    }
    .description {
        display: block;
        width: 100%;
        @mixin lead;
        color: var(--av-fixed-light);
        margin-bottom: 16px;
        @media (--viewport-tablet) {
            margin-bottom: 24px;
        }
        @media (--viewport-desktop) {
            margin: 0;
        }
    }
    .button {
        width: 100%;
        @media (--viewport-desktop) {
            margin-top: auto;
        }
    }
}
</style>
