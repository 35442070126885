import SchemaHandler from '@model/seo/schemaHandler';
import { JobPostingSchema, SchemaConfig } from '@model/seo/schemas';

const TWO_YEARS_MS = 62208000000;

export default class JobPostingHandler implements SchemaHandler {
    ctx: any;

    schemasConfig: SchemaConfig[];

    constructor(ctx: any, schemasConfig: SchemaConfig[]) {
        this.ctx = ctx;
        this.schemasConfig = schemasConfig;
    }

    getSchemaData(staticConfig: JobPostingSchema): Promise<JobPostingSchema[]> {
        return this.getRequiredFields(staticConfig);
    }

    async getRequiredFields(staticConfig: JobPostingSchema): Promise<JobPostingSchema[]> {
        await this.ctx.$store.dispatch('job/getAll');

        const jobs = this.ctx.$store.state.job.data;
        const flatJobs = Object.values(jobs).flatMap((item) => Object.values(item).flat());

        return flatJobs.map((job) => ({
            ...staticConfig,
            title: job.title,
            description: '~',
            datePosted: job.date,
            // a hacky solution that was discussed with Adrian
            validThrough: new Date(Date.now() + TWO_YEARS_MS).toISOString(),
            jobLocation: {
                '@type': 'Place',
                name: job.location.split(' ').pop(),
                address: {
                    '@type': 'PostalAddress',
                    name: job.location,
                },
            },
        }));
    }
}
