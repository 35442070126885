<template>
    <section
        :id="id"
        ref="schema"
        class="s-schema"
        :class="{ ...className, ...basicClassName }"
    >
        <div class="a-container">
            <a-slice-header :title="title" :lead="lead" />
            <div class="s-schema__img-container">
                <div class="s-schema__img">
                    <a-picture class="s-schema__picture" :link="image" :alt="imageAlt" />
                    <a-tooltip
                        v-for="(item, index) in tooltips"
                        :key="index"
                        class="s-schema__tooltip"
                        :style="`top: ${item.posY}; inset-inline-start: ${item.posX}`"
                        v-bind="item.data"
                    >
                        <span class="s-schema__tooltip-btn" aria-label="tooltip toggler" />
                    </a-tooltip>
                </div>
            </div>
            <a-link
                v-if="link"
                class="s-schema__link"
                v-bind="link"
                :type="link.type || 'direct'"
                :glyph="link.glyph || 'arrow'"
            />
        </div>
    </section>
</template>

<script>
import ATooltip from '@core//components/tooltip/tooltip.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SSchema',

    components: {
        ALink,
        ATooltip,
        APicture,
        ASliceHeader,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        image: {
            type: String,
            required: true,
        },

        imageAlt: {
            type: String,
            required: true,
        },

        layout: {
            type: String,
            default: '6/6',
        },

        link: {
            type: Object,
            default: undefined,
        },

        tooltips: {
            type: Array,
            required: true,
        },
    },

    data() {
        const classes = {
            intoView: 's-schema_into-view',
        };

        return {
            classes,
            schemaOffsetTop: null,
            windowHeight: null,
        };
    },

    computed: {
        className() {
            return {
                's-schema_layout_6-6': this.layout === '6/6',
                's-schema_layout_4-8': this.layout === '4/8',
                's-schema_layout_8-4': this.layout === '8/4',
                's-schema_layout_12': this.layout === '12',
                's-schema_layout_without-link': !this.link,
            };
        },
    },

    mounted() {
        this.schemaOffsetTop = this.$refs.schema.getBoundingClientRect().top;
        this.windowHeight = document.documentElement.clientHeight;

        window.addEventListener('scroll', this.watchScroll);
        window.addEventListener('resize', this.watchResize);

        this.initAnimation();
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.watchScroll);
        window.removeEventListener('resize', this.watchResize);
    },

    methods: {
        watchScroll() {
            this.schemaOffsetTop = this.$refs.schema?.getBoundingClientRect().top;
            this.initAnimation();
        },

        watchResize() {
            this.windowHeight = document.documentElement.clientHeight;
        },

        initAnimation() {
            if (this.schemaOffsetTop < this.windowHeight / 2) {
                this.$refs.schema?.classList.add(this.classes.intoView);

                window.removeEventListener('scroll', this.watchScroll);
                window.removeEventListener('resize', this.watchResize);
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-schema {
    @mixin basic-slice-paddings;

    &_layout {
        &_12 {
            &:deep(.a-container) {
                @media (--viewport-mobile-wide) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

            &:deep(.a-slice-header) {
                text-align: center;

                @media (--viewport-desktop) {
                    width: 83.33%;
                }

                @media (--viewport-desktop-large) {
                    width: 66.66%;
                }
            }
        }

        &_4-8 {
            &:deep(.a-container) {
                @media (--viewport-desktop) {
                    display: grid;
                    grid-template-columns:
                        calc(((100% - (16px * 11)) / 12) * 5 + (16px * 4))
                        calc(((100% - (16px * 11)) / 12) * 7 + (16px * 6));
                    grid-row-gap: 56px;
                    grid-column-gap: 16px;
                }

                @media (--viewport-desktop-wide) {
                    grid-template-columns:
                        calc(((100% - (16px * 11)) / 12) * 4 + (16px * 3))
                        calc(((100% - (16px * 11)) / 12) * 8 + (16px * 7));
                }
            }

            &:deep(.a-slice-header) {
                @media (--viewport-desktop) {
                    align-self: flex-end;
                    margin-bottom: 0;
                    width: 100%;
                }

                &__title {
                    @media (--viewport-desktop) {
                        width: 100%;
                    }
                }

                &__lead {
                    @media (--viewport-desktop) {
                        width: 100%;
                    }
                }
            }

            .s-schema__img-container {
                @media (--viewport-desktop) {
                    grid-row: span 2;
                    -ms-grid-row-span: 3;
                    width: 100%;
                    -ms-grid-column: 3;
                }
            }

            &:deep(.a-link) {
                @media (--viewport-desktop) {
                    margin-top: 0;
                    -ms-grid-row: 3;
                }
            }
        }

        &_6-6 {
            &:deep(.a-container) {
                @media (--viewport-mobile-wide) {
                    display: grid;
                    grid-template-columns:
                        calc(((100% - (16px * 11)) / 12) * 6 + (16px * 5))
                        calc(((100% - (16px * 11)) / 12) * 6 + (16px * 5));
                    grid-row-gap: 56px;
                    grid-column-gap: 16px;
                }
            }

            &:deep(.a-slice-header) {
                @media (--viewport-mobile-wide) {
                    margin-bottom: 0;
                }

                @media (--viewport-desktop) {
                    align-self: flex-end;
                    margin-bottom: 0;
                    width: 100%;
                }

                &__title {
                    @media (--viewport-desktop-wide) {
                        width: 100%;
                    }
                }

                &__lead {
                    @media (--viewport-desktop-wide) {
                        width: 100%;
                    }
                }
            }

            .s-schema__img-container {
                @media (--viewport-mobile-wide) {
                    grid-row: span 2;
                    -ms-grid-row-span: 3;
                    -ms-grid-column: 3;
                }
            }

            &:deep(.a-link) {
                @media (--viewport-mobile-wide) {
                    margin-top: 0;
                    -ms-grid-row: 3;
                }
            }
        }

        &_8-4 {
            &:deep(.a-container) {
                @media (--viewport-desktop) {
                    display: grid;
                    grid-template-columns:
                        calc(((100% - (16px * 11)) / 12) * 7 + (16px * 6))
                        calc(((100% - (16px * 11)) / 12) * 5 + (16px * 4));
                    grid-row-gap: 56px;
                    grid-column-gap: 16px;
                }

                @media (--viewport-desktop-wide) {
                    grid-template-columns:
                        calc(((100% - (16px * 11)) / 12) * 8 + (16px * 7))
                        calc(((100% - (16px * 11)) / 12) * 4 + (16px * 3));
                }

                @media (--viewport-desktop-large) {
                    grid-template-columns:
                        calc(((100% - (16px * 11)) / 12) * 7 + (16px * 6))
                        calc(((100% - (16px * 11)) / 12) * 5 + (16px * 4));
                }
            }

            &:deep(.a-slice-header) {
                @media (--viewport-desktop) {
                    align-self: flex-end;
                    margin-bottom: 0;
                    width: 100%;
                }

                &__title {
                    @media (--viewport-desktop) {
                        width: 100%;
                    }
                }

                &__lead {
                    @media (--viewport-desktop) {
                        width: 100%;
                    }
                }
            }

            .s-schema__img-container {
                @media (--viewport-desktop) {
                    grid-row: span 2;
                    -ms-grid-row-span: 3;
                    -ms-grid-column: 3;
                }
            }

            &:deep(.a-link) {
                @media (--viewport-mobile-wide) {
                    -ms-grid-row: 3;
                    margin-top: 0;
                }
            }
        }

        &_without-link {
            &:deep(.a-slice-header) {
                align-self: center;
            }

            .s-schema__img-container {
                grid-row: auto;
            }
        }
    }

    &_into-view {
        .s-schema__tooltip {
            transform: scale(1);
        }
    }

    &__img-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__img {
        position: relative;
        width: auto;
    }

    &__link {
        margin-top: 48px;
    }

    &__tooltip {
        position: absolute;
        margin-inline-start: -8px;
        margin-top: -8px;
        transform: scale(0);
        transition: transform 0.2s ease-out;
    }

    &__tooltip-btn {
        padding: 0;
        border: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: var(--av-fixed-success);
        display: block;
        cursor: pointer;
    }
}
</style>
