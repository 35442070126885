<template>
    <div class="product-card">
        <a-link
            v-if="link"
            class="link"
            v-bind="link"
            text=""
        />
        <div v-if="isDesktop" class="image-wrap">
            <a-label
                v-if="labels.length"
                class="label"
                v-bind="labels[0]"
                :theme="labels[0].theme || 'dark'"
                :variant="labels[0].variant || 'success'"
            >
                {{ labels[0].text }}
            </a-label>
            <a-label
                v-else
                class="type"
                theme="dark"
            >
                {{ type }}
            </a-label>
            <a-picture class="image" :link="image" />
        </div>
        <div class="content">
            <div v-if="labels.length" class="labels">
                <a-label
                    v-for="(label, labelIndex) in labels.slice(isDesktop)"
                    :key="`label-${labelIndex}`"
                    v-bind="label"
                    :type="label.variant || 'success'"
                >
                    {{ label.text }}
                </a-label>
            </div>
            <a-label
                v-else
                class="type"
                theme="dark"
            >
                {{ type }}
            </a-label>
            <div class="title">
                {{ title }}
            </div>
            <div class="formerly">
                {{ formerly }}
            </div>
            <div class="text">
                {{ text }}
            </div>
            <a-product-cta
                :settings="{ hasLabel: true, hasDescription: true, ...productPageCTA }"
                use-new-label-design
            />
            <div class="offer" :class="{'full-width': isSingleLineCTAButtons}">
                <a-button
                    v-for="(button, j) in buttons"
                    :key="`button-${j}`"
                    class="button"
                    v-bind="button"
                    :class="{'full-width': isSingleLineCTAButtons}"
                    @click="$emit('click', { url: button.to, type: button.type, label: button.label })"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import AProductCta from '@core/components/product-cta/product-cta.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'AProductCard',
    components: {
        ALink,
        ALabel,
        AProductCta,
        AButton,
        APicture,
    },
    mixins: [breakpoint],
    props: {
        isSingleLineCTAButtons: {
            type: Boolean,
            default: false,
        },
        link: {
            type: Object,
            default: null,
        },
        type: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            default: null,
        },
        formerly: {
            type: String,
            default: null,
        },
        labels: {
            type: Array,
            default: () => [],
        },
        productPageCTA: {
            type: Object,
            default: () => ({}),
        },
        buttons: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['click'],
};
</script>

<style lang="postcss" scoped>
.product-card {
    box-shadow: 0 0 1px rgba(0, 32, 77, 0.1763), 0 2px 6px rgba(0, 32, 77, 0.1);
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid var(--av-nav-secondary);
}

.link {
    display: block;
    position: absolute;
    inset-inline-start: 0;
    height: 100%;
    width: 100%;
    top: 0;
}

.image-wrap {
    display: block;
    position: relative;
    overflow: hidden;
    min-height: 200px;
    pointer-events: none;
}

.image {
    position: absolute;
    width: 100%;
    height: 100%;
    inset-inline-start: 0;
    top: 0;

    &:deep(.a-picture__img) {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.type {
    position: relative;
    margin-bottom: 16px;
    width: fit-content;

    @media (--viewport-desktop) {
        position: absolute;
        inset-inline-start: 24px;
        top: 24px;
        margin-bottom: 0;
    }
}

.labels {
    display: flex;
    margin-bottom: 16px;

    .a-label {
        &:not(:last-child) {
            margin-inline-end: 8px;
        }
    }

    @media (--viewport-tablet) {
        display: block;

        .a-label {
            &:not(:first-child) {
                margin-top: 8px;
            }
        }
    }

    @media (--viewport-desktop) {
        margin: 0;

        .a-label {
            &:not(:last-child) {
                margin-top: 16px;
            }
        }
    }
}

.label {
    top: 24px;
    position: absolute;
    inset-inline-start: 24px;
}

.content {
    padding: 24px;
    color: var(--av-nav-primary);
    background: var(--av-inversed-primary);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.title {
    @mixin title-accent;
    margin-bottom: 16px;

    @media (--viewport-desktop) {
        margin-bottom: 16px;
    }
}

.formerly {
    @mixin lead-accent;
    margin-bottom: 8px;
    color: var(--av-nav-primary);

    @media (--viewport-desktop) {
        margin-bottom: 5px;
    }
}

.text {
    @mixin body;
    margin-bottom: 24px;
    color: var(--av-fixed-light);
    max-width: 95%;

    @media (--viewport-tablet) {
        margin-bottom: 40px;
        max-width: 100%;
    }

    @media (--viewport-desktop) {
        @mixin paragraph;
        margin-bottom: 24px;
    }
}

.offer {
    position: relative;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    flex-wrap: wrap;
    margin-top: auto;

    &.full-width {
        flex-wrap: wrap;
    }

    @media (--viewport-desktop) {
        flex-wrap: nowrap;
    }
}

.button {
    @mixin colls 12;
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin-top: 16px;
    height: 48px;

    &.full-width {
        @mixin colls 12;
        margin-top: 16px;
    }

    &:first-child {
        margin-top: 0;
    }

    @media (--viewport-desktop) {
        @mixin colls 6;
        height: 40px;
        margin-top: 0;

        &.full-width:not(:first-child) {
            margin-top: 16px;
        }
    }
}

.cta {
    margin: auto 0 24px;

    ~ .offer {
        flex-direction: row-reverse;
        margin-top: unset;
    }
}

</style>
