<template>
    <section class="acpc-features">
        <div class="a-container">
            <div class="head">
                <h4 v-if="title" class="title">
                    {{ title }}
                </h4>
                <a-link
                    v-if="link"
                    class="link"
                    v-bind="link"
                    :size="link.size || 'title'"
                />
            </div>
            <div class="features">
                <div
                    v-for="(feature, i) in features"
                    :key="`acpc_feature_${i}`"
                    class="feature"
                    :class="{ 'scrollable': isScrollable(feature?.type) }"
                >
                    <template v-if="isScrollable(feature?.type)">
                        <div class="inner-wrapper">
                            <h6 v-if="feature.title" class="title">
                                {{ feature.title }}
                            </h6>
                            <p v-if="feature.description" class="description" v-html="feature.description" />
                            <a-button
                                v-if="feature.button"
                                v-bind="feature.button"
                                @click="scrollTo(feature.hash)"
                            />
                        </div>
                        <a-picture
                            v-if="feature.picutre"
                            is-background
                            :background="feature.picutre"
                            fit="cover"
                            position="top right"
                        />
                    </template>
                    <template v-else>
                        <div class="heading">
                            <h6 v-if="feature.title" class="title">
                                {{ feature.title }}
                            </h6>
                            <a-label v-if="feature.label" v-bind="feature.label" :variant="feature.label.variant || 'success'">
                                {{ feature.label.text }}
                            </a-label>
                        </div>
                        <a-media
                            v-bind="feature?.media"
                            :modal-id="`modal-feature-picture_${i}`"
                            class="preview"
                        />
                        <p v-if="feature.description" class="description" v-html="feature.description" />
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import AButton from '@core/components/button/button.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import AMedia from '@core/components/media/media.vue';
import APicture from '@core/components/picture/picture.vue';

const PREVIEW_HOVER_CLASS = 'preview--hover';

export default {
    name: 'SProductFeatures',
    components: {
        ALabel,
        ALink,
        AMedia,
        AButton,
        APicture,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        link: {
            type: Object,
            default: null,
        },
        features: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            SCROLL_MARGIN: 16,
        };
    },
    methods: {
        isScrollable(type) {
            if (!type) return false;
            return type === 'scrollable';
        },
        scrollTo(hash) {
            if (!('scrollBehavior' in document.documentElement.style)) return;

            const el = document.querySelector(hash);
            if (!el) return;

            window.scroll({
                top: el.getBoundingClientRect().top + window.scrollY - this.SCROLL_MARGIN,
                behavior: 'smooth',
            });
        },
        onPreviewHover(event, addHoverClass) {
            const method = addHoverClass ? 'add' : 'remove';
            event.currentTarget.classList[method](PREVIEW_HOVER_CLASS);
        },
        onPreviewClose(el) {
            el?.classList.remove(PREVIEW_HOVER_CLASS);
        },
    },
};
</script>

<style lang="postcss" scoped>
.acpc-features {
    padding: 64px 0;
    .head {
        display: flex;
        margin-bottom: 40px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;

        @media (--viewport-tablet) {
            gap: 0;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
        }
        .title {
            @mixin display-accent;
            color: var(--av-nav-primary);
        }
        .link {
            font-weight: 700;
        }
    }
    .features {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        gap: 16px;
        @media (--viewport-desktop) {
            gap: 40px 16px;
        }

        .feature {
            @mixin colls 12;
            @media (--viewport-desktop) {
                @mixin colls 4;
            }
            .heading {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: flex-start;
            }
            &:not(.scrollable) {
                .title {
                    @mixin lead-accent;
                    color: var(--av-nav-primary);
                }
                .preview {
                    @mixin colls 12;
                    margin: 16px 0;
                    :deep(.enlarged-image) {
                        .a-picture {
                            height: 148px;
                            img {
                                max-width: unset;
                                width: 100%;
                                object-fit: cover !important;
                                object-position: top right !important;
                            }
                        }
                    }
                }
                .description {
                    @mixin paragraph;
                    color: var(--av-fixed-primary);
                }
            }
            &.scrollable {
                position: relative;
                border-radius: 8px;
                border: 1px solid var(--av-brand-secondary-light);
                box-shadow: var(--av-shadow-regular);
                overflow: hidden;
                min-height: 294px;
                .inner-wrapper {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    padding: 16px;
                    .title {
                        @mixin nav-title-accent;
                        color: var(--av-nav-primary);
                        max-width: 213px;
                        @media (--viewport-tablet) {
                            max-width: 509px;
                        }
                        @media (--viewport-desktop) {
                            max-width: 203px;
                        }
                    }
                    .description {
                        @mixin body;
                        color: var(--av-fixed-primary);
                        margin: auto 0;
                    }
                }

                &:deep(.a-picture__img) {
                    z-index: -1;
                }
                &:deep(.a-button) {
                    width: 100%;
                    justify-content: center;
                    padding: 8px 16px;
                    border-radius: 8px;
                }
            }
        }
    }
}
</style>
