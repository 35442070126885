<template>
    <div class="root">
        <client-only>
            <a
                class="twitter-timeline"
                :data-width="TwitterMeta.width"
                :data-height="TwitterMeta.height"
                :data-theme="TwitterMeta.theme"
                :data-chrome="TwitterMeta.chrome"
                :data-lang="TwitterMeta.lang"
                :href="TwitterMeta.href"
            />
            <script
                async
                type="application/javascript"
                :src="TwitterMeta.scriptSrc"
                charset="utf-8"
            />
        </client-only>
    </div>
</template>

<script>
import ClientOnly from '@core/components/client-only/client-only.vue';

const TwitterMetaDefault = {
    width: 394,
    height: 1100,
    chrome: 'transparent noborders',
    theme: 'light',
    scriptSrc: 'https://platform.twitter.com/widgets.js',
};

export default {
    name: 'BlogTwitterSidebar',

    components: {
        ClientOnly,
    },

    props: {
        feedAddress: {
            type: String,
            required: false,
            default: () => 'https://x.com/Acronis?ref_src=twsrc%5Etfw',
        },

        uiLanguage: {
            type: String,
            required: false,
            default: () => 'en',
        },
    },

    data() {
        return {
            TwitterMeta: {
                ...TwitterMetaDefault,
                href: this.feedAddress,
                lang: this.uiLanguage,
            },
        };
    },
};
</script>

<style lang="postcss" scoped>
.root {
    text-align: center;
    background: var(--av-brand-accent);
    border-radius: 4px;
}

.twitter-timeline {
    color: var(--av-brand-secondary);
}
</style>
