<template>
    <div v-if="content" class="sparkle" v-html="content" />
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'InAppComponent',

    computed: {
        ...mapState({
            content: (state) => state.inapp?.translation?.content || '',
        }),
    },
};
</script>

<style lang="postcss">
.inapp {
    body {
        -webkit-font-smoothing: unset;
        -moz-osx-font-smoothing: unset;
    }
    .sparkle {
        margin: 8px;
        font-size: 13px;
        font-weight: 400;
        font-family: Times, sans-serif;
        line-height: normal;
        margin-block-start: -3px;
        ul {
            list-style-type: disc;
            margin-block-end: 16px;
            margin-block-start: 16px;
            padding-inline-start: 40px;
        }
        p {
            margin-block-end: 16px;
            margin-block-start: 16px;
        }
        b {
            font-size: 14px;
            font-weight: 700;
        }
        a {
            text-decoration: underline
        }
    }
}
</style>
