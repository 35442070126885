<template>
    <s-basic-slice class="s-cta-block" v-bind="$attrs">
        <div class="s-cta-block__buttons" :class="{ 's-cta-block__buttons_three': buttons.length >= 3 }">
            <div v-for="(item, index) in buttons" :key="`s-cta-block-${index}`" class="s-cta-block__button">
                <a-button
                    class="a-submenu__main-action"
                    v-bind="item"
                    :size="item.size || 'm'"
                    :event="setEvent(item)"
                />
                <div
                    v-for="(info, infoIndex) in item.info"
                    :key="`s-cta-block-info-${infoIndex}`"
                    class="s-cta-block__item"
                >
                    <span>{{ info.title }}</span>
                    <a-dangerous-html v-if="info.text" :content="info.text" />
                </div>
            </div>
        </div>
        <a-dangerous-html v-if="text" class="s-cta-block__text" :content="text" />
    </s-basic-slice>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SCtaBlock',
    components: {
        SBasicSlice,
        AButton,
        ADangerousHtml,
    },

    props: {
        buttons: {
            type: Array,
            default: undefined,
        },

        text: {
            type: String,
            default: undefined,
        },
    },

    methods: {
        setEvent(item) {
            return item && item.sendEventGA ? { label: item.to, ...item.sendEventGA } : {};
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-cta-block {
    &__buttons {
        display: flex;
        flex-wrap: wrap;

        &_three {
            flex-direction: column;

            .s-cta-block__button {
                max-width: 212px;
            }
            @media (--viewport-tablet) {
                flex-direction: inherit;
                .s-cta-block__button {
                    max-width: none;
                }
            }
        }

        @media (--viewport-tablet) {
            flex-wrap: nowrap;
        }
    }
    &__button {
        margin-inline-end: 24px;
        min-width: 212px;
        margin-bottom: 40px;

        @media (--viewport-tablet) {
            margin-bottom: 0;
        }
        @media (--viewport-desktop) {
            margin-inline-end: 40px;
        }
        .a-button {
            width: 100%;
            margin-bottom: 16px;
        }
        &:last-child {
            margin-inline-end: 0;
            margin-bottom: 0;
        }
    }
    &__item {
        display: flex;
        @mixin body;
        span {
            @mixin body-accent;
            margin-inline-end: 8px;
        }
    }
    &__text {
        color: var(--av-fixed-secondary);
        margin-top: 40px;
        @mixin body;
        @media (--viewport-tablet) {
            @mixin paragraph;
            @mixin colls 11;
        }
        @media (--viewport-desktop) {
            @mixin colls 8;
        }
        @media (--viewport-desktop-wide) {
            @mixin colls 5;
        }
    }
}
</style>
