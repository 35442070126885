<template>
    <div class="integration-body">
        <div class="a-container">
            <div v-if="slides || title || lead" class="main">
                <div v-if="slides" class="slider">
                    <a-slider v-if="slides.length > 1" :options="sliderOptions">
                        <a-slide v-for="(slide, i) in slides" :key="`slide_${i}`">
                            <a-media
                                v-if="slide.link"
                                class="image"
                                type="screenshot"
                                :link="slide.link"
                                :enlarged-image="slide.link"
                                :modal-id="slide.link"
                                fit="cover"
                                disable-modal
                                :is-shadow="false"
                            />
                            <a-youtube v-else-if="slide.video_url" :yt-id="getYoutubeId(slide.video_url)" />
                        </a-slide>
                    </a-slider>
                    <template v-else>
                        <a-media
                            v-if="slides[0].link"
                            class="image"
                            type="screenshot"
                            :link="slides[0].link"
                            :enlarged-image="slides[0].link"
                            :modal-id="slides[0].link"
                            fit="cover"
                            disable-modal
                            :is-shadow="false"
                        />
                        <a-youtube v-else-if="slides[0].video_url" :yt-id="getYoutubeId(slides[0].video_url)" />
                    </template>
                </div>
                <div class="text">
                    <h3 v-if="title" class="title">
                        {{ title }}
                    </h3>
                    <div v-if="lead" class="lead">
                        {{ lead }}
                    </div>
                </div>
            </div>

            <div v-if="features" class="features">
                <h3 class="title">
                    {{ featuresTitle }}
                </h3>
                <div class="features-list">
                    <div
                        v-for="(feature, i) in features"
                        :key="`feature_${i}_${feature.title}`"
                        class="feature"
                    >
                        <div class="feature-title">
                            {{ feature.title }}
                        </div>
                        <p v-if="feature.description">
                            {{ feature.description }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="localizations" class="localizations">
                <h3 class="title">
                    {{ localizationsTitle }}
                </h3>
                <div class="localizations-list">
                    <div
                        v-for="localization in localizations"
                        :key="localization"
                        class="localization"
                    >
                        {{ localization }}
                    </div>
                </div>
            </div>

            <div v-if="infoText" class="other-info">
                <h3 v-if="infoTitle" class="title">
                    {{ infoTitle }}
                </h3>
                <p v-if="infoText">
                    {{ infoText }}
                </p>
            </div>
        </div>

        <a-modal
            v-for="slide in slides"
            :id="slide.link"
            :key="slide.link"
            is-overlay-close
            type="screenshot"
        >
            <template #default>
                <img class="enlargedImage" :src="slide.link" />
            </template>
        </a-modal>
    </div>
</template>

<script>
import AMedia from '@core/components/media/media.vue';
import AModal from '@core/components/modal/modal.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import AYoutube from '@core/components/youtube/youtube.vue';

export default {
    name: 'SIntegrationBody',

    components: {
        AModal,
        ASlide,
        ASlider,
        AYoutube,
        AMedia,
    },

    props: {
        title: {
            type: String,
            default: null,
        },

        lead: {
            type: String,
            default: null,
        },

        slides: {
            type: Array,
            default: null,
        },

        features: {
            type: Array,
            default: null,
        },

        featuresTitle: {
            type: String,
            default: 'Features',
        },

        localizations: {
            type: Array,
            default: null,
        },

        localizationsTitle: {
            type: String,
            default: 'Localizations',
        },

        infoTitle: {
            type: String,
            default: null,
        },

        infoText: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            sliderOptions: {
                showNavigation: true,
                loop: false,
                showCounter: false,
                slidesPerView: 1,
            },
        };
    },

    methods: {
        getYoutubeId(videoUrl) {
            // this regex for videos is used in admin panel that uses the same API
            const videoIdRegex = /(?:youtube(?:-nocookie)?\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
            const regexMatches = videoUrl.match(videoIdRegex);
            if (regexMatches?.length >= 2) {
                return regexMatches[1];
            }
            return videoUrl;
        },
    },
};
</script>

<style lang="postcss" scoped>
.main,
.features-list {
    display: grid;
    width: 100%;
    gap: 32px;

    @media (--viewport-tablet) {
        grid-template-columns: 1fr 1fr;
    }
}

.title {
    @mixin title;
}

.lead {
    @mixin body;

    padding-top: 16px;
}

.main,
.features,
.localizations,
.other-info {
    padding-block: 24px;
    border-bottom: 1px solid var(--av-brand-accent);
}

.slider {
    overflow: hidden;
}

.features-list {
    @mixin body;

    row-gap: 16px;
    padding-top: 16px;
}

.feature-title {
    @mixin body-accent;
}

.image {
    &:deep(.a-picture__img) {
        max-height: 600px;
    }
}

.a-youtube {
    height: 320px;
}

.localizations-list {
    margin-top: 16px;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    row-gap: 8px;
    column-gap: 32px;

    @media (--viewport-tablet) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (--viewport-desktop) {
        grid-template-columns: repeat(6, 1fr);
    }
}

.localization {
    @mixin body;
}

.other-info {
    @mixin body;

    .title {
        margin-bottom: 16px;
    }
}

</style>
