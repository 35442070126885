<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import structuredData from '@core/mixins/structuredData';
import { SECTION_ID_BLOG, SECTION_ID_CPC } from '@model/const/blog';
import { CATEGORIES_COUNT, WEBINARS_AND_VIRTUAL_COUNT } from '@model/const/events';
import { LOCALE_DEFAULT } from '@model/const/locales';
import {
    RC_BLOG_NEWS_AMOUNT,
    RC_BLOG_NEWS_FIELDS_SUBSET,
    RC_CPC_NEWS_AMOUNT,
    RC_CPC_NEWS_FIELDS_SUBSET,
    RC_LOCALES_BLOG_AVAILABLE,
    RC_LOCALES_CPC_AVAILABLE,
    RC_MAX_FEATURED_RESOURCES,
    RC_RESOURCE_FIELDS_SUBSET_SHORT,
    RC_SPECIFIC_RESOURCES_AMOUNT,
} from '@model/const/resource-center';
import { assertHttpErrors } from '@utils/api-response';
import { getHtmlLang, getTextDirection } from '@utils/html-meta';
import { assertLocaleIsAllowed } from '@utils/resource-center';
import ResourceCenterHomeComponent from './component.vue';

const CASE_STUDY_ID = 5;
const ANALYST_REPORTS_ID = 18;
const COUNTRY_ID_UNITED_KINGDOM = 237;
const COUNTRY_ID_UNITED_STATES = 238;

export default Vue.extend({
    name: 'ResourceCenterHomeContainer',

    async serverPrefetch(): Promise<void> {
        const locale = this.$route.params.locale;

        assertLocaleIsAllowed(this.$store, locale);

        // if (RC_LOCALES_FOR_SERP_REDIRECT.has(locale)) {
        //     const redirectURL = `/${locale}/resource-center/search/`;
        //     const rejection = new HttpRejection('Premature rendering stop', StatusCodes.MOVED_PERMANENTLY);
        //     rejection.addHeader('Location', redirectURL);
        //     throw rejection;
        // }

        const audiencesRequest = new ProductAPIQueryBuilder('audiences')
            .setEntityPath('/api/resources/audiences/')
            .setLocales([locale])
            .toObject();

        const bannersRequest = new ProductAPIQueryBuilder('banners')
            .setEntityPath('/api/resources/banners/')
            .setLocales([locale])
            .toObject();

        const localesRequest = new ProductAPIQueryBuilder('locales')
            .setEntityPath('/api/gis/locales/')
            .addMatchesAll('is_active_on_resource_center', '=', '1')
            .toObject();

        const featuredRequest = new ProductAPIQueryBuilder('featuredResources')
            .setEntityPath('/api/resources/resources/')
            .setLocales([locale])
            .setCustomParam('process-macros', '1')
            .setPaginate(1, RC_MAX_FEATURED_RESOURCES)
            .addMatchesAll('type_id', 'not in', [CASE_STUDY_ID, ANALYST_REPORTS_ID])
            .addMatchesAll('translation.is_featured', '=', '1')
            .addSort('translation.published_at', 'desc')
            .setOutputOnly(RC_RESOURCE_FIELDS_SUBSET_SHORT)
            .toObject();

        const caseStudyRequest = new ProductAPIQueryBuilder('caseStudyResources')
            .setEntityPath('/api/resources/resources/')
            .setLocales([locale])
            .setCustomParam('process-macros', '1')
            .setPaginate(1, RC_SPECIFIC_RESOURCES_AMOUNT)
            .addMatchesAll('translation.is_featured', '=', '1')
            .addMatchesAll('type_id', '=', '5')
            .addSort('translation.published_at', 'desc')
            .setOutputOnly(RC_RESOURCE_FIELDS_SUBSET_SHORT)
            .toObject();

        const analystReportsRequest = new ProductAPIQueryBuilder('analystReportsResources')
            .setEntityPath('/api/resources/resources/')
            .setLocales([locale])
            .setCustomParam('process-macros', '1')
            .setPaginate(1, RC_SPECIFIC_RESOURCES_AMOUNT)
            .addMatchesAll('type_id', '=', '18')
            .addSort('translation.published_at', 'desc')
            .setOutputOnly(RC_RESOURCE_FIELDS_SUBSET_SHORT)
            .toObject();

        const seoPromises = [
            this.$store.dispatch('seo/getSyncedData', { key: 'routes-config', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-webpage', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-organization', locale }),
        ];

        const promises = [
            this.$store.dispatch('slices/getSyncedData', { slice: 's-main-header', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-global-footer', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'resource-center', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'resource-home-page-navigation', locale }),
            this.$store.dispatch('resourceCenter/getEntity', { request: audiencesRequest }),
            this.$store.dispatch('resourceCenter/getEntity', { request: bannersRequest }),
            this.$store.dispatch('resourceCenter/getEntity', { request: localesRequest }),
            this.$store.dispatch('resourceCenter/getEntity', { request: featuredRequest }),
            this.$store.dispatch('resourceCenter/getEntity', { request: caseStudyRequest }),
            this.$store.dispatch('resourceCenter/getEntity', { request: analystReportsRequest }),
            this.$store.dispatch('countries/getCountries'),
            ...seoPromises,
        ];

        if (RC_LOCALES_CPC_AVAILABLE.has(locale)) {
            const cpcNewsRequest = new ProductAPIQueryBuilder('insightsNews')
                .setEntityPath('/api/blog/posts/')
                .setLocales([locale])
                .addMatchesAll('is_news', '=', '1')
                .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
                .setCustomParam('process-macros', '1')
                .setOutputOnly(RC_CPC_NEWS_FIELDS_SUBSET)
                .addSort('translation.published_at', 'desc')
                .addSort('id', 'desc')
                .setPaginate(1, RC_CPC_NEWS_AMOUNT)
                .toObject();

            promises.push(this.$store.dispatch('blog/getEntity', { request: cpcNewsRequest }));
        }

        if (RC_LOCALES_BLOG_AVAILABLE.has(locale)) {
            const blogPostsRequest = new ProductAPIQueryBuilder('latestBlogPosts')
                .setEntityPath('/api/blog/posts/')
                .setLocales([locale])
                .addMatchesAll('is_news', '<>', '1')
                .addMatchesAll('section_id', '=', SECTION_ID_BLOG.toString())
                .setCustomParam('process-macros', '1')
                .setOutputOnly(RC_BLOG_NEWS_FIELDS_SUBSET)
                .addSort('translation.published_at', 'desc')
                .addSort('id', 'desc')
                .setPaginate(1, RC_BLOG_NEWS_AMOUNT)
                .toObject();

            promises.push(this.$store.dispatch('blog/getEntity', { request: blogPostsRequest }));
        }

        await Promise.all(promises);

        assertHttpErrors([
            { entity: this.$store.state.resourceCenter.locales, throwIfEmpty: true },
            { entity: this.$store.state.resourceCenter.audiences },
            { entity: this.$store.state.resourceCenter.banners },
            { entity: this.$store.state.resourceCenter.featuredResources },
            { entity: this.$store.state.resourceCenter.caseStudyResources },
            { entity: this.$store.state.resourceCenter.analystReportsResources },
            { entity: this.$store.state.blog.insightsNews },
            { entity: this.$store.state.blog.latestBlogPosts },
        ]);

        const categoriesRequest = new ProductAPIQueryBuilder('categories')
            .setEntityPath('/api/events/categories/')
            .setPaginate(1, CATEGORIES_COUNT)
            .setOutputOnly(['id', 'title'])
            .toObject();

        await Promise.all([
            this.$store.dispatch('events/getEntity', { request: categoriesRequest }),
            this.loadWebinarsAndVirtualEvents({ webinarsCategoryId: 'all', countryId: this.countryId }),
        ]);

        // Building page meta
        this.$ssrContext.res.meta = await this.getMeta();
    },

    mixins: [structuredData],

    computed: {
        ...mapGetters('config', ['$config']),
        ...mapGetters('seo', ['getRoutesConfig', 'getStaticConfigs']),

        locale() {
            return this.$route.params.locale || LOCALE_DEFAULT;
        },

        countries() {
            return this.$store.state.countries.items;
        },

        countryId() {
            if (['en-eu', 'en-gb'].includes(this.locale)) return COUNTRY_ID_UNITED_KINGDOM;

            const isoCode = this.locale.split('-')[1].toUpperCase();
            return this.countries?.find((c) => c.iso_code === isoCode)?.id || COUNTRY_ID_UNITED_STATES;
        },
    },

    methods: {
        async getMeta(): any {
            const locale = this.$route.params.locale;
            const uiStrings = this.$store.state.slices.items['resource-center'];
            const canonical = `https://${this.$config.domain}${this.$route.path}`;

            const title = uiStrings.homePageMetaTitle;
            const description = uiStrings.homePageMetaDescription;

            const ldJsonSchema = await this.getStructuredDataMarkup({
                siteID: this.$config.siteID,
                domain: this.$config.domain,
                schemasConfig: this.getStaticConfigs,
                routesConfig: this.getRoutesConfig,
            });

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'link', rel: 'canonical', href: canonical },
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: getHtmlLang(locale),
                },
                ldJsonSchema,
            };
        },

        loadWebinarsAndVirtualEvents(searchQuery: any) {
            const categoryId = searchQuery.webinarsCategoryId !== 'all' ? searchQuery.webinarsCategoryId : null;

            const hasCriteria: Record<string, any> = {};
            if (searchQuery.countryId) hasCriteria.countries = [searchQuery.countryId];
            if (searchQuery.category) hasCriteria.categories = [categoryId];

            const query = new ProductAPIQueryBuilder('virtualEvents')
                .setEntityPath('/api/events/events/')
                .setCustomParam('has-occurrences', { from: new Date().toISOString() })
                .addMatchesAll('is_virtual', '=', '1')
                .addMatchesAll('is_for_partners', '<>', '1')
                .setCustomParam('has', hasCriteria)
                .addSort('is_featured', 'desc')
                .addSort('dynamic_next_occurrence', 'asc')
                .setCustomParam('process-macros', '1')
                .setPaginate(1, WEBINARS_AND_VIRTUAL_COUNT);

            const request = query.toObject();

            return this.$store.dispatch('events/getEntity', { request });
        },
    },

    render(h) {
        const props = {
            locale: this.locale,
            countryId: this.countryId,
            dispatchLoadWebinars: this.loadWebinarsAndVirtualEvents,
        };
        return h(ResourceCenterHomeComponent, { props });
    },
});
</script>
