<template>
    <div class="academy-ribbon">
        <div class="a-container">
            <div class="wrapper">
                <a-picture v-if="icon" class="icon" :link="icon" />
                <span v-if="text" class="text">{{ text }}</span>
                <a-picture v-if="image" class="image" :link="image" />
                <a-button v-if="button" class="button" v-bind="button" />
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    components: {
        AButton,
        APicture,
    },
    props: {
        icon: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        image: {
            type: String,
            default: '',
        },
        button: {
            type: Object,
            default() {},
        },
    },
};
</script>

<style lang="postcss" scoped>
.academy-ribbon {
    background: rgba(177, 237, 29, 1);
    color: var(--av-nav-primary);
    border-bottom: 6px solid var(--av-inversed-primary);
    padding: 16px 0 10px;
    @media (--viewport-tablet) {
        padding: 24px 0 18px;
    }
    @media (--viewport-desktop) {
        padding: 0;
    }
    .wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        overflow: hidden;
        @media (--viewport-desktop) {
            flex-flow: row nowrap;
            height: 82px;
        }
        .icon {
            max-width: 32px;
            max-height: 32px;
            margin-inline-end: 16px;
        }
        .text {
            @mixin lead-accent;
            width: calc(100% - 48px);
            font-weight: 800;
            @media (--viewport-tablet) {
                width: 340px;
            }
            @media (--viewport-desktop-wide) {
                @mixin title-accent;
                font-weight: 800;
                width: auto;
            }
        }
        .image {
            display: none;
            @media (--viewport-desktop) {
                display: block;
            }
        }
        .button {
            @mixin lead-accent;
            margin-top: 16px;
            width: 100%;
            padding: 10px 46px;
            background: var(--av-nav-primary);
            color: var(--av-inversed-primary);
            @media (--viewport-tablet) {
                margin-inline-start: auto;
                margin-top: 0;
                width: auto;
            }
            @media (--viewport-desktop-wide) {
                padding: 10px 36px;
            }
            &:hover {
                background: var(--av-brand-primary);
            }
            &:active {
                background: var(--av-nav-secondary);
            }
        }
    }
}
</style>
