import { render, staticRenderFns } from "./content-cta.vue?vue&type=template&id=ff4f7466&scoped=true"
import script from "./content-cta.vue?vue&type=script&lang=js"
export * from "./content-cta.vue?vue&type=script&lang=js"
import style0 from "./content-cta.vue?vue&type=style&index=0&id=ff4f7466&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff4f7466",
  null
  
)

export default component.exports