<template>
    <section :id="id" class="s-slice s-wrapper-slice" :class="{ ...className, ...basicClassName }">
        <component
            :is="slice.name"
            v-for="(slice, index) in slices"
            v-bind="slice.data"
            :key="index"
            class="s-wrapper-slice__item"
            :border-bottom="slice.data.borderBottom || isInnerBorderBottom(slices, index)"
        />
    </section>
</template>

<script>
// NB: Using s-wrapper-slice is discouraged because it adds complexity to the content JSON,
// while its goals (paddings/margins) are achievable via mt-* / pt-* modifiers
// Removed the stories.js for it to not motivate the usage
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import { INDENTS, BOTTOM_PADDINGS, BORDERS } from '@core/slices/pages/basic-slice/constants.js';

export default {
    name: 'SWrapperSlice',

    mixins: [contentMixin, styleMixin],

    props: {
        slices: {
            type: Array,
            default: () => [],
        },

        firstChildTopPadding: {
            type: Boolean,
            default: false,
        },

        bottomPadding: {
            type: String,
            default: '',
            validator: (value) => !value || BOTTOM_PADDINGS.includes(value),
        },

        indent: {
            type: String,
            default: '',
            validator: (value) => !value || INDENTS.includes(value),
        },

        borderTop: {
            type: String,
            default: '',
            validator: (value) => !value || BORDERS.includes(value),
        },

        borderBottom: {
            type: String,
            default: '',
            validator: (value) => !value || BORDERS.includes(value),
        },

        innerBorderBottom: {
            type: String,
            default: '',
            validator: (value) => !value || BORDERS.includes(value),
        },
    },

    computed: {
        className() {
            const classes = {};
            if (this.indent) {
                classes[`s-slice_indent_${this.indent}`] = this.indent;
            }
            if (this.borderTop) {
                classes['s-slice_border-top'] = this.borderTop;
                classes[`s-slice_border-top_${this.borderTop}`] = this.borderTop;
            }
            if (this.borderBottom) {
                classes['s-slice_border-bottom'] = this.borderBottom;
                classes[`s-slice_border-bottom_${this.borderBottom}`] = this.borderBottom;
            }
            if (this.firstChildTopPadding) {
                classes['s-slice_first-child-padding'] = this.firstChildTopPadding;
            }
            if (this.bottomPadding) {
                classes[`s-slice_${this.bottomPadding}-padding`] = this.bottomPadding;
            }
            return classes;
        },
    },

    methods: {
        isInnerBorderBottom(arr, index) {
            if (arr.length - 1 !== index) {
                return this.innerBorderBottom;
            }
            return '';
        },
    },
};
</script>

<style lang="postcss" scoped>
@mixin indents-borders;
.s-wrapper-slice {
    padding-top: 0;
    padding-bottom: 0;

    &_indent {
        &_large {
            &:deep(> .s-slice) {
                &.s-feature-group:nth-child(2n):not(:first-child) {
                    padding-top: 64px;
                }
            }
        }
    }
}
</style>
