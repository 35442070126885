<template>
    <div :class="['events-page', { 'on-demand-layout': hasOnDemandLayout }]">
        <div class="main-content">
            <div :class="['header', { light: theme === 'light' }]">
                <event-menu class="menu" v-bind="menu">
                    <template #locale-select>
                        <event-locale-select v-if="localeSelect.isReady" v-bind="localeSelect" @select="setLocation" />
                    </template>
                </event-menu>
                <event-head v-if="!hasOnDemandLayout" v-bind="head" />
                <event-on-demand-head
                    v-else
                    v-bind="headForOnDemand"
                    ref="on-demand-head"
                    :sticky-menu="stickyMenu"
                    @progressChanged="onProgressChange($event)"
                >
                    <template #form>
                        <event-form
                            ref="form"
                            class="form"
                            v-bind="topForm"
                            @unlock="unlockVideo"
                            @leadRequest="onLeadRequest"
                            @update="onFormUpdate"
                        />
                    </template>
                    <template #about>
                        <event-about v-bind="about" />
                    </template>
                </event-on-demand-head>
            </div>
            <template v-if="!hasOnDemandLayout">
                <s-anchor-menu v-if="stickyMenu && isDesktop" v-bind="stickyMenu" />
                <event-container>
                    <div v-if="hasForm" id="overview" class="intro cols">
                        <event-about class="about" v-bind="about" />
                        <div class="form-wrap">
                            <event-form
                                v-bind="topForm"
                                class="form"
                                @unlock="unlockEvent"
                                @leadRequest="onLeadRequest"
                            />
                        </div>
                    </div>
                    <div v-else id="overview" class="intro">
                        <event-about class="about" v-bind="about" />
                    </div>
                </event-container>
            </template>
            <event-sections
                :event="event"
                :event-type="eventType"
                :translations="translations"
                :videos="videosInList"
                :resource-base-url="resourceBaseUrl"
                :form="accessForm"
                :access-form-enabled="accessFormEnabled"
                :has-on-demand-layout="hasOnDemandLayout"
                :g2-reviews="g2Reviews"
                @unlock="unlockEvent"
                @progressChanged="onProgressChange($event)"
                @scrollToFormClick="scrollToForm"
            />
        </div>
        <s-global-footer class="footer" v-bind="SLICE_DATA_FOOTER" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import analytics from '@core/mixins/analytics.js';
import breakpoint from '@core/mixins/breakpoint.js';
import events from '@core/mixins/events.js';
import ytTrack from '@core/mixins/ytTrack.js';
import EventAbout from '@core/slices/events/event-about/event-about.vue';
import EventForm from '@core/slices/events/event-form/event-form.vue';
import EventHead from '@core/slices/events/event-head/event-head.vue';
import EventMenu from '@core/slices/events/event-menu/event-menu.vue';
import EventOnDemandHead from '@core/slices/events/event-on-demand-head/event-on-demand-head.vue';
import EventSections from '@core/slices/events/event-sections/event-sections.vue';
import EventContainer from '@core/slices/events/shared-components/event-container/event-container.vue';
import EventLocaleSelect from '@core/slices/events/shared-components/event-locale-select/event-locale-select.vue';
import SAnchorMenu from '@core/slices/pages/anchor-menu/anchor-menu.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import { SLICE_DATA_FOOTER, DEFAULT_LANGUAGE } from '@model/const/events.ts';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import socials from '@model/const/socials.ts';
import eventUtils from '@utils/events.js';
import '@core/styles/sections/events.pcss';

const CATEGORY_TRAINING_AND_CERTIFICATION = 3;
const SCROLL_OFFSET = 32;

export default {
    name: 'EventComponent',

    components: {
        SGlobalFooter,
        EventHead,
        EventOnDemandHead,
        EventContainer,
        EventMenu,
        EventAbout,
        EventLocaleSelect,
        EventForm,
        EventSections,
        SAnchorMenu,
    },

    mixins: [events, analytics, ytTrack, breakpoint],

    props: {
        dispatchLoadOtherEvents: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            SLICE_DATA_FOOTER,
            socials,
            allVideosShown: false,
            allResourcesShown: false,
            unlockedEvents: new Set([]),
            accessFormEnabled: false,
        };
    },

    computed: {
        ...mapGetters('config', ['$config']),

        resourceBaseUrl() {
            return `${this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE}/downloads/`;
        },

        event() {
            return this.eventsStore.event.items[0] || {};
        },

        eventType() {
            return eventUtils.getEventType(this.event);
        },

        formTranslations() {
            const formTranslations = this.$store.state.slices.items['events-form'] || {};
            const language = this.event.language?.name;
            return formTranslations[language] || formTranslations[DEFAULT_LANGUAGE] || {};
        },

        g2Reviews() {
            if (!this.event.show_product_reviews) return null;

            const productReviewsMap = this.$store.state.slices.items['s-product-reviews-id-map'] || {};
            const firstProductId = this.event.product_ids.find((productId) => productReviewsMap[productId]);
            return firstProductId ? productReviewsMap[firstProductId] : null;
        },

        hasOnDemandLayout() {
            return this.event.is_on_demand || this.event.is_past;
        },

        actualOccurrence() {
            return eventUtils.getActualOccurrence(this.event);
        },

        hasMultipleFutureOccurrences() {
            const today = new Date();
            return this.event.occurrences.filter((a) => new Date(a.begins_at) > today).length > 1;
        },

        hasActionButton() {
            return this.event.cta_button_text && this.event.cta_button_url;
        },

        hasForm() {
            return this.hasOnDemandLayout || (!this.hasActionButton && this.actualOccurrence?.date > new Date());
        },

        theme() {
            return this.event.interface_theme || 'dark';
        },

        head() {
            return {
                title: this.event.name,
                labels: this.eventLabels,
                action: this.actionButton,
                backLink: {
                    to: '/',
                    title: this.translations.browseAllEventsTitle,
                },
                image: `@${this.event.cover.image_id}`,
                features: this.features,
            };
        },

        eventLabels() {
            const labels = [];
            const eventType = eventUtils.getEventType(this.event);

            labels.push({
                title: this.event.is_third_party ? this.translations.thirdParty : this.translations.firstParty,
                type: 'event-category',
            });

            if (eventType) {
                labels.push({
                    title: this.translations.types?.[eventType],
                    type: 'event-type',
                });
            }

            const firstAudience = this.event.audiences?.[0];
            if (firstAudience) {
                const audienceTitle = this.translations.forValueTitle?.replace(
                    '@value',
                    firstAudience.title,
                );

                labels.push({
                    title: audienceTitle,
                    type: 'target-audience',
                    color: firstAudience.color,
                });
            }

            if (this.event.categories?.length) {
                labels.push({
                    title: this.event.categories[0].title,
                    type: 'event-category',
                });
            }

            return labels;
        },

        actionButton() {
            if (this.hasActionButton) {
                return {
                    to: this.event.cta_button_url,
                    title: this.event.cta_button_text,
                };
            }
            return null;
        },

        features() {
            const features = [];

            // WEB-44976
            const showDifferentDateLabel = this.hasMultipleFutureOccurrences &&
                this.event.is_virtual &&
                !this.event.is_in_person &&
                this.event.categories?.some((x) => x.id === CATEGORY_TRAINING_AND_CERTIFICATION);

            const dateAndTimeTitle = showDifferentDateLabel
                ? this.translations.upcomingTrainingOnTitle
                : this.translations.dateAndTimeTitle;

            if (this.actualOccurrence) {
                const date = eventUtils.convertDate(
                    this.actualOccurrence.begins_at,
                    this.eventType,
                    this.event.timezone,
                    'en-us',
                    'MMM d, yyyy, h:mm a',
                );

                const duration = eventUtils.calculateDurationToString(
                    this.actualOccurrence.begins_at,
                    this.actualOccurrence.ends_at,
                );

                let startDate = {
                    title: dateAndTimeTitle,
                    value: date.formatted,
                    tooltip: `${this.timezoneTitle} (GMT${date.timezoneOffset})`,
                };

                if (this.event.recurrence) {
                    startDate = {
                        title: dateAndTimeTitle,
                        value: this.event.recurrence,
                    };
                }

                features.push(startDate);
                features.push({
                    value: duration,
                    title: this.translations.duration,
                });
            }

            features.push({
                value: this.translations.languageTitle?.replace('@value', this.event.language.name),
                title: this.translations.language,
            });

            return features;
        },

        menu() {
            const menu = {
                theme: this.theme,
                layout: 'event',
                eventsTitle: this.translations.events,
            };
            if (this.event.has_featured_partner && this.event.partners.length > 0) {
                const partner = this.event.partners[0];
                menu.extraLogo = `@${partner.logo_featured_id}`;
            }
            return menu;
        },

        stickyMenu() {
            if (!this.event.section_ui_has_sticky_navigation) return null;

            const labels = this.translations.stickyMenu;
            let resources;
            if (this.event.materials?.length) {
                resources = { text: labels.resources, to: '#resources', id: '#resources' };
            } else if (this.videosInList?.length) {
                resources = { text: labels.resources, to: '#videos', id: '#videos' };
            }
            const list = [
                this.event.partners?.length ? { text: labels.partners, to: '#partners', id: '#partners' } : null,
                this.event.speakers?.length ? { text: labels.speakers, to: '#speakers', id: '#speakers' } : null,
                this.event.activities?.length ? { text: labels.agenda, to: '#agenda', id: '#agenda' } : null,
                resources,
            ].filter(Boolean);

            if (!list.length) return null;

            list.unshift({ text: labels.overview, to: '#overview', id: '#overview' });

            return { list };
        },

        timezoneTitle() {
            if (this.eventType === 'virtual') {
                return this.translations.yourTimezoneTitle;
            }
            return this.translations.localTimezoneTitle;
        },

        headForOnDemand() {
            if (!this.hasOnDemandLayout) return null;

            const image = this.translations.themeBackgrounds &&
                this.translations.themeBackgrounds?.[this.theme];

            const hasGatedVideos = this.event.section_videos_is_gated && !!this.event.videos.length;
            const hasGatedMaterials = this.event.section_materials_is_gated && !!this.event.materials.length;

            const isFormShown = this.accessFormEnabled && (hasGatedVideos || hasGatedMaterials);

            return {
                ...this.head,
                video: this.videoInHeader,
                theme: this.theme,
                image,
                isFormShown,
            };
        },

        about() {
            let location = null;
            if (this.event.place_latitude && this.event.place_longitude && this.event.place_name) {
                location = {
                    to: '#location',
                    label: this.translations.location,
                    text: this.event.place_name,
                };
            }
            return {
                location,
                body: this.event.description_body,
                socials: this.socialsShareLinks,
                socialTitle: this.translations.share,
            };
        },

        socialsShareLinks() {
            const title = this.head.title;
            const url = typeof window !== 'undefined' ? window.location : '';

            return this.socials.share.map((item) => ({
                name: item.name,
                link: item.link(encodeURIComponent(url), encodeURIComponent(title)),
            }));
        },

        form() {
            let startDate = null;
            if (this.actualOccurrence) {
                startDate = this.actualOccurrence.begins_at;
            }

            const today = new Date();
            const meetingDates = this.event.occurrences
                .map((a) => ({ ...a, date: new Date(a.begins_at) }))
                .filter((a) => a.date > today)
                .sort((a, b) => a.date - b.date)
                .map((a) => eventUtils.convertDate(
                    a.begins_at,
                    this.eventType,
                    this.event.timezone,
                    LOCALE_DEFAULT,
                    'MMM d, yyyy',
                ).formatted);

            const category = this.event.categories?.[0]?.title.replace('&', 'and') || '';

            const subscribers = this.event.subscribers.map((a) => a.email);

            const trainings = this.event.activities
                .filter((a) => a.is_training)
                .sort((a, b) => new Date(a.time_begining) - new Date(b.time_begining))
                .map((a, index) => {
                    const date = eventUtils.convertDate(
                        a.time_begining,
                        this.eventType,
                        this.event.timezone,
                        LOCALE_DEFAULT,
                        'MMM d, yyyy, h:mm a',
                    ).formatted;
                    return {
                        training_session_number: index + 1,
                        training_name: a.title,
                        training_date_time: a.time_begining,
                        training_sfdc_campaign_id: a.salesforce_campaign_id,
                        textValue: a.title ? `${date} – ${a.title}` : date,
                    };
                });

            const marketoConfig = JSON.parse(this.event.marketo_config || '{}');

            return {
                eventCategory: category,
                eventId: this.event.id,
                eventName: this.event.name,
                eventType: this.eventType,
                isThirdParty: this.event.is_third_party,
                languageName: this.event.language.name,
                lmsExamUrl: this.event.lms_exam_url,
                marketoCampaignId: marketoConfig.mkto_registered_URL,
                meetingDates,
                sfdcId: this.event.salesforce_campaign_id,
                startDate,
                subscribers,
                trainings,
                zoomMeetingId: this.event.zoom_meeting_id,
            };
        },

        topForm() {
            if (!this.hasForm) return null;

            let dateTitle = null;

            if (this.actualOccurrence && this.event.recurrence) {
                const date = eventUtils.convertDate(
                    this.actualOccurrence.begins_at,
                    this.eventType,
                    this.event.timezone,
                    LOCALE_DEFAULT,
                    'MMMM d | h:mm a',
                );

                if (date.formatted) {
                    const time = date.formatted.replace('|', this.translations.at);

                    dateTitle = {
                        title: this.translations.nextEventStartsTitle?.replace('@value', time),
                        timezoneTitle: `${this.timezoneTitle} (GMT${date.timezoneOffset})`,
                    };
                }
            }

            let occurrences = [];
            if (this.event.categories?.some((x) => x.id === CATEGORY_TRAINING_AND_CERTIFICATION)) {
                const currentDate = new Date();
                occurrences = this.event.occurrences || [];
                occurrences = occurrences
                    // we only want to take upcoming events
                    .filter((occurrence) => new Date(occurrence.begins_at) >= currentDate)
                    .map((occurrence) => {
                        const startDate = eventUtils.formatDate(occurrence.begins_at, 'MMM d, yyyy');
                        const endDate = eventUtils.formatDate(occurrence.ends_at, 'MMM d, yyyy');

                        return {
                            text: `${startDate} - ${endDate}`,
                            value: occurrence.begins_at,
                        };
                    });
            }

            if (!this.hasOnDemandLayout) {
                return {
                    ...this.form,
                    dateTitle,
                    title: this.event.section_form_title || '',
                    occurrences,
                };
            }

            return {
                ...this.form,
                title: this.event.section_form_title || this.formTranslations.onDemandFormTitle,
                buttonTitle: this.formTranslations.onDemandFormButtonTitle,
                successTitle: this.formTranslations.videoFormSuccessTitle,
                successText: this.formTranslations.videoFormSuccessText,
                showSuccessImage: false,
                occurrences,
            };
        },

        accessForm() {
            return {
                ...this.form,
                title: this.formTranslations.accessFormTitle,
                buttonTitle: this.event.section_form_title || this.formTranslations.accessButtonTitle,
                successTitle: this.formTranslations.formResourceSuccessTitle,
                successText: this.formTranslations.formResourceSuccessText,
            };
        },

        allVideos() {
            if (!this.event.videos.length) return null;

            return this.event.videos?.map((video) => {
                const time = this.videoDurationToTimeString(video.duration);
                const ytId = this.getYoutubeIdFromUrl(video.url);
                const locked = this.event.section_videos_is_gated && this.accessFormEnabled;

                return {
                    time,
                    ytId,
                    locked,
                    image: null,
                    group: video.group,
                    imageAlt: video.title,
                    lockedTitleMobile: this.translations.lockedVideoTitle,
                    lockedTitleDesktop: this.translations.lockedVideoTitle,
                    formButtonTitle: this.translations.lockedVideoButtonTittle,
                };
            });
        },

        videosInList() {
            if (!this.hasOnDemandLayout) return this.allVideos;

            return this.allVideos?.slice(1);
        },

        videoInHeader() {
            if (!this.hasOnDemandLayout || !this.allVideos?.length) return null;

            return {
                ...this.allVideos[0],
                lockedTitleMobile: this.translations.lockedVideoBelowTitle,
                lockedTitleDesktop: this.translations.lockedVideoRightTitle,
                isFeatured: true,
            };
        },
    },

    mounted() {
        const countryId = this.getCountryCookie();
        if (!Number.isNaN(countryId)) {
            this.setCountry({ countryId, isConfirmed: true });
        }

        // TODO: this.$on('onLocationSet', this.loadData);?

        this.dispatchLoadOtherEvents({
            countryId: this.countryId,
            audiences: this.event.audiences,
            categoryId: this.event.categories?.[0],
            slug: this.event.slug,
            is_virtual: this.event.is_virtual,
            is_in_person: this.event.is_in_person,
            is_on_demand: this.event.is_on_demand,
            is_past: this.event.is_past,
        });

        try {
            const arr = JSON.parse(localStorage.getItem('unlockedEvents')) || [];
            this.unlockedEvents = new Set(arr);
        } catch (e) {
            this.unlockedEvents = new Set([]);
        }

        this.accessFormEnabled = !this.unlockedEvents.has(this.event.id);

        this.setYtTrackId(this.event.id);
    },

    methods: {
        ...mapActions({
            setCountry: 'events/setCountry',
        }),

        setLocation(countryId) {
            this.setCountry({ countryId, isConfirmed: true });
            this.setCountryCookie(countryId);
        },

        onProgressChange(event) {
            this.setYtTrackData(event);
        },

        onLeadRequest(event) {
            this.setYtEventData(event);
        },

        unlockEvent() {
            this.unlockedEvents.add(this.event.id);
            localStorage.setItem('unlockedEvents', JSON.stringify(Array.from(this.unlockedEvents)));
            this.accessFormEnabled = false;
        },

        async unlockVideo() {
            this.unlockEvent();
            await this.$nextTick();
            this.$refs['on-demand-head']?.triggerVideoClick();
        },

        async onFormUpdate() {
            await this.$nextTick();
            this.$refs['on-demand-head']?.onResize();
        },

        scrollToForm() {
            if (!this.$refs.form) return;

            let position = 0;
            if (!this.isDesktop) {
                const formTop = this.$refs.form.$el.getBoundingClientRect().top;
                const scrollTop = document.documentElement.scrollTop;
                position = formTop + scrollTop - SCROLL_OFFSET || 0;
            }
            window.scrollTo({ top: position, behavior: 'smooth' });
            this.$refs.form.triggerHighlightEffect();
        },
    },
};
</script>

<style lang="postcss" scoped>
.events-page {
    .main-content {
        display: flex;
        flex-direction: column;
    }

    .header {
        position: relative;

        &.dark {
            &::before {
                content: '';
                position: absolute;
                inset-inline-start: 0;
                inset-inline-end: 0;
                top: 0;
                height: 120px;
                background: linear-gradient(
                    180deg,
                    var(--av-nav-primary) 0%,
                    rgba(0, 32, 77, 0) 100%
                );
            }
        }
    }

    .intro {
        padding-bottom: 32px;

        @media (--viewport-tablet) {
            padding-top: 64px;
            padding-bottom: 32px;
        }

        @media (--viewport-desktop-wide) {
            padding-bottom: 64px;
        }
    }

    .cols {
        display: flex;
        flex-direction: column;

        @media (--viewport-tablet) {
            display: grid;
            grid-column-gap: 16px;
            grid-template-columns: 1fr 1fr;
        }

        @media (--viewport-desktop) {
            grid-template-columns: 7fr 5fr;
        }
    }

    .about {
        position: relative;
        padding-top: 64px;
        order: 2;

        @media (--viewport-tablet) {
            padding-top: 0;
            order: 1;
        }

        @media (--viewport-desktop) {
            padding-inline-end: 14%;
        }
    }

    .form-wrap {
        position: relative;
        z-index: 121; /* above s-anchor-menu */
        margin: 0 -16px;
        padding: 0 16px 32px;
        background: var(--av-nav-primary);
        display: flex;
        justify-content: center;

        @media (--viewport-tablet) {
            display: block;
            margin: 0;
            padding: 0;
            background: transparent;
            order: 2;
        }

        @media (--viewport-desktop) {
            margin-top: -136px;
        }
    }

    &.on-demand-layout {
        .header {
            position: relative;
            order: 0;
        }
    }

    &:deep(.s-anchor-menu__wrapper) {
        background-color: var(--av-solid-brand-lightest);
        box-shadow: none;
    }

    &:deep(.s-anchor-menu__link_active) {
        @mixin paragraph-accent;
    }
}
</style>
