<template>
    <div class="business_address" autocomplete="new-password">
        <label :for="id">
            {{ label }}
        </label>
        <input
            :id="id"
            v-model="business_address"
            type="text"
            :placeholder="placeholder"
            class="required"
            data-required="true"
            autocomplete="new"
            role="presentation"
        />
    </div>
</template>

<script>
export default {
    data: () => ({
        business_address: '',
        id: 'business_address',
        label: 'Business address',
        placeholder: 'Enter business address',
    }),
    methods: {
        getValue() {
            return this.business_address;
        },
        isCaptured() {
            if (process.env.VUE_ENV === 'server') return false;
            return Boolean(this.getValue());
        },
        sendGAEvent() {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'Capture',
                honeypotCapture: 'true',
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
.business_address {
    transform: scale(0);
    margin-top: -24px;
}
</style>
