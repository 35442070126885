<template>
    <div v-if="isVisible" class="workloads">
        <p v-if="page.title" class="title" v-html="page.title" />
        <div v-if="page.description" class="description">
            <div v-html="page.description" />
            <a-button v-bind="page.buttonCart" :class="className" @click="$emit('toggleCart')" />
        </div>
        <div class="workload-list">
            <div
                v-for="(workload, workloadIndex) in workloadList"
                :key="`workload-${workloadIndex}`"
                :class="['workload', { 'workload-active': hasSelected(workload.name) }]"
                @click="$emit('changeWorkload', workload.name)"
            >
                <template v-if="workload.tooltip">
                    <a-tooltip :text="workload.tooltip" popper-class="el-tooltip__purchasing-workload">
                        <span v-html="workload.title" />
                    </a-tooltip>
                </template>
                <template v-else>
                    <span class="tooltip-wrapper" v-html="workload.title" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import mixin from '../mixin.js';

export default {
    name: 'SWorkloads',

    components: {
        AButton,
        ATooltip,
    },

    mixins: [mixin],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    emits: ['changeWorkload', 'toggleCart'],

    computed: {
        page() {
            return this.settings.dictionary.expert || {};
        },
        className() {
            return this.addedLicenses.length ? 'has-product' : '';
        },
        workloadList() {
            return this.settings.workloads || [];
        },
        isVisible() {
            return this.settings.workloads?.length;
        },
    },

    methods: {
        hasSelected(name) {
            return this.settings.workloads.find((item) => item.name === name).selected;
        },
    },
};
</script>

<style lang="postcss" scoped>
.workloads {
    color: var(--av-nav-primary);
    .title {
        @mixin lead-accent;
        text-align: center;
        @media (--viewport-tablet) {
            @mixin title-accent;
        }
        @media (--viewport-desktop) {
            @mixin display-accent;
            text-align: start;
        }
    }
    .description {
        @mixin lead;
        gap: 16px;
        display: flex;
        margin-top: 40px;
        align-items: flex-start;
        justify-content: space-between;
        @media (--viewport-tablet) {
            @mixin title;
            margin-top: 16px;
            align-items: center;
        }
        @media (--viewport-desktop) {
            @mixin display;
        }
        &:deep(.a-button) {
            gap: 8px;
            position: relative;
            flex-direction: row-reverse;
            &_type_inverse {
                height: 40px;
                border-radius: 4px;
                background: rgba(38, 104, 197, 0.10);
                &:hover {
                    background: var(--av-brand-accent);
                }
                &:active {
                    background: var(--el-secondary-active);
                }
                .a-button__content {
                    color: var(--av-brand-primary);
                }
                .a-button__glyph {
                    margin: 0;
                    fill: var(--av-brand-primary);
                }
            }
            &.has-product {
                padding-inline-end: 32px;
                &:before {
                    content: '';
                    width: 12px;
                    height: 12px;
                    position: absolute;
                    border-radius: 50%;
                    inset-inline-end: 10px;
                    background: var(--av-brand-primary);
                }
            }
        }
    }
    .workload-list {
        gap: 8px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
        justify-content: center;
        @media (--viewport-tablet) {
            gap: 4px;
            margin-top: 16px;
        }
        @media (--viewport-desktop-wide) {
            gap: 16px;
        }
    }
    .workload {
        overflow: hidden;
        user-select: none;
        &:deep(.tooltip-wrapper) {
            @mixin caption-accent;
            padding: 8px 14px;
            display: flex;
            border-radius: 24px;
            color: var(--av-brand-primary);
            border: 2px solid var(--av-brand-light);
            &:hover {
                background-color: var(--av-brand-secondary-accent);
            }
            @media (--viewport-desktop-wide) {
                @mixin body-accent;
            }
        }
    }
    .workload-active {
        &:deep(.tooltip-wrapper) {
            background-color: var(--av-brand-secondary-accent);
        }
    }
}
</style>

<style lang="postcss">
.el-tooltip__purchasing-workload {
    max-width: 230px;
    b {
        font-weight: 700;
    }
}
</style>
