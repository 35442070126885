<template>
    <s-basic-slice :id="id" class="s-two-column-comparsion" v-bind="$attrs">
        <ul class="s-two-column-comparsion__list">
            <li v-for="(item, i) in items" :key="i" class="s-two-column-comparsion__item">
                <div class="s-two-column-comparsion-item">
                    <div class="s-two-column-comparsion-item__icon-container">
                        <a-icon
                            class="s-two-column-comparsion-item__icon"
                            :number="item.iconNumber"
                            :icon-alt="item.iconAlt || ' '"
                        />
                    </div>
                    <div class="s-two-column-comparsion-item__title">
                        {{ item.title }}
                    </div>
                    <ul v-if="item.list" class="s-two-column-comparsion-item__list a-list a-list_type_bullet">
                        <li
                            v-for="(listItem, index) in item.list"
                            :key="`two-column-comparsion-${index}`"
                            class="s-two-column-comparsion-item__list-item a-list__item"
                        >
                            <a-dangerous-html class="s-two-column-comparsion-item__list-item-text" :content="listItem" />
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </s-basic-slice>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AIcon from '@core/components/icon/icon.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'STwoColumnComparsion',

    components: {
        SBasicSlice,
        AIcon,
        ADangerousHtml,
    },

    inheritAttrs: false,

    props: {
        id: {
            type: String,
            required: false,
            default: '',
        },

        items: {
            type: Array,
            required: true,
            validator: (items) => items.length === 2,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-two-column-comparsion {
    margin-bottom: -48px;

    @media (--viewport-mobile-wide) {
        margin-bottom: 0;
    }

    &__list {
        display: flex;
        flex-direction: column;

        @media (--viewport-mobile-wide) {
            margin-inline-end: -16px;
            flex-direction: row;
        }

        @media (--viewport-desktop-large) {
            margin-inline-end: 0;
        }
    }

    &__item {
        margin-bottom: 48px;

        @media (--viewport-mobile-wide) {
            margin-bottom: 0;
            margin-inline-end: 16px;
            width: 50%;
        }

        @media (--viewport-desktop-large) {
            width: 41%;
            margin-inline-end: 128px;
        }

        &:first-of-type {
            @media (--viewport-mobile-wide) {
                padding-inline-end: 16px;
            }

            @media (--viewport-desktop-large) {
                padding-inline-end: 0;
            }
        }
    }

    .s-two-column-comparsion-item {
        &__icon-container {
            margin-bottom: 16px;
            width: 96px;
            height: 96px;
        }

        &__title {
            margin-bottom: 16px;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            color: rgba(var(--av-nav-primary), 0.9);
        }

        &__list-item {
            @mixin paragraph;

            color: rgba(var(--av-nav-primary), 0.9);
        }

        &__list-item-text {
            display: inline;
        }
    }
}
</style>
