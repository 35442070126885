<template>
    <div class="a-popover">
        <el-popover :reference="popoverReference" :placement="placement" :trigger="trigger">
            <div class="popover-content">
                <div v-if="content.title" class="popover-title">
                    {{ content.title }}
                </div>
                <a-dangerous-html v-if="content.content" class="popover-text" :content="content.content" />
                <ol v-if="content.list" class="popover-ol">
                    <li v-for="(item, index) in content.list" :key="index" class="popover-list-item">
                        <a-link
                            v-if="item.to"
                            class="link"
                            v-bind="item"
                        />
                        <div v-else class="popover-text">
                            {{ item.text }}
                        </div>
                    </li>
                </ol>
            </div>
        </el-popover>
        <div ref="button">
            <slot>
                <a-glyph name="tooltip-icon" />
            </slot>
        </div>
    </div>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'APopover',

    components: {
        ElPopover: () => import('@uikit/ui-kit/packages/popover'),
        ALink,
        AGlyph,
        ADangerousHtml,
    },
    mixins: [breakpoint],
    props: {
        content: {
            type: Object,
            required: true,
        },

        placement: {
            type: String,
            default: 'top',
        },

        trigger: {
            type: String,
            default: 'click',
        },
    },
    data() {
        return {
            popoverReference: null,
        };
    },

    mounted() {
        this.popoverReference = this.$refs.button;
    },
};
</script>

<style lang="postcss">
.a-popover {
    display: inline-block;
    cursor: pointer;

    .el-popover__reference {
        padding: 0;
        background: none;
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        height: auto;
        display: flex;
        align-items: center;
    }
}

.el-popover {
    @mixin caption;
    box-shadow: none;
    background: var(--av-fixed-primary);
    color: var(--av-inversed-primary);
    max-width: 325px;

    .el-popper__arrow {
        border: 0;
        background: var(--av-fixed-primary);
    }

    .popover-content {
        font-weight: 500;
        padding: 16px;
    }

    .popover-title {
        @mixin caption-accent;
        margin-bottom: 8px;
    }

    .popover-text {
        @mixin caption;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    .popover-ol {
        list-style: decimal;
        margin: 0 6px;
    }

    .popover-list-item {
        margin: 0 8px 8px;
    }

    .popover-link {
        @mixin caption;
    }
}
</style>
