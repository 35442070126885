<template>
    <section :id="id" class="s-slice s-download" :class="basicClassName">
        <div class="a-container">
            <a-slice-header :title="title" :lead="lead" :body-text="bodyText" />
            <div v-if="product" class="s-download__information">
                <template v-if="product.information">
                    <div
                        v-for="(item, key) in product.information"
                        :key="`product-${key}`"
                        class="s-download__information-item"
                    >
                        <template v-if="item.button && item.button.text">
                            <a-button
                                class="s-download__information-button"
                                type="action"
                                v-bind="item.button"
                                :event="setEvent(item.button)"
                            />
                        </template>
                        <template v-else>
                            <b class="s-download__information-title">{{ item.title }}</b>
                            <span class="s-download__information-text">{{ item.text }}</span>
                            <a-link v-if="item.link" v-bind="item.link" />
                        </template>
                    </div>
                </template>
            </div>
            <div v-if="product.links" class="s-download__links">
                <ul v-for="(list, listKey) in product.links" :key="`list-${listKey}`" class="s-download__list">
                    <li v-for="(link, linkKey) in list" :key="`link-${linkKey}`" class="s-download__list-item">
                        <span v-if="link.title" class="s-download__list-title">{{ link.title }}</span>
                        <template v-if="link.text">
                            <a-link
                                v-if="link.to"
                                v-bind="link"
                                class="s-download__list-link"
                                :size="link.size || 'paragraph'"
                            />
                            <span v-else class="s-download__list-text">{{ link.text }}</span>
                        </template>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import form from '@core/mixins/form.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SDownload',
    components: {
        ALink,
        AButton,
        ASliceHeader,
    },
    mixins: [contentMixin, styleMixin, form],
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-download {
    .a-slice-header {
        margin-bottom: 48px;
    }

    &__information {
        display: flex;
        flex-wrap: wrap;
    }

    &__information-item {
        @mixin body;
        width: 100%;
        display: block;
        color: var(--av-fixed-secondary);

        &:not(:first-child) {
            margin-top: 8px;
        }

        .a-link {
            display: inline-block;
            vertical-align: baseline;
        }
    }

    &__information-button {
        @mixin paragraph;
        font-weight: bold;
        margin-bottom: 16px;
    }

    &__information-title {
        font-weight: 600;
        margin-inline-end: 8px;
    }

    &__information-text {
        padding-inline-end: 4px;
    }

    &__list {
        margin-top: 40px;
    }

    &__list-item {
        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    &__list-link {
        vertical-align: baseline;
    }

    &__list-title {
        display: block;
        font-weight: 600;
        padding-bottom: 8px;
        color: var(--av-nav-primary);
    }

    @media (--viewport-tablet) {
        &__information {
            margin: 0 -32px;
            padding: 0 32px;
            overflow-x: auto;
            flex-wrap: nowrap;
            white-space: nowrap;
            scrollbar-width: none;
            width: calc(100% + 32px * 2);
            &::-webkit-scrollbar {
                height: 0;
                background: rgba(255, 255, 255, 0);
            }
        }

        &__information-item {
            width: auto;
            &:not(:first-child) {
                margin-inline-start: 32px;
            }
        }

        &__information-button {
            margin-inline-end: 8px;
        }

        &__information-title {
            display: block;
        }

        &__links {
            display: flex;
            flex-wrap: nowrap;
        }

        &__list {
            width: 33.33%;
            &:not(:first-child) {
                margin-inline-start: 32px;
            }
        }
    }

    @media (--viewport-desktop) {
        &__information-item {
            &:not(:first-child) {
                margin-inline-start: 48px;
            }
        }

        &__information-button {
            margin-inline-end: 16px;
        }
    }

    @media (--viewport-desktop-wide) {
        &__list {
            width: auto;
            min-width: 162px;
        }
    }
}
</style>
