<template>
    <div class="s-acronis-scs-tab">
        <div class="tab-body">
            <section
                v-for="(section, index) in menu"
                :key="`s-menu-section-${index}`"
                class="tab-section"
            >
                <ul v-for="(item, i) in section.content" :key="`tab-section_${i}`">
                    <li v-for="(listItem, j) in item.items" :key="`tab-item-${j}`" class="tab-item">
                        <a-link
                            v-bind="listItem"
                            @click="sendGAEvent(listItem.text, listItem.to)"
                        />
                    </li>
                </ul>
            </section>
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';

export default {
    name: 'SMenuTab',

    components: {
        ALink,
    },
    props: {
        menu: {
            type: Array,
            required: false,
            default: () => [],
        },
        activeTab: {
            type: String,
            required: false,
            default: undefined,
        },
    },
    methods: {
        sendGAEvent(text, to) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'Acronis',
                eventCategory: 'Interactions',
                eventAction: 'Menu',
                eventLabel: to,
                eventContext: 'click',
                eventContent: text,
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-acronis-scs-tab {
    .tab-body {
        .tab-section {
            .tab-item {
                margin-bottom: 16px;
                &:last-child {
                    margin: 0;
                }
                &:deep(.a-link__content) {
                    @mixin paragraph;
                    font-weight: 700;
                    white-space: nowrap;
                    color: var(--av-brand-primary);
                    @media (--viewport-desktop) {
                        color: var(--av-brand-secondary);
                    }
                }
            }
        }
    }
}
</style>
