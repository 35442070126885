<template>
    <div class="a-location-card">
        <div v-if="title" class="a-location-card__title">
            {{ title }}
        </div>
        <div v-if="subtitle" class="a-location-card__subtitle">
            {{ subtitle }}
        </div>
        <div v-if="address" class="a-location-card__text">
            <a class="a-location-card__address" :href="getGoogleMapsLink(address)" v-html="address" />
        </div>
        <div v-if="text" class="a-location-card__text" v-html="text" />
        <div
            v-if="contacts && contacts.length"
            class="a-location-card-contacts"
            :class="{ 'no-subtitle': !subtitle, 'has-link': link }"
        >
            <div v-for="contact in contacts" :key="contact.value" class="a-location-card-contact">
                <div class="a-location-card-contact__item">
                    <span v-if="contact.title">{{ contact.title }}<i>:&nbsp;</i></span>
                    <a-link
                        v-if="contact.type && isPhoneNumber(contact.type)"
                        class="a-location-card-contact__tel"
                        :to="`tel:${contact.value}`"
                        :size="contact.size || 'paragraph'"
                        :text="contact.value"
                    />
                    <a-link
                        v-else-if="contact.type && isEmail(contact.type)"
                        class="a-location-card-contact__email"
                        :to="`mailto:${contact.value}`"
                        :size="contact.size || 'paragraph'"
                        :text="contact.value"
                    />
                    <span v-else>{{ contact.value }}</span>
                </div>
            </div>
        </div>
        <a-link
            v-if="link"
            class="a-location-card__link"
            v-bind="link"
            :size="link.size || 'paragraph'"
        />
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import commonUtils from '@utils/common.ts';

export default {
    name: 'ALocationCard',

    components: {
        ALink,
    },

    props: {
        /**
         * Country
         */
        title: {
            type: String,
            default: undefined,
        },

        subtitle: {
            type: String,
            default: undefined,
        },

        text: {
            type: String,
            default: undefined,
        },

        address: {
            type: String,
            default: undefined,
        },

        contacts: {
            type: Array,
            default: () => [],
        },

        link: {
            type: Object,
            default: undefined,
        },
    },

    methods: {
        isPhoneNumber(type) {
            return ['tel', 'fax'].includes(type);
        },
        isEmail(type) {
            return type === 'email';
        },
        getGoogleMapsLink(rawAddress) {
            const address = commonUtils.sanitize(rawAddress);
            return `https://maps.google.com/maps?q=${address}`;
        },
    },
};
</script>

<style lang="postcss">
.a-location-card {
    background-color: var(--av-inversed-primary);
    border-radius: 4px;
    box-shadow: var(--av-shadow-regular);
    border: 1px solid var(--av-fixed-lightest);
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 24px;
    transition: box-shadow linear 0.15s;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    @mixin paragraph;
    &__title {
        @mixin colls 12;
        @mixin title-accent;
        color: var(--av-nav-primary);
        font-weight: 700;
        margin-bottom: 16px;
    }
    &__subtitle {
        @mixin colls 12;
        @mixin paragraph-accent;
        font-weight: 700;
        color: var(--av-nav-primary);
    }
    &__text {
        @mixin colls 12;
        color: var(--av-fixed-secondary);
    }
    &__address {
        text-decoration: none;
        color: inherit;
    }
    &-contacts {
        margin-top: 16px;
        @mixin colls 12;
        color: var(--av-fixed-secondary);
        a {
            color: var(--av-brand-primary);
        }
        &.no-subtitle {
            margin-top: 0;
        }
    }
    &-contact {
        &__item {
            display: flex;
        }
        &__tel,
        &__email {
            text-decoration: none;
            color: inherit;
        }
    }
    &__link {
        margin-top: auto;
    }
}
</style>
