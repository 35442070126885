<template>
    <s-basic-slice
        v-if="loaded"
        class="s-all-documentation"
        :title="title"
        :lead="lead"
        :class="className"
        :body-text="bodyText"
    >
        <s-side-menu
            class="s-all-documentation__side-menu"
            type="combined"
            :is-wide="true"
            :slices="list"
            :links="links"
            :active-slice="activeCategory"
            @click="handleClick"
        />
    </s-basic-slice>
</template>

<script>
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import SSideMenu from '@core/slices/pages/side-menu/side-menu.vue';

export default {
    name: 'SAllDocumentation',

    components: {
        SBasicSlice,
        SSideMenu,
    },

    props: {
        title: {
            type: String,
            default: null,
        },

        lead: {
            type: String,
            default: null,
        },

        bodyText: {
            type: String,
            default: null,
        },

        documentation: {
            type: Array,
            required: true,
        },

        /**
         * Links for side menu
         */
        links: {
            type: Array,
            required: true,
        },

        dictionary: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },

    data: () => ({
        loaded: false,
        list: [],
        products: [],
        activeProduct: 'all-products',
        activeCategory: 'all-products',
    }),

    computed: {
        className() {
            return {
                's-all-documentation__loaded': this.loaded,
            };
        },

        textAllProducts() {
            return this.dictionary['All products'] || 'All products';
        },

        textAllCategories() {
            return this.dictionary['All categories'] || 'All categories';
        },
    },

    beforeMount() {
        this.init();
    },

    methods: {
        init() {
            this.list = this.documentation.map((item, index) => {
                const id = item.id || `id${index}`;
                const products = item.products || [];
                const caption = item.tab || item.title || `Caption ${index}`;
                return {
                    info: { id, caption },
                    slices: [
                        {
                            info: { id },
                            name: 's-documentation',
                            data: {
                                title: item.title || '',
                                dictionary: { ...this.dictionary },
                                products: products.map((product) => ({ id, ...product })),
                            },
                        },
                    ],
                };
            });

            this.addProducts();
            this.updateProductSelector();
            this.loaded = true;
        },

        addProducts() {
            this.list.unshift({
                info: { id: 'all-products', caption: this.textAllCategories },
                slices: this.list.map((item) => item.slices[0]),
            });
        },

        updateProductSelector() {
            this.products = [];
            this.list.slice(1).forEach((item) => {
                item.slices.forEach((slice) => {
                    slice.data.products.forEach((product) => {
                        const result = this.activeCategory === 'all-products';
                        if (result || this.activeCategory === product.id) {
                            this.products.push({ id: product.id, name: product.name });
                        }
                    });
                });
            });

            if (this.products.length > 1) {
                this.products.unshift({
                    id: this.activeCategory,
                    name: this.textAllProducts,
                });
            }
        },

        setActiveProduct() {
            const result = this.list.map((item) => ({
                ...item,
                slices: item.slices.map((slice) => ({
                    ...slice,
                    data: {
                        ...slice.data,
                        selected: this.activeCategory === item.info.id ? this.activeProduct : '',
                    },
                })),
            }));
            this.list = result;
        },

        changeCategory(categoryID) {
            this.activeCategory = categoryID;
            this.updateProductSelector();
        },

        changeProduct(productName) {
            const currentName = productName === this.textAllProducts ? '' : productName;
            const currentProduct = this.products.find((p) => p.name === currentName) || {};
            this.activeProduct = currentProduct.name || '';
            this.setActiveProduct();
        },

        handleClick(categoryID, productName) {
            this.changeCategory(categoryID);
            this.changeProduct(productName);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-all-documentation {
    min-height: 800px;
    padding: 160px 0 0;
    position: relative;

    &::after {
        content: '';
        top: 0;
        width: 100%;
        z-index: -1;
        height: 600px;
        position: absolute;
        background: linear-gradient(180deg, rgba(38, 104, 197, 0.05) 0%, rgba(38, 104, 197, 0) 100%);
    }

    &__side-menu {
        padding-top: 32px;
    }

    &:deep(.s-side-menu-content) {
        margin-top: 0;
    }

    &:deep(.s-side-menu-control__arrow) {
        display: none;
    }

    &:deep(.a-accordion__content) {
        display: none;
    }

    &:deep(.a-slice-header) {
        &__title {
            @mixin display-accent;

            @media (--viewport-mobile-wide) {
                @mixin hero-accent;
            }

            @media (--viewport-desktop) {
                @mixin large-accent;
            }
        }
    }

    &:deep(.a-container) {
        .a-container {
            padding-inline-start: 0;
            padding-inline-end: 0;
        }
    }

    &:deep(.s-side-menu__links) {
        padding: 0;
        border: none;
        margin: 32px 0 0;

        .text {
            cursor: default;
        }

        .text-weght-bold {
            font-weight: 700;
        }

        .text-color-nav-primary {
            color: var(--av-nav-primary);
        }

        .text-separator {
            width: 100%;
            margin-top: 32px;
            padding-top: 32px;
            border-top: 1px solid var(--av-brand-secondary-accent);
        }
    }

    &:deep(.s-side-menu__link) {
        display: block;
        padding-inline-start: 16px;
        margin-bottom: 16px;

        .a-link__glyph {
            width: 16px;
            float: left;
            margin-inline-end: 8px;
            margin-inline-start: 0;
            position: relative;
            top: 6px;
        }
    }

    &:deep(.s-documentation) {
        padding: 64px 0 0;

        &:first-child {
            padding: 0;
        }
    }

    &:deep(~ .s-global-footer) {
        display: none;
    }

    &__loaded {
        &:deep(~ .s-global-footer) {
            display: block;
        }
    }
}
</style>
