<template>
    <resource-page>
        <resource-search-filters-panel
            v-if="!isDesktop"
            ref="filtersPanel"
            :initial-filters="searchData"
            :locked-type-id="type.id"
            :visible="filterPanelVisible"
            :products="products"
            :audiences="audiences"
            :tags="tags"
            :industries="industries"
            :common-translations="commonTranslations"
            :loading="searchIsPending"
            @change="applySearchFilters"
            @close="toggleFiltersPanel(false)"
        />

        <div class="s-slice a-container resource-search-page">
            <resource-breadcrumbs :breadcrumbs="breadcrumbs" :common-translations="commonTranslations" />

            <resource-search-panel
                ref="searchPanel"
                v-model="searchRequest"
                :common-translations="commonTranslations"
                @search="applySearchString"
            />

            <div class="page-wrapper" :class="{ 'page-wrapper--padding-bottom': totalPages === 1 }">
                <resource-search-filters-panel
                    v-if="isDesktop"
                    ref="filtersPanel"
                    :initial-filters="searchData"
                    :locked-type-id="type['id']"
                    :visible="filterPanelVisible"
                    :products="products"
                    :audiences="audiences"
                    :tags="tags"
                    :industries="industries"
                    :common-translations="commonTranslations"
                    :loading="searchIsPending"
                    @change="applySearchFilters"
                    @close="toggleFiltersPanel(false)"
                />

                <div class="resources-list">
                    <resource-search-action-bar
                        :total-results="totalResults"
                        :common-translations="commonTranslations"
                        :initial-value="searchData.sort"
                        @sort="applySortRule"
                        @toggle-filters="toggleFiltersPanel(true)"
                    />

                    <template v-if="resources.length">
                        <resource-search-item
                            v-for="resource in resources"
                            :key="resource.id"
                            :resource="resource"
                            :common-translations="commonTranslations"
                        />
                    </template>
                    <div v-else class="empty-result-message">
                        <span>{{ commonTranslations.emptySearchResultText_1 }}</span>
                        <span class="empty-result-reset" @click="triggerResetFilters">
                            &nbsp;{{ commonTranslations.emptySearchResultText_2 }}&nbsp;
                        </span>
                        <span>{{ commonTranslations.emptySearchResultText_3 }}</span>
                    </div>
                </div>
            </div>

            <pagination
                v-if="totalPages > 1"
                :url-template="paginationUrlTemplate"
                :current-page="currentPage"
                :total-pages="totalPages"
                :click-handler="setPage"
            />
        </div>
    </resource-page>
</template>

<script lang="ts">
import resourceCenterSearch from '@core/mixins/resource-center-search';
import { RC_ROOT_BREADCRUMB, RC_ROOT_URL } from '@model/const/resource-center';
import '@core/styles/sections/resource-center.pcss';

export default {
    name: 'ResourceCenterCategory',

    mixins: [resourceCenterSearch],

    props: {
        type: {
            type: Object as any,
            required: true,
        },
    },

    data: () => ({
        filterType: 'types',
        redirectPathNameFirstPage: 'resource-center-category',
        redirectPathNameOtherPages: 'resource-center-category-page',
    }),

    computed: {
        paginationUrlTemplate() {
            return `/$LOCALE$/resource-center/category/${this.type.slug}/page/$PAGE_NUMBER$/`;
        },

        breadcrumbs() {
            const rootBreadcrumb = {
                ...RC_ROOT_BREADCRUMB,
                text: this.commonTranslations.homePageBreadcrumb || RC_ROOT_BREADCRUMB.text,
            };

            return [
                rootBreadcrumb,
                {
                    sublinks: false,
                },
                {
                    text: this.type.title,
                    to: `${RC_ROOT_URL}/${this.type.slug}/`,
                },
            ];
        },

        extendedSearchParams() {
            return {
                types: [this.type.id],
            };
        },

        getQuery() {
            const query = { ...this.searchData };
            // hide types from a query, we have only one type here
            delete query.types;
            return query;
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-search-page {
    position: relative;
    margin-top: 32px;
    padding-top: 0;
    padding-bottom: 0;

    .pagination {
        margin: 64px 0 80px;
    }
}

.page-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    position: relative;
    z-index: 1;

    &--padding-bottom {
        padding-bottom: 80px;
    }

    @media (--viewport-desktop) {
        gap: 0;
    }

    @media (--viewport-desktop-large) {
        max-width: 1312px;
        gap: 32px;
        margin: 0 auto;
    }
}

.resources-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 32px;
    align-items: center;
    width: 100%;

    @media (--viewport-mobile-wide) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: inherit;
        justify-content: center;
    }

    @media (--viewport-tablet) {
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;
    }

    @media (--viewport-desktop) {
        margin-top: 26px;
        gap: 24px;
    }

    @media (--viewport-desktop-wide) {
        margin-top: 33px;
    }

    @media (--viewport-desktop-large) {
        gap: 32px;
    }
}

.empty-result-message {
    text-align: center;
    margin: 100px auto 0;
    @mixin title;
    color: var(--av-fixed-primary);
    max-width: 80%;

    @media (--viewport-mobile-wide) {
        max-width: 82%;
    }

    @media (--viewport-tablet) {
        max-width: 90%;
    }

    @media (--viewport-desktop) {
        max-width: 774px;
    }
}

.empty-result-reset {
    cursor: pointer;
    color: var(--av-brand-primary);
}
</style>
