<template>
    <div class="s-header-with-navigation" :class="className">
        <div class="sticky-bar-wrapper" :class="{'submenu-shown': submenuShown}">
            <div ref="stickyBar" v-sticky="stickyOptions" class="sticky-bar">
                <div class="a-container">
                    <div ref="submenu" class="sticky-content">
                        <div ref="submenuProduct" class="product">
                            <div v-if="submenuShown" class="product-inner">
                                <a-link
                                    v-if="isSubpage && overviewLink"
                                    class="title"
                                    :text="overviewLink.text"
                                    :to="overviewLink.to"
                                />
                                <div v-else class="title">
                                    {{ navigationTitle }}
                                </div>
                                <div v-if="navigationSubtitle" class="subtitle">
                                    {{ navigationSubtitle }}
                                </div>
                            </div>

                            <s-dropdown-navigation
                                v-show="!submenuShown"
                                ref="mobileDropdown"
                                :buttons="dropdownButtons"
                                :menu="dropdownMenu"
                                :show-menu-label="showMenuLabel"
                                :menu-label="menuLabel"
                                :title="navigationTitle"
                                :event="event"
                            />
                        </div>

                        <div v-if="submenu.length && submenuShown" ref="submenuLinks" class="product-submenu">
                            <div v-for="(item, i) in submenu" :key="`submenu-${i}`" class="item">
                                <a-link
                                    v-if="item.to"
                                    class="link"
                                    v-bind="item"
                                    :title="item.text"
                                    :class="{ 'link-active': isActiveLink(item.to) }"
                                    :event="getDefaultALinkGAEvent(item)"
                                    :size="item.size || 'paragraph'"
                                />
                                <div v-if="item.sublinks || item.submenu" ref="dropdown">
                                    <div
                                        ref="sublist"
                                        class="sublist-toggler"
                                        :class="{ active: sublistOpened }"
                                        @click="toggleSublist"
                                    >
                                        {{ item.text }}
                                        <a-glyph class="sublist-glyph" name="drop-down" />
                                    </div>
                                    <div v-if="item.submenu" class="submenu">
                                        <div class="columns">
                                            <div v-for="(tab, j) in item.submenu.columns" :key="`${tab.text}-${j}`" class="column">
                                                <div class="column-title">
                                                    {{ tab.text }}
                                                </div>
                                                <template v-for="(link, l) in tab.links">
                                                    <a-link
                                                        v-if="link.to"
                                                        :key="`${link.to}-${l}`"
                                                        class="sublink"
                                                        v-bind="link"
                                                        :size="link.size || 'paragraph'"
                                                        :class="{ 'link-active': isActiveLink(link.to) }"
                                                        :event="getDefaultALinkGAEvent(link)"
                                                    />
                                                </template>
                                            </div>
                                        </div>
                                        <div v-if="item.submenu.bottomLinks" class="submenu-bottom">
                                            <template v-for="(link, l) in item.submenu.bottomLinks">
                                                <a-link
                                                    v-if="link.to"
                                                    :key="`${link.to}-${l}`"
                                                    class="link"
                                                    v-bind="link"
                                                    :size="link.size || 'paragraph'"
                                                    :event="getDefaultALinkGAEvent(link)"
                                                />
                                            </template>
                                        </div>
                                    </div>
                                    <div v-else-if="item.sublinks" class="sublist">
                                        <div class="sublist-inner">
                                            <template v-for="(link, j) in item.sublinks">
                                                <a-link
                                                    v-if="link.to"
                                                    :key="`sublink-${j}`"
                                                    class="sublink"
                                                    v-bind="link"
                                                    :size="link.size || 'paragraph'"
                                                    :class="{ 'link-active': isActiveLink(link.to) }"
                                                    :event="getDefaultALinkGAEvent(link)"
                                                />
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="buttons" ref="submenuButtons" class="top-buttons">
                            <a-button
                                v-for="(button, i) in renderedButtons"
                                :key="`submenu-button-${i}`"
                                :ref="i === 1 ? 'secondButton' : 'firstButton'"
                                v-bind="button"
                                :size="button.size || 's'"
                                :type="button.type || getButtonType(renderedButtons, i)"
                                :event="{ ...event, ...getButtonGA(button) }"
                                :class="i === 1 ? 'second' : 'first'"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="background a-container">
            <a-picture
                :class="{ 'subpage': isSubpage }"
                :background="background"
                is-background
                fit="contain"
            />
        </div>

        <div class="main-content a-container">
            <h1 class="title">
                {{ title }}
            </h1>
            <p v-if="subtitle" class="subtitle">
                {{ subtitle }}
            </p>
            <div v-if="!isSubpage || isShort" class="cta-block">
                <a-product-cta
                    v-if="ctaSettings"
                    :settings="ctaSettings"
                    use-new-label-design
                />
                <div class="buttons">
                    <a-button
                        v-for="(button, i) in buttons"
                        :key="`button-${i}`"
                        v-bind="button"
                        :size="button.size || 's'"
                        :type="button.type || getButtonType(buttons, i)"
                        :event="{ ...event, ...getButtonGA(button) }"
                    />
                </div>
                <div v-if="actionLinks.length && !isShort" class="action-links">
                    <a-link
                        v-for="(link, i) in actionLinks"
                        :key="`link-${i}`"
                        v-bind="link"
                        :size="link.size || 'paragraph'"
                        type="light"
                    />
                </div>
                <div v-if="additionalLabel" class="cta-additional-label">
                    <span v-html="additionalLabel" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import AProductCta from '@core/components/product-cta/product-cta.vue';
import Sticky from '@core/directives/sticky.js';
import SDropdownNavigation from './dropdown-navigation.vue';

export default {
    name: 'SHeaderWithNavigation',

    components: {
        AButton,
        AGlyph,
        ALink,
        APicture,
        AProductCta,
        SDropdownNavigation,
    },

    directives: {
        Sticky,
    },

    props: {
        title: {
            type: String,
            default: '',
        },

        navigationTitle: {
            type: String,
            required: true,
        },

        subtitle: {
            type: String,
            default: null,
        },

        navigationSubtitle: {
            type: String,
            default: null,
        },

        ctaSettings: {
            type: Object,
            default: null,
        },

        isSubpage: {
            type: Boolean,
            default: false,
        },

        isShort: {
            type: Boolean,
            default: false,
        },

        actionLinks: {
            type: Array,
            default: () => [],
        },

        overviewLink: {
            type: Object,
            default: null,
        },

        buttons: {
            type: Array,
            default: () => [],
        },

        additionalLabel: {
            type: String,
            default: null,
        },

        background: {
            type: Object,
            required: true,
        },

        submenu: {
            type: Array,
            default: () => [],
        },

        menuLabel: {
            type: String,
            default: 'Menu',
        },

        overviewLabel: {
            type: String,
            default: 'Overview',
        },

        productId: {
            type: Number,
            default: null,
        },

        event: {
            type: Object,
            default: () => ({
                category: 'Conversions',
                location: 'Top CTA',
            }),
        },
    },

    data() {
        return {
            stickyOptions: { parent: '.sticky-bar-wrapper' },
            SUBMENU_ITEMS_MARGIN: 48,
            BUTTON_MARGIN: 16,
            productBarLoaded: false,
            sublinkActive: false,
            hideOneButton: false,
            hideTwoButtons: false,
            showDropdownMenu: false,
            showMenuLabel: false,
            sublistOpened: false, // should not be a boolean, because more than one sublist is possible
            submenuShown: true,
            calculateDebounced: null,
        };
    },

    computed: {
        className() {
            return {
                'is-subpage': this.isSubpage,
                'with-cta': this.isShort,
                'is-loaded': this.productBarLoaded,
            };
        },

        renderedButtons() {
            if (this.hideTwoButtons) return [];

            const buttons = [...this.buttons];

            if (this.hideOneButton) {
                buttons.shift();
            }

            return buttons;
        },

        dropdownButtons() {
            if (this.hideTwoButtons) return this.buttons;

            const buttons = [...this.buttons];

            if (this.hideOneButton) {
                buttons.pop();
                return buttons;
            }

            return [];
        },

        dropdownMenu() {
            return [
                {
                    ...this.overviewLink,
                    text: this.overviewLabel,
                },
                ...this.submenu,
            ];
        },
    },

    mounted() {
        if (!this.submenu.length) {
            this.productBarLoaded = true;
        }

        if (document.readyState === 'complete') {
            this.load();
        } else {
            window.addEventListener('load', this.load);
        }

        document.addEventListener('click', this.hideDropdown);
        window.addEventListener('scroll', this.calculate);

        this.calculateDebounced = debounce(this.calculate, 1000);
        window.addEventListener('resize', this.calculateDebounced);
    },

    beforeDestroy() {
        if (!this.submenu.length) {
            return;
        }
        window.removeEventListener('load', this.load);
        window.removeEventListener('scroll', this.calculate);
        window.removeEventListener('resize', this.calculateDebounced);
    },

    methods: {
        async load() {
            const { submenuLinks, firstButton, secondButton } = this.$refs;

            this.submenuLinksWidth = submenuLinks?.offsetWidth || 0;

            const buttonEl = firstButton?.[0]?.$el || firstButton?.$el;
            if (buttonEl) {
                this.submenuButtonsWidth = buttonEl.offsetWidth;
            }

            if (this.buttons.length > 1) {
                this.secondButtonWidth = secondButton?.$el?.offsetWidth || secondButton?.[0]?.$el?.offsetWidth || 0;
                this.submenuButtonsWidth += this.secondButtonWidth + this.BUTTON_MARGIN;
            }

            await this.calculate();

            this.productBarLoaded = true;
        },

        isResizableWidth() {
            const { submenuProduct, submenu, submenuButtons } = this.$refs;

            const availableWidth = (submenu?.offsetWidth || 0) - this.SUBMENU_ITEMS_MARGIN;

            const submenuProductWidth = submenuProduct?.offsetWidth || 0;
            const submenuButtonsWidth = submenuButtons?.offsetWidth || 0;
            const linksWidth = !this.submenuShown ? 0 : (this.submenuLinksWidth || 0);

            return submenuProductWidth + linksWidth + submenuButtonsWidth >= availableWidth;
        },

        async calculate() {
            this.submenuShown = true;
            this.hideOneButton = false;
            this.hideTwoButtons = false;

            await this.$nextTick();

            // if menu doesn’t fit - remove button 1 to keep menu
            if (this.renderedButtons.length > 1) {
                this.hideOneButton = this.isResizableWidth();
                if (this.isResizableWidth() && !this.submenu.length) {
                    this.submenuShown = false;
                }
                await this.$nextTick();
            }

            if (this.isResizableWidth()) {
                this.submenuShown = false;
                this.hideOneButton = false;
                await this.$nextTick();
            }

            if (this.isResizableWidth() && !this.submenuShown) {
                this.hideOneButton = true;
                await this.$nextTick();
            }

            if (this.isResizableWidth() && this.hideOneButton) {
                this.hideTwoButtons = true;
                await this.$nextTick();
            }

            if (!this.buttons.length > 1) {
                this.showDropdownMenu = this.isResizableWidth();
            }
        },

        getButtonType(buttons, index) {
            if (index === 0) {
                if (buttons.length === 1) return 'action';
                return 'main';
            }

            return 'action';
        },

        isActiveLink(link) {
            if (!this.$route) {
                // We're in Storybook, no refs available
                return false;
            }

            return this.$route.path === `/${this.locale}${link}`;
        },

        getButtonGA(button) {
            return {
                label: button.to,
                action: button.dataLayer?.action || button.text,
                content: this.productName,
                ecommerce: {
                    type: button?.event?.ecommerce?.type,
                    license: button?.event?.ecommerce?.license,
                },
            };
        },

        toggleSublist() {
            this.sublistOpened = !this.sublistOpened;
        },

        hideSublist() {
            this.sublistOpened = false;
        },

        getDefaultALinkGAEvent(item) {
            return {
                ...this.event,
                category: 'Interactions',
                label: item.text,
                action: 'top product tab',
            };
        },

        hideDropdown(event) {
            if (!this.sublistOpened || !this.$refs.dropdown) return;

            if (Array.isArray(this.$refs.dropdown)) {
                this.sublistOpened = this.$refs.dropdown.some((el) => el.contains(event.target));
            } else {
                this.sublistOpened = this.$refs.dropdown.contains(event.target);
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-header-with-navigation {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 88px;
    padding-bottom: 48px;
    background: var(--av-nav-primary);
    color: var(--av-inversed-primary);

    /* should be over anchor-menu when dropdown is opened */
    z-index: 121;

    @media (--viewport-mobile-wide) {
        min-height: 600px;
    }

    @media (--viewport-desktop) {
        padding-top: 85px;
    }

    @media (--viewport-desktop-wide) {
        padding-top: 96px;
    }

    @media (--viewport-desktop-large) {
        padding-top: 88px;
    }

    &.with-cta {
        @media (--viewport-mobile-wide) {
            min-height: 469px;
        }

        .cta {
            margin-top: 24px;
        }
    }

    &.is-subpage {
        @media (--viewport-mobile-wide) {
            min-height: 357px;
        }
    }

    &.is-loaded {
        .sticky-bar-wrapper {
            visibility: visible;
            opacity: 1;
        }
    }

    &:deep(.cta) {
        margin: 0 0 16px;
    }

    &:deep(.cta-label) {
        margin-bottom: 8px;
    }

    &:deep(.cta-description) {
        margin: 0 ;
        color: var(--av-inversed-primary);

        del {
            opacity: 0.6;
            color: var(--av-inversed-secondary);
        }

        b {
            font-weight: 600;
        }
    }
}

.sticky-bar:not(.is-sticky) {
    .a-button {
        background: rgba(255, 255, 255, 0);
        border: 1px solid var(--av-brand-secondary);
        padding: 7px 15px;
        transition: background 0.15s ease, border 0.15s ease;

        &.a-button_type_main {
            &:hover {
                background: var(--av-nav-secondary);
                border-color: var(--av-nav-secondary);
            }

            &:active {
                background: #0058b2;
                border-color: #0058b2;
            }
        }

        &.a-button_type_action {
            &:hover {
                background: var(--av-fixed-success);
                border-color: var(--av-fixed-success);
            }

            &:active {
                background: var(--av-fixed-success-dark);
                border-color: var(--av-fixed-success-dark);
            }
        }
    }
}

.sticky-bar-wrapper {
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 57px;
    margin-bottom: 40px;
    z-index: 11;

    @media (--viewport-mobile-wide) {
        height: 64px;
    }

    @media (--viewport-desktop-wide) {
        height: 65px;
    }

    &:not(.submenu-shown) .sticky-bar {
        height: 58px;

        &::after {
            position: absolute;
            content: '';
            z-index: 10;
            height: 1px;
            inset-inline-start: 0;
            inset-inline-end: 0;
            bottom: -2px;
            background: rgba(0, 101, 227, 0.4);
        }

        @media (--viewport-mobile-wide) {
            height: 66px;
        }

        &.two-buttons-hidden {
            .product {
                width: 100%;
            }

            .toggler {
                padding: 0;
            }
        }
    }
}

.sticky-bar {
    top: 0;
    border-top: 1px solid rgba(0, 101, 227, 0.4);
    height: 57px;
    padding-top: 8px;
    position: relative;

    @media (--viewport-mobile-wide) {
        height: 65px;
        padding-top: 12px;
    }

    &.two-buttons-hidden {
        .product {
            width: 100%;
        }

        .toggler {
            padding: 0;
        }
    }

    &.is-sticky {
        position: fixed;
        top: 0;
        width: 100%;
        box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
        border-top: 0;
        background: var(--av-nav-primary);
        border-bottom: 1px solid rgba(0, 101, 227, 0.4);
        z-index: 1000;

        &::after {
            display: none;
        }

        .subtitle {
            display: none;
        }
    }

    .a-button {
        + .a-button {
            margin-inline-start: 16px;
        }
    }
}

.sticky-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;

    .product {
        white-space: nowrap;
    }

    .title {
        @mixin nav-title-accent;
        font-weight:400;
        color: var(--av-inversed-primary);
        text-decoration: none;
        margin-top: -2px;
        margin-bottom: 4px;

        &:deep(.a-link__content) {
            @mixin nav-title-accent;
            font-weight:400;
            color: var(--av-inversed-primary);
        }

        &:hover,
        &:focus {
            &:deep(.a-link__content) {
                background: none;
                color: var(--av-inversed-light);
            }
        }
    }

    .subtitle {
        position: absolute;
        color: rgba(166, 203, 255, 1);
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 4px;
        text-transform: uppercase;
        padding-inline-end: 48px;
        font-weight: 600;
    }

    .top-buttons {
        margin-inline-start: auto;
        white-space: nowrap;
        display: flex;
        align-items: center;
    }
}

.product-submenu {
    padding-inline-start: 48px;
    display: flex;
    flex-shrink: 0;
    white-space: nowrap;
    align-items: center;

    .item {
        &:not(:last-child) {
            margin-inline-end: 24px;
        }
    }

    .link {
        @mixin paragraph-accent;
        display: block;

        &:deep(.a-link__content) {
            color: var(--av-inversed-primary);
        }

        &:hover,
        &:focus {
            &:deep(.a-link__content) {
                background: none;
                color: var(--av-link-inversed);
            }
        }

        &.link-active {
            font-weight: 600;
        }

        &::before {
            display: block;
            content: attr(title);
            font-weight: 600;
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }
    }

    .sublist-container {
        position: relative;
        padding-bottom: 12px;
        margin-bottom: -12px;
    }

    .sublist-toggler {
        @mixin paragraph-accent;
        position: relative;
        display: flex;
        align-items: center;
        background: none;
        border: none;
        padding: 4px 8px;
        margin: -4px -8px;
        color: var(--av-inversed-primary);
        cursor: pointer;
        border-radius: 8px;

        & > * {
            pointer-events: none;
        }

        &:hover {
            color: var(--av-link-inversed);
        }

        &.active {
            background: var(--av-brand-light);
            color: var(--av-inversed-primary);

            .sublist-glyph {
                transform: rotate(180deg);
                transition: transform 0.3s ease-out;
            }

            & + .sublist,
            & + .submenu  {
                display: block;
            }

            &::before {
                position: absolute;
                content: '';
                display: block;
                bottom: -20px;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 0 9px 7px 9px;
                border-color: transparent transparent rgb(64, 139, 234) transparent;
                inset-inline-end: 6px;
            }

            &::after {
                position: absolute;
                content: '';
                display: block;
                bottom: -21px;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 0 8px 6px 8px;
                border-color: transparent transparent #fff transparent;
                z-index: 1;
                inset-inline-end: 7px;
            }
        }
    }

    .sublist-glyph {
        margin-inline-start: 8px;
        fill: currentcolor;
        transition: transform 0.15s ease-out;
    }

    .sublist-inner,
    .submenu {
        background-color: var(--av-inversed-primary);
        box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
        border-radius: 6px;
        border: 1px solid var(--av-brand-secondary);
    }

    .sublist {
        display: none;
        position: relative;
        margin-inline-start: 50%;
        top: 24px;
    }

    .sublist-inner {
        position: absolute;
        transform: translateX(-50%);
    }

    .sublist-inner {
        padding: 32px;
        inset-inline: auto;
        margin: auto;
        display: flex;
        flex-direction: column;
        width: fit-content;
    }

    .sublist-inner {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .submenu {
        display: none;
        position: absolute;
        width: 960px;
        top: 56px;
        inset-inline: 0;
        margin: auto;

        .columns {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 16px;
            padding: 32px;
        }

        .column {
            display: flex;
            flex-direction: column;
            gap: 8px;
            white-space: normal;
        }
    }

    .submenu-bottom {
        border-top: 1px solid var(--av-brand-light);
        background: var(--av-solid-brand-lightest);
        padding: 16px 32px;
        display: flex;
        justify-content: space-between;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .link {
            &:deep(.a-link__content) {
                @mixin body-accent;
                color: var(--av-nav-primary);
            }

            &:deep(.a-glyph) {
                color: var(--av-brand-primary);
            }

            &:hover {
                &:deep(.a-link__content) {
                    color: var(--av-brand-secondary);
                }
            }
        }
    }

    .column-title {
        @mixin lead-accent;
        color: var(--av-nav-primary);
        margin-bottom: 8px;
    }

    .sublink {
        @mixin paragraph;
        position: relative;
        display: block;
        padding: 0;
        color: var(--av-brand-primary);
        cursor: pointer;

        &::before {
            content: '';
            display: block;
            position: absolute;
            inset: -4px -8px;
            border-radius: 4px;
        }

        &:hover,
        &.link-hover {
            &::before {
                background: var(--av-brand-secondary-accent);
            }
        }

        &:active {
            &::before {
                background: var(--av-brand-secondary-light);
            }
        }
    }
}

.split-buttons-and-ribbon-b {
    .a-button_type_main {
        padding: 11px 15px;
        border: 1px solid var(--av-nav-secondary);

        background: var(--av-nav-secondary);
        &:hover {
            background: var(--av-button-main-hover);
        }
        &:active {
            background: var(--av-button-main-active);
        }
    }

    .a-button_type_action {
        background: transparent;
        color: var(--av-inversed-primary);
        padding: 11px 15px;
        border: 1px solid #90bcf9 !important;
        border-radius: 4px;

        &:hover {
            background: var(--av-brand-light) !important;
        }

        &:active {
            background: rgba(38, 104, 197, 0.6) !important;
        }
    }

    .s-header-product-new:not(.is-subpage),
    .sticky-bar:not(.is-sticky),
    .top-buttons {
        .a-button_type_main {
            padding: 7px 15px;
            border: 1px solid var(--av-nav-secondary);

            background: var(--av-nav-secondary);
            &:hover {
                background: var(--av-button-main-hover);
            }
            &:active {
                background: var(--av-button-main-active);
            }
        }

        .a-button_type_action {
            padding: 7px 15px;
        }
    }

    .top-buttons {
        .a-button_type_main,
        .a-button_type_action {
            padding: 7px 15px;
        }
    }

    .a-webinar-card__link {
        color: var(--av-brand-primary);

        .a-button__glyph {
            fill: var(--av-brand-primary);
        }

        &:hover {
            background: rgba(64, 139, 234, 0.1) !important;
        }

        &:active {
            background: rgba(64, 139, 234, 0.2) !important;
        }
    }
}

.background.a-container {
    position: relative;
    width: 100%;
    height: 240px;
    margin-top: 24px;
    margin-bottom: -24px;

    @media (--viewport-desktop) {
        top: 85px;
        position: absolute;
        inset-inline-start: 0;
        inset-inline-end: 0;
        width: 100%;
        margin: auto;
        height: calc(100% - 85px);
    }

    @media (--viewport-desktop-wide) {
        top: 96px;
        height: calc(100% - 96px);
    }

    @media (--viewport-desktop-large) {
        top: 88px;
        height: calc(100% - 88px);
    }

    .a-picture {
        position: relative;
        width: 100%;
        height: 100%;

        @media (--viewport-desktop) {
            &:deep(.a-picture__img) {
                object-position: center bottom !important;
            }

            &.subpage {
                &:deep(.a-picture__img) {
                    object-position: right bottom !important;
                }
            }
        }
    }
}

.section-name {
    @mixin display;
    margin-bottom: 64px;
    color: var(--av-inversed-primary);
}

.main-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    position: relative;

    .title {
        @mixin display-accent;

        @media (--viewport-mobile-wide) {
            @mixin hero-accent;
        }

        @media (--viewport-desktop) {
            max-width: 50%;
            @mixin large-accent;
        }
    }

    .cta-block {
        margin-top: 40px;
    }

    .buttons {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        @media (--viewport-mobile-wide) {
            display: inline-grid;
            grid-auto-columns: 1fr;
            grid-column-gap: 16px;
        }

        .a-button {
            width: 100%;
            margin-bottom: 16px;
            padding: 11px 15px;

            @media (--viewport-mobile-wide) {
                grid-row: 1;
                margin-bottom: 0;
            }
        }
    }

    .action-links {
        margin-top: 16px;
        display: flex;
        justify-content: flex-start;

        .a-link {
            @mixin paragraph;
            color: var(--av-brand-secondary);
            text-decoration: none;
            margin-inline-end: 24px;
        }
    }
}

</style>
