<template>
    <div class="event-partner-card">
        <div class="logo-wrap">
            <a-picture
                class="logo"
                :link="logo"
                fit="contain"
                size="small"
            />
        </div>
        <div class="text">
            <div class="title">
                {{ title }}
            </div>
            <div v-if="description" class="description">
                {{ description }}
            </div>
            <a
                v-if="link"
                class="event-link link"
                :href="link.to"
                target="_blank"
            >
                {{ link.text }}
                <a-glyph name="arrow" />
            </a>
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'EventPartnerCard',

    components: {
        APicture,
        AGlyph,
    },
    props: {
        title: {
            type: String,
            required: true,
        },

        logo: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            default: null,
        },

        link: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-partner-card {
    @media (--viewport-tablet) {
        display: grid;
        grid-template-columns: 1fr 3fr;
        column-gap: 16px;
        align-items: center;
    }

    .logo-wrap {
        padding: 40px 40px;
        height: 180px;
    }

    .logo {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .text {
        color: var(--av-nav-primary);

        @media (--viewport-tablet) {
            flex-grow: 1;
        }
    }

    .title {
        @mixin display-strong;
        margin-bottom: 16px;

        @media (--viewport-desktop) {
            @mixin hero-strong;
        }
    }

    .description {
        @mixin lead;
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            @mixin title;
        }
    }

    .link {
        @mixin paragraph;
        font-weight: 500;
        color: var(--av-brand-secondary);
        margin-top: 8px;

        @media (--viewport-tablet) {
            @mixin title;
            font-weight: 500;
        }
    }
}
</style>
