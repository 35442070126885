<template>
    <div class="event-show-all-button">
        <button class="event-button button" :class="{ clicked: showAll }" @click="toggleShowAll">
            {{ showAll ? showLessText : showAllText }}
            <a-glyph name="drop-down" />
        </button>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'EventShowAllButton',

    components: {
        AGlyph,
    },

    props: {
        showAllText: {
            type: String,
            required: true,
        },
        showLessText: {
            type: String,
            required: true,
        },
    },

    emits: ['toggle'],

    data() {
        return {
            showAll: false,
        };
    },

    methods: {
        toggleShowAll() {
            this.showAll = !this.showAll;
            this.$emit('toggle', this.showAll);
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-show-all-button {
    .button {
        padding: 4px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        color: var(--av-brand-secondary);
        text-align: center;
        width: 100%;
        border: 1px solid var(--av-brand-secondary-light);

        &:hover {
            background: var(--av-brand-accent);
        }

        .a-glyph {
            margin-inline-start: 8px;
            fill: var(--av-brand-secondary);
        }

        &.clicked {
            .a-glyph {
                transform: scaleY(-1);
            }
        }
    }
}
</style>
