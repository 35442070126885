import { mapState } from 'vuex';

import '@core/styles/sections/pages.pcss';
import '@core/styles/sections/solutions.pcss';
import { SOLUTIONS_RESOURCE_URL } from '@model/const/solutions';

export default {
    data() {
        return {
            AVAILABLE_TAGS: {
                NEW: {
                    text: 'New',
                    variant: 'success',
                    weight: 30,
                },
                COMING_SOON: {
                    text: 'Coming soon',
                    variant: 'info',
                    weight: 20,
                },
                LIGHTHOUSE: {
                    text: 'Lighthouse',
                    variant: 'success',
                    weight: 10,
                },
            },
        };
    },

    computed: {
        ...mapState({
            pageSettings: (state) => state.slices.items['solutions-acronis-com-home'],
            integrations: (state) => state.solutions.integrations,
            currentIntegration: (state) => state.solutions.integration,
            categories: (state) => state.solutions.categories,
        }),
    },

    methods: {
        prepareIntegrationFields(integration) {
            if (!integration) return {};

            return {
                ...integration,
                logo: `${SOLUTIONS_RESOURCE_URL}${integration.logo}`,
                tag: this.AVAILABLE_TAGS[integration.publishing_tag] || null,
            };
        },

        sortByTags(a, b) {
            const aWeight = this.AVAILABLE_TAGS[a.publishing_tag]?.weight || 0;
            const bWeight = this.AVAILABLE_TAGS[b.publishing_tag]?.weight || 0;
            return bWeight - aWeight;
        },
    },
};
