import { parseISO } from 'date-fns';
import {
    getEventDescription,
    getShortOrganizationSchema,
    getSiteUrl,
    getUrlWithLocale,
    MAIN_SITE_URL,
} from '@core/mixins/structuredData/helper';
import currencies from '@model/const/currencies';
import { LOCALE_DEFAULT } from '@model/const/locales';
import SchemaHandler from '@model/seo/schemaHandler';
import { EventSchema, SchemaConfig } from '@model/seo/schemas';
import eventUtils from '@utils/events.js';
import { getOgImage } from '@utils/html-meta';

const PAGINATE_SIZE = 50;

export default class EventHandler implements SchemaHandler {
    ctx: any;

    schemasConfig: SchemaConfig[];

    constructor(ctx: any, schemasConfig: SchemaConfig[]) {
        this.ctx = ctx;
        this.schemasConfig = schemasConfig;
    }

    getSchemaData(staticConfig: EventSchema): Promise<EventSchema> {
        return this.getRequiredFields(staticConfig);
    }

    async getRequiredFields(staticConfig: EventSchema): Promise<EventSchema> {
        const locale = 'en-us';
        const currency = currencies[locale] || 'USD';
        const event = this.ctx.$store.state.events.event.items[0];
        const productId = event?.product_ids?.[0];
        const description = getEventDescription(event);

        if (productId && !Object.keys(this.ctx.$store.state.products?.items).length) {
            await this.ctx.$store.dispatch('products/getProducts', LOCALE_DEFAULT);
        }

        const productData = this.ctx.$store.state.products.items?.[productId];

        const data = {
            ...staticConfig,
            name: event.seo_title || event.name,
            eventStatus: 'https://schema.org/EventScheduled',
            organizer: getShortOrganizationSchema(),
            description,
        };

        if (productData) {
            const ctaConfig = productData?.parameters?.common?.productPageCTA;
            let offer: any;

            if (!ctaConfig) {
                offer = {
                    '@type': 'Offer',
                    availability: 'https://schema.org/InStock',
                    price: 0,
                    priceCurrency: 'USD',
                    name: 'Free Offer',
                    '@id': 'https://acronis.com/#FreeOffer',
                };

                if (productData.parameters?.common?.tryLink) {
                    const url = productData.parameters.common.tryLink.to;
                    if (url.startsWith('https://')) {
                        offer.url = url;
                    } else {
                        offer.url = `${MAIN_SITE_URL}/en-us${url}`;
                    }
                }
            } else {
                const cleverBridgeID = ctaConfig.cleverBridgeCartID;
                const coupon = ctaConfig.promo?.cleverBridgeCartList?.includes(cleverBridgeID)
                    ? ctaConfig.promo.coupon
                    : '';
                const payload = {
                    locale,
                    currency,
                    paginate: { number: 1, size: PAGINATE_SIZE },
                };

                await this.ctx.$store.dispatch('licenses/getList', {
                    ...payload,
                    cleverbridgeIDs: [cleverBridgeID],
                    coupon,
                });

                const license = this.ctx.$store.state.licenses.data.find((l) => l.cleverbridge_id === `${cleverBridgeID}`);

                if (license) {
                    offer = {
                        '@type': 'Offer',
                        availability: 'https://schema.org/InStock',
                        name: license.name,
                        price: license.price,
                        priceCurrency: license.currency,
                    };

                    if (productData.parameters?.common?.buyLink) {
                        const url = productData.parameters.common.buyLink.to;
                        if (url.startsWith('https://')) {
                            offer.url = url;
                        } else {
                            offer.url = `${getUrlWithLocale(this.ctx)}${url}`;
                        }
                    }
                } else {
                    offer = {
                        '@type': 'Offer',
                        price: 0,
                        priceCurrency: currency,
                        name: `${locale.toUpperCase()} Free Offer`,
                    };

                    if (productData.parameters?.common?.tryLink) {
                        const url = productData.parameters.common.tryLink.to;
                        if (url.startsWith('https://')) {
                            offer.url = url;
                        } else {
                            offer.url = `${MAIN_SITE_URL}/en-us${url}`;
                        }
                    }
                }
            }

            data.offers = offer;
        }

        const occurrence = eventUtils.getActualOccurrence(event);

        if (occurrence) {
            const startDate = parseISO(occurrence.begins_at);
            const endDate = parseISO(occurrence.ends_at);

            data.startDate = startDate.toISOString();
            data.endDate = endDate.toISOString();
        } else if (event.dynamic_last_occurence) {
            data.startDate = parseISO(event.dynamic_last_occurence).toISOString();
        } else {
            data.startDate = parseISO(event.updated_at).toISOString();
        }

        if (event.is_in_person && event.is_virtual) {
            data.eventAttendanceMode = 'MixedEventAttendanceMode';
        } else if (event.is_in_person) {
            data.eventAttendanceMode = 'OfflineEventAttendanceMode';
        } else {
            data.eventAttendanceMode = 'OnlineEventAttendanceMode';
        }

        if (event.place_name || event.place_address) {
            data.location = {
                '@type': 'Place',
                name: event.place_name,
                address: {
                    '@type': 'PostalAddress',
                    name: event.place_address,
                },
            };
        } else {
            data.location = {
                '@type': 'Place',
                address: {
                    '@type': 'PostalAddress',
                    url: `${getSiteUrl(this.ctx)}${this.ctx.$route?.path || '/'}`,
                },
            };
        }

        data.image = getOgImage(`@${event.cover.image_id}`, process.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);

        data.performer = event.speakers.map((speaker) => ({
            '@type': 'Person',
            name: speaker.full_name,
        }));

        return data;
    }
}
