<template>
    <div class="topnav">
        <topnav-static
            v-bind="mergedProps"
            :class="staticLayout"
            @menu-toggle="menuIsOpened = !menuIsOpened"
        />
        <topnav-sticky
            v-if="isSticky"
            v-bind="mergedProps"
            @menu-toggle="menuIsOpened = !menuIsOpened"
        />
        <menu-dropdown
            v-if="menuIsOpened"
            ref="menu"
            v-bind="{ ...mergedProps, isSticky }"
            @menu-close="menuIsOpened = false"
        />
    </div>
</template>

<script>
import MenuDropdown from './components/menu-dropdown.vue';
import TopnavStatic from './components/topnav-static.vue';
import TopnavSticky from './components/topnav-sticky.vue';

export default {
    name: 'DeveloperTopnav',

    components: {
        TopnavStatic,
        TopnavSticky,
        MenuDropdown,
    },

    props: {
        staticLayout: {
            type: String,
            required: false,
            default: 'bottom',
            validator: (val) => ['bottom', 'center'].includes(val),
        },

        stickyTresholdSelector: {
            type: String,
            required: false,
            default: '',
        },
    },

    data() {
        return {
            isSticky: false,
            menuIsOpened: false,
        };
    },

    computed: {
        mergedProps() {
            const res = this.$store.state.slices.items['s-developer-topnav'];
            res.stickyTresholdSelector = this.stickyTresholdSelector || res.stickyTresholdSelector;
            return { ...res, menuIsOpened: this.menuIsOpened };
        },
    },

    async serverPrefetch() {
        const locale = this.$route?.params.locale;
        await this.$store.dispatch('slices/getSyncedData', { slice: 's-developer-topnav', locale });
    },

    mounted() {
        window.addEventListener('scroll', this.onScroll, true);
        this.onScroll();
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll, true);
    },

    methods: {
        onScroll() {
            const tresholdElement = document.querySelector(this.mergedProps.stickyTresholdSelector);
            if (!tresholdElement) return;

            const tresholdGeometry = tresholdElement.getBoundingClientRect();
            this.isSticky = tresholdGeometry.y + tresholdGeometry.height <= 0;
        },
    },
};
</script>
