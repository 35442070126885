<template>
    <section class="express-signup-partner-form">
        <div class="a-container">
            <transition name="express-signup-slide-up">
                <header v-if="!isAnimation" class="header">
                    <a-logo class="logo" type="full" />
                    <h1 v-if="title" class="title">
                        {{ title }}
                    </h1>
                </header>
            </transition>
            <transition name="express-signup-slide-up">
                <s-form-container v-if="!isAnimation && form" :form="form" />
            </transition>
        </div>
        <transition name="express-signup-slide-up">
            <div v-if="!isAnimation" class="footer">
                <express-signup-footer v-if="footer" v-bind="footer" />
            </div>
        </transition>
    </section>
</template>

<script>
import ALogo from '@core/components/logo/logo.vue';
import ExpressSignupFooter from '@core/slices/pages/express-signup-shared/express-signup-footer.vue';
import SFormContainer from '@core/slices/pages/trial-form/components/form/form.vue';

export default {
    name: 'SExpressSignupPartnerForm',
    components: {
        ALogo,
        ExpressSignupFooter,
        SFormContainer,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        footer: {
            type: Object,
            required: false,
            default: null,
        },
        form: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isAnimation: true,
        };
    },
    mounted() {
        this.isAnimation = false;
    },

    serverPrefetch() {
        const locale = this.$route.params.locale;
        this.$store.dispatch('slices/getSyncedData', { slice: 's-data-centers', locale });
        this.$store.dispatch('locales/getLocaleData', { locale });
        this.$store.dispatch('countries/getCountries');
    },
};
</script>

<style lang="postcss" scoped>
.express-signup-partner-form {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    min-height: 100vh;
    overflow-x: hidden;
    background: var(--av-gradient-to-bottom-lightest);
    color: var(--av-nav-primary);
    text-align: center;

    .header {
        text-align: center;
        color: var(--av-nav-primary);
        .logo {
            width: 156px;
            height: 32px;
            margin-top: 40px;
            @media (--viewport-tablet) {
                width: 205px;
                height: 56px;
            }
        }
        .title {
            @mixin title;
            font-weight:500;
            margin: 32px auto 16px;
            @media (--viewport-tablet) {
                @mixin display;
            }
        }
    }
    .footer {
        width: 100%;
        padding: 40px 0;
        align-self: flex-end;
    }

    &:deep(.s-form-container) {
        & {
            width: 100%;
            max-width: 520px;
            min-height: 520px;
            text-align: start;
            margin: 0 auto;

            .el-input__label.is-active {
                padding-top: 5px;
            }
        }

        .s-form-container__loading {
            background: transparent;

            & + div {
                display: none;
            }
        }

        .s-form-registration {
            padding: 0;
            border: none;
            box-shadow: none;
            background: transparent;

            .el-form {
                text-align: start;
            }
        }

        .s-form-registration__loading {
            background: transparent;

            & ~ .el-form  {
                opacity: 0;
            }
        }

        .s-form-registration__title {
            @mixin paragraph;
            font-weight: 400;
            color: var(--av-fixed-secondary);

        }

        .s-form-registration__section {
            @mixin paragraph;
            text-align: center;
            font-weight: 400;
            margin: 0 0 8px;
            padding: 0;
            color: var(--av-fixed-secondary);
        }

        .s-form-registration__button {
            @mixin paragraph;
            font-weight: 500;
            display: block;
            text-align: center;
            max-width: 306px;
            width: 100%;
            margin-inline-end: auto;
            margin-inline-start: auto;
        }

        .s-form-registration__checkboxes {
            .el-checkbox__label {
                @mixin body;
                color: var(--av-fixed-secondary);
            }
        }

        .s-form-registration__note {
            max-width: 344px;
            margin: 16px auto;

            @media (--viewport-desktop) {
                max-width: 510px;
            }
        }

        .s-form-registration__note-item {
            @mixin caption;
            line-height: 20px;
            margin-bottom: 16px;
            color: var(--av-fixed-secondary);

            &:last-child {
                margin: 0;
            }
        }

        .s-form-registration__error {
            @mixin caption;
        }
    }
}

.express-signup-slide-up-leave-active,
.express-signup-slide-up-enter-active {
    transition: 0.5s;
}
.express-signup-slide-up-enter {
    transform: translateY(30%);
    opacity: 0;
}
.express-signup-slide-up-leave-to {
    transform: translateY(0);
    opacity: 1;
}
</style>
