/**
 * Link types
 * direct and download-file are legacy types, but left for backward compatibility
 *
 * @return {string[]}
 */
export const TYPES = ['download-file', 'direct', 'regular', 'secondary', 'dark', 'light'];

/**
 * Link sizes
 *
 * @return {string[]}
 */
export const SIZES = ['caption', 'body', 'paragraph', 'lead', 'title'];

/**
 * Link glyph positions
 *
 * @return {string[]}
 */
export const GLYPH_POSITIONS = ['left', 'right'];
