<template>
    <section class="s-dlp-support">
        <div class="a-container">
            <h1 v-if="pageTitle" class="s-dlp-support__page-title">
                {{ pageTitle }}
            </h1>
            <h1 v-if="textTitle" class="s-dlp-support__text-title">
                {{ textTitle }}
            </h1>
            <div v-if="lead" class="s-dlp-support__lead">
                <a-dangerous-html :content="lead" />
            </div>
            <p v-if="note" class="s-dlp-support__note">
                {{ note }}
            </p>
            <div class="s-dlp-support__content">
                <template v-for="(row, i) in content">
                    <div
                        v-if="!row.type && row.style"
                        :key="i"
                        class="s-dlp-support__row"
                        :class="`s-dlp-support__row_${row.style}`"
                    >
                        <a-dangerous-html :content="row.val" />
                    </div>
                    <ol v-else-if="row.type === 'list'" :key="i" class="s-dlp-support__ol">
                        <li v-for="(li, j) in row.list" :key="j" class="s-dlp-support__li">
                            <a-dangerous-html class="s-dlp-support__li-inner" :content="li.val" />
                        </li>
                    </ol>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';

export default {
    name: 'SDlpSupport',
    components: {
        ADangerousHtml,
    },
    props: {
        pageTitle: {
            type: String,
            default: '',
        },

        textTitle: {
            type: String,
            default: '',
        },

        lead: {
            type: String,
            default: '',
        },

        note: {
            type: String,
            default: '',
        },

        /**
         * Main text content
         */
        content: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss">
.s-dlp-support {
    @mixin basic-slice-paddings;
    color: var(--av-nav-primary);

    &__page-title {
        @mixin large-subtle;
        max-width: 300px;
        margin-bottom: 88px;

        @media (--viewport-desktop) {
            margin-top: -10px;
        }
    }

    &__text-title {
        @mixin hero;

        margin-bottom: 24px;

        @media (--viewport-mobile-wide) {
            width: 66.66%;
        }
    }

    &__lead {
        @mixin title;

        margin-bottom: 24px;

        @media (--viewport-desktop) {
            width: 66.66%;
        }
    }

    &__note {
        @mixin body;

        color: var(--av-fixed-light);
        margin-bottom: 56px;
    }

    &__content {
        color: var(--av-fixed-secondary);

        @media (--viewport-desktop) {
            width: 66.66%;
        }
    }

    &__row {
        &_p-1 {
            @mixin descriptor;
            margin-bottom: 24px;

            &-accent {
                @mixin descriptor-accent;
                margin-bottom: 24px;
            }
        }

        &_p-2 {
            @mixin paragraph;

            margin-bottom: 24px;

            &-accent {
                @mixin paragraph-accent;

                margin: 40px 0 16px;
            }
        }
    }

    &__ol {
        margin-bottom: 40px;
        list-style-type: decimal;
        list-style-position: inside;
    }

    &__li {
        @mixin paragraph;
        margin-bottom: 24px;

        &::marker {
            content: initial;
        }
    }

    &__li-inner {
        display: inline;
    }
}
</style>
