<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import { trim } from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { SITE_INT_ID_SCS } from '@model/const/sites';
import { HttpRejection } from '@model/http/rejection';
import { getOgImage } from '@utils/html-meta';
import PagesComponent from './component.vue';

export default Vue.extend({
    name: 'PagesContainer',

    async serverPrefetch(): Promise<void> {
        // Init
        const locale = LOCALE_DEFAULT;
        const slug = this.$route.params.pathMatch;
        const previewId = this.$route.params.previewId;
        const previewKey = this.$route.query?.key;
        const state = this.$store.state.pages;

        // Disable LB cache for preview
        if (previewKey || previewId) {
            this.$ssrContext.res.headers.push({ key: 'Cache-Control', val: 'no-store' });
        }

        // Get page
        const pageRequestUrl = previewId ? `/api/utility/cache/${previewId}` : '/api/core/pages';
        const pageRequest = new ProductAPIQueryBuilder('page').setEntityPath(pageRequestUrl);

        const audiencesQuery = new ProductAPIQueryBuilder('audiences')
            .setEntityPath('/api/core/pages/audiences/')
            .setPaginate(1, 50)
            .toObject();

        const typesQuery = new ProductAPIQueryBuilder('types')
            .setEntityPath('/api/core/pages/types/')
            .setPaginate(1, 50)
            .toObject();

        if (!previewId) {
            pageRequest
                .setLocales([locale])
                .addMatchesAll('slug', '=', trim(slug, '/'))
                .addMatchesAll('website_id', '=', SITE_INT_ID_SCS)
                .setPaginate(1, 1)
                .setCustomParam('page', previewKey ? { key: previewKey } : null)
                .setCustomParam('process-macros', '1');
        }

        this.$store.dispatch('pages/getEntity', { request: audiencesQuery });
        this.$store.dispatch('pages/getEntity', { request: typesQuery });
        const requestObj = pageRequest.toObject();
        await this.$store.dispatch('pages/getPage', { request: requestObj });

        if (state.httpStatus !== StatusCodes.OK) {
            throw new HttpRejection('Premature rendering stop', state.httpStatus);
        }

        if (!state.page?.id || !state.page?.body?.length) {
            throw new HttpRejection('Premature rendering stop', StatusCodes.NOT_FOUND);
        }

        // Get linked entities
        const promises: Promise<void>[] = [];

        // if (!state.page.settings?.hide_ribbon) {
        //     promises.push(this.$store.dispatch('ribbon/getAll', { locale, slug: state.page.slug }));
        // }

        // TODO: push a banners promise here if needed

        await Promise.all(promises);

        // Building page meta
        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
    },

    methods: {
        getMeta(): any {
            const page = this.$store.state.pages.page;

            const ogimage = getOgImage(page.meta?.image, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = page.meta?.title || 'Acronis SCS – US Public Sector Cyber Protection';
            const description = page.meta?.description || title;
            const isIndexed = page.is_indexed;

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: page.meta?.['og:description'] || description },
                    { tag: 'meta', property: 'og:image', content: ogimage },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    {
                        tag: 'meta',
                        name: 'twitter:description',
                        content: page.meta?.['twitter:description'] || description,
                    },
                    { tag: 'meta', name: 'twitter:image', content: ogimage },
                    isIndexed ? null : { tag: 'meta', name: 'robots', content: 'noindex, nofollow' },
                    { tag: 'link', rel: 'image_src', href: ogimage },
                ],
                htmlAttrs: {
                    dir: 'ltr',
                    lang: 'en',
                },
                ldJsonSchema: page.schema,
            };
        },
    },

    render(h) {
        return h(PagesComponent);
    },
});
</script>
