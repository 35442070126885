import { BORDERS, COLORS } from '@core/slices/pages/basic-slice/constants.js';

export default {
    props: {
        margin: {
            type: String,
            default: undefined,
            required: false,
        },
        padding: {
            type: String,
            default: undefined,
            required: false,
        },
        noBottomPadding: {
            type: Boolean,
            default: false,
        },
        borderTop: {
            type: String,
            validator: (value) => !value || BORDERS.includes(value),
        },
        borderBottom: {
            type: String,
            validator: (value) => !value || BORDERS.includes(value),
        },
        background: {
            type: String,
            validator: (value) => value === undefined || COLORS.includes(value),
            default: 'white',
        },
    },
    computed: {
        basicClassName() {
            return {
                's-slice_no-bottom-padding': this.noBottomPadding,
                's-slice_border-bottom': this.borderBottom,
                [`s-slice_border-bottom_${this.borderBottom}`]: this.borderBottom,
                's-slice_border-top': this.borderTop,
                [`s-slice_border-top_${this.borderTop}`]: this.borderTop,
                [`s-slice_background_${this.background}`]: this.background,
                [this.padding]: this.padding,
                [this.margin]: this.margin,
            };
        },
    },
};
