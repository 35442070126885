export const localeMapping = {
    'en-us': 'lang_en',
    'en-eu': 'lang_en',
    'en-sg': 'lang_en',
    'bg-bg': 'lang_bg',
    'cs-cz': 'lang_cz',
    'de-de': 'lang_de',
    'es-es': 'lang_es',
    'es-mx': 'lang_es',
    'fr-fr': 'lang_fr',
    'he-il': 'lang_iw',
    'hu-hu': 'lang_hu',
    'it-it': 'lang_it',
    'ja-jp': 'lang_ja',
    'ko-kr': 'lang_ko',
    'nl-nl': 'lang_nl',
    'pl-pl': 'lang_pl',
    'pt-br': 'lang_pt',
    'ro-ro': 'lang_ro',
    'tr-tr': 'lang_tr',
    'zh-cn': 'lang_zh-CN',
    'zh-tw': 'lang_zh-TW',
    'sv-se': 'lang_sv',
};
