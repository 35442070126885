<template>
    <section :id="id" class="s-cta-three-column" :class="basicClassName">
        <div class="a-container">
            <a-slice-header
                v-if="title || lead || bodyText"
                :title="title"
                :lead="lead"
                :body-text="bodyText"
            />
            <div class="cards-wrapper">
                <h5 v-if="cardsHeader" class="main-title">
                    {{ cardsHeader }}
                </h5>
                <div class="cards">
                    <div v-for="(item, index) in cards" :key="`cta-three-column_${index}`" class="card">
                        <div class="top">
                            <p v-if="item.title" class="title">
                                {{ item.title }}
                            </p>
                            <a-picture class="image" :link="item.image" fit="cover" />
                        </div>
                        <div class="bottom">
                            <p v-if="item.subtitle" class="subtitle">
                                {{ item.subtitle }}
                            </p>
                            <p v-if="item.text" class="text">
                                {{ item.text }}
                            </p>
                            <div v-if="item.buttons?.length" class="actions">
                                <a-button
                                    v-for="(button, i) in item.buttons"
                                    :key="`cta-three-columns-buttons_${i}`"
                                    class="button"
                                    v-bind="button"
                                    :to="button.link"
                                    :text="button.label"
                                    :type="button.type || 'action'"
                                    :glyph="button.glyph || 'arrow'"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    components: {
        ASliceHeader,
        AButton,
        APicture,
    },
    mixins: [contentMixin, styleMixin],
    props: {
        title: {
            type: String,
            default: undefined,
        },

        lead: {
            type: String,
            default: undefined,
        },

        bodyText: {
            type: String,
            default: undefined,
        },

        cardsHeader: {
            type: String,
            required: true,
        },

        cards: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-cta-three-column {
    @mixin basic-slice-paddings;
    position: relative;
    color: var(--av-nav-primary);

    &:before {
        content: '';
        width: 100%;
        height: 70%;
        top: 0;
        position: absolute;
        background-color: var(--av-solid-brand-lightest);
        z-index: -1;
    }
    &:deep(.a-slice-header) {
        @mixin colls 12;
        margin: 0 auto;
        text-align: center;
        @media (--viewport-tablet) {
            @mixin colls 10;
        }
        @media (--viewport-desktop) {
            @mixin colls 8;
        }
        &__lead {
            @mixin paragraph;
            @media (--viewport-tablet) {
                margin-top: 32px;
            }
        }
    }
    .cards-wrapper {
        .main-title {
            @mixin colls 12;
            text-align: center;
            @mixin display;
            padding-top: 72px;
            margin-bottom: 40px;
            @media (--viewport-tablet) {
                padding-top: 48px;
                margin-bottom: 24px;
            }
            @media (--viewport-desktop) {
                padding-top: 72px;
            }
        }
        .cards {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            .card {
                @mixin colls 12;
                display: flex;
                flex-flow: column wrap;
                padding: 40px 24px 24px;
                background: var(--av-inversed-primary);
                border: 1px solid var(--av-brand-light);
                border-radius: 4px;
                box-shadow: var(--av-shadow-regular);
                text-align: center;
                margin-bottom: 16px;
                min-height: 352px;
                &:last-child {
                    margin: 0;
                }
                @media (--viewport-tablet) {
                    padding: 40px 56px;
                    min-height: unset;
                    flex-flow: row wrap;
                    gap: 80px;
                }
                @media (--viewport-desktop) {
                    flex-flow: column wrap;
                    padding: 40px 24px 24px;
                    margin-bottom: 0;
                    @mixin colls 4;
                    gap: unset;
                    min-height: 524px;
                }
                @media (--viewport-desktop-wide) {
                    padding: 40px 40px 24px;
                }
                @media (--viewport-desktop-large) {
                    padding: 32px 40px 24px;
                }
                .title {
                    @mixin lead;
                    font-weight: 600;
                    @media (--viewport-desktop) {
                        margin-bottom: 40px;
                    }
                }
                .image {
                    display: none;
                    @media (--viewport-tablet) {
                        max-width: 190px;
                        display: block;
                    }
                    @media (--viewport-desktop) {
                        margin: 0 auto;
                    }
                }
                .subtitle {
                    @mixin paragraph;
                    font-weight: 600;
                    margin-bottom: 8px;
                }
                .text {
                    @mixin body;
                }
                .actions {
                    margin-top: auto;
                    &:deep(.button) {
                        @mixin colls 12;
                    }
                }
                .top {
                    margin-bottom: 24px;
                    @media (--viewport-tablet) {
                        text-align: start;
                        max-width: 225px;
                        .title {
                            @mixin title;
                        }
                    }
                    @media (--viewport-desktop) {
                        @mixin colls 12;
                        margin-bottom: 36px;
                        max-width: unset;
                        text-align: center;
                        .title {
                            @mixin paragraph;
                        }
                    }
                }
                .bottom {
                    flex-grow: 1;
                    display: flex;
                    flex-flow: column wrap;
                    @media (--viewport-tablet) {
                        text-align: start;
                        max-width: 263px;
                    }
                    @media (--viewport-desktop) {
                        @mixin colls 12;
                        max-width: unset;
                        text-align: center;
                    }
                }

            }
        }
    }
}
</style>
