<template>
    <div class="root">
        <div class="solutions-container container">
            <div class="logo-container">
                <a-dynamic-link
                    class="product"
                    native-type="a"
                    :to="productLink"
                    :event="{ action: productTitle }"
                >
                    <div class="title">
                        {{ productTitle }}
                    </div>
                    <div class="subtitle">
                        {{ ctaTitleSticky }}
                    </div>
                </a-dynamic-link>
            </div>

            <div class="buttons">
                <a-button
                    v-for="button in ctaButtons"
                    :key="button.link"
                    class="button"
                    v-bind="button"
                    :to="button.link"
                    :event="{ category: 'Button click', action: button.text, label: 'top menu' }"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADynamicLink from '@core/components/dynamic-link/dynamic-link.vue';

export default {
    name: 'SolutionsHeaderSticky',

    components: {
        AButton,
        ADynamicLink,
    },

    props: {
        productTitle: {
            type: String,
            required: true,
        },
        productLink: {
            type: String,
            required: true,
        },
        ctaTitleSticky: {
            type: String,
            default: '',
        },
        ctaButtons: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="postcss" scoped>
.root {
    position: fixed;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    z-index: 3;
    height: 64px;
    padding-top: 12px;
    box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
    border-top: 0;
    background: var(--av-nav-primary);
    border-bottom: 1px solid rgba(0, 101, 227, 0.4);

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .product {
        color: var(--av-inversed-primary);
        text-decoration: none;
        height: 38px;
        display: flex;
        align-items: center;
        padding-top: 2px;

        @media (--viewport-tablet) {
            align-items: baseline;
        }
    }

    .title {
        @mixin title;
        margin-inline-end: 8px;

        @media (--viewport-mobile-wide) {
            @mixin lead;
        }

        @media (--viewport-tablet) {
            @mixin title;
        }
    }

    .subtitle {
        @mixin paragraph;

        display: none;

        @media (--viewport-desktop) {
            display: inline;
        }
    }

    .button {
        padding: 7px 15px;
    }

    .buttons {
        display: none;
        @media (--viewport-mobile-wide) {
            display: block;
        }

        .button:not(:last-child) {
            margin-inline-end: 16px;
        }
    }
}
</style>
