<template>
    <div class="root">
        <page-not-found :footer="footer" v-bind="template" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import PageNotFound from '@core/slices/academy/page-not-found/page-not-found.vue';
import '@core/styles/sections/pages.pcss';
import '@core/styles/sections/solutions.pcss';

export default {
    name: 'Error404Component',

    components: {
        PageNotFound,
    },

    computed: {
        ...mapState({
            preset: (state) => state.slices.items['s-academy-acronis-com'] || {},
        }),
        template() {
            return this.preset?.template?.['page-not-found'];
        },
        footer() {
            const fromDefaults = this.preset.defaults?.footer;
            const fromTemplate = this.template?.footer;
            return { ...fromDefaults, ...fromTemplate };
        },
    },
};
</script>
