<template>
    <section class="collapse-section" :class="id">
        <div class="head" @click="toggleOpen()">
            <a-glyph :name="isOpen ? 'arrow-up' : 'arrow-down'" class="icon" />
            <span class="title">{{ section.title }}</span>
        </div>
        <div v-show="isOpen" v-if="$slots.additional" class="content-wrapper additional">
            <slot name="additional" />
        </div>
        <div v-show="isOpen" v-else class="content-wrapper">
            <div class="content">
                <p v-if="section.lead" class="lead" v-html="section.lead" />
                <a-link
                    v-if="section.link"
                    v-bind="section.link"
                    :event="setEvent(section.link)"
                    class="link"
                    :size="section.link.size || 'body'"
                    :target="section.link.target || '_blank'"
                    accent
                />
            </div>
            <div v-if="section.features" class="features">
                <ul v-if="section.features.list">
                    <li
                        v-for="(feature, index) in section.features.list"
                        :key="`section_${id}_features_${index}`"
                        class="feature"
                    >
                        {{ feature }}
                    </li>
                </ul>
            </div>
            <div class="options">
                <div v-if="$slots.numberOptions" class="number-options">
                    <slot name="numberOptions" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';

export default {
    components: {
        ALink,
        AGlyph,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        section: {
            type: Object,
            required: true,
        },
    },
    emits: ['collapseToggled'],
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        toggleOpen() {
            this.isOpen = !this.isOpen;
            this.$emit('collapseToggled', this.isOpen);
        },
        setEvent(item) {
            return item ? { category: 'Interactions', action: 'link', label: item.to } : {};
        },
    },
};
</script>

<style lang="postcss" scoped>
section.collapse-section {
    border-top: 1px solid var(--av-brand-light);
    &.additional {
        border-bottom: 1px solid var(--av-brand-light);
    }
    .head {
        padding: 24px 0;
        display: flex;
        align-items: center;
        gap: 16px;
        cursor: pointer;
        user-select: none;
        .title {
            @mixin paragraph-accent;
        }
    }
    .content-wrapper {
        padding-bottom: 48px;
        padding-inline-start: 32px;
        display: flex;
        flex-flow: row wrap;
        @media (--viewport-mobile-wide) {
            padding-bottom: 24px;
        }
        @media (--viewport-desktop) {
            padding-bottom: 48px;
        }
        &.additional {
            @media (--viewport-desktop-large) {
                padding-top: 26px;
            }
        }

        .content {
            width: 100%;
            @media (--viewport-desktop-wide) {
                width: 50%;
            }
            .lead {
                @mixin body;
                color: var(--av-fixed-secondary);
                margin-bottom: 24px;
                @media (--viewport-desktop-wide) {
                    margin-bottom: 16px;
                }
            }
        }
        .features {
            display: none;
            @media (--viewport-desktop-wide) {
                display: block;
                width: 40%;
                margin-inline-start: auto;
            }
            ul {
                display: none;
                margin-bottom: 16px;
                list-style-type: disc;
                list-style-position: outside;
                padding-inline-start: 16px;
                @media (--viewport-desktop) {
                    display: block;
                }
                li {
                    @mixin caption;
                    margin-bottom: 8px;
                    &:last-of-type {
                        margin: 0;
                    }
                }
            }
        }
        .options {
            .number-options {
                display: flex;
                flex-flow: row wrap;
                gap: 24px;
                margin-top: 40px;
                @media (--viewport-desktop-wide) {
                    gap: 16px;
                }
            }
        }
    }
}
</style>
