<template>
    <s-basic-slice v-if="isAppropriateLocale && baseWebinar" class="s-events" v-bind="$attrs">
        <div>
            <a-link
                v-if="link"
                class="s-events__link bottom-link"
                v-bind="link"
                :type="link.type || 'direct'"
                :glyph="link.glyph || 'arrow'"
                :size="link.size || 'title'"
            />
            <template v-if="isSliderUsed">
                <div class="s-events-item">
                    <a-event-card class="s-events__card" v-bind="baseWebinar" :loading="!isDataFetched" />
                </div>
                <a-slider :options="sliderOptions" :data-slides="miniWebinars.length">
                    <a-slide v-for="(item, index) in miniWebinars" :key="index">
                        <a-event-card class="s-events__card" v-bind="item" />
                    </a-slide>
                </a-slider>
            </template>
            <div v-else-if="webinars?.length">
                <a-event-card class="s-events__card" v-bind="baseWebinar" :loading="!isDataFetched" />
                <div class="s-events__mini-cards">
                    <a-event-card
                        v-for="(item, index) in miniWebinars"
                        :key="index"
                        class="s-events__card"
                        v-bind="item"
                        :speakers-text="item.speakersText || speakersText"
                    />
                </div>
            </div>
            <div v-else-if="noContentState" class="s-events__no-content-state">
                <a-dangerous-html :content="noContentState" />
            </div>
        </div>
    </s-basic-slice>
</template>

<script>
import { ProductAPIQueryBuilder } from '@api/builders/product';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AEventCard from '@core/components/event-card/event-card.vue';
import ALink from '@core/components/link/link.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import { LOCALE_DEFAULT } from '@model/const/locales';
import eventUtils from '@utils/events.js';

const MAX_SPEAKERS_COUNT = 2;
const MAX_EVENTS_ON_PAGE = 5;
const MAX_SYMBOLS_DESC = 200;

const AUDIENCE_MAP = {
    Cloud: [1, 3, 5, 6, 7],
    Corporate: [2],
    Consumer: [4],
};
const ALL_AUDIENCES = [1, 2, 3, 4, 5, 6, 7];

const LOCALE_LOCATIONS_MAP = {
    'en-us': 238,
    'en-eu': 21,
    'en-gb': 237,
    'en-sg': 199,
    'de-de': 85,
    'it-it': 112,
    'fr-fr': 78,
    'es-es': 209,
    'es-mx': 143,
    'ja-jp': 115,
};

const MIN_ITEMS_FOR_SLIDE = 3;

export default {
    name: 'SEvents',

    components: {
        ADangerousHtml,
        ALink,
        ASlide,
        ASlider,
        AEventCard,
        SBasicSlice,
    },

    mixins: [breakpoint],

    props: {
        isAutomated: {
            type: Boolean,
            default: true,
        },

        eventCategory: {
            type: Number,
            default: undefined,
        },

        webinar: {
            type: Array,
            default: () => ([]),
        },

        link: {
            type: Object,
            default: undefined,
        },

        noContentState: {
            type: String,
            default: '',
        },

        dataFromEventId: {
            type: Number,
            default: undefined,
        },

        highlightedId: {
            type: Number,
            default: undefined,
        },

        itemsLimit: {
            type: Number,
            default: MAX_EVENTS_ON_PAGE,
        },

        speakersText: {
            type: String,
            default: 'Speakers',
        },

        onDemandText: {
            type: String,
            default: 'Webinar on demand',
        },

        fallbackButtonText: {
            type: String,
            default: 'Book a spot',
        },

        fallbackButtonPastText: {
            type: String,
            default: 'Watch now',
        },

        accentCard: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        isDataFetched: false,
        isAppropriateLocale: true,
    }),

    computed: {
        sliderOptions() {
            const showPagination = this.miniWebinars.length > MIN_ITEMS_FOR_SLIDE;
            return {
                showNavigation: showPagination,
                loop: showPagination,
                showCounter: false,
                slideName: 'webinar',
                slidesPerView: 'auto',
            };
        },

        locale() {
            return this.$route.params.locale;
        },

        pageAudience() {
            return this.$store.state.pages?.page?.audiences || 'Generic';
        },

        pageProductId() {
            return this.$store.state.pages?.page?.product_id;
        },

        mainSiteEvents() {
            return this.$store.state.events.mainSiteEvents.items;
        },

        highlightedEvent() {
            if (!this.highlightedId) return null;
            return this.$store.state.events.highlightedEvent.items?.[0];
        },

        webinarList() {
            const useMainSiteEvents = this.mainSiteEvents?.length;
            let list = useMainSiteEvents ? this.mainSiteEvents : this.webinar;

            if (this.highlightedEvent) {
                const eventId = this.highlightedEvent.id;
                if (list.find((e) => e.id === eventId)) {
                    list = list.sort((a, b) => {
                        if (a.id === eventId) return -1;
                        if (b.id === eventId) return 1;
                        return 0;
                    });
                } else {
                    list.unshift(this.highlightedEvent);
                }
            } else if (!useMainSiteEvents) {
                return list;
            }

            const res = list
                .map((data) => {
                    if (!data.dataFromId && !data.id) return data;
                    const eventData = data.id ? data : this.mainSiteEvents.find((i) => i.id === data.dataFromId);
                    if (!eventData) return false;

                    const occurrence = this.actualOccurrence(eventData);
                    let beginsAt = eventData.dynamic_next_occurrence || eventData.dynamic_last_occurrence;
                    let duration = '';
                    let date;

                    if (occurrence) beginsAt = occurrence.begins_at;

                    if (beginsAt) {
                        const startDate = eventUtils.convertDate(
                            beginsAt,
                            this.eventType(eventData),
                            eventData.timezone,
                            this.locale,
                            'MMMM d, yyyy, h:mm a',
                        );
                        date = `${startDate.formatted} (GMT${startDate.timezoneOffset})`;
                        duration = '';

                        if (eventData.is_past || eventData.is_on_demand) {
                            date = this.onDemandText;
                        } else if (occurrence) {
                            duration = eventUtils.calculateDurationToString(
                                occurrence.begins_at,
                                occurrence.ends_at,
                            );
                        } else if (eventData.occurrences?.[0]) {
                            duration = eventUtils.calculateDurationToString(
                                eventData.occurrences[0].begins_at,
                                eventData.occurrences[0].ends_at,
                            );
                        }
                    } else {
                        date = this.onDemandText;
                    }

                    const speakers = (eventData.speakers || []).map((s) => ({
                        name: s.full_name,
                        position: s.position,
                    }));

                    return {
                        id: eventData.id,
                        date,
                        beginsAt: new Date(beginsAt).getTime(),
                        isUpcoming: !eventData.is_past && !eventData.is_on_demand,
                        title: eventData.seo_title || '',
                        desc: eventData.seo_description || '',
                        image: eventData.cover?.image_id || '',
                        imageAlt: eventData.name || '',
                        lang: eventData.language?.name || '',
                        link: {
                            to: eventData.slug ? `https://acronis.events/event/${eventData.slug}/` : '#',
                            text: this.getButtonText(eventData),
                        },
                        speakers,
                        speakersText: eventData.section_speakers_title || this.speakersText || 'Speakers',
                        timezone: eventData.timezone,
                        duration,
                    };
                })
                .filter(Boolean);

            return res.slice(0, this.itemsLimit);
        },

        webinars() {
            return this.webinarList.map((el, i) => {
                const speakers = (el.speakers || []).slice(0, i === 0 ? MAX_SPEAKERS_COUNT : 0);
                const largeText = el.desc && el.desc.length > MAX_SYMBOLS_DESC;
                const parsedText = el.desc && `${el.desc.slice(0, MAX_SYMBOLS_DESC)} ...`;
                const isUpcoming = !!el.isUpcoming;

                return {
                    ...el,
                    // This is a compatibility hack for old pages until admin JSONs get updated
                    link: {
                        to: el.link?.to || el.link,
                        text: el.link?.text || el.linkText || (isUpcoming ? this.fallbackButtonText : this.fallbackButtonPastText),
                    },
                    cardShadow: this.highlightedId ? el.id === this.highlightedId : i === 0,
                    speakers,
                    desc: el.desc && largeText ? parsedText : el.desc,
                    language: el.lang,
                    hasEnded: !isUpcoming,
                };
            });
        },

        isSliderUsed() {
            return (this.miniWebinars.length === 2 && !this.isDesktop) || this.miniWebinars.length > 2;
        },

        baseWebinar() {
            let result = this.webinars[0];

            if (this.highlightedEvent) {
                const event = this.webinars.find((webinar) => webinar.id === this.highlightedEvent.id);
                result = event || result;
            }

            return {
                ...result,
                cardShadow: true,
                accentCard: this.accentCard,
            };
        },

        miniWebinars() {
            return this.webinars
                .filter((webinar) => webinar.id !== this.baseWebinar.id)
                .map((webinar) => ({
                    ...webinar,
                    miniCard: true,
                }));
        },
    },

    serverPrefetch() {
        this.$store.dispatch('slices/getSyncedData', { slice: 'events-form', locale: LOCALE_DEFAULT });
    },

    async created() {
        const builder = new ProductAPIQueryBuilder('mainSiteEvents')
            .setEntityPath('/api/events/events/');

        if (this.isAutomated) {
            const targetLocation = LOCALE_LOCATIONS_MAP[this.locale];
            const hasParams = {};

            if (!this.$root?.STORYBOOK_VALUES && !targetLocation) {
                this.isAppropriateLocale = false;
                return;
            }

            builder
                .addMatchesAll('is_for_main_website', '=', '1')
                .setCustomParam('is-published-and-active', 1)
                .setCustomParam('nextoccurrence', 1)
                .setCustomParam('lastoccurrence', 1)
                .addSort('is_past', 'asc')
                .addSort('is_on_demand', 'asc')
                .addSort('dynamic_next_occurrence', 'asc')
                .addSort('dynamic_last_occurrence', 'desc')
                .setPaginate(1, this.itemsLimit);

            hasParams.countries = [targetLocation];

            if (this.pageProductId) {
                hasParams.products = [this.pageProductId];
            }
            if (this.eventCategory) {
                hasParams.categories = [this.eventCategory];
            }

            hasParams.audiences = AUDIENCE_MAP[this.pageAudience] || ALL_AUDIENCES;

            builder.setCustomParam('has', hasParams);
            builder.setCustomParam('process-macros', '1');

            if (this.highlightedId) {
                const highlightRequest = new ProductAPIQueryBuilder('highlightedEvent')
                    .setEntityPath('/api/events/events/')
                    .addMatchesAll('id', '=', `${this.highlightedId}`)
                    .setCustomParam('process-macros', '1')
                    .toObject();
                await this.$store.dispatch('events/getEntity', { request: highlightRequest });
            }
        } else if (this.dataFromEventId) {
            builder.addMatchesAll('id', '=', `${this.dataFromEventId}`);
        } else {
            const dataFromList = this.webinar.map((w) => w.dataFromId).filter(Boolean);
            builder.addMatchesAll('id', 'in', dataFromList);
        }

        const request = builder.toObject();
        await this.$store.dispatch('events/getEntity', { request });
        this.isDataFetched = true;
    },

    methods: {
        actualOccurrence(event) {
            return eventUtils.getActualOccurrence(event);
        },

        eventType(event) {
            return eventUtils.getEventType(event);
        },

        getButtonText(event) {
            const isUpcoming = !event.is_past && !event.is_on_demand;
            if (event.cta_button_text) return event.cta_button_text;
            return isUpcoming ? this.fallbackButtonText : this.fallbackButtonPastText;
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-events {
    &__link {
        @media (--viewport-desktop) {
            position: absolute;
            margin-top: 0;
            top: 10px;
            inset-inline-end: 32px;
        }
        @media (--viewport-desktop-wide) {
            inset-inline-end: 64px;
        }
        @media (--viewport-desktop-large) {
            inset-inline-end: 0;
        }
    }

    &__mini-cards {
        display: flex;
        justify-content: flex-start;

        @media (--viewport-desktop-wide) {
            width: 85%;
        }

        .s-events__card {
            @media (--viewport-desktop-wide) {
                width: 100%;
            }
        }
    }

    &:deep(.a-slice-header) {
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            margin-bottom: 40px;
        }
    }

    &:deep(.a-slider) {
        &__counter {
            &-total,
            &-text,
            &-current {
                color: var(--av-fixed-light);
                font-size: 18px;
            }
        }

        /* Swiper.js has a bug with looped slides when there are only 2 slides
         * when the same slide is visible 2 times, "next" button doesnt generate additional looped slides
         * Solution: only when there are 2 slides, give them each 50% width on desktop+ */

        &[data-slides='2'] {
            .a-slide {
                @media (--viewport-desktop) {
                    width: 50%;
                }
            }
        }
    }

    &:deep(.a-slide) {
        position: relative;
        inset-inline-start: 0;
        opacity: 0.3;
        width: 85%;

        @media (--viewport-tablet) {
            width: 60%;
        }

        @media (--viewport-desktop) {
            width: 40%;

            &_active,
            &_next {
                opacity: 1;
            }
        }

        .a-slider_off & {
            width: 100%;
        }

        &_active {
            opacity: 1;
        }
    }

    &__no-content-state {
        display: flex;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        padding: 32px;
        border-radius: 16px;
        border: 1px solid var(--av-brand-light);
        background: var(--av-inversed-primary);
        box-shadow: 0 4px 8px 0 rgba(36, 49, 67, .1);
    }
}
</style>
