<template>
    <div class="integration-features">
        <div class="features a-container">
            <div v-for="feature in slides" :key="feature.title" class="feature">
                <div class="images">
                    <img
                        v-for="image in feature.logo_icons"
                        :key="image"
                        class="image"
                        :src="image"
                    />
                </div>
                <div class="text">
                    <div class="title">
                        {{ feature.title }}
                    </div>
                    <div class="subtitle">
                        {{ feature.subtitle }}
                    </div>
                </div>
                <div class="buttons">
                    <a-button
                        v-if="feature.secondary_action_link"
                        type="main"
                        :text="feature.secondary_action_link.label"
                        :to="feature.secondary_action_link.url"
                        :target="feature.secondary_action_link.target"
                        size="s"
                    />
                    <a-button
                        v-if="feature.main_action_link"
                        type="action"
                        :text="feature.main_action_link.label"
                        :to="feature.main_action_link.url"
                        :target="feature.main_action_link.target"
                        size="s"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';

export default {
    name: 'SIntegrationFeatures',

    components: {
        AButton,
    },

    props: {
        slides: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.integration-features {
    padding-block: 24px 16px;
}

.features {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.feature {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    border-radius: 8px;
    padding: 16px;
    gap: 16px;
    border: 1px solid var(--av-brand-light);
    background: var(--av-inversed-primary);
    box-shadow: 0 4px 8px 0 var(--av-fixed-invisible);

    @media (--viewport-tablet) {
        flex-direction: row;
        gap: 24px;
    }
}

.images {
    flex-shrink: 0;
    display: flex;
    gap: 8px;
    padding-top: 4px;
}

.image {
    height: 48px;
    width: 48px;
    max-width: 200px;
    object-fit: contain;
    object-position: center;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--av-brand-light);
}

.title {
    @mixin lead-accent;
    margin-bottom: 8px;
}

.subtitle {
    @mixin body;
}

.buttons {
    padding-top: 4px;
    flex-shrink: 0;

    @media (--viewport-tablet) {
        margin-inline-start: auto;
    }
}

.a-button {
    &:not(:last-of-type) {
        margin-inline-end: 8px;
    }
}
</style>
