<template>
    <div class="resource-news-list">
        <div class="container">
            <div class="list">
                <h2 class="title">
                    {{ commonTranslations.homePageNewsTitle }}
                </h2>
                <div v-for="item in list" :key="item.id" class="list-item">
                    <div class="publish-date">
                        {{ item.published_at }}
                    </div>
                    <a-link v-bind="item" :to="item.pathname" :text="item.title" />
                </div>
            </div>
            <div v-if="banner" class="hero-banner">
                <a-picture is-background :link="banner.image_id" fit="cover" />
                <div class="wrapper">
                    <div class="asset-type">
                        {{ banner.resource_type }}
                    </div>
                    <div class="texts-wrapper">
                        <div class="banner-title">
                            {{ banner.title }}
                        </div>
                        <p v-if="banner.description" class="banner-description" v-html="banner.description" />
                    </div>
                    <a-button
                        class="explore-button"
                        :to="banner.cta_url"
                        type="action"
                        :text="banner.cta_label"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'ResourceNewsList',

    components: {
        AButton,
        ALink,
        APicture,
    },

    props: {
        list: {
            type: Array,
            required: true,
        },
        commonTranslations: {
            type: Object,
            required: true,
        },
        banner: {
            type: Object,
            default: undefined,
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-news-list {
    width: 100%;

    .container {
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        padding: 40px 16px 72px;

        @media (--viewport-tablet) {
            padding: 40px 32px 64px;
        }

        @media (--viewport-desktop) {
            flex-direction: row;
            align-items: flex-start;
            padding: 64px 32px;
        }

        @media (--viewport-desktop-wide) {
            padding: 64px;
        }
    }
}

.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;

    @media (--viewport-tablet) {
        align-items: flex-start;
        display: grid;
        grid-template-areas:
            "a a"
            "b c"
            "d e";
    }

    @media (--viewport-desktop) {
        display: flex;
        gap: 14px;
    }
}

.title {
    @mixin lead-accent;
    color: var(--av-nav-primary);
    text-align: center;

    @media (--viewport-tablet) {
        grid-area: a;
    }

    @media (--viewport-desktop) {
        text-align: start;
    }

    @media (--viewport-desktop-wide) {
        @mixin nav-title-accent;
    }
}

.list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (--viewport-tablet) {
        text-align: start;
        align-items: flex-start;

        &:nth-child(2) {
            grid-area: b;
        }

        &:nth-child(3) {
            grid-area: c;
        }

        &:nth-child(4) {
            grid-area: d;
        }

        &:nth-child(5) {
            grid-area: e;
        }
    }

    &:deep(.a-link__content) {
        @mixin paragraph;
        font-weight: 500;
    }
}

.publish-date {
    font-size: 12px;
    line-height: 16px;
    color: var(--av-fixed-primary);
}

.hero-banner {
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    background: #060336;
    @mixin colls 12;
    flex-shrink: 0;

    @media (--viewport-desktop) {
        @mixin colls 8;
    }
}
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 24px;
    flex-shrink: 0;
    gap: 24px;
    width: 100%;
    height: 535px;
    @media (--viewport-mobile-wide) {
        height: 343px;
    }
    @media (--viewport-tablet) {
        align-items: flex-start;
    }
    @media (--viewport-desktop) {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        max-width: initial;
    }
    .asset-type {
        position: absolute;
        top: 24px;
        inset-inline-start: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        background: var(--av-nav-secondary);
        border-radius: 4px;
        font-weight: 700;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: var(--av-inversed-primary);
    }

    .texts-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        @media (--viewport-tablet) {
            max-width: 428px;
            text-align: start;
            justify-content: flex-start;
            align-items: flex-start;
        }
        @media (--viewport-desktop) {
            max-width: 428px;
        }
        @media (--viewport-desktop-wide) {
            max-width: 544px;
        }
        @media (--viewport-desktop) {
            max-width: 428px;
        }
        @media (--viewport-desktop-wide) {
            max-width: 544px;
        }
    }

    .banner-title {
        @mixin display;
        margin-bottom: 8px;
        font-weight: 500;
        text-align: center;
        color: var(--av-inversed-primary);
        @media (--viewport-tablet) {
            max-width: 100%;
            text-align: start;
        }
    }

    .banner-description {
        @mixin paragraph;
        color: var(--av-inversed-primary);
    }

    .explore-button {
        width: 100%;
        height: 40px;
        font-weight: 700;
        font-size: 16px;
        line-height: 14px;

        @media (--viewport-tablet) {
            width: 136px;
        }
    }
}
</style>
