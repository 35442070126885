<template>
    <div v-if="content && !content.isEbook" class="s-info-partner-registration">
        <div class="s-info-partner-registration__logo">
            <a class="a-link" :href="replaceLocale(content.productLink)">
                <a-logo type="full" />
            </a>
        </div>
        <component :is="content.titleTag || 'div'" v-if="content.title" class="s-info-partner-registration__title">
            {{ content.title }}
        </component>
        <div v-if="content.subtitle" class="s-info-partner-registration__subtitle">
            {{ content.subtitle }}
        </div>
        <div v-if="content.description" class="s-info-partner-registration__description">
            <div v-for="(item, i) in content.description" :key="i" class="s-info-partner-registration__description-item">
                {{ item }}
            </div>
        </div>
        <div v-if="content.list" class="s-info-partner-registration__list">
            <div v-if="content.list.title" class="s-info-partner-registration__list-bullet-title" v-html="content.list.title" />
            <div v-if="content.list.description" class="s-info-partner-registration__list-bullet-description">
                <div
                    v-for="(item, i) in content.list.description"
                    :key="i"
                    class="s-info-partner-registration__list-bullet-description-item"
                >
                    {{ item }}
                </div>
            </div>
            <ul class="s-info-partner-registration__list-bullet">
                <li v-for="(item, i) in content.list.items" :key="i" class="s-info-partner-registration__list-bullet-item">
                    {{ item }}
                </li>
            </ul>
        </div>
        <div v-if="content.support" class="s-info-partner-registration__list">
            <ul v-if="content.support.items" class="s-info-partner-registration__list-support">
                <li v-for="(item, i) in content.support.items" :key="i" class="s-info-partner-registration__list-support-item">
                    <a-link
                        v-if="item.link"
                        class="a-link s-info-partner-registration__support-link"
                        v-bind="item"
                        :to="replaceLocale(item.link)"
                        :target="item.target || '_blank'"
                        :text="item.title"
                        :size="item.size || 'paragraph'"
                    />
                    <a-link
                        v-if="item.number"
                        class="a-link"
                        :text="item.number"
                        :to="phoneNumber(item.number)"
                        size="paragraph"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';
import form from '@core/mixins/form.js';

export default {
    name: 'SInfoPartnerRegistration',

    components: {
        ALink,
        ALogo,
    },

    mixins: [form],

    props: {
        content: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-info-partner-registration {
    flex: 1;
    text-align: center;

    &__logo {
        margin-bottom: 24px;
    }

    &__title {
        @mixin display;
        color: var(--av-nav-primary);
    }

    &__subtitle {
        @mixin title;
        display: none;
        margin: 24px 0 0;

        @media (--viewport-tablet) {
            display: block;
        }
    }

    &__description {
        @mixin paragraph;
        color: var(--av-fixed-secondary);
        margin: 40px 0 0;
    }

    &__list {
        @mixin paragraph;
        display: none;
        text-align: start;
        margin: 16px 0 0;
        list-style-type: none;
    }

    &__list-bullet {
        &-title {
            @mixin lead;
            font-weight: 600;
            margin: 16px 0 32px;
        }

        &-item {
            margin: 16px 0 0;
            padding-inline-start: 16px;
            position: relative;

            &::before {
                inset-inline-start: 0;
                top: 10px;
                content: '';
                width: 4px;
                height: 4px;
                display: block;
                border-radius: 2px;
                position: absolute;
                background: var(--av-fixed-secondary);
            }
        }
    }

    &__list-support {
        padding-top: 24px;
        position: relative;

        &::before {
            position: absolute;
            content: '';
            display: block;
            top: 0;
            inset-inline-start: 0;
            height: 1px;
            width: 100%;
            max-width: 276px;
            background: var(--av-brand-light);
        }
    }

    &__support-link {
        margin-bottom: 8px;
    }
}

@media (--viewport-mobile-wide) {
    .s-info-partner-registration {
        &__product-image {
            &:deep(.a-picture__img) {
                max-width: 386px;
            }
        }
    }
}

@media (--viewport-tablet) {
    .s-info-partner-registration {
        &__description {
            margin: 24px 0 0;
        }

        &__list {
            display: block;
        }
    }
}

@media (--viewport-desktop) {
    .s-info-partner-registration {
        width: 100%;
        text-align: start;

        &__title {
            @mixin hero;
        }

        &__list {
            padding-inline-end: 64px;
        }
    }
}

[dir='rtl'] {
    .s-info-partner-registration {
        .form-error__icon {
            margin-right: 0;
            margin-inline-end: 8px;
        }
        &:deep(.el-checkbox__label) {
            padding-inline-start: 8px;
        }
    }
}
</style>
