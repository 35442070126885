<template>
    <section v-if="isVisible" v-show="isAvailable" class="main-page-academy">
        <div class="a-container">
            <div class="wrapper">
                <div class="content">
                    <s-main-page-tag v-bind="tag({name: 'title'})" />
                    <s-main-page-tag v-bind="tag({name: 'description', isDangerous: true})" />
                    <s-main-page-cta :content="content.cta" />
                </div>
                <a-picture v-if="content.picture" v-bind="picture" />
            </div>
        </div>
    </section>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';
import breakpoints from '@core/mixins/breakpoint';
import SMainPageCta from '../components/cta.vue';
import mixin from '../components/mixin.js';
import SMainPageTag from '../components/tag.vue';

export default {
    name: 'SMainPageAcademy',
    components: {
        APicture,
        SMainPageCta,
        SMainPageTag,
    },
    mixins: [breakpoints, mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isVisible() {
            return this.content?.title || this.content?.description;
        },
        picture() {
            return {
                position: ['desktopWide', 'desktopLarge'].includes(this.currentBreakpoint) ? 'left' : 'top',
                ...this.content.picture,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-academy {
    margin-top: 48px;
    .wrapper {
        display: flex;
        overflow: hidden;
        border-radius: 8px;
        flex-direction: column-reverse;
        background: var(--av-inversed-primary);
        border: 1px solid var(--av-brand-secondary-light);
        @media (--viewport-desktop) {
            margin: 0 auto;
            @mixin colls 10;
        }
        @media (--viewport-desktop-wide) {
            width: 100%;
            flex-direction: row;
        }
    }
    .content {
        padding: 16px;
        text-align: center;
        position: relative;
        @media (--viewport-tablet) {
            padding: 32px 64px;
        }
        @media (--viewport-desktop) {
            padding: 32px 80px;
        }
        @media (--viewport-desktop-wide) {
            width: 50%;
            padding: 40px;
            text-align: start;
            padding-inline-end: 0;
        }
    }
    .title {
        @mixin title-accent;
        color: var(--av-nav-primary);
        @media (--viewport-tablet) {
            @mixin display-accent;
        }
        @media (--viewport-desktop-wide) {
            @mixin hero-accent;
        }
    }
    .description {
        @mixin paragraph;
        margin-top: 24px;
        color: var(--av-fixed-light);
        @media (--viewport-tablet) {
            @mixin lead;
        }
        @media (--viewport-desktop-wide) {
            @mixin nav-title-accent;
            font-weight: 400;
        }
    }
    .main-page-cta {
        margin-top: 40px;
        @media (--viewport-desktop) {
            justify-content: center;
        }
        @media (--viewport-desktop-wide) {
            margin-top: 32px;
            justify-content: start;
        }
    }
    &:deep(.a-button) {
        width: 100%;
        @media (--viewport-desktop-wide) {
            width: auto;
            min-width: 200px;
        }
    }
    &:deep(.a-picture) {
        width: 100%;
        height: 100%;
        height: 190px;
        @media (--viewport-desktop-wide) {
            width: 50%;
            height: auto;
        }
        .a-picture__img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
