<template>
    <div class="blog-page blog-author-posts">
        <s-locale-selector-ribbon :is-shown="isLocaleSelectorShown" />
        <div class="main-content">
            <s-main-header v-bind="sliceDataHeader" />
            <blog-container class="intro">
                <blog-bcrumbs v-bind="bcrumbs" />
                <div class="author-block">
                    <div class="author-info">
                        <a-picture
                            v-if="author.photo"
                            :link="author.photo"
                            :alt="author.name"
                        />
                        <div>
                            <div class="author-name">
                                {{ author.name }}
                            </div>
                            <div class="author-separator" />
                            <div class="author-position">
                                {{ author.position }}
                            </div>
                            <div v-if="authorLinks?.length" class="author-links">
                                <dynamic-link
                                    v-for="link in authorLinks"
                                    :key="link.name"
                                    :to="link.to"
                                    native-type="a"
                                >
                                    <a-glyph :name="link.glyph" />
                                </dynamic-link>
                            </div>
                            <div v-if="!isMobile" class="author-description">
                                {{ author.description }}
                            </div>
                        </div>
                    </div>
                    <div v-if="isMobile" class="author-description outer-desc">
                        {{ author.description }}
                    </div>
                </div>
            </blog-container>
            <blog-container v-if="latestCards" class="latest-cards">
                <blog-title-row :title="latestStoriesText" />
                <blog-cards :cards="latestCards" show-section-label>
                    <template v-if="listTotalPages > 1" #pagination>
                        <Pagination
                            :url-template="paginationUrlTemplate"
                            :current-page="listCurrentPage"
                            :total-pages="listTotalPages"
                            :click-handler="loadPage"
                        />
                    </template>
                </blog-cards>
            </blog-container>
            <blog-container v-if="!latestCards">
                <div class="no-posts">
                    {{ translations.authorNoPosts }}
                </div>
            </blog-container>
        </div>
        <s-global-footer class="footer" v-bind="sliceDataFooter" />
    </div>
</template>

<script lang="ts">
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import Pagination from '@core/components/pagination/pagination.vue';
import APicture from '@core/components/picture/picture.vue';
import analytics from '@core/mixins/analytics.js';
import blog from '@core/mixins/blog.js';
import breakpoint from '@core/mixins/breakpoint.js';
import localeRibbon from '@core/mixins/locale-ribbon.js';
import BlogBcrumbs from '@core/slices/blog/blog-bcrumbs/blog-bcrumbs.vue';
import BlogCards from '@core/slices/blog/blog-cards/blog-cards.vue';
import BlogTitleRow from '@core/slices/blog/blog-title-row/blog-title-row.vue';
import BlogContainer from '@core/slices/blog/shared-components/blog-container/blog-container.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import SLocaleSelectorRibbon from '@core/slices/pages/locale-selector-ribbon/locale-selector-ribbon.vue';
import SMainHeader from '@core/slices/pages/main-header/main-header.vue';
import commonUtils from '@utils/common';

import '@core/styles/sections/blog.pcss';

export default {
    name: 'BlogAuthorPostsComponent',

    components: {
        AGlyph,
        DynamicLink,
        APicture,
        SGlobalFooter,
        SMainHeader,
        BlogBcrumbs,
        BlogTitleRow,
        BlogContainer,
        BlogCards,
        Pagination,
        SLocaleSelectorRibbon,
    },

    mixins: [blog, analytics, localeRibbon, breakpoint],

    props: {
        dispatchLoadPage: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            listCurrentPage: 1,
            isXHRInProgress: false,
        };
    },

    computed: {
        paginationUrlTemplate() {
            return `/$LOCALE$/blog/authors/${this.$route.params.author}/page/$PAGE_NUMBER$/`;
        },

        sliceDataHeader() {
            return {
                ...this.$store.state.slices.items['s-main-header'],
                theme: 'light',
            };
        },

        sliceDataFooter() {
            return {
                ...this.$store.state.slices.items['s-global-footer'],
                type: 'short',
                theme: 'dark',
            };
        },

        author() {
            return this.$store.state.blog.author.items[0];
        },

        bcrumbs() {
            const { translations, author } = this;

            const referrer = process.env.VUE_ENV === 'client' ? document.referrer : null;
            const cpocReferrer = referrer && referrer.includes('/cyber-protection-center');
            const contentType = cpocReferrer ? 'cyber-protection-center' : 'blog';

            const bcrumbs = [
                { to: `/${contentType}/`, title: cpocReferrer ? translations.cpcTitle : translations.homeTitle },
                { to: `/${contentType}/authors`, title: cpocReferrer ? translations.cpcExperts : translations.authorsTitle },
                { title: author.name },
            ];

            return {
                theme: 'light',
                bcrumbs,
                mobileLink: bcrumbs[0],
            };
        },

        latestStoriesText() {
            return this.translations.authorPostsTitle.replace('@value', this.author.name);
        },

        latestCards() {
            const cards = this.$store.state.blog.latestCards.items || [];
            if (!cards.length) return false;
            return cards.map((card) => this.getBlogPostFields(card, this.translations));
        },

        listTotalPages() {
            return this.$store.state.blog.latestCards.pagination?.pages_total || 1;
        },

        authorLinks() {
            const links = [];

            if (this.author.link_linked_in) {
                links.push({
                    glyph: 'i-linkedin--24',
                    to: this.author.link_linked_in,
                });
            }

            if (this.author.link_twitter) {
                links.push({
                    glyph: 'x-twitter',
                    to: this.author.link_twitter,
                });
            }

            if (this.author.link_website) {
                links.push({
                    glyph: 'i-website-ab--16',
                    to: this.author.link_website,
                });
            }

            return links;
        },
    },

    mounted() {
        this.listCurrentPage = parseInt(this.$route.params.page, 10) || 1;
    },

    methods: {
        async loadPage(pageNumber: number, callback?: () => void) {
            this.isXHRInProgress = true;
            const res = await this.dispatchLoadPage(this.author.id, pageNumber);
            this.isXHRInProgress = false;

            if (res) {
                let newPath = `/${this.locale}/blog/authors/${this.$route.params.author}/`;
                if (pageNumber > 1) newPath += `page/${pageNumber}/`;

                await this.$router.replace({ path: newPath });

                this.listCurrentPage = pageNumber;
                commonUtils.scrollTop();
                callback?.();
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-author-posts {
    .intro {
        background: var(--av-nav-primary);
        padding-bottom: 64px;
        margin-bottom: 64px;

        @media (--viewport-tablet) {
            margin-bottom: 48px;
        }

        &:deep(.blog-bcrumbs) {
            padding-top: 24px;
            padding-bottom: 40px;
            justify-content: flex-start;

            .item.link {
                color: var(--av-link-inversed);
            }

            .item:not(:last-child) .link {
                padding: 4px 8px;
                border-radius: 4px;
                color: var(--av-link-inversed);

                &:hover {
                    background: var(--el-secondary-hover);
                }

                &:active {
                    background: var(--el-secondary-active);
                }
            }

            .a-glyph {
                transform: translateY(1px) translateX(2px);
                fill: var(--av-link-inversed);
            }

            @media (--viewport-tablet) {
                .item:last-child {
                    color: var(--av-inversed-primary);
                }
            }
        }

        &:deep(.blog-title-row) {
            margin-top: 8px;
        }
    }

    .author-info {
        display: flex;
        flex-direction: column;
        align-items: center;

        .a-picture {
            width: 204px;
            height: auto;
            margin-bottom: 20px;
            border-radius: 8px;
            border: 1px solid var(--av-inversed-lightest);
            overflow: hidden;
            flex-shrink: 0;

            &:deep(.a-picture__img) {
                width: 102%;
                height: 102%;
                max-width: 102%;
                max-height: 102%;
                transform: translate(-2px, -2px);
            }
        }

        .author-name {
            @mixin display-accent;
            text-align: center;
            color: var(--av-inversed-primary);
        }

        .author-separator {
            margin: 8px auto;
            width: 100%;
            height: 2px;
            background: var(--av-gradient-info);
        }

        .author-position {
            @mixin paragraph;
            text-align: center;
            color: var(--av-inversed-primary);
        }

        .author-links {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;
            margin-top: 16px;

            &:deep(.a-glyph) {
                display: block;
                fill: var(--av-solid-brand-secondary-light);
                width: 16px;
                height: 16px;
            }
        }

        @media (--viewport-mobile-wide) {
            flex-direction: row;
            align-items: flex-start;
            gap: 16px;

            .author-name,
            .author-position {
                text-align: start;
            }

            .author-links {
                justify-content: flex-start;
            }

            .a-picture {
                margin-bottom: 0;
            }
        }

        @media (--viewport-tablet) {
            .a-picture {
                width: 224px;
            }

            .author-name {
                @mixin hero-accent;
            }
        }

        @media (--viewport-desktop) {
            gap: 32px;

            .a-picture {
                width: 212px;
            }
        }

        @media (--viewport-desktop-wide) {
            .a-picture {
                width: 260px;
            }
        }

        @media (--viewport-desktop-large) {
            .a-picture {
                width: 298px;
            }
        }
    }

    .author-description {
        margin-top: 40px;
        @mixin body;
        color: var(--av-inversed-primary);

        @media (--viewport-mobile-wide) {
            margin-top: 24px;
        }
    }

    .no-posts {
        padding-bottom: 64px;
        @mixin lead;
        color: var(--av-nav-primary);
    }

    .latest-cards {
        padding-bottom: 48px;
    }

    .pagination {
        margin-top: 64px;
    }

    .blog-title-row {
        padding-bottom: 48px;

        @media (--viewport-tablet) {
            padding-bottom: 40px;
        }

        &:deep(.title) {
            text-align: start;
            font-weight: 700;

            @media (--viewport-tablet) {
                @mixin hero-accent;
            }
        }
    }

    .blog-cards {
        &:deep(.blog-card) {
            .date {
                @mixin caption;
                color: var(--av-fixed-secondary);
            }

            .image {
                height: 176px;
                border-radius: 4px;
                overflow: hidden;

                @media (--viewport-mobile-wide) {
                    height: 256px;
                }

                @media (--viewport-tablet) {
                    height: 152px;
                }
            }

            .title {
                color: var(--av-brand-primary);

                &:hover {
                    color: var(--av-brand-secondary);
                }
            }

            .text {
                margin-top: 8px;
                color: var(--av-fixed-secondary);
            }
        }

        &:deep(.blog-list) {
            .item {
                margin-bottom: 48px;

                @media (--viewport-tablet) {
                    margin-bottom: 0;
                }
            }

            .items {
                @media (--viewport-tablet) {
                    grid-template-columns: 1fr 1fr;
                    grid-auto-rows: auto;
                }

                @media (--viewport-desktop) {
                    grid-template-columns: repeat(3, 1fr);
                }

                @media (--viewport-desktop) {
                    grid-template-columns: repeat(4, 1fr);
                }
            }
        }
    }
}
</style>
