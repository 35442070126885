<template>
    <div class="events-page-not-found-wrapper">
        <event-menu v-bind="data.menu" :show-locale-selector="false" />
        <section class="events-page-not-found">
            <div class="a-container">
                <div class="wrapper">
                    <div class="content">
                        <h1 v-if="data.title" class="title">
                            {{ data.title }}
                        </h1>
                        <p v-if="data.lead" class="lead">
                            {{ data.lead }}
                        </p>
                        <ul class="resources">
                            <li v-for="(resource, index) in data.resources" :key="index">
                                <a-link
                                    v-bind="resource.link"
                                    :size="resource.link.size || 'paragraph'"
                                    accent
                                />
                                <p>{{ resource.caption }}</p>
                            </li>
                        </ul>
                        <a-button
                            class="button"
                            v-bind="data.homepageLink"
                            :type="data.homepageLink.type || 'main'"
                        />
                    </div>
                    <div class="image">
                        <a-picture v-if="data.image" :link="data.image" />
                    </div>
                </div>
            </div>
        </section>
        <s-footer class="footer" v-bind="data.footer" />
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import EventMenu from '@core/slices/events/event-menu/event-menu.vue';
import SFooter from '@core/slices/pages/global-footer/global-footer.vue';

export default {
    name: 'EventsPageNotFound',
    components: {
        ALink,
        APicture,
        AButton,
        EventMenu,
        SFooter,
    },
    computed: {
        data() {
            return this.$store.state.slices.items['s-page-not-found-events'];
        },
    },
};
</script>

<style lang="postcss" scoped>
section.events-page-not-found {
    @mixin basic-slice-paddings;
    margin-top: 80px;
    color: var(--av-nav-primary);

    .wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        .content {
            @mixin colls 12;
            @media (--viewport-desktop) {
                @mixin colls 6;
            }
            .title {
                @mixin hero;
                font-weight: 700;
                margin-bottom: 16px;
                @media (--viewport-mobile-wide) {
                    @mixin large;
                    line-height: 64px;
                }
            }
            .lead {
                @mixin title;
                margin-bottom: 40px;
            }
            ul {
                margin-bottom: 40px;
                li {
                    @mixin body;
                    margin-bottom: 16px;
                    &:last-child {
                        margin: 0;
                    }
                    a {
                        font-weight: 700;
                    }
                }
            }
            .button {
                padding: 12px 24px;
                border-radius: 8px;
            }
        }
        .image {
            display: none;
            @mixin colls 6;
            @media (--viewport-desktop) {
                display: block;
            }
            @media (--viewport-desktop-wide) {
                @mixin colls 5;
            }
        }
    }
}
</style>
