<template>
    <div v-if="form" class="resource-gated-form" :class="`resource-gated-form--${pageType}`">
        <div class="overlay" />
        <div class="content-wrapper">
            <div v-if="isVideo" @click="closeForm()">
                <a-glyph class="a-glyph-close" name="close" fill="white" />
            </div>
            <s-form-container
                :form="form"
                :is-active-success="isActiveSuccess"
                @onClickButtonSuccess="closeForm()"
                @submit="handleSubmit()"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AGlyph from '@core/components/glyph/glyph.vue';
import SFormContainer from '@core/slices/pages/trial-form/components/form/form.vue';
import {
    STORE_SLICE_NAME,
    RC_GATED_STATE_LOCKED,
    RC_GATED_STATE_SUBMITTED,
    RC_GATED_STATE_UNLOCKED,
} from '@model/const/resource-center';

export default {
    name: 'ResourceGatedForm',

    components: {
        AGlyph,
        SFormContainer,
    },

    props: {
        pageType: {
            type: String,
            default: 'pdf',
        },
        assetTitle: {
            type: String,
            default: '',
        },
        assetUrl: {
            type: String,
            default: '#',
        },
        sfdcCampaign: {
            type: String,
            default: '',
        },
        formState: {
            type: String,
            default: RC_GATED_STATE_LOCKED,
        },
    },

    emits: ['change', 'close'],

    data: () => ({
        form: null,
        isActiveSuccess: false,
    }),

    computed: {
        ...mapState({
            preset: (state) => state.slices.items?.[STORE_SLICE_NAME] || null,
        }),

        isVideo() {
            return this.pageType === 'video';
        },

        isSubmitted() {
            return this.formState === RC_GATED_STATE_SUBMITTED;
        },
    },

    watch: {
        isSubmitted(value) {
            this.isActiveSuccess = value;
        },
    },

    mounted() {
        if (!this.preset?.form) return;

        this.form = this.preset.form;

        const translation = this.preset?.translation || {};
        const description = translation[this.isVideo ? 'videoDescription' : 'textDescription'] || '{asset}';
        this.form.success.description = description?.replace('{asset}', this.assetTitle?.toLowerCase());

        const formTitle = translation[this.isVideo ? 'videoTitle' : 'textTitle'] || '{asset}';
        this.form.registration.title = formTitle?.replace('{asset}', this.assetTitle?.toLowerCase());

        if (this.isVideo) {
            this.form.success.button.to = null;
            this.form.success.button.className = 'a-button-video';
        } else {
            this.form.success.button.to = this.assetUrl || false;
            this.form.success.button.className = 'a-button-file';
        }

        this.form.flow.marketo.sfdc_campaign_id = this.sfdcCampaign || '';
        this.form.flow.marketo.utm_medium = 'website';

        const buttonText = translation[this.isVideo ? 'videoButton' : 'textButton'] || 'Download';

        this.form.registration.button.text = buttonText;
        this.form.success.button.text = buttonText;

        if (this.form.success.button.to === false) {
            delete this.form.success.button;
        }

        const events = this.form?.flow?.sendEventsGA;
        const event = events.find((item) => item.eventAction === 'submit_form_success');

        if (event) return;
        event.eventLabel = buttonText || '';
    },

    methods: {
        closeForm() {
            if (this.isSubmitted) this.$emit('change', RC_GATED_STATE_UNLOCKED);
            else this.$emit('close');
        },

        handleSubmit() {
            this.$emit('change', RC_GATED_STATE_SUBMITTED);
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-gated-form {
    .content-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-height: 624px;
        background: var(--av-inversed-primary);
        border-top: 1px solid var(--av-brand-secondary-light);
        box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
        padding: 40px 16px 32px;
        justify-content: center;

        @media (--viewport-tablet) {
            border-radius: 4px;
            width: 458px;
            border: 1px solid var(--av-brand-secondary-light);
        }
    }

    .a-glyph-close {
        cursor: pointer;
        position: absolute;
        top: -22px;
        inset-inline-end: 12px;

        @media (--viewport-tablet) {
            top: 4px;
            inset-inline-end: -26px;
        }
    }

    &:deep(.s-form-container) {
        & {
            width: auto;
            margin: 0;
            height: 100%;

            .el-input__label,
            .el-input__editor,
            .el-input__placeholder {
                @mixin body;
            }

            .el-input__label.is-active {
                font-size: 12px;
                padding-top: 2px;
                line-height: 16px;
            }
        }

        .s-form-container__loading {
            top: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
        }

        .s-form-registration {
            padding: 0;
            border: none;
            box-shadow: none;
            max-width: 374px;
        }

        .s-form-registration__title {
            @mixin lead-accent;
            color: var(--av-nav-primary);
            text-align: center;

            @media (--viewport-tablet) {
                @mixin title-accent;
            }
        }

        .s-form-registration__checkboxes {
            color: var(--av-fixed-light);

            .el-checkbox__label {
                color: var(--av-fixed-light);
            }
        }

        .s-form-registration__error {
            font-size: 12px;
            line-height: 16px;
        }

        .s-form-registration__error i {
            display: none;
        }

        .s-form-success {
            padding: 0;
            border: none;
            box-shadow: none;
        }

        .s-form-success .image {
            width: 96px;
            height: 96px;
            margin-top: -32px;

            @media (--viewport-tablet) {
                margin-top: -80px;
            }
        }

        .s-form-success .title {
            @mixin lead-accent;
            color: var(--av-nav-primary);
            margin-top: 40px;
            text-align: center;
        }

        .s-form-success .description {
            @mixin lead;
            color: var(--av-nav-primary);
            margin-top: 40px;
            margin-bottom: 16px;
            text-align: center;
        }

        .s-form-success .a-button-video {
            padding: 12px 120px;
            width: 328px;
            height: 48px;

            @media (--viewport-mobile-wide) {
                width: 374px;
            }
        }
    }
}

.resource-gated-form--video {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: -300px;

    @media (--viewport-tablet) {
        margin-top: -200px;
    }

    .overlay {
        position: fixed;
        z-index: 2003;
        top: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        bottom: 0;
        background: var(--av-fixed-secondary);
        opacity: 0.5;
    }

    .content-wrapper {
        z-index: 2004;
        margin-top: 204px;
        border-radius: 8px;
        max-width: 468px;

        @media (--viewport-tablet) {
            max-width: none;
            width: 568px;
        }

        .a-glyph:not(.error-glyph) {
            cursor: pointer;
            position: absolute;
            top: -22px;
            inset-inline-end: 12px;

            @media (--viewport-tablet) {
                top: 4px;
                inset-inline-end: -26px;
            }
        }
    }
}
</style>

<style lang="postcss">
.el-select-dropdown-resource-center {
    z-index: 2004 !important;
}
</style>
