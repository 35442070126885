<template>
    <div class="blog-head" :class="className">
        <div class="title-wrap">
            <h1 class="title">
                {{ title }}
            </h1>
            <div v-if="lead" class="lead">
                <div class="lead-text">
                    {{ lead }}
                </div>
                <div class="line" />
            </div>
        </div>
        <dynamic-link
            v-if="sectionLink"
            class="section-link"
            :to="sectionLink"
            native-type="a"
        >
            <div class="section-link-title">
                {{ sectionTitle }}&nbsp;→
            </div>
            <div class="section-link-text">
                {{ sectionText }}
            </div>
        </dynamic-link>
    </div>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';

export default {
    name: 'BlogHead',
    components: {
        DynamicLink,
    },
    props: {
        theme: {
            type: String,
            default: 'light',
        },

        title: {
            type: String,
            required: true,
        },

        lead: {
            type: String,
            default: null,
        },

        sectionLink: {
            type: String,
            default: null,
        },

        sectionTitle: {
            type: String,
            default: null,
        },

        sectionText: {
            type: String,
            default: null,
        },
    },
    computed: {
        className() {
            return {
                dark: this.theme === 'dark',
                'small-title': Boolean(this.lead),
                'no-link': !this.sectionLink,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-head {
    padding-top: 14px;
    padding-bottom: 40px;
    color: var(--av-nav-primary);

    @media (--viewport-tablet) {
        display: grid;
        column-gap: 16px;
        padding-bottom: 32px;
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (--viewport-desktop) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &.no-link {
        display: flex;

        @media (--viewport-tablet) {
            .title-wrap {
                width: 100%;
            }

            .title {
                max-width: inherit !important;
            }
        }
    }

    .title-wrap {
        width: 100%;
        text-align: center;

        @media (--viewport-tablet) {
            text-align: start;
            grid-column-start: 1;
            grid-column-end: 3;
        }

        @media (--viewport-desktop) {
            grid-column-end: 4;
        }
    }

    .title {
        @mixin display;
        margin-top: 25px;
        text-align: center;

        @media (--viewport-tablet) {
            @mixin hero-subtle;
            text-align: start;
        }

        @media (--viewport-desktop) {
            margin-top: 16px;
        }
    }

    .lead {
        @media (--viewport-tablet) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
        }
    }

    .lead-text {
        @mixin lead;
        background: #1E82FF;
        background: linear-gradient(90deg, #94D7FF 0%, #1E82FF 38.44%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;

        @media (--viewport-tablet) {
            @mixin title;
            text-align: start;
            flex-shrink: 0;
        }

        @media (--viewport-desktop) {
            @mixin title;
        }
    }

    .line {
        display: none;

        @media (--viewport-tablet) {
            display: block;
            flex-basis: 100%;
            height: 2px;
            flex-shrink: 1;
            transform: translateY(2px);
            background: #1E82FF;
            background: linear-gradient(90deg, #94D7FF 0%, #1E82FF 38.44%);
        }
    }

    .section-link {
        border: 1px solid var(--av-brand-light);
        color: var(--av-nav-primary);
        background: var(--av-brand-lightest);
        border-radius: 4px;
        padding: 7px 15px;
        text-decoration: none;
        display: block;
        text-align: center;
        margin-top: 24px;

        @media (--viewport-tablet) {
            margin-top: 0;
            text-align: start;
            padding: 15px 31px 15px 15px;
            box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
        }
    }

    .section-link-title {
        @mixin lead-accent;

        min-height: 32px;
    }

    .section-link-text {
        @mixin caption;
    }

    &.dark {
        color: var(--av-inversed-primary);

        .section-link {
            box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
            color: var(--av-inversed-primary);

            @media (--viewport-tablet) {
                background: rgba(21, 60, 122, 1);
                box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
            }
        }
    }

    &.small-title {
        padding-top: 38px;

        @media (--viewport-tablet) {
            padding-bottom: 40px;
        }

        .title {
            @mixin display;

            margin-top: 0;

            @media (--viewport-tablet) {
                max-width: 300px;
            }

            @media (--viewport-desktop) {
                margin-top: 12px;
                font-size: 40px;
                max-width: none;
            }
        }
    }
}
</style>
