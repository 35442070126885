export default {
    props: {
        /**
         * Get data slice from product
         */
        typeInheritance: {
            type: String,
            default: 'common',
        },

        products: {
            type: Array,
        },
    },

    inheritAttrs: false,

    computed: {
        productId() {
            return this.$store.state.pages?.page.product_id;
        },
        productCurrent() {
            return this.$store.state.products?.items?.[this.productId] || {};
        },
        productParameters() {
            return this.productCurrent?.parameters || {};
        },
        product() {
            return this.productParameters?.[this.typeInheritance] || {};
        },
        productSystemInfo() {
            return this.productParameters?.systemInfo || [];
        },
        productFAQ() {
            return this.productParameters?.faq || {};
        },
        productPages() {
            return this.productParameters?.pages || {};
        },
        getProducts() {
            return Array.isArray(this.products) ? this.products : [];
        },
        currentLocale() {
            return this.$route?.params.locale;
        },
    },

    methods: {
        getData(name) {
            const property = this.l10n?.[name] || this.$props[name];
            const propertyByProduct = this.product[name];

            if (Array.isArray(property) && property.length) {
                return property.filter(Boolean);
            }
            if (Array.isArray(propertyByProduct) && propertyByProduct.length) {
                return propertyByProduct.filter(Boolean);
            }

            return property || propertyByProduct;
        },
        isVisible(name) {
            const property = this.getData(name);
            if (Array.isArray(property) && property.length) {
                return true;
            }
            return Boolean(property);
        },
    },
};
