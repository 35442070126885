export default {
    links: [
        {
            link: 'https://www.facebook.com/acronis',
            name: 'facebook',
            title: 'Facebook',
        },
        {
            link: 'https://x.com/Acronis',
            name: 'twitter',
            title: 'X',
        },
        {
            link: 'https://www.youtube.com/user/Acronis',
            name: 'yt',
            title: 'YouTube',
        },
        {
            link: 'https://www.linkedin.com/company/acronis',
            name: 'linkedin',
            title: 'LinkedIn',
        },
        {
            link: 'https://community.spiceworks.com/pages/acronis',
            name: 'spiceworks',
            title: 'Spiceworks',
        },
        {
            link: 'https://www.reddit.com/r/acronis',
            name: 'reddit',
            title: 'Reddit',
        },
    ],
    share: [
        {
            link: (url, title) => `https://x.com/share?text=${title}&url=${url}`,
            name: 'twitter',
        },
        {
            link: (url) => `https://www.facebook.com/sharer.php?u=${url}`,
            name: 'facebook',
        },
        {
            link: (url, title) => `http://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`,
            name: 'linkedin',
        },
        {
            link: (url, title) => `https://www.reddit.com/submit?url=${url}&title=${title}`,
            name: 'reddit',
        },
    ],
};
