<template>
    <div class="quote">
        <div class="a-container quote__wrap">
            <div class="quote__col">
                <a-glyph class="quote__icon" name="quote-mark-large" />
                <div class="quote__info">
                    <div class="quote__subtitle">
                        {{ subtitle }}
                    </div>
                    <div class="quote__title">
                        {{ title }}
                    </div>
                    <div class="quote__author">
                        {{ author }}
                    </div>
                    <div v-if="detail && isDesktop" class="a-container quote__body">
                        <div class="quote__text">
                            {{ text }}
                        </div>
                        <a-link
                            v-bind="link"
                            :glyph="link.glyph || 'arrow'"
                            :size="link.size || 'paragraph'"
                        />
                    </div>
                    <template v-if="isMobile">
                        <a-video-card
                            v-if="video"
                            class="quote__video"
                            :yt-id="video.ytId"
                            :time="video.time"
                            :image="video.image"
                        />
                        <div v-else class="quote__image">
                            <a-picture :link="image" />
                        </div>
                    </template>
                    <div v-if="!detail" class="a-container quote__body">
                        <div class="quote__text">
                            {{ text }}
                        </div>
                        <a-link
                            v-bind="link"
                            :glyph="link.glyph || 'arrow'"
                            :size="link.size || 'paragraph'"
                        />
                    </div>
                    <div v-if="detail && (isMobile || isTablet)" class="a-container quote__body">
                        <div class="quote__text">
                            {{ text }}
                        </div>
                        <a-link
                            v-bind="link"
                            :glyph="link.glyph || 'arrow'"
                            :size="link.size || 'paragraph'"
                        />
                    </div>
                </div>
            </div>
            <template v-if="!isMobile">
                <div v-if="video" class="quote__video-wrap">
                    <a-video-card
                        class="quote__video"
                        :yt-id="video.ytId"
                        :time="video.time"
                        :image="video.image"
                    />
                </div>
                <div v-else class="quote__image">
                    <a-picture :link="image" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import AVideoCard from '@core/components/video-card/video-card.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'SSportQuote',
    components: { ALink, AVideoCard, AGlyph, APicture },
    mixins: [breakpoint],

    props: {
        subtitle: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        author: {
            type: String,
            required: true,
        },
        video: {
            type: Object,
            default: null,
        },
        image: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            required: true,
        },
        detail: {
            type: Boolean,
            default: false,
        },
        link: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.quote {
    padding-top: 64px;
    padding-bottom: 80px;
    text-align: center;

    @media (--viewport-tablet) {
        text-align: start;
    }

    @media (--viewport-desktop-large) {
        padding-top: 80px;
        padding-bottom: 100px;
    }

    &__info {
        position: relative;
        .quote__body {
            padding-inline-start: 0;
            padding-inline-end: 0;
            margin-top: 0;
        }
    }

    &__body {
        margin-top: 0;
        @media (--viewport-tablet) {
            margin-top: 24px;
        }
        @media (--viewport-desktop) {
            margin-top: 32px;
        }
    }

    &__wrap {
        @media (--viewport-tablet) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        @media (--viewport-tablet) {
            margin-inline-end: 24px;
        }
        @media (--viewport-desktop) {
            margin-inline-end: 16px;
        }

        @media (--viewport-desktop-large) {
            margin-inline-end: 70px;
        }
    }

    &__subtitle {
        color: var(--av-brand-primary);
        margin-bottom: 16px;
        @mixin paragraph;

        @media (--viewport-desktop) {
            @mixin lead;
        }
    }

    &__title {
        @mixin display;
        margin-bottom: 24px;
        color: var(--av-nav-primary);

        @media (--viewport-tablet) {
            @mixin display;
        }
        @media (--viewport-desktop) {
            @mixin hero;
            max-width: 90%;
        }
    }

    &__author {
        @mixin paragraph;
        color: var(--av-fixed-secondary);
        margin-bottom: 24px;

        @media (--viewport-desktop) {
            margin-bottom: 8px;
        }
    }

    &__text {
        @mixin paragraph;
        color: var(--av-nav-primary);
        margin-bottom: 32px;

        @media (--viewport-desktop) {
            margin-bottom: 32px;
            @mixin lead;
        }
    }

    &__video {
        border-radius: 4px;
        margin-bottom: 40px;
        min-width: 327px;
        aspect-ratio: 1.33;
        padding: 0;
        width: 100%;

        @media (--viewport-tablet) {
            margin-bottom: 16px;
            min-width: 284px;
            max-width: 463px;
        }

        @media (--viewport-desktop) {
            min-width: 391px;
        }
    }

    &__image {
        border-radius: 4px;
        filter: drop-shadow(0 4px 8px var(--av-fixed-invisible));
        position: relative;
        margin-bottom: 40px;
        height: max-content;

        @media (--viewport-tablet) {
            margin-bottom: 16px;
        }

        &:deep(img) {
            min-width: 327px;
            width: 100%;
            border-radius: 4px;
            max-width: none;
            @media (--viewport-tablet) {
                min-width: 284px;
            }

            @media (--viewport-desktop) {
                min-width: 391px;
            }
            @media (--viewport-desktop-wide) {
                min-width: 471px;
            }
        }
    }

    &__icon {
        z-index: -1;
        width: 126px;
        height: 103px;
        position: absolute;
        top: 40px;
    }
}
</style>
