<template>
    <resource-page>
        <div class="press-release">
            <div class="header-wrapper">
                <resource-breadcrumbs
                    :breadcrumbs="breadcrumbs"
                    :common-translations="commonTranslations"
                />
            </div>
            <div class="content-wrapper a-container">
                <div class="publish-date">
                    {{ publishDate }}
                </div>
                <dynamic-link class="label" :to="releasesUrl">
                    {{ commonTranslations.pressRelease }}
                </dynamic-link>
                <h1 class="title">
                    {{ release.title }}
                </h1>
                <div class="share-block">
                    <div class="share-block-text">
                        {{ commonTranslations.shareText }}
                    </div>
                    <a-social :social="socialsShareLinks" :event="gaSocialEvent" glyph-size="s" />
                </div>

                <available-locales
                    v-if="availableLocalesData.length"
                    :locales-data="availableLocalesData"
                    :common-translations="commonTranslations"
                />

                <resource-article-body v-if="body.length" :blocks="body">
                    <template v-if="boilerplateHtml" #default>
                        <div class="boilerplate" v-html="boilerplateHtml" />
                    </template>
                </resource-article-body>

                <div class="press-contacts">
                    <div class="contact-header">
                        {{ commonTranslations.pressContacts }}:
                    </div>
                    <div class="contact-info">
                        <div class="name">
                            {{ author.name }}
                        </div>
                        <div class="position">
                            {{ author.position }}
                        </div>
                    </div>
                    <div class="contact-info">
                        <a v-if="author.phone_number" :href="telLink" class="phone">
                            {{ author.phone_number }}
                        </a>
                        <dynamic-link :to="`mailto:${author.email_address}`" class="email">
                            {{ author.email_address }}
                        </dynamic-link>
                    </div>
                </div>
            </div>
        </div>
    </resource-page>
</template>

<script lang="ts">
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ASocial from '@core/components/social/social.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import AvailableLocales from '@core/slices/press-releases/shared-components/release-available-locales.vue';
import ResourceBreadcrumbs from '@core/slices/resource-center/resource-breadcrumbs/resource-breadcrumbs.vue';
import ResourceArticleBody from '@core/slices/resource-center/shared-components/resource-article-body.vue';
import ResourcePage from '@core/slices/resource-center/shared-components/resource-page.vue';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { PR_ROOT_URL } from '@model/const/press-releases';
import { GA_SOCIAL_EVENT } from '@model/const/resource-center';
import socials from '@model/const/socials';
import commonUtils from '@utils/common';
import { formatToLocaleDate } from '@utils/locales';

export default {
    name: 'PressRelease',

    components: {
        AvailableLocales,
        DynamicLink,
        ASocial,
        ResourceArticleBody,
        ResourceBreadcrumbs,
        ResourcePage,
    },

    mixins: [breakpoint],

    props: {
        year: {
            type: String,
            required: true,
        },
    },

    computed: {
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },

        availableLocalesData() {
            return this.$store.getters['pressReleases/getAvailableLocales'](this.locale);
        },

        socialsShareLinks() {
            const title = '';
            const url = typeof window !== 'undefined' ? window.location : '';

            return socials.share.map((item) => ({
                name: item.name,
                link: item.link(encodeURIComponent(`${url}`), encodeURIComponent(title)),
            }));
        },

        gaSocialEvent() {
            return GA_SOCIAL_EVENT;
        },

        author() {
            return this.$store.state.pressReleases.pressReleaseAuthor.items?.[0] || {};
        },

        release() {
            return this.$store.state.pressReleases.pressRelease.items?.[0] || {};
        },

        telLink() {
            return commonUtils.getTelLink(this.author.phone_number);
        },

        commonTranslations() {
            return this.$store.state.slices.items['press-releases'] || {};
        },

        breadcrumbs() {
            return [
                {
                    text: this.commonTranslations.pressReleases,
                    to: `${PR_ROOT_URL}/`,
                    glyph: 'home',
                    glyphPosition: 'left',
                },
                {
                    text: this.year,
                    to: `${PR_ROOT_URL}/${this.year}/`,
                },
                {
                    text: this.release.title,
                    to: '#',
                },
            ];
        },

        publishDate() {
            return formatToLocaleDate(this.release.published_at, this.locale);
        },

        body() {
            if (!this.release.contents) return [];

            const body = JSON.parse(this.release.contents);
            return body.blocks;
        },

        releasesUrl() {
            return `/${this.locale}/pr/`;
        },

        boilerplateHtml() {
            return this.commonTranslations.pressReleaseBoilerplate || '';
        },
    },
};
</script>

<style lang="postcss" scoped>
.press-release {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    border-top: 3px solid var(--av-brand-light);
    transform: translateY(-5px);

    @media (--viewport-desktop) {
        transform: none;
    }

    .header-wrapper {
        @mixin page-horizonal-paddings;
        gap: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;

        @media (--viewport-tablet) {
            display: block;
            position: relative;
            padding-top: 32px;
            padding-bottom: 40px;
        }
    }

    .publish-date {
        @mixin paragraph;
        color: var(--av-nav-primary);
        text-align: center;
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            position: absolute;
            top: -64px;
            inset-inline-end: 32px;
        }

        @media (--viewport-desktop-wide) {
            position: absolute;
            inset-inline-end: 64px;
        }
    }

    .label {
        margin: 0 auto;
        border-radius: 4px;
        background: var(--av-brand-primary);
        text-transform: uppercase;
        color: var(--av-inversed-primary);
        height: 16px;
        padding: 4px 8px;
        font-size: 11px;
        line-height: 6px;
        font-weight: 700;
    }

    .title {
        @mixin title;
        max-width: 960px;
        margin: 8px auto 0;
        text-align: center;
        color: var(--av-nav-primary);

        @media (--viewport-tablet) {
            @mixin display;
        }

        @media (--viewport-desktop) {
            @mixin hero;
        }
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;

        .article-body {
            text-align: left !important;
            margin-bottom: 48px;

            &:deep(a) {
                color: var(--av-brand-primary);

                &:hover {
                    color: var(--av-brand-secondary);
                }

                &:active {
                    color: var(--av-brand-primary);
                }
            }
        }
    }

    .share-block {
        margin: 24px 0 32px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 16px;

        &:deep(.a-social svg) {
            fill: var(--av-brand-primary);
        }
    }

    .share-block-text {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: var(--av-nav-primary);
        mix-blend-mode: normal;
        opacity: 0.9;
    }

    /* hide the last breadcrumb and the second to last arrow */
    &:deep(.a-breadcrumbs__item) {
        &:nth-last-child(-n + 2) svg,
        &:last-child {
            display: none;
        }
    }
}

.press-contacts {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    margin: 0 auto 80px;
    gap: 16px;
    border-radius: 8px;
    background: var(--av-brand-secondary-accent);
    width: 241px;

    @media (--viewport-tablet) {
        width: 100%;
        max-width: 664px;
        flex-direction: row;
        align-items: center;
        gap: 56px;
    }
}

.contact-header {
    @mixin lead-accent;
    color: var(--av-fixed-primary);
}

.contact-info {
    @mixin body;
    color: var(--av-fixed-primary);
}

.phone,
.email {
    color: var(--av-brand-primary);
}

.resource-center-page {
    &:deep(.s-global-footer__social .s-global-footer__social-wrapper) {
        border-top: 0;
    }

    &:deep(.s-main-menu) {
        @media (--viewport-tablet) {
            top: 48px;
        }
    }
}

.boilerplate {
    &:deep(a) {
        color: var(--av-brand-primary);

        &:hover {
            color: var(--av-brand-secondary);
        }

        &:active {
            color: var(--av-brand-primary);
        }
    }
}
</style>
