<template>
    <s-basic-slice :id="id" class="s-preferred-small-cards" :class="{ ...className, ...basicClassName }">
        <div class="s-preferred-small-cards__leftside">
            <div v-if="l10n.title" class="a-slice-header__title">
                {{ l10n.title }}
            </div>
            <div v-if="(l10n.lead && !l10n.title) || leadIsVisible" class="a-slice-header__lead">
                {{ l10n.lead }}
            </div>
            <a-link
                v-if="downloadLink"
                class="s-preferred-small-cards__download-file"
                :to="downloadLink.link"
                :type="downloadLink.type || 'download-file'"
                :glyph="downloadLink.glyph || 'download-arrow'"
                :glyph-position="downloadLink.glyphPosition || 'left'"
                :text="downloadLink.title"
                v-bind="downloadLink"
            />
            <div v-if="l10n.link" class="s-preferred-small-cards__link-container">
                <a-link
                    class="s-preferred-small-cards__link"
                    v-bind="l10n.link"
                    :type="l10n.link.type || 'direct'"
                    :glyph="l10n.link.glyph || 'arrow'"
                />
            </div>
        </div>
        <div class="s-preferred-small-cards__content">
            <div class="s-preferred-small-cards__list">
                <div
                    v-for="(card, index) in cardsTrimmed"
                    v-show="!visibleCards || visibleCards > index"
                    :key="`preferred-small-cards-${index}`"
                    class="s-preferred-small-cards__list-item"
                >
                    <a-small-card v-bind="card" />
                </div>
            </div>
        </div>
    </s-basic-slice>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import ASmallCard from '@core/components/small-card/small-card.vue';
import contentMixin from '@core/mixins/content.js';
import l10n from '@core/mixins/l10n.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

const CARDS_LIMIT = 12;

export default {
    name: 'SCardsSmall',

    components: {
        ALink,
        ASmallCard,
        SBasicSlice,
    },

    mixins: [l10n, contentMixin, styleMixin],

    props: {
        link: {
            type: Object,
            default: undefined,
        },
        cards: {
            type: Array,
            required: true,
        },
        downloadFileArr: {
            type: Array,
            default: undefined,
        },
        sliderOptions: {
            type: Object,
            default: () => ({}),
        },
        isRowLayout: {
            type: Boolean,
            default: false,
        },
        leadIsVisible: {
            type: Boolean,
            default: false,
        },
        isRateLayout: {
            type: Boolean,
            default: false,
        },
        isCompanyLayout: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            step: 0,
            visibleCards: 0,
            initialVisibleCards: 0,
            sliceSliderOptions: {
                slidesPerView: 'auto',
                ...this.sliderOptions,
            },
        };
    },

    computed: {
        cardsTrimmed() {
            return this.l10n.cards?.slice(0, CARDS_LIMIT);
        },

        className() {
            return {
                's-preferred-small-cards_layout': true,
                's-preferred-small-cards_layout_company': this.isCompanyLayout,
                's-preferred-small-cards_layout_row': this.isRowLayout,
                's-preferred-small-cards_layout_default': (!this.isCompanyLayout && !this.isRowLayout),
                [`layout-${this.l10n.cards.length}-cards`]: this.l10n.cards?.length,
                's-preferred-small-cards_layout_rate': this.isRateLayout,
            };
        },
        downloadLink() {
            return this.l10n.downloadFileArr?.[0] || false;
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-preferred-small-cards {
    &:deep(.a-slice-header) {
        &__title {
            @mixin display;
            margin-bottom: 16px;

            @media (--viewport-desktop) {
                @mixin hero;
            }

            @media (--viewport-desktop-wide) {
                margin-bottom: 24px;
            }
        }

        &__lead {
            margin-top: 0;
            margin-bottom: 24px;

            @media (--viewport-tablet) {
                margin-bottom: 16px;
            }

            @media (--viewport-desktop) {
                margin-bottom: 40px;
            }
        }
    }

    &__link-container {
        margin-bottom: 48px;

        @media (--viewport-desktop) {
            position: absolute;
            top: 82px;
            inset-inline-end: 32px;
            margin: 0;
        }

        @media (--viewport-desktop-wide) {
            top: 66px;
            inset-inline-end: 64px;
        }

        @media (--viewport-desktop-large) {
            top: 50px;
            inset-inline-end: 0;
        }
    }

    &__download-file {
        margin-bottom: 16px;

        @media (--viewport-desktop) {
            position: absolute;
            top: 138px;
            inset-inline-end: 32px;
            margin: 0;
        }

        @media (--viewport-desktop-wide) {
            top: 122px;
            inset-inline-end: 64px;
        }

        @media (--viewport-desktop-large) {
            top: 106px;
            inset-inline-end: 0;
        }

        &:deep(.a-link__content) {
            @mixin paragraph;
        }

        &:deep(.a-link__desc) {
            margin-top: 4px;
            font-weight: 700;

            @media (--viewport-desktop) {
                text-align: end;
            }
        }
    }

    &__slide {
        width: 66%;
        padding-inline-end: 16px;
    }

    &__list {
        display: flex;
        gap: 16px;
        flex-direction: column;
    }

    &_layout {
        &.layout-1-cards {
            @media (--viewport-desktop) {
                .s-preferred-small-cards__list-item {
                    max-width: 50%;

                    &:last-child {
                        flex-grow: 0;
                    }
                }
            }
        }

        &_company {
            &:deep(.a-slice-header) {
                &__title {
                    @mixin hero;
                }
            }
        }

        &_rate {
            &:deep(.rate-list) {
                margin-top: auto;
            }
        }

        @media (--viewport-mobile-wide) {
            &_row {
                &:deep(.a-slice-header__title) {
                    margin-bottom: 24px;
                }

                .s-preferred-small-cards__leftside {
                    max-width: initial;

                    @media (--viewport-desktop) {
                         min-width: 228px;
                    }

                    @media (--viewport-desktop-wide) {
                        min-width: 276px;
                    }

                    @media (--viewport-desktop-large) {
                        min-width: 316px;
                    }
                }

                .s-preferred-small-cards__list-item:last-child {
                    flex-grow: 1;
                }

                &.layout-1-cards {
                    .s-preferred-small-cards__list-item {
                        max-width: 50%;
                    }
                }
            }
        }

        @media (--viewport-tablet) {
            .s-preferred-small-cards__list {
                flex-direction: row;
                flex-wrap: wrap;
            }

            .s-preferred-small-cards__list-item {
                flex-grow: 1;
            }

            &.layout-2-cards,
            &.layout-3-cards,
            &.layout-4-cards,
            &.layout-5-cards,
            &.layout-6-cards,
            &.layout-7-cards,
            &.layout-8-cards,
            &.layout-9-cards,
            &.layout-10-cards,
            &.layout-11-cards,
            &.layout-12-cards {
                .s-preferred-small-cards__list-item {
                    flex-basis: calc(50% - 8px);
                }
            }

            &_row {
                &.layout-1-cards {
                    .s-preferred-small-cards__list-item {
                        max-width: 100%;
                    }
                }
            }
        }

        @media (--viewport-desktop) {
            .s-preferred-small-cards__list-item:last-child {
                flex-grow: 0;
            }

            &.layout-2-cards,
            &.layout-3-cards,
            &.layout-4-cards,
            &.layout-5-cards,
            &.layout-6-cards,
            &.layout-7-cards,
            &.layout-8-cards,
            &.layout-10-cards,
            &.layout-11-cards {
                .s-preferred-small-cards__list-item {
                    flex-basis: calc(50% - 8px);
                }
            }

            &.layout-3-cards {
                .s-preferred-small-cards__list-item {
                    flex-basis: calc(33% - 8px);
                }
            }

            &_row {
                &:deep(.a-slice-header) {
                    &__title {
                        max-width: 100%;
                    }

                    &__lead {
                        max-width: 100%;
                        margin-bottom: 16px;
                    }
                }

                &:deep(.a-container) {
                    display: flex;
                    justify-content: space-between;
                    gap: 16px;
                }

                .s-preferred-small-cards__leftside {
                    display: flex;
                    flex-direction: column;
                }

                .s-preferred-small-cards__link-container {
                    position: initial;
                    order: 1;
                    margin-bottom: 24px;
                }

                .s-preferred-small-cards__download-file {
                    position: initial;
                    order: 2;

                    &:deep(.a-link__desc) {
                        text-align: start;
                    }
                }

                &.layout-1-cards {
                    .s-preferred-small-cards__list-item {
                        max-width: 50%;
                    }
                }

                &.layout-3-cards {
                    .s-preferred-small-cards__list-item {
                        flex-basis: calc(33% - 12px);

                        &:last-child {
                            flex-basis: 100%;
                        }
                    }
                }
            }

            &_rate {
                .s-preferred-small-cards__list {
                    @mixin colls 8;
                }
            }
        }

        @media (--viewport-desktop-wide) {
            &.layout-2-cards {
                .s-preferred-small-cards__list-item {
                    flex-basis: calc(50% - 8px);
                }
            }

            &.layout-3-cards {
                .s-preferred-small-cards__list-item {
                    flex-basis: calc(33% - 8px);

                    &:last-child {
                        flex-basis: calc(33% - 8px);
                    }
                }
            }

            &.layout-5-cards,
            &.layout-6-cards,
            &.layout-9-cards,
            &.layout-10-cards {
                .s-preferred-small-cards__list-item {
                    flex-basis: calc(25% - 12px);

                    &:last-child {
                        flex-basis: calc(50% - 8px);
                    }
                }
            }

            &.layout-7-cards,
            &.layout-11-cards {
                .s-preferred-small-cards__list-item {
                    flex-basis: calc(25% - 12px);

                    &:nth-last-child(-n + 3) {
                        flex-basis: calc(33% - 8px);
                    }
                }
            }

            &.layout-4-cards,
            &.layout-8-cards,
            &.layout-12-cards {
                .s-preferred-small-cards__list-item {
                    flex-basis: calc(25% - 12px);
                }
            }

            &_rate {
                .s-preferred-small-cards__list {
                    @mixin colls 12;
                }
            }

            &_row {
                &.layout-4-cards,
                &.layout-5-cards,
                &.layout-6-cards,
                &.layout-7-cards,
                &.layout-8-cards,
                &.layout-9-cards,
                &.layout-10-cards,
                &.layout-11-cards,
                &.layout-12-cards {
                    .s-preferred-small-cards__list-item {
                        flex-basis: calc(50% - 8px);

                        &:nth-last-child(-n + 3) {
                            flex-basis: calc(50% - 8px);
                        }
                    }
                }
            }
        }
    }

    @media (--viewport-desktop) {
        &:deep(.a-slice-header) {
            &__title {
                max-width: 66.66%;
            }

            &__lead {
                max-width: 66.66%;
            }
        }

        &__content {
            position: relative;
        }
    }
}

.s-side-menu {
    &:deep(.a-container) {
        .s-preferred-small-cards {
            padding-bottom: 0;
            padding-top: 56px;
            @media (--viewport-desktop) {
                &:not(&:first-child) {
                    padding: 0;
                    margin: 104px 0 0;
                }
            }
            &:deep(.a-slice-header) {
                &__title {
                    display: none;
                    @media (--viewport-desktop) {
                        display: block;
                    }
                }
            }
            &__list {
                width: auto;
            }
            &__list-item {
                width: calc(33.33% - 16px);
                @media (--viewport-mobile-wide) {
                    width: calc(50% - 16px);
                }
                @media (--viewport-desktop-wide) {
                    width: calc(33.33% - 16px);
                }
            }
        }
    }
}

.s-preferred-small-cards_layout_row {
    .s-preferred-small-cards__content {
        flex-shrink: 0;
        width: 328px;

        @media (--viewport-tablet) {
            width: 704px;
        }

        @media (--viewport-desktop) {
            width: 716px;
        }

        @media (--viewport-desktop-wide) {
            width: 860px;
        }

        @media (--viewport-desktop-large) {
            width: 980px;
        }
    }

    &:deep(.a-slice-header__title) {
        max-width: 100%;
    }

    &:deep(.a-slice-header__lead) {
        max-width: 100%;
    }
}
</style>
