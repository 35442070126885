<template>
    <picture class="a-picture" :class="pictureClass">
        <template v-if="Boolean(background)">
            <template v-for="extension of supportedExtensions">
                <template v-for="(value, name) in background">
                    <source
                        v-bind="getBackground({ value, name, extension })"
                        :key="value + name + extension"
                    />
                </template>
            </template>
        </template>
        <template v-else-if="!isExternalLink">
            <template v-for="extension of supportedExtensions">
                <source v-bind="getContent({ extension })" :key="extension" />
            </template>
        </template>
        <img
            ref="image"
            class="a-picture__img"
            :class="imageClass"
            :alt="alt"
            :style="{ objectFit, objectPosition: position }"
            :src="fallbackLink"
            :loading="isEager ? 'eager' : 'lazy'"
        />
    </picture>
</template>

<script>
import { mapGetters } from 'vuex';
import breakpoint from '@core/mixins/breakpoint.js';
import { TYPES } from './constants.js';
import validateFit from './validateFit.js';

export default {
    name: 'APicture',

    mixins: [breakpoint],

    props: {
        link: {
            type: String,
            default: '',
        },

        alt: {
            type: String,
            default: 'Acronis',
        },

        isShadow: {
            type: Boolean,
            default: false,
        },

        isEager: {
            type: Boolean,
            default: false,
        },

        /**
         * Image's "object-fit" property.
         *
         * If we want always 'contain' but 'cover' only on desktop
         * e.g. { default: 'contain', desktop: 'cover' }
         */
        fit: {
            type: [String, Object],
            required: false,
            default: undefined,
            validator: validateFit,
        },

        /**
         * Image's "object-position" property
         */
        position: {
            type: String,
            default: 'center',
        },

        /**
         * Is a picture used as a background
         */
        isBackground: {
            type: Boolean,
            default: false,
        },

        /**
         * Image link if picture is used as a background
         */
        background: {
            type: Object,
            required: false,
            default: undefined,
        },

        /**
         * Image size if picture is not a background
         */
        size: {
            type: String,
            required: false,
            default: undefined,
            validator: (value) => value === undefined || TYPES.includes(value),
        },

        persistOriginal: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['imageLoaded', 'imageResized'],

    data() {
        return {
            supportedExtensions: ['webp', 'png'],
            pictureClass: { 'a-picture_is-background': this.isBackground },
            imageClass: { 'a-picture__img_shadow': this.isShadow },
            // TODO: this is misleading. Why having a mapping at all if we can just provide a proper template from the caller?
            // See also ./constants.js
            templateTypes: {
                normal: 'content',
                medium: 'content-md',
                small: 'content-sm',
                thumb: 'thumb',
                blogCover: 'blog-cover',
                blogCard: 'blog-card',
                background: 'background',
            },
        };
    },

    computed: {
        ...mapGetters('config', ['$config']),
        baseURL() {
            return this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE;
        },
        isExternalLink() {
            return this.link.startsWith('http:') || this.link.startsWith('https://') || this.link.startsWith('//');
        },
        fallbackLink() {
            if (this.background) {
                const hash = this.background[this.device];
                return this.buildImageLink('background', hash, 'png');
            }
            if (this.link) {
                if (this.isExternalLink) return this.link;

                const templateType = this.templateTypes[this.size] || 'content';
                return this.buildImageLink(templateType, this.link.toString(), 'png');
            }
            return '';
        },
        device() {
            if (this.isMobile) {
                return 'mobile';
            }
            if (this.isTablet) {
                return 'tablet';
            }
            return 'desktop';
        },
        objectFit() {
            const isObj = typeof this.fit === 'object';

            if (!isObj) {
                return this.fit;
            }

            return this.fit[this.device] || this.fit.default || 'fill';
        },
    },

    mounted() {
        this.addImageListeners();
    },

    beforeDestroy() {
        this.removeImageListeners();
    },

    methods: {
        addImageListeners() {
            if (!this.$refs.image) return;

            this.$refs.image.onload = () => {
                this.$emit('imageLoaded', this.$refs.image);
            };

            window.addEventListener('resize', this.handleResize);
        },
        removeImageListeners() {
            if (!this.$refs.image) return;
            this.$refs.image.onload = null;
            window.removeEventListener('resize', this.handleResize);
        },
        handleResize() {
            if (!this.$refs.image) return;
            this.$emit('imageResized', this.$refs.image);
        },
        buildImageLink(template, filename = '', extension = 'png') {
            return filename.startsWith('@') || filename.match(/^[0-9a-f]{32}$/i)
                ? `${this.baseURL}/images/${template}/${filename.replace('@', '')}.${extension}`
                : `${filename}${template.endsWith('@2x') ? '@2' : ''}.${extension}`;
        },
        getMIMEType(ext) {
            switch (ext) {
                case 'png':
                    return 'image/png';
                case 'webp':
                    return 'image/webp';
                case 'jpg':
                case 'jpeg':
                    return 'image/jpeg';
                default:
                    return 'application/octet-stream';
            }
        },
        getBackground({ name, value, extension }) {
            const type = this.getMIMEType(extension);
            const template1x = this.persistOriginal ? 'background@2x' : 'background';
            const template2x = 'background@2x';

            const srcset = [
                `${this.buildImageLink(template1x, value, extension)}`,
                `${this.buildImageLink(template2x, value, extension)} ${this.persistOriginal ? '' : '2x'}`,
            ].join(', ');

            switch (name) {
                case 'mobile': {
                    return {
                        media: '(max-width: 767px)',
                        srcset,
                        type,
                    };
                }
                case 'tablet': {
                    return {
                        media: '(min-width: 768px) and (max-width: 1024px)',
                        srcset,
                        type,
                    };
                }
                case 'desktop': {
                    return {
                        media: '(min-width: 1025px)',
                        srcset,
                        type,
                    };
                }
                default:
                    return {
                        media: '(max-width: 767px)',
                        srcset,
                        type,
                    };
            }
        },
        getContent({ extension }) {
            const templateType = this.templateTypes[this.size] || 'content';
            const template1x = this.persistOriginal ? `${templateType}@2x` : templateType;
            const template2x = `${templateType}@2x`;
            const type = this.getMIMEType(extension);
            const srcset = [
                `${this.buildImageLink(template1x, this.link.toString(), extension)}`,
                `${this.buildImageLink(template2x, this.link.toString(), extension)} ${this.persistOriginal ? '' : '2x'}`,
            ].join(', ');

            return {
                srcset,
                type,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-picture {
    display: block;

    &_is-background {
        .a-picture__img {
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0;
        }
    }

    &__img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;

        &_shadow {
            box-shadow: var(--av-shadow-regular);
        }
    }
}
</style>
