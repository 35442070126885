<template>
    <div class="integration-summary">
        <div class="a-container">
            <div class="content">
                <h2 class="title">
                    {{ title }}
                </h2>
                <div v-if="lead" class="lead">
                    {{ lead }}
                </div>
                <div v-if="links" class="links">
                    <template v-for="link in links">
                        <a-link
                            v-if="link.url && link.label"
                            :key="link.url"
                            class="link"
                            v-bind="link"
                            :to="link.url"
                            :text="link.label"
                        />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';

export default {
    name: 'SIntegrationSummary',

    components: {
        ALink,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        lead: {
            type: String,
            default: null,
        },

        links: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="postcss" scoped>
.content {
    padding-block: 24px;
    border-bottom: 1px solid var(--av-brand-accent);
}

.title {
    @mixin title;
}

.lead {
    @mixin body;

    margin-top: 16px;
}

.links {
    margin-top: 24px;
}

.link {
    @mixin body-accent;

    &:not(:last-child) {
        margin-inline-end: 24px;
    }
}

</style>
