<template>
    <s-basic-slice class="s-one-two-three-rule" v-bind="$attrs">
        <ul class="s-one-two-three-rule__list">
            <li v-for="(item, i) in items" :key="i" class="s-one-two-three-rule__list-item">
                <div class="s-one-two-three-rule-item">
                    <div class="s-one-two-three-rule-item__icon-container">
                        <a-icon
                            class="s-one-two-three-rule-item__icon"
                            :number="item.mobIconNumber"
                            :icon-alt="item.mobIconAlt || ' '"
                        />
                    </div>
                    <div class="s-one-two-three-rule-item__rightside">
                        <div class="s-one-two-three-rule-item__number">
                            {{ item.number }}
                        </div>
                        <div class="s-one-two-three-rule-item__title">
                            {{ item.title }}
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <ul class="s-one-two-three-rule__icon-list">
            <li v-for="(item, index) in items" :key="index" class="s-one-two-three-rule__icon-list-item">
                <div v-for="icon in item.iconsArr" :key="icon.iconNumber" class="s-one-two-three-rule-icon-item">
                    <div class="s-one-two-three-rule-icon-item__icon-container">
                        <a-icon
                            class="s-one-two-three-rule-icon-item__icon"
                            :number="icon.iconNumber"
                            :icon-alt="icon.iconAlt || ' '"
                        />
                    </div>
                    <div v-if="index === 0" class="s-one-two-three-rule-icon-item__arrow" aria-hidden="true" />
                </div>
            </li>
        </ul>
    </s-basic-slice>
</template>

<script>
import AIcon from '@core/components/icon/icon.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SOneTwoThreeRule',

    components: {
        SBasicSlice,
        AIcon,
    },

    inheritAttrs: false,

    props: {
        items: {
            type: Array,
            required: true,
            validator: (items) => items.length === 3,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-one-two-three-rule {
    &__list {
        @media (--viewport-mobile-wide) {
            margin: 0 -8px;
            display: flex;
        }
    }

    &__list-item {
        @media (--viewport-mobile-wide) {
            width: 33.33%;
            padding: 0 8px;
        }

        &:not(:last-of-type) {
            margin-bottom: 40px;

            @media (--viewport-mobile-wide) {
                margin-bottom: 0;
            }
        }
    }

    &__icon-list {
        display: none;

        @media (--viewport-mobile-wide) {
            margin: 0 -8px;
            display: flex;
        }
    }

    &__icon-list-item {
        @media (--viewport-mobile-wide) {
            width: 33.33%;
            padding: 0 8px;
        }

        &:first-of-type
            .s-one-two-three-rule-icon-item:last-of-type
            .s-one-two-three-rule-icon-item__arrow {
            @media (--viewport-mobile-wide) {
                width: calc(200% - 104px + 24px);
            }

            @media (--viewport-desktop) {
                width: calc(200% - 104px + 8px);
            }
        }

        &:last-of-type {
            display: flex;

            .s-one-two-three-rule-icon-item {
                margin-top: auto;
            }
        }
    }

    &-item {
        display: flex;

        &__icon-container {
            margin-inline-end: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 88px;
            height: 88px;

            @media (--viewport-mobile-wide) {
                display: none;
            }
        }

        &__number {
            margin-bottom: 8px;
            font-size: 32px;
            font-weight: 700;
            line-height: 40px;
            color: var(--av-brand-secondary);
        }

        &__title {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            color: var(--av-nav-primary);

            @media (--viewport-mobile-wide) {
                margin-bottom: 48px;
            }
        }
    }

    &-icon-item {
        position: relative;

        &:not(:last-of-type) {
            margin-bottom: 24px;
        }

        &__icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 96px;
            height: 96px;
        }

        &__arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            inset-inline-start: 104px;
            width: calc(100% - 104px + 8px);
            height: 16px;

            @media (--viewport-desktop) {
                inset-inline-start: 112px;
                width: calc(100% - 112px);
            }

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                inset-inline-end: 0;
                border-top: 2px solid var(--av-fixed-success);
                border-inline-end: 2px solid var(--av-fixed-success);
                width: 10px;
                height: 10px;
                transform: translateY(-50%) rotate(45deg);
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                inset-inline-start: 0;
                width: 100%;
                height: 2px;
                background-color: var(--av-fixed-success);
            }
        }
    }
}
</style>
