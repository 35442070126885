export const TYPES = ['label', 'text-label'];

export const VARIANTS = ['info', 'success', 'neutral'];

export const LAYOUTS = ['solid', 'subtle'];

export const SIZES = ['l', 's'];

export const THEMES = ['light', 'dark'];

export default {};
