<template>
    <s-basic-slice class="s-numbers" :class="className" v-bind="$attrs">
        <ul class="s-numbers__list">
            <li
                v-for="item in numbers"
                :key="item.title"
                class="s-numbers__item"
                :class="{ 's-numbers__item_type_reversed': item.reversed }"
                title=""
            >
                <div class="s-numbers__item-value">
                    {{ item.value }}
                </div>
                <div class="s-numbers__item-title">
                    {{ item.title }}
                </div>
                <div v-if="item.desc" class="s-numbers__item-desc">
                    {{ item.desc }}
                </div>
            </li>
        </ul>
        <a-link
            v-if="link"
            class="s-numbers__link"
            v-bind="link"
            :type="link.type || 'direct'"
            :glyph="link.glyph || 'arrow'"
        />
    </s-basic-slice>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SNumbers',

    components: {
        SBasicSlice,
        ALink,
    },

    props: {
        numbers: {
            type: Array,
            required: true,
        },

        link: {
            type: Object,
            required: false,
            default: undefined,
        },
    },

    computed: {
        className() {
            return {
                's-numbers_layout_4': this.numbers.length % 2 === 0,
                's-numbers_layout_3': this.numbers.length % 3 === 0,
                's-numbers_layout_with-desc': this.numbers[0].desc,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-numbers {
    overflow: hidden;

    &__link {
        margin-top: 48px;
    }

    &_layout {
        &_4 {
            .s-numbers {
                &__list {
                    margin-bottom: -40px;
                }

                &__item {
                    margin-bottom: 40px;
                }
            }
        }

        &_3 {
            .s-numbers {
                &__list {
                    margin-bottom: -48px;
                }

                &__item {
                    margin-bottom: 48px;
                }
            }
        }

        &_with-desc {
            .s-numbers {
                &__list {
                    margin-bottom: -40px;
                }

                &__item {
                    margin-bottom: 40px;
                }
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;

        &-value {
            margin-bottom: 8px;
            font-size: 32px;
            line-height: 1.25;
            font-weight: 700;
            color: var(--av-brand-secondary);
        }

        &-title {
            @mixin lead-accent;

            color: var(--av-nav-primary);
        }

        &_type_reversed {
            .s-numbers__item {
                &-title {
                    order: 1;
                }

                &-value {
                    margin-bottom: 0;
                    margin-top: 8px;
                    order: 2;
                }

                &-desc {
                    order: 3;
                }
            }
        }
    }

    @media (--viewport-mobile-wide) {
        &__list {
            display: flex;
            flex-wrap: wrap;
        }

        &__item {
            width: 50%;
        }

        &_layout {
            &_4 {
                .s-numbers {
                    &__list {
                        margin-bottom: -56px;
                        margin-inline-end: -16px;
                    }

                    &__item {
                        margin-bottom: 56px;
                        padding-inline-end: 32px;
                    }
                }
            }

            &_3 {
                .s-numbers {
                    &__list {
                        margin-bottom: -48px;
                        margin-inline-end: -16px;
                    }

                    &__item {
                        margin-bottom: 48px;
                        padding-inline-end: 32px;
                    }

                    &__link {
                        margin-top: 56px;
                    }
                }
            }

            &_with-desc {
                .s-numbers {
                    &__list {
                        margin-bottom: -40px;
                        margin-inline-end: -48px;
                    }

                    &__item {
                        margin-bottom: 40px;
                        padding-inline-end: 48px;
                    }

                    &__link {
                        margin-top: 48px;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop) {
        &:deep(.a-slice-header) {
            max-width: 66.66%;

            &__lead {
                max-width: 100%;
            }
        }

        &__link {
            position: absolute;
            top: 6px;
            inset-inline-end: 36px;
            max-width: 25%;
            margin-top: 0;
        }

        &_layout {
            &_4 {
                .s-numbers {
                    &__list {
                        margin-bottom: -56px;
                    }

                    &__item {
                        width: 25%;
                    }
                }
            }

            &_3 {
                .s-numbers {
                    &__list {
                        margin-bottom: -48px;
                    }

                    &__item {
                        width: 33.3%;
                    }

                    &__link {
                        margin-top: 0;
                    }
                }
            }

            &_with-desc {
                .s-numbers {
                    &__list {
                        margin-bottom: -40px;
                    }

                    &__link {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop-wide) {
        &__link {
            inset-inline-end: 74px;
        }
    }

    @media (--viewport-desktop-large) {
        &__link {
            inset-inline-end: 0;
        }
    }
}
</style>
