<template>
    <div class="s-keys s-slice" v-bind="$attrs" :class="basicClassName">
        <div class="a-container">
            <a-slice-header class="s-keys__header" :title="title" :lead="description" />
            <div class="s-keys__body">
                <div
                    v-for="(item, index) in keysPaginated"
                    :key="`keys-${index}`"
                    class="s-keys-item"
                    @click="copy(item.text)"
                >
                    <div class="s-keys-item__icon">
                        <a-glyph name="copy" size="m" />
                    </div>
                    <div class="s-keys-item__content">
                        <div class="s-keys-item__title">
                            {{ item.title }}
                        </div>
                        <div class="s-keys-item__link">
                            <a-popover :content="tooltip" trigger="click">
                                <v-clamp :max-lines="3">
                                    {{ item.text }}
                                </v-clamp>
                            </a-popover>
                        </div>
                    </div>
                </div>
                <a-link
                    v-if="keys.length > 3"
                    class="s-keys__link"
                    :class="{ 's-keys__link_open': !isShowAllKeys }"
                    :text="!isShowAllKeys ? 'Show all keys' : 'Collapse all keys'"
                    glyph="drop-down"
                    size="paragraph"
                    @click="isShowAllKeys = !isShowAllKeys"
                />
                <a-dangerous-html v-if="footer" class="s-keys__footer" :content="footer" />
            </div>
        </div>
    </div>
</template>

<script>
import VClamp from 'vue-clamp';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APopover from '@core/components/popover/popover.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SKeys',

    components: {
        VClamp,
        ADangerousHtml,
        AGlyph,
        ALink,
        ASliceHeader,
        APopover,
    },
    mixins: [contentMixin, styleMixin],
    props: {
        /**
         * Keys title
         */
        title: {
            type: String,
            required: false,
            default: undefined,
        },

        /**
         * Keys description
         */
        description: {
            type: String,
            required: false,
            default: undefined,
        },
        /**
         * Keys item
         */
        keys: {
            type: Array,
            required: false,
            default: () => [],
        },
        /**
         * Keys tooltip type
         */
        tooltip: {
            type: Object,
            default: () => ({
                content: 'default',
            }),
        },
        /**
         * Keys footer
         */
        footer: {
            type: String,
            required: false,
            default: 'default',
        },
    },
    data() {
        return {
            isShowAllKeys: false,
        };
    },
    computed: {
        keysPaginated() {
            return this.isShowAllKeys ? this.keys : this.keys.slice(0, 3);
        },
    },
    methods: {
        copy(text) {
            navigator.clipboard.writeText(text);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-keys {
    &__header {
        margin-bottom: 48px;
    }
    &__link {
        padding-bottom: 48px;
        position: relative;
        background: none;
        @mixin paragraph-accent;

        @media (--viewport-tablet) {
            padding-top: 8px;
        }
        &:deep(svg) {
            transform: rotate(-180deg);
            transition: all 0.2s;
        }
        &_open {
            &:deep(svg) {
                transform: none;
            }
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 1px;
            width: 117px;
            inset-inline-start: 0;
            background: var(--av-brand-secondary-light);
        }
    }
    &__footer {
        margin-top: 24px;
        color: var(--av-fixed-secondary);
    }
}
.s-keys-item {
    display: flex;
    margin-bottom: 40px;
    cursor: pointer;
    &:last-child {
        margin-bottom: 48px;
    }
    &__icon {
        padding-inline-end: 26px;
        position: relative;
        display: flex;
        align-items: center;

        &:after {
            content: '';
            position: absolute;
            inset-inline-end: 0;
            top: 0;
            bottom: 0;
            opacity: 0.5;
            background: var(--av-brand-secondary-light);
            width: 1px;
        }
    }

    &__content {
        padding-inline-start: 24px;
    }
    &__title {
        @mixin paragraph-accent;
        margin-bottom: 8px;
        color: var(--av-nav-primary);
        user-select: none;
    }
    &:deep(.a-popover) {
        .el-popover__reference {
            color: var(--av-link-primary);
            &:hover {
                color: var(--av-link-primary-hover);
            }
            &:active {
                color: var(--av-link-primary-active);
                background: rgba(255, 255, 255, 0);
            }
        }
    }
}
</style>
