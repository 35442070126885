<template>
    <section
        :id="id"
        class="s-g2-reviews-card"
        :class="basicClassName"
        v-bind="$attrs"
    >
        <div class="a-container">
            <div class="wrapper">
                <div class="header">
                    <a-glyph name="g2-logo" />
                    <div class="title">
                        {{ title }}
                    </div>
                </div>
                <div class="rating">
                    <div class="reviews-stars">
                        <g2-review-star
                            v-for="index in maxRating"
                            :key="index"
                            :star-id="`g2-total-star-${index}`"
                            :fill-percentage="getFillPercentage(index)"
                            :data-fill="getFillPercentage(index)"
                        />
                    </div>
                    <div class="total-rating">
                        {{ totalRating }} {{ outOfText }} 5
                    </div>
                </div>
                <a-link
                    v-if="reviewsUrl"
                    class="reviews-link"
                    :to="reviewsUrl"
                    :text="linkText"
                    size="title"
                    glyph="arrow"
                />
            </div>
        </div>
    </section>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import G2ReviewStar from '@core/slices/pages/g2-reviews/components/g2-review-star.vue';

export default {
    components: {
        AGlyph,
        ALink,
        G2ReviewStar,
    },
    mixins: [contentMixin, styleMixin],
    props: {
        averageRatingText: {
            type: String,
            required: false,
            default: 'Average rating, out of 5',
        },
        title: {
            type: String,
            default: 'G2 reviews',
        },
        outOfText: {
            type: String,
            default: 'out of',
        },
        g2ProductId: {
            type: String,
            required: true,
        },
        g2ProductName: {
            type: String,
            required: true,
        },
        showAllReviewsLink: {
            type: Boolean,
            default: true,
        },
        linkText: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            maxRating: 5,
            ssrRating: null,
        };
    },
    computed: {
        totalRating() {
            return this.ssrRating || this.$store.state.products.g2rating;
        },
        reviewsUrl() {
            if (!this.g2ProductName) return false;
            return `https://www.g2.com/products/${this.g2ProductName}/reviews`;
        },
    },
    async mounted() {
        if (!this.totalRating) {
            await Promise.all([
                this.$store.dispatch('products/getG2ProductRating', this.g2ProductId),
                this.$store.dispatch('products/getG2ProductReviews', this.g2ProductId),
            ]);
        }
    },
    methods: {
        getFillPercentage(starIndex) {
            const rating = this.totalRating;
            if (rating >= starIndex) return 100;
            if (starIndex - rating >= 1) return 0;
            return Math.ceil((rating - Math.floor(rating)) * 100);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-g2-reviews-card {
    padding: 64px 0;
    .wrapper {
        padding: 32px;
        background: var(--av-brand-secondary-accent);
        color: var(--av-nav-primary);
        align-items: center;
        border-radius: 16px;
        display: flex;
        flex-flow: row wrap;
        gap: 24px;
        .header {
            display:flex;
            align-items: center;
            gap: 24px;
            .a-glyph {
                width: 48px;
                height: 48px;
            }
            .title {
                @mixin display-accent;
                @media (--viewport-mobile-wide) {
                    @mixin hero-accent;
                }
            }
        }
        .rating {
            display:flex;
            width: 100%;
            align-items: center;
            flex-flow: row wrap;
            gap: 24px;
            @media (--viewport-tablet) {
                width: auto;
            }
            .g2-review-star {
                display:flex;
            }
            .reviews-stars {
                display: flex;
                align-items: center;
                gap: 8px;
                height: 32px;
            }
            .total-rating {
                width: 100%;
                @mixin title;
                @media (--viewport-mobile-wide) {
                    width: auto;
                }
            }
        }
        .a-link {
            width: 100%;
            font-weight: 700;
            @media(--viewport-desktop) {
                width: auto;
                margin-inline-start: auto;
            }
        }
    }
}
</style>
