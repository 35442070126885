import { LOCALE_DEFAULT } from '@model/const/locales.ts';

export default {
    computed: {
        phoneInputSettings() {
            return this.$store.state.slices.items?.['a-phone-number-input'] || null;
        },
    },
    methods: {
        onPhoneInputChange(event) {
            this.form.phoneCountryCode = event.country;
            if ('phone' in this.form) this.form.phone = event.number;
            if ('phone_number' in this.form) this.form.phone_number = event.number;
        },
    },
    serverPrefetch() {
        const locale = this.$route?.params.locale || LOCALE_DEFAULT;
        this.$store.dispatch('slices/getSyncedData', { slice: 'a-phone-number-input', locale });
    },
};
