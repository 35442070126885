import { minBy } from 'lodash';

export default {
    computed: {
        split46853() {
            // WEB-46853' - A/B Test Buy Block CTA
            if (typeof window === 'undefined') return {};
            const splits = window?.splitsCurrentActiveSet || [];
            return splits.find((x) => x.id === '46853') || {};
        },
        addedLicenses() {
            return this.settings.licenses.filter((item) => item.quantity);
        },

        editionList() {
            return this.settings.products.filter((item) => item.type === 'edition');
        },

        dictionary() {
            return this.settings?.dictionary || {};
        },

        valueExtension() {
            return Boolean(this.settings.licenses.find((item) => item.isExtension)?.quantity);
        },

        cloudWarnings() {
            const mode = this.settings?.page?.mode || 'expert';
            const warnings = this.dictionary?.[mode]?.warnings || [];
            if (warnings.length) return warnings;
            return this.settings.products.find((item) => item.product === 'cloud-storage')?.warnings || [];
        },

        warnings() {
            return [
                ...(this.isVisibleCloudWarning ? this.cloudWarnings : []),
                ...(this.isVisibleWorkloadWarning ? this.workloadWarnings : []),
                ...this.listRecommendations,
            ];
        },

        workloadWarnings() {
            const comparison = (item) => ['google-workspace', 'microsoft-365'].includes(item.name) && item.warnings;
            const list = this.settings.workloads.filter(comparison)[0];
            return list?.warnings || [];
        },

        isVisibleCloudWarning() {
            return this.addedLicenses.length && !this.addedLicenses.some((item) => item.workload);
        },

        isVisibleWorkloadWarning() {
            if (!this.workloadWarnings.length) return false;

            const hasCloudStorage = this.addedLicenses.find((item) => item.product === 'cloud-storage');
            if (hasCloudStorage) return false;

            const comparison = (item) => item.quantity && ['google-workspace', 'microsoft-365'].includes(item.workload);
            return this.settings.licenses.some(comparison);
        },

        listRecommendations() {
            // WEB-44501
            if (!this.settings?.recommendations?.length) return [];
            const addedLicenses = this.addedLicenses.map((item) => item.cleverBridgeCartID);
            return this.settings.recommendations.map((item) => {
                const listLicenses = Object.keys(item.licenses).map(Number);
                const result = addedLicenses
                    .filter((i) => listLicenses.includes(i))
                    .map((cartID) => {
                        if (this.settings?.mode === 'simple' || item.excludeSpecialOffer) {
                            const listUniqueRecommendation = [...new Set(Object.values(item.licenses))];
                            if (listUniqueRecommendation.some((i) => addedLicenses.includes(i))) return null;
                        }

                        const recommendation = this.settings.recommendations.find((i) => i.licenses[cartID]) || {};
                        if (!recommendation.description) return null;

                        const recommendedCartID = recommendation.licenses[cartID];
                        if (this.addedLicenses.find((i) => i.cleverBridgeCartID === recommendedCartID)) return null;

                        const quantity = this.addedLicenses.find((i) => i.cleverBridgeCartID === cartID)?.quantity || 1;
                        const license = this.settings.licenses.find((i) => i.cleverBridgeCartID === recommendedCartID);
                        if (!license) return null;

                        const productTitle = this.settings.products.find((i) => i.product === license.product)?.title || '';
                        const text = recommendation.description
                            .replace('@addProductTitle', productTitle)
                            .replace('@addLicense', `data-link="license"  data-license="${recommendedCartID}"`);

                        return { ...recommendation, text, quantity, license };
                    });
                return minBy(result, 'quantity');
            }).filter(Boolean);
        },

        hasMicrosoft365ID() {
            const comparison = (item) => item.workload === 'microsoft-365' && item.quantity;
            const licenses = this.addedLicenses.find(comparison);
            return Boolean(licenses);
        },

        hasGoogleWorkspaceID() {
            const comparison = (item) => item.workload === 'google-workspace' && item.quantity;
            const licenses = this.addedLicenses.find(comparison);
            return Boolean(licenses);
        },

        isVariantB() {
            // WEB-44722 - A/B Test Buy Block CTA
            if (typeof window === 'undefined') return false;
            const splits = window?.splitsCurrentActiveSet || [];
            const split = splits.find((x) => x.id === '44722') || {};
            return split.dimension && split.variant === 'b';
        },
    },

    methods: {
        scrollTo(id) {
            const position = document.getElementById(id).offsetTop;
            window.scrollTo({ top: position, behavior: 'smooth' });
        },

        translation(text) {
            return this.dictionary?.[text] || text;
        },

        currentPeriod(name) {
            return this.settings.products.find((item) => item.product === name)?.selected || 1;
        },

        titleCloud(value) {
            const list = this.dictionary?.dropdown?.cloud?.short || {};
            return list[value] || 250;
        },

        titlePeriod(value, type) {
            const list = this.dictionary?.dropdown?.period?.[type || 'short'] || {};
            return list[value || 1];
        },

        currentEdition(name, period) {
            const currentPeriod = period || this.currentPeriod(name);
            const comparison = (item) => item.product === name && item.period === currentPeriod;
            return this.settings.licenses.filter(comparison);
        },

        priceEdition(name, type) {
            const period = this.currentPeriod(name);
            const current = this.priceEditionTotal(name, 'price');
            const discount = this.priceEditionTotal(name, 'discount_absolute');
            const price = type === 'full' ? (current + discount) : current;

            if (price) {
                const text = this.translation('@price per year');
                return text.replace('@price', this.setCurrency(price / period));
            }

            const text = this.translation('From @price per year');
            const minimum = this.priceEditionMinimum(name, type);
            return minimum ? text.replace('@price', this.setCurrency(minimum)) : '';
        },

        priceEditionMinimum(edition, type) {
            const period = this.currentPeriod(edition);
            const priceList = this.currentEdition(edition)
                .filter((item) => item?.store?.price && item.period === period)
                .map((item) => {
                    const current = item.store.price;
                    const discount = item.store.discount_absolute;
                    const price = type === 'full' ? (current + discount) : current;
                    return price / period;
                }).filter(Boolean);
            return priceList.length ? Math.min(...priceList) : 0;
        },

        priceEditionTotal(edition, type) {
            const period = this.currentPeriod(edition);
            return this.currentEdition(edition)
                .filter((item) => item.quantity && item.period === period)
                .map((item) => (item.store[type] || 0) * item.quantity)
                .reduce((a, b) => a + b, 0);
        },

        addCloud() {
            const comparison = (item) => item.product === 'cloud-storage';
            if (this.addedLicenses.find(comparison)) return;
            this.$emit('toggleProduct', this.settings.products.find(comparison));
        },

        currentWorkload(name) {
            return this.settings.workloads.find((item) => item.name === name) || {};
        },

        optionText(dropdown, value) {
            return this.dictionary?.dropdown?.[dropdown.type]?.short?.[value] || this.translation('Select');
        },

        optionPlaceholder(license, dropdown) {
            if (dropdown.type !== 'cloud') return '';
            const comparison = (item) => item.product === license.product && item.quantity;
            const licenseSelected = this.settings.licenses.find(comparison);
            return this.optionText(dropdown, licenseSelected?.cloud || this.translation('Select'));
        },

        optionItem(license, type, value) {
            const result = this.settings.licenses.filter((item) => item.product === license.product);
            if (type === 'period') {
                const added = result.find((item) => item.quantity);
                const cloud = added?.cloud || 250;
                return result.find((item) => item?.cloud === cloud && item?.period === value);
            }
            return result.find((item) => item[type] === value);
        },

        optionList(license, dropdown) {
            const list = this.licensesList(license, dropdown);
            const options = [...new Set(list)];
            let price1Y = 0;
            return options.map((value) => {
                const product = this.optionItem(license, dropdown.type, value);
                const price = product?.store?.price || 0;
                const discount = product?.store?.discount_absolute || 0;
                const isPromo = this.settings.promo.isPromoActive && discount;
                if (value === 1) price1Y = price;
                const priceFull = (isPromo ? (price + discount) : (price1Y * value));
                const hasPrice = dropdown.hasPrice && !this.isMobile;
                const hasPriceFull = dropdown.type === 'period' && !this.isMobile && (value > 1 || isPromo);
                const promoText = this.translation('Save @percent');
                const percent = Math.round(100 - (100 * price) / priceFull);
                return {
                    ...product,
                    type: dropdown.type,
                    text: this.optionText(dropdown, value),
                    price: hasPrice ? this.setCurrency(price) : 0,
                    priceFull: hasPriceFull ? this.setCurrency(priceFull) : 0,
                    promo: hasPrice && hasPriceFull ? promoText.replace('@percent', `${percent}%`) : '',
                };
            });
        },

        licensesList(license, dropdown) {
            return this.settings.licenses
                .filter((item) => item.product === license.product)
                .map((item) => item[dropdown.type])
                .filter(Boolean);
        },

        cloudStorageIncluded(product) {
            return this.dictionary.cloudStorage?.[product.workload] || false;
        },

        sendEcommerce(license, type, eventAction) {
            if (!license || license.quantity < 1) return;
            this.ecommerceSendEvent({
                license: {
                    ...license.store,
                    item_list_name: license.item_list_name || '',
                },
                event: {
                    quantity: license.quantity,
                    eventLabel: license.store?.name || '',
                    eventAction,
                    ecommerce: {
                        type,
                        license: license.store?.cleverbridge_id || 0,
                    },
                },
            });
        },

        sendEcommerceAdd(license) {
            this.sendEcommerce(license, 'add', 'Add to cart');
        },

        sendEcommerceRemove(license) {
            this.sendEcommerce(license, 'remove', 'Remove from cart');
        },
    },
};
