export function normalizeHref(input, locale) {
    const initialLink = (input || '').trim();

    if (!initialLink || initialLink === '#') {
        return false;
    }

    const [, proto] = initialLink.match(/^(\w+):/) || [];

    if (['http', 'https', 'tel', 'mailto'].includes(proto)) {
        return initialLink;
    }
    if (proto) {
        // console.warn(`Unknown protocol ${proto} in ${initialLink}`);
        return initialLink;
    }
    if (initialLink.startsWith('#')) {
        return initialLink;
    }

    const FAKE_DOMAIN = 'https://www.acronis.com';
    const target = new URL(initialLink, FAKE_DOMAIN);

    // ensure trailing slash
    const isFile = Boolean(target.pathname.match(/\.\w+$/));

    if (!isFile) {
        target.pathname = target.pathname.replace(/[/]$/, '');
        target.pathname += '/';
    }

    // fix double slashes if any
    target.pathname = target.pathname.replace(/[/]+/g, '/');

    // inject locale if it's not here yet; skip files links
    if (!isFile && !target.pathname.match(/^[/][\w]{2}-[\w]{2}[/]/)) {
        target.pathname = `/${locale}${target.pathname}`;
    }

    return target.toString().replace(FAKE_DOMAIN, '');
}

// Done with regexp replace because isomorphic DOM fragment parser is too heavyweight for this task
export function normalizeHrefsInHtmlFragment(input, locale) {
    const ANCHOR_REGEXP = /<a[^>]*>[^<]*<[/]a>/gi; // to find all occurences of <a...>...</a>
    const HREF_REGEXP = /href=['"]([^'"]+)['"]/i; // to find href="..." attribute inside the anchor HTML fragment

    const hrefReplacer = (attr, href) => attr.replace(href, normalizeHref(href, locale));
    const anchorReplacer = (outerHtml) => outerHtml.replace(HREF_REGEXP, hrefReplacer);
    return input.replace(ANCHOR_REGEXP, anchorReplacer);
}
