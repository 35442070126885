<template>
    <div class="blog-title-row" :class="className">
        <div class="cols">
            <div class="title-wrap">
                <h1 class="title">
                    {{ title }}
                </h1>
                <el-select
                    v-if="sortingOptions"
                    v-model="selectedSorting"
                    :hide-on-resize="isDesktop"
                    class="sorting"
                    popper-class="blog-dropdown sorting-dropdown"
                    @change="sortingChanged"
                >
                    <el-option
                        v-for="(item, i) in sortingOptions"
                        :key="i"
                        :label="item.name"
                        :value="item.value"
                    />
                </el-select>
            </div>
            <div v-if="socialLinks" class="socials">
                <div class="social-label">
                    {{ socialsTitle }}
                </div>
                <a-social class="social" :social="socialLinks" />
            </div>
        </div>
    </div>
</template>

<script>
import ASocial from '@core/components/social/social.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'BlogTitleRow',
    components: {
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
        ASocial,
    },
    mixins: [breakpoint],
    props: {
        title: {
            type: String,
            required: true,
        },

        socialLinks: {
            type: Array,
            default: null,
        },

        socialsTitle: {
            type: String,
            default: null,
        },

        sortingOptions: {
            type: Array,
            default: null,
        },

        theme: {
            type: String,
            default: 'light',
        },

        largeFont: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['sortingChanged'],
    data() {
        return {
            selectedSorting: null,
        };
    },
    computed: {
        className() {
            return {
                dark: this.theme === 'dark',
                'large-font': this.largeFont,
                'has-sorting': Boolean(this.sortingOptions),
            };
        },
    },
    mounted() {
        if (this.sortingOptions) {
            this.selectedSorting = this.sortingOptions[0].value;
        }
    },
    methods: {
        sortingChanged(selectedSorting) {
            this.$emit('sortingChanged', selectedSorting);
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-title-row {
    padding-bottom: 40px;

    @media (--viewport-desktop-wide) {
        padding-bottom: 48px;
    }

    .cols {
        @media (--viewport-tablet) {
            display: grid;
            column-gap: 16px;
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (--viewport-desktop) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    .title-wrap {
        text-align: center;

        @media (--viewport-tablet) {
            text-align: start;
            display: flex;
            justify-content: space-between;
            grid-column-start: 1;
            grid-column-end: 3;
        }

        @media (--viewport-desktop) {
            grid-column-end: 4;
        }
    }

    .title {
        @mixin display;

        color: var(--av-nav-primary);
        font-weight: 500;

        @media (--viewport-desktop) {
            @mixin hero;
        }
    }

    .socials {
        @mixin body-accent;

        text-align: center;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        margin: 40px auto 0;
        width: 100%;
        max-width: 260px;
        align-items: center;

        @media (--viewport-tablet) {
            text-align: start;
            padding-inline-start: 16px;
            justify-content: flex-end;
            margin: 0;
            max-width: 260px;
        }
    }

    .social-label {
        white-space: nowrap;
        margin-inline-end: 12px;

        @media (--viewport-desktop-wide) {
            margin-inline-end: 24px;
        }
    }

    .social {
        flex-grow: 1;
        justify-content: space-between;
    }

    &.dark {
        color: var(--av-inversed-primary);

        .title {
            color: var(--av-inversed-primary);
        }
    }

    &.has-sorting {
        .socials {
            margin-top: 0;
        }
    }

    &.large-font {
        .title {
            text-align: center;
            font-weight: bold;

            @media (--viewport-tablet) {
                @mixin hero;
            }
        }
    }
}
</style>

<style lang="postcss">
.blog-title-row {
    .social {
        .a-social {
            &__link {
                display: flex;
                align-items: center;
                height: 100%;
                margin: 0;
            }

            &__icon {
                height: 16px;
                width: 16px;
                fill: var(--av-brand-secondary);
            }
        }
    }

    .sorting {
        max-width: 96px;
        display: flex;
        align-items: center;
        margin: 24px auto 16px;

        @media (--viewport-tablet) {
            margin: 0 -16px 0 8px;
        }

        .el-input {
            &__icon {
                color: var(--av-brand-secondary) !important;
            }

            &__editor--absolute-position {
                @mixin caption-accent;
                color: var(--av-brand-secondary);
                text-align: center;
                padding-inline-end: 4px;

                @media (--viewport-tablet) {
                    text-align: end;
                }
            }

            &__placeholder {
                display: none;
            }

            &__container {
                background: transparent;
                height: auto;
                padding: 0;
                border: 0;
            }

            &__wrapper {
                padding: 0;
                margin-inline-end: -20px;
            }

            &__icon {
                margin-inline-end: 12px;

                @media (--viewport-tablet) {
                    margin-inline-end: -20px;
                }
            }
        }
    }
}

.sorting-dropdown.el-select-dropdown {
    .el-select-dropdown {
        &__item {
            text-transform: lowercase;
        }
        &__list {
            padding: 0;
        }
    }
    @media (max-width: 767px) {
        inset-inline-start: 0 !important;
        inset-inline-end: 0 !important;
        margin: auto !important;
    }
}
</style>
