<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { HttpRejection } from '@model/http/rejection';
import { getOgImage, getTextDirection, ACRONIS_OG_IMAGE_ID } from '@utils/html-meta';
import PRMediaContactsComponent from './component.vue';

export default Vue.extend({
    name: 'PRMediaContactsContainer',

    async serverPrefetch(): Promise<void> {
        const locale = this.$route.params.locale;

        await this.$store.dispatch('locales/getLocaleData', { locale });
        const localeData = this.$store.state.locales?.locale;
        if (!localeData?.is_active_on_public_relations) {
            throw new HttpRejection('Premature rendering stop', StatusCodes.NOT_FOUND);
        }

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 's-main-header', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-global-footer', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'press-releases', locale }),
        ]);

        // Building page meta
        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
    },

    methods: {
        getMeta(): any {
            const locale = this.$route.params.locale;
            const uiStrings = this.$store.state.slices.items['press-releases'];
            const canonical = `https://${this.$config.domain}${this.$route.path}`;

            const title = uiStrings.mediaContactsTitle || 'Acronis';
            const seoTitle = uiStrings.mediaContactsMetaTitle || '';
            const seoDescription = uiStrings.mediaContactsMetaDescription || '';

            const ogImage = getOgImage(`@${ACRONIS_OG_IMAGE_ID}`, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: seoTitle },
                    { tag: 'meta', name: 'description', content: seoDescription },
                    { tag: 'meta', property: 'og:title', content: seoTitle },
                    { tag: 'meta', property: 'og:description', content: seoDescription },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', property: 'og:image', content: ogImage },
                    { tag: 'meta', name: 'twitter:title', content: seoTitle },
                    { tag: 'meta', name: 'twitter:description', content: seoDescription },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'meta', name: 'twitter:image', content: ogImage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                    { tag: 'link', rel: 'image_src', href: ogImage },
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: locale.split('-')[0],
                },
            };
        },
    },

    render(h) {
        const props = {};
        return h(PRMediaContactsComponent, { props });
    },
});
</script>
