import { render, staticRenderFns } from "./sub-menu-product.vue?vue&type=template&id=452c6ead&scoped=true"
import script from "./sub-menu-product.vue?vue&type=script&lang=js"
export * from "./sub-menu-product.vue?vue&type=script&lang=js"
import style0 from "./sub-menu-product.vue?vue&type=style&index=0&id=452c6ead&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "452c6ead",
  null
  
)

export default component.exports