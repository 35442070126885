<template>
    <main class="page-container">
        <div class="page">
            <component
                v-bind="slice.data"
                :is="slice.name"
                v-for="(slice, position) in slices"
                :key="slice.id || `${slice.name}-${position}`"
            />
        </div>
    </main>
</template>

<script>
import { merge } from 'lodash';
import Vue from 'vue';
import analytics from '@core/mixins/analytics.js';

import '@core/styles/sections/pages.pcss';

export default {
    name: 'PagesComponent',

    mixins: [analytics],

    computed: {
        page() {
            return this.$store.state.pages.page;
        },
        slices() {
            const page = this.page.body || [];
            return page.map((item) => {
                const translations = item.data?.translations || {};
                const data = merge({}, item.data, translations);
                return { ...item, data };
            });
        },
    },

    beforeCreate() {
        // TODO: make s-all-products use the same format as wrapper, tabs, side-menu
        // TODO: when it's done, `resolveNestedSlices` can be converted to oneliner or just be inlined in caller functions
        const resolveNestedSlices = (node) => {
            const nested = node.name === 's-all-products'
                ? (node.data?.slicesData || []).flatMap((x) => x.slices)
                : (node.data?.slices || []);

            return nested.filter(Boolean);
        };

        // Recursive routine for dynamic slices resolution (including `.data.slices[*].name`)
        const names = new Set();
        const doNameResolverRound = (node) => {
            names.add(node.name);
            resolveNestedSlices(node).forEach(doNameResolverRound);
        };

        this.$store.state.pages.page.body.forEach(doNameResolverRound);

        Array.from(names).filter(Boolean).forEach((fullname) => {
            const sectionName = fullname.startsWith('s-acronis-scs') ? 'scs' : 'pages';
            const sliceName = fullname.replace(/^s-/, '');
            Vue.component(fullname, () => import(`@core/slices/${sectionName}/${sliceName}/${sliceName}.vue`));
        });

        // Recursive routine for dynamic merging of .dataFrom
        const doDataFromResolverRound = (node) => {
            if (node.dataFrom) {
                if (process.env.VUE_ENV === 'server') {
                    // on the client it will be in store already
                    this.$store.dispatch('slices/getSyncedData', { slice: node.dataFrom, locale: this.locale });
                }

                // WARN: We mutate the state outside of mutation here, which is actually discouraged,
                // but in this particular case it should be safe because:
                // 1) Synced always returns a deep copy of the dataset
                // 2) Components/Slices MUST NOT mutate store.pages.page.body anyway
                // 3) We merge sliceData with syncedData below anyway
                // The only downside of this approach is we can't set `strict` flag for store
                const syncedData = this.$store.state.slices.items[node.dataFrom];
                const sliceData = node.data || {};
                node.data = merge({}, syncedData, sliceData); // eslint-disable-line
            }

            resolveNestedSlices(node).forEach(doDataFromResolverRound);
        };

        this.$store.state.pages.page.body.forEach(doDataFromResolverRound);
    },
};
</script>

<style lang="postcss" scoped>
.page {
    position: relative;
}
</style>
