<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import {
    CARD_FIELDS_SUBSET,
    NEWS_NUMBER_ON_PAGE,
    SECTION_ID_BLOG,
    SECTION_ID_CPC,
    SECTION_SLUG_BY_ID,
} from '@model/const/blog';
import { assertHttpErrors } from '@utils/api-response';
import { ACRONIS_OG_IMAGE_ID, getOgImage, getTextDirection, getHtmlLang } from '@utils/html-meta';
import BlogBlogHomeComponent from './component.vue';

const TOP_CARDS_COUNT = 1;
const CARDS_NUMBER_ON_PAGE = 9;
const FEATURED_BLOG_NUMBER_ON_PAGE = 4;
const FEATURED_CPC_NUMBER_ON_PAGE = 2;

export default Vue.extend({
    name: 'BlogBlogHomeContainer',

    async serverPrefetch(): Promise<void> {
        // Init
        const locale = this.$route.params.locale;
        const page = parseInt(this.$route.params.page, 10) || 1;

        // Get top post and determine if we should proceed
        const topPostRequest = new ProductAPIQueryBuilder('topPost')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('section_id', '=', SECTION_ID_BLOG.toString())
            .addMatchesAll('is_news', '<>', '1')
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .setCustomParam('process-macros', '1')
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(1, TOP_CARDS_COUNT)
            .toObject();

        await this.$store.dispatch('blog/getEntity', { request: topPostRequest });

        const topPost = this.$store.state.blog.topPost;

        assertHttpErrors([{ entity: topPost, throwIfEmpty: true }]);

        // Get the rest of entities
        const latestCardsRequest = new ProductAPIQueryBuilder('latestCards')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('section_id', '=', SECTION_ID_BLOG.toString())
            .addMatchesAll('is_news', '<>', '1')
            .setCustomParam('process-macros', '1')
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(page, CARDS_NUMBER_ON_PAGE, TOP_CARDS_COUNT)
            .toObject();

        const CPCNewsRequest = new ProductAPIQueryBuilder('CPCNews')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('is_news', '=', '1')
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .setCustomParam('process-macros', '1')
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(1, NEWS_NUMBER_ON_PAGE)
            .toObject();

        const spotlightedCPCRequest = new ProductAPIQueryBuilder('spotlightedCPC')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('translation.is_spotlighted', '=', '1')
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .addMatchesAll('is_news', '<>', '1')
            .setCustomParam('process-macros', '1')
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(1, 1)
            .toObject();

        const featuredBlogRequest = new ProductAPIQueryBuilder('featuredBlog')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('is_news', '<>', '1')
            .addMatchesAll('translation.is_featured', '=', '1')
            .addMatchesAll('section_id', '=', SECTION_ID_BLOG.toString())
            .setCustomParam('process-macros', '1')
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(1, FEATURED_BLOG_NUMBER_ON_PAGE)
            .toObject();

        const sixMonthsAgoDate = new Date();
        sixMonthsAgoDate.setMonth(sixMonthsAgoDate.getMonth() - 6);

        const popularBlogRequest = new ProductAPIQueryBuilder('popularBlog')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('is_news', '<>', '1')
            .addMatchesAll('section_id', '=', SECTION_ID_BLOG.toString())
            .addMatchesAll('translation.published_at', '>=', sixMonthsAgoDate.toISOString())
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .setCustomParam('process-macros', '1')
            .addSort('translation.views_count', 'desc')
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(1, FEATURED_BLOG_NUMBER_ON_PAGE)
            .toObject();

        const featuredCPCRequest = new ProductAPIQueryBuilder('featuredCPC')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('is_news', '<>', '1')
            .addMatchesAll('translation.is_featured', '=', '1')
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .setCustomParam('process-macros', '1')
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(1, FEATURED_CPC_NUMBER_ON_PAGE + 1) // +1 for spotlighted
            .toObject();

        const categoriesRequest = new ProductAPIQueryBuilder('categories')
            .setEntityPath('/api/blog/categories/')
            .setLocales([locale])
            .addMatchesAll('section_id', '=', SECTION_ID_BLOG.toString())
            .setCustomParam('process-macros', '1')
            .setOutputOnly(['id', 'section_id', 'slug', 'title'])
            .toObject();

        const sectionsTranslationsListRequest = new ProductAPIQueryBuilder('sectionTranslationsList')
            .setEntityPath('/api/blog/sections/translations/')
            .addMatchesAll('section_id', '=', SECTION_ID_BLOG.toString())
            .setOutputOnly(['locale'])
            .setPaginate(1, 20)
            .toObject();

        const promises = [
            this.$store.dispatch('slices/getSyncedData', { slice: 's-main-header', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-global-footer', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'blog', locale }),
            this.$store.dispatch('blog/getEntity', { request: latestCardsRequest }),
            this.$store.dispatch('blog/getEntity', { request: featuredBlogRequest }),
            this.$store.dispatch('blog/getEntity', { request: popularBlogRequest }),
            this.$store.dispatch('blog/getEntity', { request: categoriesRequest }),
            this.$store.dispatch('blog/getEntity', { request: sectionsTranslationsListRequest }),
        ];

        await this.$store.dispatch('locales/getLocaleData', { locale });
        const cpcIsActiveForLocale = this.$store.state.locales.locale.is_active_on_blog_cpc;

        if (cpcIsActiveForLocale) {
            promises.push(this.$store.dispatch('blog/getEntity', { request: featuredCPCRequest }));
            promises.push(this.$store.dispatch('blog/getEntity', { request: CPCNewsRequest }));
            promises.push(this.$store.dispatch('blog/getEntity', { request: spotlightedCPCRequest }));
        }

        await Promise.all(promises);

        assertHttpErrors([
            { entity: this.$store.state.blog.latestCards },
            { entity: this.$store.state.blog.featuredBlog },
            { entity: this.$store.state.blog.popularBlog },
            { entity: this.$store.state.blog.categories },
            { entity: this.$store.state.blog.sectionTranslationsList },
            { entity: this.$store.state.blog.featuredCPC },
            { entity: this.$store.state.blog.CPCNews },
            { entity: this.$store.state.blog.spotlightedCPC },
        ]);

        // Building page meta
        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
    },

    methods: {
        getMeta(): any {
            const locale = this.$route.params.locale;
            const uiStrings = this.$store.state.slices.items.blog || {};
            const canonical = `https://${this.$config.domain}${this.$route.path}`;
            const page = parseInt(this.$route.params.page, 10) || 1;
            const slug = SECTION_SLUG_BY_ID[SECTION_ID_BLOG];
            const urlBase = `https://${this.$config.domain}/${locale}/${slug}/`;
            const totalPages = this.$store.state.blog.latestCards.pagination?.pages_total || 1;
            const paginationLinks = [];
            if (page > 1) {
                const href = page === 2 ? urlBase : `${urlBase}page/${page - 1}/`;
                paginationLinks.push({ tag: 'link', rel: 'prev', href });
            }
            if (totalPages > page) {
                const href = `${urlBase}page/${page + 1}/`;
                paginationLinks.push({ tag: 'link', rel: 'next', href });
            }

            const ogImage = getOgImage(`@${ACRONIS_OG_IMAGE_ID}`, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = uiStrings.homeMetaTitle;
            const description = uiStrings.homeMetaDescription;

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogImage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogImage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'link', rel: 'image_src', href: ogImage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                    ...paginationLinks,
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: getHtmlLang(locale),
                },
            };
        },

        async loadLatestCardsPage(page) {
            const request = new ProductAPIQueryBuilder('latestCards')
                .setEntityPath('/api/blog/posts/')
                .setLocales([this.$route.params.locale])
                .addMatchesAll('section_id', '=', SECTION_ID_BLOG.toString())
                .addMatchesAll('is_news', '<>', '1')
                .setOutputOnly(CARD_FIELDS_SUBSET)
                .addSort('translation.published_at', 'desc')
                .addSort('id', 'desc')
                .setPaginate(page, CARDS_NUMBER_ON_PAGE, TOP_CARDS_COUNT)
                .toObject();

            await this.$store.dispatch('blog/getEntity', { request });

            return this.$store.state.blog[request.id].httpStatus === StatusCodes.OK;
        },
    },

    render(h) {
        const props = { dispatchLoadLatestCardsPage: this.loadLatestCardsPage };
        return h(BlogBlogHomeComponent, { props });
    },
});
</script>
