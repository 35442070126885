<script lang="ts">
import MenuTab from '../menu-tab.vue';

export default {
    components: {
        MenuTab,
    },

    props: {
        /**
         * Menu
         */
        menu: {
            type: Array,
            required: false,
            default: () => [],
        },

        /**
         * Tabs
         */
        tabs: {
            type: Object,
            default: undefined,
        },

        /**
         * Environments
         */
        environments: {
            type: Object,
            default: undefined,
        },

        /**
         * Id
         */
        id: {
            type: String,
            default: 'Item',
        },

        /**
         * Active tab id
         */
        activeTab: {
            type: String,
            default: undefined,
        },

        /**
         * If a menu is centered around menu-link (for minisites)
         */
        type: {
            type: String,
            default: undefined,
            validator: (value) => value === undefined || ['mini', 'centered', 'default'].includes(value),
        },
    },
};
</script>
