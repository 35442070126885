<template>
    <div class="dev-network">
        <div class="solutions-container container">
            <div class="bg" :style="{ 'background-image': backgroundImage }" />
            <div class="content">
                <div class="title">
                    {{ title }}
                </div>

                <div class="description">
                    {{ description }}
                </div>

                <div v-if="links.length" class="links">
                    <a-link
                        v-for="link in links"
                        :key="link.to"
                        v-bind="link"
                        class="link"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'SolutionsHeaderMessage',

    components: {
        ALink,
    },

    mixins: [breakpoint],

    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        links: {
            type: Array,
            default: () => [],
        },
        background: {
            type: Object,
            required: true,
        },
    },

    // TEMP: mimicking a-picture behavior to preserve original styles
    // TODO: align with the design team and replace the whole slice with an existing one
    computed: {
        ...mapGetters('config', ['$config']),

        backgroundImage() {
            let hash = this.background.desktop;
            if (this.isMobile) {
                hash = this.background.mobile;
            }
            if (this.isTablet) {
                hash = this.background.tablet;
            }

            hash = hash.replace('@', '');

            return `url('${this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE}/images/background/${hash}.png')`;
        },
    },
};
</script>

<style lang="postcss" scoped>
.dev-network {
    background: var(--av-nav-primary);

    .container {
        position: relative;
    }

    .content {
        position: relative;
        color: var(--av-inversed-primary);
        padding: 72px 0 0;

        @media (--viewport-tablet) {
            padding: 64px 0 0;
        }

        @media (--viewport-desktop) {
            width: calc(50% - 8px);
            padding: 88px 0 104px;
        }
    }

    .title {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 24px;
    }

    .description {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
    }

    .links {
        display: inline-flex;
        flex-direction: column;
        gap: 16px;
    }

    &:deep(.a-link__content) {
        color: var(--av-link-inversed);

        &:hover {
            color: rgba(166, 203, 255, 0.8);
        }
    }

    .bg {
        position: relative;
        width: calc(100% + 32px);
        height: 156px;
        background-repeat: no-repeat;
        background-position: top center;
        inset-inline-start: -16px;
        background-size: auto 100%;
        margin-top: 16px;

        @media (--viewport-tablet) {
            height: 199px;
            background-repeat: no-repeat;
            background-position: top center;
            background-size: auto 100%;
            margin-top: 8px;
        }

        @media (--viewport-desktop) {
            position: absolute;
            height: auto;
            top: 0;
            inset-inline-start: 0;
            inset-inline-end: 0;
            bottom: 0;
            width: auto;
            display: block;
            margin: 0;
            background-repeat: no-repeat;
            background-position: bottom right;
            background-size: 65%;
        }

        @media (--viewport-desktop-wide) {
            background-size: 1081px 385px;
            margin-inline-end: 64px;
        }
    }
}
</style>
