export const locales = [
    'bg-bg',
    'en-eu',
    'en-gb',
    'en-sg',
    'de-de',
    'fr-fr',
    'es-es',
    'es-mx',
    'it-it',
    'pl-pl',
    'pt-br',
    'ro-ro',
    'tr-tr',
    'ko-kr',
    'ja-jp',
    'hi-in',
    'hu-hu',
];

export function getEnableAfter(url) {
    if (url.includes('/products/cloud/express-signup/')) {
        return 1000;
    }

    return 10 * 1000;
}

export const pageRegex = ['/products/', '/partners/', '/solutions/'].map(
    (reg) => new RegExp(reg, 'ig'),
);
