<template>
    <div
        v-show="links.length"
        class="resource-available-locales"
        :class="{ 'resource-available-locales--gated': isGatedAsset }"
    >
        <div class="snippet-title">
            {{ commonTranslations.alsoAvailableOn }}
        </div>
        <a-link
            v-for="(link, i) in links"
            :key="i"
            class="resource-link"
            v-bind="link"
        />
    </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import ALink from '@core/components/link/link.vue';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { getLinksToAvailableLocales } from '@utils/locales';

export default {
    name: 'ResourceAvailableLocales',

    components: { ALink },

    props: {
        locales: {
            type: Array,
            required: true,
        },
        commonTranslations: {
            type: Object as any,
            required: true,
        },
        isGatedAsset: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapGetters('slices', ['getLocales']),

        locale() {
            return this.$route.params.locale || LOCALE_DEFAULT;
        },

        links() {
            return getLinksToAvailableLocales({
                localeList: this.locales,
                localesConfig: this.getLocales,
                urlTemplate: `/{{LOCALE}}/resource-center/resource/${this.$route.params.slug}/`,
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-available-locales {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin: 24px auto 56px;
    max-width: fit-content;
    padding: 16px;
    border: 1px solid var(--av-brand-light);
    border-radius: 4px;

    @media (--viewport-desktop) {
        flex-wrap: nowrap;
    }

    .snippet-title {
        @mixin body;
        font-weight: 500;
        color: var(--av-fixed-light);
        width: 100%;

        @media (--viewport-desktop) {
            width: auto;
        }
    }

    &:deep(.a-link__content) {
        @mixin body;
        font-weight: 500;
        color: var(--av-brand-secondary);
    }

    &--gated {
        flex-wrap: wrap;
        justify-content: flex-start;
        column-gap: 24px;
        row-gap: 8px;
        margin-bottom: 40px;
        max-width: initial;

        .snippet-title {
            width: auto;
        }
    }
}
</style>
