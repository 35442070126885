<template>
    <div class="event-map">
        <h2 v-if="title" class="title">
            {{ title }}
        </h2>
        <div class="features">
            <div v-if="location" class="feature">
                <div class="caption">
                    {{ location.title }}
                </div>
                <div class="value value_with-tooltip">
                    {{ location.value }}
                </div>
            </div>
            <div v-if="website" class="feature">
                <div class="caption">
                    {{ website.title }}
                </div>
                <div class="value">
                    <a class="event-link" :href="website.value" target="_blank">{{ trimLink(website.value) }}</a>
                </div>
            </div>
            <div v-if="phone" class="feature">
                <div class="caption">
                    {{ phone.title }}
                </div>
                <div class="value">
                    <a class="event-link" :href="`tel:${phone.value}`">{{ phone.value }}</a>
                </div>
            </div>
        </div>
        <div id="map" class="map">
            <div
                v-if="!loaded"
                v-loading="true"
                class="loader"
                el-loading-size="48"
            >
                <div class="caption">
                    {{ loadingText }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive.js';
import { GOOGLE_MAP_API_KEY } from '@model/const/api-keys.ts';

const ZOOM_LEVEL = 17;

export default {
    name: 'EventMap',
    directives: {
        Loading,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        location: {
            type: Object,
            default: null,
        },
        website: {
            type: Object,
            default: null,
        },
        phone: {
            type: Object,
            default: null,
        },
        marker: {
            type: Object,
            required: true,
        },
        loadingText: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            MAPS_URL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&loading=async&callback=GoogleMapsInit`,
            map: null,
            loaded: false,
        };
    },
    mounted() {
        const mapsLoaded = new Promise((resolve) => {
            window.GoogleMapsInit = resolve;

            const GMap = document.createElement('script');
            GMap.setAttribute('src', this.MAPS_URL);
            document.body.appendChild(GMap);
        });

        mapsLoaded.then(() => {
            this.initMap();
        });
    },
    methods: {
        initMap() {
            this.loaded = true;

            this.map = new window.google.maps.Map(document.getElementById('map'), {
                center: new window.google.maps.LatLng(this.marker.lat, this.marker.lng),
                zoom: ZOOM_LEVEL,
                mapTypeControl: true,
                streetViewControl: false,
                scrollwheel: false,
            });

            const icon = '/public/assets/images/marker.svg';

            // eslint-disable-next-line no-new
            new window.google.maps.Marker({
                position: new window.google.maps.LatLng(this.marker.lat, this.marker.lng),
                icon,
                map: this.map,
            });
        },

        trimLink(link) {
            return link.replace(/^[^/]*?\/\//i, '');
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-map {
    .title {
        margin-bottom: 40px;
    }

    .features {
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            margin-bottom: 32px;
            display: flex;
            justify-content: flex-start;
        }
    }

    .feature {
        @mixin paragraph;
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            margin-inline-end: 56px;
            margin-bottom: 0;

            &:last-child {
                margin-inline-end: 0;
            }
        }
    }

    .caption {
        color: var(--av-fixed-light);
        margin-bottom: 8px;
    }

    .value {
        color: var(--av-fixed-primary);
    }

    .map {
        height: 280px;
        background: var(--av-fixed-invisible);
        display: flex;
        align-items: center;
        justify-content: center;

        @media (--viewport-tablet) {
            height: 392px;
        }

        @media (--viewport-desktop) {
            height: auto;
            aspect-ratio: 2.14;
        }
    }

    .loader {
        top: -20px;
        position: relative;
        height: 48px;
        width: 48px;

        .caption {
            color: var(--av-fixed-primary);
            font-size: 14px;
            line-height: 24px;
            position: absolute;
            top: 64px;
            inset-inline-start: 50%;
            transform: translateX(-50%);
            width: 200px;
            text-align: center;
        }
    }
}
</style>
