<template>
    <section class="s-slice s-ribbon-inline" :class="{ ...basicClassName }">
        <div class="a-container">
            <div class="title">
                {{ title }}
            </div>
            <a-dangerous-html class="description" :content="description" />
        </div>
    </section>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SRibbonInline',

    components: {
        ADangerousHtml,
    },

    mixins: [contentMixin, styleMixin],

    inheritAttrs: false,

    props: {
        /**
         * Title
         */
        title: {
            type: String,
            default: 'Title',
        },

        /**
         * Description
         */
        description: {
            type: String,
            default: 'Description',
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-ribbon-inline {
    padding: 24px 0;

    &:deep(.a-container) {
        @media (--viewport-mobile-wide) {
            display: flex;
            align-items: center;
        }
    }

    .title {
        margin-bottom: 8px;
        font-weight: 700;

        @media (--viewport-mobile-wide) {
            position: relative;
            margin-bottom: 0;
            flex-shrink: 0;
            max-width: 41.66%;
            box-sizing: border-box;
            padding-inline-end: 24px;
        }

        @media (--viewport-desktop-wide) {
            @mixin lead;
        }

        @media (--viewport-desktop-large) {
            max-width: 25%;
        }

        &::before {
            display: none;
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            inset-inline-end: 0;
            width: 1px;
            height: 40px;
            background-color: var(--av-fixed-lightest);

            @media (--viewport-mobile-wide) {
                display: block;
            }
        }
    }

    .description {
        @media (--viewport-mobile-wide) {
            flex-shrink: 1;
            padding-inline-start: 24px;
        }

        @media (--viewport-desktop-wide) {
            @mixin lead;
        }

        &:deep(strong) {
            font-weight: 700;
            color: var(--av-nav-primary);
        }
    }
}
</style>
