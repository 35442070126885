<template>
    <section class="sport-page">
        <div class="sport-header">
            <a-picture
                class="sport-header__image"
                :background="background"
                fit="cover"
                is-background
            />
            <div class="sport-header__content a-container">
                <div class="sport-header__info">
                    <div class="sport-header__info-wrap">
                        <div class="sport-header__subtitle">
                            {{ header.subtitle }}
                        </div>
                        <div class="sport-header__title">
                            {{ header.title }}
                        </div>
                    </div>
                    <a-link
                        class="sport-header__link"
                        v-bind="header.link"
                        :glyph="header.link?.glyph || 'arrow'"
                        :size="header.link?.size || 'paragraph'"
                        :type="header.link?.type || 'light'"
                    />
                </div>
                <div class="sport-header__card">
                    <dynamic-link class="sport-card" :to="header.news.link">
                        <div class="sport-card__content">
                            <div class="sport-card__date">
                                {{ header.news.date }}
                            </div>
                            <div class="sport-card__name">
                                {{ header.news.name }}
                            </div>
                            <div class="sport-card__text">
                                {{ header.news.text }}
                            </div>
                        </div>
                        <div class="sport-card__image">
                            <a-picture :link="header.news.image" />
                        </div>
                    </dynamic-link>
                    <div class="sport-hash">
                        {{ header.news.hash }}
                    </div>
                </div>
            </div>
        </div>
        <div class="sport-project">
            <div class="a-container sport-project__wrap">
                <div class="sport-project__col">
                    <div class="sport-project__name">
                        {{ project.title }}
                    </div>
                    <a-link
                        v-if="!isMobile"
                        v-bind="project.link"
                        :glyph="project.link?.glyph || 'arrow'"
                        :type="project.link?.type || 'light'"
                        :size="project.link?.size || 'lead'"
                    />
                </div>
                <div class="sport-project__col">
                    <a-video-card
                        v-if="project.video"
                        class="sport-project__video"
                        :yt-id="project.video.ytId"
                        :time="project.video.time"
                        :image="project.video.image"
                    />
                    <a-picture v-else class="sport-project__image" :link="project.image" />
                    <div class="sport-project__text">
                        {{ project.text }}
                    </div>
                    <a-link
                        v-if="isMobile"
                        v-bind="project.link"
                        :glyph="project.link.glyph || 'arrow'"
                    />
                </div>
            </div>
        </div>
        <s-quote v-bind="quote" />
        <s-media v-bind="media" />
        <div :id="logos.id" class="sport-logos">
            <div class="a-container">
                <div class="sport-logos__title">
                    {{ logos.title }}
                </div>
            </div>
            <div class="sport-logos__filter">
                <div class="sport-logos__header">
                    <div class="a-container sport-logos__header-wrap">
                        <div class="sport-logos__header-links">
                            <div
                                v-for="(item, index) in logos.regions"
                                :key="index"
                                class="sport-logos__header-link"
                                :class="{ active: regionActive === (item.ShowTeams ? item.ShowTeams : item) }"
                                @click="setActiveRegion(item.ShowTeams ? item.ShowTeams : item)"
                            >
                                {{ item.name ? item.name : item }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="a-container sport-logos__body">
                    <div v-modal="'data-sport-logos'" class="sport-logos__body-main">
                        <component
                            :is="item.link ? 'dynamic-link' : 'div'"
                            v-for="(item, index) in teamsFilteredPaginated"
                            :key="index"
                            class="sport-logos__logo"
                            :class="item.link ? 'link' : null"
                            :to="item.link ? item.link : null"
                        >
                            <div class="sport-logos__wrap">
                                <a-picture :link="item.image" />
                                <div class="sport-logos__logo-name">
                                    {{ item.name }}
                                </div>
                                <div class="sport-logos__logo-subject">
                                    {{ item.subject }}
                                </div>
                            </div>
                        </component>
                    </div>
                </div>
                <a-button
                    v-if="showMoreButtonShown"
                    class="sport-logos__button"
                    :text="!isShowAllTeams ? 'Show all' : 'Show less'"
                    @click="isShowAllTeams = !isShowAllTeams"
                />
            </div>
        </div>
        <div class="sport-number">
            <div class="sport-number__title">
                {{ number.title }}
            </div>
            <div class="a-container">
                <div class="sport-number__items">
                    <div v-for="(item, index) in number.items" :key="index" class="sport-number__item">
                        <div
                            class="sport-number__item-name"
                            :class="{ 'sport-number__item-name_selected': item.selected }"
                        >
                            {{ item.name }}
                        </div>
                        <div class="sport-number__item-text">
                            {{ item.text }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <s-advantages v-bind="advantages" />
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import AVideoCard from '@core/components/video-card/video-card.vue';
import Modal from '@core/directives/modal.js';
import breakpoint from '@core/mixins/breakpoint.js';
import SAdvantages from '@core/slices/shared/sport/advantages.vue';
import SMedia from '@core/slices/shared/sport/media.vue';
import SQuote from '@core/slices/shared/sport/quote.vue';

export default {
    name: 'SSport',
    components: {
        AButton,
        APicture,
        SMedia,
        ALink,
        SAdvantages,
        AVideoCard,
        AGlyph,
        SQuote,
        DynamicLink,
    },
    directives: {
        Modal,
    },
    mixins: [breakpoint],
    props: {
        /**
         * Sitemap sections data
         */
        background: {
            type: Object,
            required: true,
        },
        header: {
            type: Object,
            required: true,
        },
        project: {
            type: Object,
            required: true,
        },
        quote: {
            type: Object,
            required: true,
        },
        media: {
            type: Object,
            required: true,
        },
        number: {
            type: Object,
            required: true,
        },
        advantages: {
            type: Object,
            required: true,
        },
        logos: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            modalData: null,
            regionActive: this.logos.defaultRegion ? this.logos.defaultRegion : 'All',
            isShowAllTeams: false,
        };
    },
    computed: {
        showMoreButtonShown() {
            return (
                this.teamsFiltered.length > this.teamsFirstPageCount && this.regionActive === 'All'
            );
        },

        teamsAll() {
            return this.logos.teams;
        },

        teamsFiltered() {
            return this.regionActive === 'All'
                ? this.teamsAll
                : this.teamsAll.filter((x) => this.regionActive === x.region);
        },

        teamsFirstPageCount() {
            if (this.isMobile) {
                return 4;
            }
            if (this.isTablet) {
                return 10;
            }
            return 18;
        },

        teamsFilteredPaginated() {
            return this.isShowAllTeams || this.regionActive !== 'All'
                ? this.teamsFiltered
                : this.teamsFiltered.slice(0, this.teamsFirstPageCount);
        },
    },
    methods: {
        setModalData(modalData) {
            this.modalData = modalData;
        },
        setActiveRegion(item) {
            this.regionActive = item;
        },
    },
};
</script>

<style lang="postcss" scoped>
.sport-page {
    position: relative;

    &:deep(.a-video-card) {
        &__content {
            background: transparent;
        }
        &__wrapper:before {
            display: none;
        }
    }
}
.sport-header {
    color: var(--av-inversed-primary);
    position: relative;
    padding-top: 72px;

    @media (--viewport-tablet) {
        height: 587px;
    }

    @media (--viewport-desktop) {
        padding-bottom: 60px;
        height: 648px;
    }

    &__image {
        position: absolute;
        inset-inline-start: 0;
        inset-inline-end: 0;
        top: 0;
        bottom: 0;
        max-height: 510px;

        @media (--viewport-tablet) {
            max-height: 100%;
        }
    }

    &__content {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__subtitle {
        @mixin display;
        font-weight: 300;
    }
    &__title {
        @mixin display-accent;
        margin-top: 24px;

        @media (--viewport-mobile-wide) {
            @mixin hero-accent;
        }

        @media (--viewport-tablet) {
            margin-top: 32px;
            max-width: 55%;
        }

        @media (--viewport-desktop) {
            @mixin large-accent;
            max-width: 50%;
        }
    }

    &__link {
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            margin-bottom: 0;
            margin-top: 16px;
        }

        @media (--viewport-desktop) {
            margin-bottom: 0;
        }
    }

    &__card {
        display: flex;
        align-self: flex-start;
        margin-inline-start: -16px;
        margin-inline-end: -16px;
        flex-direction: column-reverse;

        @media (--viewport-mobile-wide) {
            margin-inline-start: -32px;
            margin-inline-end: -32px;
        }

        @media (--viewport-tablet) {
            flex-direction: row-reverse;
            align-items: flex-end;
        }

        @media (--viewport-desktop) {
            display: flex;
            margin-inline-start: -16px;
            margin-inline-end: 0;
        }

        @media (--viewport-desktop-large) {
            margin-inline-start: 0;
        }
    }

    &__info {
        height: 434px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            inset-inline-start: -16px;
            inset-inline-end: -16px;
            bottom: 0;
            background: linear-gradient(360deg, #00204d 44.24%, rgba(0, 32, 77, 0) 83.77%);
            height: 103px;
            @media (--viewport-mobile-wide) {
                inset-inline-start: -32px;
                inset-inline-end: -32px;
            }
            @media (--viewport-tablet) {
                display: none;
            }
        }

        @media (--viewport-tablet) {
            height: auto;
        }
    }
}
.sport-hash {
    position: relative;
    background: var(--av-brand-primary);
    padding: 8px 24px 8px 16px;
    @mixin title;
    font-weight: 500;
    width: 100%;
    text-align: center;

    @media (--viewport-tablet) {
        padding: 16px 24px 16px 32px;
        width: 183px;
        min-width: 174px;
        max-width: 174px;
        border-radius: 0 4px 0 0;
        text-align: start;
        font-weight: bold;
        height: max-content;
    }

    @media (--viewport-desktop) {
        border-radius: 4px 4px 0 4px;
        min-width: 183px;
        max-width: 183px;
        padding: 16px 24px 16px 16px;
    }
}
.sport-card {
    background: var(--av-inversed-primary);
    max-width: 615px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-decoration: none;

    @media (--viewport-tablet) {
        border-radius: 4px;
        margin-inline-start: -20px;
    }

    &__content {
        padding: 16px 16px 24px 16px;

        @media (--viewport-tablet) {
            padding: 16px 8px 20px 36px;
        }
        @media (--viewport-desktop) {
            padding: 20px 24px 16px 40px;
        }
    }

    &__date {
        margin-bottom: 8px;
        @mixin caption;
        color: var(--av-nav-primary);
    }

    &__name {
        color: var(--av-nav-primary);
        font-weight: 500;
        @mixin body;

        @media (--viewport-desktop) {
            font-weight: bold;
            color: var(--av-brand-secondary);
            @mixin paragraph;
        }
    }

    &__text {
        color: var(--av-nav-primary);
        @mixin body;

        @media (--viewport-desktop) {
            @mixin paragraph;
        }
    }

    &__image {
        min-width: 305px;
        height: 100%;
        display: none;

        @media (--viewport-tablet) {
            display: block;
        }

        .a-picture {
            height: 100%;
        }

        &:deep(img) {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.sport-project {
    background: var(--av-nav-primary);
    padding-top: 64px;
    padding-bottom: 80px;

    &__wrap {
        @media (--viewport-tablet) {
            display: flex;
        }
    }

    &__video,
    &__image {
        height: 264px;
        padding-bottom: 0;
        margin-bottom: 40px;
        border-radius: 4px;
        overflow: hidden;

        @media (--viewport-tablet) {
            margin-bottom: 24px;
            max-width: 404px;
        }

        @media (--viewport-desktop) {
            max-width: 632px;
        }

        @media (--viewport-desktop-wide) {
            max-width: 656px;
        }
    }

    &__image {
        .a-picture__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__name {
        color: var(--av-inversed-primary);
        @mixin display;
        margin-bottom: 32px;
        font-weight: 400;

        @media (--viewport-tablet) {
            margin-bottom: 56px;
            font-weight: 500;
        }

        @media (--viewport-desktop) {
            @mixin hero;
            font-weight: 400;
            margin-bottom: 24px;
        }

        @media (--viewport-desktop-wide) {
            margin-bottom: 64px;
        }

        @media (--viewport-desktop-large) {
            margin-bottom: 96px;
        }
    }

    &__text {
        color: var(--av-inversed-primary);
        font-weight: 400;
        @mixin paragraph;
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            @mixin lead;
            margin-bottom: 0;
        }
        @media (--viewport-desktop) {
            font-weight: 500;
            @mixin nav-title-accent;
        }
    }

    .a-link {
        @mixin lead;
    }

    &__col {
        text-align: center;

        @media (--viewport-tablet) {
            text-align: start;
        }

        &:first-child {
            margin-inline-end: 16px;
            @media (--viewport-desktop) {
                max-width: 33%;
            }
            @media (--viewport-desktop-wide) {
                margin-inline-end: 16px;
            }

            @media (--viewport-desktop-large) {
                margin-inline-end: 122px;
            }
        }

        &:last-child {
            @media (--viewport-tablet) {
                min-width: 404px;
                max-width: 404px;
            }
            @media (--viewport-desktop) {
                max-width: 632px;
                min-width: 632px;
            }
            @media (--viewport-desktop-wide) {
                max-width: none;
                min-width: unset;
            }
        }
    }
}

.sport-number {
    padding-top: 64px;
    padding-bottom: 40px;

    @media (--viewport-tablet) {
        padding-bottom: 80px;
    }
    @media (--viewport-desktop) {
        padding-top: 80px;
        padding-bottom: 100px;
    }

    &__title {
        @mixin display;
        margin-bottom: 56px;
        text-align: center;
        color: var(--av-nav-primary);
        padding: 0 16px;

        @media (--viewport-tablet) {
            margin-bottom: 64px;
            margin-inline-start: auto;
            margin-inline-end: auto;
            max-width: 80%;
            padding: 0;
        }
        @media (--viewport-desktop) {
            @mixin hero;
            max-width: 90%;
        }
        @media (--viewport-desktop-wide) {
            max-width: 70%;
        }

        @media (--viewport-desktop-large) {
            max-width: 80%;
        }
    }
    &__items {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        width: 50%;
        text-align: center;
        margin-bottom: 40px;

        &-name {
            color: var(--av-nav-primary);
            @mixin display;
            margin-bottom: 24px;
            font-weight: 800;

            @media (--viewport-desktop) {
                font-size: 52px;
                line-height: 64px;
            }

            @media (--viewport-desktop-wide) {
                font-size: 64px;
                line-height: 100%;
                margin-bottom: 32px;
            }

            &_selected {
                color: #609425;
            }
        }
        &-text {
            font-weight: 500;
            @mixin body;

            @media (--viewport-desktop) {
                @mixin lead;
            }
        }
        @media (--viewport-tablet) {
            width: calc(100% / 4);
            margin-bottom: 0;
        }
    }
}

.sport-logos {
    background: var(--av-nav-primary);
    padding-top: 64px;
    padding-bottom: 80px;

    &__button {
        display: block;
        margin: 48px auto auto;
    }

    &__title {
        font-weight: 500;
        @mixin display;
        margin-bottom: 24px;
        color: var(--av-inversed-primary);
        text-align: center;

        @media (--viewport-tablet) {
            margin-bottom: 52px;
            text-align: start;
        }

        @media (--viewport-desktop) {
            font-weight: 400;
            @mixin hero;
            margin-bottom: 44px;
        }
    }

    &__wrap {
        .a-picture {
            height: 120px;
        }
    }

    &__header {
        border-bottom: 1px solid var(--av-brand-secondary-light);

        &-wrap {
            display: flex;
            justify-content: space-between;
        }
        &-title {
            color: var(--av-inversed-primary);
            @mixin paragraph;
            margin-bottom: 8px;
            display: none;
            @media (--viewport-mobile-wide) {
                display: block;
            }
        }
        &-links {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            padding-bottom: 3px;
            justify-content: center;
            width: 100%;
            @media (--viewport-tablet) {
                width: auto;
                justify-content: inherit;
            }
            @media (--viewport-desktop) {
                padding-bottom: 0;
            }
        }
        &-link {
            margin-inline-start: 20px;
            margin-inline-end: 20px;
            position: relative;
            color: var(--av-inversed-primary);
            cursor: pointer;
            &:first-child {
                @media (--viewport-tablet) {
                    margin-inline-start: 0;
                }
            }

            &.active {
                &:after {
                    content: '';
                    background: var(--av-brand-secondary);
                    bottom: -7px;
                    inset-inline-start: 0;
                    inset-inline-end: 0;
                    height: 3px;
                    display: block;
                    position: absolute;
                }
            }
        }
    }

    &__body {
        margin-top: 32px;

        &-main {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 16px;
            grid-row-gap: 40px;
            align-items: baseline;

            @media (--viewport-tablet) {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }

            @media (--viewport-desktop) {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            }
        }
    }

    &__logo {
        text-align: center;
        color: var(--av-inversed-primary);

        &-name {
            font-weight: 500;
            @mixin caption;
        }

        &-subject {
            @mixin caption;
            color: var(--av-inversed-primary);
        }

        &.link {
            text-decoration: none;
            color: var(--av-link-inversed);

            &:hover {
                color: var(--av-link-inversed-hover);
            }
        }
    }
}
</style>
