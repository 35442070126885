<template>
    <div class="resource-home-engage-socials">
        <div class="container">
            <h2 class="title">
                {{ commonTranslations.engageWithUs }}
            </h2>
            <div class="social-list">
                <a-dynamic-link
                    v-for="link in socialLinks"
                    :key="link.name"
                    class="social-link"
                    :to="link.link"
                    :event="gaSocialEvent"
                    real-target="_blank"
                >
                    <a-glyph class="social-icon" :name="link.name" size="m" />
                    <div class="social-title">
                        {{ link.title }}
                    </div>
                </a-dynamic-link>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import ADynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'ResourceHomeEngageSocials',

    components: {
        ADynamicLink,
        AGlyph,
    },

    props: {
        commonTranslations: {
            type: Object,
            required: true,
        },
        socialLinks: {
            type: Array,
            required: true,
        },
        gaSocialEvent: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-home-engage-socials {
    background: var(--av-solid-brand-lightest);

    .container {
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 40px 16px 48px;

        @media (--viewport-mobile-wide) {
            padding: 40px 80px 48px;
        }

        @media (--viewport-tablet) {
            flex-direction: row;
            padding: 24px 32px;
            justify-content: space-between;
        }

        @media (--viewport-desktop-wide) {
            padding: 24px 64px;
        }
    }
}

.title {
    @mixin hero;
    color: var(--av-nav-primary);

    @media (--viewport-mobile-wide) {
        flex-basis: 100%;
    }

    @media (--viewport-desktop) {
        flex-basis: inherit;
    }

    @media (--viewport-desktop-wide) {
        flex-basis: 50%;
    }
}

.social-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;

    @media (--viewport-desktop) {
        flex-wrap: nowrap;
    }
}

.social-link {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 96px;
    height: 96px;
    flex-direction: column;
    background: var(--av-inversed-primary);
    border: 1px solid var(--av-brand-secondary-light);
    box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
    border-radius: 4px;
    cursor: pointer;
    transition: 0.25s all;

    &:hover {
        filter: brightness(110%);
        transform: translateY(-1px);
        box-shadow: 0 6px 10px rgba(36, 49, 67, 0.2);
    }
}

.social-title {
    @mixin caption-accent;
    color: var(--av-nav-primary);
}
</style>
