<template>
    <div class="acr-pb-wrapper" :class="{ shown: enabled }">
        <div class="wrapper">
            <header class="header">
                <a-picture :link="imageId" is-background />
                <div class="title" v-html="title" />
            </header>
            <main>
                <div class="description" v-html="description" />
                <div class="buttons">
                    <a-button
                        v-if="ctaPrimaryText && ctaPrimaryUrl"
                        :to="ctaPrimaryUrl"
                        :text="ctaPrimaryText"
                        type="action"
                        :event="getGAEvent(ctaPrimaryUrl)"
                        @click="$emit('ctaButtonClick')"
                    />
                    <a-button
                        v-if="ctaAuxiliaryText && ctaAuxiliaryUrl"
                        :to="ctaAuxiliaryUrl"
                        :text="ctaAuxiliaryText"
                        type="main"
                        :event="getGAEvent(ctaAuxiliaryUrl)"
                        @click="$emit('ctaButtonClick')"
                    />
                </div>
            </main>
        </div>
        <div class="close" @click="$emit('closeButtonClick')">
            <a-glyph class="close-icon" name="times-24" />
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'SPopup',
    components: {
        APicture,
        AButton,
        AGlyph,
    },
    mixins: [breakpoint],
    props: {
        enabled: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        imageId: {
            type: String,
            required: true,
        },
        ctaPrimaryUrl: {
            type: String,
            required: true,
        },
        ctaPrimaryText: {
            type: String,
            required: true,
        },
        ctaAuxiliaryUrl: {
            type: String,
            default: undefined,
        },
        ctaAuxiliaryText: {
            type: String,
            default: undefined,
        },
    },
    emits: ['closeButtonClick', 'ctaButtonClick'],
    methods: {
        getGAEvent(link) {
            return {
                event: 'Acronis',
                category: 'Popup',
                action: 'Clicked',
                label: link || '[no label]',
                context: 'click',
                content: 'Generic',
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.acr-pb-wrapper {
    position: fixed;
    inset-inline-end: 48px;
    bottom: -480px;
    max-width: 300px;

    /* WEB-43924 why do we use 499 here? */
    z-index: 499;
    opacity: 0;
    transition: all 0.5s;
    animation: pb-slide-bottom 0.5s ease-in-out alternate both;
    &.shown {
        bottom: 80px;
        opacity: 1;
        animation: pb-slide-top 0.5s ease-in-out alternate both;
    }
    .wrapper {
        border-radius: 4px;
        border: 1px solid var(--av-nav-secondary);
        box-shadow: var(--av-shadow-regular);
        overflow: hidden;
        .header {
            position: relative;
            display: flex;
            align-items: center;
            height: 125px;
            width: 300px;
            background: var(--av-nav-primary);
            border-bottom: 1px solid var(--av-nav-secondary);
            &:deep(img) {
                object-fit: cover;
            }

            .title {
                position: relative;
                margin-inline-start: 16px;
                max-width: 160px;
                @mixin lead;
                font-weight: 700;
                color: var(--av-inversed-primary);
                span {
                    @mixin body;
                    display: block;
                    line-height: 20px;
                    font-weight: 400;
                }
            }
        }
        main {
            background: var(--av-nav-primary);
            padding: 16px;
            .description {
                @mixin body;
                line-height: 20px;
                margin-bottom: 16px;
                color: var(--av-inversed-primary);
            }
            .buttons {
                display: flex;
                align-items: center;
                flex-flow: row wrap;
                gap: 16px;
                &:deep(.a-button) {
                    padding: 8px 16px;
                    font-weight: 700;
                }
            }
        }
    }
    .close {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 0;
        inset-inline-start: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:deep(.a-glyph) {
            width: 24px;
            height: 24px;
        }
    }
    @keyframes pb-slide-top {
        0% {
            transform: translateY(150%);
        }
        40% {
            scale: 1.4;
        }
        80% {
            scale: 1.2;
        }
        100% {
            scale: 1;
            transform: translateY(0%);
        }
    }
    @keyframes pb-slide-bottom {
        0% {
            scale: 1;
            transform: translateY(0%);
        }
        80% {
            scale: 0.6;
        }
        40% {
            scale: 0.4;
        }
        100% {
            transform: translateY(150%);
            scale: 0;
        }
    }
}
</style>
