import { merge } from 'lodash';

export default {
    props: {
        title: {
            type: String,
            default: undefined,
        },
        lead: {
            type: String,
            default: undefined,
        },
        translations: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            l10n: {},
        };
    },
    created() {
        this.l10n = merge({}, this.$props, this.$props.translations);
    },
    computed: {
        l10nAttrs() {
            return {
                ...this.$attrs,
                lead: this.l10n.lead,
                title: this.l10n.title,
            };
        },
    },
};
