<template>
    <section class="s-header" :class="{ ...className }">
        <s-visual :content="$props" />
        <s-content :content="$props" />
    </section>
</template>

<script>
import contentMixin from '@core/mixins/content.js';
import phoneInput from '@core/mixins/phone-input.js';
import styleMixin from '@core/mixins/style.js';
import SContent from './components/content.vue';
import SVisual from './components/visual.vue';

export default {
    name: 'SHeaderLpTrialForm',

    components: {
        SContent,
        SVisual,
    },

    mixins: [contentMixin, styleMixin, phoneInput],

    props: {
        theme: {
            type: String,
            default: 'dark',
        },

        background: {
            type: Object,
            default: null,
        },

        partner: {
            type: Object,
            default: null,
        },

        label: {
            type: String,
            default: null,
        },

        description: {
            type: Array,
            default: null,
        },

        benefits: {
            type: Array,
            default: null,
        },

        features: {
            type: Array,
            default: null,
        },

        links: {
            type: Array,
            default: null,
        },

        awards: {
            type: Object,
            default: null,
        },

        trustpilot: {
            type: Object,
            default: null,
        },

        form: {
            type: Object,
            default: null,
        },
    },

    computed: {
        className() {
            return {
                [`s-header-${this.theme}`]: this.theme,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-header {
    position: relative;
    padding-bottom: 64px;
    background: var(--av-inversed-primary);
}

.s-header-dark {
    .visual {
        background: var(--av-nav-primary);
    }
}

.s-header-light {
    &:deep(.visual-logo) {
        .a-logo {
            fill: var(--av-nav-primary);
        }
    }

    &:deep(.visual-title) {
        color: var(--av-nav-primary);
    }

    &:deep(.visual-product-name) {
        color: var(--av-nav-primary);
    }
}
</style>
