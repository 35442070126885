<template>
    <section class="acpc-cta">
        <div class="a-container">
            <div class="wrapper">
                <div class="content">
                    <strong v-if="productName" class="product-name">{{ productName }}</strong>
                    <h5 class="title">
                        {{ title }}
                    </h5>
                    <span v-if="label" class="label">{{ label }}</span>
                </div>
                <div class="actions">
                    <a-button
                        v-for="(button, i) in buttons"
                        :key="`acpc-cta-button_${i}`"
                        class="button"
                        v-bind="button"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import productMixin from '@core/mixins/product.js';

export default {
    name: 'SProductCta',
    components: {
        AButton,
    },
    mixins: [productMixin],
    props: {
        title: {
            type: String,
            default: '',
        },
        productName: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        buttons: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.acpc-cta {
    padding-top: 64px;
    padding-bottom: 64px;
    .wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        background: var(--av-nav-primary);
        color: var(--av-inversed-primary);
        overflow: hidden;
        border-radius: 16px;
        padding: 48px 32px 48px;
        @media (--viewport-tablet) {
            padding: 48px 32px;
        }
        @media (--viewport-desktop-wide) {
            padding: 48px 80px;
        }
        .content {
            width: 100%;
            text-align: center;
            @media (--viewport-desktop) {
                max-width: calc(100% - 280px);
                text-align: start;
            }
            .product-name {
                @mixin title-accent;
                display: block;
                margin-bottom: 8px;
                background: linear-gradient(90deg, rgb(148, 206, 241) 0%, rgb(0, 101, 227) 100%);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            .title {
                @mixin display;
                @media (--viewport-desktop) {
                    @mixin hero;
                }
            }
            .label {
                @mixin lead-accent;
                display: block;
                margin-top: 32px;
            }
        }
        .actions {
            width: 100%;
            margin: 32px auto 0;
            @media (--viewport-tablet) {
                max-width: 80%
            }
            @media (--viewport-desktop) {
                max-width: 200px;
                margin: 0;
            }
            .button {
                width: 100%;
                margin-bottom: 24px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>
