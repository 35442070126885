<template>
    <div v-if="isVisibleCTA" :class="['cta', { 'cta-promo': hasDiscount }]">
        <template v-if="hasLabel">
            <template v-if="useNewLabelDesign">
                <dynamic-link
                    v-if="settings.labelTo"
                    :to="settings.labelTo"
                    :event="{ ...settings.labelEvent, label: settings.labelTo, content: label }"
                    native-type="a"
                >
                    <a-label
                        class="cta-label-new"
                        :variant="settings.labelVariant || 'success'"
                        :layout="settings.labelLayout || 'subtle'"
                        :theme="settings.labelTheme"
                        :size="settings.labelSize"
                    >
                        <a-glyph v-if="hasDiscount && glyph" :name="glyph" size="s" />
                        <span v-html="label" />
                    </a-label>
                </dynamic-link>
                <a-label
                    v-else
                    class="cta-label-new"
                    :variant="settings.labelVariant || 'success'"
                    :layout="settings.labelLayout || 'subtle'"
                    :theme="settings.labelTheme"
                    :size="settings.labelSize"
                >
                    <a-glyph v-if="hasDiscount && glyph" :name="glyph" size="s" />
                    <span v-html="label" />
                </a-label>
            </template>
            <template v-else>
                <dynamic-link
                    v-if="settings.labelTo"
                    :to="settings.labelTo"
                    :event="{ ...settings.labelEvent, label: settings.labelTo, content: label }"
                    native-type="a"
                >
                    <div class="cta-label">
                        <a-glyph v-if="hasDiscount && glyph" :name="glyph" />
                        <span v-html="label" />
                    </div>
                </dynamic-link>
                <div v-else class="cta-label">
                    <a-glyph v-if="hasDiscount && glyph" :name="glyph" />
                    <span v-html="label" />
                </div>
            </template>
        </template>
        <div v-if="hasDescription" class="cta-description">
            <dynamic-link v-if="settings.to" :to="settings.to" native-type="a">
                <span v-html="description" />
            </dynamic-link>
            <p v-else v-html="description" />
        </div>

        <div v-if="trialPage && isPromoActive" class="cta-trial">
            <p v-if="trialPage.label" class="cta-trial-label">
                <a-glyph v-if="trialPage.glyph" :name="trialPage.glyph" />
                <span v-html="trialPage.label" />
            </p>
            <p v-if="trialPage.description" v-html="trialPage.description" />
        </div>
    </div>
</template>

<script>
import { merge } from 'lodash';

import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALabel from '@core/components/label/label.vue';
import analytics from '@core/mixins/analytics.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import currency from '@core/mixins/currency.js';
import productMixin from '@core/mixins/product.js';
import purchasing from '@core/mixins/purchasing.js';

export default {
    name: 'AProductCta',

    components: {
        DynamicLink,
        AGlyph,
        ALabel,
    },

    mixins: [productMixin, purchasing, currency, analytics, cleverbridge],

    props: {
        settings: {
            type: Object,
            default: null,
        },
        useNewLabelDesign: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            label: '',
            glyph: '',
            description: '',
            hasDiscount: false,
            isPromoActive: false,
        };
    },

    computed: {
        isVisibleCTA() {
            return this.hasLabel || this.hasDescription || this.trialPage;
        },

        hasLabel() {
            return this.settings.hasLabel && this.label;
        },

        hasDescription() {
            return this.settings.hasDescription && this.description;
        },

        trialPage() {
            return this.settings?.trialPageCTA;
        },
    },

    created() {
        const productId = this.settings?.productId || this.internalProductId;

        const pageSettingsCTA = this.productItems[productId]?.parameters?.common?.productPageCTA;
        const settings = merge(pageSettingsCTA, this.settings);
        const isPromoActive = !settings.doNotApplyPromo && this.checkPromo(settings.promo);
        const cleverBridgeCartID = settings.lisenseID || settings.cleverBridgeCartID;

        this.label = settings?.label || '';
        this.description = settings?.description || '';

        if (!cleverBridgeCartID || settings.isHidden || this.trialPage) {
            this.isPromoActive = isPromoActive;
            return;
        }

        const license = this.licenseData({ cleverBridgeCartID, promo: { isPromoActive, ...settings.promo } });

        const discrount = license.discount_absolute || 0;
        const period = settings.isMonthlyPrice ? 12 : 1;

        const text = (isPromoActive && discrount ? settings.promo?.description : settings.description) || '';
        this.hasDiscount = isPromoActive && discrount;

        const price = license.price ? this.setCurrency(license.price / period, { productId }) : '';
        const priceFull = this.hasDiscount ? this.setCurrency((license.price + discrount) / period, { productId }) : '';

        this.description = text
            .replace(/XX.XX/i, price)
            .replace(/@priceFull/i, priceFull)
            .replace(/@price/i, price);

        if (this.settings.hasLabel) {
            this.glyph = settings?.promo?.glyph || settings?.glyph || '';
            this.label = this.hasDiscount ? settings?.promo?.label : (settings?.label || '');
        }
    },
};
</script>

<style lang="postcss" scoped>
.cta {
    @mixin title;
    color: var(--av-nav-primary);
}

.cta-label {
    display: flex;
    font-weight: 400;
    align-items: center;
    color: var(--av-fixed-success);

    .a-glyph {
        width: 24px;
        height: 24px;
        margin-inline-end: 8px;
        fill: var(--av-fixed-success);
    }
}

.cta-description {
    display: flex;
    flex-wrap: wrap;
    margin: 8px 0 0;
    align-items: center;

    del {
        color: var(--av-fixed-light);
    }
}

.cta-trial {
    @mixin body;
    padding-inline-start: 32px;
    color: var(--av-fixed-secondary);

    a {
        color: var(--av-brand-secondary);
    }

    .a-glyph {
        top: 0;
        inset-inline-start: -32px;
        width: 24px;
        height: 24px;
        position: absolute;
        fill: var(--av-fixed-success);
    }
}

.cta-trial-label {
    font-weight: 600;
    position: relative;
}
</style>
