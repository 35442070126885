<template>
    <div class="resource-gated-asset-wrapper" :class="{ 'resource-gated-asset-wrapper--doc': !isVideo }">
        <template v-if="isVideo">
            <a-link
                v-if="isMobile"
                class="mobile-back-button"
                :text="commonTranslations.backToResourceLibrary"
                glyph="arrow-left"
                glyph-position="left"
                :to="backToSearchUrl"
            />
            <resource-breadcrumbs
                v-else
                :breadcrumbs="breadcrumbs"
                :common-translations="commonTranslations"
            />
        </template>
        <div v-else class="top-wrapper">
            <div class="top-wrapper-inner">
                <div class="top-wrapper-left">
                    <a-link
                        class="back-button"
                        :text="commonTranslations.backToResourceLibrary"
                        glyph="arrow-left"
                        glyph-position="left"
                        :to="backToSearchUrl"
                    />
                    <div class="audience-title">
                        {{ audienceTitle }}
                    </div>
                    <div class="asset-type">
                        {{ assetType.text }}
                    </div>
                </div>
            </div>
        </div>
        <div class="s-slice a-container resource-gated-asset" :class="`resource-gated-asset--${pageType}`">
            <div class="padding-wrapper">
                <h1 class="title">
                    {{ resource.title }}
                </h1>

                <resource-available-locales
                    v-if="availableLocales.length"
                    :locales="availableLocales"
                    :common-translations="commonTranslations"
                    is-gated-asset
                />

                <div class="content-wrapper">
                    <resource-article-body
                        v-if="body.length"
                        class="article-body"
                        :blocks="body"
                        text-align="left"
                    />
                    <div v-if="isVideo && isDesktop" class="content-wrapper-video" @click="onLockedVideoClick">
                        <resource-video
                            :ref="`video-desktop-${ytId}`"
                            class="resource-video"
                            :locked="formLocked"
                            :yt-id="ytId"
                        />
                    </div>
                </div>

                <div class="share-block">
                    <div class="share-block-text">
                        {{ commonTranslations.shareText }}
                    </div>
                    <a-social :social="socialsShareLinks" glyph-size="s" :event="gaSocialEvent" />
                </div>
            </div>

            <template v-if="isVideo">
                <div
                    v-if="resource.url && !['desktop', 'desktopWide', 'desktopLarge', ''].includes(currentBreakpoint)"
                    class="embed-video"
                    @click="onLockedVideoClick"
                >
                    <resource-video
                        :ref="`video-${ytId}`"
                        :locked="formLocked"
                        :yt-id="ytId"
                        class="resource-video"
                        @click="onLockedVideoClick"
                    />
                </div>

                <transition name="fade">
                    <resource-gated-form
                        v-if="formLocked && formVisible"
                        :asset-title="assetType.text"
                        :asset-url="assetUrl"
                        :page-type="pageType"
                        :form-state="formState"
                        :sfdc-campaign="resource.salesforce_campaign_id"
                        @change="onFormStateChange"
                        @close="formVisible = false"
                    />
                </transition>
            </template>

            <template v-else>
                <div :class="{ 'right-side': isDesktop }">
                    <div v-if="resource.cover_image_id" class="cover-image">
                        <a-picture
                            :link="`@${resource.cover_image_id}`"
                            :alt="resource.seo_title"
                            fit="cover"
                            is-background
                        />
                    </div>

                    <transition name="fade">
                        <resource-gated-form
                            v-if="formLocked || !isVideo"
                            class="gated-form"
                            :asset-title="assetType.text"
                            :asset-url="assetUrl"
                            :page-type="pageType"
                            :form-state="formState"
                            :sfdc-campaign="resource.salesforce_campaign_id"
                            @change="onFormStateChange"
                        />
                    </transition>
                </div>
            </template>
        </div>
        <a-modal
            v-if="ytId"
            :id="ytId"
            is-youtube
            type="youtube"
        >
            <a-youtube :yt-id="ytId" :params="{ autoplay: 1 }" />
        </a-modal>
    </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import ALink from '@core/components/link/link.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import ASocial from '@core/components/social/social.vue';
import AYoutube from '@core/components/youtube/youtube.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import ResourceBreadcrumbs from '@core/slices/resource-center/resource-breadcrumbs/resource-breadcrumbs.vue';
import ResourceAvailableLocales from '@core/slices/resource-center/shared-components/resource-available-locales.vue';
import { LOCALE_DEFAULT } from '@model/const/locales';
import {
    STORE_SLICE_NAME,
    RC_GATED_FORM_DEFAULTS,
    RC_RESOURCE_STORAGE_UNLOCKED_KEY,
    RC_GATED_STATE_LOCKED,
    RC_GATED_STATE_UNLOCKED,
    RC_ROOT_URL,
    RC_LOCAL_STORAGE_SEARCH_FILTERS,
} from '@model/const/resource-center';
import commonUtils from '@utils/common';
import ResourceGatedForm from '../resource-gated-form/resource-gated-form.vue';
import ResourceArticleBody from '../shared-components/resource-article-body.vue';
import ResourceVideo from './components/resource-video.vue';

export default {
    name: 'SResourceGatedAsset',

    components: {
        ALink,
        AModal,
        AYoutube,
        APicture,
        ASocial,
        ResourceBreadcrumbs,
        ResourceArticleBody,
        ResourceGatedForm,
        ResourceAvailableLocales,
        ResourceVideo,
    },

    mixins: [breakpoint],

    props: {
        resource: {
            type: Object as any,
            required: true,
        },
        breadcrumbs: {
            type: Array,
            required: true,
        },
        commonTranslations: {
            type: Object as any,
            required: true,
        },
        assetType: {
            type: Object as any,
            required: true,
        },
        socialsShareLinks: {
            type: Array,
            default: () => ([]),
        },
        pageType: {
            type: String,
            required: true,
        },
        gaSocialEvent: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        formVisible: false,
        form: RC_GATED_FORM_DEFAULTS,
        formState: RC_GATED_STATE_UNLOCKED,
    }),

    computed: {
        ...mapGetters('config', ['$config']),

        locale() {
            return this.$route.params.locale || LOCALE_DEFAULT;
        },

        ytId() {
            if (!this.resource.url) return null;
            return this.resource.url.split('/').pop();
        },

        youtubeUrl() {
            return this.formState === RC_GATED_STATE_UNLOCKED
                ? `${this.resource.url}?autoplay=1&enablejsapi=1`
                : this.resource.url;
        },

        availableLocales() {
            return this.$store.getters['resourceCenter/getAvailableLocales'](this.locale);
        },

        backToSearchUrl() {
            const url = `${RC_ROOT_URL}/search/`;

            if (process.env.VUE_ENV === 'server') return url;

            const savedFilters = JSON.parse(localStorage.getItem('resourceSearchFilters') || '{}');

            if (!Object.keys(savedFilters).length) return url;

            const queryParams = Object.entries((savedFilters))
                .map(([key, val]) => [key, Array.isArray(val) ? val : [val]])
                .flatMap(([key, val]: any) => val.map((i) => `${key}=${i}`))
                .join('&');

            localStorage.removeItem(RC_LOCAL_STORAGE_SEARCH_FILTERS);

            return `${url}#${queryParams}`;
        },

        body() {
            if (!this.resource.description) return [];

            const body = JSON.parse(this.resource.description);
            return body.blocks;
        },

        audienceTitle() {
            return this.resource.audiences[0]?.title;
        },

        assetUrl() {
            if (this.resource.url) return this.resource.url;

            if (!this.resource.file_id) return null;

            const storageUrl = this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE;
            return `${storageUrl}/downloads/${this.resource.file_id}`;
        },

        isVideo() {
            return this.pageType === 'video';
        },

        formStorageState: {
            get() {
                return localStorage.getItem(`${RC_RESOURCE_STORAGE_UNLOCKED_KEY}${this.resource.id}`) ||
                    RC_GATED_STATE_LOCKED;
            },
            set(value: string) {
                localStorage.setItem(`${RC_RESOURCE_STORAGE_UNLOCKED_KEY}${this.resource.id}`, value);
                this.formState = value;
            },
        },

        formLocked() {
            return this.formState !== RC_GATED_STATE_UNLOCKED;
        },
    },

    watch: {
        formLocked(newValue) {
            if (!newValue) this.formVisible = false;
        },
    },

    mounted() {
        this.formState = this.formStorageState;
    },

    methods: {
        async onFormStateChange(state) {
            this.formStorageState = state;
            if (state === RC_GATED_STATE_UNLOCKED) {
                await this.$nextTick();
                this.onLockedVideoClick();
            }
        },

        onLockedVideoClick() {
            if (!this.formLocked) {
                this.triggerVideoClick();
                return;
            }
            this.formVisible = true;

            commonUtils.scrollTop();
        },

        triggerVideoClick() {
            const videoRef = this.$refs[`video-${this.ytId}`];
            const videoRefDesktop = this.$refs[`video-desktop-${this.ytId}`];
            videoRef?.triggerClick();
            videoRefDesktop?.triggerClick();
        },
    },

    async serverPrefetch(): Promise<void> {
        const locale = this.$route.params.locale;
        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: STORE_SLICE_NAME, locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'a-phone-number-input', locale }),
        ]);
    },
};
</script>

<style lang="postcss" scoped>
.resource-gated-asset-wrapper {
    &--doc {
        @media (--viewport-tablet) {
            margin-top: 32px;
        }
    }

    &:deep(.a-breadcrumbs) {
        @media (--viewport-tablet) {
            margin-inline-start: 32px;
        }
        @media (--viewport-desktop-wide) {
            margin-inline-start: 64px;
        }
        @media (--viewport-desktop-large) {
            margin-inline-start: 0;
        }

        &__item {
            &:last-child {
                display: none;
            }

            &:nth-last-child(2) {
                svg {
                    display: none;
                }
            }
        }
    }
}

.resource-gated-asset {
    position: relative;
    color: var(--av-nav-primary);
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media (--viewport-desktop) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 32px;
        gap: 24px;
    }

    @media (--viewport-desktop-wide) {
        padding: 0 64px;
    }

    @media (--viewport-desktop-large) {
        padding: 0;
    }

    .padding-wrapper {
        width: 100%;
        padding: 0 16px;

        @media (--viewport-tablet) {
            padding: 0 32px;
        }

        @media (--viewport-desktop) {
            padding: 0;
        }
    }

    &--video {
        @media (--viewport-desktop) {
            margin-top: 0;
        }

        .audience-title {
            margin-top: 30px;
        }

        .title {
            margin-top: 32px;
            @mixin display;

            @media (--viewport-tablet) {
                @mixin hero;
            }
        }

        .embed-video {
            position: relative;
            margin-top: 16px;
            margin-bottom: 80px;

            .resource-video {
                width: 328px;
                height: 178px;
                border: 0;
                border-radius: 3px;
                box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);
                margin: 0 auto;

                @media (--viewport-mobile-wide) {
                    width: 472px;
                    height: 260px;
                }
                @media (--viewport-tablet) {
                    border-radius: 5px;
                }
                @media (--viewport-desktop-wide) {
                    width: 568px;
                    height: 313px;
                    border-radius: 6px;
                }
            }
        }

        .content-wrapper {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;

            @media (--viewport-desktop) {
                gap: 16px;

                .article-body {
                    margin-top: -16px;
                }
            }

            .resource-video {
                width: 472px;
                height: 260px;
                border: 0;
                border-radius: 3px;
                @media (--viewport-tablet) {
                    border-radius: 5px;
                }
                @media (--viewport-desktop-wide) {
                    width: 568px;
                    height: 313px;
                    border-radius: 6px;
                }
            }
        }
    }
}

.resource-breadcrumbs {
    margin-top: 32px;
}

.audience-title {
    margin-top: 24px;
    @mixin paragraph;
    color: var(--av-nav-primary);
}

.asset-type {
    @mixin display-accent;
    color: var(--av-nav-primary);
}

.title {
    margin-top: 24px;
    @mixin title;

    @media (--viewport-tablet) {
        @mixin display;
    }

    @media (--viewport-desktop) {
        @mixin hero;
    }
}

.article-body {
    margin-top: 24px;
}

.share-block {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;

    &-text {
        margin-top: 5px;
        @mixin paragraph-accent;
    }

    @media (--viewport-desktop) {
        margin-bottom: 80px;
        display: flex;
        justify-content: flex-start;
    }
}

.cover-image {
    position: relative;
    width: 100%;
    height: 174px;
    margin-top: 100px;
    overflow: hidden;
    @media (--viewport-tablet) {
        width: 458px;
        margin-top: 48px;
    }

    @media (--viewport-desktop) {
        margin-top: 0;
    }

    &:deep(.a-picture) {
        width: 100%;
        height: 100%;
        position: absolute;
        transform: none;
    }
}

.gated-form {
    margin-top: -4px;
    margin-bottom: 80px;
}

.right-side {
    display: flex;
    flex-direction: column;
    width: 458px;
    align-items: center;
    justify-content: flex-start;

    @media (--viewport-desktop) {
        margin-top: -144px;
    }
}

.content-wrapper-video {
    position: relative;
    width: 328px;
    height: 178px;
    flex-shrink: 0;
    background: var(--av-brand-light);
    border-radius: 3px;
    box-shadow: 0 10px 20px 0 var(--av-fixed-lightest);

    @media (--viewport-mobile-wide) {
        width: 472px;
        height: 260px;
    }

    @media (--viewport-tablet) {
        border-radius: 5px;
    }

    @media (--viewport-desktop-wide) {
        width: 568px;
        height: 313px;
        border-radius: 6px;
    }
}

.video-locker {
    position: absolute;
    cursor: pointer;
    width: 328px;
    height: 178px;

    @media (--viewport-mobile-wide) {
        width: 472px;
        height: 260px;
    }

    @media (--viewport-desktop-wide) {
        width: 568px;
        height: 313px;
    }
}

.top-wrapper--video {
    border-bottom: 1px solid rgba(0, 101, 227, 0.25);
    background: linear-gradient(180deg, rgba(38, 104, 197, 0.15) 0%, rgba(38, 104, 197, 0) 100%);
}

.top-wrapper-inner {
    display: flex;
    flex-direction: column;
    padding: 32px 16px 0 16px;
    max-width: 1312px;
    margin: 0 auto;

    @media (--viewport-tablet) {
        padding: 0 32px;
    }

    @media (--viewport-desktop-wide) {
        padding: 0 64px;
    }

    @media (--viewport-desktop-large) {
        padding: 0;
    }

    &--video {
        padding: 32px 16px;

        @media (--viewport-tablet) {
            flex-direction: row;
            justify-content: space-between;
            padding: 0 32px;
        }

        @media (--viewport-desktop) {
            padding: 0 64px;
        }

        @media (--viewport-desktop-wide) {
            padding: 0 118px 0 64px;
        }

        @media (--viewport-desktop-large) {
            padding: 0 118px 0 0;
        }
    }
}

.back-button {
    display: flex;
    align-items: center;
    @mixin paragraph-accent;
    color: var(--av-brand-primary);
}

.mobile-back-button {
    margin-top: 32px;
    margin-inline-start: 16px;
    @mixin paragraph-accent;
    color: var(--av-brand-primary);
}
</style>
