<template>
    <div class="careers">
        <s-intro v-bind="{...intro, jobs}" />
        <s-quote v-bind="quote" />
        <s-locations v-bind="locations" />
        <s-features v-bind="features" />
        <s-life v-bind="life" />
        <!-- <s-socials v-bind="socials" /> -->
    </div>
</template>

<script>
import SFeatures from './slices/features.vue';
import SIntro from './slices/intro.vue';
import SLife from './slices/life.vue';
import SLocations from './slices/locations.vue';
import SQuote from './slices/quote.vue';

export default {
    name: 'SCareers',

    components: {
        SIntro,
        SQuote,
        SFeatures,
        SLocations,
        SLife,
    },

    props: {
        intro: {
            type: Object,
            required: true,
        },
        quote: {
            type: Object,
            required: true,
        },
        features: {
            type: Object,
            required: true,
        },
        locations: {
            type: Object,
            required: true,
        },
        life: {
            type: Object,
            required: true,
        },
        socials: {
            type: Object,
            required: true,
        },
    },

    computed: {
        jobsByLocation() {
            return this.$store.state.job?.data || {};
        },

        jobs() {
            return Object.values(this.jobsByLocation)
                .flatMap((department) => Object.values(department).flat());
        },
    },

    async serverPrefetch() {
        await this.$store.dispatch('job/getAll');
    },
};
</script>

<style lang="postcss" scoped>
.careers {
    display: flex;
    flex-direction: column;
}
</style>
