<template>
    <main class="main-page">
        <template v-for="(item, itemIndex) in list">
            <component
                :is="`s-${item.name}`"
                :key="`s-${itemIndex}`"
                :content="{...item, tabActive: tabActive}"
                @setTabActive="setTabActive"
            />
        </template>
    </main>
</template>

<script>
import { merge } from 'lodash';
import SAcademy from './slices/academy.vue';
import SAnnounce from './slices/announce.vue';
import SAnnounces from './slices/announces.vue';
import SAwards from './slices/awards.vue';
import SBlog from './slices/blog.vue';
import SCasestudy from './slices/casestudy.vue';
import SHeader from './slices/header/header.vue';
import SPartners from './slices/partners.vue';
import SPlatform from './slices/platform.vue';
import SServices from './slices/services.vue';
import SStats from './slices/stats.vue';

export default {
    name: 'SMainPage',
    components: {
        SAcademy,
        SAnnounce,
        SAnnounces,
        SAwards,
        SBlog,
        SCasestudy,
        SHeader,
        SPartners,
        SPlatform,
        SServices,
        SStats,
    },
    props: {
        slices: {
            type: Array,
            default: null,
        },
        translations: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            list: {},
            tabActive: 0,
        };
    },
    beforeMount() {
        this.list = merge(this.slices, this.translations);
    },
    serverPrefetch() {
        const list = this.slices
            .filter((slice) => slice.name === 'awards' && slice.list?.length)
            .flatMap((i) => i.list);
        [...new Set(list)].forEach((aid) => this.$store.dispatch('awards/getAward', aid));
    },
    methods: {
        setTabActive(id) {
            this.tabActive = id;
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page {
    position: relative;
    padding: 0 0 48px;
}
</style>
