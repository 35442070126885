<template>
    <div class="event-container" :class="`event-container--${theme}`">
        <div class="container">
            <slot @progressChanged="$emit('progressChanged', $event)" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventContainer',
    props: {
        theme: {
            type: String,
            default: 'light',
            validator: (value) => !value || ['light', 'dark'].includes(value),
        },
    },
    emits: ['progressChanged'],
};
</script>

<style lang="postcss" scoped>
.event-container {
    .container {
        margin-inline-start: auto;
        margin-inline-end: auto;
        padding-inline-start: 16px;
        padding-inline-end: 16px;
        max-width: 1440px;

        @media (--viewport-tablet) {
            padding-inline-start: 32px;
            padding-inline-end: 32px;
        }

        @media (--viewport-desktop-wide) {
            padding-inline-start: 64px;
            padding-inline-end: 64px;
        }
    }

    &--dark {
        padding-top: 1px;
        background: var(--av-nav-primary);

        @media (--viewport-mobile-wide) {
            padding-top: 40px;
        }

        @media (--viewport-tablet) {
            padding-top: 28px;
        }
    }
}
</style>
