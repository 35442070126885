<template>
    <section
        :id="id"
        class="s-slice s-solutions-list"
        :class="{ 's-solutions-list_open': isShowAllCards || isShowAllCardsClick, ...basicClassName }"
    >
        <div class="a-container">
            <div class="s-solutions-list__wrap">
                <div v-for="(card, index) in cardsPaginated" :key="index" class="s-solutions-list__item">
                    <s-solutions-card :title="card.title" :link="card.link" :text="card.text" />
                </div>
            </div>
            <div class="s-solutions-list__showmore-wrapper">
                <div
                    v-if="cards.length > solutionsItemsCount"
                    class="s-solutions-list__showmore"
                    type="button"
                    @click="isShowAllCardsClick = !isShowAllCardsClick"
                >
                    <span class="s-solutions-list__showmore-text">
                        {{ isShowAllCards || isShowAllCardsClick ? showLessText : showMoreText }}
                    </span><a-glyph class="s-solutions-list__showmore-icon" name="drop-down-arrow" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import SSolutionsCard from '@core/components/solutions-card/solutions-card.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SSolutionsList',
    components: {
        SSolutionsCard,
        AGlyph,
    },
    mixins: [contentMixin, styleMixin, breakpoint],
    props: {
        cards: {
            type: Array,
            required: true,
        },
        showMoreText: {
            type: String,
            required: true,
        },
        showLessText: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isShowAllCardsClick: false,
        };
    },
    computed: {
        solutionsItemsCount() {
            let ITEMS_COUNT = 15;
            if (this.mobile) {
                ITEMS_COUNT = 5;
            } else if (this.tablet) {
                ITEMS_COUNT = 10;
            }
            return ITEMS_COUNT;
        },
        isShowAllCards() {
            return this.solutionsItemsCount >= this.cards.length;
        },
        cardsPaginated() {
            return this.isShowAllCards || this.isShowAllCardsClick
                ? this.cards
                : this.cards.slice(0, this.solutionsItemsCount);
        },

        solutionsCards() {
            let CARDS_COUNT = 15;
            if (this.mobile) {
                CARDS_COUNT = 5;
            } else if (this.tablet) {
                CARDS_COUNT = 10;
            }
            return this.cards.slice(0, CARDS_COUNT);
        },
        mobile() {
            return ['mobile'].includes(this.currentBreakpoint);
        },
        tablet() {
            return ['tablet', 'mobileWide'].includes(this.currentBreakpoint);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-solutions-list {
    padding: 0;
    &_open {
        .s-solutions-list__showmore-icon {
            transform: rotate(-180deg);
        }
        .s-solutions-list__showmore-wrapper:after {
            display: none;
        }
    }
    &__wrap {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: 1fr;
        @media (--viewport-mobile-wide) {
            grid-template-columns: 1fr 1fr;
        }
        @media (--viewport-desktop) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    &__showmore {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        cursor: pointer;
        &-wrapper {
            display: flex;
            justify-content: center;
            position: relative;
            &::after {
                content: '';
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
                position: absolute;
                inset-inline-start: 0;
                inset-inline-end: 0;
                height: 200px;
                top: -200px;
            }
        }
        &-text {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            color: var(--av-brand-secondary);
            margin-inline-end: 19px;
            @media (--viewport-tablet) {
                font-size: 24px;
                line-height: 32px;
            }
        }
        &-icon {
            width: 10px;
            height: 5px;
        }
    }
}
</style>
