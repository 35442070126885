<template>
    <div class="resource-home-page-navigation">
        <div class="container">
            <template v-if="needRenderDesktop">
                <div
                    v-for="(item, i) in items"
                    :key="i"
                    class="item-wrapper"
                    @click="toggleItem(i)"
                >
                    <a-link
                        class="item sub-item-desktop"
                        :class="{ 'item--opened': item.isOpened }"
                        v-bind="item"
                        :text="item.title"
                        :glyph-position="item.glyphPosition || 'left'"
                        :glyph="item.glyph || 'arrow-narrow-right'"
                        glyph-size="xxs"
                    />
                    <div v-show="item.isOpened" class="sub-items-wrapper">
                        <div v-if="item.subtitle" class="subtitle">
                            {{ item.subtitle }}
                        </div>
                        <a
                            v-for="(subItem, j) in item.subItems"
                            :key="j"
                            :href="getUrl(subItem)"
                            class="sub-item"
                            @click="($event) => openPage(subItem, $event)"
                        >
                            <span>{{ subItem.title }}</span>
                            <a-glyph v-if="isExternalLink(subItem.to)" name="external-link-round" />
                        </a>
                    </div>
                </div>
            </template>
            <template v-else>
                <a-link
                    class="toggle-navigation"
                    :class="{ 'toggle-navigation--opened': isOpened }"
                    :text="commonTranslations.navigation"
                    glyph="arrow-narrow-up"
                    @click="isOpened = !isOpened"
                />
                <div class="items-wrapper" :class="{ 'items-wrapper--opened': isOpened }">
                    <template v-if="openedItem">
                        <a-link
                            class="sub-item back-button"
                            :text="commonTranslations.back"
                            glyph-position="left"
                            glyph="arrow-left"
                            @click="toggleItem(-1)"
                        />
                        <div class="separator" />
                        <div v-if="openedItem.subtitle" class="subtitle">
                            {{ openedItem.subtitle }}
                        </div>

                        <a
                            v-for="(subItem, j) in openedItem.subItems"
                            :key="j"
                            :href="getUrl(subItem)"
                            class="sub-item sub-item-mobile"
                            @click="($event) => openPage(subItem, $event)"
                        >
                            <span>{{ subItem.title }}</span>
                            <a-glyph v-if="isExternalLink(subItem.to)" name="external-link-round" />
                        </a>
                    </template>
                    <template v-else>
                        <div
                            v-for="(item, i) in items"
                            :key="i"
                            class="item-wrapper"
                            @click="toggleItem(i)"
                        >
                            <a-link
                                class="item"
                                :class="{ 'item--opened': item.isOpened }"
                                v-bind="item"
                                :text="item.title"
                                :glyph-position="item.glyphPosition || 'left'"
                                :glyph="item.glyph || 'arrow-narrow-right'"
                                glyph-size="xxs"
                            />
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'ResourceHomePageNavigation',

    components: {
        ALink,
        AGlyph,
    },

    mixins: [breakpoint],

    props: {
        links: {
            type: Array,
            required: true,
        },
        commonTranslations: {
            type: Object as any,
            required: true,
        },
    },

    data: () => ({
        isOpened: false,
        items: [],
    }),

    computed: {
        locale() {
            return this.$route?.params.locale || 'en-us';
        },

        openedItem() {
            return this.items.find((item) => item.isOpened);
        },

        needRenderDesktop() {
            return this.currentBreakpoint === '' || this.isDesktop;
        },
    },

    created() {
        this.items = this.links.map((link) => ({ ...link, isOpened: false }));
    },

    mounted() {
        document.addEventListener('click', this.handleComponentClick);
    },

    beforeDestroy() {
        document.removeEventListener('click', this.handleComponentClick);
    },

    methods: {
        getUrl(item) {
            return this.isExternalLink(item.to) ? item.to : `/${this.locale}${item.to}`;
        },

        openPage(item, event = null) {
            event?.preventDefault();
            window.open(item.to, this.isExternalLink(item.to) ? '_blank' : '_self');
        },

        toggleItem(itemIndex) {
            this.items.forEach((item, index) => {
                if (index !== itemIndex) this.items[index].isOpened = false;
                else this.items[index].isOpened = !item.isOpened;
            });
        },

        handleComponentClick(event) {
            // check for click outside any item to close dropdowns
            if (!this.isDesktop) return;

            const itemClass = 'item-wrapper';
            let elementToCheck = event.target;

            while (elementToCheck) {
                if (elementToCheck.classList.contains(itemClass)) return;
                elementToCheck = elementToCheck.parentElement;
            }

            this.toggleItem(-1);
        },

        isExternalLink(link) {
            return !link.includes('/resource-center/');
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-home-page-navigation {
    width: 100%;
    background: var(--av-solid-brand-accent);

    .container {
        max-width: 1440px;
        margin: 0 auto;
        z-index: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;

        @media (--viewport-desktop) {
            padding: 16px 32px;
            justify-content: space-between;
        }

        @media (--viewport-desktop-wide) {
            padding: 16px 64px;
        }
    }

    .a-link {
        width: 100%;

        &:deep(> span) {
            display: inline-flex;
        }

        &:deep(span) {
            width: 100%;
        }
    }

    &:deep(.sub-item-mobile .a-glyph) {
        top: -3px;
    }
}

.toggle-navigation {
    text-align: center;

    &:deep(.a-link__content) {
        @mixin paragraph-accent;
        background: transparent !important;
    }

    &:deep(.a-glyph) {
        margin-inline-start: auto;
        will-change: transform;
        transition: transform 0.1s linear;
        transform: rotate(180deg) translateY(-1px);

        @media (--viewport-desktop) {
            transform: none;
        }
    }

    &--opened {
        &:deep(.a-glyph) {
            transform: none;

            @media (--viewport-desktop) {
                transform: rotate(90deg);
            }
        }
    }
}

.item-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:last-child {
        .sub-items-wrapper {
            transform: translateX(-52px);
        }
    }
}

.item {
    @mixin paragraph-accent;
    color: var(--av-brand-primary);
    position: relative;

    &:deep(.a-link__content) {
        background: transparent !important;
    }

    &:deep(.a-glyph) {
        will-change: transform;
        transition: transform 0.1s linear;
        transform: none;

        @media (--viewport-desktop) {
            top: 8px;
            inset-inline-start: 10px;
        }
    }

    &--opened {
        &:deep(.a-glyph) {
            transform: rotate(90deg);
        }
    }
}

.sub-items-wrapper {
    position: absolute;
    width: auto;
    min-width: 232px;
    top: 36px;
    inset-inline-start: 0;
    padding: 24px 24px 34px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    background: var(--av-inversed-primary);
    box-shadow: 0 10px 20px var(--av-fixed-lighter);
    border-radius: 4px;
    border: 1px solid var(--av-brand-secondary-light);
}

.subtitle {
    @mixin body-accent;
    margin-bottom: 8px;
}

.sub-item {
    position: relative;
    display: block;
    width: 100%;
    gap: 14px;
    color: var(--av-brand-primary);
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;

    .a-glyph {
        flex-shrink: 0;
        position: absolute;
        inset-inline-end: 0;
        top: 4px;
    }

    &.sub-item-mobile {
        font-size: 16px;
    }
}

.items-wrapper {
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 338px;
    padding: 24px 40px;
    transform: translateY(-5px);
    border-radius: 4px;
    background: var(--av-inversed-primary);
    border: 1px solid var(--av-brand-secondary-light);
    box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.15s ease;

    &--opened {
        opacity: 1;
        visibility: visible;
    }

    .item-wrapper {
        margin-inline-start: -7px;
    }

    &:deep(.a-link__content) {
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
    }

    &:deep(.a-glyph) {
        transform: translate(7px, 8px);
    }

    .separator {
        width: 100%;
        height: 2px;
        background: var(--av-brand-secondary-accent);
    }

    .subtitle {
        font-size: 16px;
        font-weight: bold;
        color: var(--av-nav-primary);
    }

    .back-button {
        &:deep(.a-glyph) {
            transform: none;
            margin: 0;
        }

        &:deep(.a-link__content) {
            @mixin lead;
            font-weight: 700;
        }
    }
}
</style>
