import { render, staticRenderFns } from "./integration-details.vue?vue&type=template&id=04d74960&scoped=true"
import script from "./integration-details.vue?vue&type=script&lang=js"
export * from "./integration-details.vue?vue&type=script&lang=js"
import style0 from "./integration-details.vue?vue&type=style&index=0&id=04d74960&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04d74960",
  null
  
)

export default component.exports