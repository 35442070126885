<template>
    <div v-if="title || lead" class="a-slice-header">
        <component :is="titleTag" v-if="title" class="a-slice-header__title">
            {{ title }}
        </component>
        <a-dangerous-html
            v-if="lead"
            class="a-slice-header__lead"
            :content="lead"
            :tag="'p'"
        />
        <a-dangerous-html
            v-if="bodyText"
            class="a-slice-header__body-text"
            :content="bodyText"
            :tag="'p'"
        />
        <slot />
    </div>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';

export default {
    name: 'ASliceHeader',

    components: {
        ADangerousHtml,
    },

    props: {
        title: {
            type: String,
            default: '',
        },

        lead: {
            type: String,
            default: '',
        },

        bodyText: {
            type: String,
            default: '',
        },

        titleTag: {
            type: String,
            default: 'h2',
        },
    },
};
</script>

<style lang="postcss">
.a-slice-header {
    margin-bottom: 40px;

    @media (--viewport-desktop) {
        width: calc(((100% - 32px) / 3) * 2 + 16px);
    }

    &__title {
        @mixin display-accent;

        color: var(--av-nav-primary);

        @media (--viewport-mobile-wide) {
            @mixin hero-accent;
        }

        @media (--viewport-desktop) {
            @mixin large-accent;
        }

        &:only-child {
            margin-bottom: 0;
        }
    }

    &__lead {
        @mixin lead;
        margin-top: 24px;
        color: var(--av-nav-primary);

        @media (--viewport-mobile-wide) {
            @mixin title;
        }
    }

    &__body-text {
        margin-top: 24px;
    }
}
</style>
