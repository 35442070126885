<template>
    <div class="other-integrations">
        <s-cards-small
            :title="title"
            :cards="cards"
            has-external-image
        />
    </div>
</template>

<script>
import SCardsSmall from '@core/slices/pages/cards-small/cards-small.vue';

export default {
    name: 'SolutionsOtherIntegrations',

    components: {
        SCardsSmall,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        cards: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="postcss" scoped>
.other-integrations {
    &:deep(.a-slice-header__title) {
        @mixin title;
    }
}

</style>
