/**
 * Basic slice types
 *
 * @return {string[]}
 */
export const LAYOUTS = ['row'];

/**
 * Basic background color
 * - white - Solid white (default)
 * - light-blue - Solid light-blue
 * - dark-blue - Solid dark-blue
 * - l-b-gradient-top - Light-blue gradient to top
 * - l-b-gradient-bottom - Light-blue gradient to bottom
 * @return {string[]}
 */
export const COLORS = [
    'white',
    'light-blue',
    'dark-blue',
    'l-b-gradient-top',
    'l-b-gradient-bottom',
];

/**
 * Variants of type border
 *
 * @return {string[]}
 */
export const BORDERS = ['full-width', 'content-wide', 'short'];

/**
 * Slice indents
 * None - Large indent 80/104 (default)
 * medium - Medium indent 64/88
 * small - Small indent 40/64
 * @return {string[]}
 */
export const INDENTS = ['small', 'medium'];

/**
 * Slice bottom paddings
 * None - padding 88 (default)
 * small - padding 64
 * @return {string[]}
 */
export const BOTTOM_PADDINGS = ['small'];
