<template>
    <main class="express-signup-wrapper">
        <section class="express-signup-partner-form">
            <div class="a-container">
                <transition name="express-signup-slide-up">
                    <header v-if="!isAnimation" class="header">
                        <a-logo class="logo" type="full" />
                        <h1 v-if="mainTitle" class="title">
                            {{ mainTitle }}
                        </h1>
                    </header>
                </transition>
                <transition name="express-signup-slide-up">
                    <s-form-container v-if="!isAnimation && form" :form="computedForm" />
                </transition>
            </div>
        </section>
        <section class="landing">
            <a-picture class="bg" :background="card.background" is-background />
            <div class="a-container">
                <transition name="express-signup-slide-up">
                    <div v-if="!isAnimation" class="header">
                        <h1 v-if="card.title" class="title">
                            {{ card.title }}
                        </h1>
                    </div>
                </transition>
                <transition name="express-signup-slide-up">
                    <section v-if="!isAnimation" class="card-wrapper">
                        <div class="content">
                            <div v-if="card" class="card">
                                <p v-if="card.description" class="description" v-html="card.description" />
                                <div class="list-wrapper">
                                    <ul class="list">
                                        <li v-for="(feature, index) in card.features" :key="`features_${index}`">
                                            <a-glyph class="icon" name="check-square" fill="brand-primary" />
                                            <span class="text">{{ feature }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </transition>
            </div>
        </section>
        <section class="licensing">
            <div class="a-container">
                <transition-group appear name="express-signup-slide-up">
                    <section v-if="payment && !isAnimation" key="payment" class="payment">
                        <h3 v-if="payment.title" class="title">
                            {{ replacePrice(payment.title) }}
                        </h3>
                        <p v-if="payment.lead" class="lead" v-html="payment.lead" />
                        <express-signup-payment-infographic
                            class="infographic"
                            :data="payment.infographic"
                            :formatted-price="formattedPrice"
                        />
                    </section>
                    <section v-if="trial && !isAnimation" key="trial" class="trial">
                        <h3 v-if="trial.title" class="title">
                            {{ trial.title }}
                        </h3>
                        <p v-if="trial.text" class="text" v-html="replacePrice(trial.text)" />
                        <a-link
                            v-if="trial.link"
                            class="link"
                            v-bind="trial.link"
                            :size="trial.link.size || 'paragraph'"
                        />
                    </section>
                </transition-group>
            </div>
        </section>
        <transition name="express-signup-slide-up">
            <div v-if="!isAnimation" class="footer">
                <express-signup-footer v-if="footer" v-bind="footer" />
            </div>
        </transition>
    </main>
</template>

<script>
import { mapState } from 'vuex';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';
import APicture from '@core/components/picture/picture.vue';
import currency from '@core/mixins/currency.js';
import ExpressSignupFooter from '@core/slices/pages/express-signup-shared/express-signup-footer.vue';
import SFormContainer from '@core/slices/pages/trial-form/components/form/form.vue';
import ExpressSignupPaymentInfographic from './components/payment-infographic.vue';
import { tiersMap } from './constants.js';

export default {
    name: 'SExpressSignupSales',
    components: {
        ALogo,
        ALink,
        SFormContainer,
        AGlyph,
        APicture,
        ExpressSignupFooter,
        ExpressSignupPaymentInfographic,
    },
    mixins: [currency],
    props: {
        header: {
            type: Object,
            required: false,
            default: undefined,
        },
        card: {
            type: Object,
            required: false,
            default: undefined,
        },
        mainTitle: {
            type: String,
            default: undefined,
        },
        form: {
            type: Object,
            default: undefined,
        },
        disclaimer: {
            type: String,
            required: false,
            default: undefined,
        },
        payment: {
            type: Object,
            required: false,
            default: undefined,
        },
        trial: {
            type: Object,
            required: false,
            default: undefined,
        },
        animation: {
            type: Object,
            required: false,
            default: undefined,
        },
        video: {
            type: Object,
            required: false,
            default: undefined,
        },
        footer: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    data() {
        return {
            isAnimation: true,
            defaults: {
                price: null,
                currency: null,
            },
            fomrattedPrice: null,
        };
    },
    computed: {
        ...mapState({
            queryStringData: (state) => state.trial?.queryStringData || {},
            currentLocale: (state) => state.locales?.locale || 'en-us',
            locales: (state) => state.locales?.locales || [],
        }),
        computedForm() {
            if (!this.formattedPrice) return this.form;

            const form = this.form;
            const forRepalceIndex = form.registration.notes.findIndex((el) => el.includes('XXX'));
            form.registration.notes[forRepalceIndex] = this.replacePrice(form.registration.notes[forRepalceIndex]);
            return form;
        },
    },
    async mounted() {
        this.savePassedParameters();
        this.setDefaultCurrency();
        this.setDefaultPrice();
        await this.$nextTick();
        this.formatPrice();
        this.isAnimation = false;
    },
    methods: {
        getDefaultPrice() {
            const localPrice = localStorage.getItem('trial_es_price');
            if (localPrice) return parseInt(localPrice, 10);

            const defaultPrices = Object.values(tiersMap).find((el) => el.tier === 2);
            return defaultPrices[this.defaults.currency];
        },
        getDefaultCurrency() {
            const localCurrency = localStorage.getItem('trial_es_currency');
            if (localCurrency) return localCurrency;

            return this.currentLocale.currency;
        },
        setDefaultPrice() {
            if (this.defaults.price) return;
            this.defaults.price = this.getDefaultPrice();
            localStorage.setItem('trial_es_price', this.defaults.price);
        },
        setDefaultCurrency() {
            if (this.defaults.currency) return;
            this.defaults.currency = this.getDefaultCurrency();
            localStorage.setItem('trial_es_currency', this.defaults.currency);
        },
        formatPrice() {
            const locale = this.locales.find((el) => el.currency === this.defaults.currency);
            const format = locale?.currency_format;
            this.formattedPrice = this.setCurrency(this.defaults.price, { format });
            if (!locale) this.formatPriceFallback();
        },
        formatPriceFallback() {
            const format = this.currentLocale.currency_format;
            let symbol;
            let withoutCurrencySymbol = true;

            switch (this.defaults.currency) {
                case ('CAD'):
                    symbol = 'CA$';
                    break;
                case ('AUD'):
                    symbol = 'AU$';
                    break;
                default:
                    symbol = null;
                    withoutCurrencySymbol = false;
                    break;
            }

            const price = this.setCurrency(this.defaults.price, { format, withoutCurrencySymbol });
            this.formattedPrice = `${symbol || ''}${price}`;
        },
        replacePrice(str) {
            if (!str || typeof str !== 'string') return false;
            if (!this.formattedPrice) return false;

            return str.replace('XXX', this.formattedPrice);
        },
        savePassedParameters() {
            const expectedKeys = ['price', 'currency', 'aan', 'opportunity_id'];
            expectedKeys.forEach((key) => {
                if (!Object.keys(this.queryStringData)?.length) return;
                const val = this.queryStringData[key] || localStorage.getItem(`trial_es_${key}`);
                if (!val || val === 'undefined') return;

                this.defaults[key] = key === 'price' ? parseInt(val, 10) : val;
                localStorage.setItem(`trial_es_${key}`, val);
            });
        },
    },
    serverPrefetch() {
        const locale = this.$route.params.locale;
        this.$store.dispatch('slices/getSyncedData', { slice: 's-data-centers', locale });
        this.$store.dispatch('locales/getAll');
        this.$store.dispatch('countries/getCountries');
    },
};
</script>

<style lang="postcss" scoped>
.express-signup-wrapper {
    background: var(--av-gradient-to-bottom-lightest);
    color: var(--av-nav-primary);
    .header {
        text-align: center;
        color: var(--av-nav-primary);
        .logo {
            width: 156px;
            height: 32px;
            margin-top: 40px;
            @media (--viewport-tablet) {
                width: 205px;
                height: 56px;
            }
        }
        .title {
            @mixin title;
            font-weight:500;
            margin: 32px auto 16px;
            @media (--viewport-tablet) {
                @mixin display;
            }
        }
    }
    &:deep(.s-form-container) {
        & {
            width: 100%;
            max-width: 520px;
            min-height: 520px;
            text-align: start;
            margin: 0 auto;

            .el-input__label.is-active {
                padding-top: 5px;
            }
        }

        .s-form-container__loading {
            background: transparent;

            & + div {
                display: none;
            }
        }

        .s-form-registration {
            padding: 0;
            border: none;
            box-shadow: none;
            background: transparent;

            .el-form {
                text-align: start;
            }
        }

        .s-form-registration__loading {
            background: transparent;

            & ~ .el-form  {
                opacity: 0;
            }
        }

        .s-form-registration__title {
            @mixin paragraph;
            font-weight: 400;
            color: var(--av-fixed-light);

        }

        .s-form-registration__section {
            @mixin paragraph;
            text-align: center;
            font-weight: 400;
            margin: 0 0 8px;
            padding: 0;
            color: var(--av-fixed-secondary);
        }

        .s-form-registration__button {
            @mixin paragraph;
            font-weight: 500;
            display: block;
            text-align: center;
            max-width: 306px;
            width: 100%;
            margin-inline-end: auto;
            margin-inline-start: auto;
        }

        .s-form-registration__checkboxes {
            .el-checkbox__label {
                @mixin body;
                color: var(--av-fixed-secondary);
            }
        }

        .s-form-registration__note {
            max-width: 344px;
            margin: 16px auto;

            @media (--viewport-desktop) {
                max-width: 510px;
            }
        }

        .s-form-registration__note-item {
            @mixin caption;
            line-height: 20px;
            margin-bottom: 16px;
            color: var(--av-fixed-secondary);

            &:last-child {
                margin: 0;
            }
        }

        .s-form-registration__error {
            @mixin caption;
        }
    }
    .landing {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        .bg {
            &:deep(img) {
                object-fit: none !important;
                object-position: top center !important;
                @media all and (min-width: 480px) {
                    object-fit: contain !important;
                }
                @media (--viewport-tablet) {
                    object-fit: cover !important;
                    object-position: center center !important;
                }
            }
        }
        .header, .card-wrapper {
            flex-basis: 100%;
        }
        .header {
            color: var(--av-inversed-primary);
            text-align: center;
            .logo {
                width: 156px;
                height: 32px;
                margin-top: 48px;
                @media (--viewport-tablet) {
                    margin-top: 40px;
                    width: 205px;
                    height: 56px;
                }
            }
            .title {
                @mixin title;
                font-weight: 700;
                margin: 32px auto 24px;
                @media (--viewport-tablet) {
                    @mixin display;
                    margin: 32px auto;
                }
            }
        }
        .card-wrapper {
            position: relative;
            z-index: 10;
            margin: 0 -18px;
            @media (--viewport-mobile-wide) {
                margin: 0 -34px;
            }
            @media (--viewport-tablet) {
                margin: 0;
            }
            .content {
                .card {
                    padding: 24px 16px;
                    background: var(--av-inversed-primary);
                    box-shadow: var(--av-shadow-regular);
                    border: 2px solid var(--av-brand-secondary);
                    color: var(--av-nav-primary);
                    @media (--viewport-tablet) {
                        max-width: 704px;
                        border-radius: 8px;
                        margin: 32px auto -48px;
                        padding: 24px;
                    }
                    @media (--viewport-desktop) {
                        max-width: 762px;
                        padding: 40px 56px;
                    }
                    .description {
                        @mixin paragraph;
                        margin: 0 auto 16px;
                        text-align: center;
                        @media (--viewport-tablet) {
                            max-width: 580px;
                            margin-bottom: 24px;
                        }
                        @media (--viewport-desktop) {
                            max-width: 645px;
                        }
                    }
                    .list-wrapper {
                        display: flex;
                        align-items: flex-start;
                        flex-flow: row wrap;
                        justify-content: space-between;
                        margin-bottom: 24px;
                    }
                    .list {
                        @mixin colls 12;
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: space-between;
                        gap: 8px 32px;
                        li {
                            @mixin paragraph;
                            flex-basis: 100%;
                            display: flex;
                            align-items: flex-start;
                            @media (--viewport-tablet) {
                                flex-basis: calc(50% - 16px);
                            }

                            .text {
                                flex-basis: calc(100% - 20px);
                            }
                            .icon {
                                width: 16px;
                                flex-basis: 16px;
                                margin-inline-end: 4px;
                                margin-top: 6px;
                            }
                        }
                    }
                    .button {
                        @mixin paragraph;
                        font-weight: 500;
                        width: 100%;
                        margin: 16px auto 0;
                        @media (--viewport-tablet) {
                            display: block;
                            text-align: center;
                            max-width: 276px;
                            margin: 24px auto 0;
                        }
                    }
                }
            }
        }
    }
    .licensing {
        position: relative;
        .payment {
            @mixin colls 12;
            margin: 0 auto 54px;
            text-align: center;
            padding-top: 48px;
            color: var(--av-nav-primary);
            @media (--viewport-tablet) {
                margin: 0 auto 32px;
                padding-top: 110px;
            }
            .title {
                @mixin title;
                font-weight: 500;
                margin: 0 auto 16px;
                @media (--viewport-tablet) {
                    @mixin display;
                    @mixin colls 10;
                }
                @media (--viwport-desktop) {
                    @mixin colls 12;
                }
            }
            .lead {
                @mixin paragraph;
                margin-bottom: 32px;
            }
        }
    }
    .trial {
        color: var(--av-nav-primary);
        margin: 0 auto;
        max-width: 520px;
        text-align: center;
        margin-bottom: 48px;
        .title {
            @mixin colls 12;
            @mixin paragraph;
            margin-bottom: 8px;
            font-weight: 700;
            text-align: center;
            @media (--viewport-tablet) {
                margin-bottom: 16px;
            }
        }
        .text {
            @mixin colls 12;
            @mixin paragraph;
            margin: 0 auto 8px;
        }
        .link {
            display: block;
        }
        .button {
            margin: 48px auto;
            min-width: 338px;
            width:auto;
            max-width: 100%;
            font-weight: 400;
            color: var(--av-brand-primary);
        }
    }

    .footer {
        padding: 32px 0;
        border-top: 1px solid #c6dcf9;
        background: linear-gradient(0deg, rgba(38, 104, 197, 0.05), rgba(38, 104, 197, 0.05)), #fff;
        @media (--viewport-tablet) {
            background: rgba(64, 139, 234, 0.05);
            padding: 24px 0;
        }
        &:deep(.express-signup-main-footer) {
            &__logo {
                flex-basis: 100%;
                margin-bottom: 16px;
                @media (--viewport-tablet) {
                    margin-bottom: 0;
                    margin-inline-end: 16px;
                    flex-basis: auto;
                }
            }
            &__copyright {
                flex-basis: auto;
                margin-inline-end: 0 !important;
                @media (--viewport-tablet) {
                    margin-inline-end: auto !important;
                }
            }
            &__links {
                margin-top: 32px;
                flex-flow: column;
                align-items: center;
                @media (--viewport-tablet) {
                    flex-flow: row wrap;
                    margin: 0;
                }
                &__item {
                    margin-bottom: 8px;
                    margin-inline-end: 0 !important;
                    @media (--viewport-tablet) {
                        margin-bottom: 0;
                        margin-inline-end: 16px !important;
                    }
                    &:last-child {
                        margin-bottom: 0;
                        @media (--viewport-tablet) {
                            margin-inline-end: 0 !important;
                        }
                    }
                }
            }
        }
    }
}

.express-signup-slide-up-leave-active,
.express-signup-slide-up-enter-active {
    transition: 0.5s;
}
.express-signup-slide-up-enter {
    transform: translateY(50%);
    opacity: 0;
}
.express-signup-slide-up-leave-to {
    transform: translateY(0);
    opacity: 1;
}
</style>
