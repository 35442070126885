<template>
    <div class="builder">
        <s-ribbon
            link-url="#"
            label-primary="Warning: If any of the Live Previews here doesn't work please use the Preview in Storybook button."
            icon-position="start"
            icon-name="warning"
            style-name="yellow"
        />
        <div class="a-container a-pt-12 a-pb-2">
            <div class="page">
                <div v-if="!isError" class="builder">
                    <el-select
                        v-model="selectedSlice"
                        label="Select slice"
                        class="select"
                        filterable
                        @change="setActiveSlice"
                    >
                        <el-option
                            v-for="item in availableSlices"
                            :key="item"
                            :label="item"
                            :value="item"
                        />
                    </el-select>
                    <el-select
                        v-if="selectedSlice"
                        v-model="selectedVariant"
                        label="Select slice variant (from Uikit)"
                        class="select"
                        @change="setActiveVariant"
                    >
                        <el-option
                            v-for="item in variantsSelect"
                            :key="item"
                            :label="item"
                            :value="item"
                        />
                    </el-select>
                    <div v-if="selectedVariant" class="links">
                        <a-link
                            :to="getVariantLink(selectedVariant)"
                            text="Preview in storybook"
                            target="_blank"
                            size="paragraph"
                            accent
                        />
                        <a-link
                            v-if="figmaLink"
                            :to="figmaLink"
                            text="Preview in Figma"
                            target="_blank"
                            size="paragraph"
                            accent
                        />
                    </div>
                    <a-tabs v-if="selectedVariant" class="tabs" @click="syncSliceData">
                        <a-tab label="JSON">
                            <div v-if="selectedVariant" class="code-wrapper">
                                <a-button
                                    class="copy-button"
                                    :text="isCoppied ? 'Copied to clipboard' : 'Copy'"
                                    :glyph="isCoppied ? null : 'copy'"
                                    size="s"
                                    @click="coppyJson"
                                />
                                <textarea v-model="data" class="code" />
                            </div>
                        </a-tab>
                        <a-tab label="Live preview">
                            <template v-if="isSliceReadyToShow && !isJSONError">
                                <component
                                    v-bind="sliceData"
                                    :is="selectedSlice"
                                />
                            </template>
                            <template v-if="isJSONError">
                                Seems like JSON string is invalid, please verify your JSON <a
                                    :href="`https://jsonformatter.curiousconcept.com/?data=${encodeData(data)}`"
                                    class="a-link-static"
                                    target="_blank"
                                >
                                    here
                                </a>
                            </template>
                        </a-tab>
                    </a-tabs>
                </div>
                <div v-else>
                    <p class="error">
                        This slice <strong>does NOT support</strong> builder yet...<br />
                        if you think this is a mistake you can contact <br /><br />
                        Nikolay Andreev at nikolay.andreev@acronis.com<br />
                        or<br />
                        Evgeny Shekera at evgeny.shekera@acronis.com
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import ATab from '@core/components/tabs/tab.vue';
import ATabs from '@core/components/tabs/tabs.vue';
import SRibbon from '@core/slices/shared/ribbon/ribbon.vue';
import { availableInBuilder } from '@model/const/translations.ts';

export default {
    name: 'BuilderComponent',
    components: {
        SRibbon,
        ATabs,
        ATab,
        AButton,
        ALink,
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
    },
    data() {
        return {
            isSliceReadyToShow: false,
            selectedSlice: '',
            selectedVariant: null,
            variantsSelect: null,
            figmaLink: null,
            variants: [],
            data: null,
            sliceData: null,
            isError: false,
            isJSONError: false,
            isCoppied: false,
            timer: null,
        };
    },
    computed: {
        availableSlices() {
            return availableInBuilder;
        },
        pathname() {
            return this.selectedSlice.replace(/^s-/, '');
        },
    },
    watch: {
        data() {
            this.syncSliceData();
        },
    },
    methods: {
        syncSliceData() {
            this.isSliceReadyToShow = false;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.isSliceReadyToShow = true;
            }, 300);

            if (!this.data) {
                this.sliceData = {};
                this.isJSONError = true;
                return;
            }

            try {
                this.sliceData = JSON.parse(this.data)?.data;
                this.isJSONError = false;
            } catch {
                this.sliceData = {};
                this.isJSONError = true;
            }
        },
        getVariantLink(variant) {
            const uikit = 'https://uikit.web.gcp.acronis.com/?path=/story/slices-pages-';
            const variantSlug = variant.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`);
            return `${uikit}${this.pathname}-${variantSlug}`;
        },
        async setActiveSlice() {
            try {
                this.isSliceReadyToShow = false;
                this.variants = await import(`@core/slices/pages/${this.pathname}/mock.js`);
                Vue.component(this.selectedSlice, () => import(`@core/slices/pages/${this.pathname}/${this.pathname}.vue`));
                this.isSliceReadyToShow = true;
                this.variantsSelect = Object.keys(this.variants).filter((el) => el !== 'Figma' && el !== 'Mock');
                this.selectedVariant = this.variantsSelect[0];
                this.setActiveVariant();
                this.figmaLink = this.variants?.Figma || null;
            } catch (e) {
                this.isError = true;
                // eslint-disable-next-line no-console
                console.error(e);
            }
        },
        setActiveVariant() {
            this.data = null;
            const variant = this.variants[this.selectedVariant];
            if (!variant || !variant?.args) return;

            const exampleJSON = `{"data": ${JSON.stringify(variant.args)},"name": "${this.selectedSlice}"}`;
            this.data = JSON.stringify(JSON.parse(exampleJSON), null, 4);
        },
        async coppyJson() {
            await navigator.clipboard.writeText(this.data);

            this.isCoppied = true;
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.isCoppied = false;
            }, 1000);
        },
        encodeData(json) {
            try {
                return encodeURIComponent(json);
            } catch {
                return null;
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.page {
    position: relative;
    .error {
        text-align: center;
    }
    .title {
        @mixin display-accent;
        margin-bottom: 16px;
    }
    .select {
        margin-bottom: 8px;
    }
    .links {
        display:flex;
        flex-flow: row wrap;
        gap: 8px;
    }
    .a-tabs {
        &:deep(.a-tabs__header) {
            margin-top: 32px;
            margin-bottom: 8px;
        }
    }
    .code-wrapper {
        position: relative;
        .copy-button {
            position: absolute;
            inset-inline-end: 16px;
            top: 16px;
            z-index: 20;
        }
    }
    .code {
        @mixin descriptor;
        border: 1px solid var(--av-brand-light);
        border-radius: 4px;
        padding: 8px;
        color: var(--av-fixed-secondary);
        overflow: scroll;
        width: 100%;
        height: 400px;
        max-height: 400px;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
</style>
