<template>
    <div class="integration-header">
        <div class="a-container">
            <div class="main">
                <img class="logo" :src="logo" :alt="title" />
                <div class="integration-info">
                    <div class="row">
                        <span class="label">{{ integrationTitle }}:</span> {{ title }}
                        <a-label v-if="tag" v-bind="tag" :size="tag.size || 's'">
                            {{ tag.text }}
                        </a-label>
                    </div>
                    <div v-if="categories.length" class="row">
                        <span class="label">{{ categoryTitle }}:</span> {{ categoriesText }}
                    </div>
                    <div v-if="company" class="row">
                        <span class="label">{{ companyTitle }}:</span> {{ company }}
                    </div>
                </div>
                <div v-if="cta?.url" class="cta">
                    <a-button
                        :key="cta.url"
                        class="button"
                        :to="cta.url"
                        :text="cta.label"
                        type="main"
                        size="s"
                        v-bind="cta"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALabel from '@core/components/label/label.vue';

export default {
    name: 'SIntegrationHeader',

    components: {
        AButton,
        ALabel,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        categories: {
            type: Array,
            default: null,
        },

        company: {
            type: String,
            default: null,
        },

        logo: {
            type: String,
            required: true,
        },

        cta: {
            type: Object,
            default: null,
        },

        tag: {
            type: String,
            default: null,
        },

        integrationTitle: {
            type: String,
            default: 'Integration',
        },

        categoryTitle: {
            type: String,
            default: 'Category',
        },

        companyTitle: {
            type: String,
            default: 'Company',
        },
    },

    computed: {
        categoriesText() {
            return this.categories.map((category) => category?.title).join(', ');
        },
    },
};
</script>

<style lang="postcss" scoped>
.integration-header {
    padding-block: 24px;
    background: var(--av-inversed-primary);
}

.a-label {
    margin-inline-start: 8px;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 16px;

    @media (--viewport-tablet) {
        align-items: center;
        flex-direction: row;
        gap: 24px;
    }
}

.logo {
    height: 48px;
    width: 100%;
    max-width: 200px;
    object-fit: contain;
    object-position: left center;
}

.integration-info {
    @mixin body;

    @media (--viewport-tablet) {
        padding-inline-start: 24px;
        border-inline-start: 1px solid var(--av-brand-accent);
    }
}

.row {
    display: flex;
    align-items: baseline;
}

.label {
    color: var(--av-fixed-light);
    margin-inline-end: 4px;
}

.cta {
    @media (--viewport-tablet) {
        margin-inline-start: auto;
    }
}

.button {
    @mixin paragraph;

    padding-inline: 32px;
}

</style>
