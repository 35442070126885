<template>
    <div class="event-card-list">
        <div class="items">
            <event-card
                v-for="(card, i) in cardsToShow"
                :key="i"
                class="item"
                v-bind="{ ...card, learnMoreTitle }"
            />
            <div
                v-if="isLoading"
                v-loading="true"
                class="loading"
                el-loading-size="48"
            />
        </div>
        <a-dangerous-html
            v-if="notFound"
            class="not-found"
            :content="notFoundText"
            @click.native="handleClick"
        />
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive.js';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import EventCard from '../shared-components/event-card/event-card.vue';

export default {
    name: 'EventCardList',

    components: {
        EventCard,
        ADangerousHtml,
    },

    directives: {
        Loading,
    },

    props: {
        cards: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        learnMoreTitle: {
            type: String,
            required: true,
        },
        notFoundTitle: {
            type: String,
            required: true,
        },
        limit: {
            type: Number,
            default: 0,
        },
    },

    emits: ['filterReset'],

    computed: {
        notFound() {
            return this.cards.length === 0 && !this.isLoading;
        },

        notFoundText() {
            return this.notFoundTitle.replace(
                /@resetLink\('(.*?)'\)/,
                '<a class="event-link filterReset">$1</a>',
            );
        },

        cardsToShow() {
            return this.limit > 0 ? [].concat(this.cards).splice(0, this.limit) : this.cards;
        },
    },

    methods: {
        handleClick(event) {
            if (event.target.classList.contains('filterReset')) {
                this.$store.dispatch('events/resetFilterForm');
                this.$emit('filterReset');
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-card-list {
    .items {
        min-height: 40px;
        position: relative;

        @media (--viewport-tablet) {
            display: grid;
            column-gap: 16px;
            row-gap: 56px;
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: 1fr;
        }

        @media (--viewport-desktop) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .not-found {
        @mixin lead;
        padding: 20px 0;
        text-align: center;
        max-width: 600px;
        margin: 0 auto;
    }

    .loading {
        position: absolute;
        inset-inline-start: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        min-height: 100px;
    }

    .item {
        margin-bottom: 32px;
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }

        @media (--viewport-tablet) {
            margin-bottom: 0;

            &::after {
                display: none;
            }
        }
    }
}
</style>
