<template>
    <div v-if="content" class="notification">
        <a-glyph v-if="content.glyph" class="icon" :name="content.glyph" />
        <div v-if="content.title" class="title" v-html="content.title" />
        <div v-if="content.description" class="description">
            <p
                v-for="(item, i) in content.description"
                :key="i"
                class="description-item"
                v-html="item"
            />
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import form from '@core/mixins/form.js';

export default {
    name: 'TrialFormNotification',

    components: {
        AGlyph,
    },

    mixins: [form],

    props: {
        /**
         * Notification form
         */
        content: {
            type: Object,
            default: null,
        },
    },

    mounted() {
        if (this.content.eventTracking) {
            this.sendDataLayer({
                eventAction: window.location.href,
                ...this.content.eventTracking,
            });
        }
    },
};
</script>

<style lang="postcss" scoped>
.notification {
    width: 100%;
    display: flex;
    padding: 40px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: var(--av-inversed-primary);
}

.title {
    @mixin title-accent;
    margin: 16px 0 0;
    color: var(--av-nav-primary);
}

.icon {
    width: 32px;
    height: 32px;
    fill: var(--av-fixed-success);
}

.description {
    margin-top: 16px;
}

.description-item {
    @mixin paragraph;
    color: var(--av-fixed-secondary);

    &:not(:first-child) {
        margin-top: 16px;
    }

    &:deep(a) {
        text-decoration: none;
        color: var(--av-brand-primary);
    }
}

@media (--viewport-mobile-wide) {
    .notification {
        border: 1px solid #e3ecf9;
        box-shadow: var(--av-shadow-small);
    }
}
</style>
