<template>
    <div class="event-numbers">
        <h2 v-if="title" class="title">
            {{ title }}
        </h2>
        <div class="items">
            <div v-for="(item, i) in numbers" :key="i" class="item">
                <div class="value">
                    {{ item.value }}
                </div>
                <div class="label">
                    {{ item.label }}
                </div>
            </div>
        </div>
        <a v-if="link" class="event-link link" :href="link.to">{{ link.text }} →</a>
    </div>
</template>

<script>
export default {
    name: 'EventNumbers',

    props: {
        title: {
            type: String,
            default: null,
        },

        numbers: {
            type: Array,
            default: () => [],
        },

        link: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-numbers {
    text-align: center;

    .items {
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            display: flex;
            justify-content: space-between;
        }

        @media (--viewport-desktop) {
            margin-bottom: 32px;
            width: 66.7%;
            margin-inline-start: auto;
            margin-inline-end: auto;
        }
    }

    .item {
        text-align: center;
        margin-bottom: 32px;
        width: 100%;

        @media (--viewport-tablet) {
            padding: 0 40px;
            width: auto;
        }
    }

    .value {
        color: var(--av-nav-secondary);
        font-size: 64px;
        line-height: 72px;
        font-weight: 700;

        @media (--viewport-tablet) {
            font-size: 88px;
            line-height: 96px;
        }
    }

    .label {
        @mixin paragraph-accent;
    }

    .link {
        @mixin lead-strong;
        cursor: pointer;
    }
}
</style>
