<template>
    <component :is="tag" :class="classNames" :style="style">
        <slot />
    </component>
</template>

<script lang="ts">
export default {
    name: 'ARow',

    props: {
        align: {
            type: String,
            default: 'top',
        },
        gutter: {
            type: Number,
            default: 0,
        },
        justify: {
            type: String,
            default: 'start',
        },
        tag: {
            type: String,
            default: 'div',
        },
    },

    computed: {
        style() {
            if (!this.gutter) return {};

            const margin = `-${this.gutter / 2}px`;
            return {
                marginLeft: margin,
                marginRight: margin,
            };
        },

        classNames() {
            return {
                'a-form-row': true,
                [`is-justify-${this.justify}`]: this.justify !== 'start',
                [`is-align-${this.align}`]: this.align !== 'top',
            };
        },
    },
};
</script>
<style lang="postcss" scoped>
.a-form-row {
    position: relative;
    box-sizing: border-box;

    &.is-justify-center {
      justify-content: center;
    }

    &.is-justify-end {
      justify-content: flex-end;
    }

    &.is-justify-space-between {
      justify-content: space-between;
    }

    &.is-justify-space-around {
      justify-content: space-around;
    }

    &.is-align-middle {
      align-items: center;
    }

    &.is-align-bottom {
      align-items: flex-end;
    }

    &::before,
    &::after {
      display: table;
      content: '';
    }

    &::after {
      clear: both;
    }
}
</style>
