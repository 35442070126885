<template>
    <div class="event-label" :style="labelColorStyle">
        {{ title }}
    </div>
</template>

<script>
export default {
    name: 'EventLabel',
    props: {
        title: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'audience',
        },
        color: {
            type: String,
            default: null,
        },
    },

    computed: {
        className() {
            return `type-${this.type}`;
        },

        labelColorStyle() {
            if (this.color) {
                return {
                    'background-color': `#${this.color}`,
                };
            }
            if (this.type === 'target-audience') {
                return {
                    'background-color': 'var(--av-fixed-success)',
                };
            }
            if (this.type === 'event-category') {
                return {
                    'background-color': 'var(--av-brand-secondary-accent)',
                    color: 'var(--av-nav-secondary)',
                };
            }
            if (this.type === 'event-type') {
                return {
                    'background-color': 'var(--av-nav-secondary)',
                };
            }
            if (this.type === 'event-category-dark') {
                return {
                    'background-color': 'var(--av-brand-secondary-accent)',
                    color: 'var(--av-nav-secondary)',
                };
            }
            return {};
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-label {
    @mixin note;
    margin-inline-end: 3px;
    margin-inline-start: 3px;
    margin-bottom: 8px;
    height: 16px;
    padding: 0 8px;
    border-radius: 4px;
    color: var(--av-inversed-primary);
    white-space: nowrap;
    text-transform: uppercase;
}
</style>
