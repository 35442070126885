<template>
    <s-basic-slice class="s-digital-world" v-bind="$attrs">
        <ul class="s-digital-world__main-list">
            <li v-for="(section, index) in cards" :key="index" class="s-digital-world__main-list-item">
                <ul class="s-digital-world__inner-list">
                    <li v-for="card in section" :key="card.icon" class="s-digital-world__inner-list-item">
                        <div class="digital-world-card">
                            <div class="digital-world-card__icon-wrapper">
                                <a-icon
                                    class="digital-world-card__icon"
                                    :number="card.icon"
                                    :icon-alt="card.title || ' '"
                                />
                            </div>
                            <div class="digital-world-card__title">
                                {{ card.title }}
                            </div>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </s-basic-slice>
</template>

<script>
import AIcon from '@core/components/icon/icon.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SDigitalWorld',
    components: {
        SBasicSlice,
        AIcon,
    },
    inheritAttrs: false,
    props: {
        cards: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-digital-world {
    &__main-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px -40px;

        @media (--viewport-mobile-wide) {
            margin-inline-end: -16px;
            display: flex;
        }

        @media (--viewport-desktop-wide) {
            margin-inline-end: 0;
            flex-direction: column;
        }
    }

    &__main-list-item {
        width: 50%;
        padding: 0 8px 40px;

        @media (--viewport-mobile-wide) {
            width: 25%;
            padding: 0;
            padding-inline-end: 16px;

            &:nth-child(1) {
                order: 1;
            }

            &:nth-child(2) {
                order: 3;
            }

            &:nth-child(3) {
                order: 4;
            }

            &:nth-child(4) {
                order: 2;
            }
        }

        @media (--viewport-desktop-wide) {
            width: 100%;
            padding-inline-end: 0;

            &:not(:nth-child(3)) {
                margin-bottom: 16px;
            }
        }
    }

    &__inner-list {
        @media (--viewport-desktop-wide) {
            display: flex;
            flex-direction: row;
        }
    }

    &__inner-list-item {
        margin-bottom: 8px;

        @media (--viewport-mobile-wide) {
            margin-bottom: 0;
        }

        @media (--viewport-desktop-wide) {
            width: 14.25%;
        }

        &:first-of-type {
            .digital-world-card {
                &__icon-wrapper {
                    margin-bottom: 8px;
                    width: 40px;
                    height: 40px;

                    @media (--viewport-mobile-wide) {
                        width: 32px;
                        height: 32px;
                    }

                    @media (--viewport-desktop-wide) {
                        width: 56px;
                        height: 56px;
                    }
                }

                &__title {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 600;
                    color: var(--av-nav-primary);
                }

                @media (--viewport-mobile-wide) {
                    background-color: var(--av-brand-accent);
                }
            }
        }

        &:not(:first-of-type) {
            .digital-world-card {
                @media (--viewport-mobile-wide) {
                    background-color: var(--av-brand-lightest);
                    border-top: var(--av-border);
                }

                @media (--viewport-desktop-wide) {
                    border-top: none;
                    border-inline-start: var(--av-border);
                }

                &__icon {
                    display: none;

                    @media (--viewport-mobile-wide) {
                        display: block;
                    }
                }
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .digital-world-card {
        @media (--viewport-mobile-wide) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 120px;
            padding: 16px;
        }

        &__icon-wrapper {
            @media (--viewport-mobile-wide) {
                margin-bottom: 8px;
                width: 32px;
                height: 32px;
            }

            @media (--viewport-desktop-wide) {
                margin-bottom: 8px;
                width: 40px;
                height: 40px;
            }
        }

        &__icon {
            max-width: 100%;
        }

        &__title {
            font-size: 12px;
            line-height: 16px;
            color: var(--av-fixed-secondary);

            @media (--viewport-desktop) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}
</style>
