import SchemaHandler from '@model/seo/schemaHandler';
import { OrganizationSchema, SchemaConfig } from '@model/seo/schemas';
import { getUrlWithLocale } from '../helper';

export default class OrganizationHandler implements SchemaHandler {
    ctx: any;

    schemasConfig: SchemaConfig[];

    constructor(ctx: any, schemasConfig: SchemaConfig[]) {
        this.ctx = ctx;
        this.schemasConfig = schemasConfig;
    }

    getSchemaData(staticConfig: OrganizationSchema): Promise<OrganizationSchema> {
        return Promise.resolve(this.getRequiredFields(staticConfig));
    }

    getRequiredFields(staticConfig: OrganizationSchema): OrganizationSchema {
        const url = getUrlWithLocale(this.ctx);

        return {
            ...staticConfig,
            '@id': `${url}/company/`,
            url,
        };
    }
}
