<template>
    <div class="a-label" :class="classNames">
        <div class="content">
            <a-glyph v-if="glyph" :name="glyph" :size="glyphSize" />
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
import AGlyph from '@core/components/glyph/glyph.vue';
import { TYPES, VARIANTS, LAYOUTS, SIZES, THEMES } from './constants.js';

export default {
    name: 'ALabel',

    components: {
        AGlyph,
    },

    props: {
        type: {
            type: String,
            default: 'label',
            validator: (value) => TYPES.includes(String(value)),
        },

        variant: {
            type: String,
            default: 'info',
            validator: (value) => VARIANTS.includes(String(value)),
        },

        layout: {
            type: String,
            default: 'solid',
            validator: (value) => LAYOUTS.includes(String(value)),
        },

        size: {
            type: String,
            default: 'l',
            validator: (value) => SIZES.includes(String(value)),
        },

        theme: {
            type: String,
            default: 'light',
            validator: (value) => THEMES.includes(String(value)),
        },

        glyph: {
            type: String,
            default: '',
        },
    },

    computed: {
        classNames() {
            return {
                [`type-${this.type}`]: this.type,
                [`variant-${this.variant}`]: this.variant,
                [`layout-${this.layout}`]: this.layout,
                [`size-${this.size}`]: this.size,
                [`theme-${this.theme}`]: this.theme,
            };
        },

        glyphSize() {
            if (this.type === 'label') return 's';

            return this.size === 'l' ? 'm' : 's';
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-label {
    display: inline-block;
    position: relative;

    .content {
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .a-glyph {
        flex-shrink: 0;
        fill: currentcolor;
        margin-inline-end: 8px;
    }

    &.size-s {
        .a-glyph {
            margin-inline-end: 6px;
        }
    }
}

.a-label.type-label {
    @mixin note-heading;
    box-sizing: border-box;
    font-weight: 700;
    padding: 4px 12px;
    border-radius: 16px;

    --border-width: 2px;

    &.size-s {
        padding: 0 8px;
        border-radius: 8px;

        --border-width: 1px;

        .a-glyph {
            width: 12px;
            height: 12px;
        }
    }

    &.variant-info {
        color: var(--av-nav-primary);
    }

    &.variant-success {
        color: rgb(65, 113, 9);
    }

    &.variant-neutral {
        color: var(--av-fixed-neutral-dark);
    }

    &.layout-solid {
        &.variant-info {
            background: var(--av-gradient-info-lightest);
        }

        &.variant-success {
            background: var(--av-gradient-success-lightest);
        }

        &.variant-neutral {
            background: var(--av-fixed-neutral-light);
        }
    }

    &.layout-subtle {
        &:before {
            content: '';
            position: absolute;
            inset: 0;
            z-index: 1;
            margin: 0;
            border-radius: inherit;
        }

        &:after {
            content: '';
            position: absolute;
            inset: 0;
            z-index: 2;
            margin: var(--border-width);
            border-radius: inherit;
            background-color: var(--av-inversed-primary);
        }

        &.variant-info {
            &:before {
                background: var(--av-gradient-info);
            }
        }

        &.variant-success {
            &:before {
                background: var(--av-gradient-success);
            }
        }

        &.variant-neutral {
            &:before {
                background: var(--av-fixed-neutral);
            }
        }
    }

    &.theme-dark {
       color: var(--av-inversed-primary);

        &.layout-solid {
            &.variant-info {
                background: var(--av-gradient-info);
            }

            &.variant-success {
                background: var(--av-gradient-success);
            }

            &.variant-neutral {
                background: var(--av-fixed-neutral);
            }
        }

        &.layout-subtle {
            &:after {
                background-color: var(--av-nav-primary);
            }

            &.variant-neutral {
                color: var(--av-fixed-neutral-light);
            }
        }
    }
}

.a-label.type-text-label {
    @mixin paragraph-accent;

    &.size-s {
        @mixin body-accent;
    }

    &.variant-info {
        color: var(--av-nav-secondary);
    }

    &.variant-success {
        color: var(--av-button-action);
    }

    &.variant-neutral {
        color: var(--av-fixed-neutral-dark);
    }

    &.theme-dark {
        &.variant-info {
            color: var(--av-brand-secondary);
        }

        &.variant-success {
            color: var(--av-fixed-success);
        }

        &.variant-neutral {
            color: var(--av-inversed-light);
        }
    }
}

</style>
