export default {
    props: {
        margin: {
            type: String,
            default: undefined,
            required: false,
        },
        padding: {
            type: String,
            default: undefined,
            required: false,
        },
    },
    computed: {
        marginClasses() {
            return this.margin || [];
        },
        paddingClasses() {
            return this.padding || [];
        },
    },
};
