/* eslint-disable max-len */
import Vue from 'vue';
import SCardsSmall from '@core/slices/pages/cards-small/cards-small.vue';
import { Icon as CardsSmallIcon, Logo as CardsSmallLogo } from '@core/slices/pages/cards-small/mock.js';

Vue.component('SCardsSmall', SCardsSmall);

export const Figma = 'https://www.figma.com/design/SxYPthnMMnpIFdM8FAy3KT/01-2-UI-Kits---Website-UI?node-id=13361-9847&t=2XkN6La7v9gz0nyg-4';

export const Default = {
    args: {
        translations: {
            title: 'Use Cases',
            slices: [
                { data: CardsSmallIcon.args, label: 'System Builders' },
                { data: CardsSmallLogo.args, label: 'HDD/SSD Vendors' },
                { data: CardsSmallIcon.args, label: 'System Integrators' },
                { data: CardsSmallLogo.args, label: 'Storage Vendors' },
                { data: CardsSmallIcon.args, label: 'ISV/ Cloud Partners' },
                { data: CardsSmallLogo.args, label: 'Custom Development' },
                { data: CardsSmallIcon.args, label: 'Custom Development' },
            ],
        },
        background: 'light-blue',
        type: 'tile',
        useSmallTabs: false,
        sliderOptions: { speed: 300 },
        slices: [
            { anchorId: 'one', name: 's-cards-small' },
            { name: 's-cards-small' },
            { name: 's-cards-small' },
            { name: 's-cards-small' },
            { name: 's-cards-small' },
            { name: 's-cards-small' },
            { name: 's-cards-small' },
        ],
    },
};
