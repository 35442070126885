<template>
    <div v-if="content" class="s-info-contact-sales">
        <p class="s-info-contact-sales__logo">
            <a class="a-link s-info-contact-sales__logo-link" :href="replaceLocale(content.productLink)">
                <a-logo type="full" />
                <div v-if="content.title" class="s-info-contact-sales__logo-title">{{ content.title }}</div>
            </a>
        </p>
        <div v-if="content.description" class="s-info-contact-sales__description">
            <p v-for="(item, i) in content.description" :key="i" class="s-info-contact-sales__description-item">
                {{ item }}
            </p>
        </div>
        <template v-if="content.list">
            <div
                v-for="(list, name) in content.list"
                :key="name"
                class="s-info-contact-sales__info"
                :class="className(name)"
            >
                <p v-if="list.title" class="s-info-contact-sales__info-title">
                    <strong>{{ list.title }}</strong>
                </p>
                <ul v-if="list.items" class="s-info-contact-sales__list">
                    <li v-for="(item, i) in list.items" :key="i" class="s-info-contact-sales__list-item">
                        <template v-if="!!item.number">
                            <div v-if="item.title" class="s-info-contact-sales__list-item-title">
                                {{ item.title }}
                            </div>
                        </template>
                        <a-link
                            v-if="item.link"
                            class="a-link s-info-contact-sales__link"
                            v-bind="item"
                            :to="replaceLocale(item.link)"
                            :target="item.target || '_blank'"
                            :text="item.title"
                        />
                        <a-link
                            v-if="item.number"
                            class="a-link s-info-contact-sales__phone"
                            :text="item.number"
                            :to="phoneNumber(item.number)"
                            :event="setEvent(item)"
                        />
                    </li>
                </ul>
            </div>
        </template>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';
import form from '@core/mixins/form.js';

export default {
    name: 'SInfoContactSales',

    components: {
        ALogo,
        ALink,
    },

    mixins: [form],

    props: {
        content: {
            type: Object,
            default: null,
        },
    },

    methods: {
        phoneNumber(number) {
            return `tel: +${number.replace(/[^0-9]/g, '')}`;
        },

        className(name) {
            return `s-info-contact-sales__info-${name}`;
        },

        setEvent(item) {
            return { action: item.number, category: 'click' };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-info-contact-sales {
    flex: 1;
    text-align: center;

    &__logo {
        &:deep(.a-logo) {
            margin: 0 0 8px;
        }
    }

    &__logo-title {
        @mixin display-accent;
        margin: 8px;
        color: var(--av-nav-primary);

        @media (--viewport-mobile-wide) {
            @mixin hero-accent;
        }
    }

    &__info-title,
    &__info-support {
        display: none;
    }

    &__description-item {
        @mixin lead;
    }

    &__list {
        margin: 8px 0 0;
    }

    &__list-item {
        @mixin body;
        &:not(:first-child) {
            margin: 8px 0 0;
        }
    }

    &__phone {
        @mixin lead;
    }
}

@media (--viewport-desktop) {
    .s-info-contact-sales {
        padding: 0;
        text-align: start;
        padding-inline-end: 32px;

        &__logo-title {
            @mixin large-accent;
            margin: 24px 0 16px;
        }

        &__info {
            margin: 48px 0 0;
        }

        &__info-title {
            @mixin body;
            display: block;
        }

        &__info-support {
            display: block;
        }

        &__info-phone {
            .s-info-contact-sales__list-item {
                &:not(:first-child) {
                    margin: 24px 0 0;
                }
            }
        }

        &__list {
            margin: 24px 0 0;
        }

        &__list-item {
            @mixin body;
            &:not(:first-child) {
                margin: 8px 0 0;
            }
        }
    }
}
</style>
