<template>
    <div class="blog-pager">
        <div class="arrow" :class="{ disabled: currentPage === 1 }" @click="prev()">
            <a-glyph class="glyph" name="icon-pointer-left" />
        </div>
        <div class="pages">
            <a
                v-for="i in visibleWindow"
                :key="i"
                class="page"
                :class="{ active: currentPage === i }"
                :href="getHref(i)"
                @click.prevent="onPageSelected(i)"
            >
                {{ i }}
            </a>
        </div>
        <div class="arrow arrow_right" :class="{ disabled: currentPage === totalPages }" @click="next()">
            <a-glyph class="glyph" name="icon-pointer-right" />
        </div>
    </div>
</template>

<script>
import { range } from 'lodash';
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'BlogPager',

    components: {
        AGlyph,
    },

    props: {
        currentPage: {
            type: Number,
            default: 1,
        },

        totalPages: {
            type: Number,
            default: 1,
        },

        visibleWindowLength: {
            type: Number,
            default: 7,
        },

        baseUrl: {
            type: String,
            required: true,
        },
    },

    emits: ['pageSelected'],

    computed: {
        visibleWindow() {
            const wing = Math.floor(this.visibleWindowLength / 2);

            const rightLack = Math.max(0, wing - (this.totalPages - this.currentPage));
            const leftLack = Math.max(0, wing - this.currentPage + 1);

            const leftBound = Math.max(1, this.currentPage - wing - rightLack);
            const rightBound = Math.min(this.totalPages, this.currentPage + wing + leftLack);

            return range(leftBound, rightBound + 1);
        },
    },

    methods: {
        onPageSelected(val) {
            this.$emit('pageSelected', val);
        },

        prev() {
            this.onPageSelected(this.currentPage - 1);
        },

        next() {
            this.onPageSelected(this.currentPage + 1);
        },

        getHref(pageNumber) {
            return `${this.baseUrl}page/${pageNumber}`;
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-pager {
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    width: 306px;
    padding: 0 48px;

    .arrow {
        position: absolute;
        inset-inline-start: 0;
        width: 48px;
        height: 48px;
        border: 1px solid var(--av-brand-secondary-light);
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 0.25s ease-out;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        &.disabled {
            pointer-events: none;
            border-color: var(--av-brand-accent);
        }

        &:hover {
            background-color: var(--av-brand-lightest);
        }

        &:active {
            background-color: var(--av-brand-accent);
        }

        &:focus {
            border: 3px solid var(--av-brand-secondary-light);
        }

        &_right {
            inset-inline-start: auto;
            inset-inline-end: 0;
        }
    }

    .glyph {
        fill: var(--av-brand-secondary);
        transition: fill 0.25s ease-out;
    }

    .pages {
        display: inline-flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .page {
        @mixin paragraph;

        min-width: 30px;
        aspect-ratio: 1;
        text-align: center;
        margin: 0 -4px;
        position: relative;
        padding: 0 10px;
        cursor: pointer;
        color: var(--av-fixed-light);
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 0;

        &.active {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                inset-inline-start: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background: var(--av-brand-accent);
            }
        }
    }
}
</style>
