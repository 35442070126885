<template>
    <div class="a-webinar-card" :class="{ 'a-webinar-card--shadow': cardShadow, 'a-webinar-card--mini': miniCard }">
        <a-picture
            v-if="!miniCard && image"
            class="a-webinar-card__img"
            :link="image"
            :alt="imageAlt"
            size="medium"
            :position="picturePosition"
            fit="cover"
        />
        <div class="a-webinar-card__info">
            <div v-if="label && !hasEnded" class="a-webinar-card__date label-wrapper">
                <a-glyph class="a-accordion__icon" :name="label.glyph" />
                <span>{{ label.text }}</span>
            </div>
            <div
                v-else
                class="a-webinar-card__date"
                :class="{'a-webinar-card__date--ended': hasEnded}"
            >
                <template v-if="hasEnded">
                    <a-glyph class="a-accordion__icon" name="play" />
                    <span>Webinar on demand</span>
                </template>
                <template v-else>
                    <a-glyph class="a-accordion__icon" name="calendar" />
                    <span>{{ date }}</span>
                </template>
            </div>
            <div class="a-webinar-card__title">
                {{ title }}
            </div>
            <div class="a-webinar-card__desc">
                {{ desc }}
            </div>
            <div class="a-webinar-card__bottom">
                <div v-if="!miniCard && speakers.length" class="a-webinar-card__speakers">
                    <p class="a-webinar-card__speakers-title">
                        {{ speakersText }}
                    </p>
                    <ul class="a-webinar-card__first-speakers">
                        <li
                            v-for="speaker in speakers"
                            :key="speaker.name"
                            class="a-webinar-card__first-speakers-item"
                        >
                            <span class="a-webinar-card__first-speakers-name">{{ speaker.name }},&nbsp;</span>
                            <span v-if="speaker.position" class="a-webinar-card__first-speakers-position">{{ speaker.position }}</span>
                        </li>
                    </ul>
                </div>
                <a-link
                    v-if="miniCard"
                    v-bind="link"
                    :glyph="link.glyph || 'arrow'"
                    :size="link.size || 'paragraph'"
                />
                <a-button
                    v-else
                    class="a-webinar-card__link"
                    v-bind="link"
                    :type="link.type || 'action'"
                    :size="link.size || 's'"
                    :glyph="link.glyph || 'arrow'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'AWebinarCard',

    components: {
        AGlyph,
        AButton,
        ALink,
        APicture,
    },

    mixins: [breakpoint],

    props: {
        speakers: {
            type: Array,
            default: () => [],
        },

        speakersText: {
            type: String,
            default: undefined,
        },

        hasEnded: {
            type: Boolean,
        },

        date: {
            type: String,
            default: undefined,
        },

        label: {
            type: Object,
            default: undefined,
        },

        title: {
            type: String,
            required: true,
        },

        desc: {
            type: String,
            required: true,
        },

        link: {
            type: Object,
            default: () => ({}),
        },

        miniCard: {
            type: Boolean,
        },

        image: {
            type: String,
            default: undefined,
        },

        imageAlt: {
            type: String,
            default: undefined,
        },

        imagePosition: {
            type: String,
            // new cards are wide and focused off-center to the right
            default: '67% center',
        },

        imageMobilePosition: {
            type: String,
            default: '74% center',
        },

        cardShadow: {
            type: Boolean,
        },
    },

    computed: {
        picturePosition() {
            return this.isMobile ? this.imageMobilePosition : this.imagePosition;
        },
    },
};
</script>

<style lang="postcss">
.a-webinar-card {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &--shadow {
        margin-top: 18px;
        box-shadow: var(--av-shadow-small);
        border-radius: 4px;
        border: 1px solid var(--av-brand-accent);
        width: 100%;

        &__info {
            border-inline-end: var(--av-border);
            border-bottom: var(--av-border);
            border-inline-start: var(--av-border);
            border-radius: 0 0 4px 4px;

            @media (--viewport-tablet) {
                border-top: var(--av-border);
                border-inline-end: var(--av-border);
                border-bottom: var(--av-border);
                border-inline-start: none;
                border-radius: 0 4px 4px 0;
            }
        }
    }

    &:not(.a-webinar-card--mini) {
        .a-webinar-card__desc {
            margin-bottom: 32px;
            line-height: 24px;

            @media (--viewport-desktop) {
                margin-bottom: 40px;
                margin-inline-end: 10px;
            }
        }
    }

    &--mini:not(&--shadow) {
        .a-webinar-card__info {
            padding-inline-start: 0;

            @media (--viewport-tablet) {
                padding-top: 64px;
            }
        }
    }

    @media (--viewport-tablet) {
        flex-direction: row;
    }

    @media (--viewport-desktop-wide) {
        width: 85%;
    }

    .a-picture {
        display: flex;
        min-height: 212px;
        max-height: 300px;
        height: auto;

        @media (--viewport-tablet) {
            display: block;
            min-height: 400px;
            max-height: none;
            min-width: 276px;
            max-width: 276px;
        }

        @media (--viewport-desktop) {
            min-height: 300px;
            min-width: 272px;
            max-width: 272px;
        }

        .a-picture__img {
            min-width: 100%;
            min-height: 100%;
        }
    }

    &__info {
        padding: 24px;
    }

    &__lang {
        @mixin body;

        margin-bottom: 8px;
        color: var(--av-brand-secondary);
    }

    &__title {
        @mixin title-accent;

        color: var(--av-nav-primary);
        margin-bottom: 8px;
    }

    &__desc {
        @mixin paragraph;

        margin-bottom: 24px;
    }

    &__date {
        @mixin body-accent;

        margin-bottom: 8px;
        color: var(--av-fixed-success);
        display: flex;
        align-items: center;

        .a-glyph {
            margin-top: 0;
            margin-inline-end: 11px;
            height: 52px;
            width: 18px;
            fill: var(--av-fixed-success);
        }

        &--ended {
            color: var(--av-brand-secondary);

            .a-glyph {
                fill: var(--av-brand-secondary);
            }
        }

        &.label-wrapper {
            fill: var(--av-brand-secondary);
            color: var(--av-brand-secondary);
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;

        @media (--viewport-tablet) {
            align-items: flex-start;
        }

        @media (--viewport-desktop) {
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    &__speakers {
        margin-bottom: 32px;

        @media (--viewport-desktop) {
            margin-bottom: 0;
            margin-inline-end: 24px;
        }
    }

    &__speakers-title {
        @mixin paragraph-accent;

        color: var(--av-nav-primary);
        margin-bottom: 8px;
    }

    &__first-speakers-name {
        @mixin body-accent;

        color: var(--av-fixed-primary);

        @media (--viewport-tablet) {
            margin-inline-end: 4px;
        }
    }

    &__speakers-comma {
        display: none;
    }

    &__first-speakers-position {
        @mixin body;

        color: var(--av-fixed-secondary);
    }

    &__link {
        width: 100%;
        justify-content: center;
        margin-top: 0;
        margin-inline-start: auto;

        svg {
            display: none;
        }

        @media (--viewport-tablet) {
            width: 240px;
            justify-content: space-between;

            svg {
                display: block;
            }
        }
    }

    &:deep(.a-slider__nav) {
        margin-top: 24px;
        margin-bottom: 16px;
    }
}
</style>
