<template>
    <div v-if="isVisible" class="main-page-cta">
        <template v-for="(item, i) in content">
            <a-link v-if="item.isLink" :key="`item-cta-${i}`" v-bind="item" />
            <a-button
                v-else
                :key="`item-cta-${i}`"
                v-bind="{ type: 'main', size: 'm', ...item }"
                @click="$emit('click')"
            />
        </template>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';

export default {
    name: 'SMainPageCTA',
    components: {
        AButton,
        ALink,
    },
    props: {
        content: {
            type: Array,
            default: null,
        },
    },
    emits: ['click'],
    computed: {
        isVisible() {
            return this.content?.length;
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-cta {
    gap: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (--viewport-tablet) {
        flex-direction: row;
    }
    &:deep(.a-button) {
        width: 100%;
        @media (--viewport-tablet) {
            width: auto;
        }
        &_type_inverse {
            color: var(--av-brand-primary);
            border: 1px solid var(--av-brand-primary);
            &:hover {
                background: var(--av-brand-accent);
            }
            &:active {
                background: var(--el-secondary-active);
            }
            .a-button__content {
                color: var(--av-brand-primary);
            }
        }
    }
}
</style>
