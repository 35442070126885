<template>
    <div class="s-video-item">
        <button v-modal="id" class="s-video-item__btn" type="button">
            <div class="s-video-item__left">
                <a-glyph
                    class="s-video-item__glyph"
                    name="video"
                    fill="brand-primary"
                />
                <span class="s-video-item__time">{{ time }}</span>
            </div>
            <div class="s-video-item__right">
                <span class="s-video-item__title">{{ title }}</span>
                <span class="s-video-item__desc">{{ desc }}</span>
            </div>
        </button>

        <a-modal
            :id="id"
            type="playlist"
            is-youtube
        >
            <template #default>
                <a-youtube :yt-id="ytId" :params="ytParams" :title="title" />
            </template>

            <template #playlist>
                <a-modal-playlist
                    :title="title"
                    :desc="desc"
                    :prev-title="prevTitle"
                    :prev-id="prevId"
                    :next-title="nextTitle"
                    :next-id="nextId"
                />
            </template>
        </a-modal>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import AModalPlaylist from '@core/components/modal/modal-playlist.vue';
import AModal from '@core/components/modal/modal.vue';
import AYoutube from '@core/components/youtube/youtube.vue';
import Modal from '@core/directives/modal.js';

export default {
    name: 'SVideoItem',

    directives: {
        Modal,
    },

    components: {
        AYoutube,
        AGlyph,
        AModal,
        AModalPlaylist,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        desc: {
            type: String,
            required: true,
        },

        time: {
            type: String,
            required: true,
        },

        id: {
            type: String,
            required: true,
        },

        ytId: {
            type: String,
            default: '',
        },

        ytParams: {
            type: Object,
            default: () => ({ autoplay: 1 }),
        },

        prevTitle: {
            type: String,
            default: '',
        },

        prevId: {
            type: String,
            default: '',
        },

        nextId: {
            type: String,
            required: false,
            default: '',
        },

        nextTitle: {
            type: String,
            required: false,
            default: '',
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-video-item {
    height: 100%;

    &__left {
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background: rgba(64, 139, 234, 0.05);
        max-width: 72px;
    }

    &__right {
        padding: 16px;
    }

    &__btn {
        display: flex;
        width: 100%;
        height: 100%;
        background: none;
        border: 1px solid var(--av-brand-secondary-light);
        box-shadow: var(--av-shadow-small);
        border-radius: 4px;
        cursor: pointer;
        transition: box-shadow 0.3s ease-out;

        &:hover {
            box-shadow: var(--av-shadow-regular);
            transition: box-shadow 0.15s ease-out;

            .s-video-item {
                &__title {
                    color: var(--av-nav-secondary);
                    transition: color 0.15s ease-out;
                }

                &__glyph {
                    fill: var(--av-nav-secondary);
                    transition: fill 0.15s ease-out;
                }
            }
        }
    }

    &__title {
        @mixin lead-accent;

        color: var(--av-brand-primary);
        transition: color 0.3s ease-out;
    }

    &__time {
        @mixin body-accent;

        margin-top: auto;
        color: var(--av-brand-primary);
    }

    &__glyph {
        display: block;
        min-width: 24px;
        width: 40px;
        height: 40px;
        transition: fill 0.3s ease-out;
    }

    &__desc {
        @mixin body;

        margin-top: 16px;
        display: block;
        color: var(--av-fixed-primary);
    }
}
</style>
