export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=33809-16402&t=JMJ2fbzoLIVoGwai-4';

export const Default = {
    args: {
        translations: {
            settings: {
                title: 'Keep up-to-date with our products and offers',
                button: {
                    text: 'Subscribe now',
                },
            },
        },
    },
};
