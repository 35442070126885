<template>
    <a-svg-sprite
        class="a-icon"
        :name="name"
        :title="iconAlt"
        :class-name="className"
    />
</template>

<script>
import ASvgSprite from '@core/components/svg-sprite/svg-sprite.vue';

export default {
    name: 'AIcon',

    components: { ASvgSprite },

    props: {
        number: {
            type: [String, Number],
            required: true,
        },

        iconVariant: {
            type: Boolean,
            default: false,
        },

        iconAlt: {
            type: String,
            default: '',
        },

        className: {
            type: Object,
            required: false,
            default: undefined,
        },

        theme: {
            type: String,
            default: null,
        },
    },

    computed: {
        /**
         * Returns svg path
         *
         * @returns string
         */
        name() {
            const { number, iconVariant } = this;
            const variant = iconVariant ? 'S' : 'L';
            let iconName = String(number);

            while (iconName.length < 4) {
                iconName = `0${iconName}`;
            }

            return [iconName, variant, this.theme].filter(Boolean).join('_');
        },
    },
};
</script>

<style lang="postcss">
.a-icon {
    display: block;
    vertical-align: bottom;
    max-width: 100%;
    max-height: 100%;
}
</style>
