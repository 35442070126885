<template>
    <div class="resource-search-tag" @click="toggleTag">
        <span>{{ title }}</span>
        <a-glyph v-show="selected" name="close" size="xs" />
    </div>
</template>

<script lang="ts">
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'ResourceSearchTag',

    components: {
        AGlyph,
    },

    props: {
        id: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['select', 'remove'],

    methods: {
        toggleTag() {
            this.$emit(this.selected ? 'remove' : 'select', this.id);
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-search-tag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 0 12px 1px;
    background: var(--av-brand-lightest);
    border: 1px solid var(--av-brand-light);
    border-radius: 27px;
    @mixin body;
    color: var(--av-fixed-primary);
    cursor: pointer;

    &:deep(.a-glyph) {
        width: 10px;
        height: 10px;
        margin-top: 2px;
        flex-shrink: 0;
        cursor: pointer;
    }
}
</style>
