<template>
    <div class="main-footer">
        <div class="a-container">
            <div class="wrapper">
                <dynamic-link :to="logoLink">
                    <a-picture
                        v-if="logo"
                        class="logo"
                        :link="logo"
                    />
                </dynamic-link>
                <nav class="content">
                    <ul class="links">
                        <li class="item copyright">
                            ©{{ currentYear }} {{ sitename }}
                        </li>
                        <li
                            v-for="(link, index) in links"
                            :key="`footer_links_${index}`"
                            class="item"
                        >
                            <a-link v-bind="link" />
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SAcademyFooter',
    components: {
        APicture,
        ALink,
        DynamicLink,
    },
    props: {
        logoLink: {
            type: String,
            default: '/',
        },
        logo: {
            type: String,
            default: '@7178e73672ff3bb5e7ed0bec61332818',
        },
        sitename: {
            type: String,
            default: 'MSP Academy',
        },
        links: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-footer {
    background: var(--av-solid-brand-accent);
    padding: 40px 0;
    @media (--viewport-desktop) {
        border-top: 1px solid var(--av-brand-light);
    }
    @media (--viewport-desktop-wide) {
        padding-top: 37px;
        padding-bottom: 28px;
    }
    .wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        @media (--viewport-desktop) {
            justify-content: flex-start;
        }
        .logo {
            max-width: 166px;
            margin-bottom: 32px;
            @media (--viewport-desktop) {
                margin: 0;
            }
        }
        .content {
            @mixin body;
            color: var(--av-brand-primary);
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            margin: 0 auto;
            width: 100%;
            justify-content: center;
            @media (--viewport-desktop) {
                width: auto;
                margin: 0;
                margin-inline-start: auto;
                justify-content: flex-start;
            }

            .links {
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                gap: 24px;
                justify-content: center;
                .item {
                    @media (--viewport-tablet) {
                        border-inline-end: 1px solid var(--av-brand-light);
                        padding-inline-end: 24px;
                    }
                    &:last-child {
                        border: none;
                        padding-inline-end: 0;
                    }
                }
            }
        }
    }
}
</style>
