<template>
    <el-form
        ref="form"
        autocomplete="off"
        name="register"
        class="form step-form a-container"
        :model="form"
        :rules="rules"
        @submit="submit"
    >
        <h2 class="title">
            {{ uiStrings.formTitle }}
        </h2>

        <div class="section-title">
            {{ uiStrings.sectionTitleAccountAdministrator }}
        </div>

        <el-form-item prop="first_name">
            <el-input
                v-model="form.first_name"
                :label="uiStrings.formLabelFirstName"
                name="first_name"
                type="text"
            />
        </el-form-item>

        <el-form-item prop="last_name">
            <el-input
                v-model="form.last_name"
                :label="uiStrings.formLabelLastName"
                name="last_name"
                type="text"
            />
        </el-form-item>

        <el-form-item prop="email">
            <el-input
                ref="email"
                v-model="form.email"
                :label="uiStrings.formLabelEmail"
                name="email"
                type="email"
                @change="checkGdprOptInStatus()"
            />
        </el-form-item>

        <div class="section-title">
            {{ uiStrings.sectionTitleCompanyInformation }}
        </div>

        <el-form-item prop="company">
            <el-input
                v-model="form.company"
                :label="uiStrings.formLabelCompanyName"
                name="company"
                type="text"
            />
        </el-form-item>

        <el-form-item prop="company_type">
            <el-select
                v-model="form.company_type"
                :label="uiStrings.formLabelCompanyType"
                :hide-on-resize="isDesktop"
                name="company_type"
                class="select"
            >
                <el-option
                    v-for="item in uiStrings.formSelectorCompanyTypes"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                />
            </el-select>
        </el-form-item>

        <el-form-item prop="phone">
            <el-input
                v-model="form.phone"
                :label="uiStrings.formLabelBusinessPhone"
                name="phone"
                type="phone"
            />
        </el-form-item>

        <el-form-item prop="company_size">
            <el-select
                v-model="form.company_size"
                :label="uiStrings.formLabelCompanySize"
                :hide-on-resize="isDesktop"
                name="company_size"
                class="select"
            >
                <el-option
                    v-for="item in uiStrings.formSelectorCompanySizes"
                    :key="item"
                    :label="item"
                    :value="item"
                />
            </el-select>
        </el-form-item>

        <el-form-item prop="country">
            <el-select
                v-model="form.country"
                v-bind="labelSelect"
                :label="uiStrings.formLabelCountry"
                name="country"
                class="select"
                filterable
                :hide-on-resize="isDesktop"
            >
                <el-option
                    v-for="item in countries"
                    :key="item.iso_code"
                    :label="item.name"
                    :value="item.iso_code"
                />
            </el-select>
        </el-form-item>

        <el-form-item v-if="countryRegions[form.country]" prop="state">
            <el-select
                v-model="form.state"
                v-bind="labelSelect"
                :label="uiStrings.formLabelState"
                name="state"
                class="select"
                filterable
                :hide-on-resize="isDesktop"
            >
                <el-option
                    v-for="item in countryRegions[form.country]"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                />
            </el-select>
        </el-form-item>

        <el-form-item v-if="showPostalCodeField" prop="postal_code">
            <el-input
                v-model="form.postal_code"
                :label="uiStrings.formLabelPostalCode"
                name="postal_code"
                type="text"
            />
        </el-form-item>

        <div class="actions">
            <div class="reg-line">
                <el-form-item prop="terms">
                    <el-checkbox v-model="form.terms" name="terms" class="checkbox-label">
                        <span class="text">
                            <a-dangerous-html :content="uiStrings.formMessageTermsAndConditions" />
                        </span>
                    </el-checkbox>
                </el-form-item>

                <label v-if="isVisibleOptIn" for="subscribe" class="checkbox-label">
                    <el-checkbox v-model="form.gdpr_opt_in" name="gdpr_opt_in">
                        <span class="text">
                            {{ uiStrings.formMessageNewsOptIn }}
                        </span>
                    </el-checkbox>
                </label>
            </div>

            <el-form-error
                v-if="error"
                size="large"
                class="error-message"
                show-icon
            >
                {{ error }}
            </el-form-error>

            <a-button
                :text="uiStrings.formSubmitButtonText"
                type="main"
                size="m"
                native-type="submit"
                class="submit"
                :event="{ doNotSendGA: true }"
            />

            <div class="recaptcha-text">
                <a-dangerous-html :content="uiStrings.formMessageRecaptchaProtected" />
            </div>

            <div ref="recaptcha" class="g-recaptcha" />
        </div>

        <div
            v-if="xhrProgressCounter > 0"
            v-loading="true"
            class="loading"
            el-loading-size="48"
            el-loading-background="fixed-primary"
            el-loading-opacity="0.2"
        />
    </el-form>
</template>

<script lang="ts">
// TODO: probably can be done with a form constructor?
// TODO: can be better aligned with `form` and `recaptcha` mixins – to not reimplement what's already done there

import Loading from '@uikit/ui-kit/packages/loading/src/directive.js';
import Vue from 'vue';
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';
import recaptcha from '@core/mixins/recaptcha.js';
import USStates from '@model/const/states';
import formHelper from '@utils/form.js';

export default Vue.extend({
    name: 'DeveloperRegisterProgramStepForm',

    components: {
        AButton,
        ADangerousHtml,
        ElForm: () => import('@uikit/ui-kit/packages/form'),
        ElFormItem: () => import('@uikit/ui-kit/packages/form-item'),
        ElFormError: () => import('@uikit/ui-kit/packages/form-error'),
        ElInput: () => import('@uikit/ui-kit/packages/input'),
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
        ElCheckbox: () => import('@uikit/ui-kit/packages/checkbox'),
    },

    directives: {
        Loading,
    },

    mixins: [form, recaptcha, breakpoint],

    props: {
        uiStrings: {
            type: Object,
            required: true,
        },
    },

    emits: ['success'],

    data() {
        return {
            // UI
            xhrProgressCounter: 0,
            isVisibleOptIn: false,
            recaptchaResponse: '',
            error: '',

            // Form
            form: {
                form_id: 'developer_portal_registration',
                sfdc_campaign_id: '7011T000001FPEiQAO',
                lead_source: 'Developer Network',
                new_lead_source_detail: 'Platform Partner Registration',
                first_name: null,
                last_name: null,
                email: '',
                phone: '',
                gdpr_opt_in: false,
                country: '',
                state: '',
                company: '',
                company_size: '',
                company_type: '',
                terms: false,
                utm_campaign: null,
                utm_content: null,
                utm_term: null,
                utm_medium: null,
                utm_source: null,
            },

            // Selectors
            countryRegions: {
                US: USStates,
            },
        };
    },

    computed: {
        errorPostalCode() {
            return this.setErrorMessage('notFilledPostalCode');
        },
        countries() {
            return Array
                .from(this.$store.state.countries.items || [])
                .sort((a, b) => String(a.name).localeCompare(b.name));
        },
        showPostalCodeField() {
            return this.form.country === 'DE';
        },
        rules() {
            return {
                first_name: {
                    required: true,
                    whitespace: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledFirstName'),
                },
                last_name: {
                    required: true,
                    whitespace: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledLastName'),
                },
                email: {
                    required: true,
                    whitespace: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledEmail'),
                    validator: this.validateEmail.bind(this),
                },
                phone: {
                    required: true,
                    whitespace: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledPhone'),
                    validator: this.validatePhone.bind(this),
                },
                country: {
                    required: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledCountry'),
                },
                state: {
                    required: false,
                    trigger: 'debounce-input blur',
                    validator: (rule, val, cb) => {
                        const errors = [
                            (this.form.country in this.countryRegions) && !val ? new Error(this.setErrorMessage('notFilledState')) : null,
                        ];
                        return cb(errors.filter(Boolean));
                    },
                },
                postal_code: {
                    required: false,
                    trigger: 'debounce-input blur',
                    validator: (rule, val, cb) => {
                        const errors = [
                            this.showPostalCodeField && !val ? new Error(this.errorPostalCode) : null,
                            this.showPostalCodeField && val && !/^\d+$/.test(val) ? new Error(this.errorPostalCode) : null,
                        ];
                        return cb(errors.filter(Boolean));
                    },
                },
                company: {
                    required: true,
                    whitespace: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledCompany'),
                },
                company_size: {
                    required: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledCompanySize'),
                },
                company_type: {
                    required: true,
                    trigger: 'debounce-input blur',
                    message: this.setErrorMessage('notFilledCompanyType'),
                },
                terms: {
                    // TODO: `enum` validator doesn't work because async-validator peered with @uikit is dramatically outdated (v1.8.5)
                    // and has a bug with validatation of the `false` value, as it does `if (value)` internally.
                    // There is a chance that fresh @uikit v23 has this peer dep updated and this rule works there.
                    // Meanwhile we'll have to mimic this behavior with a custom validator function.
                    // See also: this commit https://github.com/yiminghe/async-validator/commit/756f5e1023d5305104a6758517b592f52657cf70
                    // type: 'enum',
                    // enum: [true],
                    trigger: 'debounce-input blur',
                    validator: (rule, val, cb) => {
                        const errors = [
                            val ? null : new Error(this.setErrorMessage('platformTerms')),
                        ];
                        return cb(errors.filter(Boolean));
                    },
                },
            };
        },
    },

    async mounted() {
        this.recaptchaInject();

        await Promise.all([
            this.acronisAccountAutofill(),
            this.geolocationAutofill(),
        ]);
    },

    methods: {
        assemblePayload() {
            const clientId = formHelper.getCIDval() || '[ERROR] Client ID not generated';

            const payload = {
                ...this.form,
                ck: formHelper.getCookies(formHelper.MRKTO_COOKIE_NAME) || formHelper.createMarketoToken(),
                _recaptchaResponse: this.recaptchaResponse,
                google_analytics_clientid: clientId,
                google_analytics_id: clientId,
                google_analytics_trackingid: formHelper.GA_TRACKING_ID,
                google_analytics_userid: formHelper.getCookies(formHelper.UID_COOKIE_NAME) || '',
            };

            Object.keys(payload)
                .filter((i) => !payload[i as keyof object])
                .forEach((i) => delete payload[i as keyof object]);

            return payload;
        },

        async checkGdprOptInStatus() {
            this.form.gdpr_opt_in = false;

            if (this.form.email === '' || !this.form.country || this.form.country === 'US') {
                this.isVisibleOptIn = true;
                return;
            }

            try {
                this.xhrProgressCounter++;
                const resp: any = await this.$store.dispatch('trial/getOptInStatus', this.form.email);

                this.isVisibleOptIn = resp?.success && !resp.data?.optIn;
                this.form.gdpr_opt_in = !resp?.success || resp.data?.optIn;
            } finally {
                this.xhrProgressCounter--;
            }
        },

        async acronisAccountAutofill() {
            this.xhrProgressCounter++;
            await this.$store.dispatch('user/get');
            this.xhrProgressCounter--;

            const account = this.$store.state.user.data;

            if (!account) return;

            this.form.email = account.email || '';
            this.form.first_name = account.first || '';
            this.form.last_name = account.last || '';
            this.form.company = account.company_name || '';
            this.form.company_type = this.companyTypes.find((x) => x.value === account.company_type)?.value || '';
            this.form.company_size = account.company_size;
            this.form.phone = account.phone || '';
            this.form.country = account.country || '';
            this.form.state = account.state_province;

            // Force optin status check after the programmatic email change
            await this.checkGdprOptInStatus();

            this.$refs.form.clearValidate();
        },

        async geolocationAutofill() {
            this.xhrProgressCounter++;
            await this.$store.dispatch('geolocation/getGeoLocation');
            this.xhrProgressCounter--;

            const loc = this.$store.state.geolocation.location;

            if (!loc) return;

            this.form.country = loc.data.country?.code;
        },

        async validateRecaptcha() {
            const res = await this.recaptchaActive().execute(this.recaptchaWidgetId);
            return res;
        },

        validateForm() {
            // TODO: `await this.$refs.form.validate();` throws a very obscure error in storybook
            return new Promise((resolve) => {
                this.$refs.form.validate(resolve);
            });
        },

        async submit() {
            this.error = '';

            this.recaptchaResponse = await this.validateRecaptcha();
            if (!this.recaptchaResponse) {
                this.error = this.setErrorMessage('notFilledCaptcha');
                return;
            }

            const isFormValid = await this.validateForm();
            if (!isFormValid) {
                return;
            }

            const payload = this.assemblePayload();

            try {
                this.xhrProgressCounter++;
                await this.$store.dispatch('trial/sendLead', payload);
                this.$emit('success');
            } catch (e) {
                this.error = this.getSAPIErrorMessage(e);
            } finally {
                this.xhrProgressCounter--;
            }
        },

        getSAPIErrorMessage(error) {
            const isEmailError = error?.ex && error?.code === 'invalid_email';

            if (isEmailError) {
                const isTypo = error.ex.reason === 'failed custom grammar check' || error.ex.didYouMean;
                const errorKey = isTypo ? 'invalidMailTypo' : 'invalidMailGeneral';
                return this.formElementsDefault.errors?.eventsMailGunErrors?.[errorKey] || 'Error';
            }

            const specificError = this.formElementsDefault.errors?.apiErrorCodes?.[error.code] || 'Error';
            const defaultError = this.formElementsDefault.errors?.apiErrorCodes?.default || 'Error';
            return specificError || defaultError;
        },
    },
});
</script>

<style lang="postcss" scoped>
.step-form {
    position: relative;
    text-align: start;
}

.loading {
    position: absolute !important;
    inset-inline-start: 0;
    inset-inline-end: 0;
    top: 0;
    bottom: 0;
}

.title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    margin: 0 0 16px 0;
    color: var(--av-fixed-primary);
    text-align: center;
}

.section-title {
    font-size: 16px;
    line-height: 24px;
    margin: 32px 0 16px;
}

.submit {
    width: 100%;
    margin-top: 24px;
}

.actions {
    font-size: 14px;
    line-height: 23px;
}

.checkbox-label {
    /* TODO: align to the first line? */
}

.recaptcha-text {
    margin-top: 24px;
}
</style>
