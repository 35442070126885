<template>
    <div class="a-tab" data-tab-panel>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ATab',

    props: {
        /**
         * Title
         */
        label: {
            type: String,
            required: true,
        },

        labelIcon: {
            type: String,
            default: undefined,
        },

        labelIconAlt: {
            type: String,
            default: undefined,
        },

        anchorId: {
            type: String,
            default: undefined,
        },

        /**
         * Subtitle
         */
        translatedLabel: {
            type: String,
            default: undefined,
        },
        glyph: {
            type: Object,
            default: null,
        },
        aLabel: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-tab {
    display: none;
    &_active {
        display: block;
    }
}
</style>
