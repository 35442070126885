import Cookies from 'js-cookie';

export default {
    data() {
        return {
            LOCALE_COOKIE_NAME: 'language_prefix',
            LOCALE_COOKIE_EXPIRATION: 30,
        };
    },

    computed: {
        locale() {
            return this.$route?.params.locale || 'en-us';
        },
    },

    methods: {
        storeLocaleInCookies(locale) {
            if (!locale) return;

            Cookies.set(this.LOCALE_COOKIE_NAME, locale, {
                path: '/',
                expires: this.LOCALE_COOKIE_EXPIRATION,
            });
        },
    },
};
