export default {
    props: {
        /**
         * Slice id
         */
        id: {
            type: String,
        },

        /**
         * Slice title
         */
        title: {
            type: String,
        },

        /**
         * Slice lead
         */
        lead: {
            type: String,
        },

        /**
         * Slice body text
         */
        bodyText: {
            type: String,
        },
    },

    inheritAttrs: false,
};
