<template>
    <div v-if="tags.length" class="asset-tags-list">
        <dynamic-link
            v-for="(tag, index) in tags"
            :key="index"
            :to="tag.to"
            class="list-item"
        >
            {{ tag.text }}
        </dynamic-link>
    </div>
</template>

<script lang="ts">
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';

export default {
    components: {
        DynamicLink,
    },

    props: {
        tags: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.asset-tags-list {
    margin-top: 72px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 20px;

    @media (--viewport-mobile-wide) {
        padding: 0 138px;
    }
}

.list-item {
    @mixin note-heading;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 0 8px;
    color: var(--av-brand-primary);
    background: var(--av-brand-accent);
    border-radius: 4px;
}
</style>
