<script lang="ts">
import Vue from 'vue';
import BuilderComponent from './component.vue';

export default Vue.extend({
    name: 'BuilderContainer',

    serverPrefetch(): any {
        this.$ssrContext.res.meta = this.getMeta();
    },

    methods: {
        getMeta(): any {
            const title = 'Acronis - Slice Builder (Internal)';

            const meta = {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'robots', content: 'noindex, nofollow' },
                ],
            };

            return meta;
        },
    },

    render(h) {
        return h(BuilderComponent);
    },
});
</script>
