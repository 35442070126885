<template>
    <section :id="id" class="s-product-review" :class="{...basicClassName}">
        <div class="a-container">
            <div class="a-slice-header s-product-review__header">
                <h2 class="a-slice-header__title s-product-review__title" :class="titleClasses">
                    <span
                        v-if="l10n.title"
                        class="s-product-review__title__text"
                    >
                        {{ l10n.title }}
                    </span>

                    <span
                        v-if="ratingValue && l10n.reviews?.length > 1"
                        class="s-product-review__rate"
                    >
                        <a-svg-sprite class="s-product-review__rate__star" name="star" />

                        <span class="s-product-review__rate__value">
                            {{ ratingValue }}
                        </span>

                        <span class="s-product-review__rate__text">
                            {{ l10n.averageRatingText }}
                        </span>
                    </span>
                </h2>
            </div>
            <p v-if="l10n.subheader" class="s-product-review__subheader">
                {{ l10n.subheader }}
            </p>
            <a-slider
                :options="{ ...sliderOptions, loop: infinite }"
                :class="{ 'a-slider_off': l10n.reviews.length === 1, 'a-slider_numbers_off': l10n.reviews.length < 4 }"
            >
                <a-slide v-for="(item, index) in l10n.reviews" :key="index">
                    <a-review-card v-bind="{ ...item, type, noImage }" :ll="l10n.showLessText" :lm="l10n.showMoreText" />
                </a-slide>
            </a-slider>
            <a-link
                v-if="l10n.allReviewsLink && sliderOptions.showNavigation"
                class="s-product-review__reviews-link"
                v-bind="l10n.allReviewsLink"
                :size="l10n.allReviewsLink.size || 'title'"
                accent
                :glyph="l10n.allReviewsLink.glyph || 'arrow'"
            />
        </div>
    </section>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import AReviewCard from '@core/components/review-card/review-card.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import ASvgSprite from '@core/components/svg-sprite/svg-sprite.vue';
import contentMixin from '@core/mixins/content.js';
import l10n from '@core/mixins/l10n.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SProductReviews',

    components: {
        ASlider,
        ASlide,
        AReviewCard,
        ALink,
        ASvgSprite,
    },

    mixins: [l10n, contentMixin, styleMixin],

    inheritAttrs: false,

    props: {
        infinite: {
            type: Boolean,
            required: false,
            default: false,
        },
        background: {
            type: String,
            required: false,
            default: 'white',
        },
        subheader: {
            type: String,
            required: false,
            default: undefined,
        },
        allReviewsLink: {
            type: Object,
            required: false,
            default: undefined,
        },
        averageRatingText: {
            type: String,
            required: false,
            default: 'Average rating, out of 5',
        },
        reviews: {
            type: Array,
            required: true,
        },
        showMoreText: {
            type: String,
            default: 'Show more',
        },
        showLessText: {
            type: String,
            default: 'Show less',
        },
        type: {
            type: String,
            required: true,
            validator: (type) => ['personal', 'company'].includes(type),
        },
    },

    data() {
        const NO_IMAGE_LIMIT = 2;

        const showNavigation = this.reviews.length > 1;

        const rateSum = this.reviews.reduce((acc, next) => acc + next.rate, 0);

        // Requirement: if more than 2 personal image are missing, do not use images at all
        const noImageCount =
            this.type === 'personal' ? this.reviews.filter((x) => !x.image).length : 0;

        return {
            sliderOptions: {
                init: this.reviews.length > 1,
                showNavigation,
                // disabled due to Swiper conflict between the Fraction pagination and slidesPerView: auto
                showCounter: false, // : showNavigation
                counterText: 'of',
                sendEvent: true,
                slideName: 'review',
                slidesPerView: 'auto',
            },
            ratingValue: rateSum > 0 ? (rateSum / this.reviews.length).toFixed(1) : 0,
            noImage: noImageCount > NO_IMAGE_LIMIT,
        };
    },

    computed: {
        titleClasses() {
            return {
                's-product-review__title_with-rate': this.l10n.reviews.length > 1,
                's-product-review__title_long': this.l10n.title?.length > 22,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-product-review {
    @mixin basic-slice-paddings;
    overflow: hidden;

    &__header {
        margin-bottom: 24px;
        @media (--viewport-desktop) {
            @mixin colls 8;
        }
    }
    &__title {
        @mixin display;
        @media (--viewport-tablet) {
            @mixin hero;
        }

        @media (--viewport-tablet) {
            &_with-rate {
                &:not(.s-product-review__title_long) {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    .s-product-review__rate {
                        margin-inline-start: 24px;
                    }
                }

                &.s-product-review__title_long {
                    .s-product-review__rate {
                        margin-top: 8px;
                    }
                }
            }
        }
    }

    &__subheader {
        @mixin colls 12;
        @mixin title;
        color: var(--av-nav-primary);
        margin-top: 24px;

        @media (--viewport-desktop) {
            @mixin colls 8;
        }
    }

    &__rate {
        @mixin body;
        display: flex;
        align-items: center;
        margin-top: 8px;

        @media (--viewport-tablet) {
            margin-top: 0;
        }
        &__text {
            @mixin paragraph;
            color: var(--av-fixed-secondary);
        }
        &__star {
            margin-inline-end: 8px;
            width: 32px;
            height: 32px;
            fill: var(--av-fixed-success);
        }
        &__value {
            @mixin display;
            margin-inline-end: 16px;

            @media (--viewport-mobile-wide) {
                @mixin hero;
                margin-inline-end: 24px;
            }
        }
    }

    &:deep(.a-slider) {
        margin-top: 48px;
        overflow: hidden;
        @media (--viewport-tablet) {
            overflow: visible;
        }
        @media (--viewport-desktop-large) {
            overflow: hidden;
        }
        .a-slide {
            @mixin colls 12;
            @media (--viewport-tablet) {
                @mixin colls 9;
                margin-inline-end: calc(8.3333% - 14.6667px + 32px);
            }
            @media (--viewport-desktop) {
                @mixin colls 8;
            }
            &:last-child {
                @mixin colls 12;
                margin-inline-end: 0;
                .a-review-card {
                    @mixin colls 12;
                    @media (--viewport-tablet) {
                        @mixin colls 9;
                    }
                    @media (--viewport-desktop) {
                        @mixin colls 8;
                    }
                }
            }
            &_next {
                opacity: 0.5;
                position: relative;
            }
            &.swiper-slide-prev {
                opacity: 0;
            }
        }
        &__nav {
            margin-top: 48px;
            margin-bottom: 0;
        }
        &__counter {
            &-total,
            &-text,
            &-current {
                @mixin lead;
                color: var(--av-fixed-light);
            }
        }
        &_numbers_off {
            .a-slider__dots {
                display: none;
            }
        }
    }
    &__reviews-link {
        margin-top: 48px;
    }
    &.s-slice_background_dark-blue {
        .s-product-review__title__text,
        .s-product-review__rate__value,
        .s-product-review__rate__text,
        .s-product-review__subheader,
        .a-review-card__title,
        .a-review-card__text,
        .a-review-card__author,
        .a-review-card__rate__value {
            color: var(--av-inversed-primary);
        }
        .a-slider__counter {
            &-total,
            &-text,
            &-current {
                color: var(--av-inversed-primary);
            }
        }
    }
}
</style>
