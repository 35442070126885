<template>
    <section v-if="content" class="main-page-new__integration" :class="basicClassName">
        <div class="a-container">
            <div class="content">
                <h4 v-if="content.title" class="title" v-html="content.title" />
                <p v-if="content.subtitle" class="subtitle" v-html="content.subtitle" />
                <p v-if="content.description" class="description" v-html="content.description" />
            </div>
            <div class="partners">
                <div v-show="isReady" class="slider-wrapper">
                    <a-slider :options="sliderOptions">
                        <a-slide v-for="(item, i) in content.partners" :key="i">
                            <dynamic-link v-if="item.to" to="/" native-type="a">
                                <a-picture
                                    class="logo"
                                    :link="item.logo"
                                    :is-eager="isRTL"
                                />
                            </dynamic-link>
                            <a-picture
                                v-else
                                class="logo"
                                :link="item.logo"
                                :is-eager="isRTL"
                            />
                        </a-slide>
                    </a-slider>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import { LOCALE_DEFAULT, isRTL } from '@model/const/locales.ts';

export default {
    name: 'SMainPageIntegration',
    components: {
        APicture,
        ASlide,
        ASlider,
        DynamicLink,
    },
    mixins: [contentMixin, styleMixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isReady: false,
            sliderOptions: {
                loop: false,
                showNavigation: true,
                showPagination: true,
                slidesPerView: 2,
                spaceBetween: 20,
                slideToClickedSlide: false,
                customPagination: { type: 'bullets' },
                slidesPerGroup: 2,
                breakpoints: {
                    768: { slidesPerGroup: 4, slidesPerView: 4 },
                    1024: { slidesPerGroup: 6, slidesPerView: 6 },
                    1280: { slidesPerGroup: 7, slidesPerView: 7 },
                },
                on: {
                    init: () => this.onSwiperInit(),
                },
            },
        };
    },
    computed: {
        isRTL() {
            const locale = this.$route.params.locale || LOCALE_DEFAULT;
            return isRTL(locale);
        },
    },
    mounted() {
        this.isReady = true;
    },
    methods: {
        // HACK: moving control buttons out of swiper internal container to make them render without custom navigation duplication
        // However, this actually should be an option of the slider component itself
        onSwiperInit() {
            const controls = document.querySelector('.main-page-new .a-slider__controls');
            const wrapper = document.querySelector('.main-page-new .slider-wrapper');
            wrapper.insertBefore(controls, null);
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-new {
    &__integration {
        @mixin basic-slice-paddings;

        .content {
            @mixin colls 12;
            text-align: center;
            color: var(--av-inversed-primary);

            .title {
                @mixin display-accent;
                margin-bottom: 24px;

                @media (--viewport-mobile-wide) {
                    @mixin hero-accent;
                }

                @media (--viewport-desktop) {
                    @mixin large-accent;
                }
            }
            .subtitle {
                @mixin title;
                margin-bottom: 16px;
                @media (--viewport-tablet) {
                    @mixin display;
                }
                &:deep(a:first-child) {
                    text-decoration: none;
                    font-weight:500;
                    background: linear-gradient(90deg, rgb(148, 206, 241) 0%, rgb(0, 101, 227) 100%);
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            .description {
                @mixin colls 12;
                @mixin paragraph;
                margin: 0 auto 16px;
                @media (--viewport-tablet) {
                    @mixin colls 10;
                }
                @media (--viewport-desktop) {
                    max-width: 860px;
                    margin: 0 auto 40px;
                }
            }
        }

        .partners {
            margin-top: 40px;
            .slider-wrapper {
                position: relative;
                max-width: 1150px;
                margin: 0 auto;
                padding: 0 32px;
                @media (--viewport-tablet) {
                    padding: 0 72px;
                }
                @media (--viewport-desktop-wide) {
                    padding: 0 100px;
                }
                @media (--viewport-desktop-large) {
                    padding: 0 110px;
                }
            }
        }

        /* Custom slider layout overrides */
        &:deep(.a-slider) {
            .a-slide {
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 1;
                img {
                    max-width: 119px;
                    max-height: 76px;
                }
            }
            &__nav {
                justify-content: center;
                margin: 16px 0 0;
            }
            &__control {
                display: none;
                position: absolute;
                align-items: center;
                justify-content: center;
                top: 14px;
                margin: 0;
                @media (--viewport-tablet) {
                    display: flex;
                }
            }
            &__control_prev {
                inset-inline-start: 0;
            }
            &__control_next {
                inset-inline-end: 0;
            }
            &__control_disabled {
                pointer-events: none;
            }
        }
    }
}
</style>

<style lang="postcss">
/* dot elements are not scoped due to Swiper behavior */
.main-page-new__integration .a-slider li.a-slider__dot {
    margin: 0 5px;
    width: 6px;
    height: 6px;
    &:before {
        background: var(--av-brand-secondary);
    }
    &_active {
        &:before {
            background: var(--av-brand-primary);
        }
    }
}
</style>
