<template>
    <div class="blog-cards">
        <div v-if="title" class="title-wrap">
            <h3 class="title">
                {{ title }}
            </h3>
            <blog-social-bar
                v-if="socialLinks"
                class="socials"
                :title="followUsOn"
                :links="socialLinks"
            />
        </div>
        <blog-list :cards="cards" :is-loading="isLoading" :show-section-label="showSectionLabel">
            <template #sidebar>
                <slot name="sidebar" />
            </template>
        </blog-list>
        <slot name="pagination" />
    </div>
</template>

<script>
import BlogList from '../shared-components/blog-list/blog-list.vue';
import BlogSocialBar from '../shared-components/blog-social-bar/blog-social-bar.vue';

export default {
    name: 'BlogCards',
    components: {
        BlogList,
        BlogSocialBar,
    },
    props: {
        title: {
            type: String,
            default: null,
        },

        cards: {
            type: Array,
            required: true,
        },

        isLoading: {
            type: Boolean,
            default: false,
        },

        socialLinks: {
            type: Array,
            default: null,
        },

        followUsOn: {
            type: String,
            default: null,
        },

        showSectionLabel: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-cards {
    color: var(--av-nav-primary);

    .title-wrap {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 40px;

        @media (--viewport-tablet) {
            align-items: flex-start;

            .socials {
                margin-inline-start: 2px;
            }
        }

        @media (--viewport-desktop) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 48px;

            .socials {
                margin-inline-start: 0;
            }
        }
    }

    .title {
        @mixin display;
        text-align: center;
        color: var(--av-nav-primary);

        @media (--viewport-tablet) {
            @mixin hero-accent
            text-align: start;
        }
    }

    .socials {
        flex-shrink: 0;

        @media (--viewport-tablet) {
            margin-inline-start: 16px;
        }
    }

    .blog-social-bar {
        margin-top: 16px;

        &:deep(.title) {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
        }
    }
}
</style>
