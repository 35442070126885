<template>
    <header class="express-signup-main-header" :class="{ noResell }">
        <div class="express-signup-main-header__logo__wrapper">
            <a-picture class="express-signup-main-header__logo__wrapper__logo" :background="logo" />
        </div>
        <transition name="express-signup-slide-up">
            <div v-if="!isAnimation" class="express-signup-main-header__intro">
                <div v-if="title" class="express-signup-main-header__intro__title">
                    {{ title }}
                </div>
                <div v-if="lead" class="express-signup-main-header__intro__lead">
                    {{ lead }}
                </div>
            </div>
        </transition>
    </header>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'ExpressSignupLogo',
    components: {
        APicture,
    },
    props: {
        title: {
            type: String,
            default: undefined,
        },
        lead: {
            type: String,
            default: undefined,
        },
        noResell: {
            type: Boolean,
            default: false,
        },
        logo: {
            type: Object,
            default() {
                return {
                    desktop: '@34546b83644a20fab5f5cfbbe5eb00b3',
                    tablet: '@34546b83644a20fab5f5cfbbe5eb00b3',
                    mobile: '@b4c7c3d1362bc1d1cf91d60627b2c74b',
                };
            },
        },
    },
    data: () => ({
        isAnimation: true,
    }),
    mounted() {
        this.isAnimation = false;
    },
};
</script>

<style lang="postcss">
.express-signup-main-header {
    width: 100%;
    &__logo__wrapper {
        padding: 24px 0;
        @media (--viewport-tablet) {
            padding: 32px 0 21px 0;
        }
        &__logo {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            @media (--viewport-tablet) {
                @mixin colls 10;
            }
            @media (--viewport-desktop) {
                @mixin colls 8;
            }
            @media (--viewport-desktop-wide) {
                @mixin colls 6;
            }
        }
    }
    &__intro {
        display: block;
        text-align: center;
        color: var(--av-nav-primary);
        &__title {
            @mixin display;
            font-weight: 600;
            @media (--viewport-tablet) {
                @mixin hero;
            }
        }
        &__lead {
            margin-top: 24px;
            @mixin lead;
            @media (--viewport-tablet) {
                @mixin title;
            }
        }
    }
    &.noResell {
        .express-signup-main-header__logo__wrapper {
            padding: 24px 0 48px 0;
            @media (--viewport-tablet) {
                padding: 32px 0 45px 0;
            }
        }
        .express-signup-main-header__intro__lead {
            @media (--viewport-desktop) {
                @mixin colls 8;
                margin: 24px auto 0;
            }
        }
    }
}
</style>
