<template>
    <section v-if="isVisible" v-show="isAvailable" class="main-page-stats">
        <div class="a-container">
            <s-main-page-tag v-bind="tag({name: 'title'})" />
            <s-main-page-tag v-bind="tag({name: 'description', isDangerous: true})" />
            <div v-if="content.list" class="list">
                <div
                    v-for="(item, itemIndex) in content.list"
                    :key="`item-${itemIndex}`"
                    class="item"
                >
                    <p class="value" v-html="item.value" />
                    <p class="title" v-html="item.title" />
                    <p class="description" v-html="item.description" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import mixin from '../components/mixin.js';
import SMainPageTag from '../components/tag.vue';

export default {
    name: 'SMainPageStats',
    components: {
        SMainPageTag,
    },
    mixins: [mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isVisible() {
            return this.content?.title || this.content?.description || this.content?.list;
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-stats {
    margin-top: 48px;
    text-align: center;
    .title {
        @mixin display-accent;
        color: var(--av-nav-primary);
        @media (--viewport-tablet) {
            @mixin hero-accent;
        }
    }
    .description {
        @mixin title;
        margin-top: 8px;
        color: var(--av-fixed-light);
        @media (--viewport-tablet) {
            @mixin display;
        }
    }
    .list {
        gap: 16px;
        display: flex;
        margin-top: 24px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        @media (--viewport-tablet) {
            gap: 0;
            flex-wrap: wrap;
            flex-direction: row;
        }
        @media (--viewport-desktop) {
            gap: 16px;
            flex-wrap: nowrap;
        }
        .item {
            width: 100%;
            padding: 24px 0;
            @media (--viewport-tablet) {
                width: 50%;
                padding: 40px 0;
            }
            @media (--viewport-desktop) {
                width: 100%;
            }
            .value {
                @mixin hero-accent;
                line-height: 40px;
                color: var(--av-nav-secondary);
            }
            .title {
                @mixin paragraph-accent;
                margin-top: 4px;
                color: var(--av-nav-primary);
            }
            .description {
                @mixin body;
                margin: 0;
                padding: 8px 32px 0;
                color: var(--av-fixed-light);
            }
        }
    }
}
</style>
