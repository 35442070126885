<template>
    <div class="menu-tab-partners">
        <menu-tab v-bind="$props" />
    </div>
</template>

<script lang="ts">
import MenuTabMixin from './menu-tab-mixin.vue';

export default {
    name: 'MenuTabPartners',
    mixins: [MenuTabMixin],
};
</script>

<style lang="postcss" scoped>
.menu-tab-partners {
    &:deep(.s-menu-tab__body) {
        @media (--viewport-tablet) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (--viewport-desktop) {
            grid-template-columns: repeat(4, 3fr);
        }

        @media (--viewport-desktop-wide) {
            grid-column-gap: 20px;
        }
    }

    &:deep(.s-menu-tab__section) {
        &:nth-child(3) {
            @media (--viewport-tablet) {
                border-top: 1px solid var(--av-brand-accent);
            }

            @media (--viewport-desktop) {
                border-top: none;
            }
        }

        &:nth-child(4) {
            @media (--viewport-tablet) {
                border-top: none;
            }

            @media (--viewport-desktop) {
                max-width: 164px;
            }

            @media (--viewport-desktop-wide) {
                max-width: 204px;
            }

            @media (--viewport-desktop-large) {
                max-width: 260px;
            }
        }

        .s-menu-tab {
            &__cta-item-link {
                .a-link__content {
                    @mixin body-accent;
                    color: var(--av-nav-primary);
                }

                &:hover,
                &:focus {
                    .a-link__content {
                        background: none;
                        color: rgba(0, 88, 198, 1);
                    }
                }
            }
        }

        @media (--viewport-desktop) {
            &:first-child {
                .s-menu-tab {
                    &__description {
                        margin: 8px 0 0;
                    }
                    &__cta-item {
                        &:not(:first-child) {
                            margin: 8px 0 0;
                        }
                    }
                }
            }
        }
    }

    .s-menu-tab.s-menu-tab_centered {
        @media (--viewport-desktop) {
            &:deep(.s-menu-tab__body_type_partners) {
                width: 752px;
            }

            &:deep(.s-menu-tab__section) {
                &.s-menu-tab__section_divider_short {
                    min-width: 250px;
                }

                &.s-menu-tab__section_submenu {
                    min-width: 250px;
                    padding-inline-start: 0;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
