<template>
    <footer class="s-acronis-scs-main-footer s-slice" :class="basicClassName">
        <div class="a-container">
            <div class="wrapper">
                <dynamic-link class="logo-link" :to="logoLink">
                    <a-scs-logo class="logo" />
                </dynamic-link>
                <div v-if="showPhone" class="phone">
                    <a-link v-bind="phone" />
                </div>
                <div class="copyright">
                    {{ copyright.replace('currentYear', currentYear) }}
                </div>
                <nav class="links">
                    <ul>
                        <li v-for="(link, index) in links" :key="`scs_footer_links${index}`">
                            <a-link v-bind="link" />
                        </li>
                    </ul>
                </nav>
                <a-social :social="socialLinks" :class="{ 'no-phone': !showPhone }" />
            </div>
        </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALink from '@core/components/link/link.vue';
import AScsLogo from '@core/components/scs-logo/scs-logo.vue';
import ASocial from '@core/components/social/social.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SAcronisScsFooter',
    components: {
        ASocial,
        ALink,
        AScsLogo,
        DynamicLink,
    },
    mixins: [contentMixin, styleMixin],
    props: {
        background: {
            type: String,
            default: 'light-blue',
        },
        showPhone: {
            type: Boolean,
            default: true,
        },
        logoLink: {
            type: String,
            default: '/',
        },
    },
    data() {
        return {
            STORE_SLICE_NAME: 's-acronis-scs-footer',
            currentYear: new Date().getFullYear(),
        };
    },
    computed: {
        ...mapState({
            phone: (state) => state.slices.items?.['s-acronis-scs-footer']?.phone || {},
            copyright: (state) => state.slices.items?.['s-acronis-scs-footer']?.copyright || '',
            links: (state) => state.slices.items?.['s-acronis-scs-footer']?.links || [],
            socialLinks: (state) => state.slices.items?.['s-acronis-scs-footer']?.socialLinks || [],
        }),
    },
    serverPrefetch() {
        this.$store.dispatch('slices/getSyncedData', { slice: this.STORE_SLICE_NAME, locale: 'en-us' });
    },
};
</script>

<style lang="postcss" scoped>
footer.s-acronis-scs-main-footer {
    padding: 32px 0;
    @media (--viewport-desktop) {
        padding: 40px 0;
    }
    .wrapper {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        .logo-link {
            display: inline-block;
            margin-inline-end: 32px;
            margin-bottom: 16px;
            max-width: 170px;
            max-height: 21px;
            order: 1;
            @media (--viewport-mobile-wide) {
                margin-bottom: 0;
                margin-inline-end: 32px;
            }
            @media (--viewport-desktop) {
                margin-inline-end: 64px;
                position: relative;
                top: 12px;
            }
            @media (--viewport-desktop-wide) {
                position: unset;
            }
            .logo {
                max-height: 21px;
                width: 100%;
            }
        }
        .phone {
            width: 100%;
            margin-inline-end: 8px;
            order: 2;
            @media (--viewport-mobile-wide) {
                width: auto;
            }
            @media (--viewport-tablet) {
                width: 40%;
            }
            @media (--viewport-desktop) {
                width: auto;
            }
            &:deep(.a-link__content) {
                @mixin body;
                font-weight: 700;
                color: var(--av-nav-primary);
            }
        }
        .copyright {
            @mixin body;
            color: var(--av-nav-primary);
            order: 3;
            width: 100%;
            @media (--viewport-mobile-wide) {
                width: auto;
                order: 4;
                margin-top: 16px;
                margin-inline-end: 8px;
            }
            @media (--viewport-desktop) {
                order: 3;
                margin-top: 0;
            }
            @media (--viewport-desktop-wide) {
                margin-inline-end: 16px;
            }
        }
        .links {
            width: 100%;
            order: 4;
            @media (--viewport-mobile-wide) {
                order: 5;
            }
            @media (--viewport-tablet) {
                margin-top: 16px;
                width: auto;
            }
            @media (--viewport-desktop) {
                order: 4;
                margin-top: 0;
                width: 100%;
                padding-inline-start: 234px;
            }
            @media (--viewport-desktop-wide) {
                width: auto;
                padding: 0;
            }
            ul {
                li {
                    @mixin body;
                    position: relative;
                    display: inline-block;
                    padding: 0 8px;
                    @media all and (min-width: 360px) {
                        padding: 0 16px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        height: 16px;
                        width: 1px;
                        background: var(--av-brand-light);
                        inset-inline-start: 100%;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &:first-child {
                        padding-inline-start: 0;
                    }
                    &:last-child {
                        padding-inline-end: 0;
                        &:after {
                            display: none;
                        }
                    }
                    &:deep(.a-link__content) {
                        color: var(--av-brand-secondary);
                    }
                }
            }
        }
        &:deep(.a-social) {
            order: 5;
            margin-top: 16px;
            @media (--viewport-mobile-wide) {
                order: 3;
                margin-top: 0;
                margin-inline-start: auto;
            }
            @media (--viewport-desktop) {
                order: 5;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                inset-inline-end: 0;
            }
            @media (--viewport-desktop-wide) {
                position: unset;
                transform: none;
            }
            .a-social__item {
                .a-social__link {
                    margin: 0;
                    margin-inline-end: 32px;
                    .a-glyph {
                        fill: var(--av-nav-primary);
                    }
                }
                &:last-child {
                    .a-social__link {
                        margin: 0;
                    }
                }
            }
            &.no-phone {
                @media (--viewport-mobile-wide) {
                    padding-inline-start: 40%;
                }
                @media (--viewport-desktop) {
                    padding: 0;
                }
            }
        }
    }
}
</style>
