const defaultBreakpoints = {
    mobileWide: 576,
    tablet: 768,
    desktop: 1024,
    desktopWide: 1280,
    desktopLarge: 1440,
};

export default {
    data() {
        return {
            currentBreakpoint: '',
        };
    },

    computed: {
        breakpoints() {
            return defaultBreakpoints;
        },

        isMobile() {
            return ['mobile', 'mobileWide'].includes(this.currentBreakpoint);
        },

        isTablet() {
            return this.currentBreakpoint === 'tablet';
        },

        isDesktop() {
            return ['desktop', 'desktopWide', 'desktopLarge'].includes(this.currentBreakpoint);
        },
    },

    methods: {
        getDeviceTypeByScreenWidth(clientWidth) {
            if (clientWidth < this.breakpoints.tablet) return 'mobile';
            if (clientWidth < this.breakpoints.desktop) return 'tablet';
            return 'desktop';
        },

        handleResize() {
            const clientWidth = window.innerWidth;
            const { mobileWide, tablet, desktop, desktopWide, desktopLarge } = this.breakpoints;

            if (clientWidth < mobileWide) {
                this.currentBreakpoint = 'mobile';
            } else if (clientWidth < tablet) {
                this.currentBreakpoint = 'mobileWide';
            } else if (clientWidth < desktop) {
                this.currentBreakpoint = 'tablet';
            } else if (clientWidth < desktopWide) {
                this.currentBreakpoint = 'desktop';
            } else if (clientWidth < desktopLarge) {
                this.currentBreakpoint = 'desktopWide';
            } else {
                this.currentBreakpoint = 'desktopLarge';
            }
        },
    },

    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
};
