<template>
    <div class="blog-bcrumbs" :class="className">
        <template v-if="!isMobile">
            <dynamic-link class="item link" :to="firstItem.to" native-type="a">
                <a-glyph class="home" name="blog-home" :is-stroke="true" />
                {{ firstItem.title }}
            </dynamic-link>
            <div v-for="(item, i) in items" :key="i" class="item">
                <a-glyph class="arrow" name="arrow-narrow-right" />
                <dynamic-link
                    v-if="item.to"
                    class="link"
                    :to="item.to"
                    native-type="a"
                >
                    {{ item.title }}
                </dynamic-link>
                <template v-else>
                    {{ item.title }}
                </template>
            </div>
        </template>
        <template v-else>
            <dynamic-link class="item link" :to="mobileLink.to" native-type="a">
                <a-glyph class="home" name="arrow-left" />
                {{ mobileLink.title }}
            </dynamic-link>
        </template>
    </div>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'BlogBcrumbs',
    components: {
        DynamicLink,
        AGlyph,
    },
    mixins: [breakpoint],
    props: {
        bcrumbs: {
            type: Array,
            required: true,
        },
        mobileLink: {
            type: Object,
            required: true,
        },
        theme: {
            type: String,
            default: 'light',
        },
    },

    computed: {
        className() {
            return {
                dark: this.theme === 'dark',
            };
        },

        firstItem() {
            return this.bcrumbs[0];
        },

        items() {
            return [...this.bcrumbs].splice(1);
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-bcrumbs {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    justify-content: center;

    @media (--viewport-tablet) {
        padding-bottom: 8px;
        justify-content: flex-start;
    }

    &.dark {
        .item {
            color: var(--av-inversed-light);
        }

        .link {
            color: var(--av-brand-secondary);
        }
    }

    .item {
        @mixin caption-accent;

        color: var(--av-nav-primary);
        display: flex;
        align-items: center;
    }

    .link {
        text-decoration: none;
        color: var(--av-brand-secondary);
    }

    .home {
        position: relative;
        top: -1px;
        width: 12px;
        height: 12px;
        margin-inline-end: 11px;
    }

    .arrow {
        margin: 0 13px;
        fill: var(--av-brand-secondary);
        height: 10px;
        width: 5px;
    }
}
</style>
