<template>
    <div class="g2-review-star">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
        >
            <defs>
                <linearGradient :id="starId">
                    <stop offset="0%" :stop-color="fillColor" />
                    <stop :offset="offset" :stop-color="fillColor" />
                    <stop :offset="offset" stop-color="white" />
                    <stop offset="100%" stop-color="white" stop-opacity="1" />
                </linearGradient>
            </defs>
            <!-- eslint-disable max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.5917 3.5837C14.8564 3.02431 15.4037 2.66931 15.9997 2.66675C16.5985 2.66931 17.1443 3.02431 17.4102 3.5837L20.3124 9.73264C20.5433 10.2172 20.9825 10.5528 21.4954 10.6311L27.9884 11.6167C28.5798 11.7084 29.0707 12.1405 29.2567 12.7342C29.4408 13.328 29.2864 13.9816 28.8597 14.4163L24.1602 19.2026C23.7914 19.5809 23.6203 20.1244 23.7095 20.6569L24.8188 27.4152C24.9183 28.0299 24.6769 28.6541 24.1955 29.0201C23.7071 29.3861 23.0686 29.4363 22.5393 29.1451L16.733 25.9547C16.2727 25.7016 15.7292 25.704 15.2689 25.9547L9.46142 29.1451C8.93204 29.4363 8.29115 29.3861 7.80614 29.0201C7.3248 28.6518 7.08241 28.0299 7.18314 27.4152L8.29115 20.6556C8.38134 20.1244 8.2093 19.5809 7.8402 19.2026L3.14075 14.4163C2.7121 13.9816 2.55868 13.3292 2.74372 12.7342C2.929 12.1405 3.42064 11.7084 4.01226 11.6167L10.505 10.6311C11.0179 10.5528 11.4571 10.2172 11.6868 9.73264L14.5917 3.5837Z"
                :fill="`url(#${starId})`"
                stroke-width="2"
                :stroke="fillColor"
            />
            <!-- eslint-enable max-len -->
        </svg>
    </div>
</template>

<script lang="ts">
export default {
    name: 'G2ReviewStar',

    props: {
        starId: {
            type: String,
            required: true,
        },
        fillPercentage: {
            type: Number,
            required: true,
        },
        fillColor: {
            type: String,
            default: '#ff492c',
        },
    },

    computed: {
        offset() {
            return `${this.fillPercentage}%`;
        },
    },
};
</script>
