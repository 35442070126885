<template>
    <div class="integration-list">
        <div class="a-container">
            <div v-if="categories.length && !isDesktop && isMounted" class="mobile-menu">
                <el-select
                    v-model="activeCategory"
                    popper-class="solutions-dropdown"
                    :hide-on-resize="isDesktop"
                    @change="onCategoryChange"
                >
                    <el-option
                        key=""
                        :label="allCategoriesTitle"
                        value=""
                    />
                    <template v-for="category in categories">
                        <el-option
                            v-if="!category.children?.length"
                            :key="category.code"
                            :label="category.title"
                            :value="category.code"
                        />
                        <div v-else :key="category.code">
                            <el-option
                                :label="category.title"
                                :value="category.code"
                            />
                            <el-option-group>
                                <el-option
                                    v-for="item in category.children"
                                    :key="item.code"
                                    :label="item.title"
                                    :value="item.code"
                                />
                            </el-option-group>
                        </div>
                    </template>
                </el-select>
            </div>

            <div class="cols">
                <div class="menu">
                    <el-menu
                        v-if="categories.length && (isDesktop || !currentBreakpoint)"
                        type="secondary"
                        :default-active="activeCategoryCode"
                        @select="onCategoryChange"
                        @open="onSubmenuOpen"
                    >
                        <el-menu-item index="">
                            {{ allCategoriesTitle }}
                        </el-menu-item>
                        <template v-for="(category, i) in categories">
                            <el-submenu
                                v-if="category.children?.length"
                                :key="`category_${i}`"
                                :index="category.code"
                            >
                                <el-menu-item :ref="`item${category.code}`" :index="category.code">
                                    <a
                                        class="category-link"
                                        :href="`/${locale}/category/${category.code}/`"
                                        @click.prevent
                                    >
                                        {{ category.title }} {{ allCardsText }}
                                    </a>
                                </el-menu-item>
                                <template #title>
                                    {{ category.title }}
                                </template>
                                <el-menu-item
                                    v-for="subcategory in category.children"
                                    :key="subcategory.code"
                                    :index="subcategory.code"
                                >
                                    <a
                                        class="category-link"
                                        :href="`/${locale}/category/${category.code}/${subcategory.code}/`"
                                        @click.prevent
                                    >
                                        {{ subcategory.title }}
                                    </a>
                                </el-menu-item>
                            </el-submenu>
                            <el-menu-item v-else :key="`category_${i}`" :index="category.code">
                                {{ category.title }}
                            </el-menu-item>
                        </template>
                    </el-menu>
                </div>
                <div class="content">
                    <div class="search-wrap">
                        <el-search
                            v-model="searchText"
                            class="search"
                            :placeholder="searchPlaceholder"
                            suffix-icon="i-search-o--16"
                        />
                    </div>
                    <h2 v-if="title" class="title">
                        {{ title }}
                    </h2>
                    <div v-if="subtitle" class="lead">
                        {{ subtitle }}
                    </div>
                    <div v-if="pagedCards.length" class="integrations">
                        <s-integration-card
                            v-for="card in pagedCards"
                            :key="card.slug"
                            :button-title="buttonTitle"
                            v-bind="card"
                        />
                    </div>

                    <div v-if="isMounted && !pagedCards.length" class="no-integrations">
                        <a-glyph name="search" size="l" />
                        <div class="no-integrations-title">
                            {{ noIntegrationsTitle }}
                        </div>
                        {{ noIntegrationsText }}
                    </div>

                    <el-pagination
                        v-if="listedCards.length > pageSize && isMounted"
                        :total="listedCards.length"
                        :page-size="pageSize"
                        :current-page="currentPage"
                        popper-class="pagination-dropdown"
                        @current-change="onPaginationChange"
                    />
                    <div v-if="!isMounted">
                        <div v-for="i in totalPages" :key="`page_${i}`">
                            <a :href="getPageLink(i)">{{ i }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import SIntegrationCard from './components/integration-card.vue';

export default {
    name: 'SolutionsIntegrationList',

    components: {
        SIntegrationCard,
        AGlyph,
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
        ElOptionGroup: () => import('@uikit/ui-kit/packages/option-group'),
        ElSearch: () => import('@uikit/ui-kit/packages/search'),
        ElMenu: () => import('@uikit/ui-kit/packages/menu'),
        ElMenuItem: () => import('@uikit/ui-kit/packages/menu-item'),
        ElSubmenu: () => import('@uikit/ui-kit/packages/submenu'),
        ElPagination: () => import('@uikit/ui-kit/packages/pagination'),
    },

    mixins: [breakpoint],

    props: {
        title: {
            type: String,
            default: null,
        },

        subtitle: {
            type: String,
            default: null,
        },

        categories: {
            type: Array,
            required: true,
        },

        activeCategoryCode: {
            type: String,
            default: '',
        },

        page: {
            type: Number,
            default: 1,
        },

        pageSize: {
            type: Number,
            default: 9,
        },

        cards: {
            type: Array,
            default: () => [],
        },

        allCategoriesTitle: {
            type: String,
            default: 'All categories',
        },

        allCardsText: {
            type: String,
            default: '(all)',
        },

        buttonTitle: {
            type: String,
            default: 'Learn more',
        },

        noIntegrationsTitle: {
            type: String,
            default: 'No results found',
        },

        searchPlaceholder: {
            type: String,
            default: 'Search',
        },

        noIntegrationsText: {
            type: String,
            default: null,
        },
    },

    emits: ['category-select', 'page-change'],

    data() {
        return {
            currentPage: 1,
            searchText: '',
            activeCategory: '',
            isMounted: false,
        };
    },

    computed: {
        locale() {
            return this.$route?.params.locale || 'en-us';
        },

        allCategories() {
            const all = {};

            const push = (category) => {
                all[category.code] = {
                    title: category.title,
                    description: category.description,
                    parent: category.parent,
                    code: category.code,
                };
            };

            this.categories.forEach((category) => {
                push(category);
                if (category.children) category.children.forEach(push);
            });

            return all;
        },

        listedCards() {
            const searchQuery = this.searchText.trim().toLowerCase().replace(/[^a-z0-9- ]/g, '');

            if (searchQuery.length < 3) return this.cards;

            const searchWords = searchQuery.split(' ');

            const searchInCard = (card) => {
                const matchCounts = searchWords.reduce((counts, word) => {
                    const addedCounts = counts;
                    const regex = new RegExp(word, 'ig');
                    const inTitle = card.title.toLowerCase().match(regex)?.length || 0;
                    const inDescription = card.description.toLowerCase().match(regex)?.length || 0;
                    const inLongDescription = card.longDescription.toLowerCase().match(regex)?.length || 0;

                    if (!inTitle && !inDescription && !inLongDescription) addedCounts.foundAllWords = false;

                    addedCounts.inTitle += inTitle;
                    addedCounts.inDescription += inDescription;
                    addedCounts.inLongDescription += inLongDescription;

                    return addedCounts;
                }, { inTitle: 0, inDescription: 0, inLongDescription: 0, foundAllWords: true });

                return {
                    ...card,
                    foundInTitle: matchCounts.inTitle,
                    foundInDescription: matchCounts.inDescription,
                    foundInLongDescription: matchCounts.inLongDescription,
                    foundAllWords: matchCounts.foundAllWords,
                };
            };

            return this.cards
                .map(searchInCard)
                .filter(({ foundAllWords }) => foundAllWords)
                .sort((a, b) => {
                    if (a.foundInTitle > b.foundInTitle) return -1;
                    if (a.foundInTitle < b.foundInTitle) return 1;
                    if (a.foundInDescription > b.foundInDescription) return -1;
                    if (a.foundInDescription < b.foundInDescription) return 1;
                    if (a.foundInLongDescription > b.foundInLongDescription) return -1;
                    if (a.foundInLongDescription < b.foundInLongDescription) return 1;
                    return 0;
                });
        },

        pagedCards() {
            return [...this.listedCards]
                .splice((this.currentPage - 1) * this.pageSize, this.pageSize);
        },

        totalPages() {
            return Math.ceil(this.listedCards.length / this.pageSize);
        },
    },

    watch: {
        searchText() {
            this.$emit('page-change', 1);
        },

        page(value) {
            this.currentPage = value;
        },
    },

    created() {
        this.currentPage = this.page;
        this.activeCategory = this.activeCategoryCode;
    },

    mounted() {
        this.isMounted = true;
    },

    methods: {
        onSubmenuOpen(categoryCode) {
            this.$refs?.[`item${categoryCode}`]?.[0].$el.click();
        },

        onCategoryChange(categoryCode) {
            this.$emit('category-select', this.allCategories[categoryCode]);
        },

        onPaginationChange(page) {
            this.$emit('page-change', page);
        },

        getPageLink(page) {
            const { locale, category, subcategory } = this.$route.params;

            let link = `/${locale}`;
            if (category) link += `/category/${category}`;
            if (subcategory) link += `/${subcategory}`;
            if (page > 1) link += `/page/${page}`;

            return `${link}/`;
        },
    },
};
</script>

<style lang="postcss" scoped>
.integration-list {
    color: var(--av-fixed-primary);
}

.cols {
    @media (--viewport-desktop) {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }

    @media (--viewport-desktop-wide) {
        display: grid;
        grid-template-columns: 1fr 3fr;
    }
}

.mobile-menu {
    max-width: 400px;
    padding-top: 16px;
}

.menu {
    margin-bottom: 32px;
    background: var(--av-inversed-primary);
}

.search-wrap {
    padding-block: 16px;
    border-bottom: 1px solid var(--av-brand-accent);
}

.search {
    @mixin paragraph;
    max-width: 400px;
    width: 100%;

    @media (--viewport-desktop) {
        @mixin body;
        width: 208px;
    }
}

.title {
    @mixin title-accent;
    margin-top: 32px;

    @media (--viewport-desktop) {
        @mixin display;
        margin-top: 16px;
    }
}

.lead {
    @mixin body;
    margin-top: 16px;
}

.title,
.lead,
.search-wrap,
.integrations {
    @media (--viewport-desktop) {
        padding-inline-start: 24px;
    }
}

.integrations {
    padding-block: 24px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media (--viewport-tablet) {
        grid-template-columns: 1fr 1fr;
    }

    @media (--viewport-desktop-wide) {
        gap: 24px;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.no-integrations {
    padding: 48px 0 32px;
    text-align: center;
}

.no-integrations-title {
    @mixin title;
    margin-top: 16px;
    margin-bottom: 8px;
}

.el-pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 56px;
    margin-top: 16px;
}

.integration-list:deep(.el-menu--vertical.el-menu--secondary) {
    .el-menu-item,
    .el-submenu__item,
    .el-submenu__item.is-root {
        @mixin body-accent;
        background: var(--av-inversed-primary);
        padding-inline-start: 24px;

        &:active,
        &.is-active {
            background: var(--av-brand-lightest);
            color: var(--av-fixed-primary);
        }

        &:hover {
            background: var(--av-brand-accent);
        }
    }

    .el-submenu .el-menu-item {
        padding: 0;
        border-top: 1px solid var(--av-brand-accent);

        .el-text {
            width: 100%;
        }

        .category-link {
            display: block;
            width: 100%;
            padding: 8px 16px 8px 48px;
        }
    }

    .el-submenu__arrow {
        position: absolute;
        margin: 0;
        inset-inline-end: 24px;
    }

    .el-submenu.is-opened {
        .el-submenu__arrow {
            transform: rotate(90deg);
        }
    }

    .el-text {
        white-space: normal;
    }
}

:deep(.el-pager) {
    margin-inline-end: 40px;

    &__item {
        border-color: var(--av-brand-secondary-light);
        color: var(--av-nav-primary);
        min-width: 40px;

        &:hover {
            background: var(--av-brand-secondary-accent);
        }

        &.is-active {
            background: var(--av-brand-secondary-light);
            border: 0;
            padding-top: 1px;
            pointer-events: none;
        }
    }
}
:deep(.el-menu--vertical.el-menu--secondary .el-menu__bar) {
    padding-bottom: 0;
}

:deep(.el-split-button) {
    .el-dropdown__split-left {
        border-inline-end-width: 1px !important;
    }
    .el-button {
        border-color: var(--av-brand-secondary-light);
        color: var(--av-nav-primary);
    }
    .el-dropdown__divider {
        display: none;
    }
}
</style>

<style lang="postcss">
.el-select-dropdown.solutions-dropdown {
    .el-select-group .el-select-dropdown__item {
        padding-inline-start: 32px;
    }
}
</style>
