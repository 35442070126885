<template>
    <div class="testimonials">
        <div class="a-container">
            <h4 v-if="title" class="title">
                {{ title }}
            </h4>
            <div v-if="videos" class="videos">
                <div
                    v-for="(video, index) in videos"
                    :key="`testimonial_videos_${index}`"
                    class="video-wrapper"
                    @mouseover="handleHover(index)"
                    @mouseleave="handleMouseLeave(index)"
                    @click="handleClick(index)"
                >
                    <video
                        :id="`video_${index}`"
                        ref="videos"
                        :src="video.link"
                        class="video"
                        :poster="getPosterUrl(video.poster) || false"
                        :controls="false"
                        plays-inline
                    />
                    <a-glyph
                        v-if="currentPlayingIndex !== index"
                        name="play"
                        class="play"
                    />
                    <span v-if="video.person" class="person">{{ video.person }}</span>
                    <span v-if="video.position" class="position">{{ video.position }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AGlyph from '@core/components/glyph/glyph.vue';
import breakpoint from '@core/mixins/breakpoint';

export default {
    components: {
        AGlyph,
    },
    mixins: [breakpoint],
    props: {
        title: {
            type: String,
            default: '',
        },
        videos: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            currentPlayingIndex: null,
            userInteracted: false,
        };
    },
    computed: {
        ...mapGetters('config', ['$config']),
        baseURL() {
            return this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE;
        },
        shouldClick() {
            return ['mobile', 'mobileWide', 'tablet'].includes(this.currentBreakpoint);
        },
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleUserInteraction);
    },
    mounted() {
        document.addEventListener('click', this.handleUserInteraction, { once: true });
    },
    methods: {
        handleUserInteraction() {
            this.userInteracted = true;
        },
        getPosterUrl(id) {
            if (!id) return false;
            return `${this.baseURL}/images/content@2x/${id.replace('@', '')}.webp` || false;
        },
        handleHover(index) {
            if (this.shouldClick || !this.userInteracted) return;
            this.playVideo(index);
        },
        handleMouseLeave(index) {
            if (this.shouldClick || !this.userInteracted) return;
            this.pauseVideo(index);
        },
        handleClick(index) {
            if (!this.userInteracted) {
                this.handleUserInteraction();
                this.playVideo(index);
            }
            if (!this.shouldClick) return;
            if (this.currentPlayingIndex === index) {
                this.pauseVideo(index);
            } else {
                this.playVideo(index);
            }
        },
        playVideo(index) {
            if (this.currentPlayingIndex !== null && this.currentPlayingIndex !== index) {
                this.pauseVideo(this.currentPlayingIndex);
            }
            const video = this.$refs.videos[index];
            if (!video) return;
            video.play();
            this.currentPlayingIndex = index;
        },
        pauseVideo(index) {
            const video = this.$refs.videos[index];
            if (video) {
                video.pause();
                this.currentPlayingIndex = null;
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.testimonials {
    padding: 48px 0;
    text-align: center;
    color: var(--av-nav-primary);
    position: relative;
    z-index: 3;
    .title {
        @mixin hero-accent;
        margin-bottom: 32px;
    }
    .videos {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        gap: 48px;
        margin: 0 auto;
        @media (--viewport-mobile-wide) {
            gap: 16px 48px;
        }
        @media(--viewport-tablet) {
            @mixin colls 9;
        }
        @media (--viewport-desktop) {
            @mixin colls 12;
            gap: 0;
            justify-content: space-between;
        }
        @media (--viewport-desktop-wide) {
            @mixin colls 10;
        }
        .video-wrapper {
            position: relative;
            width: 205px;
            video {
                width: 100%;
                height: 345px;
                object-fit: cover;
                border-radius: 24px;
                margin-bottom: 8px;
                border: 1px solid var(--av-brand-secondary-light);
            }
            .play {
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 48px;
                height: 48px;
                top: 40%;
                fill: var(--av-inversed-lighter);
                pointer-events: none;
            }
            .person {
                @mixin body-accent;
                display: block;
            }
            .position {
                @mixin descriptor;
                color: var(--av-fixed-primary);
            }
        }
    }
}
</style>
