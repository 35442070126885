import { Mock } from '@core/slices/pages/requirements/mock.js';

export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=47482-896&t=JMJ2fbzoLIVoGwai-4';

export const Columnsx2 = {
    name: 'Columns x2',
    args: {
        translations: {
            title: 'Test title',
            slices: [
                { lead: 'On-Premise Management Console #1', systemInfo: Mock },
                { lead: 'On-Premise Management Console #2', systemInfo: Mock },
            ],
            link: { text: 'Optional Link' },
        },
        columnsCount: 2,
        link: { to: '#' },
        slices: [],
    },
    argTypes: {
        columnsCount: {
            options: [2],
        },
    },
};

export const Columnsx3 = {
    name: 'Columns x3',
    args: {
        translations: {
            title: 'Test title',
            slices: [
                { lead: 'On-Premise Management Console #1', systemInfo: Mock },
                { lead: 'On-Premise Management Console #2', systemInfo: Mock },
                { lead: 'On-Premise Management Console #3', systemInfo: Mock },
            ],
        },
        columnsCount: 3,
        slices: [],
    },
    argTypes: {
        columnsCount: {
            options: [3],
        },
    },
};
