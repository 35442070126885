export const LOCALE_ENGLISH_MAPPING = {
    'en-us': 'en-us',
    'de-de': 'en-eu',
    'es-es': 'en-eu',
    'es-mx': 'en-us',
    'fr-fr': 'en-eu',
    'it-it': 'en-eu',
    'pl-pl': 'en-eu',
    'ja-jp': 'en-sg',
    'ko-kr': 'en-sg',
    'he-il': 'en-us',
};
