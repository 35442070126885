<template>
    <section v-if="isVisible" v-show="isAvailable" class="main-page-platform">
        <div class="a-container">
            <s-main-page-tag v-bind="tag({name: 'title'})" />
            <div v-if="content.list" class="list">
                <div
                    v-for="(item, itemIndex) in content.list"
                    :key="`item-${itemIndex}`"
                    :class="className(item) "
                >
                    <template v-if="item.info">
                        <div
                            v-for="(info, infoIndex) in item.info"
                            :key="`links-${infoIndex}`"
                            class="item"
                        >
                            <p v-if="info.title" class="title" v-html="info.title" />
                            <ul>
                                <li v-for="(link, linkIndex) in info.links" :key="`link-${linkIndex}`">
                                    <a-link v-bind="link" />
                                </li>
                            </ul>
                        </div>
                    </template>
                    <template v-else>
                        <a-picture v-if="item.picture" v-bind="item.picture" />
                        <div class="wrapper-button">
                            <a-button v-if="item.button" v-bind="item.button" />
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <a-picture
            v-if="content.picture && content.tabActive !== 2"
            class="background"
            v-bind="content.picture"
        />
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import mixin from '../components/mixin.js';
import SMainPageTag from '../components/tag.vue';

export default {
    name: 'SMainPagePlatform',
    components: {
        AButton,
        ALink,
        APicture,
        SMainPageTag,
    },
    mixins: [mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isVisible() {
            return this.content.title || this.content.list?.length;
        },
    },
    methods: {
        className(item) {
            return ['wrapper', { picture: item.picture }, { info: item.info }];
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-platform {
    padding: 48px 0 0;
    position: relative;
    .title {
        @mixin display-accent;
        text-align: center;
        color: var(--av-nav-primary);
        @media (--viewport-tablet) {
            @mixin hero-accent;
        }
        @media (--viewport-desktop) {
            @mixin colls 10;
            margin: 0 auto;
        }
        @media (--viewport-desktop-wide) {
            @mixin colls 8;
        }
        @media (--viewport-desktop-large) {
            @mixin colls 7;
        }
    }
    .list {
        gap: 24px;
        display: flex;
        margin-top: 40px;
        flex-direction: column;
        @media (--viewport-tablet) {
            gap: 24px;
        }
        @media (--viewport-desktop-wide) {
            flex-direction: row;
        }
    }
    .wrapper {
        gap: 24px;
        display: flex;
        text-align: center;
        flex-direction: column;
        .item {
            @media (--viewport-tablet) {
                width: 50%;
            }
            @media (--viewport-desktop-wide) {
                width: 100%;
            }
        }
        .title {
            @mixin title-accent;
            margin: 0;
            width: auto;
            color: var(--av-nav-primary);
        }
        &:deep(.a-link) {
            display: flex;
            margin-top: 8px;
            justify-content: center;
            .a-link__content {
                line-height: 20px;
            }
        }
    }
    .wrapper-button {
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        align-items: center;
        inset-inline-start: 0;
    }
    .info {
        @media (--viewport-tablet) {
            flex-direction: row;
        }
        @media (--viewport-desktop-wide) {
            flex: 1 1 0;
            flex-direction: column;
        }
        &:first-child {
            .title {
                @media (--viewport-desktop-wide) {
                    text-align: end;
                }
            }
            @media (--viewport-desktop-wide) {
                justify-content: space-between;
                &:deep(.a-link) {
                    justify-content: flex-end;
                }
            }
        }
        &:last-child {
            .title {
                @media (--viewport-desktop-wide) {
                    text-align: start;
                }
            }
            @media (--viewport-desktop-wide) {
                justify-content: space-between;
                &:deep(.a-link) {
                    justify-content: flex-start;
                }
            }
        }
    }
    .picture {
        &:deep(.a-picture) {
            width: 100%;
            height: auto;
            margin: auto;
            overflow: hidden;
            border-radius: 4px;
            border: 2px solid var(--av-brand-primary);
            @media (--viewport-desktop) {
                width: 798px;
            }
            @media (--viewport-desktop-wide) {
                width: 568px;
            }
            @media (--viewport-desktop-large) {
                width: 648px;
            }
            .a-picture__img {
                width: 100%;
                height: 100%;
                position: relative;
            }
        }
        &:deep(.a-button) {
            margin: auto;
            min-width: 200px;
        }
    }
    .background {
        z-index: -1;
        top: 56px;
        width: 100%;
        height: auto;
        position: absolute;
        inset-inline-start: 0;
        @media (--viewport-desktop-wide) {
            top: -164px;
        }
        &:deep(.a-picture__img) {
            width: 100%;
            height: 100%;
            max-height: 918px;
        }
    }
}
</style>
