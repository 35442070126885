export const MOCK_SMALL_CARD_COMPANY = {
    labels: [
        { variant: 'success' },
        { variant: 'info' },
        { variant: 'neutral' },
    ],
    company: {
        address: '19-21 Nihonbashi Hakozakicho, Chuo-ku, Tokyo 103-8510',
        phone: { to: 'tel:+359875328030' },
        website: { glyph: 'arrow', to: '#' },
    },
};

export const MOCK_SMALL_CARD_COMPANY_TRANSLATES = {
    title: 'Partner benefits',
    lead: `As you grow your partnership with Acronis, you’ll unlock additional perks.
            Gain access to special benefits and programs designed to delight your customers and help your business thrive.`,
};

export const MOCK_SMALL_CARD_COMPANY_TRANSLATES_CARD = {
    title: 'IBM Japan Ltd.',
    text: `Company info. The great benefit of using Acronis is securing the data, our IP (intellectual property).
                Data is king in our business.`,
    labels: [
        { text: 'Success' },
        { text: 'Info' },
        { text: 'Neutral' },
    ],
    company: {
        address: '19-21 Nihonbashi Hakozakicho, Chuo-ku, Tokyo 103-8510',
        phone: { text: '+359875328030' },
        website: { text: 'Visit website' },
    },
};

export const MOCK_SMALL_CARD_COMPANY_TRANSLATES_RATE = {
    rate: [
        { title: 'Financial' },
        { title: 'Marketing' },
        { title: 'Enablement' },
        { title: 'Support' },
    ],
};
