<template>
    <div class="blog-cpc-list">
        <div class="items">
            <blog-cpc-card
                v-for="card in cards"
                :key="card.id"
                class="item"
                v-bind="{ ...card }"
            />
            <div
                v-if="isLoading"
                v-loading="true"
                class="loading"
                el-loading-color="inversed-primary"
                el-loading-size="48"
            />
        </div>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import BlogCpcCard from './blog-cpc-card.vue';

export default {
    name: 'BlogCpcList',
    components: {
        BlogCpcCard,
    },
    directives: {
        Loading,
    },
    props: {
        cards: {
            type: Array,
            required: true,
        },

        isLoading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-cpc-list {
    .items {
        position: relative;
        @media (--viewport-tablet) {
            display: grid;
            column-gap: 16px;
            row-gap: 24px;
            grid-template-columns: 1fr 1fr 1fr;
            grid-auto-rows: 1fr;
        }
    }

    .loading {
        position: absolute;
        inset-inline-start: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }

    .item {
        margin-bottom: 40px;

        @media (--viewport-tablet) {
            margin-bottom: 0;
        }
    }
}
</style>
