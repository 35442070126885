import SchemaHandler from '@model/seo/schemaHandler';
import { OrganizationSchema, SchemaConfig, WebSiteSchema } from '@model/seo/schemas';
import {
    getBCP47Locale,
    getUrlWithLocale,
    MAIN_SITE_URL,
    SITE_ID_EVENTS,
    MAIN_WEBSITE_NAME,
    EVENTS_WEBSITE_NAME,
} from '../helper';
import { Schema } from '../index';
import OrganizationHandler from './organizationHandler';

export default class WebSiteHandler implements SchemaHandler {
    ctx: any;

    schemasConfig: SchemaConfig[];

    constructor(ctx: any, schemasConfig: SchemaConfig[]) {
        this.ctx = ctx;
        this.schemasConfig = schemasConfig;
    }

    getSchemaData(staticConfig: WebSiteSchema): Promise<WebSiteSchema> {
        return Promise.resolve(this.getRequiredFields(staticConfig));
    }

    getRequiredFields(staticConfig: WebSiteSchema): WebSiteSchema {
        const siteID = this.ctx.$store.getters['config/$config'].siteID;
        const locale = this.ctx.$route?.params.locale || 'en-us';
        const url = getUrlWithLocale(this.ctx);
        const creator = this.getCreator();

        let name;
        let description;

        if (siteID === SITE_ID_EVENTS) {
            const uiStrings = this.ctx.$store.state.slices.items.events || {};
            name = EVENTS_WEBSITE_NAME;
            description = uiStrings.homePageLead || name;
        } else {
            const page = this.ctx.$store.state.pages?.page;
            name = MAIN_WEBSITE_NAME;
            description = page?.meta?.description || name;
        }

        return {
            ...staticConfig,
            isAccessibleForFree: true,
            isFamilyFriendly: true,
            inLanguage: getBCP47Locale(locale),
            publisher: { '@id': `${MAIN_SITE_URL}/#Organization` },
            '@id': url,
            creator,
            url,
            name,
            description,
        };
    }

    getCreator(): OrganizationSchema {
        const staticConfig: any = this.schemasConfig.find((schema) => schema['@type'] === Schema.Organization);
        const handler = new OrganizationHandler(this.ctx, this.schemasConfig);
        return handler.getRequiredFields(staticConfig);
    }
}
