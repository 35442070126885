<template>
    <div class="event-radio-control">
        <label v-for="(button, i) in buttons" :key="i" class="radio-wrap">
            <input
                class="radio"
                type="radio"
                :checked="defaultValue === button.value"
                :name="name"
                :value="button.value"
                @change="change(button.value)"
            />
            <div class="label">{{ button.title }}</div>
        </label>
    </div>
</template>

<script>
export default {
    name: 'EventRadioControl',
    props: {
        buttons: {
            type: Array,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        defaultValue: {
            type: String,
            required: true,
        },
    },
    emits: ['change'],
    methods: {
        change(value) {
            this.$emit('change', { value });
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-radio-control {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .radio-wrap {
        margin-inline-end: 16px;

        &:last-child {
            margin-inline-end: 0;
        }

        @media (--viewport-desktop) {
            margin-inline-end: 24px;
        }
    }

    .radio {
        visibility: hidden;
        position: absolute;

        &:checked {
            + .label {
                color: var(--av-nav-primary);
                pointer-events: none;
            }
        }
    }

    .label {
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        cursor: pointer;
        color: var(--av-brand-secondary);

        @media (--viewport-desktop) {
            @mixin paragraph-accent;
        }
    }
}
</style>
