<template>
    <div class="resource-case-study-snippets">
        <div v-for="snippet in snippets" :key="snippet.id" class="snippet-item">
            <div v-if="snippet.type === 'caseStudyLeftLogo'" class="logo-wrap">
                <a-picture class="snippet-logo" :link="snippet.data.file.url" />
            </div>
            <div v-else class="snippet-text-block">
                <div class="snippet-title">
                    {{ snippet.data.title }}
                </div>
                <div v-for="(item, i) in snippet.data.items" :key="i" class="snippet-item">
                    {{ item }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'ResourceCaseStudySnippets',

    components: {
        APicture,
    },

    props: {
        snippets: {
            type: Array,
            required: true,
        },
    },

    data: () => ({}),

    computed: {},
};
</script>

<style lang="postcss" scoped>
.resource-case-study-snippets {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;

    @media (--viewport-tablet) {
        text-align: start;
        flex-shrink: 0;
        width: 164px;
    }

    @media (--viewport-desktop) {
        width: 228px;
    }

    @media (--viewport-desktop-wide) {
        width: 276px;
    }

    @media (--viewport-desktop-large) {
        margin-inline-start: 64px;
        margin-inline-end: -82px;
    }
}

.snippet-text-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    @media (--viewport-tablet) {
        align-items: flex-start;
    }
}

.snippet-item {
    @media (--viewport-tablet) {
        width: 100%;
    }
}

.snippet-title {
    @mixin paragraph-accent;
    color: var(--av-nav-primary);
}

.snippet-item {
    @mixin paragraph;
    color: var(--av-fixed-secondary);
}

.logo-wrap {
    width: 164px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:deep(.a-picture) {
        height: 100%;

        &__img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    @media (--viewport-tablet) {
        align-items: flex-start;
        justify-content: flex-start;
    }

    @media (--viewport-desktop) {
        width: 228px;
    }

    @media (--viewport-desktop-wide) {
        width: 276px;
    }
}
</style>
