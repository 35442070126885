<template>
    <component :is="tag" :class="classNames" :style="style">
        <slot />
    </component>
</template>

<script lang="ts">
export default {
    name: 'ACol',

    props: {
        span: {
            type: Number,
            default: 24,
        },
        tag: {
            type: String,
            default: 'div',
        },
        offset: {
            type: Number,
            default: 0,
        },
        xs: {
            type: [Number, Object],
            default: undefined,
        },
        sm: {
            type: [Number, Object],
            default: undefined,
        },
        md: {
            type: [Number, Object],
            default: undefined,
        },
        lg: {
            type: [Number, Object],
            default: undefined,
        },
        xl: {
            type: [Number, Object],
            default: undefined,
        },
    },

    computed: {
        gutter() {
            let parent = this.$parent;
            while (parent && parent.$options.name !== 'ARow') {
                parent = parent.$parent;
            }
            return parent ? parent.gutter : 0;
        },

        style() {
            if (!this.gutter) return {};

            const padding = `${this.gutter / 2}px`;
            return {
                paddingLeft: padding,
                paddingRight: padding,
            };
        },

        classNames() {
            const classList = ['a-form-col'];

            if (this.span) {
                classList.push(`a-form-col-${this.span}`);
            }

            if (this.offset) {
                classList.push(`a-form-col-offset-${this.offset}`);
            }

            ['xs', 'sm', 'md', 'lg', 'xl'].forEach((size) => {
                if (typeof this[size] === 'number') {
                    classList.push(`a-form-col-${size}-${this[size]}`);
                } else if (typeof this[size] === 'object') {
                    const props = this[size];
                    Object.keys(props).forEach((prop) => {
                        classList.push(prop !== 'span'
                            ? `a-form-col-${size}-${prop}-${props[prop]}`
                            : `a-form-col-${size}-${props[prop]}`);
                    });
                }
            });

            return classList;
        },
    },
};
</script>
