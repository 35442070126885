<template>
    <div class="s-header-defend">
        <a-picture
            class="s-header-defend__img a-container"
            :background="background"
            is-background
            fit="contain"
        />
        <div class="s-header-defend__wrap">
            <div class="a-container s-header-defend__wrap-container">
                <div class="s-header-defend__header">
                    <div class="s-header-defend__title">
                        <a-logo type="full" />
                        <span>{{ title }}</span>
                    </div>
                    <div v-if="label" class="s-header-defend__label">
                        {{ label }}
                    </div>
                </div>
                <div class="s-header-defend__footer">
                    <div class="s-header-defend__col">
                        <a-product-cta :settings="{ hasDescription: true, ...productPageCTA }" />
                    </div>
                    <a-button
                        v-for="(btn, key) in buttons"
                        :key="key"
                        class="s-header-defend__button s-header-defend__col"
                        v-bind="btn"
                    />
                    <div class="s-header-defend__links">
                        <a-link
                            v-for="(link, key) in actionLinks"
                            :key="key"
                            class="s-header-defend__col"
                            v-bind="link"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';
import APicture from '@core/components/picture/picture.vue';
import AProductCta from '@core/components/product-cta/product-cta.vue';
import analytics from '@core/mixins/analytics.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import purchasing from '@core/mixins/purchasing.js';

export default {
    name: 'SHeaderDefend',

    components: {
        APicture,
        ALink,
        AButton,
        ALogo,
        AProductCta,
    },

    mixins: [purchasing, analytics, cleverbridge],

    props: {
        background: {
            type: Object,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            default: '',
        },

        buttons: {
            type: Array,
            required: true,
        },

        actionLinks: {
            type: Array,
            required: true,
        },

        productPageCTA: {
            type: Object,
            default: undefined,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-header-defend {
    height: 758px;
    position: relative;

    @media (--viewport-tablet) {
        height: 600px;
    }

    &__wrap {
        &-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
    }

    &__img {
        position: relative;
        height: 100%;
        padding: 0 !important;

        @media (--viewport-tablet) {
            &:deep(img) {
                padding: 0 32px;
            }
        }
        @media (--viewport-desktop-large) {
            &:deep(img) {
                max-width: 90%;
            }
        }
    }

    &__wrap {
        position: absolute;
        inset-inline-start: 0;
        inset-inline-end: 0;
        top: 40px;
        bottom: 48px;
    }

    &__title {
        font-size: 32px;
        line-height: 32px;
        font-weight: 300;
        color: var(--av-nav-primary);
        margin-bottom: 8px;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;

        @media (--viewport-tablet) {
            justify-content: flex-start;
        }

        &:deep(.a-logo) {
            width: 100%;
            height: 19px;
            margin-bottom: 8px;

            @media (--viewport-tablet) {
                margin-inline-end: 8px;
                margin-bottom: 0;
                width: 110px;
                height: 32px;
            }
        }

        span {
            max-width: 80%;

            @media (--viewport-tablet) {
                max-width: 100%;
            }
        }
    }

    &__label {
        font-size: 16px;
        line-height: 24px;
        color: var(--av-fixed-success);
        text-align: center;

        @media (--viewport-tablet) {
            text-align: start;
        }
    }

    &__footer {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        text-align: center;

        @media (--viewport-tablet) {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: unset;
            text-align: start;
        }
    }

    &__button {
        min-width: 200px;
        margin-inline-start: auto;
        margin-inline-end: auto;

        @media (--viewport-tablet) {
            min-width: 150px;
            margin-inline-start: 0;
        }
    }

    &__col {
        margin-top: 16px;

        &:first-child {
            margin-top: 0;
        }

        @media (--viewport-tablet) {
            margin-top: 0;
            margin-inline-end: 24px;
        }
    }

    &__links {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        margin-top: 24px;
        justify-content: center;

        .s-header-defend__col {
            margin-top: 0;

            &:first-child {
                margin-inline-end: 16px;
            }
        }

        @media (--viewport-tablet) {
            margin-top: 0;

            .s-header-defend__col {
                &:first-child {
                    margin-inline-end: 24px;
                }
            }
        }

        &:deep(.a-link__glyph_position_right) {
            margin-inline-start: 8px;
        }
    }
}
</style>
