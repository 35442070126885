<template>
    <div class="developer-register-program">
        <step-form
            v-if="step === 'form'"
            :ui-strings="uiStrings"
            class="step"
            @success="step = 'success'"
        />
        <step-success
            v-if="step === 'success'"
            :ui-strings="uiStrings"
            class="step"
        />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import StepForm from './step-form.vue';
import StepSuccess from './step-success.vue';

export default Vue.extend({
    name: 'DeveloperRegisterProgram',

    components: {
        StepForm,
        StepSuccess,
    },

    props: {
        uiStrings: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            step: 'form',
        };
    },

    async serverPrefetch() {
        await this.$store.dispatch('countries/getCountries');
    },
});
</script>

<style lang="postcss" scoped>
.developer-register-program {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    min-height: 100vh;
}

.step {
    background: white;
    max-width: 560px;
    margin: 80px 12px;
    padding: 40px;
    border: 1px solid var(--av-solid-brand-secondary-light);
    border-radius: 4px;
    box-shadow: var(--av-shadow-small);
}

:deep(.grecaptcha-badge) {
    display: none !important;
}
</style>
