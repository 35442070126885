<template>
    <s-awards v-if="isVisible && isAvailable" class="main-page-awards" :awards="list" />
</template>

<script>
import { mapState } from 'vuex';
import SAwards from '@core/slices/pages/awards/awards.vue';
import mixin from '../components/mixin.js';

export default {
    name: 'SMainPageAwards',
    components: {
        SAwards,
    },
    mixins: [mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapState({
            awards: (state) => state.awards.items || {},
        }),
        isVisible() {
            return this.content?.list?.length;
        },
        list() {
            return this.content.list.map((pid) => this.awards[pid])
                .filter(Boolean)
                .map((item) => ({ ...item, url: item.link }));
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-awards {
    padding: 96px 0 48px;
    &:deep(.list) {
        &.large {
            display: flex;
            gap: 48px 8px;
            padding: 0;
            margin: 0;
            flex-wrap: wrap;
            flex-direction: row;
            justify-items: unset;
            align-items: stretch;
            justify-content: center;
            align-content: space-around;
            @media (--viewport-tablet) {
                gap: 48px 16px;
            }
            @media (--viewport-desktop) {
                gap: 48px 24px;
            }
            @media (--viewport-desktop-wide) {
                gap: 48px 72px;
            }
            .item {
                width: 172px;
                .award {
                    align-items: center;
                }
            }
        }
    }
}
</style>
