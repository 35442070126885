<template>
    <div class="s-blog-insights">
        <blog-container :theme="theme">
            <blog-insights
                :title="title"
                :lead="lead"
                :link="link"
                :spotlighted-card="spotlightedCard"
                :news-title="newsTitle"
                :news="CPCNews"
                :news-video-label="videoLabel"
                :cards="featuredCPCCards"
            />
        </blog-container>
    </div>
</template>

<script>
import blog from '@core/mixins/blog.js';
import BlogInsights from '@core/slices/blog/blog-insights/blog-insights.vue';
import BlogContainer from '@core/slices/blog/shared-components/blog-container/blog-container.vue';

export default {
    name: 'SBlogInsights',
    components: {
        BlogInsights,
        BlogContainer,
    },
    mixins: [blog],
    props: {
        title: {
            type: String,
            required: true,
        },

        lead: {
            type: String,
            required: true,
        },

        newsTitle: {
            type: String,
            required: true,
        },

        videoLabel: {
            type: String,
            required: true,
        },

        link: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            theme: 'dark-navy',
        };
    },

    computed: {
        // if there are no spotlighted items we are using first featured instead
        spotlightedCard() {
            if (this.spotlightedPost) {
                return this.spotlightedPost;
            }

            const post = (this.$store.state.blog.featuredCPC.items || [])[0];
            return this.getBlogPostFields(post, this.translations);
        },

        featuredCPCCards() {
            const featured = (this.$store.state.blog.featuredCPC.items || [])
                .map((card) => this.getBlogPostFields(card, this.translations));

            return this.spotlightedPost
                ? featured.slice(0, -1)
                : featured.slice(1);
        },

        CPCNews() {
            const news = this.$store.state.blog.CPCNews.items || [];
            return news.map((item) => this.getNewsFields(item));
        },
    },
};
</script>
