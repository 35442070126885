<template>
    <div class="cpoc-page cpc-home">
        <div class="main-content">
            <div class="bg-section cpoc-wrapper">
                <s-blog-cpc-header v-bind="sliceDataHeader" />
                <s-blog-cpc-bar
                    :links="menuLinks"
                    :title="translations.cpcAboutTitle"
                    :news-title="translations.newsTitle"
                    :about-title="translations.cpcAbout"
                    :lead="translations.cpcAboutLead"
                />
                <s-blog-cpc-intro v-if="spotlightedCard" :spotlighted-card="spotlightedCard" />
            </div>
            <div class="cpoc-wrapper main">
                <s-feature-single v-bind="featureSingle" />
                <s-value-cards v-if="activityCards" :cards="activityCards" :title="translations.cpcAboutCardsTitle" />
                <s-value-cards v-if="serviceCards" :cards="serviceCards" :title="translations.cpcAboutServicesTitle" />
                <s-speakers v-if="speakers" :speakers="speakers" :title="translations.cpcSpeakers" />
                <!-- <s-blog-cpc-people :title="translations.cpcAnalysts" :people="authors" />
                <s-awards
                    v-if="awards"
                    :title="translations.cpcAwardsTitle"
                    :awards="awards"
                    columns="4"
                /> -->
                <s-global-footer class="footer" v-bind="sliceDataFooter" />
            </div>
        </div>
    </div>
</template>

<script>
import analytics from '@core/mixins/analytics.js';
import blog from '@core/mixins/blog.js';
import breakpoint from '@core/mixins/breakpoint.js';
import SBlogCpcBar from '@core/slices/blog/blog-cpc/blog-cpc-bar.vue';
import SBlogCpcHeader from '@core/slices/blog/blog-cpc/blog-cpc-header.vue';
import SBlogCpcIntro from '@core/slices/blog/blog-cpc/blog-cpc-intro.vue';
// import SBlogCpcPeople from '@core/slices/blog/blog-cpc/blog-cpc-people.vue';
// import SAwards from '@core/slices/pages/awards/awards.vue';
import SFeatureSingle from '@core/slices/pages/feature-single/feature-single.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer';
import SSpeakers from '@core/slices/pages/speakers/speakers.vue';
import SValueCards from '@core/slices/pages/value-cards/value-cards.vue';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import socials from '@model/const/socials.ts';
import '@core/styles/sections/cpoc.pcss';

export default {
    name: 'BlogCPCAbout',

    components: {
        SBlogCpcHeader,
        SBlogCpcBar,
        SBlogCpcIntro,
        // SBlogCpcPeople,
        SSpeakers,
        // SAwards,
        SGlobalFooter,
        SFeatureSingle,
        SValueCards,
    },

    mixins: [blog, analytics, breakpoint],

    props: {
        dispatchLoadLatestCardsPage: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            socials,
        };
    },

    computed: {
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },

        authorsLink() {
            return {
                to: '/cyber-protection-center/authors/',
                text: this.translations.insightsLink,
            };
        },

        sliceDataHeader() {
            return {
                ...this.$store.state.slices.items['s-main-header'],
            };
        },

        sliceDataFooter() {
            return {
                sliceSettingsName: 's-preset-cpoc-footer',
                socialGlyphSize: 'm',
                hasLogo: false,
                hasLocaleSelector: false,
                hasBorderTopCopyright: true,
                type: 'short',
                theme: 'dark',
            };
        },

        categories() {
            return this.$store.state.blog.categories.items || [];
        },

        menuLinks() {
            return this.categories.map((category) => this.getCategoryFields(category, this.locale));
        },

        spotlightedCard() {
            return this.translations.cpcAboutSpotlightedCard;
        },

        authors() {
            return (this.$store.state.blog.authors.items || [])
                .map(this.getAuthorFields);
        },

        activityCards() {
            return this.translations.cpcActivityCards;
        },

        serviceCards() {
            return this.translations.cpcServiceCards;
        },

        speakers() {
            return this.translations.cpcSpeakerCards;
        },

        awards() {
            return this.translations.cpcAboutAwards;
        },

        featureSingle() {
            const feature = this.translations.cpcAboutMainFeature;
            return {
                title: feature.title,
                lead: feature.lead,
                bodyText: feature.text,
                content: {
                    list: {
                        column: 2,
                        links: [
                            {
                                text: feature.sidebarText,
                            },
                        ],
                        title: feature.sidebarTitle,
                    },
                },
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.cpoc-page {
    &:deep(.cpc-bar) {
        margin-bottom: 24px;
    }

    &:deep(.head .lead) {
        @media (--viewport-tablet) {
            max-width: 300px;
        }
        @media (--viewport-desktop) {
            max-width: 380px;
        }
    }

    &:deep(.a-slice-header__title) {
        color: var(--av-fixed-primary);
        margin-bottom: 24px;
        @mixin display-accent;

        @media (--viewport-tablet) {
            @mixin hero-accent;
            margin-bottom: 40px;
        }
    }

    &:deep(.a-slice-header__lead) {
        @mixin display-accent;
    }

    .main {
        margin-top: 16px;
    }
}
</style>
