<template>
    <section :id="id" class="s-award-compact" :class="{ ...className, ...basicClassName }">
        <div class="a-container">
            <span class="award">
                <span v-if="awardTitle" class="award-title">{{ awardTitle }}</span>
                <div v-if="awardLink" class="award-source">
                    <dynamic-link
                        v-if="awardLink"
                        :to="awardLink"
                        class="image-link"
                    >
                        <a-picture
                            v-if="awardLogo"
                            class="award-logo"
                            :link="awardLogo"
                            :alt="awardLogoAlt"
                        />
                    </dynamic-link>
                    <a-link
                        class="award-link"
                        :glyph="glyph"
                        size="paragraph"
                        :to="awardLink"
                        :text="awardLinkText"
                        has-non-breaking-glyph
                    />
                </div>
                <div v-else class="award-source">
                    <a-picture
                        v-if="awardLogo"
                        class="award-logo"
                        :link="awardLogo"
                        :alt="awardLogoAlt"
                    />
                    <span class="award-text">{{ awardLinkText }}</span>
                </div>
            </span>
            <ul v-if="addAwards" class="add-list">
                <li v-for="(item, index) in addAwards" :key="index" class="add-item">
                    <a-picture class="add-item-logo" :link="item.path" :alt="item.alt" />
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SAwardCompact',

    components: {
        APicture,
        DynamicLink,
        ALink,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        awardTitle: {
            type: String,
            default: undefined,
        },

        awardLink: {
            type: String,
            default: undefined,
        },

        awardLogo: {
            type: String,
            required: true,
        },

        awardLogoAlt: {
            type: String,
            default: 'Acronis',
        },

        awardLinkText: {
            type: String,
            default: undefined,
        },

        /**
         * Award link text
         */
        addAwards: {
            type: Array,
            default: undefined,
        },

        background: {
            type: String,
            default: 'light-blue',
        },

        glyph: {
            type: String,
            default: 'arrow-long',
        },
    },

    computed: {
        className() {
            return {
                'layout_add-awards': this.addAwards,
                'layout_one-award': !this.addAwards,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-award-compact {
    padding: 40px 0;
}

.a-container {
    display: flex;
    align-items: center;
    overflow-x: hidden;
}

.award {
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 100%;
    overflow: hidden;
}

.award-title {
    display: none;
    padding-inline-end: 32px;
    color: var(--av-nav-primary);
}

.award-source {
    margin-top: -16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 600;
    color: var(--av-nav-primary);
}

.award-logo {
    margin-inline-end: 24px;
    margin-top: 16px;

    &:deep(.a-picture__img) {
        height: 64px;
    }
}

.image-link {
    flex-shrink: 0;
}

.award-text,
.award-link {
    position: relative;
    margin-top: 16px;
    flex-grow: 1;
    width: calc(100% - 24px - 104px);
    max-width: 100%;
}

.arrow {
    transform: translateY(-4px);
    margin-inline-start: 8px;
    transition: fill linear 0.2s;
    fill: var(--av-brand-primary);
}

.add-list {
    display: none;
    margin-inline-end: -40px;
    height: 32px;
    overflow: hidden;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.add-item {
    padding-inline-end: 40px;
}

.add-item-logo {
    height: 32px;
    opacity: 0.5;
}

@media (--viewport-mobile-wide) {
    .layout_add-awards {
        .add-list {
            display: flex;
            width: calc(42% + 40px);
        }

        .award {
            width: 58%;
            margin-inline-end: 40px;
        }
    }

    .layout_one-award {
        .award-title {
            display: block;
            width: 33.3%;
            padding-inline-end: 8%;
        }

        .award-source {
            width: 66.6%;
        }
    }
}

@media (--viewport-desktop) {
    .award-source {
        flex-wrap: nowrap;
    }

    .award-text {
        width: 100%;
    }
}

[dir='rtl'] {
    .arrow {
        transform: translateY(-4px) rotate(180deg);
    }
}
</style>
