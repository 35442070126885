<template>
    <section
        :id="id"
        class="s-slice s-solutions"
        v-bind="$attrs"
        :class="basicClassName"
    >
        <div class="a-container">
            <a-slice-header :lead="lead" :title="title" />
            <ul v-if="isVisibleListLinks" class="s-solutions__list s-solutions__list-links">
                <template v-for="(card, index) in cardList">
                    <li v-if="card.type === 1" :key="index" class="s-solutions__list-item">
                        <a-link
                            class="s-solutions__list-item-link"
                            :to="`${url}#${card.id}`"
                            :text="card.title"
                            size="paragraph"
                            @click="clickCard(index)"
                        />
                    </li>
                </template>
            </ul>
            <div class="s-solutions__list">
                <template v-for="(card, index) in cardList">
                    <div
                        v-if="itemIsVisible(card, index)"
                        :key="index"
                        class="s-solutions__list-item"
                        :class="{ 's-solutions__list-item-opened': card.type !== 1 }"
                    >
                        <div
                            class="s-solutions__container"
                            :class="{ 's-solutions__active': card.type === 1 && cardActive === index }"
                        >
                            <div :id="card.id" class="s-solutions__card">
                                <a-link
                                    v-if="card.type === 1"
                                    class="s-solutions__card-link"
                                    :to="`${url}#${card.id}`"
                                    @click="clickCard(index)"
                                />
                                <div class="s-solutions__image">
                                    <a-picture
                                        class="s-solutions__image-item"
                                        :link="currentCard(card).img.link"
                                        :alt="currentCard(card).img.alt || currentCard(card).title"
                                    />
                                </div>
                                <div class="s-solutions__content">
                                    <div class="s-solutions__title">
                                        {{ currentCard(card).title }}
                                    </div>
                                    <div class="s-solutions__description">
                                        {{ currentCard(card).description }}
                                    </div>
                                    <ul class="s-solutions__product">
                                        <template v-for="(link, linkIndex) in currentCard(card).links">
                                            <li
                                                v-if="card.type !== 1 || cardMax === 1 || linkIndex < 2"
                                                :key="link.to"
                                                class="s-solutions__product-item"
                                            >
                                                <div class="s-solutions__product-label">
                                                    {{ link.label }}
                                                </div>
                                                <a-link
                                                    class="s-solutions__product-title"
                                                    v-bind="link"
                                                    :text="link.title"
                                                />
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SSolutions',

    components: {
        ASliceHeader,
        ALink,
        APicture,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        pref: {
            type: [String, Number],
            required: false,
            default: '',
        },

        cards: {
            type: Array,
            required: true,
        },

        isVisibleListLinks: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            url: this.$route ? this.$route.params.pathMatch : '',
            cardList: [],
            cardMax: 3,
            cardActive: -1,
        };
    },

    mounted() {
        this.resizeWindow();
        window.addEventListener('resize', this.resizeWindow);
        const cardId = window.location.hash.slice(1);

        this.cardList.forEach(({ type, id }, index) => {
            if (id === cardId) {
                this.changeActiveCard(type, index);
            }
        });

        this.$root.$on('tabChangeActive', this.tabChangeEvent);
    },

    created() {
        let i = 0;
        const end = this.cards.length;
        while (i < end) {
            this.cardList.push({
                id: `a_tab_${this.pref || ''}_${i}`,
                type: 1,
                ...this.cards[i],
            });
            i++;
            if (i % 2 === 0) {
                this.cardList.push({ type: 2 });
            }
            if (i % 3 === 0) {
                this.cardList.push({ type: 3 });
            }
        }
        if (i % 2 !== 0) {
            this.cardList.push({ type: 2 });
        }
        if (i % 3 !== 0) {
            this.cardList.push({ type: 3 });
        }
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.resizeWindow);
        this.$root.$off('tabChangeActive', this.tabChangeEvent);
    },

    methods: {
        tabId() {
            const [pref, name, tabId] = window.location.hash.split('_');
            return pref === '#a' && name === 'tab' && tabId ? Number(tabId) - 1 : 0;
        },
        changeActiveCard(item, index) {
            if (item === 1) {
                this.cardActive = index;
            }
        },
        clickCard(index) {
            if (this.cardActive === index) {
                this.cardActive = -1;
            } else {
                this.$root.$emit('tabChangeActive', {
                    activeTabIndex: this.tabId(),
                    hash: window.location.hash.slice(1),
                });
            }
        },
        currentCard(card) {
            if (card.type !== 1) {
                return this.cardList[this.cardActive > 0 ? this.cardActive : 0];
            }
            return card;
        },
        async tabChangeEvent(e) {
            const cardIndex = this.cardList.findIndex((x) => x.id === e.hash);
            const card = this.cardList[cardIndex];
            if (card) this.changeActiveCard(card.type, cardIndex);
            await this.$nextTick();
            document.querySelector(`#${e.hash}`).scrollIntoView();
        },
        resizeWindow() {
            if (window.innerWidth >= 1024) {
                this.cardMax = 3;
                return;
            }
            this.cardMax = window.innerWidth >= 768 ? 2 : 1;
        },
        itemIsVisible(card, i) {
            return card.type === 1 ||
                (card.type === this.cardMax && this.cardActive >= 0 &&
                i >= this.cardActive &&
                i <= this.cardActive + this.cardMax * 2 - 1);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-solutions {
    &:deep(.a-slice-header) {
        margin: 0;

        .a-slice-header__lead {
            margin: 0;
        }
    }

    &__list {
        margin-top: 24px;

        &:first-child {
            margin-bottom: 24px;
        }
    }

    &__list-links {
        padding-bottom: 24px;
    }

    &__list-item {
        padding-top: 16px;
    }

    &__container {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        border: var(--av-border);
        background-color: var(--av-inversed-primary);
        box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
    }

    &__card {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: stretch;
        flex-direction: column;
    }

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 142px;
        text-align: center;
        border-radius: 4px 4px 0 0;
        background: var(--av-brand-secondary-accent);
    }

    &__content {
        padding: 16px 24px 24px;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__image-item {
        width: auto;
        height: 131px;
    }

    &__title {
        @mixin title-accent;

        font-size: 18px;
        line-height: 24px;
        color: var(--av-nav-primary);
    }

    &__description {
        margin: 16px 0;
    }

    &__product {
        margin-top: auto;
    }

    &__product-item {
        margin: 24px 0 0;

        &:first-child {
            margin: 20px 0 0;
        }
    }

    &__product-label {
        font-size: 12px;
        line-height: 16px;
    }

    &__product-title {
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        color: var(--av-brand-secondary);
    }

    @media (--viewport-tablet) {
        &:deep(.a-slice-header) {
            .a-slice-header__lead {
                margin: 0 8px;
            }
        }

        &__list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin: 40px 0 0 0;
            margin-inline-end: -8px;
        }

        &__list-item {
            width: 50%;
            padding: 16px 8px 0;
            display: flex;
        }

        &__container {
            position: relative;
        }

        &__card-link {
            content: '';
            top: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            position: absolute;
        }

        &__description {
            display: none;
        }

        &__active {
            box-shadow: none;
            position: relative;
            border-color: var(--av-brand-primary);

            &::after {
                content: '';
                inset-inline-start: 50%;
                z-index: 1;
                width: 10px;
                height: 10px;
                bottom: -22px;
                background: white;
                position: absolute;
                transform: rotate(45deg);
                border-top: 1px solid var(--av-brand-primary);
                border-inline-start: 1px solid var(--av-brand-primary);
            }
        }

        &__list-item-opened {
            flex: 1 100%;

            .s-solutions__container {
                cursor: default;
                border-radius: 4px;
                box-sizing: border-box;
                border: 1px solid var(--av-brand-primary);
                box-shadow: 0 5px 6px rgba(138, 173, 207, 0.1865);

                .s-solutions {
                    &__card {
                        padding: 40px;
                        flex-direction: row-reverse;
                    }

                    &__content {
                        width: 100%;
                        padding: 0;
                    }

                    &__image {
                        display: none;
                    }

                    &__image-item {
                        width: auto;
                        height: 151px;
                        max-width: 234px;
                    }

                    &__title {
                        font-size: 32px;
                        line-height: 40px;
                        font-weight: 400;
                    }

                    &__description {
                        display: block;
                        font-size: 18px;
                        margin: 16px 0 0;
                        max-width: 736px;
                        line-height: 24px;
                    }

                    &__product {
                        display: flex;
                    }

                    &__product-item {
                        width: 254px;
                        display: flex;
                        margin: 40px 0 0 32px;
                        flex-direction: column;

                        &:first-child {
                            width: 220px;
                            margin-inline-start: 0;
                        }
                    }

                    &__product-label {
                        margin: 0 0 8px;
                        min-height: 16px;
                    }
                }
            }

            ~ .s-solutions {
                &__list-item-opened {
                    display: none;
                }
            }
        }
    }

    @media (--viewport-desktop) {
        &__list-item {
            width: 33.33%;
        }

        &__list-item-opened {
            .s-solutions__container {
                .s-solutions {
                    &__content {
                        width: 75%;
                    }

                    &__image {
                        width: 25%;
                        display: flex;
                        align-items: center;
                        background: rgba(255, 255, 255, 0);
                    }
                }
            }
        }
    }
}
</style>
