<template>
    <div class="all-documentation">
        <s-header-simple
            :title="title"
            :lead="lead"
            :body-text="bodyText"
            background="light-blue"
            class="documentation-header"
        />
        <s-basic-slice background="light-blue" class="page-links">
            <span
                v-for="(link, index) in pageLinks"
                :key="`page_links_${index}`"
            >
                <a-dynamic-link
                    :class="{ active: matchesCurrentPage(link.link) }"
                    :to="goToPage(link.link)"
                >
                    {{ link.text }}
                </a-dynamic-link>
            </span>
        </s-basic-slice>
        <div class="a-container">
            <div class="documentation-wrapper">
                <div
                    id="main-content"
                    class="main"
                    :class="{loading: isLoading}"
                >
                    <div id="sidebar" class="sidebar">
                        <div ref="sideInner" class="sidebar__inner">
                            <s-side-menu
                                :class="{ sticky: isSideMenuSticky }"
                                :documentation="documentation"
                                :links="links"
                                :active-product-group="activeProductGroup"
                                :filtered-product-groups="filteredGroupIds"
                                :dictionary="dictionary"
                                @update:active-product-group="activeProductGroup = $event"
                                @update:product-name-search="productSearchText = $event"
                                @accordionCollapse="onCollapse($event)"
                            />
                        </div>
                    </div>
                    <div id="content" class="content">
                        <s-content
                            :dictionary="dictionary"
                            :product-groups="filteredProductGroups"
                            :no-results="emptyResultsMessage"
                            @accordionCollapse="onCollapse($event)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ADynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import SHeaderSimple from '@core/slices/pages/header-simple/header-simple.vue';
import SContent from './components/content.vue';
import SSideMenu from './components/side-menu.vue';

export default {
    name: 'SAllDocumentationNew',

    components: {
        ADynamicLink,
        SBasicSlice,
        SHeaderSimple,
        SSideMenu,
        SContent,
    },
    mixins: [breakpoint],
    props: {
        /**
         * Title
         */
        title: {
            type: String,
            default: null,
        },

        pageLinks: {
            type: Array,
            default: () => ([]),
        },

        emptyResultsMessage: {
            type: Object,
            default: () => ({
                title: 'Nothing found',
                description: 'Try changing between the latest and legacy products or check the spelling of your search phrase',
            }),
        },

        /**
         * Lead
         */
        lead: {
            type: String,
            default: null,
        },

        /**
         * BodyText
         */
        bodyText: {
            type: String,
            default: null,
        },

        /**
         * Documentation
         */
        documentation: {
            type: Array,
            required: true,
        },

        /**
         * Links for side menu
         */
        links: {
            type: Array,
            required: true,
        },

        /**
         * Dictionary
         */
        dictionary: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data: () => ({
        activeProductGroup: 'all-products',
        productSearchText: '',
        isSideMenuSticky: false,
        resizeTimer: null,
        isLoading: true,
    }),
    computed: {
        filteredGroupIds() {
            const groups = this.documentation
                .filter((group) => (
                    group.products.some((product) => product.name.toLowerCase().includes(this.productSearchText.toLowerCase()))
                ))
                .map((group) => group.id);

            return ['all-products', ...groups];
        },
        filteredProductGroups() {
            let productGroup = this.activeProductGroup === 'all-products'
                ? this.documentation
                : this.documentation.filter((item) => item.id === this.activeProductGroup);

            productGroup = productGroup
                .filter((group) => (
                    group.products.some((product) => product.name.toLowerCase().includes(this.productSearchText.toLowerCase()))
                ))
                .map((group) => {
                    const products = group.products.filter((product) => (
                        product.name.toLowerCase().includes(this.productSearchText.toLowerCase())
                    ));

                    return {
                        ...group,
                        products,
                    };
                });

            return productGroup;
        },
    },
    beforeDestroy() {
        clearInterval(this.checkSidebar);
        clearTimeout(this.resizeTimer);
    },
    async mounted() {
        await import('sticky-sidebar');
        document.body.classList.add('documentation-new');
        await this.initSidebar();
        const hash = this.$route.hash;
        setInterval(this.checkSidebar, 1000);
        this.isLoading = false;
        if (hash) {
            const params = new URLSearchParams(hash.slice(1));
            const group = params.get('group');
            if (!group) {
                this.activeProductGroup = 'all-products';
                return;
            }
            const existInGroups = this.documentation.some((el) => el.id.includes(group));
            if (existInGroups) this.activeProductGroup = group;
            else this.activeProductGroup = 'all-products';
        }
    },
    methods: {
        async initSidebar() {
            await this.$nextTick();
            try {
                this.sidebar = new window.StickySidebar('#sidebar', {
                    containerSelector: '#main-content',
                    innerWrapperSelector: '.sidebar__inner',
                    topSpacing: 16,
                    bottomSpacing: -56,
                });

                if (!this.isMobile) return this.sidebar.initialize();
                return this.sidebar.destroy();
            } catch {
                return false;
            }
        },
        onCollapse(collapse) {
            if (collapse) {
                this.dispatchEmptyResize();
                return;
            }
            this.sidebar.direction = 'down';
            this.sidebar.updateSticky();
            this.dispatchEmptyResize();
        },
        checkSidebar() {
            const content = document.querySelector('#content .wrapper');
            const contentHeight = content.clientHeight || null;
            if (!this.sidebar || !contentHeight) return;
            this.dispatchEmptyResize();

            if (!this.isDesktop || contentHeight < 800) {
                this.sidebar.destroy();
                return;
            }

            this.sidebar.initialize();
        },
        dispatchEmptyResize(debounce = true, timer = 150) {
            if (!debounce) return window.dispatchEvent(new Event('resize'));

            clearTimeout(this.resizeTimer);
            this.resizeTimer = setTimeout(() => window.dispatchEvent(new Event('resize')), timer);
            return this.resizeTimer;
        },
        goToPage(link) {
            return `${link}${this.$route.hash}`;
        },
        matchesCurrentPage(url) {
            return this.$route.path.endsWith(url);
        },
    },
};
</script>

<style lang="postcss" scoped>
.all-documentation {
    min-height: 100vh;
    &:deep(.s-header-simple__text-title) {
        padding-top: 48px;
    }
}

.documentation-wrapper {
    padding-top: 48px;
    #main-content {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        position: relative;
        padding-bottom: 48px;
        &.loading {
            &:after {
                content: '';
                position:absolute;
                width: 100%;
                height: 100%;
                background: var(--av-inversed-primary);
                opacity: 0.8;
                z-index: 99;
            }
        }
        #sidebar {
            position: relative;
            z-index: 99;
            @mixin colls 12;
            @media (--viewport-desktop) {
                @mixin colls 4;
            }
            @media (--viewport-desktop-large) {
                @mixin colls 3;
            }
            .sidebar__inner{
                transform: translate(0, 0); /* For browsers don't support translate3d. */
                transform: translate3d(0, 0, 0);
                will-change: position, transform;
            }
            &.is-affixed {
                .sidebar__inner {
                    @media (--viewport-desktop) {
                        padding-bottom: 48px;
                    }
                }
            }
        }
        #content {
            @mixin colls 12;
            @media (--viewport-desktop) {
                @mixin colls 8;
            }
            @media (--viewport-desktop-large) {
                @mixin colls 9;
            }
        }
    }
}

.documentation-header {
    @mixin display-accent;

    @media (--viewport-mobile-wide) {
        @mixin hero-accent;
    }

    @media (--viewport-desktop) {
        @mixin large-accent;
    }

    &:deep(.s-header-simple .space-top) {
        padding-top: 48px;
    }
}

.page-links {
    padding: 48px 0 0;
    @mixin lead-accent;

    &:deep(a) {
        padding-bottom: 6px;
        margin-inline-end: 24px;
        border-bottom: 2px solid transparent;
        display: inline-block;
        color: var(--av-brand-primary);
        &.active {
            @mixin lead-accent;
            color: var(--av-nav-primary);
            border-color: var(--av-nav-primary);
        }
    }
}
</style>
