<template>
    <div class="event-pager">
        <el-pagination
            v-show="mounted"
            :total="totalCount"
            :page-size="pageSize"
            :current-page="currentPage"
            popper-class="pagination-dropdown"
            @current-change="change"
        />
        <div v-if="!mounted">
            <div v-for="i in totalPages" :key="`page_${i}`">
                <a :href="getPageLink(i)">{{ i }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventPager',
    components: {
        ElPagination: () => import('@uikit/ui-kit/packages/pagination'),
    },
    props: {
        pageSize: {
            type: Number,
            default: 6,
        },

        totalCount: {
            type: Number,
            required: true,
        },

        query: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['pageSelected'],
    data() {
        return {
            mounted: false,
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalCount / this.pageSize);
        },
        currentPage() {
            return parseInt(this.query.currentPage, 10) || 1;
        },
    },
    mounted() {
        this.mounted = true;
    },
    methods: {
        change(newPage) {
            this.$emit('pageSelected', newPage);
        },
        getPageLink(page) {
            const queryString = new URLSearchParams({ ...this.query, currentPage: page }).toString();
            return `?${queryString}`;
        },
    },
};
</script>

<style lang="postcss">
.event-pager {
    display: flex;
    justify-content: center;

    .el-pager {
        margin-inline-end: 40px;

        &__item {
            border-color: var(--av-brand-secondary-light);
            color: var(--av-nav-primary);
            min-width: 40px;

            &:hover {
                background: var(--av-brand-secondary-accent);
            }

            &.is-active {
                background: var(--av-brand-secondary-light);
                border: 0;
                padding-top: 1px;
                pointer-events: none;
            }
        }
    }

    .el-split-button {
        .el-dropdown__split-left {
            border-inline-end-width: 1px !important;
        }
        .el-button {
            border-color: var(--av-brand-secondary-light);
            color: var(--av-nav-primary);
        }
        .el-dropdown__divider {
            display: none;
        }
    }
}

.el-dropdown-menu {
    margin-inline-start: -50px;

    .el-dropdown-menu__item {
        color: var(--av-nav-primary);

        &:hover {
            background: var(--av-brand-secondary-accent);
        }
    }
}
</style>
