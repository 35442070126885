<template>
    <s-basic-slice v-bind="$attrs">
        <a-table-new v-bind="table" />
    </s-basic-slice>
</template>

<script>
import ATableNew from '@core/components/table-new/table-new.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'STablesNew',

    components: {
        ATableNew,
        SBasicSlice,
    },

    inheritAttrs: false,

    props: {
        table: {
            type: Object,
            required: true,
        },
    },
};
</script>
