<script lang="ts">
import Vue from 'vue';
import { LOCALE_DEFAULT } from '@model/const/locales';
import Error404Component from './component.vue';

export default Vue.extend({
    name: 'Error404Container',

    async serverPrefetch(): Promise<void> {
        await this.$store.dispatch('slices/getSyncedData', { slice: 'solutions-acronis-com-home', locale: LOCALE_DEFAULT });
        await this.$store.dispatch('slices/getSyncedData', { slice: 'solutions-acronis-com-404', locale: LOCALE_DEFAULT });

        this.$ssrContext.public.isNotFoundTrap = true;
        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        pageSettings() {
            return this.$store.state.slices.items['solutions-acronis-com-404'];
        },
    },

    methods: {
        getMeta(): any {
            const title = this.pageSettings.pageMeta.title;

            const meta = {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'robots', content: 'noindex, nofollow' },
                ],
                htmlAttrs: {
                    dir: 'ltr',
                    lang: 'en',
                },
            };

            return meta;
        },
    },

    render(h) {
        return h(Error404Component);
    },
});
</script>
