<template>
    <div class="cpoc-page cpc-category cpoc-wrapper">
        <div class="main-content">
            <div class="bg-section cpoc-wrapper">
                <s-blog-cpc-header v-bind="sliceDataHeader" />
                <s-blog-cpc-bar
                    :links="menuLinks"
                    :title="translations.cpcTitle"
                    :lead="translations.cpcLead"
                    :news-title="translations.newsTitle"
                    :about-title="translations.cpcAbout"
                    :active-category="category.id"
                />
            </div>
            <div class="cpoc-wrapper">
                <s-blog-cpc-insights
                    :cards="topCards"
                    :title="categoryTitle"
                />
                <s-blog-cpc-cards
                    :title="translations.cpcCardsTitle"
                    :cards="latestCards"
                    :is-loading="isXHRInProgress"
                >
                    <template v-if="listTotalPages > 1" #pagination>
                        <a-pagination
                            :current-page="listCurrentPage"
                            :total-pages="listTotalPages"
                            :url-template="pageUrl"
                            :click-handler="loadLatestCardsPage"
                        />
                    </template>
                </s-blog-cpc-cards>
            </div>
            <div class="cpoc-wrapper">
                <s-global-footer class="footer" v-bind="sliceDataFooter" />
            </div>
        </div>
    </div>
</template>

<script>
import APagination from '@core/components/pagination/pagination.vue';
import analytics from '@core/mixins/analytics.js';
import blog from '@core/mixins/blog.js';
import breakpoint from '@core/mixins/breakpoint.js';
import SBlogCpcBar from '@core/slices/blog/blog-cpc/blog-cpc-bar.vue';
import SBlogCpcCards from '@core/slices/blog/blog-cpc/blog-cpc-cards.vue';
import SBlogCpcHeader from '@core/slices/blog/blog-cpc/blog-cpc-header.vue';
import SBlogCpcInsights from '@core/slices/blog/blog-cpc/blog-cpc-insights.vue';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import socials from '@model/const/socials.ts';
import '@core/styles/sections/cpoc.pcss';

export default {
    name: 'SBlogCpsCategory',

    components: {
        SBlogCpcHeader,
        SBlogCpcBar,
        SBlogCpcInsights,
        SBlogCpcCards,
        APagination,
        SGlobalFooter,
    },

    mixins: [blog, analytics, breakpoint],

    props: {
        dispatchLoadLatestCardsPage: {
            type: Function,
            required: true,
        },
        category: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            socials,
            listCurrentPage: 1,
            cardsReloading: false,
            isXHRInProgress: false,
        };
    },

    computed: {
        // ==== Input params
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },
        sliceDataHeader() {
            return {
                ...this.$store.state.slices.items['s-main-header'],
                theme: 'dark',
            };
        },

        sliceDataFooter() {
            return {
                sliceSettingsName: 's-preset-cpoc-footer',
                socialGlyphSize: 'm',
                hasLogo: false,
                hasLocaleSelector: false,
                hasBorderTopCopyright: true,
                type: 'short',
                theme: 'dark',
            };
        },

        pageUrl() {
            return `/${this.locale}/cyber-protection-center/categories/${this.$route.params.category}/page/$PAGE_NUMBER$/`;
        },

        authorsLink() {
            return {
                to: '/cyber-protection-center/authors/',
                text: this.translations.insightsLink,
            };
        },

        authors() {
            return (this.$store.state.blog.authors.items || [])
                .map(this.getAuthorFields);
        },

        categories() {
            return this.$store.state.blog.categories.items || [];
        },

        menuLinks() {
            return this.categories.map((category) => this.getCategoryFields(category, this.locale));
        },

        categoryTitle() {
            const title = this.translations.categoryRecentTitle;
            return title?.replace('@value', this.category.title);
        },

        latestCards() {
            const cards = this.$store.state.blog.latestCards.items || [];
            return cards.map((card) => this.getBlogPostFields(card, this.translations));
        },

        listTotalPages() {
            return this.$store.state.blog.latestCards.pagination?.pages_total || 1;
        },

        topCards() {
            return (this.$store.state.blog.topCards.items || []).map((item) => this.getBlogPostFields(item, this.translations));
        },
    },

    mounted() {
        this.listCurrentPage = parseInt(this.$route.params.page, 10) || 1;
    },

    methods: {
        async loadLatestCardsPage(page, callback) {
            this.isXHRInProgress = true;
            const success = await this.dispatchLoadLatestCardsPage(page);
            this.isXHRInProgress = false;
            if (success) {
                this.listCurrentPage = page;
            }
            callback();
        },
    },
};
</script>

<style lang="postcss" scoped>
.cpoc-page {
    &:deep(.cpc-bar) {
        margin-bottom: 48px;
        @media (--viewport-tablet) {
            margin-bottom: 16px;
        }
    }
    &:deep(.cpc-insights) {
        margin-bottom: 40px;
    }
}
</style>
