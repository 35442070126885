/* eslint-disable max-len */
const ARGS_DEFAULT = {
    translations: {
        email: { text: 'Questions? Just email us at <a href="mailto:access-sales@acronis.com">access-sales@acronis.com</a>' },
        media: { alt: 'Acronis' },
        actionLinks: [
            { text: 'Contact sales +1 781 782 9000' },
            { text: 'Find a Reseller' },
        ],
        buttons: [
            { text: 'Try now' },
            { text: 'Buy now' },
        ],
        breadcrumbs: [
            { text: '' },
            { },
            { text: 'Solution for service providers' },
            { text: 'Managed server backups' },
        ],
    },
    media: {
        type: 'image',
        link: '/images/schema-shield/schema',
        enlargedImage: '/images/schema-shield/schema',
    },
    actionLinks: [
        { to: 'tel:+17817829000' },
        { to: '#' },
    ],
    buttons: [
        { to: '#', type: 'action' },
        { to: '#', type: 'main' },
    ],
    breadcrumbs: [
        { to: '#', glyph: 'home', glyphPosition: 'left' },
        { sublinks: true },
        { to: '#' },
        { to: '#' },
    ],
};

export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=44581-68721&t=TokMyJyDDac8zlkS-4';

export const Video = {
    args: {
        translations: {
            title: 'Back up and protect your virtual environments easily and efficiently',
            bodyText: 'Mitigate risks with agentless and <nobr>agent-based</nobr> backup and recovery of your virtual <a href="#">machines </a>and hosts on any of six supported hypervisors – VMware vSphere, Microsoft <nobr>Hyper-V,</nobr> Red Hat Virtualization, Citrix XenServer, Linux.',
            media: {
                text: 'Real-time demo of ransomware protection',
                time: '2:00',
                imageAlt: 'video',
            },
        },
        navTheme: 'light',
        background: 'white',
        cta: false,
        card: false,
        media: {
            type: 'video',
            ytId: 'kSLEGX-j9Jg',
            image: '/images/video_car/video_car',
        },
    },
};

export const BackgroundImage = {
    args: {
        translations: {
            title: 'Back up and protect your virtual environments easily and efficiently',
            bodyText: 'Mitigate risks with agentless and <nobr>agent-based</nobr> backup and recovery of your virtual <a href="#">machines </a>and hosts on any of six supported hypervisors – VMware vSphere, Microsoft <nobr>Hyper-V,</nobr> Red Hat Virtualization, Citrix XenServer, Linux.',
            media: ARGS_DEFAULT.media,
        },
        navTheme: 'dark',
        background: 'dark-blue',
        cta: false,
        card: false,
        media: ARGS_DEFAULT.media,
        backgroundImage: {
            link: {
                mobile: '@638631ff403174f73f1a8d6c75044a41',
                tablet: '@638631ff403174f73f1a8d6c75044a41',
                desktop: '@638631ff403174f73f1a8d6c75044a41',
            },
        },
    },
};

export const Cta = {
    args: {
        translations: {
            title: 'Back up and protect your virtual environments easily and efficiently',
            bodyText: 'Mitigate risks with agentless and <nobr>agent-based</nobr> backup and recovery of your virtual <a href="#">machines </a>and hosts on any of six supported hypervisors – VMware vSphere, Microsoft <nobr>Hyper-V,</nobr> Red Hat Virtualization, Citrix XenServer, Linux.',
            media: ARGS_DEFAULT.translations.media,
            buttons: ARGS_DEFAULT.translations.buttons,
            actionLinks: ARGS_DEFAULT.translations.actionLinks,
            email: ARGS_DEFAULT.translations.email,
            breadcrumbs: ARGS_DEFAULT.translations.breadcrumbs,
        },
        navTheme: 'light',
        background: 'white',
        cta: true,
        card: false,
        media: ARGS_DEFAULT.media,
        buttons: ARGS_DEFAULT.buttons,
        actionLinks: ARGS_DEFAULT.actionLinks,
        breadcrumbs: ARGS_DEFAULT.breadcrumbs,
    },
};

export const Card = {
    args: {
        translations: {
            title: 'Back up and protect your virtual environments easily and efficiently',
            bodyText: 'Mitigate risks with agentless and <nobr>agent-based</nobr> backup and recovery of your virtual <a href="#">machines </a>and hosts on any of six supported hypervisors – VMware vSphere, Microsoft <nobr>Hyper-V,</nobr> Red Hat Virtualization, Citrix XenServer, Linux.',
            lead: 'All-in-one data protection and cybersecurity to keep business flowing. No matter what.',
            description: 'Mitigate risks with agentless and agent-based backup and recovery of your virtual machines and hosts on any of six supported hypervisors',
            titleCard: 'Acronis Cyber Backup',
            media: ARGS_DEFAULT.translations.media,
            buttons: ARGS_DEFAULT.translations.buttons,
            email: ARGS_DEFAULT.translations.email,
            cardLabel: { text: 'Label text' },
        },
        navTheme: 'light',
        background: 'white',
        cta: false,
        card: true,
        media: ARGS_DEFAULT.media,
        buttons: ARGS_DEFAULT.buttons,
        cardLabel: {
            type: 'label',
            variant: 'success',
            layout: 'solid',
            size: 'l',
            theme: 'light',
            glyph: 'coins',
        },
    },
};

export const Visual = {
    args: {
        translations: {
            title: 'Back up and protect your virtual environments easily and efficiently',
            lead: 'All-in-one data protection and cybersecurity to keep business flowing. No matter what.',
        },
        navTheme: 'light',
        background: 'white',
        cta: false,
        card: false,
        visual: {
            type: 'video',
            source: [
                { type: 'webm', link: '//www.acronis.com/i/video/power.webm' },
                { type: 'mp4', link: '//www.acronis.com/i/video/power.mp4' },
            ],
        },
    },
};
