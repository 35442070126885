<template>
    <section v-if="content" class="main-page-new__subscription">
        <s-subscription v-bind="content" />
    </section>
</template>

<script>
import SSubscription from '@core/slices/pages/subscription/subscription.vue';

export default {
    name: 'SMainPageSubscription',
    components: {
        SSubscription,
    },
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-new__subscription {
    &:deep(.s-subscription) {
        text-align: center;
        padding: 56px 0 48px;
        background: var(--av-brand-secondary-accent);
        @media (--viewport-tablet) {
            padding: 56px 0;
        }
        &__grid {
            &__content {
                color: var(--av-nav-primary);
                &__title {
                    @mixin display;
                    margin: 0 auto;
                    font-weight: 700;
                    margin-bottom: 16px;
                }

                &__description {
                    @mixin lead;
                    margin-bottom: 32px;
                    @media (--viewport-tablet) {
                        margin-bottom: 16px;
                    }
                }

                .a-button {
                    @mixin lead;
                    min-width: 180px;
                    padding: 12px 0;
                    width: 100%;
                    font-weight: 700;
                    @media (--viewport-mobile-wide) {
                        width: auto;
                    }
                }
            }
        }
    }

    @media (--viewport-mobile-wide) {
        &:deep(.s-subscription) {
            &__grid {
                &__content {
                    width: 100%;
                }
            }
        }
    }
}
</style>
