<template>
    <a-svg-sprite class="a-glyph" :name="name" :class="className" />
</template>

<script>
import ASvgSprite from '@core/components/svg-sprite/svg-sprite.vue';
import { SIZES, COLORS, GLYPHS } from './constants.js';

export default {
    name: 'AGlyph',

    components: { ASvgSprite },

    props: {
        name: {
            type: String,
            required: true,
            validator: (value) => GLYPHS.includes(value),
        },

        size: {
            type: String,
            default: 's',
            validator: (value) => SIZES.includes(value),
        },

        isStroke: {
            type: Boolean,
            default: false,
        },

        fill: {
            type: String,
            default: undefined,
            validator: (value) => COLORS.includes(value),
        },
    },

    computed: {
        /**
         * Returns classes for component
         *
         * @returns {{[className]: condition}
         */
        className() {
            const { name, computedSize, isStroke, fill } = this;
            return {
                [`a-glyph__${name}`]: true,
                [`a-glyph_size_${computedSize}`]: computedSize,
                'a-glyph_stroke': isStroke,
                [`a-glyph_fill_${fill}`]: fill,
            };
        },

        computedSize() {
            if (this.size === 'xxxl') return 'xxxl';
            if (this.name.includes('--16')) return 's';
            if (this.name.includes('--24')) return 'm';
            if (this.name.includes('--32')) return 'l';
            return this.size;
        },
        type() {
            if (this.name.startsWith('i-')) return 'monochrome';
            if (this.name.startsWith('clr-')) return 'multicolor';
            return 'custom';
        },
    },
};
</script>

<style lang="postcss">
.a-glyph {
    display: inline-block;
    vertical-align: bottom;
    height: 16px;
    width: 16px;
    fill: var(--av-brand-secondary);

    &_size {
        &_xxs {
            width: 5px;
            height: 10px;
        }

        &_xs {
            width: 12px;
            height: 12px;
        }

        &_s {
            width: 16px;
            height: 16px;
        }

        &_m {
            width: 24px;
            height: 24px;
        }

        &_l {
            width: 32px;
            height: 32px;
        }

        &_xxxl {
            width: 72px;
            height: 72px;
        }
    }

    &_fill {
        &_blue {
            color: var(--av-link-secondary);
            fill: var(--av-link-secondary);
        }

        &_orange {
            color: rgba(215, 66, 53, 1);
            fill: rgba(215, 66, 53, 1);
        }

        &_azure {
            color: rgba(1, 179, 243, 1);
            fill: rgba(1, 179, 243, 1);
        }

        &_white {
            color: var(--av-inversed-primary);
            fill: var(--av-inversed-primary);
        }

        &_brand-light {
            color: var(--av-brand-light);
            fill: var(--av-brand-light);
        }

        &_brand-light {
            color: var(--av-brand-light);
            fill: var(--av-brand-light);
        }

        &_brand-primary {
            color: var(--av-brand-primary);
            fill: var(--av-brand-primary);
        }

        &_brand-secondary {
            color: var(--av-brand-secondary);
            fill: var(--av-brand-secondary);
        }

        &_fixed-success {
            color: var(--av-fixed-success);
            fill: var(--av-fixed-success);
        }

        &_nav-secondary {
            color: var(--av-nav-secondary);
            fill: var(--av-nav-secondary);
        }

        &_orange-bright {
            color: var(--av-fixed-warning);
            fill: var(--av-fixed-warning);
        }
    }

    &_stroke,
    &__verification {
        fill: var(--av-inversed-primary);
        stroke: var(--av-brand-secondary);
        stroke-opacity: 1;
        stroke-width: 2;
    }
}
</style>
