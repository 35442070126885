<template>
    <div class="a-container">
        <div class="cpc-intro" :style="computedStyle">
            <div class="spotlight-item">
                <dynamic-link :to="spotlightedCard.link" native-type="a">
                    <a-picture
                        class="spotlight-image"
                        :link="spotlightedCard.image_id"
                        fit="cover"
                        is-background
                    />
                </dynamic-link>
                <div ref="info" class="spotlight-info">
                    <dynamic-link :to="spotlightedCard.link" native-type="a">
                        <div class="title">
                            {{ format(spotlightedCard.title, 70) }}
                        </div>
                        <div class="description">
                            {{ format(spotlightedCard.description, 105) }}
                        </div>
                    </dynamic-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { truncate } from 'lodash';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoints from '@core/mixins/breakpoint.js';

export default {
    name: 'SBlogCpcIntro',
    components: {
        APicture,
        DynamicLink,
    },
    mixins: [breakpoints],
    props: {
        spotlightedCard: {
            type: Object,
            required: true,
        },
        links: {
            type: Array,
            required: true,
        },

        title: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            infoHeight: 0,
        };
    },
    computed: {
        computedStyle() {
            const topPosition = this.isMobile ? 96 : 88;
            const paddingBottom = this.infoHeight - topPosition;
            return `margin-bottom: ${paddingBottom}px;`;
        },
    },
    mounted() {
        this.calculateInfoHeight();
        window.addEventListener('resize', this.calculateInfoHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.calculateInfoHeight);
    },
    methods: {
        calculateInfoHeight() {
            this.infoHeight = this.$refs.info.offsetHeight;
        },
        format(str, length) {
            return truncate(str, { length, separator: ' ' });
        },
    },
};
</script>

<style lang="postcss" scoped>
.cpc-intro {
    margin-bottom: 88px;
    @media (--viewport-tablet) {
        margin-bottom: 80px;
    }
    .spotlight-item {
        display: block;
        position: relative;
        min-height: 418px;
        border-bottom: 2px solid var(--tru-main);
        .spotlight-info {
            position: absolute;
            background: var(--tru-main);
            color: var(--av-inversed-primary);
            padding: 16px;
            z-index: 30;
            inset-inline-start: 50%;
            bottom: auto;
            top: calc(100% - 96px);
            transform: translateX(-50%);
            width: calc(100% - 32px);
            @media (--viewport-tablet) {
                top: calc(100% - 88px);
                width: calc(100% - 96px);
            }
            @media (--viewport-desktop) {
                width: 765px;
                inset-inline-start: 0;
                transform: unset;
            }
            .title {
                @mixin display-accent;
                margin-bottom: 8px;
            }
            .description {
                @mixin paragraph;
            }
        }
    }
}
</style>
