<template>
    <dynamic-link
        v-if="ribbon"
        class="ribbon-link"
        :to="ribbon.linkUrl"
        :target="isLaunch ? '_self' : '_blank'"
    >
        <s-ribbon v-bind="ribbon" />
    </dynamic-link>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import SRibbon from './ribbon.vue';

export default {
    name: 'RibbonContainer',
    components: { SRibbon, DynamicLink },
    props: {
        ribbons: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            ribbon: null,
        };
    },
    computed: {
        isLaunch() {
            return this.ribbon.linkUrl.includes('#launch');
        },
    },
    mounted() {
        this.ribbon = this.ribbons[Math.floor(Math.random() * this.ribbons.length)];
    },
};
</script>

<style lang="postcss" scoped>
.ribbon-link {
    text-decoration: none;
}
</style>
