import { render, staticRenderFns } from "./press-releases-home-pagination.vue?vue&type=template&id=e32edf36&scoped=true"
import script from "./press-releases-home-pagination.vue?vue&type=script&lang=ts"
export * from "./press-releases-home-pagination.vue?vue&type=script&lang=ts"
import style0 from "./press-releases-home-pagination.vue?vue&type=style&index=0&id=e32edf36&prod&lang=postcss&scoped=true"
import style1 from "./press-releases-home-pagination.vue?vue&type=style&index=1&id=e32edf36&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e32edf36",
  null
  
)

export default component.exports