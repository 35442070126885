<template>
    <div class="quote">
        <div class="a-container">
            <h2 class="title">
                {{ title }}
            </h2>
            <div v-if="quote" class="author-wrap">
                <div v-if="author" class="author">
                    <a-picture class="photo" :link="author.image" />
                    <div class="name">
                        {{ author.name }}
                    </div>
                    <div class="author-title">
                        {{ author.title }}
                    </div>
                </div>
                <div class="quote-text">
                    {{ quote }}
                </div>
            </div>
            <div v-if="cards.length" class="cards">
                <div
                    v-for="card in cards"
                    :key="card.value"
                    class="card"
                >
                    <div class="value-wrap">
                        <div class="label">
                            {{ card.title }}
                        </div>
                        <div class="value">
                            {{ card.value }}
                        </div>
                    </div>
                    <div class="card-text">
                        {{ card.text }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SCareersQuote',

    components: {
        APicture,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        quote: {
            type: String,
            default: null,
        },

        author: {
            type: Object,
            default: null,
        },

        cards: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
        };
    },
};
</script>

<style lang="postcss" scoped>
.quote {
    position: relative;
    color: var(--av-inversed-primary);
    overflow: hidden;
    padding: 64px 0;
    background: linear-gradient(70.2deg, rgba(1, 19, 69, 1) 0%, rgba(59, 101, 191, 1) 100%);

    @media (--viewport-tablet) {
        padding: 96px 0 112px;
    }

    .a-container {
        position: relative;
        z-index: 1;
    }

    .title {
        @mixin display-accent;

        text-align: center;
        margin-bottom: 32px;

        @media (--viewport-tablet) {
            @mixin hero-accent;

            margin-bottom: 56px;
        }
    }

    .author-wrap {
        margin: 0 auto 64px;
        max-width: 1024px;

        @media (--viewport-tablet) {
            display: flex;
            align-items: center;
        }
    }

    .author {
        text-align: center;

        @media (--viewport-tablet) {
            flex-shrink: 0;
            margin-inline-end: 40px;
        }
    }

    .photo {
        width: 112px;
        height: 112px;
        margin: 0 auto 16px;
    }

    .name {
        @mixin title-accent;
    }

    .author-title {
        @mixin lead-accent;

        margin-bottom: 40px;
        color: var(--av-inversed-light);

        @media (--viewport-tablet) {
            margin-bottom: 0;
        }
    }

    .quote-text {
        @mixin lead;

        @media (--viewport-tablet) {
            @mixin title;
        }
    }

    .cards {
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        text-align: center;
        max-width: 800px;
        margin: 0 auto;

        @media (--viewport-desktop) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            max-width: none;
            gap: 16px;
        }
    }

    .card {
        @media (--viewport-tablet) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 32px;
        }

        @media (--viewport-desktop) {
            display: block;
        }
    }

    .value-wrap {
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            margin-bottom: 0;
        }

        @media (--viewport-desktop) {
            margin-bottom: 16px;
        }
    }

    .label {
        @mixin lead-accent;

        @media (--viewport-desktop) {
            margin-bottom: 8px;
        }
    }

    .value {
        @mixin hero-strong;

        background: linear-gradient(90deg, rgba(3, 104, 223, 1), rgba(109, 228, 252, 1));
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .card-text {
        @mixin lead;
        font-weight: 500;
    }
}
</style>
