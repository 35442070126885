<template>
    <div class="socials">
        <div class="a-container">
            <h2 class="title">
                {{ title }}
            </h2>
            <div v-if="lead" class="lead">
                {{ lead }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SCareersSocials',

    components: {
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        lead: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
        };
    },

    computed: {

    },

    methods: {

    },
};
</script>

<style lang="postcss" scoped>
.socials {
    @mixin basic-slice-paddings;
    color: var(--av-nav-primary);

    .title {
        @mixin title-accent;

        margin-bottom: 24px;
    }

    .lead {
        @mixin lead;
    }
}
</style>
