<script lang="ts">
import Vue from 'vue';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import { SECTION_ID_CPC, CARD_FIELDS_SUBSET, NEWS_NUMBER_ON_PAGE } from '@model/const/blog';
import { LOCALE_DEFAULT } from '@model/const/locales';
import BlogInsightsComponent from './component.vue';

const FEATURED_CPC_NUMBER_ON_PAGE = 2;

export default Vue.extend({
    name: 'SBlogInsights',

    async serverPrefetch(): Promise<void> {
        const locale = this.$route?.params.locale || LOCALE_DEFAULT;

        const featuredCPCRequest = new ProductAPIQueryBuilder('featuredCPC')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('is_news', '<>', '1')
            .addMatchesAll('translation.is_featured', '=', '1')
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setCustomParam('process-macros', '1')
            .setPaginate(1, FEATURED_CPC_NUMBER_ON_PAGE + 1) // +1 for spotlighted
            .toObject();

        const CPCNewsRequest = new ProductAPIQueryBuilder('CPCNews')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('is_news', '=', '1')
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setCustomParam('process-macros', '1')
            .setPaginate(1, NEWS_NUMBER_ON_PAGE)
            .toObject();

        const spotlightedCPCRequest = new ProductAPIQueryBuilder('spotlightedCPC')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('translation.is_spotlighted', '=', '1')
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .addMatchesAll('is_news', '<>', '1')
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setCustomParam('process-macros', '1')
            .setPaginate(1, 1)
            .toObject();

        const promises = [
            this.$store.dispatch('slices/getSyncedData', { slice: 'blog', locale }),
            this.$store.dispatch('blog/getEntity', { request: featuredCPCRequest }),
            this.$store.dispatch('blog/getEntity', { request: CPCNewsRequest }),
            this.$store.dispatch('blog/getEntity', { request: spotlightedCPCRequest }),
        ];

        await Promise.all(promises);
    },

    render(h) {
        return h(BlogInsightsComponent, { props: this.$attrs });
    },
});
</script>
