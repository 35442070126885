<template>
    <section :id="id" class="sport-page-detail">
        <div class="sport-detail-header" :class="header.theme">
            <a-picture
                class="sport-detail-header__image"
                :background="background"
                fit="cover"
                is-background
            />
            <div class="sport-detail-header__content a-container">
                <a-breadcrumbs
                    v-if="isBreadcrumbs"
                    :links="breadcrumbs"
                    glyph-desktop="icon-pointer-right"
                    glyph-mobile="long-arrow-left"
                    theme="light"
                    :sublinks="true"
                />
                <a-slice-header class="sport-detail-header__title" :title="header.title" />
                <a-button
                    v-if="header.buttons"
                    class="sport-detail-header__button"
                    v-bind="header.buttons"
                    type="action"
                />
            </div>
        </div>
        <div v-if="info" class="sport-detail-info">
            <div class="a-container sport-detail-info__container">
                <div class="sport-detail-info__logo">
                    <a-picture :link="info.imagebrand" />
                </div>
                <div class="sport-detail-info__text">
                    {{ info.text }}
                </div>
            </div>
        </div>
        <div v-if="featured" class="sport-featured">
            <a-picture
                class="sport-featured__image"
                :background="featured.background"
                fit="cover"
                is-background
            />
            <div class="a-container sport-featured__container">
                <div class="sport-featured__media">
                    <div @click="addHashToUrl">
                        <a-media v-bind="featured.media" :id="featured.id" />
                    </div>
                </div>
                <div class="sport-featured__meta">
                    <div class="sport-featured__title">
                        {{ featured.title }}
                    </div>
                    <div class="sport-featured__detail">
                        {{ featured.detail }}
                    </div>
                </div>
            </div>
        </div>
        <s-quote v-bind="quote" />
        <div class="sport-detail-protecting s-slice">
            <div class="a-container">
                <a-slice-header :title="protecting.title" :body-text="protecting.text" />
                <div class="sport-detail-protecting__items">
                    <div v-for="(item, i) in protecting.items" :key="i" class="sport-detail-protecting__item">
                        <div class="sport-detail-protecting__icon">
                            <a-icon :number="item.icon" />
                        </div>
                        <div class="sport-detail-protecting__name">
                            {{ item.name }}
                        </div>
                        <div class="sport-detail-protecting__text">
                            {{ item.text }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sport-detail-gallery s-slice">
            <div class="a-container">
                <a-slice-header :title="gallery.title" :lead="gallery.lead" />
                <a-slider class="sport-detail-gallery__slider" :options="sliceSliderOptions">
                    <a-slide v-for="(item, i) in gallery.items" :key="`gallery-slide-${i}`" class="sport-detail-gallery__slide">
                        <a-picture :link="item.image" />
                        <div class="sport-detail-gallery__text">
                            <a-dangerous-html :content="item.text" />
                        </div>
                    </a-slide>
                </a-slider>
            </div>
        </div>
        <s-media class="sport-detail-video" v-bind="media" />
        <s-advantages class="sport-detail-advantages" v-bind="advantages" />
    </section>
</template>

<script>
import ABreadcrumbs from '@core/components/breadcrumbs/breadcrumbs.vue';
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AIcon from '@core/components/icon/icon.vue';
import AMedia from '@core/components/media/media.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SAdvantages from '@core/slices/shared/sport/advantages.vue';
import SMedia from '@core/slices/shared/sport/media.vue';
import SQuote from '@core/slices/shared/sport/quote.vue';

export default {
    name: 'SSportDetail',
    components: {
        ABreadcrumbs,
        AButton,
        AMedia,
        ADangerousHtml,
        ASliceHeader,
        SMedia,
        SAdvantages,
        SQuote,
        ASlider,
        ASlide,
        AIcon,
        APicture,
    },
    mixins: [contentMixin, styleMixin],
    props: {
        background: {
            type: Object,
            required: true,
        },
        breadcrumbs: {
            type: Array,
            required: false,
            default: () => [],
        },
        featured: {
            type: Object,
            required: false,
            default: () => undefined,
        },
        header: {
            type: Object,
            required: true,
        },
        quote: {
            type: Object,
            required: true,
        },
        info: {
            type: Object,
            required: false,
            default: undefined,
        },
        protecting: {
            type: Object,
            required: true,
        },
        media: {
            type: Object,
            required: true,
        },
        advantages: {
            type: Object,
            required: true,
        },
        gallery: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            sliceSliderOptions: {
                slidesPerView: 'auto',
                showNavigation: true,
                spaceBetween: 16,
            },
        };
    },
    computed: {
        isBreadcrumbs() {
            return Boolean(this.breadcrumbs.length);
        },
    },
    mounted() {
        window.addEventListener('load', this.scrollToHash);
    },
    beforeDestroy() {
        window.removeEventListener('load', this.scrollToHash);
    },
    methods: {
        addHashToUrl() {
            if (!this.featured.id) return;

            window.location.hash = this.featured.id;
        },
        async scrollToHash() {
            await this.$nextTick();

            const hash = window.location.hash;
            if (!hash) return;

            const el = document.querySelector(hash);
            if (!el) return;

            window.scroll({
                top: el.getBoundingClientRect().top + window.scrollY,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
.sport-page-detail {
    &:deep(.a-breadcrumbs) {
        position: absolute;
        top: 0;
        width: auto;
        &__current {
            color: var(--av-inversed-primary);
        }
        &__link {
            .a-link__content {
                color: var(--av-inversed-primary);
                &__glyph {
                    color: var(--av-inversed-primary);
                }
            }
        }
        &__item {
            .a-breadcrumbs__glyph {
                fill: var(--av-inversed-primary);
            }
        }
        .a-glyph {
            fill: var(--av-inversed-primary) !important;
        }
    }
}
.sport-featured {
    position: relative;
    padding: 88px 0;

    &__container {
        @media (--viewport-tablet) {
            display: flex;
            align-items: center;
        }
    }

    &__title {
        @mixin display;
        margin-bottom: 24px;
        color: var(--av-nav-primary);

        @media (--viewport-tablet) {
            @mixin display;
        }
        @media (--viewport-desktop) {
            @mixin hero;
        }
    }

    &__detail {
        @mixin paragraph;
        color: var(--av-nav-primary);
        margin-bottom: 32px;

        @media (--viewport-desktop) {
            margin-bottom: 32px;
            @mixin title;
        }
    }

    &__media {
        min-height: 261px;
        margin-bottom: 10px;

        @media (--viewport-tablet) {
            min-width: 425px;
            margin-bottom: 0;
        }

        &:deep(.a-video-card) {
            min-height: 261px;
            padding-bottom: 0;
        }

        &:deep(.a-video-card__wrapper) {
            min-height: 261px;
        }

        @media (--viewport-tablet) {
            margin-inline-end: 113px;
        }
    }
}

.sport-detail-header {
    position: relative;
    padding-top: 77px;

    padding-bottom: 55px;

    @media (--viewport-tablet) {
        padding-top: 75px;
    }
    @media (--viewport-desktop) {
        padding-top: 93px;
        padding-bottom: 0;
    }
    &:after {
        content: '';
        position: absolute;
        inset-inline-start: 0;
        inset-inline-end: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(180deg, #00204d 0%, rgba(25, 58, 131, 0) 45.98%);
    }

    &.light {
        &:deep(.a-slice-header__title) {
            color: var(--av-inversed-primary);
        }
    }

    @media (--viewport-desktop) {
        height: 431px;
    }
    &__image {
        position: absolute;
        inset-inline-start: 0;
        inset-inline-end: 0;
        top: 0;
        bottom: 0;
        max-height: 510px;

        @media (--viewport-desktop) {
            max-height: 100%;
        }
    }
    &__content {
        position: relative;
        z-index: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &__title {
        margin-top: 75px;
        @media (--viewport-tablet) {
            max-width: 50%;
            margin-top: 75px;
            margin-bottom: 25px;
            &:deep(.a-slice-header__title) {
                @mixin display;
            }
        }
        @media (--viewport-desktop) {
            margin-top: 60px;
            max-width: 50%;
            margin-bottom: 40px;
            &:deep(.a-slice-header__title) {
                @mixin hero;
            }
        }
        @media (--viewport-desktop-wide) {
            max-width: 45%;
        }
    }
    &__button {
        background: var(--av-nav-primary);
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        height: 40px;
        width: fit-content;
        padding: 8px 16px;
        border-radius: 2px;
        &:hover {
            background: var(--av-nav-secondary);
        }
    }
}
.sport-detail-info {
    background: var(--av-solid-brand-lightest);
    padding: 28px 0;
    &__container {
        @media (--viewport-tablet) {
            display: flex;
            align-items: center;
        }
    }
    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        @media (--viewport-tablet) {
            justify-content: flex-start;
        }

        &:deep(.a-picture) {
            img {
                width: 100%;
                height: 100%;
            }
        }

        &:deep(span) {
            border-inline-start: 1px solid var(--av-nav-primary);
            margin-inline-start: 24px;
            margin-inline-end: 24px;
            height: 53px;
        }
    }
    &__text {
        display: block;
        margin: auto auto 0;
        text-align: center;
        @mixin body;
        margin-top: 25px;
        color: var(--av-nav-primary);
        @media (--viewport-tablet) {
            text-align: start;
            margin-top: 0;
            margin-inline-start: 40px;
        }
        @media (--viewport-desktop) {
            @mixin paragraph;
            margin-inline-start: 5%;
            max-width: 100%;
        }
        @media (--viewport-desktop-wide) {
            margin-inline-start: 11%;
            max-width: 63%;
        }
    }
}
.sport-detail-video {
    background: var(--av-nav-primary);
    border-bottom: 1px solid rgba(38, 104, 197, 0.5);
}
.sport-detail-advantages {
    background: linear-gradient(360deg, #15316f 0%, #00204d 100%);
}
.sport-detail-gallery {
    overflow-x: hidden;
    .a-container {
        overflow: hidden;
        @media (--viewport-tablet) {
            padding-inline-start: 0;
            margin-inline-start: 32px;
        }
        @media (--viewport-desktop-wide) {
            margin-inline-start: 64px;
            padding: 0;
        }
        @media (--viewport-desktop-large) {
            margin-inline-end: 0;
            max-width: none;
        }
        @media (--viewport-desktop-large) {
            max-width: 1312px;
            margin: 0 auto;
            width: 100%;
            padding-inline-start: 0;
            padding-inline-end: 0;
        }
    }
    &__slider {
        overflow: visible;
    }
    &__slide {
        max-width: 269px;
        width: 100%;

        /*
        &:not(.a-slide_active) {
            opacity: 0.5;
            cursor: pointer;
            &:hover {
                opacity: 1;
            }
        }
        */

        &:deep(img) {
            width: 100%;
            height: 100%;
        }
        @media (--viewport-mobile-wide) {
            max-width: 550px;
        }
        @media (--viewport-desktop-wide) {
            max-width: 712px;
        }
    }
    &__text {
        margin-top: 24px;
        max-width: 95%;
    }
    &:deep(.a-slider__nav) {
        margin-top: 48px;
    }
}
.sport-detail-protecting {
    background: var(--av-brand-accent);
    padding-top: 64px;
    padding-bottom: 64px;

    &__items {
        display: flex;
        flex-wrap: wrap;
        @media (--viewport-tablet) {
            flex-wrap: nowrap;
        }
    }
    &__item {
        padding-inline-end: 16px;
        margin-bottom: 40px;
        width: 50%;
        &:nth-child(2) {
            padding-inline-end: 0;
        }
        @media (--viewport-tablet) {
            &:nth-child(2) {
                padding-inline-end: 16px;
            }
            width: auto;
        }

        @media (--viewport-desktop) {
            margin-bottom: 0;
        }
        &:last-child {
            padding-inline-end: 0;
        }
    }
    &__name {
        @mixin lead;
        margin-bottom: 16px;
        font-weight: 700;
        color: var(--av-nav-primary);
        @media (--viewport-desktop) {
            @mixin title;
            margin-bottom: 8px;
            font-weight: normal;
        }
    }
    &__icon {
        margin-bottom: 24px;
        margin-top: 24px;
        width: 96px;
        height: 96px;
    }
    &__text {
        @mixin descriptor;
    }

    &:deep(.a-slice-header__body-text) {
        @mixin lead;
        color: var(--av-nav-primary);
    }

    &:deep(.a-slice-header) {
        margin-bottom: 24px;
        @media (--viewport-desktop) {
            width: 90%;
        }
    }

    &:deep(.a-slice-header__title) {
        text-align: center;
        @media (--viewport-desktop) {
            text-align: start;
        }
    }
}
</style>
