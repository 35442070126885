<template>
    <div v-if="content?.awards || list" class="awards">
        <ul v-if="content?.awards" :class="['list', {'list-title': itemTitle}]">
            <li v-for="(item, itemIndex) in content.awards" :key="`award-${itemIndex}`" class="list-item">
                <a-picture :link="item.to" :alt="item.name" />
                <p v-if="item.title" v-html="item.title" />
            </li>
        </ul>

        <ul v-if="list" :class="['list', {'list-title': hasTitle}]">
            <li v-for="(item, index) in awards" :key="index" class="list-item">
                <a-picture :link="item.image" :alt="item.title" />
                <p v-if="hasTitle && item.title" class="title" v-html="item.title" />
            </li>
        </ul>
    </div>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SInfoContentAwards',

    components: {
        APicture,
    },

    props: {
        content: {
            type: Object,
            default: null,
        },

        list: {
            type: Array,
            default: null,
        },

        hasTitle: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        awards() {
            return this.list.map((pid) => this.$store.state.awards.items[pid]).filter(Boolean);
        },

        itemTitle() {
            return this.content.awards.find((item) => item.title);
        },
    },

    serverPrefetch() {
        if (!this.list) return;
        this.list.forEach((aid) => this.$store.dispatch('awards/getAward', aid));
    },
};
</script>

<style lang="postcss" scoped>
.awards {
    overflow: hidden;

    .list {
        gap: 8px;
        row-gap: 16px;
        display: flex;
        flex-wrap: wrap;
        margin: 32px 32px 0;
        align-items: center;
        justify-content: center;

        @media (--viewport-tablet) {
            gap: 8px;
            margin: 32px 16px 0;
            justify-content: center;
        }

        @media (--viewport-desktop) {
            gap: 16px;
        }

        .list-item {
            width: 96px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:deep(.a-picture__img) {
                max-height: 48px;
                max-width: 88px;
            }
        }
    }

    .list-title {
        gap: 0;
        margin: 0;

        .list-item {
            @mixin lead-accent;
            width: auto;
            height: auto;
            display: flex;
            margin: 32px 0 0;
            padding: 8px 16px;
            border-radius: 16px;
            align-items: center;
            padding-inline-end: 32px;
            background: var(--av-brand-secondary-accent);

            @media (--viewport-tablet) {
                margin: 32px auto 0;
                max-width: 254px;
                padding-inline-end: 0;
            }

            & > p {
                padding-inline-end: 24px;
            }

            .a-picture {
                height: 64px;
                display: flex;
                align-items: center;
                margin-inline-end: 16px;

                &:deep(.a-picture__img) {
                    margin: 0;
                }
            }
        }
    }
}
</style>
