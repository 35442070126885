export const numberKeys = [
    48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105,
];

export const mapNumberKeys = {
    48: { number: 0 },
    49: { number: 1 },
    50: { number: 2 },
    51: { number: 3 },
    52: { number: 4 },
    53: { number: 5 },
    54: { number: 6 },
    55: { number: 7 },
    56: { number: 8 },
    57: { number: 9 },
    96: { number: 0 },
    97: { number: 1 },
    98: { number: 2 },
    99: { number: 3 },
    100: { number: 4 },
    101: { number: 5 },
    102: { number: 6 },
    103: { number: 7 },
    104: { number: 8 },
    105: { number: 9 },
};

export default { numberKeys, mapNumberKeys };
