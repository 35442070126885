<template>
    <dynamic-link
        v-if="!isContext"
        class="a-button"
        :class="className"
        :to="to"
        :target="target"
        :title="title"
        :event="gaEvent"
        :type="to ? null : nativeType"
        :is-router-link="isRouterLink"
        :native-type="nativeType"
        @click="handleClick"
    >
        <span class="a-button__content">{{ text }}</span>
        <a-glyph v-if="glyph" class="a-button__glyph" :name="glyph" />
    </dynamic-link>
    <div v-else class="a-button-with-context">
        <dynamic-link
            class="a-button"
            :class="className"
            :to="to"
            :target="target"
            :title="title"
            :event="gaEvent"
            :type="to ? null : nativeType"
            :is-router-link="isRouterLink"
            :native-type="nativeType"
            @click="handleClick"
        >
            <span class="a-button__content">{{ text }}</span>
            <a-glyph v-if="glyph" class="a-button__glyph" :name="glyph" />
        </dynamic-link>
        <div class="a-button-with-context__text">
            {{ context }}
        </div>
    </div>
</template>

<script>
import { TYPES, SIZES } from '@core/components/button/constants.js';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import { Logger } from '@utils/logger.ts';

export default {
    name: 'AButton',

    components: {
        AGlyph,
        DynamicLink,
    },

    props: {
        /**
         * Is Router Link
         */
        isRouterLink: {
            type: Boolean,
            default: false,
        },

        /**
         * Button text
         */
        text: {
            type: String,
            default: undefined,
        },

        /**
         * Button title
         */
        title: {
            type: String,
            default: undefined,
        },

        /**
         * Link URL
         */
        to: {
            type: String,
            default: null,
        },

        target: {
            type: String,
            default: () => undefined,
        },

        /**
         * Button type
         */
        type: {
            type: String,
            default: undefined,
            validator: (value) => !value || TYPES.includes(value),
        },

        /**
         * Button native type
         */
        nativeType: {
            type: String,
            default: 'button',
        },

        /**
         * Button size
         */
        size: {
            type: String,
            default: undefined,
            validator: (value) => !value || SIZES.includes(value),
        },

        /**
         * Disabled
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * Glyph name
         */
        glyph: {
            type: String,
            default: undefined,
        },

        /**
         * Context present
         */
        isContext: {
            type: Boolean,
            default: false,
        },

        /**
         * Context
         */
        context: {
            type: String,
            default: undefined,
        },

        /**
         * GA Event
         */
        event: {
            type: Object,
            default: undefined,
        },
    },

    emits: ['click'],

    data() {
        return {
            elem: null,
        };
    },

    computed: {
        /**
         * Returns classes for component
         *
         * @returns {[className]: condition}
         */
        className() {
            const { type, size, disabled, glyph, context } = this;
            return [
                'a-button_type',
                `a-button_type_${type || 'secondary'}`,
                {
                    [`a-button_size_${size}`]: size,
                    'a-button_with-glyph': glyph,
                    'a-button_disabled': disabled,
                    'a-button-with-context__button': context,
                },
            ];
        },

        ctaLocation() {
            const result = {
                location: -1,
                button: -1,
            };

            try {
                const containingPage = this.elem ? this.elem.closest('.page') : null;
                if (!containingPage) {
                    return result;
                }

                const topLevelSlices = Array.from(containingPage.children);

                result.location = topLevelSlices.findIndex((x) => x.contains(this.elem));
                if (result.location < 0) {
                    // should never happen
                    return result;
                }

                const sliceButtons = topLevelSlices[result.location].querySelectorAll('.a-button');
                result.button = Array.from(sliceButtons).findIndex((x) => x === this.elem);
            } catch (error) {
                Logger.error({ error });
            }

            return result;
        },

        gaEvent() {
            if (this.event?.doNotSendGA) {
                return this.event;
            }

            return {
                event: 'Acronis',
                category: 'Conversions',
                action: this.text,
                label: this.to || '[no label]',
                context: 'click',
                content: 'Generic',
                ...this.ctaLocation,
                ...this.event,
            };
        },
    },

    mounted() {
        this.elem = this.$el;
    },

    methods: {
        handleClick(event) {
            this.$emit('click', event);
        },
    },
};
</script>

<style lang="postcss">
.a-button {
    @mixin paragraph-accent;

    display: inline-flex;
    justify-content: center;
    margin: 0;
    padding: 12px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;

    &_with-glyph {
        align-items: center;
        justify-content: space-between;
    }

    &_type {
        &_main {
            color: var(--av-inversed-primary);
            background: var(--av-button-main);
            &:hover {
                background: var(--av-button-main-hover);
            }

            &:active {
                background: var(--av-button-main-active);
            }
            &.a-button_disabled {
                background: var(--av-button-main-light);
            }
        }

        &_action {
            color: var(--av-inversed-primary);
            background: var(--av-button-action);
            &:hover {
                background: var(--av-button-action-hover);
            }

            &:active {
                background: var(--av-button-action-active);
            }
            &.a-button_disabled {
                background: var(--av-button-action-light);
            }
        }

        &_secondary {
            color: var(--av-button-secondary);
            border: 1px solid var(--av-button-secondary);
            &:hover {
                background: var(--el-secondary-hover);
            }

            &:active {
                background: var(--el-secondary-active);
            }
            .a-button__glyph {
                fill: var(--av-button-secondary);
            }
            &.a-button_disabled {
                color: var(--av-button-secondary-light);
                border: 1px solid var(--av-button-secondary-light);
                &:hover,
                &:active {
                    background: transparent;
                }
                .a-button__glyph {
                    fill: var(--av-button-secondary-light);
                }
            }
        }

        &_inverse {
            color: var(--av-button-inversed);
            border: 1px solid var(--av-button-inversed);
            &:hover {
                background: var(--av-button-inversed-hover);
            }

            &:active {
                background: var(--av-button-inversed-active);
            }
            &.a-button_disabled {
                color: var(--av-button-inversed-light);
                border: 1px solid var(--av-button-inversed-light);
                &:hover,
                &:active {
                    background: transparent;
                }
                .a-button__glyph {
                    fill: var(--av-button-inversed-light);
                }
            }
        }
    }

    &_disabled {
        pointer-events: none;
        color: var(--av-inversed-light);
        .a-button__glyph {
            fill: var(--av-inversed-light);
        }
    }

    &_size {
        &_s {
            padding: 8px 16px;
        }
    }

    &__glyph {
        margin-inline-start: 16px;
        min-height: 16px;
        min-width: 16px;
        fill: var(--av-inversed-primary);
    }
    &__content {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-with-context {
        position: relative;
        text-align: center;

        @media (--viewport-desktop) {
            margin-bottom: -24px;
        }

        &__button {
            width: 100%;
        }

        &__text {
            display: none;

            @media (--viewport-desktop) {
                display: block;
                position: absolute;
                margin-top: 8px;
                width: 100%;
                text-align: center;

                @mixin caption;
            }
        }
    }
}
</style>
