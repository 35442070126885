<template>
    <div class="a-table__wrapper" :class="`a-table__wrapper_type-${settings.type}`">
        <div class="a-table" :class="className" :style="cssVars">
            <a-dangerous-html
                v-if="settings.lead?.length"
                class="a-table__lead"
                :content="settings.lead"
            />
            <!-- Conditional scroll container for type-1 tables -->
            <div v-if="settings.type === 1" class="a-table__scroll-container">
                <div ref="head" class="a-table__head">
                    <div
                        class="a-table__head-row syncscroll"
                        name="table-part"
                        :class="{ 'a-table__head-row_no-scroll': settings.headMobileType === 'no-scroll' }"
                    >
                        <div v-if="settings.switcher" class="a-table__head-cell">
                            <label class="a-table-switcher">
                                <input
                                    v-model="withOptionalRows"
                                    class="a-table-switcher__input"
                                    type="checkbox"
                                />
                                <span class="a-table-switcher__text">{{ settings.switcher }}</span>
                                <span class="a-table-switcher__decor" aria-hidden="true" />
                            </label>
                        </div>
                        <div
                            v-for="(cell, index) in head"
                            :key="`table-head-cell-${index}`"
                            class="a-table__head-cell"
                            :class="{
                                ['a-table__cell_colspan_' + cell.colspan]: cell.colspan,
                                ['a-table__cell_align_' + cell.align]: cell.align
                            }"
                        >
                            <template v-if="cell.title">
                                <div class="a-table__head-title">
                                    {{ cell.title }}
                                </div>
                            </template>
                            <template v-else>
                                <a-link
                                    v-if="cell.to"
                                    class="a-table__head-link"
                                    v-bind="cell"
                                    :size="cell.size || 'paragraph'"
                                />
                                <div v-if="cell.val" class="a-table__head-text">
                                    {{ cell.val }}
                                </div>
                                <div v-if="cell.desc" class="a-table__head-desc">
                                    {{ cell.desc }}
                                </div>
                                <div v-if="cell.label" class="a-table__head-label">
                                    {{ cell.label }}
                                </div>
                                <div v-if="cell.product" class="a-table__head-product">
                                    {{ cell.product }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <table class="a-table__body syncscroll" name="table-part">
                    <tr
                        v-for="(row, rowIndex) in rows"
                        :key="`table-row-${rowIndex}`"
                        class="a-table__row"
                        :class="getClassNameForTableRow(row)"
                    >
                        <td
                            v-for="(cell, cellIndex) in row.content"
                            :key="`table-cell-${cellIndex}`"
                            class="a-table__cell"
                            :class="getClassNameForTableCell(cell)"
                        >
                            <div v-if="settings.mobileType === 'column'" class="a-table__head-mobile">
                                <template v-if="head[cellIndex].title">
                                    <div
                                        class="a-table__head-mobile-field a-table__head-mobile-title"
                                        v-html="head[cellIndex].title"
                                    />
                                </template>
                                <template v-else>
                                    <div
                                        v-if="head[cellIndex].val"
                                        class="a-table__head-mobile-field a-table__head-mobile-text"
                                        v-html="head[cellIndex].val"
                                    />
                                    <div
                                        v-if="head[cellIndex].desc"
                                        class="a-table__head-mobile-field a-table__head-mobile-desc"
                                        v-html="head[cellIndex].desc"
                                    />
                                    <div
                                        v-if="head[cellIndex].label"
                                        class="a-table__head-mobile-field a-table__head-mobile-label"
                                        v-html="head[cellIndex].label"
                                    />
                                    <div
                                        v-if="head[cellIndex].product"
                                        class="a-table__head-mobile-field a-table__head-mobile-product"
                                        v-html="head[cellIndex].product"
                                    />
                                </template>
                            </div>
                            <template v-if="/subtitle|category|text/.test(cell.type)">
                                <div :class="[`a-table__${cell.type}`]">
                                    <a-glyph
                                        v-if="cell.glyph"
                                        class="a-table__glyph"
                                        :name="cell.glyph"
                                        :fill="cell.fill"
                                    />
                                    <template v-if="cell.productPageCTA">
                                        <a-product-cta
                                            :settings="{ hasDescription: true, ...cell.productPageCTA }"
                                        />
                                    </template>
                                    <template v-else>
                                        <a-link
                                            v-if="cell.to"
                                            v-bind="cell"
                                            class="a-table__category-link"
                                            :text="cell.val"
                                            :size="cell.size || 'paragraph'"
                                        />
                                        <span v-else v-html="cell.val" />
                                    </template>
                                    <a-tooltip v-if="cell.tooltip" class="a-table__tooltip" :text="cell.tooltip" />
                                </div>
                            </template>
                            <template v-else-if="cell.type === 'list'">
                                <div class="a-table__list-wrap">
                                    <ul class="a-table__list">
                                        <li v-for="(item, i) in cell.list" :key="i" class="a-table__list-item">
                                            {{ item }}
                                        </li>
                                    </ul>
                                </div>
                            </template>
                            <template v-else-if="cell.type === 'checked'">
                                <div class="a-table__check">
                                    <div v-if="translated && translated.checked" class="a-table__check-text">
                                        {{ translated.checked[cell.val] }}
                                    </div>
                                    <div v-else class="a-table__check-icon" :class="[`a-table__check-icon_${cell.val}`]" />
                                </div>
                            </template>
                            <template v-else-if="cell.type === 'button'">
                                <div class="a-table__button-wrapper">
                                    <a-button
                                        v-bind="cell.val"
                                        :to="cell.val.link"
                                    />
                                </div>
                            </template>
                            <template v-if="cell.description">
                                <div
                                    class="a-table__description"
                                    :class="{ 'a-table__description-full': cell.description.full }"
                                >
                                    <a-icon
                                        v-if="cell.description.icon"
                                        class="a-table__description-icon"
                                        icon-variant
                                        :number="cell.description.icon"
                                    />
                                    <div v-if="cell.description.title" class="a-table__description-title">
                                        {{ cell.description.title }}
                                    </div>
                                    <div v-if="cell.description.text" class="a-table__description-text">
                                        {{ cell.description.text }}
                                    </div>
                                    <a-link
                                        v-if="cell.description.link"
                                        class="a-table__description-link"
                                        v-bind="cell.description.link"
                                    />
                                    <div
                                        v-if="cell.description.note"
                                        class="a-table__description-note"
                                    >
                                        {{ cell.description.note }}
                                    </div>
                                </div>
                            </template>
                        </td>
                    </tr>
                </table>
            </div>
            <template v-else>
                <div ref="head" class="a-table__head">
                    <div
                        class="a-table__head-row syncscroll"
                        name="table-part"
                        :class="{ 'a-table__head-row_no-scroll': settings.headMobileType === 'no-scroll' }"
                    >
                        <div v-if="settings.switcher" class="a-table__head-cell">
                            <label class="a-table-switcher">
                                <input
                                    v-model="withOptionalRows"
                                    class="a-table-switcher__input"
                                    type="checkbox"
                                />
                                <span class="a-table-switcher__text">{{ settings.switcher }}</span>
                                <span class="a-table-switcher__decor" aria-hidden="true" />
                            </label>
                        </div>
                        <div
                            v-for="(cell, index) in head"
                            :key="`table-head-cell-${index}`"
                            class="a-table__head-cell"
                            :class="{
                                ['a-table__cell_colspan_' + cell.colspan]: cell.colspan,
                                ['a-table__cell_align_' + cell.align]: cell.align
                            }"
                        >
                            <template v-if="cell.title">
                                <div class="a-table__head-title">
                                    {{ cell.title }}
                                </div>
                            </template>
                            <template v-else>
                                <a-link
                                    v-if="cell.to"
                                    class="a-table__head-link"
                                    v-bind="cell"
                                    :size="cell.size || 'paragraph'"
                                />
                                <div v-if="cell.val" class="a-table__head-text">
                                    {{ cell.val }}
                                </div>
                                <div v-if="cell.desc" class="a-table__head-desc">
                                    {{ cell.desc }}
                                </div>
                                <div v-if="cell.label" class="a-table__head-label">
                                    {{ cell.label }}
                                </div>
                                <div v-if="cell.product" class="a-table__head-product">
                                    {{ cell.product }}
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <table class="a-table__body syncscroll" name="table-part">
                    <tr
                        v-for="(row, rowIndex) in rows"
                        :key="`table-row-${rowIndex}`"
                        class="a-table__row"
                        :class="getClassNameForTableRow(row)"
                    >
                        <td
                            v-for="(cell, cellIndex) in row.content"
                            :key="`table-cell-${cellIndex}`"
                            class="a-table__cell"
                            :class="getClassNameForTableCell(cell)"
                        >
                            <div v-if="settings.mobileType === 'column'" class="a-table__head-mobile">
                                <template v-if="head[cellIndex].title">
                                    <div
                                        class="a-table__head-mobile-field a-table__head-mobile-title"
                                        v-html="head[cellIndex].title"
                                    />
                                </template>
                                <template v-else>
                                    <div
                                        v-if="head[cellIndex].val"
                                        class="a-table__head-mobile-field a-table__head-mobile-text"
                                        v-html="head[cellIndex].val"
                                    />
                                    <div
                                        v-if="head[cellIndex].desc"
                                        class="a-table__head-mobile-field a-table__head-mobile-desc"
                                        v-html="head[cellIndex].desc"
                                    />
                                    <div
                                        v-if="head[cellIndex].label"
                                        class="a-table__head-mobile-field a-table__head-mobile-label"
                                        v-html="head[cellIndex].label"
                                    />
                                    <div
                                        v-if="head[cellIndex].product"
                                        class="a-table__head-mobile-field a-table__head-mobile-product"
                                        v-html="head[cellIndex].product"
                                    />
                                </template>
                            </div>
                            <template v-if="/subtitle|category|text/.test(cell.type)">
                                <div :class="[`a-table__${cell.type}`]">
                                    <a-glyph
                                        v-if="cell.glyph"
                                        class="a-table__glyph"
                                        :name="cell.glyph"
                                        :fill="cell.fill"
                                    />
                                    <template v-if="cell.productPageCTA">
                                        <a-product-cta
                                            :settings="{ hasDescription: true, ...cell.productPageCTA }"
                                        />
                                    </template>
                                    <template v-else>
                                        <a-link
                                            v-if="cell.to"
                                            v-bind="cell"
                                            class="a-table__category-link"
                                            :text="cell.val"
                                            :size="cell.size || 'paragraph'"
                                        />
                                        <span v-else v-html="cell.val" />
                                    </template>
                                    <a-tooltip v-if="cell.tooltip" class="a-table__tooltip" :text="cell.tooltip" />
                                </div>
                            </template>
                            <template v-else-if="cell.type === 'list'">
                                <div class="a-table__list-wrap">
                                    <ul class="a-table__list">
                                        <li v-for="(item, i) in cell.list" :key="i" class="a-table__list-item">
                                            {{ item }}
                                        </li>
                                    </ul>
                                </div>
                            </template>
                            <template v-else-if="cell.type === 'checked'">
                                <div class="a-table__check">
                                    <div v-if="translated && translated.checked" class="a-table__check-text">
                                        {{ translated.checked[cell.val] }}
                                    </div>
                                    <div v-else class="a-table__check-icon" :class="[`a-table__check-icon_${cell.val}`]" />
                                </div>
                            </template>
                            <template v-else-if="cell.type === 'button'">
                                <div class="a-table__button-wrapper">
                                    <a-button
                                        v-bind="cell.val"
                                        :to="cell.val.link"
                                    />
                                </div>
                            </template>
                            <template v-if="cell.description">
                                <div
                                    class="a-table__description"
                                    :class="{ 'a-table__description-full': cell.description.full }"
                                >
                                    <a-icon
                                        v-if="cell.description.icon"
                                        class="a-table__description-icon"
                                        icon-variant
                                        :number="cell.description.icon"
                                    />
                                    <div v-if="cell.description.title" class="a-table__description-title">
                                        {{ cell.description.title }}
                                    </div>
                                    <div v-if="cell.description.text" class="a-table__description-text">
                                        {{ cell.description.text }}
                                    </div>
                                    <a-link
                                        v-if="cell.description.link"
                                        class="a-table__description-link"
                                        v-bind="cell.description.link"
                                    />
                                    <div
                                        v-if="cell.description.note"
                                        class="a-table__description-note"
                                    >
                                        {{ cell.description.note }}
                                    </div>
                                </div>
                            </template>
                        </td>
                    </tr>
                </table>
            </template>
        </div>
    </div>
</template>

<script>
import { throttle } from 'lodash';
import './sync-scroll.js';
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import AIcon from '@core/components/icon/icon.vue';
import ALink from '@core/components/link/link.vue';
import AProductCta from '@core/components/product-cta/product-cta.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import analytics from '@core/mixins/analytics.js';
import breakpoint from '@core/mixins/breakpoint.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import purchasing from '@core/mixins/purchasing.js';
import { ANCHOR_HEIGHT } from '@core/slices/pages/anchor-menu/anchor-menu.vue';

export default {
    name: 'ATableNew',

    components: {
        ADangerousHtml,
        AGlyph,
        AButton,
        ATooltip,
        ALink,
        AProductCta,
        AIcon,
    },

    mixins: [breakpoint, purchasing, analytics, cleverbridge],

    props: {
        translated: {
            type: Object,
            default: undefined,
        },

        settings: {
            type: Object,
            required: true,
        },

        head: {
            type: Array,
            required: true,
        },

        rows: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            withOptionalRows: true,
            submenuOnPage: false,
            countHeadStylesThrottled: null,
        };
    },

    computed: {
        cssVars() {
            let stickyOffset = ANCHOR_HEIGHT;
            const anchorMenu = this.$store.state.pages?.page?.body?.find((s) => s.name === 's-anchor-menu');

            if (anchorMenu?.data?.stickyOffset) {
                let deviceType;

                if (typeof window !== 'undefined') {
                    deviceType = this.getDeviceTypeByScreenWidth(window.outerWidth);
                } else {
                    deviceType = this.currentBreakpoint;
                }

                const offsetConfig = anchorMenu.data.stickyOffset;
                const addOffset = offsetConfig[deviceType] || offsetConfig.default || 0;
                stickyOffset += addOffset;
            }

            return {
                '--table-sticky-offset': `${stickyOffset}px`,
            };
        },

        className() {
            return {
                [`a-table_layout_${this.settings.layout}`]: this.settings.layout,
                [`a-table_height_${this.settings.height}`]: this.settings.height,
                [`a-table_align_${this.settings.align}`]: this.settings.align,
                'a-table_sticky': this.settings.sticky,
                'a-table_without-optional': !this.withOptionalRows,
                'a-table_with_submenu': this.submenuOnPage,
                'a-table_mobile-type-column': this.settings.mobileType === 'column',
                [`a-table_type-${this.settings.type}`]: this.settings.type,
                'a-table_lte-3-cols': this.rows[0].content.length <= 3,
            };
        },

        currentLocale() {
            return this.$route?.params.locale;
        },
    },

    mounted() {
        if (this.settings.sticky) {
            const submenu = document.querySelector('.a-submenu');
            const productSticky = document.querySelector('.s-header-product-new');

            if (submenu || productSticky) {
                this.submenuOnPage = true;
            }

            if (this.currentBreakpoint === 'desktopLarge') {
                this.countHeadStyles();
            }

            this.countHeadStylesThrottled = throttle(this.countHeadStyles, 200);

            window.addEventListener('resize', this.countHeadStylesThrottled);
        }
    },

    destroyed() {
        if (this.settings.sticky) {
            window.removeEventListener('resize', this.countHeadStylesThrottled);
        }
    },

    methods: {
        getClassNameForTableCell(cell) {
            return {
                [`a-table__cell_colspan_${cell.colspan}`]: cell.colspan,
                [`a-table__cell_align_${cell.align}`]: cell.align,
                'a-table__cell_without_border': cell.withoutBorder,
            };
        },

        getClassNameForTableRow(row) {
            return {
                'a-table__row_optional': row.optional,
                'a-table__row_with-indent': row.subtitleIndent,
                'a-table__row_no-scroll': row.mobileType === 'no-scroll',
                'a-table__row_multirow': row.mobileType === 'multirow',
                [`a-table__row_vertical_${row.vertical}`]: row.vertical,
            };
        },

        countHeadStyles() {
            const containerWidth = 1312;

            if (this.currentBreakpoint === 'desktopLarge') {
                this.$refs.head.style.margin = `0 ${
                    (containerWidth - document.documentElement.clientWidth) / 2
                }px`;
                this.$refs.head.style.padding = `0 ${
                    (document.documentElement.clientWidth - containerWidth) / 2
                }px`;
            } else {
                this.$refs.head.style = '';
            }
        },
    },

    async serverPrefetch() {
        await this.initSettingsCTA(this.rows.flatMap((item) => item?.content));
    },
};
</script>

<style lang="postcss" scoped>
.a-table__wrapper_type-1 {
  overflow-x: auto;
  max-width: 100%;
    .a-table__scroll-container {
        overflow-x: auto;
        display: block;
        max-width: 100%;
    }
}

.a-table {
    &_layout {
        &_8-2 {
            .a-table__cell,
            .a-table__head-cell {
                @mixin colspan 50%, 50%;

                flex: 0 0 50%;

                @media (--viewport-tablet) {
                    @mixin colspan 25%, 25%;

                    flex: 0 0 25%;
                }

                @media (--viewport-desktop) {
                    @mixin colspan 9%, 9%;

                    flex: 0 0 9%;

                    &:first-of-type {
                        @mixin colspan 37%, 9%;

                        flex: 0 0 37%;
                    }
                }
            }
        }

        &_6-1 {
            .a-table__cell,
            .a-table__head-cell {
                @mixin colspan 50%, 50%;

                flex: 0 0 50%;

                @media (--viewport-tablet) {
                    @mixin colspan 25%, 25%;

                    flex: 0 0 25%;
                }

                @media (--viewport-desktop) {
                    @mixin colspan 16.66%, 16.66%;

                    flex: 0 0 16.66%;
                }
            }
        }

        &_6-2 {
            .a-table__cell,
            .a-table__head-cell {
                @mixin colspan 50%, 50%;

                flex: 0 0 50%;

                @media (--viewport-tablet) {
                    @mixin colspan 33.33%, 33.33%;

                    flex: 0 0 33.3%;
                }

                @media (--viewport-desktop) {
                    @mixin colspan 25%, 25%;

                    flex: 0 0 25%;
                }

                @media (--viewport-desktop-wide) {
                    @mixin colspan 20%, 20%;

                    flex: 0 0 20%;
                }
            }
        }

        &_5-1 {
            .a-table__cell,
            .a-table__head-cell {
                @mixin colspan 50%, 50%;

                flex: 0 0 50%;

                @media (--viewport-tablet) {
                    @mixin colspan 25%, 25%;

                    flex: 0 0 25%;
                }

                @media (--viewport-desktop) {
                    @mixin colspan 16.66%, 16.66%;

                    flex: 0 0 16.66%;

                    &:first-of-type {
                        @mixin colspan 33.33%, 16.66%;

                        flex: 0 0 33.33%;
                    }
                }
            }
        }

        &_5-2 {
            .a-table__cell,
            .a-table__head-cell {
                @mixin colspan 50%, 50%;

                flex: 0 0 50%;

                @media (--viewport-tablet) {
                    @mixin colspan 16.66%, 16.66%;

                    flex: 0 0 16.66%;

                    &:first-of-type {
                        @mixin colspan 33.33%, 50%;

                        flex: 0 0 33.33%;

                        & > * {
                            padding-inline-end: 27%;
                        }
                    }
                }
            }
        }

        &_4-1 {
            .a-table__cell,
            .a-table__head-cell {
                @mixin colspan 50%, 50%;

                flex: 0 0 50%;

                @media (--viewport-tablet) {
                    @mixin colspan 25%, 25%;

                    flex: 0 0 25%;
                }
            }
        }

        &_4-2 {
            .a-table__cell,
            .a-table__head-cell {
                @mixin colspan 50%, 50%;

                flex: 0 0 50%;

                @media (--viewport-tablet) {
                    @mixin colspan 25%, 25%;

                    flex: 0 0 25%;
                }

                @media (--viewport-desktop) {
                    @mixin colspan 16.66%, 16.66%;

                    flex: 0 0 16.66%;

                    &:first-of-type {
                        @mixin colspan 50%, 16.66%;

                        flex: 0 0 50%;

                        & > * {
                            padding-inline-end: 35%;
                        }
                    }
                }
            }
        }

        &_4-3 {
            .a-table__cell,
            .a-table__head-cell {
                @mixin colspan 50%, 50%;

                flex: 0 0 50%;

                @media (--viewport-tablet) {
                    @mixin colspan 16.66%, 16.66%;

                    flex: 0 0 16.66%;

                    &:first-of-type {
                        @mixin colspan 50%, 16.66%;

                        flex: 0 0 50%;

                        & > * {
                            padding-inline-end: 18%;
                        }
                    }
                }

                @media (--viewport-desktop) {
                    &:first-of-type {
                        & > * {
                            padding-inline-end: 35%;
                        }
                    }
                }
            }
        }

        &_4-4 {
            .a-table__cell,
            .a-table__head-cell {
                @mixin colspan 50%, 50%;

                flex: 0 0 50%;

                @media (--viewport-tablet) {
                    @mixin colspan 16.66%, 16.66%;

                    flex: 0 0 16.66%;

                    &:first-of-type {
                        @mixin colspan 50%, 16.66%;

                        flex: 0 0 50%;
                    }
                }
            }
        }

        &_3-1 {
            .a-table__cell,
            .a-table__head-cell {
                @mixin colspan 50%, 50%;

                flex: 0 0 50%;

                @media (--viewport-tablet) {
                    @mixin colspan 33.33%, 33.33%;

                    flex: 0 0 33.33%;
                }
            }
        }

        &_3-2 {
            .a-table__cell,
            .a-table__head-cell {
                @mixin colspan 50%, 50%;

                flex: 0 0 50%;

                @media (--viewport-tablet) {
                    @mixin colspan 25%, 25%;

                    flex: 0 0 25%;

                    &:first-of-type {
                        @mixin colspan 50%, 25%;

                        flex: 0 0 50%;

                        & > * {
                            padding-inline-end: 18%;
                        }
                    }
                }

                @media (--viewport-desktop) {
                    &:first-of-type {
                        & > * {
                            padding-inline-end: 35%;
                        }
                    }
                }
            }
        }

        &_3-3 {
            .a-table__cell,
            .a-table__head-cell {
                @mixin colspan 50%, 50%;

                flex: 0 0 50%;

                @media (--viewport-tablet) {
                    @mixin colspan 25%, 25%;

                    flex: 0 0 25%;

                    &:first-of-type {
                        @mixin colspan 50%, 25%;

                        flex: 0 0 50%;
                    }
                }
            }
        }

        &_2-1 {
            .a-table__cell,
            .a-table__head-cell {
                @mixin colspan 50%, 50%;

                flex: 0 0 50%;
            }
        }

        &_2-2 {
            .a-table__cell,
            .a-table__head-cell {
                @mixin colspan 33.33%, 33.33%;

                flex: 0 0 33.33%;

                &:first-of-type {
                    @mixin colspan 66.66%, 33.33%;

                    flex: 0 0 66.66%;
                }
            }
        }
    }

    &_height {
        &_small {
            .a-table__cell {
                padding-top: 16px;
                padding-bottom: 16px;
            }
        }

        &_bigger {
            .a-table__cell {
                padding-top: 24px;
                padding-bottom: 24px;
            }
        }
    }

    &_sticky {
        .a-table {
            &__head {
                position: sticky;
                z-index: 1;
                top: 0;
                margin: 0 -16px;
                padding: 0 16px;
                box-shadow: var(--av-shadow-small);
                clip-path: inset(0 0 -20px 0);

                @media (--viewport-mobile-wide) {
                    margin: 0 -32px;
                    padding: 0 32px;
                }
                @media (--viewport-desktop-wide) {
                    margin: 0 -64px;
                    padding: 0 64px;
                }
            }

            &__head-cell {
                border-bottom: none;
            }
        }
    }

    &_with_submenu {
        &.a-table_sticky {
            .a-table {
                &__head {
                    top: 58px;
                    @media (--viewport-mobile-wide) {
                        top: 65px;
                    }
                }
            }
        }
    }

    &_without-optional {
        .a-table__row_optional {
            display: none;
        }
    }

    &_align {
        &_left {
            .a-table__cell,
            .a-table__head-cell {
                &:not(:first-of-type) {
                    text-align: start;
                }
            }
            .a-table__check {
                position: relative;
                height: 100%;

                &-icon {
                    top: 50%;
                    inset-inline-start: 0;
                    transform: translate(0, -50%);
                }
            }
        }

        &_center {
            .a-table__cell,
            .a-table__head-cell {
                &:not(:first-of-type) {
                    text-align: center;
                }
            }

            &:deep(.cta-description) {
                justify-content: center;
            }
        }
    }

    &__head-mobile {
        display: none;
    }

    &_mobile-type-column {
        @media (max-width: 767px) {
            .a-table {
                &__head {
                    display: none;
                }

                &__category {
                    display: flex;
                }

                &__glyph {
                    margin-inline-end: 16px;
                }

                &__row {
                    flex-wrap: wrap;
                }

                &__cell {
                    flex-basis: 100%;

                    &:not(:last-child) {
                        border-bottom: 0;
                    }

                    &:last-child {
                        padding-bottom: 32px;
                        margin-bottom: 24px;
                    }
                }

                &__head-mobile {
                    display: block;
                }

                &__head-mobile-field {
                    margin-bottom: 16px;
                }
            }
        }
    }

    .data-center-figure {
        fill: green;
    }

    &__head {
        display: block;
    }

    &__head-row {
        display: flex;
        overflow-x: scroll;
        background-color: var(--av-inversed-primary);
        scrollbar-width: none;
        margin: 0 -16px;
        padding: 0 calc(16px - 8px);

        &::-webkit-scrollbar {
            height: 0;
            background: rgba(255, 255, 255, 0);
        }

        @media (--viewport-mobile-wide) {
            margin: 0 -32px;
            padding: 0 calc(32px - 8px);
        }

        @media (--viewport-desktop-wide) {
            margin: 0 -64px;
            padding: 0 calc(64px - 8px);
        }

        @media (--viewport-desktop-large) {
            margin: 0 -8px;
            padding: 0;
        }

        &.a-table__head-row_no-scroll {
            @media (max-width: 767px) {
                .a-table {
                    &__head-cell {
                        flex-basis: auto;
                        flex-shrink: 1;
                    }
                }
            }
        }
    }

    &__head-cell {
        display: block;
        padding: 32px 8px;
        border-bottom: 1px solid var(--av-brand-secondary-light);
        overflow: hidden;

        &:first-of-type {
            text-align: start;
        }

        &_align {
            &_left {
                text-align: start !important;
            }

            &_center {
                text-align: center !important;
            }

            &_right {
                text-align: end !important;
            }
        }
    }

    &__head-text,
    &__head-mobile-text {
        @mixin paragraph;

        font-weight: 700;
        color: var(--av-nav-primary);
    }

    &__head-desc,
    &__head-mobile-desc {
        color: var(--av-nav-primary);
    }

    &__head-title,
    &__head-mobile-title {
        @mixin hero-subtle;
    }

    &__head-label,
    &__head-mobile-label {
        @mixin body;

        color: var(--av-fixed-success);
        margin-bottom: 4px;
    }

    &__head-product,
    &__head-mobile-product {
        font-size: 24px;
        line-height: 24px;
        font-weight: bold;
    }

    &__row {
        display: flex;
        &_vertical {
            &_middle {
                .a-table__text {
                    display: flex;
                    align-items: center;
                    height: 100%;
                }
            }
            &_bottom {
                .a-table__text {
                    display: flex;
                    align-items: flex-end;
                    height: 100%;
                }
            }
        }
        &_with-indent {
            padding-top: 40px;
        }

        &.a-table__row_no-scroll {
            @media (max-width: 767px) {
                .a-table {
                    &__cell {
                        flex-basis: auto;
                        flex-shrink: 1;
                        flex-grow: 1;

                        &:first-child {
                            flex-basis: 60%;
                        }
                    }
                }
            }
        }

        &.a-table__row_multirow {
            @media (max-width: 767px) {
                flex-wrap: wrap;

                .a-table {
                    &__cell {
                        &:first-child {
                            flex-basis: 100%;
                        }
                    }
                }
            }
        }
    }

    &__body {
        display: block;
        overflow-x: scroll;
        scrollbar-width: none;
        margin: 0 -16px;
        padding: 0 calc(16px - 8px);

        &::-webkit-scrollbar {
            height: 0;
            background: rgba(255, 255, 255, 0);
        }

        @media (--viewport-mobile-wide) {
            margin: 0 -32px;
            padding: 0 calc(32px - 8px);
        }

        @media (--viewport-desktop-wide) {
            margin: 0 -64px;
            padding: 0 calc(64px - 8px);
        }

        @media (--viewport-desktop-large) {
            margin: 0 -8px;
            padding: 0;
        }
    }

    &__cell {
        display: block;
        position: relative;
        padding-inline-start: 8px;
        padding-inline-end: 8px;
        border-bottom: 1px solid var(--av-brand-secondary-light);
        overflow: hidden;

        &_align {
            &_left {
                text-align: start !important;

                &:deep(.cta-description) {
                    justify-content: start;
                }
            }

            &_center {
                text-align: center !important;

                .a-table__subtitle {
                    justify-content: center;
                }
            }

            &_right {
                text-align: end !important;

                &:deep(.cta-description) {
                    justify-content: end;
                }
            }
        }

        &_without_border {
            border: none;
        }

        &:deep(a:not(.a-button, .a-link)) {
            color: var(--av-brand-primary);
            text-decoration: none;

            del {
                color: var(--av-brand-primary);
            }

            &:hover {
                color: var(--av-brand-secondary);
            }
            &:focus {
                background: var(--av-brand-secondary-light);
                color: var(--av-brand-primary);
            }
            &:active {
                background: none;
                color: var(--av-brand-primary);
            }
        }
    }

    &__subtitle {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
    }

    &__tooltip {
        position: relative;
        top: 3px;
        margin-inline-start: 8px;
    }

    &__glyph {
        display: block;
        margin-bottom: 8px;
        width: 24px;
        height: 24px;
    }

    &__category {
        @mixin paragraph;

        vertical-align: middle;
        font-weight: 600;
        color: var(--av-nav-primary);

        &:deep(.cta-description) {
            font-weight: 600;
        }
    }

    &__description {
        margin-top: -8px;
        padding-inline-start: 48px;
        position: relative;

        &-icon {
            top: 0;
            inset-inline-start: 0;
            width: 32px;
            height: 32px;
            position: absolute;
        }

        &-title {
            @mixin paragraph-accent;

            margin-top: 8px;
            color: var(--av-nav-primary);
        }

        &-text {
            margin-top: 8px;
        }

        &-link {
            @mixin body-accent;

            margin-top: 8px;

            .a-link {
                &__glyph_position_right {
                    margin-inline-start: 8px;
                }
            }
        }

        &-note {
            @mixin body;

            margin-top: 8px;
            color: var(--av-fixed-light);
        }
    }

    &__description-full {
        padding: 0 !important;
    }

    &__text {
        color: var(--av-fixed-secondary);
    }

    &__list {
        list-style-type: none;
    }

    &__list-item {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 8px;
        }
    }

    &__check {
        min-width: 12px;
    }

    &__check-icon {
        position: absolute;
        top: 50%;
        inset-inline-start: 50%;
        transform: translate(-50%, -50%);

        &_true {
            width: 12px;
            height: 12px;
            border-radius: 100%;
            background: var(--av-fixed-success);
        }

        &_false {
            width: 16px;
            height: 2px;
            background-color: var(--av-brand-secondary-light);
        }
    }

    &__button-wrapper {
        padding: 16px 0;

        &:deep(.a-button) {
            width: 100%;
        }
    }

    .a-table-switcher {
        display: flex;
        align-items: center;
        cursor: pointer;

        &__input {
            position: absolute;
            visibility: hidden;

            &:checked ~ .a-table-switcher__decor {
                background-color: var(--av-fixed-success);

                &::after {
                    transform: translate(17px, -50%);
                }
            }
        }

        &__text {
            @mixin paragraph;

            display: inline-block;
            position: relative;
            margin-inline-end: 16px;
            color: var(--av-fixed-secondary);
        }

        &__decor {
            position: relative;
            flex: 0 0 37px;
            height: 22px;
            border-radius: 100px;
            background-color: var(--av-brand-light);
            transition: background-color 0.15s ease-out;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                inset-inline-start: 2px;
                width: 16px;
                border-radius: 100px;
                height: 16px;
                background-color: var(--av-inversed-primary);
                transition: transform 0.25s ease-out;
            }
        }
    }
    i,
    em {
        font-style: italic;
    }
    b {
        font-weight: bold;
    }
    strong {
        font-weight: 600;
    }

    &:deep(.cta) {
        font: var(--font-default);
    }

    &:deep(.cta-description) {
        margin: 0;
    }

    .a-table__subtitle {
        &:deep(.cta) {
            font-size: 18px;
            line-height: 32px;
        }
    }

    &__lead {
        @mixin paragraph;
        margin-top: 48px;
        margin-bottom: 40px;
        color: #384A63;

        @media (--viewport-tablet) {
            margin-bottom: 56px;
        }

        @media (--viewport-desktop) {
            max-width: 632px;
        }

        @media (--viewport-desktop-wide) {
            max-width: 760px;
        }

        @media (--viewport-desktop-large) {
            max-width: 870px;
        }
    }

    &_type-1 {
        .a-table__head-cell,
        .a-table__cell {
            border-bottom: 1px solid rgba(56, 74, 99, .2);

            &:first-child {
                box-shadow: 3px 0 2px 0 rgba(230, 231, 233, .5);

                @media (--viewport-tablet) {
                    box-shadow: none;
                }
            }

            &:not(:first-of-type) {
                padding: 24px 12px;
            }
        }

        .a-table__head-text {
            @mixin lead;
            font-weight: 600;
        }

        .a-table__text {
            color: #384A63
        }
    }

    &_lte-3-cols {
        .a-table__head-cell:first-child,
        .a-table__cell:first-child {
            flex-grow: 1;
        }
    }
}

.a-table_type-1 .syncscroll {
    overflow-x: unset;
}
</style>

<style lang="postcss">
.s-slice_background_dark-blue,
.s-slice_background_light-blue,
.s-slice_background_n-p-gradient-top,
.s-slice_background_l-b-gradient-top,
.s-slice_background_l-b-gradient-bottom {
    .a-table__head-row {
        background: transparent;
    }
}

.has-anchor-menu {
    .a-table_sticky {
        --table-sticky-offset: 52px;

        .a-table__head {
            top: var(--table-sticky-offset) !important;
        }
    }
}
</style>
