<template>
    <s-basic-slice class="s-features-with-img" :class="className" v-bind="$attrs">
        <div v-if="media" class="s-features-with-img__img-wrapper">
            <div class="s-features-with-img__img-container">
                <a-media class="s-features-with-img__media" v-bind="media" />
            </div>
        </div>
        <ul class="s-features-with-img__list">
            <li v-for="(card, i) in cards" :key="i" class="s-features-with-img__list-item">
                <a-feature-card
                    class="s-features-with-img__feature-card"
                    v-bind="card"
                    :size="'s'"
                    :title-tag="$attrs.title && 'h3'"
                    :direction="'row'"
                    icon-small
                />
            </li>
        </ul>
    </s-basic-slice>
</template>

<script>
import AFeatureCard from '@core/components/feature-card/feature-card.vue';
import AMedia from '@core/components/media/media.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SFeaturesWithImage',

    components: {
        SBasicSlice,
        AFeatureCard,
        AMedia,
    },

    inheritAttrs: false,

    props: {
        media: {
            type: Object,
            default: undefined,
        },

        cards: {
            type: Array,
            required: true,
        },
    },

    computed: {
        className() {
            return {
                's-features-with-img_layout_4-cards': this.cards.length === 4,
                's-features-with-img_layout_2-3-cards': this.cards.length < 4,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-features-with-img {
    overflow: hidden;

    &__img-wrapper {
        margin-bottom: 56px;
    }

    &__img-container {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    &__img, &__media {
        width: 100%;
    }

    &__list-item {
        &:not(:last-of-type) {
            margin-bottom: 56px;
        }
    }

    &__feature-card {
        &:deep(.a-feature-card__title) {
            @mixin paragraph;
        }

        &:deep(.a-feature-card__image-container) {
            margin-inline-end: 16px;
        }
    }

    @media (--viewport-mobile-wide) {
        &__img-wrapper {
            margin-bottom: 48px;
            display: flex;
            justify-content: center;
            padding-bottom: 60px;
            border-bottom: var(--av-border);
        }

        &__img-container {
            max-width: 66.66%;
            flex-grow: 1;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: -55px;
            margin-inline-end: -16px;
        }

        &__list-item {
            margin: 0 16px 56px 0;
            width: calc(50% - 16px);
            padding-inline-end: 16px;
        }
    }

    @media (--viewport-desktop) {
        &_layout {
            &_2-3-cards {
                &:deep(.a-container) {
                    display: flex;
                }

                .s-features-with-img {
                    &__img-wrapper {
                        margin-bottom: 0;
                        width: 52%;
                        padding-inline-end: 110px;
                        padding-bottom: 0;
                        border: none;
                    }

                    &__img-container {
                        max-width: none;
                    }

                    &__list {
                        display: block;
                        margin-bottom: -56px;
                        width: 48%;
                    }

                    &__list-item {
                        width: auto;

                        &:not(:last-of-type) {
                            margin-bottom: 56px;
                        }
                    }
                }
            }

            &_4-cards {
                .s-features-with-img {
                    &__img-wrapper {
                        margin-bottom: 64px;
                        padding-bottom: 74px;
                    }

                    &__img-container {
                        max-width: 66%;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop-wide) {
        &__img-wrapper {
            width: 61%;
            padding-inline-end: 136px;
        }

        &__list {
            margin-inline-end: 0;
            width: 39%;
        }

        &_layout {
            &_2-3-cards {
                &:deep(.a-container) {
                    display: flex;
                }

                .s-features-with-img {
                    &__img-wrapper {
                        width: 61%;
                        padding-inline-end: 136px;
                        border: none;
                    }

                    &__img-container {
                        max-width: none;
                    }

                    &__list {
                        display: block;
                        margin-bottom: -56px;
                        width: 39%;
                    }

                    &__list-item {
                        margin: 0;
                        width: auto;

                        &:not(:last-of-type) {
                            margin-bottom: 56px;
                        }
                    }
                }
            }

            &_4-cards {
                &:deep(.a-container) {
                    display: flex;
                }

                .s-features-with-img {
                    &__img-wrapper {
                        width: 61%;
                        padding-inline-end: 136px;
                        border: none;
                    }

                    &__img-container {
                        max-width: none;
                    }

                    &__list {
                        display: block;
                        margin-bottom: 0;
                        width: 39%;
                    }

                    &__list-item {
                        margin: 0;
                        width: auto;

                        &:not(:last-of-type) {
                            margin-bottom: 56px;
                        }
                    }
                }
            }
        }
    }

    @media (--viewport-desktop-large) {
        &_layout {
            &_2-3-cards {
                .s-features-with-img {
                    &__img-wrapper {
                        width: 61.5%;
                        padding-inline-end: 157px;
                    }

                    &__list {
                        width: 38.5%;
                    }
                }
            }

            &_4-cards {
                .s-features-with-img {
                    &__img-wrapper {
                        width: 61.5%;
                        padding-inline-end: 157px;
                    }

                    &__list {
                        width: 38.5%;
                    }
                }
            }
        }
    }
}
</style>
