<template>
    <div class="blog-dropdown-menu">
        <el-select
            v-model="activeLink"
            class="menu"
            popper-class="blog-dropdown menu-dropdown"
            :hide-on-resize="isDesktop"
            :placeholder="placeholder"
            @change="mobileMenuClick"
        >
            <el-option
                v-for="(item, i) in links"
                :key="i"
                :label="item.title"
                :value="item"
            />
        </el-select>
    </div>
</template>

<script>
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'BlogDropdownMenu',
    components: {
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
    },
    mixins: [breakpoint],
    props: {
        links: {
            type: Array,
            required: true,
        },

        placeholder: {
            type: String,
            default: '',
        },

        activeLinkId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            activeLink: null,
        };
    },
    mounted() {
        if (this.activeLinkId) {
            this.activeLink = this.links.find((link) => link.id === this.activeLinkId);
        }
    },
    methods: {
        mobileMenuClick(item) {
            if (item) {
                document.location.href = item.link;
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-dropdown-menu {
    padding-bottom: 40px;
    max-width: 767px;

    .menu {
        display: block;
        border: 1px solid var(--av-brand-light);
        background: var(--av-inversed-primary);
        color: var(--av-fixed-secondary);
        border-radius: 4px;
        height: 46px;
    }
}
</style>

<style lang="postcss">
.blog-dropdown-menu {
    .menu {
        .el-input {
            height: 100%;

            &__container {
                height: 100%;
                padding: 0;
                padding-inline-end: 9px;
                padding-inline-start: 7px;
            }

            &__icon {
                color: var(--av-brand-primary) !important;
            }

            &__placeholder {
                @mixin body;

                color: var(--av-fixed-light);
            }

            &__editor--absolute-position {
                @mixin body;
                color: var(--av-nav-primary);
            }

            &__label.is-active {
                display: none;
            }
        }

        .el-input.is-active:not(.el-input--small).el-input--has-label .el-input__wrapper {
            padding-top: 11px;
        }
    }
}
</style>
