<template>
    <s-basic-slice class="s-feature-group" v-bind="$attrs">
        <div class="s-feature-group__content">
            <div class="s-feature-group__title">
                <div :class="classContent" :style="styleContent">
                    <p v-if="description" class="s-feature-group__description">
                        {{ description }}
                    </p>
                    <h3 class="s-feature-group__subtitle">
                        {{ subtitle }}
                    </h3>
                    <div v-if="labelFlag" class="s-feature-group__label-flag">
                        {{ labelFlag }}
                    </div>
                    <div v-if="text" class="s-feature-group__text">
                        {{ text }}
                    </div>
                    <a-link
                        v-if="link && isDesktop"
                        class="s-feature-group__link s-feature-group__link-desktop"
                        v-bind="link"
                        :type="link.type || 'direct'"
                        :glyph="link.glyph || 'arrow'"
                    />
                </div>
            </div>
            <ul class="s-feature-group__list">
                <li v-for="(card, i) in cards" :key="i" class="s-feature-group__list-item">
                    <a-feature-card
                        class="s-feature-group__feature-card"
                        v-bind="card"
                        size="xs"
                        icon-small
                    />
                </li>
            </ul>
        </div>
        <a-link
            v-if="link && (isMobile || isTablet)"
            class="s-feature-group__link"
            v-bind="link"
            :type="link.type || 'direct'"
            :glyph="link.glyph || 'arrow'"
        />
    </s-basic-slice>
</template>

<script>
import AFeatureCard from '@core/components/feature-card/feature-card.vue';
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SFeatureGroup',

    components: {
        ALink,
        SBasicSlice,
        AFeatureCard,
    },

    mixins: [breakpoint],

    inheritAttrs: false,

    props: {
        description: {
            type: String,
            default: undefined,
        },

        subtitle: {
            type: String,
            required: true,
        },

        labelFlag: {
            type: String,
            default: undefined,
        },

        text: {
            type: String,
            default: undefined,
        },

        link: {
            type: Object,
            default: undefined,
        },

        cards: {
            type: Array,
            required: true,
            validator: (cards) => cards.length > 1,
        },

        /**
         * Sticky for left part
         */
        isSticky: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            styleContent: {},
            classContent: {
                's-feature-group__title-sticky': this.isSticky,
            },
        };
    },

    mounted() {
        window.addEventListener('load', () => {
            const subMenu = document.getElementsByClassName('a-submenu');
            const anchorMenu = document.getElementsByClassName('s-anchor-menu');
            const subMenuProduct = document.getElementsByClassName('s-sub-menu-product');
            let topOffsetSticky = 64 + (anchorMenu.length ? anchorMenu[0].offsetHeight : 0);
            if (subMenu.length) {
                if (subMenuProduct.length) {
                    topOffsetSticky += subMenuProduct[0].offsetHeight;
                } else {
                    topOffsetSticky += subMenu[0].offsetHeight;
                }
            }
            this.styleContent = this.isSticky ? { top: `${topOffsetSticky}px` } : {};
        });
    },
};
</script>

<style lang="postcss" scoped>
.s-feature-group {
    &__title {
        margin-bottom: 40px;
    }

    &__description {
        margin-bottom: 8px;
        color: var(--av-fixed-light);
    }

    &__subtitle {
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        color: var(--av-nav-primary);
    }

    &__label-flag {
        margin-bottom: 24px;
        font-weight: 600;
        color: var(--av-fixed-success);
    }

    &__list {
        margin-bottom: -56px;
    }

    &__list-item {
        margin-bottom: 56px;
    }

    &__feature-card {
        padding-inline-end: 16px;
    }

    &__text {
        font-size: 18px;
        line-height: 24px;
    }

    &__link {
        margin-top: 56px;
    }

    &__title-sticky {
        top: 64px;
        position: sticky;
    }

    @media (--viewport-mobile-wide) {
        &__subtitle {
            font-size: 24px;
            line-height: 32px;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 -56px;
            align-items: flex-start;
            justify-content: space-between;
        }

        &__list-item {
            width: calc(50% - 8px);
        }
    }

    @media (--viewport-desktop) {
        .s-slice__header {
            margin-bottom: 64px;
        }

        &__content {
            display: flex;
        }

        &__title {
            margin-inline-end: 16px;
            width: calc((100% - 16px * 2) / 3);
            flex-shrink: 0;
            padding-inline-end: 16px;
        }
    }
}
</style>
