<template>
    <section :id="id" class="s-slice s-header-split" :class="{ ...basicClassName }">
        <div class="a-container">
            <div class="s-header-split__leftside">
                <h1 class="a-slice-header__title s-header-split__title">
                    {{ title }}
                </h1>
                <div class="s-header-split__text">
                    {{ text }}
                </div>
            </div>
            <div class="s-header-split__img-container">
                <a-picture class="s-header-split__img" :link="imagePath" :alt="imageAlt" />
            </div>
        </div>
    </section>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SHeaderSplit',

    components: {
        APicture,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        text: {
            type: String,
            required: true,
        },

        imagePath: {
            type: String,
            required: true,
        },

        imageAlt: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-header-split {
    background-color: var(--av-nav-primary);
    overflow: hidden;

    @media (--viewport-mobile-wide) {
        padding: 56px 0 64px;
    }

    @media (--viewport-desktop) {
        padding: 72px 0 80px;
    }

    .a-container {
        @media (--viewport-mobile-wide) {
            display: flex;
        }
    }

    &__title {
        margin-bottom: 64px;
        font-size: 38px;
        font-weight: 300;
        line-height: 48px;
        color: var(--av-inversed-primary);

        @media (--viewport-mobile-wide) {
            margin-top: 16px;
            width: 100%;
        }

        @media (--viewport-desktop) {
            font-size: 48px;
            line-height: 56px;
        }
    }

    &__text {
        color: var(--av-inversed-primary);
    }

    &__leftside {
        margin-bottom: 120px;
        position: relative;

        @media (--viewport-mobile-wide) {
            width: 41.66%;
            padding-inline-end: 16px;
        }

        @media (--viewport-desktop-wide) {
            width: 33.33%;
        }
    }

    &__img-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (--viewport-mobile-wide) {
            width: 58.33%;
        }

        @media (--viewport-desktop-wide) {
            width: 66.66%;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: -88px;
            inset-inline-start: -16px;
            inset-inline-end: -16px;
            z-index: -1;
            width: 100vw;
            height: 0;
            padding-top: 180%;
            background: radial-gradient(
                50% 50% at 50% 50%,
                var(--av-nav-primary) 0%,
                rgba(var(--av-nav-primary), 0.4296) 49.48%,
                rgba(var(--av-nav-primary), 0) 90.4%
            ) 50% 50% / contain;
            user-select: none;

            @media (--viewport-mobile-wide) {
                inset-inline-start: auto;
                inset-inline-end: -32px;
                bottom: -53px;
                width: 154%;
                padding-top: 140%;
            }

            @media (--viewport-desktop) {
                inset-inline-end: -80px;
                bottom: -80px;
                width: 189%;
                padding-top: 104%;
            }

            @media (--viewport-desktop-wide) {
                inset-inline-end: -56px;
                bottom: -66px;
                width: 107%;
                padding-top: 68%;
            }

            @media (--viewport-desktop-large) {
                inset-inline-end: -32px;
                bottom: -72px;
                width: 105%;
                padding-top: 60%;
            }
        }
    }

    &__img {
        width: 100%;
        position: relative;
    }
}
</style>
