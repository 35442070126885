<template>
    <div class="a-service-card" :class="{ [`a-service-card_size_large`]: large }">
        <div class="a-service-card__header">
            <div class="a-service-card__title">
                {{ title }}
            </div>
            <div class="a-service-card__description">
                {{ description }}
            </div>
        </div>
        <div class="a-service-card__footer">
            <template v-if="links.first">
                <a-link
                    v-if="!large"
                    class="a-service-card__button"
                    v-bind="links.first"
                    :size="links.first?.size || 'paragraph'"
                />
                <a-button
                    v-else
                    class="a-service-card__button"
                    v-bind="links.first"
                    :size="links.first?.size || 's'"
                />
            </template>
            <a-button
                class="a-service-card__button"
                v-bind="links.last"
                :size="links.last?.size || 's'"
                :type="type || 'action'"
                :class="{ [`a-service-card__button_filled`]: !large }"
            />
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import { TYPES } from './constants.js';

export default {
    name: 'AServiceCard',

    components: {
        AButton,
        ALink,
    },

    props: {
        /**
         * Card type: default state / promo state
         */
        type: {
            type: String,
            default: undefined,
            validator: (value) => value === undefined || TYPES.includes(value),
        },

        title: {
            type: String,
            required: true,
        },

        large: {
            type: Boolean,
            required: false,
        },

        description: {
            type: String,
            required: true,
        },

        links: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="postcss">
.a-service-card {
    border-radius: 4px;
    box-shadow: 0 5px 6px 0 rgba(138, 173, 207, 0.19);
    border: 1px solid var(--av-fixed-lightest);
    background-color: var(--av-inversed-primary);
    position: relative;
    padding: 24px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90.5%;
    @media (--viewport-mobile-wide) {
        flex-wrap: nowrap;
    }

    &_size_large {
        padding: 24px;
        @media (--viewport-mobile-wide) {
            flex-wrap: wrap;
        }

        .a-service-card__header {
            text-align: start;
        }
        .a-service-card__title {
            @mixin title;
            margin-bottom: 8px;

            @media (--viewport-desktop) {
                @mixin display;
            }
        }

        .a-service-card__description {
            @mixin body;
        }

        .a-service-card__footer {
            display: flex;
            width: 100%;
            margin-top: 24px;
            margin-bottom: 0;
            grid-gap: 16px;
            @media (--viewport-mobile-wide) {
                flex-direction: column;
            }

            & > * {
                width: 100%;
                margin: 0 !important;
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        text-align: center;
    }

    &__title {
        @mixin paragraph-accent;

        color: var(--av-nav-primary);
    }

    &__description {
        @mixin caption;

        color: var(--av-fixed-light);
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin: 24px 0 -16px;
    }

    &__button {
        margin: 0 8px 16px;
        white-space: nowrap;
        text-align: center;
        @media (--viewport-desktop-wide) {
            &_filled {
                min-width: 180px;
            }
        }
    }

    @media (--viewport-mobile-wide) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 24px;

        &__button {
            margin: 0;
            white-space: nowrap;
            text-align: center;
        }

        &__header {
            text-align: start;
        }

        &__footer {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            margin: 0;

            :nth-child(1) {
                margin-inline-end: 24px;
            }
            :last-child {
                margin-inline-end: 0;
            }

            :nth-child(2) {
                span {
                    margin: 0 16px;
                }
            }
        }
    }
}
</style>
