<template>
    <div class="blog-load-more">
        <div v-if="!isLoading" class="button-wrap">
            <a-button
                class="button"
                type="main"
                :text="buttonText"
                @click="onClick"
            />
        </div>
        <div
            v-if="isLoading"
            v-loading="true"
            class="progress"
            el-loading-size="48"
        />
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import AButton from '@core/components/button/button.vue';

export default {
    name: 'BlogLoadMore',

    components: {
        AButton,
        // ElLoading: () => import('@uikit/ui-kit/packages/loading'),
    },

    directives: {
        Loading,
    },

    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },

        buttonText: {
            type: String,
            required: true,
        },
    },

    emits: ['click'],

    methods: {
        onClick() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="postcss" scoped>
.button-wrap {
    height: 48px;
    text-align: center;
}

.button {
    @mixin body-accent;

    background: var(--av-brand-primary);
    padding: 12px 29px;
}

.progress {
    height: 48px;
}
</style>
