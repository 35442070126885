class Sticky {
    constructor(el, options) {
        this.el = el;
        this.options = options;
        this.className = this.options.activeClass || 'is-sticky';
        this.pageOffsetTop = 0;

        this.el.classList.add('directive-v-sticky');

        const page = document.querySelector('.page');

        if (page) {
            this.pageOffsetTop = page.offsetTop;
        }

        this.parent = this.el.closest(options.parent);

        this.isSticky = false;

        this.sticky = this.sticky.bind(this);

        this.init();
    }

    init() {
        window.addEventListener('load', this.sticky);
        window.addEventListener('scroll', this.sticky, {
            capture: true,
            passive: true,
        });
        window.addEventListener('resize', this.sticky, {
            capture: true,
            passive: true,
        });
    }

    sticky() {
        const ribbonHeight = document.querySelector('.locale-selector-ribbon')?.offsetHeight || 0;

        const mainHeaderHeight = document.querySelector('.s-main-header')?.offsetHeight || 0;

        let treshold = this.parent.offsetTop + this.pageOffsetTop + this.parent.offsetHeight - this.el.offsetHeight;

        treshold = this.options.stickyHeader ? treshold - mainHeaderHeight : treshold + ribbonHeight;

        this.isSticky = window.pageYOffset > treshold;

        if (this.isSticky) {
            this.el.classList.add(this.className);
        } else {
            this.el.classList.remove(this.className);
        }
    }

    destroy() {
        window.removeEventListener('load', this.sticky);
        window.removeEventListener('scroll', this.sticky, {
            capture: true,
            passive: true,
        });
        window.removeEventListener('resize', this.sticky, {
            capture: true,
            passive: true,
        });
    }
}

export default {
    inserted(el, { value }) {
        el.sticky = new Sticky(el, value);
    },

    unbind(el) {
        if (el.sticky) {
            el.sticky.destroy();
            el.sticky = null;
        }
    },
};
