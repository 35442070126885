<template>
    <div class="resource-search-sort-dropdown">
        <a-link
            :class="{ 'mirrored': showSortDropdown }"
            :text="value.label"
            glyph="arrow-narrow-up"
            :glyph-size="isDesktop ? 'xs' : undefined"
            glyph-position="right"
            :has-no-ref="true"
            @click="toggleSortDropdown"
        />
        <div
            class="dropdown-options"
            :class="{
                'dropdown-options--visible': showSortDropdown,
                'dropdown-options--hidden': !showSortDropdown,
            }"
        >
            <div v-for="item in items" :key="item.value" @click="selectSortValue(item)">
                {{ item.label }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'ResourceSearchSortDropdown',

    components: {
        ALink,
    },

    mixins: [breakpoint],

    props: {
        value: {
            type: Object,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
    },

    emits: ['input'],

    data: () => ({
        showSortDropdown: false,
    }),

    methods: {
        toggleSortDropdown() {
            this.showSortDropdown = !this.showSortDropdown;
        },

        selectSortValue(item) {
            this.showSortDropdown = false;
            this.$emit('input', item);
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-search-sort-dropdown {
    position: relative;

    &:deep(.a-link__content) {
        @mixin paragraph;
    }

    &:deep(.a-glyph) {
        width: 10px;
        height: 5px;
        margin-bottom: 10px;

        @media (--viewport-desktop) {
            margin-bottom: 8px;
        }
    }

    @media (--viewport-desktop) {
        top: 10px;
        inset-inline-end: 0;

        &:deep(.a-link__content) {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
        }
    }
}

.dropdown-options {
    position: absolute;
    height: 0;
    overflow: hidden;
    inset-inline-end: 0;
    bottom: -8px;
    transform: translateY(100%);
    min-width: max-content;
    display: flex;
    flex-direction: column;
    background: var(--av-inversed-primary);
    box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
    border-radius: 4px;
    transition: all 0.15s linear;

    &--hidden {
        border: 0;
    }

    &--visible {
        height: 66px;
        border: 1px solid var(--av-brand-primary);
    }
}

.dropdown-options div {
    @mixin body;
    color: var(--av-fixed-secondary);
    padding: 4px 16px;
    cursor: pointer;

    &:hover {
        background: var(--av-brand-secondary-accent);
    }
}

:deep(.a-link__content) {
    background: transparent !important;
}

:deep(.a-link *) {
    user-select: none;
}

:deep(.a-glyph) {
    transition: transform 0.15s ease;
}

:deep(.a-glyph) {
    @media (--viewport-desktop) {
        transform: translateY(2px);
    }
}

:deep(.mirrored .a-glyph) {
    transform: rotate(-180deg);

    @media (--viewport-desktop) {
        transform: rotate(-180deg) translateY(-2px);
    }
}
</style>
