<template>
    <div class="a-feature-card" :class="{ [`a-feature-card_size_${size}`]: size, [`a-feature-card_layout_${direction}`]: direction }">
        <div v-if="icon" class="a-feature-card__image-container">
            <a-icon
                class="a-feature-card__image"
                :class="{ 'a-feature-card__image_s': iconSmall }"
                :number="icon"
                :icon-alt="iconAlt || title"
                :icon-variant="iconVariant"
            />
        </div>
        <div class="a-feature-card__desc">
            <a-label
                v-if="label"
                size="s"
                variant="success"
                class="a-feature-card__label"
                :class="{ 'a-feature-card__label_with-image': icon, 'a-feature-card__label_with-image_small': icon && iconSmall }"
            >
                {{ label }}
            </a-label>
            <a-link
                v-if="to && title"
                class="a-feature-card__title a-feature-card__link"
                :to="to"
                :text="title"
                :target="target"
            />
            <component :is="titleTagName" v-if="!to && title" class="a-feature-card__title">
                {{ title }}
            </component>
            <a-dangerous-html v-if="content" class="a-feature-card__text" :content="content" />
            <div v-if="tags.length" class="a-feature-card__tags">
                <div v-for="(item, index) in tags" :key="index" class="a-feature-card__tag-item">
                    <a-label class="a-feature-card__tag-label" variant="info" size="s">
                        {{ item }}
                    </a-label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AIcon from '@core/components/icon/icon.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import { SIZES, DIRECTIONS } from './constants.js';

export default {
    name: 'AFeatureCard',

    components: {
        ALink,
        ALabel,
        AIcon,
        ADangerousHtml,
    },

    props: {
        /**
         * Title
         */
        title: {
            type: String,
            default: undefined,
        },

        /**
         * Title Tag
         */
        titleTag: {
            type: String,
            default: undefined,
        },

        /**
         * Title size
         */
        size: {
            type: String,
            default: 'm',
            validator: (value) => value === undefined || SIZES.includes(value),
        },

        /**
         * Image URL
         */
        icon: {
            type: [String, Number],
            default: '',
        },

        /**
         * Icon small
         */
        iconSmall: {
            type: Boolean,
            default: false,
        },

        /**
         * Icon variant
         */
        iconVariant: {
            type: Boolean,
            default: false,
        },

        /**
         * Image alt
         */
        iconAlt: {
            type: String,
            default: '',
        },

        /**
         * Label text
         */
        label: {
            type: String,
            default: '',
        },

        /**
         * Icon content
         */
        content: {
            type: String,
            default: '',
        },

        /**
         * card layout direction
         */
        direction: {
            type: String,
            default: 'column',
            validator: (value) => value === undefined || DIRECTIONS.includes(value),
        },

        /**
         * card link to
         */
        to: {
            type: String,
            default: undefined,
        },

        /**
         * card link target
         */
        target: {
            type: String,
            default: undefined,
        },

        /**
         * tags
         */
        tags: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            titleTagName: this.titleTag || 'div',
        };
    },
};
</script>

<style lang="postcss" scoped>

.a-feature-card {
    position: relative;
    display: flex;
    align-items: flex-start;

    &__desc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    &__label {
        position: absolute;
        inset-inline-start: 0;
        bottom: 100%;

        &_with-image {
            top: 96px;
            bottom: auto;
        }

        &_with-image_small {
            top: 48px;
        }
    }

    &__image-container {
        margin-bottom: 16px;
    }

    &__image {
        width: 96px;
        height: 96px;

        &_s {
            width: 48px;
            height: 48px;
        }
    }

    &__title {
        width: 100%;
        font-weight: 600;
        color: var(--av-nav-primary);
        &:deep(.a-link__content) {
            width: 100%;
            font-weight: 600;
        }
    }

    &__link {
        color: var(--av-brand-secondary);
    }

    &__text {
        max-width: 100%;
        margin-top: 8px;
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0 -8px;
    }

    &__tag-item {
        margin-inline-end: 8px;
        margin-bottom: 8px;
    }

    &__tag-label {
        display: block;
    }

    &_size {
        &_m {
            .a-feature-card__title {
                @mixin title;
                &:deep(.a-link__content) {
                    @mixin title;
                }
            }
        }

        &_s {
            .a-feature-card__title {
                @mixin lead;
                &:deep(.a-link__content) {
                    @mixin lead;
                }
            }
        }

        &_xs {
            .a-feature-card__title {
                @mixin paragraph;
                &:deep(.a-link__content) {
                    @mixin paragraph;
                }
            }
        }
    }

    &_layout {
        &_row {
            flex-direction: row;

            .a-feature-card__label {
                position: static;
            }

            .a-feature-card__image-container {
                margin-inline-end: 16px;
                flex-shrink: 0;

                @media (--viewport-desktop) {
                    margin-inline-end: 28px;
                }

                @media (--viewport-desktop-wide) {
                    margin-inline-end: 36px;
                }

                @media (--viewport-desktop-large) {
                    margin-inline-end: 41px;
                }
            }
        }

        &_column {
            flex-direction: column;
        }
    }
}
</style>
