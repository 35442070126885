<template>
    <div class="blog-social-bar">
        <div v-if="title" class="title">
            {{ title }}
        </div>
        <a-social class="links" :social="links" />
    </div>
</template>

<script>
import ASocial from '@core/components/social/social.vue';

export default {
    name: 'BlogSocialBar',

    components: {
        ASocial,
    },

    props: {
        title: {
            type: String,
            required: false,
            default: undefined,
        },

        links: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-social-bar {
    @mixin body-accent;

    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 40px;

    @media (--viewport-tablet) {
        text-align: start;
        justify-content: flex-end;
        margin-top: 0;
    }
}

.title {
    margin-inline-end: 8px;
}
</style>

<style lang="postcss">
.blog-social-bar .a-social {
    &__link {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__icon {
        height: 16px;
        width: 16px;
        fill: var(--av-brand-secondary);
    }
}
</style>
