<template>
    <div class="speakers s-slice" :class="{ ...className, ...basicClassName }">
        <div class="a-container">
            <a-slice-header :title="title" :lead="lead" />
            <div class="speakers-list">
                <a-speaker
                    v-for="(speaker, i) in speakersToShow"
                    :key="i"
                    v-bind="speaker"
                    :layout="layout"
                    :modal-id="`speaker${i}`"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import ASpeaker from './components/speaker.vue';

export default {
    name: 'SSpeakers',

    components: {
        ASpeaker,
        ASliceHeader,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        title: {
            type: String,
            default: null,
        },

        lead: {
            type: String,
            default: null,
        },

        speakers: {
            type: Array,
            required: true,
        },

        readMoreTitle: {
            type: String,
            required: false,
            default: 'Read more',
        },

        limit: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        layout() {
            if (this.speakers.length === 1) {
                return 'full';
            }
            if (this.speakers.length === 2) {
                return 'medium';
            }
            return 'short';
        },

        speakersToShow() {
            return this.limit > 0 ? [].concat(this.speakers).splice(0, this.limit) : this.speakers;
        },

        className() {
            return {
                [`layout-${this.layout}`]: true,
            };
        },
    },

    methods: {
        clampStateChange(i, isClamped) {
            this.isTextClampedForSpeaker[i] = isClamped;
        },
    },
};
</script>

<style lang="postcss" scoped>
.speakers {
    &.layout-short {
        .speakers-list {
            grid-template-columns: repeat(2, minmax(0, 1fr));

            @media (--viewport-tablet) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            @media (--viewport-desktop) {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
        }
    }

    &.layout-medium {
        .speakers-list {
            @media (--viewport-desktop) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }
    }

    .speakers-list {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 16px;
        column-gap: 16px;
        margin-bottom: 32px;
    }
}
</style>
