const devices = ['default', 'mobile', 'tablet', 'desktop'];
const fitTypes = ['cover', 'contain', 'fill', 'none', 'scale-down'];

function validateString(fit) {
    if (!fitTypes.includes(fit)) {
        throw new Error(`Available fit types: "${fitTypes.join('m ')}"\nInput: "${fit}"`);
    }

    return true;
}

function validateObject(fit) {
    const invalidDevices = Object.keys(fit).filter((key) => !devices.includes(key));
    if (invalidDevices.length) {
        throw new Error(`Available device types: "${devices.join(', ')}\nInput: "${invalidDevices.join(', ')}"`);
    }

    const invalidFits = Object.values(fit).filter((val) => !fitTypes.includes(val));
    if (invalidFits.length) {
        throw new Error(`Available fit types: "${fitTypes.join(', ')}"\nInput: "${invalidFits.join(', ')}"`);
    }

    return true;
}

export default (fit) => {
    if (typeof fit === 'string') {
        return validateString(fit);
    }

    if (typeof fit === 'object') {
        return validateObject(fit);
    }

    throw new Error('Fit can only be of type: "string, object"');
};
