<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { LOCALE_DEFAULT } from '@model/const/locales';
import Error404Component from './component.vue';

export default Vue.extend({
    name: 'Error404Container',

    async serverPrefetch(): Promise<void> {
        await this.$store.dispatch('slices/getSyncedData', { slice: 's-academy-acronis-com', locale: LOCALE_DEFAULT });

        this.$ssrContext.public.isNotFoundTrap = true;
        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapState({
            page: (state: any) => state.slices.items['s-academy-acronis-com'],
        }),
    },

    methods: {
        getMeta(): any {
            const title = this.page?.template?.['404']?.meta?.title || 'Acronis Academy';
            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'robots', content: 'noindex, nofollow' },
                ],
                htmlAttrs: {
                    dir: 'ltr',
                    lang: 'en',
                },
            };
        },
    },

    render(h) {
        return h(Error404Component);
    },
});
</script>
