<template>
    <!-- eslint-disable max-len -->
    <div class="s-unsupported-browser">
        <div class="s-unsupported-browser-header">
            <dynamic-link class="s-unsupported-browser-header__logo" to="/">
                <svg viewBox="0 0 158 32" width="127" height="40">
                    <path d="M0 31.536h4.823l2.319-5.75h17.113l2.273 5.75h4.823L18.18 0H13.17L0 31.536zm11.687-17.02l.788-2.04c1.345-3.34 2.505-6.122 3.2-8.21.742 2.088 1.902 4.87 3.247 8.21l.834 2.04h-8.069zM44.243 32c5.983 0 10.713-3.293 12.198-9.229h-4.777c-1.02 3.246-3.664 5.055-7.235 5.055-4.916 0-7.977-3.478-7.977-8.533 0-4.823 2.876-8.534 7.93-8.534 3.618 0 6.169 1.67 7.235 5.427h4.777c-1.066-6.076-5.75-9.6-11.919-9.6-7.188 0-12.753 5.147-12.753 12.707C31.722 26.62 36.545 32 44.243 32zm15.769-.464h4.637v-12.66c0-4.36 1.345-7.143 6.215-7.143h.695V6.586h-.417c-2.875 0-5.148 1.39-6.678 3.57l-.186-3.107h-4.266v24.487zM85.148 32c7.42 0 12.846-5.38 12.846-12.707 0-7.235-5.333-12.707-12.846-12.707-7.235 0-12.846 5.194-12.846 12.707C72.302 26.62 77.727 32 85.148 32zm16.278-.464h4.638V17.438c0-3.989 2.551-6.632 6.678-6.632 3.803 0 5.38 2.18 5.38 6.168v14.562h4.637V16.093c0-6.076-3.246-9.507-9.043-9.507-3.339 0-5.936 1.02-7.838 3.014l-.324-2.55h-4.128v24.486zm46.562.464c2.969 0 5.287-.556 7.05-1.994 1.669-1.345 2.55-3.386 2.55-5.704 0-2.18-.973-4.314-3.014-5.427-1.531-.834-3.2-1.16-5.009-1.437-1.901-.279-4.127-.51-5.333-.974-1.206-.464-1.855-1.16-1.855-2.505 0-2.086 1.809-3.617 4.869-3.617 2.876 0 5.102 1.391 5.38 4.22h4.406c-.278-5.333-4.081-7.976-9.786-7.976-5.24 0-9.321 2.736-9.321 7.93 0 2.226.881 4.22 3.107 5.287 1.438.696 3.107 1.067 5.519 1.438 2.319.37 3.478.556 4.591.974 1.159.417 1.901 1.066 1.901 2.643 0 2.272-2.179 3.386-5.055 3.386-3.478 0-5.843-1.3-6.307-4.267h-4.498c.463 5.797 4.823 8.023 10.805 8.023zm-20.173-.464h4.591V7.05h-4.591v24.487zm-42.62-3.71c-4.731 0-8.117-3.478-8.117-8.58 0-4.776 3.015-8.487 8.07-8.487 4.962 0 8.116 3.664 8.116 8.487 0 4.916-3.293 8.58-8.07 8.58zM8.718 21.843l1.484-3.617H21.24l1.437 3.617H8.718z" />
                </svg>
            </dynamic-link>
        </div>
        <div class="s-unsupported-browser-container">
            <div class="s-unsupported-browser-wrap">
                <div class="s-unsupported-browser-main">
                    <div class="s-unsupported-browser-main__icon">
                        <svg
                            class="a-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="96"
                            height="96"
                            viewBox="0 0 48 48"
                        >
                            <path opacity=".45" d="M24 4l20 34H4L24 4zm0 33a2 2 0 100-4 2 2 0 000 4zm2-19h-4l.67 13h2.66L26 18z" fill="url(#acr_0003_S_paint0_linear)" />
                            <path opacity=".45" d="M43 42L24 10 5 42h38zm-19-5a2 2 0 100-4 2 2 0 000 4zm2-19h-4l.67 13h2.66L26 18z" fill="url(#acr_0003_S_paint1_linear)" />
                            <path opacity=".45" d="M7.375 38L24 10l16.625 28H7.375zM24 37a2 2 0 100-4 2 2 0 000 4zm2-19h-4l.67 13h2.66L26 18z" fill="url(#acr_0003_S_paint2_linear)" />
                            <linearGradient
                                id="acr_0003_S_paint0_linear"
                                x1="6.285"
                                y1="-7.556"
                                x2="-14.942"
                                y2="33.652"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#70B1FF" />
                                <stop offset="1" stop-color="#005CCD" />
                            </linearGradient>
                            <linearGradient
                                id="acr_0003_S_paint1_linear"
                                x1="7.171"
                                y1="-.876"
                                x2="-12.699"
                                y2="38.059"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#70B1FF" />
                                <stop offset="1" stop-color="#005CCD" />
                            </linearGradient>
                            <linearGradient
                                id="acr_0003_S_paint2_linear"
                                x1="19.021"
                                y1="10.33"
                                x2=".263"
                                y2="31.78"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#86B5FA" />
                                <stop offset="1" stop-color="#699BE7" />
                            </linearGradient>
                        </svg>
                    </div>
                    <p class="s-unsupported-browser-main__title" v-html="getTitle" />
                    <p v-if="data" class="s-unsupported-browser-main__text">
                        <span v-for="item in data" :key="item.id">
                            <span v-if="item.text">{{ item.text }}</span>
                            <a-link v-if="item.link" v-bind="item.link" :size="item.link.size || 'paragraph'" />
                        </span>
                    </p>
                    <p v-else class="s-unsupported-browser-main__description">
                        <span v-html="getDescription" />
                    </p>
                </div>
                <div class="s-unsupported-browser-footer">
                    <div class="s-unsupported-browser-footer__company" v-html="getCompany" />
                    <a-link v-bind="getLegal" size="paragraph" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALink from '@core/components/link/link.vue';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';

export default {
    name: 'SUnsupportedBrowser',

    components: {
        ALink,
        DynamicLink,
    },

    props: {
        title: {
            type: String,
            default: '',
        },

        data: {
            type: [Array, Boolean],
            default: false,
        },

        company: {
            type: String,
            default: '',
        },

        legal: {
            type: [Object, Boolean],
            default: false,
        },
    },

    computed: {
        locale() {
            return this.$route?.params.locale;
        },

        getTitle() {
            const text = {
                'en-us': 'Sorry, your browser is not supported.',
                'de-de': 'Es tut uns leid, aber Ihr Browser wird nicht unterstützt.',
                'it-it': 'Il browser non è supportato.',
                'fr-fr': "Désolé, votre navigateur n'est pas pris en charge.",
                'es-es': 'Lo sentimos, su navegador no es compatible.',
                'zh-tw': '抱歉，您的瀏覽器不受支援。',
                'zh-cn': '抱歉，您的浏览器不受支持。',
                'ko-kr': '죄송합니다. 브라우저가 지원되지 않습니다.',
                'ja-jp': '申し訳ありません。ご利用のブラウザはサポートされていません。',
            };

            return this.title || this.getTranslation(text);
        },

        getDescription() {
            /* eslint-disable max-len */
            const text = {
                'en-us': [
                    "It seems that our new website is incompatible with your current browser's version.",
                    'Don’t worry, this is easily fixed!',
                    'To view our complete website, simply <a href="https://browsehappy.com/?locale=en" target="_blank" class="a-link">update</a>',
                    'your browser now or <a href="#" class="a-link" id="unsupported-continue">continue anyway.</a>',
                ],
                'de-de': [
                    'Es scheint, dass unsere neue Website mit der derzeitigen Version Ihres Browsers nicht kompatibel ist. Das lässt sich jedoch leicht beheben! Um unsere komplette Website sehen zu können, müssen Sie einfach nur ein <a href="https://browsehappy.com/?locale=de" target="_blank" class="a-link">Update</a> Ihres Browsers durchführen.',
                ],
                'it-it': [
                    'Il nuovo sito web non è compatibile con questa versione del browser. Il problema può essere risolto facilmente. Per visitare il sito web completo, è infatti sufficiente <a href="https://browsehappy.com/?locale=it" target="_blank" class="a-link">aggiornare</a> ora il browser.',
                ],
                'fr-fr': [
                    'Il semble que notre nouveau site Web soit incompatible avec la version de votre navigateur. Ne vous en faites pas, la solution est simple ! Pour voir l\'ensemble de notre site Web, mettez tout simplement votre navigateur <a href="https://browsehappy.com/?locale=fr" target="_blank" class="a-link">à jour</a>, dès maintenant.',
                ],
                'es-es': [
                    'Parece que nuestro nuevo sitio web es incompatible con la versión actual de su navegador. No se preocupe, esto es fácil de solucionar. Para ver nuestro sitio web, solo tiene que <a href="https://browsehappy.com/?locale=es" target="_blank" class="a-link">actualizar</a> su navegador ahora.',
                ],
                'zh-tw': [
                    '似乎您當前的瀏覽器版本與我們的新網站不相容。不用擔心，這很容易解決！慾檢視完整網站，現在 <a href="https://browsehappy.com/?locale=zh" target="_blank" class="a-link">更新</a>瀏覽器即可。',
                ],
                'zh-cn': [
                    '我们的新网站似乎与您的当前浏览器版本不兼容。别担心，这很容易修复！要查看我们的完整网站，只需立即<a href="https://browsehappy.com/?locale=zh" target="_blank" class="a-link">更新</a>浏览器即可。',
                ],
                'ko-kr': [
                    'Acronis의 새로운 웹 사이트가 현재 귀하의 브라우저 버전과 호환되지 않는 것 같습니다. 걱정하지 마십시오. 쉽게 해결될 수 있는 문제입니다! Acronis의 완전한 웹 사이트를 보려면 지금 귀하의 브라우저를 <a href="https://browsehappy.com/?locale=ko" target="_blank" class="a-link">업데이트</a>하면 됩니다.',
                ],
                'ja-jp': [
                    '現在ご利用のブラウザのバージョンは、弊社の新しいウェブサイトと互換性がないようです。ただし、この問題は簡単に解決できます。ウェブサイトを正しい状態でご覧になるには、ブラウザを<a href="https://browsehappy.com/?locale=jp" target="_blank" class="a-link">アップデート</a>してください。',
                ],
            };
            /* eslint-enable */

            return this.getTranslation(text).join(' ');
        },

        getCompany() {
            const text = {
                'en-us': 'All rights reserved.',
                'de-de': 'Alle Rechte vorbehalten.',
                'it-it': 'Tutti i diritti riservati.',
                'fr-fr': 'Tous droits réservés.',
                'es-es': 'Todos los derechos reservados.',
                'zh-tw': '保留所有權利。',
                'zh-cn': '保留所有权利。',
            };

            return this.company || `© 2003–${new Date().getFullYear()} Acronis International GmbH. ${this.getTranslation(text)}`;
        },

        getLegal() {
            const text = {
                'en-us': 'Legal information',
                'de-de': 'Rechtliche Informationen',
                'it-it': 'Note legali',
                'fr-fr': 'Informations légales',
                'es-es': 'Información legal',
                'ja-jp': '法律上の注意事項',
                'ko-kr': '법률 정보',
                'zh-tw': '法律資訊',
                'zh-cn': '法律信息',
            };

            const legalLink = {
                text: this.getTranslation(text),
                to: '/en-us/legal/', // locale is hardcoded because not all minisites have this page [WEB-44668]
            };

            return this.legal || legalLink;
        },
    },

    methods: {
        getTranslation(text) {
            return text[this.locale] || text[LOCALE_DEFAULT];
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-unsupported-browser {
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;

    &-container {
        display: table;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background: #e5e5e5;
        background: var(--av-gradient-to-bottom-lightest);
    }

    &-wrap {
        display: table-cell;
        vertical-align: middle;
    }

    &-header {
        width: 90%;
        margin: 24px auto 0 auto;
        text-align: center;

        &__logo {
            display: block;
            height: 27px;
            width: 133px;

            @media (--viewport-desktop) {
                height: 32px;
                width: 158px;
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &-main {
        text-align: center;
        margin-inline-start: auto;
        margin-inline-end: auto;
        width: 90%;

        &__icon {
            position: relative;
            margin: 0 auto 40px;
            width: 96px;
            height: 96px;
        }

        &__title {
            @mixin display;

            margin-bottom: 24px;
        }

        &__text,
        &__description {
            @mixin paragraph;

            & .a-link {
                vertical-align: baseline;
            }
        }
    }

    &-footer {
        position: absolute;
        width: 100%;
        margin-top: 4em;
        text-align: center;
        margin-bottom: 30px;

        &__company {
            @mixin paragraph;

            margin: 0 auto;
            width: 90%;
        }
    }

    &-active {
        overflow: hidden;

        .s-unsupported-browser {
            top: 0;
            inset-inline-start: 0;
            display: block;

            /* WEB-43924 why do we use 10000 here ? */
            z-index: 10000;
            position: fixed;
            background: var(--av-inversed-primary);
        }
    }
}
</style>
