<template>
    <div class="s-cta-even-column">
        <div class="a-container content">
            <a-slice-header
                v-if="title || lead || subtitle"
                :title="title"
                :lead="subtitle"
                :body-text="lead"
            />
            <div class="container">
                <p class="header">
                    {{ accentSubtitle }}
                </p>
                <div class="items" :class="{'has-grid': cards.length > 1}">
                    <div v-for="(card, i) in cards" :key="`card_${i}`" class="cta-card">
                        <div class="cta-top">
                            <div v-if="card.title || card.image" class="cta-top-inner">
                                <p v-if="card.title" class="cta-title">
                                    {{ card.title }}
                                </p>
                                <a-picture
                                    v-if="card.image"
                                    class="cta-image"
                                    :link="card.image"
                                    fit="contain"
                                />
                            </div>
                        </div>
                        <div class="cta-bottom">
                            <p v-if="card.subtitle" class="cta-subtitle">
                                {{ card.subtitle }}
                            </p>
                            <p v-if="card.text" class="cta-text">
                                {{ card.text }}
                            </p>
                            <div v-if="card.buttons.length" class="action-buttons">
                                <a-button
                                    v-for="(button, j) in card.buttons"
                                    :key="`button_${j}`"
                                    v-bind="button"
                                    :size="button.size || 's'"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';

export default {
    name: 'SCtaEvenColumn',
    components: {
        ASliceHeader,
        AButton,
        APicture,
    },
    props: {
        title: {
            type: String,
            default: null,
        },

        subtitle: {
            type: String,
            default: null,
        },

        lead: {
            type: String,
            default: null,
        },

        accentSubtitle: {
            type: String,
            default: null,
        },

        cards: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-cta-even-column {
    @mixin basic-slice-paddings;
    color: var(--av-nav-primary);
    position: relative;
    background: linear-gradient(180deg, rgba(38, 104, 197, 0.05) 0%, rgba(38, 104, 197, 0) 100%);
}

.content {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.a-slice-header {
    width: 100%;
    max-width: 960px;
    margin-bottom: 56px;
}

:deep(.a-slice-header) {
    &__title {
        @mixin hero;
    }

    &__lead {
        @mixin title;
    }

    &__body-text {
        @mixin lead;
    }
}

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.header {
    @mixin display-accent;
    color: var(--av-nav-primary);
    text-align: center;
    margin-bottom: 32px;

    @media (--viewport-tablet) {
        text-align: start;
    }

    @media (--viewport-desktop) {
        text-align: center;
    }
}

.items {
    flex-direction: column;
    align-items: center;
    grid-template-columns: 1fr;
    grid-gap: 24px;

    &.has-grid {
        display: grid;
    }

    @media (--viewport-desktop) {
        justify-content: center;
        grid-template-columns: repeat(2, 1fr);
    }
}

.cta-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--av-nav-primary);
    background-color: var(--av-inversed-primary);
    box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
    border: 1px solid var(--av-brand-light);
    border-radius: 4px;
    height: fit-content;
    padding: 32px;

    @media (--viewport-tablet) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 40px;
        padding: 40px;
        align-items: flex-start;
    }

    @media (--viewport-desktop) {
        display: flex;
        max-width: 468px;
        flex-direction: column;
        align-items: center;
    }
}

.cta-top {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (--viewport-tablet) {
        min-width: 225px;
    }

    @media (--viewport-desktop) {
        align-items: center;
    }
}

.cta-top-inner {
    width: 100%;

    @media (--viewport-desktop) {
        margin-bottom: 36px;
    }
}

.cta-bottom {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    width: 100%;

    @media (--viewport-tablet) {
        align-items: center;
    }

    @media (--viewport-desktop) {
        margin-inline-start: 0;
    }
}

.cta-title + .cta-image {
    margin-top: 32px;

    @media (--viewport-desktop) {
        margin-top: 40px;
    }
}

.cta-image {
    display: none;
    width: fit-content;
    height: fit-content;
    margin-inline: auto;

    &:deep(.a-picture__img) {
        min-height: 120px;
        max-height: 174px;
        height: auto;
        width: 100%;
    }

    @media (--viewport-tablet) {
        display: block;
    }
}

.cta-title {
    @mixin nav-title-accent;

    text-align: center;
    margin-bottom: 32px;

    @media (--viewport-tablet) {
        margin-bottom: 0;
        text-align: start;
    }

    @media (--viewport-desktop) {
        text-align: center;
    }
}

.cta-subtitle {
    @mixin lead-accent;

    margin-bottom: 8px;
    text-align: center;

    @media (--viewport-tablet) {
        width: 100%;
        text-align: start;
    }

    @media (--viewport-desktop) {
        text-align: center;
    }
}

.cta-text {
    @mixin paragraph;
    width: 100%;
    text-align: center;

    @media (--viewport-tablet) {
        text-align: start;
    }

    @media (--viewport-desktop) {
        text-align: center;
    }
}

.cta-subtitle + .action-buttons,
.cta-text + .action-buttons {
    padding-top: 40px;
}

.action-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (--viewport-desktop) {
        margin-top: auto;
    }

    .a-button {
        width: 100%;
    }
}
</style>
