<template>
    <div class="resource-home-specific-type-list">
        <div class="container">
            <div class="texts-wrapper">
                <h2 class="title">
                    {{ title }}
                </h2>
                <a-link
                    :text="browseTitle"
                    glyph="arrow"
                    :to="linkUrl"
                />
            </div>
            <div class="list">
                <resource-search-item
                    v-for="item in list"
                    :key="item.id"
                    class="resource-search-item"
                    :resource="item"
                    :common-translations="commonTranslations"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import ALink from '@core/components/link/link.vue';
import ResourceSearchItem from '@core/slices/resource-center/resource-search-item/resource-search-item.vue';
import { RC_ROOT_URL } from '@model/const/resource-center';

export default {
    name: 'ResourceFeaturedList',

    components: {
        ALink,
        ResourceSearchItem,
    },

    props: {
        list: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        commonTranslations: {
            type: Object,
            required: true,
        },
        browseTitle: {
            type: String,
            required: true,
        },
        linkUrl: {
            type: String,
            default: `${RC_ROOT_URL}/search/`,
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-home-specific-type-list {
    width: 100%;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 1440px;
        margin: 0 auto;
        gap: 40px;
        padding: 64px 16px;

        @media (--viewport-tablet) {
            padding: 64px 32px;
        }

        @media (--viewport-desktop-wide) {
            padding: 64px;
        }
    }
}

.texts-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media (--viewport-mobile-wide) {
        flex-flow: row wrap;
        justify-content: space-between;
        width: 100%;
    }

    &:deep(.a-link__content) {
        @mixin lead-accent;
        color: var(--av-brand-primary);
    }
}

.title {
    @mixin hero;
    color: var(--av-nav-primary);
}

.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;

    @media (--viewport-mobile-wide) {
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    @media (--viewport-tablet) {
        display: grid;
        align-items: initial;
        grid-template-columns: 1fr 1fr;
    }

    @media (--viewport-desktop) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media (--viewport-desktop-large) {
        gap: 60px;
    }
}

.resource-search-item {
    width: 328px;

    @media (--viewport-mobile-wide) {
        width: 256px;
    }

    @media (--viewport-tablet) {
        width: initial;
    }
}
</style>
