export const TYPES = [
    'normal',
    'medium',
    'small',
    'thumb',
    'blogCover',
    'blogCard',
    'background',
];

export default {};
