<template>
    <li class="a-slide" :class="className">
        <slot />
    </li>
</template>

<script>
export default {
    name: 'ASlide',

    inject: ['slideWithBorder'],

    computed: {
        className() {
            return {
                'a-slide_with-border': this.slideWithBorder,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-slide {
    @media (--viewport-desktop) {
        &_with-border {
            border-inline-start: var(--av-border);
            padding-inline-start: 24px;
        }
    }

    &:only-child {
        opacity: 1;
    }
}

.a-slide {
    position: relative;
    inset-inline-start: 0;
    width: 85%;

    @media (--viewport-tablet) {
        width: 60%;
    }

    @media (--viewport-desktop) {
        width: 40%;
    }

    .a-slider_off & {
        width: 100%;
    }
}
</style>
