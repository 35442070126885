import { render, staticRenderFns } from "./resource-cta-card.vue?vue&type=template&id=6a431e00&scoped=true"
import script from "./resource-cta-card.vue?vue&type=script&lang=ts"
export * from "./resource-cta-card.vue?vue&type=script&lang=ts"
import style0 from "./resource-cta-card.vue?vue&type=style&index=0&id=6a431e00&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a431e00",
  null
  
)

export default component.exports