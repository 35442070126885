<template>
    <s-basic-slice :id="id" class="s-social-links" :class="{ ...basicClassName }">
        <a-slice-header :title="title" :lead="lead" :body-text="bodyText" />
        <div class="s-social-links__list">
            <dynamic-link
                v-for="link in socialLinks"
                :key="link.name"
                class="s-social-links__item"
                :to="link.to"
                real-target="_blank"
            >
                <a-glyph class="s-social-links__icon" :name="link.name" size="l" />
                <div class="s-social-links__title">
                    {{ link.title }}
                </div>
            </dynamic-link>
        </div>
    </s-basic-slice>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SSocialCards',

    components: {
        DynamicLink,
        AGlyph,
        ASliceHeader,
        SBasicSlice,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        socialLinks: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-social-links {
    &__list {
        display: flex;
        margin-top: 40px;
        flex-wrap: wrap;
    }

    &__item {
        flex-shrink: 0;
        padding: 24px;
        width: 148px;
        height: 148px;
        border: 1px solid rgba(230, 230, 230, 0.9);
        box-sizing: border-box;
        box-shadow: 0 5px 6px rgba(138, 173, 207, 0.1);
        border-radius: 4px;
        text-decoration: none;
        display: block;
        margin: 0 16px 16px 0;
        transition: all 0.2s ease;

        &:hover {
            box-shadow: 0 5px 6px rgba(138, 173, 207, 0.186);

            &:deep(.a-glyph) {
                fill: var(--av-nav-secondary);
            }
        }

        &:last-child {
            margin: 0;
        }
    }

    &__title {
        @mixin paragraph;

        margin-top: 28px;
        color: var(--av-fixed-secondary);
    }

    &__icon {
        width: 48px;
        height: 48px;
    }
}
[dir='rtl'] {
    .s-social-links__item:last-child {
        margin: 0 16px 16px 0;
    }
}
</style>
