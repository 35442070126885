<template>
    <svg
        width="132"
        height="48"
        viewBox="0 0 132 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_2476_7492)">
            <path d="M53.0701 24.0004V36.0216H65.0887V24.0004H77.1099V11.9792H65.0887L53.0701 24.0004Z" fill="white" />
            <path d="M48.1435 11.9797H36.1223V24.0009H48.1435V11.9797Z" fill="white" />
            <path d="M12.0754 11.9808V24.002H24.0966V36.0232H36.1178V24.002L24.0966 11.9808H12.0754Z" fill="white" />
            <path d="M119.2 11.9845V36.0243L107.179 24.0031V11.9845H119.2Z" fill="white" />
            <path d="M95.154 11.9797H83.1328V24.0009L95.154 36.0221H107.175V24.0009H95.154V11.9797Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_2476_7492">
                <rect width="131.271" height="48" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'ATruLogo',
};
</script>
