<template>
    <div class="step-success">
        <div class="title">
            {{ uiStrings.successTitle }}
        </div>

        <div class="text">
            <a-dangerous-html :content="uiStrings.successMessage" />
        </div>

        <a-picture class="picture" :link="uiStrings.successImage" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import APicture from '@core/components/picture/picture.vue';

export default Vue.extend({
    name: 'DeveloperRegisterProgramStepSuccess',

    components: {
        ADangerousHtml,
        APicture,
    },

    props: {
        uiStrings: {
            type: Object,
            required: true,
        },
    },
});
</script>

<style lang="postcss" scoped>
.step-success {
    text-align: center;
}

.title {
    @mixin title;
    color: var(--av-nav-primary);
    margin-bottom: 24px;
}

.text {
    @mixin paragraph;
    color: var(--av-text-primary);
    margin-bottom: 24px;
}
</style>
