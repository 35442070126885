<template>
    <div v-if="list" class="selector-wrapper" :class="{'opened': isOpened}">
        <div class="selector" @click="handleOpen()">
            <div v-if="label" class="label" v-html="label" />
            <div v-if="text" class="text" v-html="text" />
            <a-glyph name="drop-down" />
        </div>
        <div class="list">
            <label
                v-for="(item, itemIndex) in list"
                :key="`item-${itemIndex}`"
                :class="['item', { 'item-selected': item.id === itemSelected }]"
            >
                <input
                    v-model="itemSelected"
                    type="radio"
                    :value="item.id"
                    @change="$emit('change', $event)"
                />
                <div class="text" v-html="item.text || ''" />
            </label>
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'SSelector',

    components: {
        AGlyph,
    },

    props: {
        list: {
            type: Array,
            default: null,
        },

        label: {
            type: String,
            default: null,
        },

        selected: {
            type: Number,
            default: 0,
        },
    },

    emits: ['change'],

    data: () => ({
        isOpened: false,
        itemSelected: 0,
    }),

    computed: {
        text() {
            const current = this.list.find((item) => item.id === this.itemSelected);
            return current.text || '';
        },
    },

    created() {
        this.itemSelected = this.selected;
    },

    methods: {
        handleOpen() {
            this.isOpened = true;
            window.addEventListener('click', this.handleClose, true);
        },

        handleClose() {
            this.isOpened = false;
            window.removeEventListener('click', this.handleClose, true);
        },
    },
};
</script>

<style lang="postcss" scoped>

.selector-wrapper {
    height: 48px;
    display: flex;
    user-select: none;
    position: relative;
    border-radius: 4px;
    background: var(--av-inversed-primary);
    border: 1px solid var(--av-brand-light);
    .a-glyph {
        top: 16px;
        inset-inline-end: 16px;
        width: 16px;
        height: 16px;
        position: absolute;
        fill: var(--av-brand-primary);
    }
}

.label {
    @mixin caption;
    color: var(--av-fixed-light);
}

.text {
    @mixin paragraph;
    width: 100%;
    display: flex;
    margin-top: -2px;
    color: var(--av-fixed-primary);
    &:deep(.promo) {
        @mixin paragraph-accent;
        color: #7c9b1e;
        margin-inline-start: 16px;
    }
}

.selector {
    width: 100%;
    display: flex;
    cursor: pointer;
    flex-wrap: unset;
    padding: 0 16px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: var(--av-fixed-primary);
}

.list {
    z-index: 2;
    display: none;
    padding: 8px 0;
    margin: 0 -1px;
    width: calc(100% + 2px);
    border-radius: 0 0 4px 4px;
    border: 1px solid var(--av-brand-primary);
    border-top: 1px solid var(--av-brand-primary);
}

.item {
    width: 100%;
    display: flex;
    cursor: pointer;
    position: relative;
    padding: 8px 16px;
    justify-content: flex-start;

    &:hover {
        background: var(--av-brand-secondary-accent);
    }
}

.item-selected {
    background-color: var(--el-secondary-active);
}

.opened {
    border-radius: 4px 4px 0 0;
    border-color: var(--av-brand-primary);

    &:before {
        top: 0;
        inset-inline-start: 0;
        z-index: 1;
        content: '';
        width: 100%;
        height: 100%;
        cursor: default;
        position: absolute;
    }

    .selector {
        cursor: default;
    }

    .list {
        inset-inline-start: 0;
        top: 100%;
        display: block;
        position: absolute;
        background: var(--av-inversed-primary);
    }

    .a-glyph {
        transform: rotate(180deg);
    }
}
</style>
