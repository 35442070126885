<template>
    <div class="trial-es-questions" :class="{ 'full-width': !question.card.columns }">
        <h1 v-if="question.content.title" class="title">
            {{ question.content.title }}
        </h1>
        <div class="card">
            <h2 v-if="question.card.title" class="title">
                {{ question.card.title }}
            </h2>
            <div v-if="question.card.columns" class="columns">
                <div v-if="question.card.answers.leftColumn" class="left">
                    <express-signup-question-button
                        v-for="answer in question.card.answers.leftColumn"
                        :key="`question_${answer.questionId}_answer_${answer.id}`"
                        class="answer"
                        v-bind="answer"
                        @click="submitQuestion(answer)"
                    />
                </div>
                <div v-if="question.card.answers.rightColumn" class="right">
                    <express-signup-question-button
                        v-for="answer in question.card.answers.rightColumn"
                        :key="`question_${answer.questionId}_answer_${answer.id}`"
                        class="answer"
                        v-bind="answer"
                        @click="submitQuestion(answer)"
                    />
                </div>
            </div>
            <div v-else class="full">
                <express-signup-question-button
                    v-for="answer in question.card.answers.leftColumn"
                    :key="`question_${answer.questionId}_answer_${answer.id}`"
                    class="answer"
                    v-bind="answer"
                    @click="submitQuestion(answer)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { numberKeys, mapNumberKeys } from '@core/slices/pages/express-signup-shared/constants.js';
import ExpressSignupQuestionButton from '@core/slices/pages/express-signup-shared/express-signup-question-button.vue';

export default {
    components: {
        ExpressSignupQuestionButton,
    },
    props: {
        question: {
            type: Object,
            required: true,
            default: undefined,
        },
    },
    emits: ['answeredQuestion'],
    mounted() {
        window.addEventListener('keydown', this.keyDownHandler);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.keyDownHandler);
    },
    methods: {
        keyDownHandler(e) {
            if (!numberKeys.includes(e.keyCode)) return false;
            const key = mapNumberKeys[e.keyCode].number;

            const button = document.querySelector(`.express-signup-question__button__${key}`);
            return button.click();
        },
        submitQuestion(answer) {
            this.$emit('answeredQuestion', answer);
        },
    },
};
</script>

<style lang="postcss" scoped>
.trial-es-questions {
    @mixin colls 12;
    margin: 0 auto;
    padding: 48px 0 80px;
    @media (--viewport-tablet) {
        padding-bottom: 0;
        min-height: 578px;
    }
    @media (--viewport-desktop) {
        max-width: 860px;
    }
    &.full-width {
        @media (--viewport-tablet) {
            max-width: 600px;
        }
        &:deep(.trial-es-questions__card__title) {
            margin-bottom: 52px;
        }
    }
    .title {
        text-align: center;
        @mixin display-accent;
        margin-bottom: 60px;
        color: var(--av-nav-primary);

        @media (--viewport-mobile-wide) {
            @mixin hero-accent;
        }

        @media (--viewport-tablet) {
            margin-bottom: 52px;
        }

        @media (--viewport-desktop) {
            @media large-accent;
        }
    }
    .card {
        padding: 32px 16px;
        background: var(--av-inversed-primary);
        box-shadow: var(--av-shadow-regular);
        border-radius: 8px;
        @media (--viewport-desktop) {
            padding: 32px 72px;
        }
        @media (--viewport-tablet) {
            padding: 32px 48px;
        }
        .title {
            @mixin title-accent;
            font-weight: 700;
            text-align: center;
            color: var(--av-nav-primary);
            margin-bottom: 24px;
        }

        .columns {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            @media (--viewport-desktop) {
                padding: 0 60px;
            }
            .left {
                margin-bottom: 8px;
                @media (--viewport-tablet) {
                    margin-bottom: 0;
                }
            }
            .left,
            .right {
                @mixin colls 12;
                @media (--viewport-tablet) {
                    @mixin colls 6;
                }
            }
        }
        .full {
            padding: 0 24px;
            @media (--viewport-tablet) {
                padding: 0 120px;
            }
        }
        &:deep(.express-signup-question__button__text) {
            @mixin caption;
            line-height: 20px;
            font-weight: 500;
        }
    }
}
</style>
