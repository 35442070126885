<template>
    <s-basic-slice class="s-image-with-instructions" :class="className" v-bind="$attrs">
        <div class="s-image-with-instructions__wrapper">
            <div class="s-image-with-instructions__screenshot">
                <div class="s-image-with-instructions__label">
                    <template v-for="(item, index) in cards">
                        <div v-if="item.css" :key="index" class="s-image-with-instructions__label-item">
                            <div class="s-image-with-instructions__label-item-desktop" :style="item.css" v-html="index + 1" />
                            <div
                                class="s-image-with-instructions__label-item-mobile"
                                :style="item.cssMobile ? item.cssMobile : item.css"
                                v-html="index + 1"
                            />
                        </div>
                    </template>
                    <a-picture class="s-image-with-instructions__img" :link="image.path" :alt="image.alt" />
                </div>
            </div>
            <ol class="s-image-with-instructions__list">
                <li v-for="item in cards" :key="item.title" class="s-image-with-instructions__list-item">
                    {{ item.title }}
                </li>
            </ol>
        </div>
    </s-basic-slice>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SImageWithInstructions',

    components: {
        APicture,
        SBasicSlice,
    },

    inheritAttrs: false,

    props: {
        image: {
            type: Object,
            required: true,
        },

        cards: {
            type: Array,
            required: true,
        },
    },

    computed: {
        className() {
            return {
                's-image-with-instructions_layout_4-items': this.cards.length === 4,
                's-image-with-instructions_layout_3-items': this.cards.length === 3,
                's-image-with-instructions_layout_2-items': this.cards.length < 3,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-image-with-instructions {
    &:deep(.a-slice-header) {
        margin-bottom: 24px;

        &__lead {
            @mixin lead;
        }
    }

    &__screenshot {
        text-align: center;
    }

    &__label {
        max-width: 326px;
        position: relative;
        margin: 0 auto;
    }

    &__label-item-mobile,
    &__label-item-desktop {
        background-color: #3b91f6;
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        font-weight: 700;
        font-size: 14px;
        color: var(--av-inversed-primary);
        line-height: 30px;
    }

    &__label-item-desktop {
        display: none;
    }

    &__img {
        width: 100%;
        vertical-align: middle;
    }

    &__list {
        list-style: decimal;
        padding: 40px 0 0 24px;
        margin-top: -32px;
    }

    &__list-item {
        padding: 0;
        margin-top: 32px;

        &::marker {
            content: initial; /* fix for reset.css content: '' */
        }
    }

    @media (--viewport-mobile-wide) {
        &:deep(.a-slice-header) {
            margin-bottom: 40px;

            &__lead {
                @mixin title;

                max-width: 668px;
            }
        }

        &__screenshot {
            display: flex;
            justify-content: flex-start;
        }

        &__label {
            max-width: 464px;
        }

        &__label-item-mobile {
            display: none;
        }

        &__label-item-desktop {
            display: block;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin: -24px 0 0 0;
            margin-inline-end: -24px;
        }

        &__list-item {
            padding: 0;
            padding-inline-end: 16px;
            padding-inline-start: 4px;
            margin: 40px 0 0 0;
            margin-inline-end: 24px;
            width: calc(50% - 24px);
        }
    }

    @media (--viewport-desktop) {
        &:deep(.a-slice-header) {
            max-width: 100%;

            &__lead {
                max-width: 794px;
            }
        }

        &__label {
            max-width: 632px;
        }

        &__list {
            margin-inline-start: 24px;
            padding: 0;
        }

        &__list-item {
            margin: 56px 0 0 0;
            padding: 0;
            padding-inline-end: 42px;
            padding-inline-start: 4px;
        }

        &__screenshot {
            padding: 0 16px;
        }

        &_layout {
            &_2-items {
                .s-image-with-instructions {
                    &__list-item {
                        width: 50%;
                    }
                }
            }

            &_3-items {
                .s-image-with-instructions {
                    &__list-item {
                        width: 33.33%;
                    }
                }
            }

            &_4-items {
                .s-image-with-instructions {
                    &__list-item {
                        width: 25%;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop-wide) {
        &__wrapper {
            display: flex;
        }

        &__label {
            max-width: 566px;
        }

        &__list {
            display: block;
            width: 34%;
            margin: -32px 0 0 98px;
        }

        &__list-item {
            margin-top: 32px;
            width: auto !important;
        }
    }

    @media (--viewport-desktop-large) {
        &__list {
            margin-inline-start: 198px;
        }
    }
}
</style>
