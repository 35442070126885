import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import Pagination from '@core/components/pagination/pagination.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import PressReleasesHomeFilters
    from '@core/slices/press-releases/press-releases-home-filters/press-releases-home-filters.vue';
import PressReleasesReleaseCard
    from '@core/slices/press-releases/press-releases-release-card/press-releases-release-card.vue';
import ResourcePage from '@core/slices/resource-center/shared-components/resource-page.vue';
import { LOCALE_DEFAULT } from '@model/const/locales';
import commonUtils from '@utils/common';

export default {
    components: {
        DynamicLink,
        Pagination,
        PressReleasesHomeFilters,
        PressReleasesReleaseCard,
        ResourcePage,
    },

    mixins: [breakpoint],

    props: {
        searchHashData: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        searchData: {},
        searchIsPending: false,
    }),

    computed: {
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },

        pressReleasesConfig() {
            return this.$store.state.slices.items['press-releases'] || {};
        },

        releaseList() {
            const releases = this.$store.state.pressReleases.pressReleases.items || [];
            return releases.map((release) => ({
                ...release,
                publishedAt: release.published_at,
            }));
        },

        mediaLinks() {
            return this.pressReleasesConfig.mediaLinks || [];
        },

        totalPages() {
            return this.$store.state.pressReleases.pressReleases.pagination?.pages_total || 0;
        },

        currentPage() {
            return this.searchData.page || 1;
        },
    },

    methods: {
        triggerResetFilters() {
            this.applyFilter({ reset: true });
        },

        fixPageNumber() {
            if (this.searchData.page > this.totalPages) this.searchData.page = this.totalPages;
        },

        applyFilter({ filters, reset }) {
            const params = this.$route.params;
            params.locale = filters?.locale || this.locale;
            params.year = filters?.year || params.year;

            this.searchData.locale = params.locale;
            if (this.searchData.locale !== this.locale) {
                this.searchData.page = 1;
            }

            if (params.year && !reset) {
                this.searchData.year = params.year;
                this.searchData.page = 1;
            } else {
                delete this.searchData.year;
            }

            this.search(reset);
        },

        updateUrlParams(reset: boolean) {
            const localeChanged = Boolean(this.searchData.locale);
            const params = this.$route.params;
            const locale = this.searchData.locale || this.locale;
            const year = reset ? null : this.searchData.year || params.year;
            const page = reset ? null : this.searchData.page || 1;

            let path = `/${locale}/pr/`;
            if (year) path += `${year}/`;
            if (page > 1) path += `page/${page}/`;

            if (path !== this.$route.path) {
                // we need to request new localization via ssr
                if (localeChanged) {
                    window.location.href = path;
                } else {
                    this.$router.push(path);
                }
            }
        },

        async search(reset = false) {
            this.updateUrlParams(reset);

            this.searchIsPending = true;
            await this.$store.dispatch('pressReleases/applyHomeFilters', {
                locale: this.searchData.locale || this.locale,
                filters: this.searchData,
            });
            delete this.searchData.locale;
            delete this.searchData.year;

            commonUtils.scrollTop();

            this.searchIsPending = false;
        },

        async setPage(pageNumber: number, callback?: () => void) {
            this.searchData.page = pageNumber;

            if (this.year) {
                this.searchData.year = this.year;
            } else {
                delete this.searchData.year;
            }

            await this.search();

            callback?.();
        },

        getUrl(to: string) {
            return `/${this.locale}${to}`;
        },
    },
};
