<template>
    <div class="greet-modal-wrapper">
        <div class="greet-modal">
            <div class="wrapper" :class="{ loading: isLoading }">
                <a-picture :link="content.image" :alt="computedTitle" class="image" />
                <h6 class="title">
                    {{ computedTitle }}
                </h6>
                <p class="lead">
                    {{ content.lead }}
                </p>
                <div
                    v-if="isLoading"
                    v-loading="true"
                    class="loader"
                    el-loading-size="48"
                    el-loading-color="brand-secondary"
                    :el-loading-text="content.loadingText"
                />
                <a-button
                    v-if="!isLoading"
                    v-bind="content.button"
                    class="button"
                    @click="$emit('onClose')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import { upperFirst } from 'lodash';
import AButton from '@core/components/button/button.vue';
import APicture from '@core/components/picture/picture.vue';
import commonUtils from '@utils/common';

export default {
    components: {
        AButton,
        APicture,
    },
    directives: {
        Loading,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: null,
        },
        content: {
            type: Object,
            required: true,
        },
    },
    emits: ['onClose'],
    computed: {
        computedTitle() {
            if (!this.name) {
                const string = this.content.title.replace('{name}, ', '');
                return upperFirst(string);
            }

            return this.content.title.replace('{name}', this.name);
        },
    },
    mounted() {
        commonUtils.globalOverflowProperty('hidden');
    },
    beforeDestroy() {
        commonUtils.globalOverflowProperty('auto');
    },
};
</script>

<style lang="postcss" scoped>
.greet-modal-wrapper {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding: 64px 0;
    z-index: 900;
    background: var(--av-fixed-secondary);
    overflow: auto;
    .greet-modal {
        max-width: calc(100% - 32px);
        width: 100%;
        margin: auto;
        background: var(--av-inversed-primary);
        border: 1px solid var(--av-brand-light);
        box-shadow: var(--av-shadow-modal);
        border-radius: 4px;
        animation: vroi-popup 0.3s both;
        @media (--viewport-mobile-wide) {
            max-width: 417px;
        }
        @media (--viewport-tablet) {
            max-width: 566px;
        }
        .wrapper {
            padding: 40px;
            padding-bottom: 64px;
            text-align: center;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            @media (--viewport-tablet) {
                padding-bottom: 72px;
            }
            .title {
                @mixin colls 12;
                @mixin title-accent;
                color: var(--av-nav-primary);
                margin-bottom: 16px;
            }
            .lead {
                @mixin paragraph;
                color: var(--av-nav-primary);
                margin-bottom: 48px;
            }
            .image {
                margin-bottom: 24px;
            }
            .loader {
                min-height: 80px;
                width: 100%;
            }
            &.loading {
                .lead {
                    margin-bottom: 40px;
                }
            }
        }
        @keyframes vroi-popup {
            0% {
                opacity:0;
            }
            100% {
                opacity:1;
            }
        }
    }
}
</style>
