<template>
    <section :id="id" class="s-slice s-ransomware-attacks" :class="basicClassName">
        <div class="a-container">
            <div v-if="title" class="a-slice-header s-ransomware-attacks__header">
                <h2 class="a-slice-header__title s-ransomware-attacks__title">
                    {{ title }}
                </h2>
            </div>
            <a-tabs class="s-ransomware-attacks__content">
                <a-tab v-for="(tab, index) in cards" :key="`tab_${index}`" :label="tab.name">
                    <a-ransomware-card v-bind="tab" />
                </a-tab>
            </a-tabs>
        </div>
    </section>
</template>

<script>
import ARansomwareCard from '@core/components/ransomware-card/ransomware-card.vue';
import ATab from '@core/components/tabs/tab.vue';
import ATabs from '@core/components/tabs/tabs.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SRansomwareAttacks',

    components: {
        ARansomwareCard,
        ATabs,
        ATab,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        title: {
            type: String,
            required: false,
            default: undefined,
        },

        cards: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss">
.s-ransomware-attacks {
    &__header {
        margin-bottom: 48px;

        @media (--viewport-desktop) {
            margin-bottom: 64px;
        }
    }

    &__title {
        width: auto;

        @media (--viewport-mobile-wide) {
            @mixin display;
        }

        @media (--viewport-desktop) {
            @mixin hero;
        }
    }

    &__content {
        .a-tabs__header {
            margin-bottom: 64px;
        }
    }
}
</style>
