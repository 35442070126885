<template>
    <section :id="id" class="s-fact-slider" :class="basicClassName">
        <div class="a-container">
            <div class="a-slice-header__title">
                {{ title }}
            </div>
            <div class="s-fact-slider__wrapper">
                <div class="s-fact-slider__content">
                    <div class="a-slice-header__lead">
                        {{ lead }}
                    </div>
                    <a-slider class="s-fact-slider__slider" :options="sliceSliderOptions">
                        <a-slide v-for="(item, index) in sliderItems" :key="`slider_fact_${index}`">
                            <div class="s-fact-slider-item" />
                            <div class="s-fact-slider-item__number">
                                {{ item.number }}
                            </div>
                            <div class="s-fact-slider-item__percent">
                                {{ item.percent }}
                            </div>
                            <div class="s-fact-slider-item__desc">
                                {{ item.desc }}
                            </div>
                        </a-slide>
                    </a-slider>
                </div>
                <div class="s-fact-slider__radar-container">
                    <svg
                        class="s-fact-slider-radar"
                        viewBox="0 0 1176 1176"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <defs>
                            <linearGradient
                                id="paint0_linear"
                                x1="588"
                                y1="376.909"
                                x2="736.351"
                                y2="442.342"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#1A60B3" stop-opacity="0" />
                                <stop offset="1" stop-color="#1A60B3" stop-opacity=".24" />
                            </linearGradient>
                        </defs>
                        <!-- eslint-disable max-len -->
                        <path d="M588 1176c324.743 0 588-263.257 588-588S912.743 0 588 0 0 263.257 0 588s263.257 588 588 588z" fill="#1A60B3" fill-opacity=".05" />
                        <path d="M1175.5 588c0 324.467-263.033 587.5-587.5 587.5S.5 912.467.5 588 263.533.5 588 .5s587.5 263.033 587.5 587.5z" stroke="#1A60B3" stroke-opacity=".15" />
                        <path d="M588 912c178.94 0 324-145.06 324-324S766.94 264 588 264 264 409.06 264 588s145.06 324 324 324z" fill="#1A60B3" fill-opacity=".05" />
                        <path d="M911.5 588c0 178.664-144.836 323.5-323.5 323.5S264.5 766.664 264.5 588 409.336 264.5 588 264.5 911.5 409.336 911.5 588z" stroke="#1A60B3" stroke-opacity=".15" />
                        <path d="M588 799c116.532 0 211-94.468 211-211s-94.468-211-211-211-211 94.468-211 211 94.468 211 211 211z" fill="#1A60B3" fill-opacity=".05" />
                        <path d="M798.5 588c0 116.256-94.244 210.5-210.5 210.5S377.5 704.256 377.5 588 471.744 377.5 588 377.5 798.5 471.744 798.5 588z" stroke="#1A60B3" stroke-opacity=".15" />
                        <path d="M588 708c66.274 0 120-53.726 120-120s-53.726-120-120-120-120 53.726-120 120 53.726 120 120 120z" fill="#1A60B3" fill-opacity=".05" />
                        <path d="M707.5 588c0 65.998-53.502 119.5-119.5 119.5-65.998 0-119.5-53.502-119.5-119.5 0-65.998 53.502-119.5 119.5-119.5 65.998 0 119.5 53.502 119.5 119.5z" stroke="#1A60B3" stroke-opacity=".15" />
                        <path d="M588 609c11.598 0 21-9.402 21-21s-9.402-21-21-21-21 9.402-21 21 9.402 21 21 21z" fill="#1A60B3" fill-opacity=".05" />
                        <path stroke="#1A60B3" stroke-opacity=".15" d="M587.5 0v1176M1176 588.5H0" />
                        <g class="s-fact-slider-radar__peleng-marker">
                            <path d="M588 377v211l149-149.459C698.822 400.51 646.158 377 588 377z" fill="url(#paint0_linear)" />
                            <path
                                d="M588 588l149-149.5"
                                stroke="#1A60B3"
                                stroke-width="1.509"
                                stroke-linecap="round"
                            />
                        </g>
                        <g class="s-fact-slider-radar__point s-fact-slider-radar__point_1">
                            <switch>
                                <foreignObject
                                    class="s-fact-slider-radar__text"
                                    x="598"
                                    y="666"
                                    width="300"
                                    height="1176"
                                >
                                    <span>{{ radarText[0] }}</span>
                                </foreignObject>
                                <text class="s-fact-slider-radar__text" x="630" y="680">
                                    <tspan>{{ radarText[0] }}</tspan>
                                </text>
                            </switch>
                            <path opacity=".08" d="M747.09 663.513c8.334 0 15.09-6.828 15.09-15.251 0-8.422-6.756-15.25-15.09-15.25S732 639.84 732 648.262c0 8.423 6.756 15.251 15.09 15.251z" fill="#FF842D" />
                            <path opacity=".15" d="M747.09 658.937c5.833 0 10.563-4.78 10.563-10.676s-4.73-10.675-10.563-10.675c-5.834 0-10.563 4.779-10.563 10.675 0 5.896 4.729 10.676 10.563 10.676z" fill="#FF4000" />
                            <path d="M747.09 654.365c3.333 0 6.036-2.732 6.036-6.101 0-3.369-2.703-6.1-6.036-6.1-3.334 0-6.036 2.731-6.036 6.1 0 3.369 2.702 6.101 6.036 6.101z" fill="#F11D12" />
                        </g>
                        <g class="s-fact-slider-radar__point s-fact-slider-radar__point_2">
                            <switch>
                                <foreignObject
                                    class="s-fact-slider-radar__text"
                                    x="495"
                                    y="730"
                                    width="300"
                                    height="1176"
                                >
                                    <span>{{ radarText[1] }}</span>
                                </foreignObject>
                                <text class="s-fact-slider-radar__text" x="580" y="730">
                                    <tspan>{{ radarText[1] }}</tspan>
                                </text>
                            </switch>
                            <path opacity=".08" d="M647.09 713.077c8.334 0 15.09-6.828 15.09-15.25 0-8.423-6.756-15.251-15.09-15.251S632 689.404 632 697.827c0 8.422 6.756 15.25 15.09 15.25z" fill="#FF842D" />
                            <path opacity=".15" d="M647.09 708.501c5.833 0 10.563-4.779 10.563-10.675 0-5.896-4.73-10.676-10.563-10.676-5.834 0-10.563 4.78-10.563 10.676s4.729 10.675 10.563 10.675z" fill="#FF4000" />
                            <path d="M647.09 703.929c3.333 0 6.036-2.731 6.036-6.1 0-3.369-2.703-6.1-6.036-6.1-3.334 0-6.036 2.731-6.036 6.1 0 3.369 2.702 6.1 6.036 6.1z" fill="#F11D12" />
                        </g>
                        <g class="s-fact-slider-radar__point s-fact-slider-radar__point_3">
                            <switch>
                                <foreignObject
                                    class="s-fact-slider-radar__text"
                                    x="365"
                                    y="758"
                                    width="300"
                                    height="1176"
                                >
                                    <span>{{ radarText[2] }}</span>
                                </foreignObject>
                                <text class="s-fact-slider-radar__text" x="365" y="758">
                                    <tspan>{{ radarText[2] }}</tspan>
                                </text>
                            </switch>
                            <path opacity=".08" d="M517.09 756.544c8.334 0 15.09-6.828 15.09-15.25 0-8.423-6.756-15.251-15.09-15.251S502 732.871 502 741.294c0 8.422 6.756 15.25 15.09 15.25z" fill="#FF842D" />
                            <path opacity=".15" d="M517.09 751.968c5.833 0 10.563-4.78 10.563-10.675 0-5.896-4.73-10.676-10.563-10.676-5.834 0-10.563 4.78-10.563 10.676 0 5.895 4.729 10.675 10.563 10.675z" fill="#FF4000" />
                            <path d="M517.09 747.396c3.333 0 6.036-2.731 6.036-6.1 0-3.37-2.703-6.101-6.036-6.101-3.334 0-6.036 2.731-6.036 6.101 0 3.369 2.702 6.1 6.036 6.1z" fill="#F11D12" />
                        </g>
                        <g class="s-fact-slider-radar__point s-fact-slider-radar__point_4">
                            <switch>
                                <foreignObject
                                    class="s-fact-slider-radar__text"
                                    x="290"
                                    y="608"
                                    width="300"
                                    height="1176"
                                >
                                    <span>{{ radarText[3] }}</span>
                                </foreignObject>
                                <text class="s-fact-slider-radar__text" x="330" y="620">
                                    <tspan>{{ radarText[3] }}</tspan>
                                </text>
                            </switch>
                            <path opacity=".08" d="M440.09 607.851c8.334 0 15.09-6.828 15.09-15.251 0-8.422-6.756-15.25-15.09-15.25S425 584.178 425 592.6c0 8.423 6.756 15.251 15.09 15.251z" fill="#FF842D" />
                            <path opacity=".15" d="M440.09 603.275c5.833 0 10.563-4.78 10.563-10.676s-4.73-10.675-10.563-10.675c-5.834 0-10.563 4.779-10.563 10.675 0 5.896 4.729 10.676 10.563 10.676z" fill="#FF4000" />
                            <path d="M440.09 598.702c3.333 0 6.036-2.731 6.036-6.1 0-3.369-2.703-6.1-6.036-6.1-3.334 0-6.036 2.731-6.036 6.1 0 3.369 2.702 6.1 6.036 6.1z" fill="#F11D12" />
                        </g>
                        <g class="s-fact-slider-radar__point s-fact-slider-radar__point_5">
                            <switch>
                                <foreignObject
                                    class="s-fact-slider-radar__text"
                                    x="314"
                                    y="497"
                                    width="300"
                                    height="1176"
                                >
                                    <span>{{ radarText[4] }}</span>
                                </foreignObject>
                                <text class="s-fact-slider-radar__text" x="370" y="510">
                                    <tspan>{{ radarText[4] }}</tspan>
                                </text>
                            </switch>
                            <path opacity=".08" d="M465.09 497.501c8.334 0 15.09-6.828 15.09-15.25 0-8.423-6.756-15.251-15.09-15.251S450 473.828 450 482.251c0 8.422 6.756 15.25 15.09 15.25z" fill="#FF842D" />
                            <path opacity=".15" d="M465.089 492.925c5.834 0 10.563-4.78 10.563-10.675 0-5.896-4.729-10.676-10.563-10.676-5.833 0-10.563 4.78-10.563 10.676 0 5.895 4.73 10.675 10.563 10.675z" fill="#FF4000" />
                            <path d="M465.09 488.353c3.333 0 6.036-2.731 6.036-6.1 0-3.369-2.703-6.101-6.036-6.101-3.334 0-6.036 2.732-6.036 6.101 0 3.369 2.702 6.1 6.036 6.1z" fill="#F11D12" />
                        </g>
                        <g class="s-fact-slider-radar__point s-fact-slider-radar__point_6">
                            <switch>
                                <foreignObject
                                    class="s-fact-slider-radar__text"
                                    x="398"
                                    y="548"
                                    width="300"
                                    height="1176"
                                >
                                    <span>{{ radarText[5] }}</span>
                                </foreignObject>
                                <text class="s-fact-slider-radar__text" x="420" y="560">
                                    <tspan>{{ radarText[5] }}</tspan>
                                </text>
                            </switch>
                            <path opacity=".08" d="M550.09 549.138c8.334 0 15.09-6.828 15.09-15.251 0-8.422-6.756-15.25-15.09-15.25S535 525.465 535 533.887c0 8.423 6.756 15.251 15.09 15.251z" fill="#FF842D" />
                            <path opacity=".15" d="M550.09 544.562c5.833 0 10.563-4.78 10.563-10.676s-4.73-10.675-10.563-10.675c-5.834 0-10.563 4.779-10.563 10.675 0 5.896 4.729 10.676 10.563 10.676z" fill="#FF4000" />
                            <path d="M550.09 539.99c3.333 0 6.036-2.732 6.036-6.101 0-3.369-2.703-6.1-6.036-6.1-3.334 0-6.036 2.731-6.036 6.1 0 3.369 2.702 6.101 6.036 6.101z" fill="#F11D12" />
                        </g>
                        <g class="s-fact-slider-radar__point s-fact-slider-radar__point_7">
                            <switch>
                                <foreignObject
                                    class="s-fact-slider-radar__text"
                                    x="510"
                                    y="448"
                                    width="300"
                                    height="1176"
                                >
                                    <span>{{ radarText[6] }}</span>
                                </foreignObject>
                                <text class="s-fact-slider-radar__text" x="545" y="465">
                                    <tspan>{{ radarText[6] }}</tspan>
                                </text>
                            </switch>
                            <path opacity=".08" d="M659.09 450.007c8.334 0 15.09-6.828 15.09-15.251 0-8.422-6.756-15.25-15.09-15.25S644 426.334 644 434.756c0 8.423 6.756 15.251 15.09 15.251z" fill="#FF842D" />
                            <path opacity=".15" d="M659.089 445.431c5.834 0 10.563-4.78 10.563-10.676 0-5.895-4.729-10.675-10.563-10.675-5.833 0-10.563 4.78-10.563 10.675 0 5.896 4.73 10.676 10.563 10.676z" fill="#FF4000" />
                            <path d="M659.09 440.859c3.333 0 6.036-2.732 6.036-6.101 0-3.369-2.703-6.1-6.036-6.1-3.334 0-6.036 2.731-6.036 6.1 0 3.369 2.702 6.101 6.036 6.101z" fill="#F11D12" />
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SFactSlider',

    components: {
        ASlider,
        ASlide,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        sliderOptions: {
            type: Object,
            default: () => ({}),
        },

        sliderItems: {
            type: Array,
            required: true,
        },

        radarText: {
            type: Array,
            required: true,
        },

        /**
         * background color
         */
        background: {
            type: String,
            default: 'light-blue',
        },
    },

    data() {
        return {
            sliceSliderOptions: {
                showNavigation: true,
                ...this.sliderOptions,
            },
        };
    },
};
</script>

<style lang="postcss" scoped>
.a-slice-header {
    margin-bottom: 40px;

    @media (--viewport-desktop) {
        width: calc(((100% - 32px) / 3) * 2 + 16px);
    }

    &__title {
        @mixin display;

        color: var(--av-nav-primary);

        @media (--viewport-mobile-wide) {
            @mixin hero;
        }

        &:only-child {
            margin-bottom: 0;
        }
    }

    &__lead {
        @mixin lead;
        margin-top: 24px;
        color: var(--av-nav-primary);

        @media (--viewport-mobile-wide) {
            @mixin title;
        }
    }

    &__body-text {
        margin-top: 24px;
    }
}

.s-fact-slider {
    overflow: hidden;
    @mixin basic-slice-paddings;

    .a-slice-header__title {
        width: 100%;

        @media (--viewport-desktop) {
            width: 41.66%;
            padding-inline-end: 16px;
        }

        @media (--viewport-desktop-wide) {
            margin-bottom: 16px;
        }

        @media (--viewport-desktop-large) {
            margin-bottom: 24px;
        }
    }

    &__wrapper {
        @media (--viewport-mobile-wide) {
            display: flex;
        }
    }

    .a-slice-header__lead {
        margin-bottom: 380px;

        @media (--viewport-mobile-wide) {
            margin-bottom: 48px;
        }

        @media (--viewport-desktop-wide) {
            margin-bottom: 40px;
        }
    }

    &__content {
        @media (--viewport-mobile-wide) {
            width: 41.66%;
            padding-inline-end: 16px;
        }
    }

    &__slider {
        @media (--viewport-desktop-wide) {
            width: 80%;
        }
    }

    &-item {
        &__number {
            margin-bottom: 16px;
            font-size: 12px;
            line-height: 24px;
            text-transform: uppercase;
            color: var(--av-fixed-lighter);
        }

        &__percent {
            margin-bottom: 8px;
            font-size: 32px;
            font-weight: 700;
            line-height: 32px;
            color: var(--av-brand-secondary);

            @media (--viewport-mobile-wide) {
                margin-bottom: 0;
            }

            @media (--viewport-desktop-wide) {
                margin-bottom: 8px;
            }
        }

        &__desc {
            @mixin paragraph;

            color: rgba(var(--av-nav-primary), 0.9);
        }
    }

    &:deep(.a-slider__nav) {
        margin-top: 40px;
    }

    &__radar-container {
        position: absolute;
        top: 34px;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        z-index: -1;

        @media (--viewport-mobile-wide) {
            position: relative;
            top: -203px;
            inset-inline-start: 10px;
            transform: none;
            width: 58.33%;
        }

        @media (--viewport-desktop) {
            top: -447px;
            inset-inline-start: -32px;
        }

        @media (--viewport-desktop-wide) {
            top: -466px;
            inset-inline-start: 57px;
        }
    }

    &-radar {
        position: absolute;
        top: 0;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        width: 716px;
        height: 716px;

        @media (--viewport-mobile-wide) {
            width: 760px;
            height: 760px;
        }

        @media (--viewport-desktop) {
            width: 1169px;
            height: 1172px;
        }

        @media (--viewport-desktop-large) {
            width: 1176px;
            height: 1179px;
        }

        &__peleng-marker {
            transform-origin: 50% 50%;
            animation: rotation 10s linear infinite;
        }

        &__point {
            opacity: 0;
            animation: fade-in 10s ease-out infinite;

            &_1 {
                animation-delay: 2s;
            }

            &_2 {
                animation-delay: 3.1s;
            }

            &_3 {
                animation-delay: 4.7s;
            }

            &_4 {
                animation-delay: 6.2s;
            }

            &_5 {
                animation-delay: 7.8s;
            }

            &_6 {
                animation-delay: 7.9s;
            }

            &__text {
                fill: var(--av-nav-primary);
                color: var(--av-nav-primary);
                font-size: 13.5px;
                font-weight: 700;
                text-align: center;
            }
        }

        &__text {
            fill: var(--av-nav-primary);
            color: var(--av-nav-primary);
            font-size: 14px;
            font-weight: 700;
            text-align: center;
        }
    }
}

@keyframes rotation {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fade-in {
    10% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}
</style>
