<template>
    <svg xmlns="http://www.w3.org/2000/svg" :class="className">
        <title v-if="title">{{ title }}</title>
        <use :href="`#${name}`" xmlns:xlink="http://www.w3.org/1999/xlink" />
    </svg>
</template>

<script>
export default {
    name: 'ASvgSprite',

    props: {
        name: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            default: undefined,
        },

        className: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
