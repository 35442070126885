<template>
    <div class="menu-tab-company">
        <menu-tab v-bind="$props" />
    </div>
</template>

<script lang="ts">
import MenuTabMixin from './menu-tab-mixin.vue';

export default {
    name: 'MenuTabSolutions',
    mixins: [MenuTabMixin],
};
</script>

<style lang="postcss" scoped>
.menu-tab-company {
    .s-menu-tab_centered {
        @media (--viewport-desktop) {
            &:deep(.s-menu-tab__body) {
                width: 748px;
            }

            &:deep(.s-menu-tab__section) {
                &:first-child {
                    flex-shrink: 0;
                }

                &.s-menu-tab__section_submenu {
                    min-width: 324px;
                }
            }
        }
    }

    &:deep(.s-menu-tab__body) {
        @media (--viewport-desktop) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media (--viewport-desktop-wide) {
            grid-column-gap: 18px;
        }
    }

    &:deep(.s-menu-tab__section) {
        &:nth-child(4) {
            @media (--viewport-tablet) {
                grid-column: span 3;
            }

            @media (--viewport-desktop) {
                grid-column: auto;
                max-width: 164px;
            }

            @media (--viewport-desktop-wide) {
                max-width: 204px;
            }

            @media (--viewport-desktop-large) {
                max-width: 260px;
            }
        }
    }
}
</style>
