<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { SOLUTIONS_RESOURCE_URL } from '@model/const/solutions';
import { HttpRejection } from '@model/http/rejection';
import { getOgImage } from '@utils/html-meta';
import IntegrationComponent from './component.vue';

export default Vue.extend({
    name: 'IntegrationContainer',

    async serverPrefetch(): void {
        const { slug, locale } = this.$route.params;

        if (locale !== LOCALE_DEFAULT) {
            throw new HttpRejection(`Premature rendering stop: '${locale}' not found`, StatusCodes.NOT_FOUND);
        }

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 'solutions-acronis-com-home', locale: LOCALE_DEFAULT }),
            this.$store.dispatch('solutions/getIntegrations'),
            this.$store.dispatch('solutions/getIntegration', { slug }),
            this.$store.dispatch('solutions/getCategories'),
        ]);

        const integration = this.$store.state.solutions.integration;

        if (!integration) {
            throw new HttpRejection(`Premature rendering stop: '${slug}' not found`, StatusCodes.NOT_FOUND);
        }

        // Building page meta
        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
        pageSettings() {
            return this.$store.state.slices.items['solutions-acronis-com-home'];
        },
    },

    methods: {
        getMeta(): any {
            const integration = this.$store.state.solutions.integration;

            const ogimage = integration.logo
                ? `${SOLUTIONS_RESOURCE_URL}${integration.logo}`
                : getOgImage(this.pageSettings.pageMeta.image, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);

            const title = integration.name || this.pageSettings.pageMeta.title;
            const description = integration.short_description || this.pageSettings.pageMeta.description;
            const canonical = `https://${this.$config.domain}${this.$route.path}`;

            const meta = {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogimage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogimage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'meta', name: 'keywords', content: this.pageSettings.pageMeta.keywords },
                    { tag: 'link', rel: 'image_src', href: ogimage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                ],
                htmlAttrs: {
                    dir: 'ltr',
                    lang: 'en',
                },
            };

            return meta;
        },
    },

    render(h) {
        return h(IntegrationComponent);
    },
});
</script>
