/* eslint-disable max-len */
export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=57964-1880&t=JMJ2fbzoLIVoGwai-4';

export const ValueCard = {
    args: {
        translations: {
            title: 'Cards Highlight + Value Card',
            lead: 'Convert customers and reap commission rewards: our globally available selection of consumer and corporate products makes it easy.',
            addText: `
                <p>Have questions? <a href="#">Contact us</a></p>
                <p>Already Acronis user? <a href="#">Login now</a></p>
            `,
            valueCard: {
                title: 'Start Growing Your Business with Acronis',
                text: 'Apply for the Acronis Cyber Partner Program and start earning money with Acronis.',
                buttonText: 'Join Program',
            },
        },
        centered: false,
        type: '0',
        valueCard: {
            icon: '131',
            to: '#',
            type: 'action',
        },
        background: 'light-blue',
    },
};

export const ProductCard = {
    args: {
        translations: {
            title: 'Cards Highlight + Product Card + List',
            lead: 'Technical documentation',
            productCard: {
                title: 'Live demo webinar',
                text: 'Join our regular demo sessions to learn how you and your team can leverage the Acronis Cyber Cloud solution.',
                learnLink: { text: 'Learn More' },
                tryLink: { text: 'Register for the webinar' },
            },
            list: [
                { text: 'Acronis Cyber Cloud - Partner Guide' },
                { text: 'Acronis Cyber Protect Cloud - Cyber Protection Guide' },
                { text: 'Acronis Cyber Cloud - Management Portal Administrator Guide' },
            ],
        },
        centered: false,
        type: '1',
        productCard: {
            learnLink: { to: '#try' },
            tryLink: { to: '#learn' },
        },
        list: [
            { to: '#list1' },
            { to: '#list2' },
            { glyph: 'download-arrow', to: '#list3' },
        ],
        background: 'light-blue',
    },
};

export const SmallCards = {
    args: {
        translations: {
            title: 'Cards Highlight + Small Cards',
            lead: 'Convert customers and reap commission rewards: our globally available selection of consumer and corporate products makes it easy.',
            addText: `
                <p>Have questions? <a href="#">Contact us</a></p>
                <p>Already Acronis user? <a href="#">Login now</a></p>
            `,
            smallCardArr: [
                {
                    title: 'Sell Products',
                    text: 'Apply for a new reseller partnership and start earning money with Acronis',
                },
                {
                    title: 'Sell Services',
                    text: 'Acronis resellers can add cloud services to their portfolio.',
                },
            ],
        },
        centered: false,
        type: '0',
        smallCardArr: [
            {
                to: '#try',
                icon: '76',
                iconSmall: true,
            },
            {
                to: '#learn',
                icon: '131',
                iconSmall: true,
            },
        ],
        background: 'light-blue',
    },
};
