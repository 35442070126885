/* eslint-disable max-len */
const USER_AVATAR = '/images/intro/quote_author';
const LOGO = '/images/partner_logos/mono/heg';
const LOGO_2 = '/images/partner_logos/mono/aws';
const LOGO_3 = '/images/partner_logos/mono/cdw';
const LOGO_4 = '/images/partner_logos/mono/comparex';
const REVIEW = {
    title: 'Finally, data security I can trust and afford',
    text: 'Acronis is used primarily as a backup solution. However, from a single console, we are also able to offer disaster recovery and Microsoft 365 backup to new and existing clients. In additional to protecting numerous platforms with a single product and single agent, there were several other key benefits of working with Acronis. First, we were able to recover machines remotely straight from the console. Acronis is used primarily as a backup solution. However, from a single console, we are also able to offer disaster recovery and Microsoft 365 backup to new and existing clients. In additional to protecting numerous platforms with a single product and single agent, there were several other key benefits of working with Acronis. First, we were able to recover machines remotely straight from the console. Acronis is used primarily as a backup solution. However, from a single console, we are also able to offer disaster recovery and Microsoft 365 backup to new and existing clients. In additional to protecting numerous platforms with a single product and single agent, there were several other key benefits of working with Acronis. First, we were able to recover machines remotely straight from the console. Acronis is used primarily as a backup solution. However, from a single console, we are also able to offer disaster recovery and Microsoft 365 backup to new and existing clients. In additional to protecting numerous platforms with a single product and single agent, there were several other key benefits of working with Acronis. First, we were able to recover machines remotely straight from the console.',
    author: 'Andrés F. Ambrosi Chica',
};

const ARG_DEFAULTS = {
    background: 'white',
    type: 'personal',
    infinite: false,
};

export const Figma = 'https://www.figma.com/file/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?type=design&node-id=27959-11100';

export const PersonalCustomizeCta = {
    name: 'Personal: customize CTA',
    args: {
        translations: {
            title: 'Product reviews',
            subheader: 'Acronis solutions are trusted by thousands of service providers worldwide. See what they have to say.',
            averageRatingText: 'Average rating, out of 5',
            showMoreText: 'Custom Read More text',
            showLessText: 'Custom Read Less text',
            reviews: [
                { ...REVIEW, role: 'Head o IT, Scuderia Torro Rosso', cta: { text: 'Custom CTA text' } },
                { ...REVIEW, role: 'Head o IT, Scuderia Ferrari' },
                { ...REVIEW, role: 'Head o IT, Alpine', cta: { text: 'Custom CTA text' } },
                { ...REVIEW, role: 'Head o IT, AMG Mercedes Petronas' },
            ],
        },
        ...ARG_DEFAULTS,
        type: 'personal',
        reviews: [
            { image: USER_AVATAR, rate: 3.5, cta: { link: 'https://acronis.com' } },
            { image: USER_AVATAR, rate: 4 },
            { image: USER_AVATAR, rate: 1, cta: { link: 'https://acronis.com' } },
            { image: USER_AVATAR, rate: 2.7 },
        ],
    },
};

export const PersonalPhotoLogoRating = {
    name: 'Personal: photo + logo + rating',
    args: {
        translations: {
            title: 'Product reviews',
            subheader: 'Acronis solutions are trusted by thousands of service providers worldwide. See what they have to say.',
            averageRatingText: 'Average rating, out of 5',
            allReviewsLink: { text: 'Read all reviews' },
            reviews: Array.from({ length: 4 }).map(() => ({
                ...REVIEW,
                role: 'IT security director',
                companyName: 'HEG GmbH',
            })),
        },
        ...ARG_DEFAULTS,
        type: 'personal',
        allReviewsLink: { to: '#' },
        reviews: [
            { image: USER_AVATAR, rate: 3.5, logo: LOGO },
            { image: USER_AVATAR, rate: 4.8, logo: LOGO_2 },
            { image: USER_AVATAR, rate: 1.2, logo: LOGO_3 },
            { image: USER_AVATAR, rate: 3, logo: LOGO_4 },
        ],
    },
};

export const PersonalPhotoLogo = {
    name: 'Personal: photo + logo',
    args: {
        translations: {
            title: 'Product reviews',
            subheader: 'Acronis solutions are trusted by thousands of service providers worldwide. See what they have to say.',
            averageRatingText: 'Average rating, out of 5',
            allReviewsLink: { text: 'Read all reviews' },
            reviews: Array.from({ length: 4 }).map(() => ({
                ...REVIEW,
                role: 'IT security director',
            })),
        },
        ...ARG_DEFAULTS,
        allReviewsLink: { to: '#' },
        type: 'personal',
        reviews: Array.from({ length: 4 }).map(() => ({
            image: USER_AVATAR,
            logo: LOGO,
        })),
    },
};

export const PersonalPhoto = {
    name: 'Personal: photo',
    args: {
        translations: {
            title: 'Product reviews',
            subheader: 'Acronis solutions are trusted by thousands of service providers worldwide. See what they have to say.',
            averageRatingText: 'Average rating, out of 5',
            reviews: Array.from({ length: 4 }).map(() => ({
                ...REVIEW,
                role: 'IT security director',
                companyName: 'HEG GmbH',
            })),
        },
        ...ARG_DEFAULTS,
        type: 'personal',
        reviews: Array.from({ length: 4 }).map((_, i) => ({
            image: i === 1 ? null : USER_AVATAR,
        })),
    },
};

export const CompanyLogo = {
    name: 'Company: logo',
    args: {
        translations: {
            title: 'Product reviews',
            subheader: 'Acronis solutions are trusted by thousands of service providers worldwide. See what they have to say.',
            averageRatingText: 'Average rating, out of 5',
            reviews: [
                { ...REVIEW, role: 'Head o IT, Scuderia Torro Rosso' },
                { ...REVIEW, role: 'Head o IT, Scuderia Ferrari' },
                { ...REVIEW, role: 'Head o IT, Alpine' },
                { ...REVIEW, role: 'Head o IT, AMG Mercedes Petronas' },
            ],
        },
        ...ARG_DEFAULTS,
        type: 'company',
        reviews: Array.from({ length: 4 }).map(() => ({
            logo: LOGO,
        })),
    },
};

export const CompanyRating = {
    name: 'Company: logo + rating',
    args: {
        translations: {
            title: 'Product reviews',
            subheader: 'Acronis solutions are trusted by thousands of service providers worldwide. See what they have to say.',
            averageRatingText: 'Average rating, out of 5',
            reviews: [
                { ...REVIEW, role: 'Head o IT, Scuderia Torro Rosso' },
                { ...REVIEW, role: 'Head o IT, Scuderia Ferrari' },
                { ...REVIEW, role: 'Head o IT, Alpine' },
                { ...REVIEW, role: 'Head o IT, AMG Mercedes Petronas' },
            ],
        },
        ...ARG_DEFAULTS,
        type: 'company',
        reviews: [
            { logo: LOGO, rate: 3.5 },
            { logo: LOGO, rate: 4 },
            { logo: LOGO, rate: 1 },
            { logo: LOGO, rate: 2.7 },
        ],
    },
};
