<template>
    <div class="a-technology-card">
        <div class="a-technology-card__category">
            {{ category }}
        </div>
        <h3 class="a-technology-card__title">
            <a-link
                v-if="to"
                :to="to"
                :text="title"
                :size="size || 'title'"
                :target="target"
            />
            <template v-else>
                {{ title }}
            </template>
        </h3>
        <div class="a-technology-card__text">
            {{ text }}
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';

export default {
    name: 'ATechnologyCard',

    components: {
        ALink,
    },

    props: {
        category: {
            type: String,
            required: true,
        },

        to: {
            type: String,
            default: undefined,
        },

        title: {
            type: String,
            required: true,
        },

        text: {
            type: String,
            required: true,
        },

        size: {
            type: String,
            default: undefined,
        },

        target: {
            type: String,
            default: undefined,
        },
    },
};
</script>

<style lang="postcss">
.a-technology-card {
    display: block;
    text-decoration: none;

    &__category {
        @mixin caption;

        color: var(--av-fixed-secondary);
        font-weight: 600;
        margin-bottom: 4px;
    }

    &__title {
        @mixin title-accent;
        margin-bottom: 16px;
        color: var(--av-nav-primary);
    }

    &__text {
        color: var(--av-fixed-primary);
    }
}
</style>
