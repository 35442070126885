<template>
    <div class="s-slice header-two-cards" :class="theme">
        <a-picture
            v-if="background"
            :background="background"
            fit="cover"
            is-background
        />
        <div class="header">
            <a-breadcrumbs
                v-if="breadcrumbs"
                :theme="theme"
                :sublinks="true"
                :links="breadcrumbs"
            />
            <div class="a-container">
                <div class="header-inner">
                    <div class="header-cell main">
                        <div class="header-col">
                            <component
                                :is="isHeaderTag ? 'h1' : 'div'"
                                class="title"
                                :style="isDesktop ? `font-size:${propToCss(title.size)}; line-height: ${propToCss(title.height)};` : false"
                            >
                                {{ title.text }}
                            </component>
                            <div v-if="featureMain" class="feature-main">
                                <a-picture class="feature-image" :link="featureMain.image" />
                                <div class="feature-main-text-content">
                                    <div class="feature-title">
                                        {{ featureMain.title }}
                                    </div>
                                    <div class="feature-text">
                                        {{ featureMain.text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="(product) in products"
                        :key="product.productId"
                        class="header-cell"
                    >
                        <a-product-card
                            v-bind="product"
                            :is-single-line-c-t-a-buttons="isSingleLineCTAButtons"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ABreadcrumbs from '@core/components/breadcrumbs/breadcrumbs.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import purchasing from '@core/mixins/purchasing.js';
import AProductCard from '@core/slices/pages/header-lp-two-products/components/product-card.vue';

export default {
    name: 'SHeaderTwoCards',
    components: {
        ABreadcrumbs,
        APicture,
        AProductCard,
    },
    mixins: [breakpoint, purchasing],
    props: {
        breadcrumbs: {
            type: Array,
            default: () => [],
        },
        isHeaderTag: {
            type: Boolean,
            default: true,
        },
        isSingleLineCTAButtons: {
            type: Boolean,
            default: false,
        },
        featureMain: {
            type: Object,
            default: null,
        },
        products: {
            type: Array,
            required: true,
        },
        title: {
            type: Object,
            required: true,
        },
        background: {
            type: Object,
            required: false,
            default: undefined,
        },
        theme: {
            type: String,
            validator: (prop) => ['light', 'dark'].includes(prop),
            default: 'dark',
        },
    },
    data() {
        return {
            EVENT_TIMEOUT: 10 * 1000,
        };
    },
    mounted() {
        setTimeout(() => {
            this.sendDataLayer({
                eventCategory: 'Timing',
                eventContext: 'timing',
                eventAction: this.$route.path,
                eventLabel: '10sec',
            });
        }, this.EVENT_TIMEOUT);
    },
    methods: {
        sendDataLayer(data) {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                event: 'Acronis',
                ...data,
            });
        },

        propToCss(prop) {
            if (!prop) return false;

            return `${prop}px !important`;
        },
    },

    async serverPrefetch() {
        await this.initSettingsCTA(this.products);
    },
};
</script>

<style lang="postcss" scoped>
.header-two-cards {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid var(--av-brand-secondary-accent);
    position: relative;
    color: var(--av-nav-primary);

    &.dark {
        color: var(--av-inversed-primary);

        .feature-text {
            color: var(--av-inversed-light);
        }
    }
}

.a-breadcrumbs {
    margin-bottom: 24px;
    background: none;
}

.header {
    padding: 80px 0 48px;
    background: linear-gradient(
        134.26deg,
        #193a83 1.43%,
        #143578 20.95%,
        #0e2f6d 40.47%,
        #082a63 59.98%,
        #042558 79.5%,
        #00204e 99.02%
    );

    @media (--viewport-desktop) {
        padding-top: 88px;
    }
}

.header-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (--viewport-desktop-wide) {
        flex-wrap: nowrap;
    }
}

.header-cell {
    width: 100%;
    margin-bottom: 16px;

    &.main {
        margin-bottom: 24px;
    }

    @media (--viewport-tablet) {
        margin-bottom: 0;
        @mixin colls 6;

        &.main {
            @mixin colls 12;
            margin-bottom: 32px;
        }
    }

    @media (--viewport-desktop-wide) {
        @mixin colls 4;

        &.main {
            @mixin colls 4;
            margin-bottom: 0;
        }
    }
}

.header-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    @media (--viewport-tablet) {
        flex-direction: row;
        align-items: center;
    }
    @media (--viewport-desktop-wide) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
}

.logo-wrap {
    margin-bottom: 48px;
    @media (--viewport-tablet) {
        margin-bottom: 40px;
    }
}

.logo {
    width: 118.49px;
    height: 24.06px;

    @media (--viewport-desktop) {
        width: 158px;
        height: 32px;
    }
}

.title {
    @mixin title-accent;

    @media (--viewport-tablet) {
        @mixin display-accent;
        display: inline-block;
        padding-inline-end: 16px;
    }
    @media (--viewport-desktop) {
        @mixin hero-accent;
    }
    @media (--viewport-desktop-wide) {
        display: block;
        padding-inline-end: 0;
        margin-bottom: 64px;
        width: 100%;
    }
}

.feature-title {
    @mixin lead-accent;
}

.feature-image {
    width: 56px;
    height: 56px;
    margin-top: 4px;
}

.feature-main-text-content {
    margin-inline-start: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (--viewport-tablet) {
        margin-inline-start: 0;
        margin-top: 16px;
    }
    @media (--viewport-desktop) {
        margin-inline-start: 16px;
        margin-top: 0;
    }
}

.feature-main {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: center;
    margin-top: 16px;
    flex-shrink: 0;

    @media (--viewport-tablet) {
        @mixin colls 4;
        margin: 0;
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
    }
    @media (--viewport-desktop) {
        @mixin colls 3;
        flex-direction: row;
    }
    @media (--viewport-desktop-wide) {
        width: auto;
        display: flex;
        align-items: unset;
    }
}

.table {
    margin-bottom: 40px;
}

</style>
