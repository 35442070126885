<template>
    <div class="press-releases-release-card">
        <div class="publish-date">
            {{ publishDate }}
        </div>
        <dynamic-link class="release-link" :to="getUrl(slug)">
            <h2>{{ title }}</h2>
        </dynamic-link>
    </div>
</template>

<script lang="ts">
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { formatToLocaleDate } from '@utils/locales';

export default {
    name: 'PressReleasesReleaseCard',

    components: {
        DynamicLink,
    },

    props: {
        title: {
            type: String,
            required: true,
        },
        publishedAt: {
            type: String,
            required: true,
        },
        slug: {
            type: String,
            required: true,
        },
    },

    computed: {
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },

        publishDate() {
            return formatToLocaleDate(this.publishedAt, this.locale);
        },

        year() {
            return this.publishedAt.split('-')[0];
        },
    },

    methods: {
        getUrl(slug) {
            return `/pr/${this.year}/${slug}/`;
        },
    },
};
</script>

<style lang="postcss" scoped>
.publish-date {
    @mixin caption;
    margin-bottom: 8px;
    color: var(--av-fixed-primary);
}

.release-link {
    @mixin paragraph;
    color: var(--av-brand-primary);

    &:hover {
        color: var(--av-brand-secondary);
    }

    &:active {
        color: var(--av-brand-secondary);
    }
}
</style>
