export default [
    {
        name: 'Deutsch',
        locale: 'de-de',
    },
    {
        name: 'English',
        locale: 'en-us',
    },
    {
        name: 'Español',
        locale: 'es-es',
    },
    {
        name: 'Français',
        locale: 'fr-fr',
    },
    {
        name: 'Italiano',
        locale: 'it-it',
    },
    {
        name: '日本語',
        locale: 'ja-jp',
    },
];
