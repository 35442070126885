<template>
    <div class="express-signup-sales-infographic__wrapper">
        <a-picture
            class="express-signup-sales-infographic__wrapper__bg"
            :link="data.bgImage"
            is-background
            fit="contain"
            position="right"
        />
        <div class="express-signup-sales-infographic__wrapper__timeline">
            <div class="express-signup-sales-infographic__wrapper__timeline__line" />
            <svg
                class="express-signup-sales-infographic__wrapper__timeline__arrow"
                width="22"
                height="25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <!-- eslint-disable max-len -->
                <path d="M20.426 9.76c1.95 1.126 1.95 3.94 0 5.065L5.074 23.69C3.125 24.814.688 23.407.688 21.156V3.43c0-2.25 2.437-3.657 4.386-2.532L20.426 9.76Z" fill="#fff" />
                <path
                    d="M19.695 13.56 4.343 22.421a1.462 1.462 0 0 1-2.193-1.266V3.43a1.462 1.462 0 0 1 2.193-1.266l15.352 8.864c.975.562.975 1.97 0 2.532Z"
                    stroke="#1553AB"
                    stroke-opacity=".8"
                    stroke-width="2.924"
                />
                <!-- eslint-enable max-len -->
            </svg>
        </div>
        <div v-if="data.sections.advancedPayment" class="express-signup-sales-infographic__wrapper__first">
            <div
                v-if="data.sections.advancedPayment.text"
                class="express-signup-sales-infographic__wrapper__first__text"
                v-html="replacePrice(data.sections.advancedPayment.text)"
            />
            <div
                v-if="data.sections.advancedPayment.label"
                class="express-signup-sales-infographic__wrapper__first__label"
            >
                {{ data.sections.advancedPayment.label }}
            </div>
            <div
                v-if="data.sections.advancedPayment.caption"
                class="express-signup-sales-infographic__wrapper__first__caption"
                v-html="data.sections.advancedPayment.caption"
            />
        </div>
        <div v-if="data.sections.middle" class="express-signup-sales-infographic__wrapper__middle">
            <div class="express-signup-sales-infographic__wrapper__middle__badges">
                <div
                    v-if="data.sections.middle.lineText"
                    class="express-signup-sales-infographic__wrapper__middle__badges__line"
                >
                    {{ data.sections.middle.lineText }}
                </div>
            </div>
            <div v-if="data.sections.middle.label" class="express-signup-sales-infographic__wrapper__middle__label">
                {{ data.sections.middle.label }}
            </div>
            <div
                v-if="data.sections.middle.text"
                class="express-signup-sales-infographic__wrapper__middle__text"
                v-html="data.sections.middle.text"
            />
        </div>
        <div v-if="data.sections.nextPayment" class="express-signup-sales-infographic__wrapper__third">
            <div
                v-if="data.sections.nextPayment.text"
                class="express-signup-sales-infographic__wrapper__third__text"
                v-html="data.sections.nextPayment.text"
            />
            <div class="express-signup-sales-infographic__wrapper__third__glyph">
                <a-glyph :name="data.sections.nextPayment.glyph" />
            </div>
            <div
                v-if="data.sections.nextPayment.caption"
                class="express-signup-sales-infographic__wrapper__third__caption"
                v-html="replacePrice(data.sections.nextPayment.caption)"
            />
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'ExpressSignupPaymentInfographic',
    components: {
        APicture,
        AGlyph,
    },
    props: {
        data: {
            type: Object,
            required: false,
            default: undefined,
        },
        formattedPrice: {
            type: String,
            default: null,
        },
    },
    methods: {
        replacePrice(str) {
            if (!str || typeof str !== 'string') return false;
            if (!this.formattedPrice) return false;

            return str.replace('XXX', this.formattedPrice);
        },
    },
};
</script>

<style lang="postcss" scoped>
.express-signup-sales-infographic__wrapper {
    @mixin colls 12;
    position: relative;
    color: var(--av-nav-primary);
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    @media (--viewport-tablet) {
        min-height: 196px;
        max-width: 910px;
    }
    &__bg {
        display: none;
        user-select: none;
        pointer-events: none;
        &:deep(img) {
            z-index: 1;
            object-fit: none !important;
            object-position: center center !important;
        }
        @media (--viewport-tablet) {
            display: block;
        }
    }
    &__timeline {
        &__line {
            position: absolute;
            width: 3px;
            top: 15px;
            bottom: 0;
            height: auto;
            inset-inline-end: 40px;
            z-index: 2;
            background-image: linear-gradient(
                180deg,
                rgba(64, 139, 234, 0.5) 0%,
                rgba(64, 139, 234, 0.5) 86%,
                rgba(68, 118, 188, 0.8) 97%,
                rgba(68, 118, 188, 0.8) 100%
            );
            border-radius: 11px;
            @media (--viewport-mobile-wide) {
                top: 60px;
            }
            @media (--viewport-tablet) {
                top: 50%;
                width: 100%;
                inset-inline-end: 0;
                inset-inline-start: 0;
                height: 3px;
                transform: translateY(-50%);
                background-image: linear-gradient(
                    90deg,
                    rgba(64, 139, 234, 0.5) 0%,
                    rgba(64, 139, 234, 0.5) 93%,
                    rgba(68, 118, 188, 0.8) 97%,
                    rgba(68, 118, 188, 0.8) 100%
                );
            }
        }
        &__arrow {
            position: absolute;
            transform: rotate(90deg);
            inset-inline-end: 30px;
            top: 99%;
            z-index: 3;
            @media (--viewport-tablet) {
                top: 50%;
                inset-inline-end: 0;
                transform: translateY(-50%);
            }
        }
    }
    &__first,
    &__third {
        &__text {
            @mixin paragraph;
            width: 67%;
            @media (--viewport-tablet) {
                @mixin body;
                width: auto;
            }
            @media (--viewport-desktop) {
                @mixin paragraph;
            }
        }
        &__caption {
            @mixin caption;
            width: 67%;
            @media (--viewport-tablet) {
                position: absolute;
                width: 100%;
            }
        }
    }
    &__first {
        width: 100%;
        position: relative;
        z-index: 10;
        text-align: start;
        @media (--viewport-tablet) {
            position: absolute;
            height: 100%;
            width: 35%;
            max-width: 230px;
            z-index: 10;
        }
        &__text {
            @media (--viewport-tablet) {
                width: 200%;
            }
        }
        &__label {
            @mixin title;
            position: absolute;
            background: var(--av-nav-secondary);
            border: 2px solid var(--av-brand-secondary);
            border-radius: 26px;
            color: var(--av-inversed-primary);
            font-weight: 600;
            padding: 8px 16px;
            top: 15px;
            inset-inline-end: 0;

            @media (--viewport-tablet) {
                font-size: 20px;
                line-height: 30px;
                padding: 6px 16px;
                top: 50%;
                inset-inline-end: unset;
                transform: translateY(-50%);
            }
        }
        &__caption {
            margin-top: 16px;
            @media (--viewport-tablet) {
                margin: 0;
                max-width: 150px;
                top: calc(50% + 36px);
            }
        }
    }
    &__middle {
        width: 100%;
        margin-bottom: 48px;
        z-index: 10;
        position: relative;
        @media (--viewport-tablet) {
            position: absolute;
            height: 100%;
            max-width: calc(100% - 230px);
            margin: 0;
            inset-inline-start: 110px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
        }
        @media (--viewport-desktop) {
            inset-inline-start: 130px;
        }
        &__badges {
            margin: 40px auto;
            @media (--viewport-tablet) {
                margin: 0;
            }
            &__line {
                @mixin caption;
                padding: 8px 0;
                font-weight: 600;
                background: var(--av-inversed-primary);
                border: 1px solid var(--av-nav-secondary);
                color: var(--av-nav-primary);
                border-radius: 46px;
                @media (--viewport-mobile-wide) {
                    @mixin lead;
                }
                @media (--viewport-tablet) {
                    @mixin caption;
                    font-weight: 500;
                    padding: 4px 8px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    max-width: 180px;
                }
                @media all and (min-width: 860px) {
                    max-width: 240px;
                }
            }
        }
        &__text {
            @mixin paragraph;
            width: 60%;
            text-align: start;
            margin-bottom: 8px;
            @media (--viewport-tablet) {
                @mixin caption;
                position: absolute;
                width: auto;
                margin: 0;
                inset-inline-start: 190px;
                top: calc(50% + 59px);
                transform: translateY(-50%);
            }
            @media all and (min-width: 860px) {
                inset-inline-start: 280px;
            }
        }
        &__label {
            @mixin title;
            background: var(--av-nav-secondary);
            border: 2px solid var(--av-brand-secondary);
            border-radius: 26px;
            color: var(--av-inversed-primary);
            font-weight: 600;
            position: absolute;
            inset-inline-end: 0;
            bottom: 0;
            padding: 8px 16px;
            z-index: 10;

            @media (--viewport-tablet) {
                padding: 6px 16px;
                font-size: 20px;
                line-height: 30px;
                top: 50%;
                transform: translateY(-50%);
                inset-inline-start: 190px;
                bottom: unset;
                inset-inline-end: unset;
            }
            @media all and (min-width: 860px) {
                width: auto;
                inset-inline-start: 280px;
            }
        }
    }
    &__third {
        text-align: start;
        width: 100%;
        position: relative;
        z-index: 10;
        @media (--viewport-tablet) {
            position: absolute;
            height: 100%;
            inset-inline-end: 0;
            max-width: 200px;
        }
        @media (--viewport-desktop) {
            max-width: 230px;
        }
        &__glyph {
            position: absolute;
            top: 0;
            inset-inline-end: 15px;
            z-index: 10;
            background: var(--av-nav-secondary);
            border: 2px solid var(--av-brand-secondary);
            border-radius: 46px;
            padding: 14px;
            @media (--viewport-tablet) {
                border-radius: 26px;
                padding: 9px;
                top: 50%;
                inset-inline-start: 0;
                inset-inline-end: unset;
                transform: translateY(-50%);
            }
            &:deep(svg) {
                fill: var(--av-inversed-primary);
                width: 24px;
                height: 24px;
            }
        }
        &__caption {
            margin-top: 16px;
            width: 80%;
            @media (--viewport-mobile-wide) {
                width: 100%;
            }
            @media (--viewport-tablet) {
                margin: 0;
                top: calc(50% + 59px);
                transform: translateY(-50%);
            }
        }
    }
}
[dir="rtl"] {
    .express-signup-sales-infographic {
        &__wrapper {
            &__timeline__arrow {
                @media (--viewport-tablet) {
                    transform: rotate(180deg) translateY(50%);
                }
            }
            &__bg {
                &:deep(img) {
                    transform: rotateY(180deg);
                }
            }
        }
    }
}
</style>
