<template>
    <div class="blog-webinars">
        <div class="title-wrap">
            <div class="title">
                {{ title }}
            </div>
            <client-only>
                <a-link
                    class="link"
                    :to="link.to"
                    :text="link.title"
                    glyph="arrow"
                    size="lead"
                />
            </client-only>
        </div>
        <div class="items">
            <dynamic-link
                v-for="(item, i) in items"
                :key="i"
                class="item"
                :to="item.link"
                native-type="a"
            >
                <div class="item-title">
                    {{ item.title }}
                </div>
                <div class="item-date">
                    <template v-if="item.date">
                        <blog-date-localized :date="item.date" />
                    </template>
                    <template v-else-if="item.dateCaption">
                        {{ item.dateCaption }}
                    </template>
                </div>
                <div class="item-time">
                    {{ item.time }}
                </div>
                <div class="item-type">
                    {{ item.type }}
                </div>
            </dynamic-link>
        </div>
    </div>
</template>
<script>
import ClientOnly from '@core/components/client-only/client-only.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALink from '@core/components/link/link.vue';
import BlogDateLocalized from '../shared-components/blog-date-localized/blog-date-localized.vue';

export default {
    name: 'BlogWebinars',
    components: {
        ClientOnly,
        DynamicLink,
        ALink,
        BlogDateLocalized,
    },
    props: {
        title: {
            type: String,
            required: true,
        },

        link: {
            type: Object,
            required: true,
        },

        items: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-webinars {
    @mixin basic-slice-paddings;

    .title-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        @media (--viewport-tablet) {
            align-items: flex-start;
        }

        @media (--viewport-desktop) {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .title {
        @mixin display;
        color: var(--av-nav-primary);
        text-align: center;
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            @mixin hero;
            font-weight: 500;
            text-align: start;
            margin-bottom: 0;
        }
    }

    .link {
        @mixin lead-accent;

        color: var(--av-brand-secondary);
        margin-inline-start: 16px;
    }

    .items {
        @media (--viewport-tablet) {
            display: grid;
            column-gap: 16px;
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (--viewport-desktop-wide) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    .item {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        text-decoration: none;
        background: var(--av-inversed-primary);
        border: 1px solid var(--av-brand-secondary-light);
        box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
        border-radius: 4px;
        min-height: 204px;
        padding: 24px 16px 16px;
        margin-bottom: 16px;
        cursor: pointer;

        &:hover {
            .item-title {
                color: var(--av-brand-secondary);
            }
        }

        &:nth-child(4) {
            display: none;
        }

        @media (--viewport-tablet) {
            margin-bottom: 0;
        }

        @media (--viewport-desktop-wide) {
            &:nth-child(4) {
                display: flex;
            }
        }
    }

    .item-title {
        @mixin paragraph-accent;

        color: var(--av-nav-primary);
        margin-bottom: 8px;
    }

    .item-date {
        @mixin caption;

        font-weight: 500;
        margin-top: auto;
        margin-bottom: 4px;
        color: var(--av-fixed-secondary);
    }

    .item-time {
        @mixin caption;

        font-weight: bold;
        color: var(--av-fixed-light);
        margin-bottom: 24px;
    }

    .item-type {
        @mixin caption-accent;

        color: var(--av-brand-secondary);
    }
}
</style>
