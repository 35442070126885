<template>
    <section v-if="content" class="main-page-new__footer" :class="basicClassName">
        <div class="a-container">
            <div class="social">
                <div v-if="!isMobile" class="title">
                    {{ content.title }}
                </div>
                <a-social :social="socialLinks" />
            </div>
            <div class="content">
                <div class="item">
                    <dynamic-link class="logo" to="/" native-type="a">
                        <a-logo type="full" />
                    </dynamic-link>
                    <div v-if="copyright" class="copyright">
                        © 2003–{{ currentYear }} {{ copyright }}
                    </div>
                </div>
                <div class="legal">
                    <div v-if="legalLinks" class="links">
                        <a-link
                            v-for="(item, i) in legalLinks"
                            :key="i"
                            v-bind="item"
                            :type="item.type || 'dark'"
                            :size="item.size || 'paragraph'"
                        />
                    </div>
                    <div v-if="content.hasLocaleSelector" class="locale-selector">
                        <button class="button" @click="toggleLocaleSelector">
                            <a-glyph name="language-select" />
                            <span class="country">{{ language }}</span>
                        </button>
                        <s-locale-selector
                            :is-shown="isLocaleSelectorActive"
                            @close="toggleLocaleSelector"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';
import ASocial from '@core/components/social/social.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SLocaleSelector from '@core/slices/pages/locale-selector/locale-selector.vue';

export default {
    name: 'SMainPageFooter',
    components: {
        AGlyph,
        ALink,
        ALogo,
        ASocial,
        DynamicLink,
        SLocaleSelector,
    },
    mixins: [contentMixin, styleMixin, breakpoint],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isLocaleSelectorActive: false,
            STORE_SLICE_NAME: 's-global-footer',
            currentYear: new Date().getFullYear(),
        };
    },
    serverPrefetch() {
        const locale = this.$route.params.locale;
        this.$store.dispatch('slices/getSyncedData', { slice: this.STORE_SLICE_NAME, locale });
    },
    computed: {
        ...mapState({
            language: (state) => state.slices.items?.['s-main-header']?.lang || '',
            copyright: (state) => state.slices.items?.['s-global-footer']?.copyright || '',
            legalLinks: (state) => state.slices.items?.['s-global-footer']?.legalLinks || [],
            socialLinks: (state) => state.slices.items?.['s-global-footer']?.socialLinks || [],
        }),
    },
    methods: {
        toggleLocaleSelector() {
            this.isLocaleSelectorActive = !this.isLocaleSelectorActive;
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-new {
    &__footer {
        background: var(--av-brand-lightest);
        padding-top: 40px;
        padding-bottom: 40px;
        .social {
            display: flex;
            justify-content: flex-start;
            .title {
                @mixin paragraph;
                font-weight: 600;
                margin-inline-end: 40px;
                color: var(--av-nav-primary);
            }
            &:deep(.a-social) {
                margin: 0 -12px;
                &__link {
                    margin: 0 12px;
                    .a-glyph {
                        fill: var(--av-nav-primary);
                    }
                }
            }
        }
        .content {
            margin-top: 40px;
            padding-top: 40px;
            border-top: 1px solid var(--av-brand-light);
            .logo {
                &:deep(.a-logo) {
                    width: 110px;
                    height: 22px;
                    fill: var(--av-nav-primary);
                }
            }
            .copyright {
                @mixin body;
                margin-top: 8px;
                color: var(--av-fixed-secondary);
            }
        }
        .legal {
            margin-top: 16px;
            padding-top: 24px;
            border-top: 1px solid var(--av-brand-light);
            &:deep(.a-link) {
                position: relative;
                &:not(:first-child) {
                    margin-inline-start: 48px;
                    &:before {
                        top: 4px;
                        width: 1px;
                        content: '';
                        height: 16px;
                        position: absolute;
                        inset-inline-start: -22px;
                        background: var(--av-brand-light);
                    }
                }
                &__content {
                    @mixin body;
                    line-height: 24px;
                    color: var(--av-brand-primary);
                }
            }
        }
        .locale-selector {
            margin-top: 24px;
            .button {
                margin: 0;
                padding: 0;
                display: flex;
                border: none;
                cursor: pointer;
                background: none;
                align-items: center;
                .a-glyph {
                    fill: var(--av-nav-primary);
                    margin-inline-end: 8px;
                }
            }
            .country {
                @mixin body;
                color: var(--av-brand-primary);
            }
        }

        @media (--viewport-tablet) {
            .content {
                .item {
                    display: flex;
                    align-items: center;
                }
                .copyright {
                    margin-top: 0;
                    margin-inline-start: 40px;
                }
            }
            .legal {
                display: flex;
                align-items: center;
                margin-top: 24px;
            }
            .locale-selector {
                margin-top: 0;
                position: relative;
                margin-inline-start: 48px;
                &:before {
                    top: 4px;
                    width: 1px;
                    content: '';
                    height: 16px;
                    position: absolute;
                    inset-inline-start: -22px;
                    background: var(--av-brand-light);
                }
            }
        }

        @media (--viewport-desktop-wide) {
            .content {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .copyright {
                    margin-top: 0;
                    margin-inline-start: 40px;
                }
            }
            .legal {
                border: none;
                margin-top: 0;
                padding-top: 0;
                &:deep(.a-link) {
                    position: relative;
                    &:first-child {
                        margin-inline-start: 48px;
                        &:before {
                            top: 4px;
                            width: 1px;
                            content: '';
                            height: 16px;
                            position: absolute;
                            inset-inline-start: -22px;
                            background: var(--av-brand-light);
                        }
                    }
                }
            }
        }
    }
}
</style>
