<template>
    <div class="event-speakers" :class="className">
        <h2 v-if="title" class="title">
            {{ title }}
        </h2>
        <div v-if="lead" class="section-lead">
            {{ lead }}
        </div>
        <div class="speakers-list">
            <event-speaker
                v-for="(speaker, i) in speakersToShow"
                :key="i"
                v-bind="speaker"
                :layout="layout"
                :modal-id="`speaker${i}`"
            />
        </div>
    </div>
</template>

<script>
import EventSpeaker from '../shared-components/event-speaker/event-speaker.vue';

export default {
    name: 'EventSpeakers',

    components: {
        EventSpeaker,
    },

    props: {
        title: {
            type: String,
            default: null,
        },

        lead: {
            type: String,
            default: null,
        },

        speakers: {
            type: Array,
            required: true,
        },

        readMoreTitle: {
            type: String,
            required: false,
            default: 'Read more',
        },

        limit: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        layout() {
            if (this.speakers.length === 1) {
                return 'full';
            }
            if (this.speakers.length === 2) {
                return 'medium';
            }
            return 'short';
        },

        speakersToShow() {
            return this.limit > 0 ? [].concat(this.speakers).splice(0, this.limit) : this.speakers;
        },

        className() {
            return `layout-${this.layout}`;
        },
    },

    methods: {
        clampStateChange(i, isClamped) {
            this.isTextClampedForSpeaker[i] = isClamped;
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-speakers {
    &.layout-short {
        .speakers-list {
            grid-template-columns: repeat(2, minmax(0, 1fr));

            @media (--viewport-tablet) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            @media (--viewport-desktop) {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
        }
    }

    &.layout-medium {
        .speakers-list {
            @media (--viewport-desktop) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }
    }

    .speakers-list {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 16px;
        column-gap: 16px;
        margin-bottom: 32px;
    }
}
</style>
