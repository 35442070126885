<template>
    <div class="topnav-static">
        <div class="wrapper a-container">
            <div class="logo-wrapper">
                <a-dynamic-link
                    :to="logo.link"
                    :target="logo.target"
                    :rel="logo.rel"
                    :event="{ action: 'Acronis' }"
                >
                    <a-logo class="logo" type="full" fill="white" />
                </a-dynamic-link>
            </div>

            <nav class="menu-wrapper-desktop">
                <ul class="menu">
                    <li v-for="link of menuLinks" :key="link.link" class="menu-item">
                        <a-dynamic-link
                            class="link"
                            :class="{ active: isActiveLink(link.link) }"
                            :to="link.link"
                            :event="{ action: link.link }"
                        >
                            {{ link.text }}
                        </a-dynamic-link>
                    </li>
                </ul>
            </nav>

            <div class="menu-wrapper-mobile">
                <a-dynamic-link
                    class="login-link"
                    :to="login.link"
                    :target="login.target"
                    :event="{ action: login.link }"
                >
                    <a-glyph
                        class="glyph"
                        name="group"
                        fill="white"
                        size="s"
                    />
                </a-dynamic-link>

                <div class="vertical-separator">
                    &nbsp;
                </div>

                <div class="menu-burger" @click.stop="$emit('menu-toggle')">
                    <a-glyph
                        v-if="!menuIsOpened"
                        class="glyph"
                        name="burger-menu"
                        fill="white"
                        size="l"
                    />
                    <a-glyph
                        v-else
                        class="glyph"
                        name="close"
                        fill="white"
                        size="l"
                    />
                </div>
            </div>

            <div class="login-wrapper-desktop">
                <a-dynamic-link
                    class="link login"
                    :to="login.link"
                    :target="login.target"
                    :event="{ action: login.link }"
                >
                    <a-glyph class="glyph" name="group" fill="white" />
                    <span>{{ login.text }}</span>
                </a-dynamic-link>
            </div>
        </div>
    </div>
</template>

<script>
import ADynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALogo from '@core/components/logo/logo.vue';

export default {
    name: 'DeveloperTopnavStatic',

    components: {
        ADynamicLink,
        AGlyph,
        ALogo,
    },

    props: {
        logo: {
            type: Object,
            required: true,
        },

        menuLinks: {
            type: Array,
            required: true,
        },

        login: {
            type: Object,
            required: true,
        },

        mobileMenuTitle: {
            type: String,
            required: true,
        },

        contactUs: {
            type: Object,
            required: false,
            default: null,
        },

        menuIsOpened: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['menu-toggle'],

    methods: {
        isActiveLink(link) {
            const path = this.$route?.params?.pathMatch;

            if (!path && link === '/') return true;

            return path && link.includes(path);
        },
    },
};
</script>

<style lang="postcss" scoped>
.topnav-static {
    background: var(--av-nav-primary);
    position: relative;
    z-index: 3;
}

.bottom {
    padding-top: 44px;
}

.center {
    padding: 24px 0;

    @media (--viewport-desktop) {
        padding: 32px 0;
    }
}

.wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.login-wrapper-desktop {
    margin-inline-start: auto;
}

.menu-wrapper-desktop, .login-wrapper-desktop {
    display: none;
}
.menu-wrapper-mobile {
    display: flex;
}

@media (--viewport-desktop) {
    .menu-wrapper-desktop, .login-wrapper-desktop {
        display: block;
    }
    .menu-wrapper-mobile {
        display: none;
    }
}

.logo-wrapper {
    @media (--viewport-desktop) {
        margin-inline-end: 48px;
    }
}

.logo {
    height: 27px;
    width: 133px;

    @media (--viewport-desktop) {
        height: 32px;
        width: 158px;
    }
}

.link {
    @mixin paragraph-accent;
    color: var(--av-inversed-primary);

    &.active {
        color: var(--av-link-inversed-hover);
        pointer-events: none;
    }

    &:hover {
        color: var(--av-link-inversed-active);

        .a-glyph {
            color: var(--av-link-inversed-active);
        }
    }

    &.login {
        @mixin paragraph;
    }

    .a-glyph {
        transition: none;
    }
}

.login-link {
    line-height: 16px;
}

.login-wrapper-desktop svg, .login-wrapper-desktop span {
    vertical-align: middle;
}

.menu-wrapper-desktop .menu {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .menu-item + .menu-item {
        margin-inline-start: 24px;
    }
}

.menu-wrapper-mobile {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.menu-burger {
    color: var(--av-inversed-primary);
    cursor: pointer;
}

.vertical-separator {
    height: 24px;
    width: 1px;
    background: var(--av-fixed-info-dark);
    margin: 0 8px 0 12px;
}
</style>
