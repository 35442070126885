<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import structuredData from '@core/mixins/structuredData';
import { HttpRejection } from '@model/http/rejection';
import { assertHttpErrors } from '@utils/api-response';
import { getOgImage, getTextDirection, getHtmlLang } from '@utils/html-meta';
import { assertLocaleIsAllowed } from '@utils/resource-center';
import ResourceCenterResourceComponent from './component.vue';

export default Vue.extend({
    name: 'RcResourceContainer',

    async serverPrefetch(): Promise<void> {
        const locale = this.$route.params.locale;
        const slug = this.$route.params.slug;

        assertLocaleIsAllowed(this.$store, locale);

        // Redirecting old /resource/$ID format to new /resource/$SLUG
        if (slug.match(/^\d+$/)) {
            const slugRequest = new ProductAPIQueryBuilder('resource')
                .setEntityPath('/api/resources/resources')
                .setLocales([locale])
                .setCustomParam('process-macros', '1')
                .addMatchesAll('id', '=', slug)
                .setPaginate(1, 1)
                .toObject();

            await this.$store.dispatch('resourceCenter/getEntity', { request: slugRequest });
            const resourceState = this.$store.state.resourceCenter.resource;

            assertHttpErrors([{ entity: resourceState }]);

            const redirectURL = resourceState.items?.length
                ? `/${locale}/resource-center/resource/${resourceState.items[0].slug}/`
                : `/${locale}/resource-center/`;

            const rejection = new HttpRejection('Premature rendering stop', StatusCodes.MOVED_PERMANENTLY);
            rejection.addHeader('Location', redirectURL);
            throw rejection;
        }

        const resourceRequest = new ProductAPIQueryBuilder('resource')
            .setEntityPath('/api/resources/resources')
            .setLocales([locale])
            .addMatchesAll('slug', '=', slug)
            .setCustomParam('process-macros', '1')
            .setCustomParam('resource', this.previewKey ? { key: this.previewKey } : null)
            .setPaginate(1, 1)
            .toObject();

        await this.$store.dispatch('resourceCenter/getEntity', { request: resourceRequest });

        const resourceState = this.$store.state.resourceCenter.resource;

        assertHttpErrors([{ entity: resourceState, throwIfEmpty: true }]);

        // Get the rest of entities
        if (this.previewKey) {
            this.$ssrContext.res.headers.push({ key: 'Cache-Control', val: 'no-store' });
        }

        const resource = resourceState.items[0];

        const localesRequest = new ProductAPIQueryBuilder('locales')
            .setEntityPath('/api/gis/locales/')
            .addMatchesAll('is_active_on_resource_center', '=', '1')
            .toObject();

        const availableLocalesRequest = new ProductAPIQueryBuilder('availableLocales')
            .setEntityPath('/api/resources/resources/translations')
            .addMatchesAll('resource_id', '=', resource.id)
            .setPaginate(1, 20)
            .setOutputOnly(['locale'])
            .toObject();

        const seoPromises = [
            this.$store.dispatch('seo/getSyncedData', { key: 'routes-config', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-website', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-webpage', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-organization', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-contact-point', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-offer', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-postal-address', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-video-object', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-faq-page', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-breadcrumbs', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-job-posting', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-product', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-article', locale }),
        ];

        const requests = [
            this.$store.dispatch('slices/getSyncedData', { slice: 's-main-header', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-global-footer', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'resource-center', locale }),
            this.$store.dispatch('resourceCenter/getEntity', { request: localesRequest }),
            this.$store.dispatch('resourceCenter/getEntity', { request: availableLocalesRequest }),
            this.$store.dispatch('products/getProducts', locale),
            ...seoPromises,
        ];

        if (resource.call_to_action_id) {
            const callToActionRequest = new ProductAPIQueryBuilder('cta')
                .setEntityPath('/api/core/call-to-actions/')
                .addMatchesAll('id', '=', resource.call_to_action_id)
                .setPaginate(1, 1)
                .toObject();

            const callToActionDataRequest = new ProductAPIQueryBuilder('ctaData')
                .setEntityPath('/api/core/call-to-actions/translations/')
                .addMatchesAll('call_to_action_id', '=', resource.call_to_action_id)
                .addMatchesAll('locale', '=', locale)
                .setPaginate(1, 1)
                .toObject();

            requests.push(
                this.$store.dispatch('resourceCenter/getEntity', { request: callToActionRequest }),
                this.$store.dispatch('resourceCenter/getEntity', { request: callToActionDataRequest }),
            );
        }

        if (resource.is_gated) {
            requests.push(this.$store.dispatch('countries/getCountries'));
        }

        await Promise.all(requests);

        assertHttpErrors([
            { entity: this.$store.state.resourceCenter.locales, throwIfEmpty: true },
            { entity: this.$store.state.resourceCenter.availableLocales, throwIfEmpty: true },
            { entity: this.$store.state.resourceCenter.cta },
            { entity: this.$store.state.resourceCenter.ctaData },
        ]);

        // Building page meta
        this.$ssrContext.res.meta = await this.getMeta(resource);
    },

    mixins: [structuredData],

    computed: {
        ...mapGetters('config', ['$config']),
        ...mapGetters('seo', ['getRoutesConfig', 'getStaticConfigs']),
        previewKey() {
            return this.$route.query?.key;
        },
    },

    methods: {
        async getMeta(resource): any {
            const locale = this.$route.params.locale;
            const canonical = `https://${this.$config.domain}${this.$route.path}`;
            const uiStrings = this.$store.state.slices.items['resource-center'];

            const ogImage = getOgImage(`@${resource.cover_image_id}`, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = `${resource.seo_title} | ${uiStrings.homePageMetaTitle}`;
            const description = resource.seo_description;

            const ldJsonSchema = await this.getStructuredDataMarkup({
                siteID: this.$config.siteID,
                domain: this.$config.domain,
                schemasConfig: this.getStaticConfigs,
                routesConfig: this.getRoutesConfig,
            });

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', property: 'og:image', content: ogImage },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'meta', name: 'twitter:image', content: ogImage },
                    { tag: 'link', rel: 'image_src', href: ogImage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: getHtmlLang(locale),
                },
                ldJsonSchema,
            };
        },

        sendViewBeacon() {
            const request = new ProductAPIQueryBuilder('viewBeacon')
                .setEntityPath('/api/resources/resources/views/')
                .setCustomParam('resource_id', this.$store.state.resourceCenter.resource.items[0]?.id)
                .setCustomParam('locale', this.$route.params.locale)
                .toObject();

            this.$store.dispatch('resourceCenter/sendViewBeacon', { request });
        },
    },

    render(h) {
        const props = {
            dispatchSendViewBeacon: this.sendViewBeacon,
            isPreview: Boolean(this.previewKey),
        };
        return h(ResourceCenterResourceComponent, { props });
    },
});
</script>
