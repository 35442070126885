<template>
    <section :id="id" class="s-slice s-award-highlight" :class="basicClassName">
        <div class="a-container">
            <div class="s-award-highlight__logo-container">
                <a-picture class="s-award-highlight__logo" :link="logoPath" :alt="logoAlt" />
            </div>
            <div class="s-award-highlight__rightside">
                <a-slice-header :title="title" :lead="lead" />
                <a-link
                    v-if="link"
                    class="s-award-highlight__link"
                    v-bind="link"
                    :type="link.type || 'direct'"
                    :glyph="link.glyph || 'arrow'"
                />
            </div>
        </div>
    </section>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SAwardHighlight',

    components: {
        ASliceHeader,
        ALink,
        APicture,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        /**
         * Optional Link
         */
        link: {
            type: Object,
            default: undefined,
        },

        logoPath: {
            type: String,
            required: true,
        },

        logoAlt: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-award-highlight {
    .a-container {
        display: flex;
        flex-direction: column;
    }

    .a-slice-header {
        margin-bottom: 0;
        width: 100%;
    }

    &__logo-container {
        margin-bottom: 24px;
        width: 184px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__logo {
        max-width: 100%;
    }

    &__link {
        margin-top: 24px;
    }

    &:deep(.a-slice-header__lead) {
        max-width: 100%;
    }

    @media (--viewport-tablet) {
        .a-container {
            flex-direction: row;
            align-items: center;
        }

        &__logo-container {
            @mixin colls 4;

            margin-bottom: 0;
            padding: 0 16px;
            justify-content: center;
        }

        &__rightside {
            @mixin colls 8;
        }
    }

    @media (--viewport-desktop) {
        &__logo-container {
            @mixin colls 3;

            margin-bottom: 0;
            padding: 0 16px;
            justify-content: center;
        }

        &__rightside {
            @mixin colls 9;
        }
    }

    @media (--viewport-desktop-wide) {
        &__rightside {
            @mixin colls 8;
        }
    }
}
</style>
