<template>
    <section :id="id" class="s-mobile-app-highlights">
        <div class="a-container">
            <div class="image-container">
                <a-picture class="message-image" :link="image" :alt="alt" />
            </div>
            <div class="message-content">
                <h2 class="message-title">
                    {{ title }}
                </h2>
                <div class="message-desc">
                    <a-dangerous-html :class="descSize" :content="desc" />

                    <a-link
                        v-if="link"
                        class="link"
                        v-bind="link"
                        :size="link.size || 'title'"
                        :glyph="link.glyph || 'arrow'"
                        accent
                    />

                    <p v-if="subtitle" class="subtitle">
                        {{ subtitle }}
                    </p>
                </div>
                <section class="message-store-section">
                    <ul class="message-store-section-list">
                        <li class="message-store-section-item">
                            <dynamic-link
                                class="message-store-link"
                                :to="storeSectionLinks[0].to"
                                :title="storeSectionLinks[0].title"
                            >
                                <a-glyph class="message-store-icon message-store-apple" name="apple-store" />
                            </dynamic-link>
                        </li>
                        <li class="message-store-section-item">
                            <dynamic-link
                                class="message-store-link"
                                :to="storeSectionLinks[1].to"
                                :title="storeSectionLinks[1].title"
                            >
                                <a-glyph class="message-store-icon message-store-android" name="android-store" />
                            </dynamic-link>
                        </li>
                        <li
                            v-if="storeSectionText"
                            class="message-store-section-item message-store-section-item-text"
                        >
                            <a-link
                                v-bind="storeSectionText"
                                :size="storeSectionText.size || 'paragraph'"
                            />
                        </li>
                    </ul>
                </section>
            </div>
        </div>
    </section>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SMobileAppHighlight',

    components: {
        ALink,
        ADangerousHtml,
        AGlyph,
        APicture,
        DynamicLink,
    },

    props: {
        id: {
            type: String,
            default: '',
        },

        image: {
            type: String,
            required: true,
        },

        link: {
            type: Object,
            required: false,
            default: undefined,
        },

        subtitle: {
            type: String,
            default: '',
        },

        alt: {
            type: String,
            default: 'Acronis',
        },

        title: {
            type: String,
            required: true,
        },

        desc: {
            type: String,
            required: true,
        },

        descSize: {
            type: String,
            default: 'paragraph',
        },

        storeSectionText: {
            type: Object,
            required: false,
            default: undefined,
        },

        storeSectionLinks: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-mobile-app-highlights {
    @mixin basic-slice-paddings;

    .a-container {
        @media (--viewport-desktop) {
            display: flex;
            justify-content: space-between;
        }
    }

    .message-content {
        @media (--viewport-desktop) {
            order: 1;
            width: 41.66%;
        }

        @media (--viewport-desktop-wide) {
            width: 50%;
        }
    }

    .image-container {
        position: relative;
        margin-bottom: 32px;
        overflow: hidden;

        @media (--viewport-mobile-wide) {
            margin: 0 auto 32px;
            max-width: 66.66%;
        }

        @media (--viewport-desktop) {
            order: 2;
            margin: 0;
            width: 41.66%;
            max-width: 100%;
        }
    }

    .message-image {
        max-width: 100%;
    }

    .message-title {
        margin-bottom: 24px;

        @mixin display;

        color: var(--av-nav-primary);

        @media (--viewport-mobile-wide) {
            margin-bottom: 32px;

            @mixin hero;
        }

        @media (--viewport-desktop) {
            margin-bottom: 24px;
        }

        @media (--viewport-desktop-wide) {
            margin-bottom: 32px;
        }
    }

    .message-desc {
        margin-bottom: 32px;
        color: var(--av-fixed-secondary);

        .lead {
            @mixin lead;
        }

        .paragraph {
            @mixin paragraph;
        }

        .link {
            margin: 32px 0 56px;
        }

        .subtitle {
            @mixin title;
            font-weight: 600;
        }
    }

    .message-store-section-list {
        margin: 0 -16px;
        display: flex;
        align-items: center;
    }

    .message-store-section-item {
        margin: 0 16px;

        &-text {
            margin-inline-start: 34px;
            max-width: 256px;
            font-weight: 600;
        }
    }

    .message-store-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: 1px solid var(--av-solid-brand-secondary-accent);
        transition: border-color 0.3s ease-out;

        &:hover {
            border-color: var(--av-solid-brand-secondary-light);
        }
    }

    .message-store-icon {
        fill: var(--av-brand-secondary);
        transition: fill 0.3s ease-out;

        &:hover {
            fill: var(--av-nav-secondary);
        }
    }

    .message-store-apple {
        width: 66px;
        height: 66px;
    }

    .message-store-android {
        width: 22px;
        height: 33px;
    }
}
</style>
