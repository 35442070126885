<template>
    <div v-sticky="{ parent: '.s-anchor-menu' }" class="s-anchor-menu" :style="style">
        <div ref="menuInner" class="s-anchor-menu__inner" :style="innerStyle">
            <div
                class="s-anchor-menu__wrapper"
                :class="{ 's-anchor-menu__wrapper--dark': theme === 'dark', 'has-button': hasButtonCta }"
            >
                <div class="a-container">
                    <div class="s-anchor-menu__list-wrapper">
                        <div class="list-mask left-mask" />

                        <button
                            ref="menuActivatorBtn"
                            type="button"
                            class="menu-activator-btn"
                            :class="{ active: showMenu }"
                            @click="toggleMenu"
                        >
                            <span>{{ activeLinkText }}</span>
                            <a-glyph name="drop-down" />
                        </button>

                        <ul ref="menuList" class="s-anchor-menu__list">
                            <li
                                v-for="(item, index) in list"
                                :key="`menu-${index}`"
                                class="s-anchor-menu__item"
                            >
                                <a-link
                                    class="s-anchor-menu__link"
                                    v-bind="item"
                                    :class="getActiveLinkClass(item.id)"
                                    :type="theme === 'dark' ? 'light' : 'regular'"
                                    do-not-change-location
                                    @click="scroll(item.id, $event)"
                                />
                            </li>
                        </ul>
                        <div class="list-mask right-mask" />
                    </div>

                    <ul
                        v-if="cta"
                        class="s-anchor-menu__list cta-main-menu"
                        :class="{ 'sticky-only': showCtaOnStickyOnly }"
                    >
                        <li
                            v-for="(item, index) in cta"
                            :key="`menu-cta-${index}`"
                            class="s-anchor-menu__item s-anchor-menu__cta"
                        >
                            <div class="s-anchor-menu__cta-item">
                                <a-button
                                    v-if="item.ctaType === 'button'"
                                    class="s-anchor-menu__link"
                                    v-bind="item"
                                    :size="item.size || 's'"
                                    :glyph="item.glyph || 'arrow'"
                                    accent
                                />
                                <a-link
                                    v-else
                                    class="s-anchor-menu__link"
                                    v-bind="item"
                                    :glyph="item.glyph || 'arrow'"
                                    :type="theme === 'dark' ? 'light' : 'regular'"
                                    accent
                                />
                            </div>
                        </li>
                    </ul>
                </div>

                <div v-show="showMenu" ref="navSubmenu" class="nav-submenu">
                    <a-link
                        v-for="(item, index) in list"
                        :key="`link-${index}`"
                        class="nav-submenu__link"
                        v-bind="item"
                        :class="getActiveLinkClass(item.id)"
                        :type="theme === 'dark' ? 'light' : 'regular'"
                        do-not-change-location
                        @click="scroll(item.id, $event)"
                    />

                    <a-link
                        v-for="(item, index) in cta"
                        :key="`button-${index}`"
                        class="cta-btn nav-submenu__link"
                        v-bind="item"
                        :glyph="item.glyph || 'arrow'"
                        :type="theme === 'dark' ? 'light' : 'regular'"
                        accent
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import Sticky from '@core/directives/sticky.js';
import breakpoint from '@core/mixins/breakpoint.js';

export const ANCHOR_HEIGHT = 52;

export default {
    name: 'SAnchorMenu',

    components: {
        AButton,
        AGlyph,
        ALink,
    },

    directives: {
        Sticky,
    },

    mixins: [breakpoint],

    props: {
        list: {
            type: Array,
            required: true,
        },

        theme: {
            type: String,
            required: false,
            default: undefined,
        },

        cta: {
            type: Array,
            required: false,
            default: undefined,
        },

        showCtaOnStickyOnly: {
            type: Boolean,
            default: false,
        },

        stickyOffset: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        const [active] = this.list;

        return {
            scrollShift: 8,
            scrollMargin: 0,
            ANCHOR_HEIGHT,
            isSubmenuVisible: false,
            lastPageYOffset: 0,
            active,
        };
    },

    computed: {
        topOffset() {
            let deviceType;

            if (typeof window !== 'undefined') {
                deviceType = this.getDeviceTypeByScreenWidth(window.outerWidth);
            } else {
                deviceType = this.currentBreakpoint;
            }

            return this.stickyOffset[deviceType] || this.stickyOffset.default || 0;
        },

        style() {
            return {
                top: `-${this.topOffset}px`,
            };
        },

        innerStyle() {
            return {
                top: `${this.topOffset}px`,
            };
        },

        hasButtonCta() {
            return this.cta?.some((c) => c.ctaType === 'button');
        },

        isMobile() {
            return this.currentBreakpoint === 'mobile';
        },

        showMenu() {
            return this.isMobile && this.isSubmenuVisible;
        },

        activeLinkText() {
            return this.list.find((item) => item.id === this.active)?.text || '';
        },
    },

    mounted() {
        document.body.classList.add('has-anchor-menu');

        window.addEventListener('scroll', this.setActiveLink);
        window.addEventListener('resize', this.setActiveLink);

        document.addEventListener('click', this.onNavSubmenuClickAway);
        window.addEventListener('scroll', this.onPageScroll);

        this.$refs.menuList?.addEventListener('scroll', this.onNavListScroll);

        this.setActiveLink();

        const page = document.querySelector('.page');

        this.scrollMargin = this.ANCHOR_HEIGHT + this.topOffset - this.scrollShift;

        // ribbon above the .page adds offset
        if (page) {
            this.scrollMargin -= page.offsetTop;
        }
    },

    beforeDestroy() {
        document.body.classList.remove('has-anchor-menu');

        window.removeEventListener('scroll', this.setActiveLink);
        window.removeEventListener('resize', this.setActiveLink);
        this.$refs.menuList?.removeEventListener('scroll', this.onNavListScroll);
        document.removeEventListener('click', this.onNavSubmenuClickAway);
        window.removeEventListener('scroll', this.onPageScroll);
    },

    methods: {
        scroll(hash) {
            this.isSubmenuVisible = false;

            if (this.$route?.hash !== hash) {
                this.$router.push({ hash });
            }

            const el = document.querySelector(hash);
            if (el) {
                window.scroll({
                    top: el.offsetTop - this.scrollMargin,
                    behavior: 'smooth',
                });
            }
        },

        setActiveLink() {
            const ZOOM_LVL_COMPENSATION = 2;
            const pageYOffset = Math.ceil(window.pageYOffset) + this.scrollMargin + ZOOM_LVL_COMPENSATION;
            const scrolled = this.list.filter((item) => {
                const sectionEl = document.querySelector(item.id);
                return sectionEl
                    ? pageYOffset >= sectionEl.offsetTop
                    : false;
            });

            const last = scrolled.length ? scrolled.pop() : this.list[0];

            this.active = last ? last.id : null;
        },

        getActiveLinkClass(id) {
            const dark = this.theme === 'dark';
            const active = this.active === id;

            return {
                's-anchor-menu__link_active': active,
                's-anchor-menu__link_active--dark': active && dark,
            };
        },

        toggleMenu() {
            this.isSubmenuVisible = !this.isSubmenuVisible;
        },

        onNavSubmenuClickAway(event) {
            if (!this.$refs.menuActivatorBtn.contains(event.target) && !this.$refs.navSubmenu.contains(event.target)) {
                this.isSubmenuVisible = false;
            }
        },

        onNavListScroll() {
            const leftMask = document.querySelector('.left-mask');
            const rightMask = document.querySelector('.right-mask');

            const firstListItem = this.$refs.menuList.querySelector('.s-anchor-menu__item:first-child');
            const lastListItem = this.$refs.menuList.querySelector('.s-anchor-menu__item:last-child');

            const LEFT_PADDING = 32;

            if (firstListItem.getBoundingClientRect().left < LEFT_PADDING) {
                leftMask.style.opacity = 1;
            } else {
                leftMask.style.opacity = 0;
            }

            if (lastListItem.getBoundingClientRect().right < this.$refs.menuList.getBoundingClientRect().right) {
                rightMask.style.opacity = 0;
            } else {
                rightMask.style.opacity = 1;
            }
        },

        onPageScroll() {
            if (this.isMobile && (window.pageYOffset < this.lastPageYOffset)) {
                this.$refs.menuInner.classList.add('hide');
                this.isSubmenuVisible = false;
            } else {
                this.$refs.menuInner.classList.remove('hide');
            }

            this.lastPageYOffset = window.pageYOffset;
        },
    },
};
</script>

<style lang="postcss" scoped>

.menu-activator-btn {
    display: inline-flex;
    align-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--av-nav-primary);

    > span {
        display: inline-block;
        margin-inline-end: 8px;
    }

    .a-glyph {
        fill: var(--av-nav-primary);
    }

    &.active .a-glyph {
        transform: rotateZ(180deg);
    }
}

.nav-submenu {
    max-height: 456px;
    width: 256px;
    border: 1px solid var(--av-brand-secondary);
    background-color: var(--av-inversed-primary);
    position: absolute;
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: var(--av-shadow-regular);
    top: 100%;
    inset-inline-start: 16px;

    /* WEB-43924 why do we use 120 here ? */
    z-index: 120;

    &__link {
        @mixin body;
        padding: 8px 15px;
        display: block;

        &:hover {
            background-color: var(--av-brand-secondary-accent);
            .a-link__content {
                color: var(--av-nav-primary);
            }
        }
    }

    .cta-btn {
        border-top: 1px solid var(--av-brand-secondary-accent);

        @media (--viewport-mobile-wide) {
            display: none;
        }
    }
}

.s-anchor-menu {
    position: relative;
    &.is-sticky {
        height: 52px;

        .s-anchor-menu__inner.hide {
            top: -100%;
        }

        .s-anchor-menu__inner {
            position: fixed;

            /* WEB-43924 why do we use 120 here ? */
            z-index: 120;
            top: 0;
            width: 100%;
            transition: all 0.3s;
        }
    }

    &:not(.is-sticky) .cta-main-menu.sticky-only {
        display: none;
    }

    &__inner {
        position: relative;
    }

    &__top {
        top: 0;
        position: relative;
    }

    &__list-wrapper {
        list-style: none;
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        position: relative;
        scrollbar-width: none;

        .s-anchor-menu__item {
            margin-inline-end: 16px;
        }

        &::-webkit-scrollbar {
            height: 0;
            background: rgba(255, 255, 255, 0);
        }

        .s-anchor-menu__list,
        .list-mask {
            display: none;
        }

        @media (--viewport-mobile-wide) {
            overflow-x: auto;

            .s-anchor-menu__list,
            .list-mask {
                display: inline-flex;
            }

            .menu-activator-btn {
                display: none;
            }
        }
    }

    .list-mask {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        inset-inline-end: 0;
        width: 64px;
        background: linear-gradient(90deg, #fff 0%, rgba(0, 0, 0, 0) 100%);
        transform: rotate(-180deg);
        pointer-events: none;

        &.left-mask {
            opacity: 0;
        }

        &:first-child {
            inset-inline-start: 0;
            transform: rotate(0deg);
        }
    }

    &__wrapper {
        position: relative;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        min-height: 52px;
        padding: 14px 0;
        display: flex;
        background-color: var(--av-inversed-primary);
        box-shadow: 0 4px 8px rgba(36, 49, 67, 0.2);

        &.has-button {
            padding: 6px 0;
        }

        .a-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            > .s-anchor-menu__list {
                margin-inline-start: auto;
                flex-grow: 0;
                flex-shrink: 0;
            }
        }

        &--dark {
            color: var(--av-brand-secondary);
            background: var(--av-nav-primary);
            box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);
        }
    }

    &__list {
        display: flex;
        align-items: center;
        overflow-x: auto;
        scrollbar-width: none;

        &.cta-main-menu {
            display: none;

            @media (--viewport-mobile-wide) {
                display: inline-flex;
            }
        }

        &::-webkit-scrollbar {
            height: 0;
            background: rgba(255, 255, 255, 0);
        }
    }

    &__item {
        display: block;

        &:deep(.a-link.nuxt-link-exact-active) {
            color: var(--av-nav-primary);

            .a-link__content {
                color: currentcolor;
            }
        }
    }

    &__cta {
        @mixin descriptor;
        display: flex;
        flex-wrap: nowrap;
        color: var(--av-brand-secondary);
        margin-inline-start: 17px;
    }

    &__link {
        white-space: nowrap;

        &_active {
            color: var(--av-nav-primary);

            &:deep(.a-link__content) {
                color: currentcolor;
            }

            &--dark {
                color: var(--av-inversed-primary);

                &:deep(.a-link__content) {
                    color: currentcolor;
                }
            }
        }
    }
}
</style>
