import { Schema } from '@core/mixins/structuredData';
import OrganizationHandler from '@core/mixins/structuredData/handlers/organizationHandler';
import SchemaHandler from '@model/seo/schemaHandler';
import { SchemaConfig, WebPageSchema } from '@model/seo/schemas';
import {
    getSiteUrl,
    getPostVideoObject,
    getEventDescription,
    ROUTE_NAME_BLOG_POST,
    SITE_ID_EVENTS,
    MAIN_WEBSITE_NAME,
    BLOG_WEBSITE_NAME,
    EVENTS_WEBSITE_NAME,
} from '../helper';
import VideoObjectHandler from './videoObjectHandler';

const ABOUT_SLICE_NAME = 's-company-pages-about';

export default class WebPageHandler implements SchemaHandler {
    ctx: any;

    schemasConfig: SchemaConfig[];

    constructor(ctx: any, schemasConfig: SchemaConfig[]) {
        this.ctx = ctx;
        this.schemasConfig = schemasConfig;
    }

    getSchemaData(staticConfig: WebPageSchema): Promise<WebPageSchema> {
        return Promise.resolve(this.getRequiredFields(staticConfig));
    }

    getRequiredFields(staticConfig: WebPageSchema): WebPageSchema {
        const siteID = this.ctx.$store.getters['config/$config'].siteID;

        if (siteID === SITE_ID_EVENTS) {
            return this.getEventSiteFields(staticConfig);
        }

        if (this.ctx.$route.name === ROUTE_NAME_BLOG_POST) {
            return this.getBlogPostFields(staticConfig);
        }

        return this.getMainSiteFields(staticConfig);
    }

    getBlogPostFields(staticConfig) {
        const fullPath = `${getSiteUrl(this.ctx)}${this.ctx.$route?.path || '/'}`;
        const post = this.ctx.$store.state.blog?.post?.items[0];

        if (!post) return null;

        const headline = post.seo_title;
        const description = post.seo_description;

        const data = {
            ...staticConfig,
            '@id': `${fullPath}#WebPage`,
            name: BLOG_WEBSITE_NAME,
            url: fullPath,
            publisher: this.getPublisher(),
            headline,
            description,
        };

        const video = getPostVideoObject(post.body);
        if (video) {
            data.video = this.getVideoObjectSchema(post, video);
        }

        return data;
    }

    getVideoObjectSchema(post, video) {
        const handler = new VideoObjectHandler(this.ctx, this.schemasConfig);
        return handler.getVideoObjectSchema(post, video);
    }

    getEventSiteFields(staticConfig) {
        const fullPath = `${getSiteUrl(this.ctx)}${this.ctx.$route?.path || '/'}`;
        const event = this.ctx.$store.state.events.event.items[0];
        const description = getEventDescription(event);

        const data = {
            ...staticConfig,
            '@id': `${fullPath}#WebPage`,
            name: EVENTS_WEBSITE_NAME,
            url: fullPath,
            publisher: this.getPublisher(),
            headline: event.seo_title || event.name,
            description,
        };

        if (event.videos?.length) {
            data.video = this.getEventVideoSchema(event.videos[0], description);
        }

        return data;
    }

    getMainSiteFields(staticConfig) {
        const fullPath = `${getSiteUrl(this.ctx)}${this.ctx.$route?.path || '/'}`;
        const page = this.ctx.$store.state.pages?.page;
        const headline = page?.meta?.title || MAIN_WEBSITE_NAME;
        const description = page?.meta?.description || headline;
        const slices = page?.body || [];
        const aboutConfig = slices.find?.((slice) => slice.name === ABOUT_SLICE_NAME);

        const data = {
            ...staticConfig,
            '@id': `${fullPath}#WebPage`,
            name: MAIN_WEBSITE_NAME,
            url: fullPath,
            publisher: this.getPublisher(),
            headline,
            description,
        };

        if (aboutConfig?.data?.featuredVideo) {
            const videoObjectHandler = new VideoObjectHandler(this.ctx, this.schemasConfig);
            data.video = videoObjectHandler.extendVideoObjectSchema(aboutConfig.data.featuredVideo);
        }

        return data;
    }

    getPublisher() {
        const staticConfig: any = this.schemasConfig.find((schema) => schema['@type'] === Schema.Organization);
        const handler = new OrganizationHandler(this.ctx, this.schemasConfig);
        return handler.getRequiredFields(staticConfig);
    }

    getEventVideoSchema(video, description) {
        const videoUrl = video.url;
        const ytId = videoUrl.split('?v=').pop();
        const thumbnails = ['default', 'mqdefault', 'hqdefault', 'sddefault', 'maxresdefault']
            .map((res) => `https://i.ytimg.com/vi/${ytId}/${res}.jpg`);

        return {
            '@type': 'VideoObject',
            '@id': videoUrl,
            contentUrl: videoUrl,
            name: video.title,
            description,
            thumbnailUrl: thumbnails,
            publisher: this.getPublisher(),
            uploadDate: video.created_at,
            potentialAction: {
                '@type': 'LikeAction',
                name: 'Like',
                target: videoUrl,
            },
        };
    }
}
