export default {
    'bg-bg': 'EUR',
    'cs-cz': 'EUR',
    'de-de': 'EUR',
    'en-eu': 'EUR',
    'en-gb': 'GBP',
    'en-sg': 'USD',
    'en-us': 'USD',
    'es-es': 'EUR',
    'es-mx': 'USD',
    'fr-fr': 'EUR',
    'he-il': 'EUR',
    'hu-hu': 'EUR',
    'it-it': 'EUR',
    'ja-jp': 'JPY',
    'ko-kr': 'USD',
    'pl-pl': 'EUR',
    'pt-br': 'USD',
    'ro-ro': 'EUR',
    'tr-tr': 'EUR',
    'zh-cn': 'USD',
    'zh-tw': 'USD',
};
