<template>
    <section :id="id" :class="{ ...basicClassName }">
        <s-purchasing-version-2
            v-if="isVersion2"
            :page="page"
            :purchase-page-c-t-a="purchasePageCTA"
        />
    </section>
</template>

<script>
import { mapValues } from 'lodash';
import contentMixin from '@core/mixins/content.js';
import purchasing from '@core/mixins/purchasing';
import styleMixin from '@core/mixins/style.js';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import SPurchasingVersion2 from './version-2/index.vue';

export default {
    name: 'SPurchasingCyberProtect',
    components: {
        SPurchasingVersion2,
    },
    mixins: [contentMixin, styleMixin, purchasing],
    props: {
        page: {
            type: Object,
            default: null,
        },
        purchasePageCTA: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        STORE_SLICE_NAME: 's-purchasing-cyber-protect',
        searchParams: null,
    }),
    computed: {
        slice() {
            return this.$store.state.slices.items?.[this.STORE_SLICE_NAME] || {};
        },
        isVersion2() {
            return Boolean(this.slice['version-2']);
        },
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },
    },
    async serverPrefetch() {
        await this.$store.dispatch('slices/getSyncedData', { slice: 's-loader', locale: this.locale });
        await this.$store.dispatch('slices/getSyncedData', { slice: this.STORE_SLICE_NAME, locale: this.locale });

        const listCartID = this.slice?.specialOffer?.licenseID || {};
        const listCleverBridgeCartID = mapValues(listCartID, (item) => ({ cleverBridgeCartID: item }));
        const slice = { ...this.slice, ...listCleverBridgeCartID };
        const settings = { ...this.purchasePageCTA, isPurchase: true };

        await this.findCleverBridgeCart(slice, settings);
    },
};
</script>
