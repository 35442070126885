import { Schema } from '@core/mixins/structuredData';
import OrganizationHandler from '@core/mixins/structuredData/handlers/organizationHandler';
import {
    getPostVideoObject,
    SCHEMA_URL,
    ROUTE_NAME_BLOG_POST,
    ROUTE_NAME_RC_ARTICLE,
} from '@core/mixins/structuredData/helper';
import { LOCALE_DEFAULT } from '@model/const/locales';
import SchemaHandler from '@model/seo/schemaHandler';
import { SchemaConfig, VideoObjectSchema } from '@model/seo/schemas';

const SLICE_NAME_ABOUT = 's-company-pages-about';
const SLICE_NAME_FEATURED_VIDEO = 's-video-featured';
const SLICE_NAME_MESSAGE_HIGHLIGHTS = 's-message-highlights';
const SLICE_NAME_WRAPPER_SLICE = 's-wrapper-slice';
const SLICE_NAME_HEADER_MESSAGE = 's-header-message';

const THUMBNAILS = ['default', 'mqdefault', 'hqdefault', 'sddefault', 'maxresdefault'];

export default class VideoObjectHandler implements SchemaHandler {
    ctx: any;

    schemasConfig: SchemaConfig[];

    constructor(ctx: any, schemasConfig: SchemaConfig[]) {
        this.ctx = ctx;
        this.schemasConfig = schemasConfig;
    }

    getSchemaData(staticConfig: VideoObjectSchema): Promise<VideoObjectSchema> {
        return Promise.resolve(this.getRequiredFields(staticConfig));
    }

    getRequiredFields(staticConfig: VideoObjectSchema): VideoObjectSchema {
        const routeName = this.ctx.$route.name;

        if (routeName === ROUTE_NAME_BLOG_POST) {
            return this.getBlogVideoObject(staticConfig);
        }

        if (routeName === ROUTE_NAME_RC_ARTICLE) {
            return this.getRcVideoObject(staticConfig);
        }

        const locale = this.ctx.$route.params?.locale || LOCALE_DEFAULT;
        const slices = this.ctx.$store.state.pages?.page?.body || [];
        const featuredVideo = slices.find((config) => config.name === SLICE_NAME_FEATURED_VIDEO);
        const aboutConfig = slices.find((config) => config.name === SLICE_NAME_ABOUT);
        const headerMessage = slices.find((config) => config.name === SLICE_NAME_HEADER_MESSAGE);
        let messageHighlights: any;

        // hack for product pages
        if (this.ctx.$route.path.startsWith(`/${locale}/products/`)) {
            const wrapper = slices.find((config: any) => config.name === SLICE_NAME_WRAPPER_SLICE);

            messageHighlights = wrapper?.data.slices.find(
                (config) => config.name === SLICE_NAME_MESSAGE_HIGHLIGHTS && config.data.video,
            );
        } else {
            messageHighlights = slices.find(
                (config) => config.name === SLICE_NAME_MESSAGE_HIGHLIGHTS && config.data.video,
            );
        }

        let videoConfig: any;

        if (featuredVideo) {
            videoConfig = {
                video: featuredVideo.data,
                title: featuredVideo.data.videoTitle,
                lead: featuredVideo.data.title,
            };
        } else if (aboutConfig?.data?.featuredVideo) {
            videoConfig = aboutConfig.data.featuredVideo;
        } else if (messageHighlights?.data?.video) {
            videoConfig = {
                video: messageHighlights.data.video,
                title: messageHighlights.data.video.text,
                lead: messageHighlights.data.video.imageAlt || messageHighlights.data.lead,
            };
        } else if (headerMessage?.data?.media?.type === 'video') {
            videoConfig = {
                video: headerMessage.data.media,
                title: headerMessage.data.media.text,
                lead: headerMessage.data.media.imageAlt,
            };
        }

        if (!videoConfig?.video?.ytId) return null;

        return {
            ...staticConfig,
            ...this.extendVideoObjectSchema(videoConfig),
        };
    }

    extendVideoObjectSchema(videoConfig: any): VideoObjectSchema {
        const ytId = videoConfig.video.ytId;

        if (!ytId) return null;

        const embedUrl = `https://www.youtube.com/embed/${ytId}`;
        const videoUrl = `${embedUrl}?rel=0&showinfo=0&autoplay=1&enablejsapi=1`;
        const time = videoConfig.video.time?.split(':') || 0;
        const duration = time ? `PT${time[0] || 0}M${time[1] || 0}S` : null;
        const thumbnails = THUMBNAILS.map((res) => `https://i.ytimg.com/vi/${ytId}/${res}.jpg`);

        const data: VideoObjectSchema = {
            '@type': 'VideoObject',
            '@id': videoUrl,
            contentUrl: videoUrl,
            name: videoConfig.title,
            description: videoConfig.lead,
            thumbnailUrl: thumbnails,
            publisher: this.getPublisher(),
            uploadDate: videoConfig.uploadDate || new Date().toISOString(),
            embedUrl,
            potentialAction: {
                '@type': 'LikeAction',
                name: 'Like',
                target: `https://www.youtube.com/embed/${ytId}`,
            },
        };

        if (duration) {
            data.duration = duration;
        }

        return data;
    }

    getBlogVideoObject(staticConfig: VideoObjectSchema) {
        const post = this.ctx.$store.state.blog.post?.items?.[0];
        const video = getPostVideoObject(post.body);

        if (!video) return null;

        return {
            ...staticConfig,
            ...this.getVideoObjectSchema(post, video),
        };
    }

    getRcVideoObject(staticConfig: VideoObjectSchema) {
        const dataSource = this.ctx.$store.state.resourceCenter.resource?.items?.[0];
        let video: any = getPostVideoObject(dataSource.description);

        if (!video) {
            if (dataSource.url?.includes('youtube')) {
                video = { data: { id: dataSource.url.split('/').pop() } };
            } else {
                return null;
            }
        }

        return {
            ...staticConfig,
            ...this.getVideoObjectSchema(dataSource, video),
        };
    }

    getVideoObjectSchema(post: any, video: any) {
        return {
            '@context': SCHEMA_URL,
            '@type': 'VideoObject',
            ...this.extendVideoObjectSchema({
                video: { ytId: video.data?.id },
                title: post.seo_title,
                lead: post.seo_description,
                uploadDate: post.published_at,
            }),
        };
    }

    getPublisher() {
        const staticConfig: any = this.schemasConfig.find((schema) => schema['@type'] === Schema.Organization);
        const handler = new OrganizationHandler(this.ctx, this.schemasConfig);
        return handler.getRequiredFields(staticConfig);
    }
}
