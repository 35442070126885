<template>
    <main class="page-container">
        <solutions-top-nav v-bind="pageSettings.topnav" />
        <solutions-header v-bind="pageSettings.header" />
        <solutions-integration-details
            :header="header"
            :summary="summary"
            :features="features"
            :body="body"
            :company="company"
            :back-link="backLink"
            :support-link="pageSettings.integrations.supportLink"
        />
        <solutions-other-integrations
            v-if="integrationsBySameVendor.length"
            :title="otherIntegrationsTitle"
            :cards="integrationsBySameVendor"
        />
        <solutions-footer-message v-bind="pageSettings.footerMessage" />
        <s-global-footer v-bind="pageSettings.footer" />
    </main>
</template>

<script>
import solutions from '@core/mixins/solutions.js';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import SolutionsFooterMessage from '@core/slices/solutions/footer-message/footer-message.vue';
import SolutionsHeader from '@core/slices/solutions/header/header.vue';
import SolutionsIntegrationDetails from '@core/slices/solutions/integration-details/integration-details.vue';
import SolutionsOtherIntegrations from '@core/slices/solutions/other-integrations/other-integrations.vue';
import SolutionsTopNav from '@core/slices/solutions/topnav/topnav.vue';
import { SOLUTIONS_RESOURCE_URL } from '@model/const/solutions';

export default {
    name: 'IntegrationComponent',

    components: {
        SolutionsFooterMessage,
        SolutionsHeader,
        SolutionsTopNav,
        SolutionsIntegrationDetails,
        SolutionsOtherIntegrations,
        SGlobalFooter,
    },

    mixins: [solutions],

    computed: {
        integration() {
            return this.prepareIntegrationFields(this.currentIntegration);
        },

        integrationsBySameVendor() {
            return this.integrations
                .filter((item) => item.vendor === this.integration.vendor && item.slug !== this.integration.slug)
                .map(this.prepareIntegrationFields)
                .sort(this.sortByTags)
                .map(this.getIntegrationCardFields);
        },

        otherIntegrationsTitle() {
            return this.pageSettings.integrations.otherByVendor.replace('@vendor', this.integration.vendor);
        },

        header() {
            const categories = this.integration.categories
                .map((code) => this.categories.find((category) => category.code === code))
                .filter(Boolean);

            return {
                title: this.integration.name,
                categories,
                company: this.integration.vendor,
                logo: this.integration.logo,
                cta: this.integration.call_to_action_link,
                tag: this.integration.tag,
                integrationTitle: this.pageSettings.integrations.integrationTitle,
                categoryTitle: this.pageSettings.integrations.categoryTitle,
                companyTitle: this.pageSettings.integrations.companyTitle,
            };
        },

        summary() {
            const links = [
                ...(this.integration.custom_links || []),
                this.integration.documentation_url,
                this.integration.release_notes_url,
                this.integration.solution_brief_url,
                this.integration.term_of_service_url,
            ]
                .filter(Boolean)
                .map(this.handleUrlInLinks);

            return {
                title: this.integration.title,
                lead: this.integration.detailed_description,
                links,
            };
        },

        features() {
            return {
                slides: this.integration.linked_feature_slides?.map((x) => ({
                    ...x,
                    logo_icons: x.logo_icons?.map((y) => `${SOLUTIONS_RESOURCE_URL}${y}`) || [],
                })),
            };
        },

        body() {
            return {
                title: this.integration.media_slides?.title,
                lead: this.integration.media_slides?.description,
                slides: this.integration.media_slides?.items.map((item) => ({
                    ...item,
                    link: item.image ? `${SOLUTIONS_RESOURCE_URL}${item.image}` : undefined,
                })),
                features: this.integration.feature_slides?.features,
                featuresTitle: this.integration.feature_slides?.title || this.pageSettings.integrations.featuresTitle,
                localizationsTitle: this.pageSettings.integrations.localizationsTitle,
                infoTitle: this.integration.promo?.title,
                infoText: this.integration.promo?.content,
            };
        },

        supportLinks() {
            const support = this.integration.support_info;

            if (!support) return [];

            const emails = support.emails?.filter(Boolean).map((email) => ({
                label: email,
                target: '_blank',
                url: `mailto:${email}`,
            }));

            const phones = support.phones?.filter(Boolean).map((phone) => ({
                label: phone,
                target: '_blank',
                url: `tel:${phone}`,
            }));

            return [
                ...(emails || []),
                ...(phones || []),
                ...(support.links || []).map(this.handleUrlInLinks),
            ];
        },

        company() {
            return {
                companyTitle: this.integration.company_info?.title || this.pageSettings.integrations.companyInfo,
                companyLinks: this.integration.company_info?.links?.map(this.handleUrlInLinks) || [],
                supportTitle: this.integration.support_info?.title || this.pageSettings.integrations.supportInfo,
                supportLinks: this.supportLinks,
            };
        },

        backLink() {
            return {
                to: '/',
                text: this.pageSettings.integrations.backLinkTitle,
            };
        },
    },

    methods: {
        handleUrlInLinks(link) {
            if (link.file) return { ...link, url: `${SOLUTIONS_RESOURCE_URL}${link.file}` };

            let url = link?.url.trim() || '';
            if (!/^(https?:)?\/\//i.test(url)) {
                url = `https://${url}`;
            }
            return { ...link, url };
        },

        getIntegrationCardFields(item) {
            const labels = item.tag ? [item.tag] : null;
            return {
                title: item.name,
                text: item.short_description,
                to: `/integrations/${item.slug}/`,
                logo: item.logo,
                labels,
            };
        },
    },
};
</script>
