<template>
    <div class="event-speaker">
        <event-speaker-card
            v-if="layout === 'full'"
            layout="full"
            :socials="socials"
            :name="name"
            :title="title"
            :text="text"
            :photo="photo"
        />
        <template v-else>
            <event-speaker-card
                v-modal="modalId"
                :layout="layout"
                :title="title"
                :name="name"
                :photo="photo"
                :text="text"
                :read-more-title="readMoreTitle"
            />
            <a-modal :id="modalId" is-overlay-close>
                <event-speaker-card
                    layout="full"
                    :socials="socials"
                    :name="name"
                    :title="title"
                    :text="text"
                    :photo="photo"
                />
            </a-modal>
        </template>
    </div>
</template>

<script>
import AModal from '@core/components/modal/modal.vue';
import Modal from '@core/directives/modal.js';
import EventSpeakerCard from './event-speaker-card.vue';

export default {
    name: 'EventSpeaker',

    components: {
        AModal,
        EventSpeakerCard,
    },

    directives: {
        Modal,
    },

    props: {
        name: {
            type: String,
            required: true,
        },
        photo: {
            type: String,
            default: 'fd3353668ea5bda3b807d3682fe6ac8e',
        },
        position: {
            type: String,
            required: true,
        },
        company: {
            type: String,
            default: null,
        },
        socials: {
            type: Array,
            required: false,
            default: () => [],
        },
        text: {
            type: String,
            default: null,
        },
        readMoreTitle: {
            type: String,
            default: 'Read more',
        },
        layout: {
            type: String,
            required: true,
        },
        modalId: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            isTextClamped: false,
        };
    },

    computed: {
        title() {
            return this.company ? `${this.position}, ${this.company}` : this.position;
        },

        speaker() {
            return {
                company: this.company || null,
                name: this.name || null,
                photo: this.photo || null,
                position: this.position || null,
                socials: this.socials,
                text: this.text,
                title: this.title,
            };
        },
    },
};
</script>

<style lang="postcss">
.event-speaker {
    text-align: start;

    .a-modal .inner {
        max-width: 768px;

        .event-speaker-card {
            margin: 0 auto;
            box-shadow: var(--av-shadow-modal);
            border-radius: 6px;
            padding: 24px 16px 32px;

            @media (--viewport-tablet) {
                padding: 48px 48px 56px;
            }
        }
    }
}
</style>
