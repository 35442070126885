<template>
    <ul class="a-social">
        <li v-for="socialItem in social" :key="socialItem.link" class="a-social__item">
            <dynamic-link
                class="a-social__link"
                :to="socialItem.link"
                :title="socialItem.title"
                native-type="a"
                :event="{ ...event, label: socialItem.link, action: getDataLayerAction(socialItem, socialItem.name) }"
            >
                <a-glyph class="a-social__icon" :name="socialItem.glyph || socialItem.name" :size="glyphSize" />
            </dynamic-link>
        </li>
    </ul>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'ASocial',

    components: {
        AGlyph,
        DynamicLink,
    },

    props: {
        /**
         * Social glyphs-library
         */
        social: {
            type: Array,
            required: true,
        },

        /**
         * GA Event
         */
        event: {
            type: Object,
            default() {
                return {
                    category: 'Social',
                };
            },
        },

        glyphSize: {
            type: String,
            default: 's',
        },
    },

    methods: {
        getDataLayerAction(item, defaultAction) {
            if (item.dataLayer && item.dataLayer.action) {
                return item.dataLayer.action;
            }
            return defaultAction;
        },
    },
};
</script>

<style lang="postcss">
.a-social {
    display: flex;
    justify-content: center;

    &__link {
        margin: 0 8px;
        cursor: pointer;
        border: 0;
        padding: 0;
        background: rgba(255, 255, 255, 0);
    }

    &__icon {
        &:hover {
            fill: var(--av-brand-secondary);
        }
    }
}
</style>
