<template>
    <div class="event-card" :class="className">
        <a class="event-link" :href="link">
            <a-picture
                class="image"
                :link="imageId || fallbackImage"
                position="80% center"
                fit="cover"
                size="medium"
            />
            <div class="labels">
                <event-label
                    v-for="(label, i) in labels"
                    :key="i"
                    class="label"
                    v-bind="label"
                />
            </div>
            <v-clamp class="title" tag="div" :max-lines="3">{{ title }}</v-clamp>
            <template v-if="theme === 'main'">
                <div v-if="date" class="date">{{ date }}</div>
                <div class="details">{{ typeAndLocation }}</div>
                <div class="details">{{ languageAndDuration }}</div>
            </template>
        </a>
        <template v-if="theme === 'default'">
            <div v-if="date" class="date">
                {{ date }}
            </div>
            <div class="details">
                {{ typeAndLocation }}
            </div>
            <div class="details">
                {{ languageAndDuration }}
            </div>
        </template>
        <a v-if="showLearnMore" class="event-link learn-more" :href="link">{{ learnMoreTitle }} →</a>
    </div>
</template>

<script>
import VClamp from 'vue-clamp';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import EventLabel from '../event-label/event-label.vue';

const FallbackImages = [
    'bd62a55daae50fdec0d6a6b238034607',
    '30ed349a67c19cf8c26685d868f90f47',
    'e44479aa9902652a121944102811a7bd',
    '18a373b02d45b7c3ed01a5efacfff718',
    'f62a350b7e35dfe1e3dd057a635af90b',
    '74dd033305077b8ad219667201379601',
    '701297a7531d17743bf6f7c5f50e97c8',
    '8d171c9d5dce1ca04801e22dc732de75',
];

export default {
    name: 'EventCard',

    components: {
        VClamp,
        APicture,
        EventLabel,
    },

    mixins: [breakpoint],
    props: {
        id: {
            type: Number,
            required: true,
        },

        theme: {
            type: String,
            default: 'default',
        },

        imageId: {
            type: String,
            default: null,
        },

        labels: {
            type: Array,
            default: () => [],
        },

        title: {
            type: String,
            required: true,
        },

        date: {
            type: String,
            default: null,
        },

        type: {
            type: String,
            default: '',
        },

        location: {
            type: String,
            default: '',
        },

        language: {
            type: String,
            default: '',
        },

        duration: {
            type: String,
            default: '',
        },

        link: {
            type: String,
            required: true,
        },

        learnMoreTitle: {
            type: String,
            default: 'Learn more',
        },
    },

    computed: {
        className() {
            return this.theme !== 'default' ? this.theme : null;
        },

        fallbackImage() {
            return FallbackImages[this.id % FallbackImages.length];
        },

        showLearnMore() {
            return this.isMobile && this.theme === 'default';
        },

        typeAndLocation() {
            return [this.type, this.location].filter(Boolean).join(' | ');
        },

        languageAndDuration() {
            return [this.language, this.duration].filter(Boolean).join(' | ');
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-card {
    &.main {
        max-width: 544px;

        .event-link {
            height: 100%;
            display: block;
            padding-bottom: 24px;
        }

        .image {
            display: block;
            border-radius: 0;
        }

        .labels,
        .title,
        .date,
        .details {
            padding: 0 24px;
        }
    }

    .image {
        display: none;
        aspect-ratio: 1.98;
        margin-bottom: 24px;
        border-radius: 4px;
        overflow: hidden;

        @media (--viewport-tablet) {
            display: block;
        }
    }

    .event-link {
        text-decoration: none;
        width: 100%;

        &:hover {
            .title {
                color: var(--av-brand-secondary);
            }
        }
    }

    .labels {
        display: flex;
        flex-wrap: wrap;
        margin-inline-start: -4px;

        @media (--viewport-tablet) {
            margin-bottom: 8px;
            padding-inline-end: 32px;
        }
    }

    .title {
        color: var(--av-fixed-primary);
        @mixin title-accent;
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            margin-bottom: 24px;
            padding-inline-end: 32px;
        }
    }

    .date {
        color: var(--av-fixed-secondary);
        @mixin paragraph-accent;

        @media (--viewport-tablet) {
            padding-inline-end: 32px;
        }
    }

    .details {
        color: var(--av-fixed-secondary);
        @mixin paragraph;

        @media (--viewport-tablet) {
            padding-inline-end: 32px;
        }
    }

    .learn-more {
        margin-top: 16px;
        display: inline-block;
        @mixin body-accent;
        color: var(--av-brand-secondary);
    }
}
</style>

<style lang="postcss">
.event-card {
    .image {
        .a-picture__img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
