<template>
    <div v-if="isVisible" id="comparison-acp" class="s-purchasing-acp-comparison">
        <div class="products">
            <div class="products-wrapper">
                <div class="product-item">
                    <p class="product-title" v-html="translation('Compare Acronis Cyber Protect editions')" />
                    <a-link v-if="comparisonLink" v-bind="comparisonLink" />
                </div>
                <div class="product-item">
                    <div v-for="(edition, editionIndex) in editionList" :key="`edition-${editionIndex}`" class="edition-item">
                        <p v-if="!isMobile && edition.label?.text" class="edition-label">
                            <a-label v-bind="{...edition.label, size: 'l', text: ''}">
                                {{ edition.label.text }}
                            </a-label>
                        </p>
                        <div class="edition-title" v-html="edition.title" />
                        <template v-if="!isMobile">
                            <div class="edition-price">
                                <template v-if="edition.fromFull">
                                    <div class="edition-price-item">
                                        <span class="full" v-html="priceEdition(edition.product, 'full')" />
                                        <span class="discount" v-html="priceDiscount(edition)" />
                                    </div>
                                    <p class="current" v-html="priceEdition(edition.product, 'current')" />
                                </template>
                                <template v-else>
                                    <p class="current" v-html="priceEdition(edition.product, 'current')" />
                                </template>
                            </div>
                            <div class="edition-button">
                                <a-button
                                    v-bind="button"
                                    :event="{ doNotSendGA: true }"
                                    @click.native="scrollTo(`edition-${edition.product}`)"
                                />
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div v-for="(row, rowIndex) in comparisonList" :key="`row-${rowIndex}`" :class="{ ...rowClassName(row) }">
            <div v-for="(cell, cellIndex) in row" :key="`cell-${cellIndex}`" :class="{ ...cellClassName(row, cell) }">
                {{ isNubmer(cell) ? '' : cell }}
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import currency from '@core/mixins/currency.js';
import mixin from '../mixin.js';

export default {
    name: 'SComparison',

    components: {
        AButton,
        ALabel,
        ALink,
    },

    mixins: [breakpoint, mixin, currency],

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    computed: {
        button() {
            return {
                size: 's',
                type: 'action',
                text: this.translation('Configure'),
            };
        },
        isVisible() {
            return this.comparisonList.length;
        },

        comparisonLink() {
            return this?.settings?.comparison?.link || null;
        },

        comparisonList() {
            return this?.settings?.comparison?.list || [];
        },
    },

    methods: {
        priceDiscount(edition) {
            const price = edition.fromFull;
            const discount = edition.from;
            const percent = Math.round(100 - (100 * discount) / price);
            return this.translation('Save @percent').replace('@percent', `${percent}%`);
        },

        isNubmer(item) {
            return Number.isInteger(item);
        },

        rowClassName(row) {
            return {
                row,
                'row-title': row.length <= 1,
            };
        },

        cellClassName(row, item) {
            return {
                cell: row,
                [`cell-${item ? 'plus' : 'minus'}`]: this.isNubmer(item),
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.products {
    top: 0;
    z-index: 1;
    position: sticky;
    background: var(--av-inversed-primary);
    box-shadow: 0 15px 20px 0 var(--av-fixed-lightest);
    border-bottom: 2px solid var(--av-brand-secondary-light);
}

.products-wrapper {
    gap: 0 4px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1320px;
    padding: 24px 8px 8px;
    justify-content: center;
    @media (--viewport-tablet) {
        padding: 24px 32px;
    }
    @media (--viewport-desktop) {
        gap: unset;
        flex-wrap: nowrap;
        padding: 42px 32px 32px;
    }

    @media (--viewport-desktop-wide) {
        gap: unset;
        padding: 42px 64px 32px;
    }

    @media (--viewport-desktop-large) {
        padding: 42px 0 32px;
    }
}

.product-item {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    &:first-child {
        width: 100%;
        flex-wrap: wrap;
    }
    @media (--viewport-tablet) {
        width: 25%;
        flex-wrap: wrap;
        padding-inline-end: 16px;
    }

    @media (--viewport-desktop) {
        &:first-child {
            width: 25%;
            justify-content: flex-start;
        }
    }

    &:not(:first-child) {
        gap: 0 4px;
        margin-top: 24px;

        @media (--viewport-tablet) {
            padding: 0;
            width: 100%;
            gap: 0 16px;
            margin: 32px 0 0;
        }

        @media (--viewport-desktop) {
            width: 75%;
            margin: 0;
        }
    }

    &:deep(.a-link) {
        margin-top: 16px;
        @media (--viewport-desktop) {
            display: flex;
            margin: auto 0 16px;
        }
        .a-link__content {
            @mixin body-accent;
        }
    }

}

.product-title {
    @mixin display;
    width: 100%;
    padding: 0 8px;
    text-align: center;
    color: var(--av-nav-primary);
    @media (--viewport-tablet) {
        padding: 0;
    }
    @media (--viewport-desktop) {
        text-align: start;
        margin-bottom: 12px;
    }
}

.edition-item {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
    border-radius: 8px;
    position: relative;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
    border: 2px solid var(--av-brand-secondary-light);
    @media (--viewport-tablet) {
        padding: 24px 16px 16px;
    }
}

.edition-label {
    z-index: 1;
    width: 100%;
    bottom: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    inset-inline-start: 0;
    justify-content: center;

    &:deep(.a-label) {
        top: 12px;
        height: 24px;
        margin: 0 16px;
        overflow: hidden;
        text-align: center;
    }
}

.edition-title {
    @mixin body-accent;
    text-align: center;
    color: var(--av-nav-primary);
    @media (--viewport-desktop) {
        @mixin lead-accent;
    }
}

.edition-price {
    margin-top: auto;
    padding-top: 16px;
    text-align: center;
    @media (--viewport-desktop) {
        @mixin lead-accent;
    }
    .edition-price-item {
        display: flex;
        flex-direction: column;

        @media (--viewport-desktop-wide) {
            gap: 0 8px;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;
        }

        .full {
            @mixin paragraph;
            font-weight: 400;
            text-decoration: line-through;
            color: var(--av-fixed-secondary);
        }
        .discount {
            @mixin paragraph-accent;
            color: rgba(124, 155, 30, 1);
        }
    }
    .current {
        @mixin paragraph-accent;
        color: var(--av-nav-primary);
        @media (--viewport-desktop) {
            @mixin lead-accent;
        }
    }
}

.edition-button {
    width: 100%;
    padding-top: 4px;

    .a-button {
        @mixin paragraph-accent;
        width: 100%;
        white-space: unset;
        text-align: center;
    }
}

.row {
    @mixin body;
    display: flex;
    padding: 16px;
    flex-wrap: wrap;
    flex-direction: row;
    color: var(--av-fixed-secondary);
    border-top: 1px solid var(--av-brand-secondary-light);

    &:last-child {
        border-bottom: 1px solid var(--av-brand-secondary-light);
    }

    @media (--viewport-desktop) {
        padding: 0 32px;
    }

    @media (--viewport-desktop-wide) {
        margin: 0 auto;
        padding: 0 64px;
        max-width: 1320px;
    }

    @media (--viewport-desktop-large) {
        padding: 0;
    }
}

.cell {
    display: flex;
    width: 33.33%;
    padding: 0 16px;
    font-weight: 700;
    text-align: center;
    align-items: center;
    justify-content: center;

    &:first-child {
        width: 100%;
        font-weight: 400;
    }

    @media (--viewport-desktop) {
        width: 25%;
        padding: 16px 0;
        border-inline-start: 1px solid var(--av-brand-secondary-light);

        &:first-child {
            width: 25%;
            text-align: start;
            padding-inline-end: 16px;
            border-inline-start: none;
            justify-content: flex-start;
        }
    }
}

.row-title {
    @mixin lead-accent;
    border-top: none;
    color: var(--av-fixed-primary);
    background: var(--av-solid-brand-accent);

    @media (--viewport-desktop-wide) {
        padding: 0;
        max-width: none;
    }

    .cell {
        width: 100%;
        font-weight: 700;

        @media (--viewport-desktop-wide) {
            margin: 0 auto;
            padding: 16px 64px;
            width: 1320px;
        }

        @media (--viewport-desktop-large) {
            padding: 16px 0;
        }
    }

    + .row {
        border-top: none;
    }
}

.cell-plus,
.cell-minus {
    vertical-align: middle;

    &:after {
        content: '';
        display: block;
        margin: 0 auto;
    }
}

.cell-plus {
    &:after {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: var(--av-fixed-success);
    }
}

.cell-minus {
    position: relative;

    &:after {
        top: 50%;
        width: 24px;
        height: 2px;
        margin-top: -1px;
        position: absolute;
        inset-inline-start: 50%;
        margin-inline-start: -12px;
        background: var(--av-nav-primary);
    }
}
</style>
