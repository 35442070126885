<template>
    <div class="ti-page-not-found-wrapper">
        <s-main-header theme="light" />
        <a-picture
            v-if="data.image"
            class="background"
            :link="data.image"
            :fit="{ default: 'cover' }"
            position="center top"
        />
        <section class="ti-page-not-found" :class="{ ...basicClassName }">
            <div class="a-container">
                <div class="content">
                    <div class="top">
                        <a-slice-header
                            class="slice-header"
                            :title="data.title"
                            :lead="data.lead"
                        />
                        <a-button
                            class="button"
                            v-bind="data.homepageLink"
                            :type="data.homepageLink.type || 'main'"
                        />
                    </div>
                    <div class="bottom">
                        <div class="copyright">
                            © {{ new Date().getFullYear() }} {{ data.copyrightText }}
                        </div>
                        <a-link class="legal" v-bind="data.legalLink" />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SMainHeader from '@core/slices/pages/main-header/main-header.vue';

export default {
    name: 'AcronisPageNotFound',
    components: {
        AButton,
        ALink,
        APicture,
        ASliceHeader,
        SMainHeader,
    },
    mixins: [contentMixin, styleMixin],
    computed: {
        data() {
            return this.$store.state.slices.items['s-page-not-found-ti'] || {};
        },
    },
};
</script>

<style lang="postcss" scoped>
.ti-page-not-found-wrapper {
    height: 100vh;
}
.background {
    position: absolute;
    height: 100%;
    width: 100%;
    inset-inline: 0;
    bottom: 0;
    max-width: 524px;
    max-height: 420px;
    margin: auto;

    @media (min-height: 800px) {
        max-height: 492px;
    }

    @media (min-height: 960px) {
        max-height: 620px;
    }

    :deep(.a-picture__img) {
        width: 100%;
        height: 100%;
    }
}
.ti-page-not-found {
    padding: 144px 0 24px;
    height: 100%;
    @media (--viewport-mobile-wide) {
        padding: 144px 0 40px;
    }
    .a-container,
    .content {
        height: 100%;
    }
    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (--viewport-desktop) {
            margin: 0 auto;
            width: 83.33%;
        }
    }
    .top {
        text-align: center;
    }
    .slice-header {
        margin-bottom: 24px;
        text-align: center;
        @media (--viewport-desktop) {
            width: 100%;
        }
        .a-slice-header__title {
            margin-bottom: 16px;
        }
    }
    .bottom {
        text-align: center;
    }
    .copyright {
        color: var(--av-fixed-light);
    }
}
</style>
