import SchemaHandler from '@model/seo/schemaHandler';
import { HowToSchema, SchemaConfig } from '@model/seo/schemas';

export default class HowToHandler implements SchemaHandler {
    ctx: any;

    schemasConfig: SchemaConfig[];

    constructor(ctx: any, schemasConfig: SchemaConfig[]) {
        this.ctx = ctx;
        this.schemasConfig = schemasConfig;
    }

    getSchemaData(staticConfig: HowToSchema): Promise<HowToSchema> {
        return Promise.resolve(this.getRequiredFields(staticConfig));
    }

    getRequiredFields(staticConfig: HowToSchema): HowToSchema {
        const post = this.ctx.$store.state.blog.post?.items?.[0];

        if (!post) return null;

        return {
            ...staticConfig,
        };
    }
}
