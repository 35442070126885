/* eslint-disable max-len */

export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=46214-83511&t=YdHBHy6Bx0MHVIeQ-4';

export const Default = {
    args: {
        translations: {
            title: 'Features',
            link: { text: 'All Features' },
            cards: [
                {
                    title: 'Absolute VM Protection',
                    content: 'Defend more hypervisors, including RedHat, KVM, Oracle VM, Citrix Xen, VMware and Hyper-V, with simple licensing per host allowing protection for an unlimited number of VMs',
                },
                {
                    title: 'Acronis Instant Restore',
                    content: 'Reduce outage recovery time with best-in-industry RTO by starting any physical or virtual Windows or Linux system directly from the backup storage',
                    label: 'Advanced',
                },
                {
                    title: 'Acronis Universal Restore',
                    content: 'Restore an entire system to new, dissimilar hardware with a few clicks. Our technology ensures easy system migration between physical and virtual platforms',
                },
                {
                    title: 'Automated Bare-metal Recovery',
                    content: 'Recover up to two times faster with a complete system image available and smart technology that automatically detects boot requirements',
                },
                {
                    title: 'Acronis Universal Restore',
                    content: 'Restore an entire system to new, dissimilar hardware with a few clicks. Our technology ensures easy system migration between physical and virtual platforms',
                },
                {
                    title: 'Acronis Universal Restore',
                    content: 'Restore an entire system to new, dissimilar hardware with a few clicks. Our technology ensures easy system migration between physical and virtual platforms',
                },
            ],
        },
        link: { to: '#' },
        cards: [
            { icon: '1', to: '#' },
            { icon: '2', to: '#' },
            { icon: '3' },
            { icon: '4' },
            { icon: '5' },
            { icon: '6' },
        ],
    },
};
