<template>
    <div class="blog-people">
        <div class="title-wrap">
            <div class="title">
                {{ title }}
            </div>
            <client-only>
                <a-link
                    class="link"
                    v-bind="link"
                    :text="link.title"
                    :glyph="link.glyph || 'arrow'"
                />
            </client-only>
        </div>
        <div class="people">
            <div v-for="(person, i) in people" :key="i" class="person">
                <a-picture class="photo" :link="person.photo || fallbackImage" fit="cover" />
                <div class="info">
                    <a-link class="name" :to="person.link" :text="person.name" />
                    <div class="position">
                        {{ person.position }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClientOnly from '@core/components/client-only/client-only.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'BlogPeople',
    components: {
        ClientOnly,
        APicture,
        ALink,
    },
    props: {
        title: {
            type: String,
            required: true,
        },

        link: {
            type: Object,
            required: true,
        },

        people: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            fallbackImage: 'fd3353668ea5bda3b807d3682fe6ac8e',
        };
    },
};
</script>

<style lang="postcss" scoped>
.blog-people {
    @mixin basic-slice-paddings;

    .title-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        @media (--viewport-mobile-wide) {
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 48px;
        }

        @media (--viewport-desktop) {
            margin-bottom: 40px;
        }
    }

    .title {
        @mixin hero;

        text-align: center;
        font-weight: bold;
        color: var(--av-nav-primary);
        margin-bottom: 16px;

        @media (--viewport-mobile-wide) {
            @mixin display-strong;

            margin-bottom: 0;
            text-align: start;
        }

        @media (--viewport-desktop) {
            @mixin hero;
        }
    }

    .link {
        @media (--viewport-mobile-wide) {
            margin-inline-start: 16px;
        }

        &:deep(.a-link__content) {
            @mixin lead-accent;
        }

        &:deep(.a-link__glyph_position_right) {
            margin-inline-start: 10px;
        }
    }

    .people {
        display: flex;
        justify-content: space-between;
        gap: 6px;

        @media (--viewport-tablet) {
            gap: 40px;
        }

        @media (--viewport-desktop) {
            gap: 16px;
        }
    }

    .person {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (--viewport-desktop-wide) {
            flex-direction: row;
        }
    }

    .photo {
        flex-grow: 0;
        flex-shrink: 0;
        width: 80px;
        height: 80px;
        border-radius: 4px;
        margin-inline-end: 16px;
        margin-bottom: 16px;
    }

    .info {
        flex-grow: 1;
    }

    .name {
        text-decoration: none;
        margin-bottom: 8px;

        &:deep(.a-link__content) {
            @mixin lead-accent;
            color: var(--av-nav-primary);
        }
    }

    .position {
        font-size: 14px;
        line-height: 24px;
        color: var(--av-fixed-secondary);
        opacity: 0.9;
    }
}
</style>
