<template>
    <div class="event-partner-logos" :class="{ 'no-lead': !lead }">
        <h2 class="title">
            {{ title }}
        </h2>
        <div v-if="lead" class="section-lead">
            {{ lead }}
        </div>
        <div class="logos">
            <div v-for="(partner, i) in partners" :key="i" class="logo-wrap">
                <a v-if="partner.link" class="event-link" :href="partner.link.to">
                    <a-picture
                        class="logo"
                        :link="partner.logo"
                        fit="contain"
                        size="small"
                    />
                </a>
                <a-picture
                    v-else
                    class="logo"
                    :link="partner.logo"
                    fit="contain"
                    size="small"
                />
            </div>
        </div>
    </div>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'EventPartnerLogos',

    components: {
        APicture,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        lead: {
            type: String,
            default: '',
        },

        partners: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-partner-logos {
    color: var(--av-nav-primary);

    &.no-lead {
        @media (--viewport-desktop) {
            display: grid;
            grid-template-columns: 1fr 2fr;
            column-gap: 16px;
        }

        .logos {
            @media (--viewport-tablet) {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }

            @media (--viewport-desktop) {
                margin-top: 0;
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            @media (--viewport-desktop-wide) {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
        }

        .title {
            @media (--viewport-desktop) {
                height: 120px;
                display: flex;
                align-items: center;
                margin-bottom: 0;
            }
        }
    }

    .section-lead {
        margin-bottom: 24px;
    }

    .logos {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 16px;
        row-gap: 16px;
        align-items: center;
        margin-top: 16px;

        @media (--viewport-tablet) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @media (--viewport-desktop) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        @media (--viewport-desktop-wide) {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }

        @media (--viewport-desktop-large) {
            grid-template-columns: repeat(6, minmax(0, 1fr));
        }
    }

    .logo-wrap {
        padding: 8px 40px 8px 0;
        height: 120px;
        display: flex;
        align-items: center;
    }

    .logo {
        height: 100%;
        display: flex;
        align-items: center;
    }
}
</style>

<style lang="postcss">
.event-partner-logos {
    .a-picture {
        &__img {
            margin: 0;
        }
    }
}
</style>
