<template>
    <div class="a-ransomware-card">
        <div class="a-ransomware-card__img-container">
            <a-picture class="a-ransomware-card__img" :link="image" :alt="imageAlt" />
        </div>
        <div class="a-ransomware-card__details">
            <h3 class="a-ransomware-card__name">
                {{ name }}
            </h3>
            <div class="a-ransomware-card__risk">
                <div class="a-ransomware-card__rate">
                    <template v-for="(i, index) in 5">
                        <a-svg-sprite
                            :key="`skull_${i}`"
                            class="a-ransomware-card__skull"
                            :class-name="{ 'a-ransomware-card__skull_active': index < rate }"
                            name="skull"
                        />
                    </template>
                </div>
                <div class="a-ransomware-card__impact">
                    <div class="a-ransomware-card__attribute">
                        Impact:
                    </div>
                    <div class="a-ransomware-card__value">
                        <template v-if="impact">
                            High
                        </template>
                        <template v-else>
                            Moderate
                        </template>
                    </div>
                </div>
                <div class="a-ransomware-card__status">
                    <div class="a-ransomware-card__attribute">
                        Status:
                    </div>
                    <div class="a-ransomware-card__value">
                        <template v-if="status">
                            Active
                        </template>
                        <template v-else>
                            Inactive
                        </template>
                    </div>
                </div>
            </div>
            <div class="a-ransomware-card__info">
                <h4 class="a-ransomware-card__subtitle">
                    Details:
                </h4>
                <p>{{ text }}</p>
                <p v-if="onAcronis" class="a-ransomware-card__after-content">
                    <span>Find out more about {{ name }} on Acronis:&nbsp;</span>
                    <a-link :to="onAcronis" :text="onAcronis" size="paragraph" />
                </p>
                <h4 v-if="news" class="a-ransomware-card__subtitle a-ransomware-card__subtitle_news">
                    In the News:
                </h4>
                <ul class="a-list">
                    <li v-for="item in news" :key="item.url" class="a-list__item">
                        <a-link
                            v-bind="item"
                            :to="item.url"
                            :target="item.target || '_blank'"
                            size="paragraph"
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASvgSprite from '@core/components/svg-sprite/svg-sprite.vue';

export default {
    name: 'ARansomwareCard',

    components: {
        ALink,
        APicture,
        ASvgSprite,
    },

    props: {
        name: {
            type: String,
            required: true,
        },

        text: {
            type: String,
            required: true,
        },

        image: {
            type: String,
            required: true,
        },

        imageAlt: {
            type: String,
            default: undefined,
        },

        status: {
            type: Boolean,
            default: false,
        },

        impact: {
            type: Boolean,
            default: false,
        },

        rate: {
            type: Number,
            required: true,
        },

        onAcronis: {
            type: String,
            default: undefined,
        },

        news: {
            type: Array,
            default: undefined,
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-ransomware-card {
    &__img-container {
        margin: 0 auto 40px;

        @media (min-width: 360px) {
            border-radius: 4px;
            overflow: hidden;
            width: 328px;
            height: 328px;
        }

        @media (--viewport-tablet) {
            max-width: none;
            width: 284px;
            height: 284px;
            float: left;
            margin-inline-end: 76px;
            margin-inline-start: 0;
        }

        @media (--viewport-desktop) {
            width: 309px;
            height: 309px;
            margin-inline-end: 98px;
        }

        @media (--viewport-desktop-wide) {
            width: 276px;
            height: 276px;
            margin-inline-end: 113px;
        }

        @media (--viewport-desktop-large) {
            width: 317px;
            height: 317px;
            margin-inline-end: 127px;
        }
    }

    &__img {
        display: block;
        width: 100%;
        height: 100%;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            position: relative;
            overflow: hidden;
        }

        &:deep(.a-picture__img) {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                position: absolute;
                top: 50%;
                inset-inline-start: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                max-width: none;
                height: auto;
            }
        }
    }

    &__details {
        @media (--viewport-desktop) {
            overflow: hidden;
        }
    }

    &__name {
        @mixin display;

        margin-bottom: 32px;
        color: var(--av-nav-primary);

        @media (--viewport-desktop) {
            margin-bottom: 24px;
        }
    }

    &__risk {
        margin-bottom: 64px;
        display: flex;
        flex-wrap: wrap;

        @media (--viewport-desktop-wide) {
            margin-bottom: 48px;
        }
    }

    &__rate {
        width: 100%;
        display: flex;
        margin-bottom: 48px;

        @media (--viewport-desktop) {
            width: 228px;
            margin-bottom: 0;
            margin-inline-end: 16px;
        }

        @media (--viewport-desktop-wide) {
            width: 276px;
        }

        @media (--viewport-desktop-large) {
            width: 317px;
        }
    }

    &__skull {
        width: 19px;
        height: 30px;
        fill: var(--av-fixed-lighter);

        &:not(:first-child) {
            margin-inline-start: 16px;
        }

        &_active {
            fill: var(--av-nav-primary);
        }
    }

    &__impact {
        width: calc(50% - 8px);
        margin-inline-end: 16px;
        flex-shrink: 0;

        @media (--viewport-desktop) {
            width: 146px;
        }

        @media (--viewport-desktop-wide) {
            width: 179px;
        }

        @media (--viewport-desktop-large) {
            width: 206px;
        }
    }

    &__attribute {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 8px;
        font-weight: 600;
        color: var(--av-nav-primary);
    }

    &__value {
        display: flex;
        align-items: center;
    }

    &__info {
        @media (--viewport-tablet) {
            clear: both;
        }
    }

    &__after-content {
        margin-top: 32px;

        span {
            display: inline-block;
            vertical-align: middle;
        }
    }

    &__subtitle {
        font-size: 18px;
        line-height: 24px;
        color: var(--av-nav-primary);
        margin-bottom: 16px;
        font-weight: 600;

        &_news {
            margin-top: 48px;
        }
    }

    &:deep(.a-list__item) {
        padding: 0;
    }
}
</style>
