<template>
    <section :id="id" class="s-slice s-message-highlights" :class="{ ...className, ...basicClassName }">
        <div class="s-message-highlights__container a-container">
            <div class="s-message-highlights__content">
                <a-label
                    v-if="l10n.label"
                    class="s-message-highlights__label"
                    :type="l10n.labelType"
                    :glyph="l10n.labelGlyph"
                    :variant="l10n.labelVariant"
                >
                    {{ l10n.label }}
                </a-label>
                <p v-if="l10n.descriptor && (l10n.title || l10n.lead)" class="s-message-highlights__description">
                    {{ l10n.descriptor }}
                </p>
                <h2 v-if="l10n.title" class="a-slice-header__title">
                    {{ l10n.title }}
                </h2>
                <a-dangerous-html v-if="l10n.lead" class="a-slice-header__lead" :content="l10n.lead" />
                <a-dangerous-html v-if="l10n.bodyText" class="s-message-highlights__text" :content="l10n.bodyText" />
                <a-dangerous-html
                    v-else-if="l10n.bulletListTitle"
                    class="s-message-highlights__bullets-title"
                    :content="l10n.bulletListTitle"
                />
                <ul v-if="l10n.tags?.length" class="s-message-highlights__tags">
                    <li v-for="(item, index) in l10n.tags" :key="`tags_${index}`" class="s-message-highlights__tag-item">
                        <a-tag
                            v-if="item.link && item.link.text"
                            class="s-message-highlights__tag-label"
                            :link="item.link"
                            v-bind="item.link"
                        />
                        <a-tag
                            v-else
                            class="s-message-highlights__tag-label"
                            :text="item"
                            type="chip"
                            style="pointer-events: none"
                        />
                    </li>
                </ul>

                <a-product-cta v-if="trialPageCTA" :settings="{ trialPageCTA }" />

                <ul
                    v-if="l10n.bulletListItems?.length"
                    class="s-message-highlights__bullets-list a-list"
                    :class="{ 'list-glyph-bullets': Boolean(bulletListGlyph), 'a-list_type_bullet': !Boolean(bulletListGlyph) }"
                >
                    <li
                        v-for="(item, index) in l10n.bulletListItems"
                        :key="index"
                        class="s-message-highlights__bullets-item a-list__item"
                    >
                        <a-glyph v-if="bulletListGlyph" class="bullet" v-bind="bulletListGlyph" />
                        <a-dangerous-html class="s-message-highlights__bullets-item-text" :content="item" />
                    </li>
                </ul>
                <ul v-if="l10n.linkListItems?.length" class="s-message-highlights__links-list a-link-list">
                    <li
                        v-for="(item, index) in l10n.linkListItems"
                        :key="index"
                        class="s-message-highlights__link-item a-link-list__item"
                    >
                        <a-link
                            class="a-link-list__link"
                            v-bind="item"
                            :size="item.size || 'paragraph'"
                        />
                    </li>
                </ul>
                <ul v-if="l10n.downloadFileArr?.length" class="s-message-highlights__download-file-list">
                    <li
                        v-for="(item, index) in l10n.downloadFileArr"
                        :key="index"
                        class="s-message-highlights__download-file-item"
                    >
                        <a-link
                            v-bind="item"
                            :type="item.type || 'download-file'"
                            :glyph="item.glyph || 'download-arrow'"
                            :glyph-position="item.glyphPosition || 'left'"
                            :text="item.title"
                        />
                    </li>
                </ul>
                <ul v-if="l10n.tagLinks?.length" class="s-message-highlights__tag-link-list">
                    <li v-for="(item, index) in l10n.tagLinks" :key="index" class="s-message-highlights__tag-link-item">
                        <a-link
                            v-bind="item"
                            :type="item.type || 'regular'"
                            :text="item.title"
                            :size="item.size || 'paragraph'"
                        />
                    </li>
                </ul>

                <s-trial-form v-if="!isVisibleDownloadList" :form="form" />

                <template v-if="isVisibleDownloadList">
                    <div
                        v-for="(item, i) in downloadList()"
                        :key="`s-message-highlights-download-${i}`"
                        class="s-message-highlights__download"
                    >
                        <div class="s-message-highlights__download-items">
                            <div
                                v-for="(button, buttonIndex) in item.list"
                                :key="buttonIndex"
                                class="s-message-highlights__download-item"
                            >
                                <a-link
                                    v-if="button.isLink"
                                    v-bind="button"
                                    :type="button.type || 'regular'"
                                    :size="button.size || 'paragraph'"
                                    @click="handleClick(button)"
                                />
                                <a-button
                                    v-else
                                    v-bind="button"
                                    :key="`s-message-highlights-download-item-${buttonIndex}`"
                                    :size="button.size || 'm'"
                                    :target="button.target || '_top'"
                                    :event="setEvent(button)"
                                />
                            </div>
                        </div>
                        <div v-if="item.description" class="s-message-highlights__download-description" v-html="item.description" />
                        <div v-if="item.info" class="s-message-highlights__download-info">
                            <div
                                v-for="(info, infoIndex) in item.info"
                                :key="`s-message-highlights-download-info-${infoIndex}`"
                                class="s-message-highlights__download-info-item"
                            >
                                <p class="s-message-highlights__download-info-title" v-html="info.title" />
                                <p class="s-message-highlights__download-info-text" v-html="info.text" />
                            </div>
                        </div>
                    </div>
                </template>
                <a-link
                    v-if="l10n.link"
                    class="s-message-highlights__link link-desktop"
                    v-bind="l10n.link"
                    :type="l10n.link.type || 'direct'"
                    :glyph="l10n.link.glyph || 'arrow'"
                    :size="l10n.link.size || 'title'"
                    accent
                    :class="{ 's-message-highlights__link-brand-secondary': l10n.link.isBrandSecondary }"
                />
                <a-link
                    v-if="l10n.link && l10n.eventCountdown"
                    class="s-message-highlights__link link-mobile link-event-countdown"
                    v-bind="l10n.link"
                    :type="l10n.link.type || 'direct'"
                    :glyph="l10n.link.glyph || 'arrow'"
                    :size="l10n.link.size || 'title'"
                    accent
                    :class="{ 's-message-highlights__link-brand-secondary': l10n.link.isBrandSecondary }"
                />

                <div class="s-message-highlights__cta-button-wrapper">
                    <a-button
                        v-if="l10n.ctaButton"
                        class="s-message-highlights__cta-button"
                        v-bind="l10n.ctaButton"
                        :size="l10n.ctaButton.size || 'm'"
                        :type="l10n.ctaButton.type || 'main'"
                    />
                </div>
            </div>
            <div v-if="l10n.media" class="s-message-highlights__img-container">
                <a-media v-bind="l10n.media" />
            </div>
            <!-- TODO: Remove image and video below when media becomes widely used in admin  -->
            <div v-if="l10n.image" class="s-message-highlights__img-container">
                <a-media
                    class="s-message-highlights__img"
                    :link="l10n.image"
                    :alt="l10n.imageAlt"
                    :type="l10n.imageType"
                    :is-shadow="l10n.imageType === 'screenshot' ? true : isShadow"
                    :class="imageHighlightClass"
                    :enlarged-image="enlargedImage"
                    :is-overlay-close="isOverlayClose"
                    :modal-id="modalId"
                />
            </div>
            <div v-if="isAllowedToShowVideo" class="s-message-highlights__video-container">
                <a-video-card v-bind="l10n.video" class="s-message-highlights__video" :size="videoCardSize" />
            </div>
            <event-countdown
                v-if="l10n.eventCountdown && !isAllowedToShowVideo"
                v-bind="l10n.eventCountdown"
                @countdown-over="eventCountdownIsOver = true"
            />

            <video
                v-if="l10n.videoLink"
                class="s-message-highlights__img-container"
                crossorigin="anonymous"
                autoplay
                preload
                muted
                loop
                :poster="image"
            >
                <source
                    v-for="(source, index) in getVideoLink"
                    :key="index"
                    :src="source.link"
                    :type="source.type"
                />
                <p>This browser does not support the video element.</p>
            </video>
            <a-link
                v-if="l10n.link && !l10n.eventCountdown"
                class="s-message-highlights__link link-mobile"
                v-bind="l10n.link"
                :type="l10n.link.type || 'direct'"
                :glyph="l10n.link.glyph || 'arrow'"
                :size="l10n.link.size || 'title'"
                accent
                :class="{ 's-message-highlights__link-brand-secondary': l10n.link.isBrandSecondary }"
            />
        </div>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import AMedia from '@core/components/media/media.vue';
import AProductCta from '@core/components/product-cta/product-cta.vue';
import ATag from '@core/components/tag/tag.vue';
import AVideoCard from '@core/components/video-card/video-card.vue';
import Modal from '@core/directives/modal.js';
import analytics from '@core/mixins/analytics.js';
import breakpoint from '@core/mixins/breakpoint.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import contentMixin from '@core/mixins/content.js';
import form from '@core/mixins/form.js';
import l10n from '@core/mixins/l10n.js';
import purchasing from '@core/mixins/purchasing.js';
import styleMixin from '@core/mixins/style.js';
import STrialForm from '@core/slices/pages/trial-form/trial-form.vue';
import EventCountdown from './event-countdown.vue';

export default {
    name: 'SMessageHighlights',
    components: {
        AProductCta,
        AButton,
        AGlyph,
        ADangerousHtml,
        ALink,
        AMedia,
        ALabel,
        ATag,
        AVideoCard,
        STrialForm,
        EventCountdown,
    },

    directives: {
        Modal,
    },

    mixins: [l10n, contentMixin, styleMixin, breakpoint, purchasing, form, analytics, cleverbridge],

    inheritAttrs: false,

    props: {
        background: {
            type: String,
            default: 'white',
        },
        label: {
            type: String,
            default: undefined,
        },
        labelVariant: {
            type: String,
            required: false,
            default: 'info',
        },
        labelType: {
            type: String,
            default: 'label',
        },
        labelGlyph: {
            type: String,
            default: '',
        },
        descriptor: {
            type: String,
            default: undefined,
        },
        bodyText: {
            type: String,
            default: undefined,
        },
        bulletListTitle: {
            type: String,
            default: undefined,
        },
        tags: {
            type: Array,
            default: () => [],
        },
        bulletListItems: {
            type: Array,
            default: () => [],
        },
        bulletListGlyph: {
            type: Object,
            default: undefined,
        },
        downloadFileArr: {
            type: Array,
            default: () => [],
        },
        tagLinks: {
            type: Array,
            default: () => [],
        },
        linkListItems: {
            type: Array,
            default: () => [],
        },
        media: {
            type: Object,
            default: undefined,
        },
        image: {
            type: String,
            default: undefined,
        },
        enlargedImage: {
            type: String,
            default: undefined,
        },
        isOverlayClose: {
            type: Boolean,
            default: true,
        },
        imageAlt: {
            type: String,
            default: undefined,
        },
        imageType: {
            type: String,
            default: 'image',
        },
        video: {
            type: Object,
            default: undefined,
        },
        videoLink: {
            type: String,
            default: undefined,
        },
        link: {
            type: Object,
            default: undefined,
        },
        isCentered: {
            type: Boolean,
            default: false,
        },
        withTabletAltLayout: {
            type: Boolean,
            default: false,
        },
        isReversed: {
            type: Boolean,
            default: false,
        },
        isShadow: {
            type: Boolean,
            default: false,
        },
        download: {
            type: Array,
            default: () => [],
        },
        form: {
            type: Object,
            default: null,
        },
        trialPageCTA: {
            type: Object,
            default: undefined,
        },
        ctaButton: {
            type: Object,
            default: null,
        },
        extraClassName: {
            type: String,
            default: undefined,
        },
        eventCountdown: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            selectedOS: '',
            eventCountdownIsOver: false,
        };
    },

    computed: {
        videoCardSize() {
            return this.isMobile || this.isTablet ? 'small' : 'large';
        },
        className() {
            return {
                's-message-highlights_layout_centered': this.isCentered,
                's-message-highlights_layout_reversed': this.isReversed,
                's-message-highlights_layout_with-tablet-alt': this.withTabletAltLayout,
                's-message-highlights_layout_without-link': !this.l10n.link,
                [this.extraClassName]: Boolean(this.extraClassName),
            };
        },
        imageHighlightClass() {
            return {
                's-message-highlights__img_shadow': this.imageType !== 'screenshot' && this.isShadow,
                'cursor-pointer': this.enlargedImage,
            };
        },
        modalId() {
            return `${this.image || ''}-${this.enlargedImage || ''}`;
        },
        getVideoLink() {
            const formats = ['webm', 'mp4'];

            return formats.map((el) => ({
                link: `${this.l10n.videoLink}.${el}`,
                type: `video/${el}`,
            }));
        },
        isVisibleDownloadList() {
            if (!this.form) {
                return true;
            }
            return this.isDesktop && window?.matchMedia('(hover:hover)').matches;
        },
        isAllowedToShowVideo() {
            if (!this.l10n.video) return false;
            if (!this.l10n.eventCountdown) return true;
            return this.eventCountdownIsOver;
        },
    },

    created() {
        if (this.l10n.eventCountdown) {
            const utcOffset = (new Date()).getTimezoneOffset() / 60;
            const utcOffsetDate = new Date(this.l10n.eventCountdown.startDate);
            utcOffsetDate.setHours(utcOffsetDate.getHours() - utcOffset);
            this.eventCountdownIsOver = new Date().getTime() >= utcOffsetDate.getTime();
        }
    },

    mounted() {
        this.selectedOS = /mac|iphone|ipad|ipod/i.test(navigator.userAgent) ? 'MAC' : 'PC';
    },

    methods: {
        downloadList() {
            return this.l10n.download.filter(
                (item) => item.list && (!item.type || item.type === this.selectedOS),
            );
        },
        handleClick(button) {
            if (button.changeOS) {
                this.selectedOS = button.changeOS;
            }
        },
    },

    async serverPrefetch() {
        if (!this.trialPageCTA) return;
        await this.getLicenses(this.productPageCTA);
    },
};
</script>

<style lang="postcss" scoped>
.s-message-highlights {
    @mixin basic-slice-paddings;
    position: relative;

    &__container {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;
    }
    &__content {
        @mixin colls 12;
        @media (--viewport-desktop) {
            @mixin colls 6;
        }
        @media (--viewport-desktop) {
            margin-bottom: 0;
        }
    }
    .a-slice-header__title {
        @mixin display-accent;
        color: var(--av-nav-primary);
        margin-bottom: 24px;
        @media (--viewport-mobile-wide) {
            @mixin hero-accent;
        }
    }
    .a-slice-header__lead {
        @mixin lead;
        color: var(--av-nav-primary);

        @media (--viewport-mobile-wide) {
            @mixin title;
        }
    }
    &__label {
        margin-bottom: 8px;
    }
    &__description {
        @mixin paragraph;
        margin-bottom: 8px;
        color: var(--av-fixed-light);
    }
    &__tags {
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
    }
    &__tag-item {
        margin-inline-end: 8px;
        margin-bottom: 8px;
    }
    &__tag-label {
        display: flex;
    }
    &__text {
        margin-top: 24px;
        &:first-child {
            margin-top: 0;
        }
    }
    &__links-list {
        margin-top: 40px;
    }
    &__link-item {
        display: flex;
        align-items: center;
    }
    &__bullets-list {
        margin-top: 40px;
    }

    &__bullets-item-text {
        @mixin paragraph;
        display: inline;
    }

    .list-glyph-bullets {
        .a-list__item {
            padding-inline-start: 32px;
            text-indent: -32px;
        }

        .a-list__item::before {
            display: none;
        }

        .bullet {
            margin: 0 12px 4px 0;
        }
    }

    &__download-file-list {
        margin-top: 40px;
        display: flex;
    }

    &__download-file-item {
        @mixin colls 6;
        margin-inline-end: 16px;
        @media (--viewport-mobile-wide) {
            @mixin colls 4;
        }
        @media (--viewport-desktop) {
            @mixin colls 6;
        }
    }
    &__tag-link-list {
        margin-top: 40px;
        display: flex;
    }

    &__tag-link-item {
        @mixin colls 6;
        margin-inline-end: 16px;
        @media (--viewport-mobile-wide) {
            @mixin colls 4;
        }
        @media (--viewport-desktop) {
            @mixin colls 6;
        }
    }
    &__img-container,
    &__video-container {
        display: block;
        margin-top: 56px;
        @mixin colls 12;

        @media (--viewport-mobile-wide) {
            @mixin colls 8;
            margin: 56px auto 0;
        }

        @media (--viewport-desktop) {
            display: flex;
            justify-content: center;
            align-items: center;
            @mixin colls 5;
            margin: 0;
        }
    }

    &__img.cursor-pointer {
        cursor: pointer;
    }

    &__link {
        @mixin colls 12;
        margin-top: 56px;

        &-brand-secondary {
            &:deep(.a-link) {
                &__content {
                    color: var(--av-brand-secondary);

                    &__glyph {
                        fill: var(--av-brand-secondary);
                    }
                }
            }
        }
    }

    &__download {
        @mixin body;
        width: 100%;
        margin: 56px 0 0;
        text-align: center;

        &-item {
            &:not(:first-child) {
                margin: 24px 0 0 0;
            }

            &:deep(.a-button) {
                min-width: 244px;
            }

            &:deep(.a-link) {
                &__content {
                    color: var(--av-brand-secondary);
                }

                &:hover,
                &:focus {
                    .a-link__content {
                        background: none;
                        color: var(--av-brand-secondary);
                    }
                }
            }
        }

        &-description {
            margin: 24px 0 0;
            text-align: start;
            color: var(--av-fixed-light);
        }

        &-info {
            display: flex;
            margin: 40px 0 0;
            text-align: start;
            flex-wrap: nowrap;
            align-items: flex-start;
            color: var(--av-fixed-secondary);

            a,
            a:hover {
                text-decoration: none;
                color: var(--av-brand-secondary);
            }
        }

        &-info-item {
            width: 33.33%;
        }

        &-info-title {
            @mixin body-accent;
        }

        &-info-text {
            padding-top: 8px;
            padding-inline-end: 32px;
        }
    }

    @media (--viewport-mobile-wide) {
        &__download {
            text-align: start;

            &-item {
                display: inline-block;

                &:not(:first-child) {
                    margin-top: 0;
                    margin-inline-start: 24px;
                }

                &:deep(.a-button) {
                    min-width: auto;
                }
            }

            &-description {
                margin: 16px 0 0;
            }
        }
    }

    @media (--viewport-tablet) {
        &_layout {
            &_with-tablet-alt {
                .s-message-highlights {
                    &__content {
                        @mixin colls 7;
                        padding-inline-end: 16px;
                        @media (--viewport-desktop) {
                            @mixin colls 6;
                            padding: 0;
                        }
                    }
                    &__img-container,
                    &__video-container {
                        margin: 0;
                        @mixin colls 5;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop) {
        &__download {
            &-items {
                display: flex;
                align-items: center;
            }
        }
    }

    &_layout {
        &_centered {
            .s-message-highlights {
                &__container {
                    align-items: center;
                }
            }
        }
        &_reversed {
            .s-message-highlights {
                &__img-container,
                &__video-container {
                    @media (--viewport-desktop) {
                        order: 1;
                    }
                }

                &__content {
                    @media (--viewport-desktop) {
                        order: 2;
                    }
                }

                &__link {
                    order: 3;
                    @media (--viewport-desktop) {
                        margin-inline-start: auto;
                        @mixin colls 6;
                    }
                }
            }
        }
    }

    &__link {
        &.link-mobile {
            @media(--viewport-desktop) {
                display: none;
            }
        }

        &.link-event-countdown {
            margin-bottom: 56px;
            text-align: center;
        }

        &.link-desktop {
            display: none;
            margin-inline-start: 0;
            @media(--viewport-desktop) {
                display: block;
            }
        }
    }

    &__screen {
        width: 100%;
        display: block;
        box-shadow: 0 9px 31px rgba(1, 63, 138, 0.2);
    }

}

.s-message-highlights_layout_reversed.s-message-highlights_layout_with-tablet-alt {
    .s-message-highlights {
        &__img-container,
        &__video-container {
            @media (--viewport-tablet) {
                order: 1;
            }
        }
        &__content {
            @media (--viewport-tablet) {
                order: 2;
            }
        }
        &__link {
            order: 3;
            @media (--viewport-tablet) {
                @mixin colls 7;
                margin-inline-start: auto;
            }
            @media (--viewport-desktop) {
                @mixin colls 6;
            }
        }
        &__content {
            @media (--viewport-tablet) {
                padding-inline-end: 0;
                padding-inline-start: 16px;
            }
            @media (--viewport-desktop) {
                padding: 0;
            }
        }
    }
}

.s-slice_background_dark-blue {
    .s-message-highlights {
        &__description,
        &__title,
        &__text,
        &__bullets-list {
            color: var(--av-inversed-primary);
        }
    }
}

.s-message-highlights.s-slice_background_dark-blue {
    &:deep(.a-link__desc),
    &:deep(.a-link__tag),
    &:deep(.a-slice-header__title),
    &:deep(.a-slice-header__lead) {
        color: var(--av-inversed-primary);
    }
}

.s-message-highlights {
    &:deep(.s-form) {
        padding: 0;

        .a-container {
            padding: 0;
        }

        &-registration {
            padding: 0 16px;

            @media (--viewport-mobile-wide) {
                padding: 0;
                border: none;
                box-shadow: none;
            }

            .el-form {
                margin-top: 16px;
            }

            &__title {
                @mixin body;
                font-weight: 400;

                @media (--viewport-mobile-wide) {
                    text-align: start;
                }
            }
        }

        &-container {
            min-height: auto;

            @media (--viewport-mobile-wide) {
                width: 100%;
                margin: 40px auto 0;
            }

            @media (--viewport-tablet) {
                width: 464px;
            }

            @media (--viewport-desktop) {
                margin: 40px 0 0;
            }

            .el-input {
                &__label {
                    @mixin body;

                    &.is-active {
                        @mixin caption;
                    }
                }
            }

            &__loading {
                min-height: 200px;

                ~ .s-form-registration {
                    display: none;
                }
            }
        }

        &-success {
            border: none;
            padding: 0 16px;
            box-shadow: none;

            @media (--viewport-mobile-wide) {
                padding: 0;
            }

            .description {
                @mixin body;
                margin-top: 8px;

                @media (--viewport-desktop) {
                    @mixin title-accent;
                    color: var(--av-nav-primary);
                }
            }

            .a-glyph {
                width: 32px;
                height: 32px;
            }
        }
    }
}

.s-message-highlights.event-countdown {
    .a-slice-header__title,
    .s-message-highlights__text {
        text-align: center;

        @media (--viewport-desktop) {
            text-align: start;
        }
    }

    .s-message-highlights__cta-button-wrapper {
        width: 100%;
        text-align: center;

        @media (--viewport-desktop) {
            width: 0;
        }
    }

    .s-message-highlights__cta-button {
        min-width: 152px;
        margin-top: 48px;
        margin-bottom: 56px;

        @media (--viewport-desktop) {
            margin-bottom: 0;
        }
    }

    .s-message-highlights__link {
        &:deep(.a-link__content) {
            font-weight: 600;
        }
    }

    .s-message-highlights__text {
        @mixin lead;

        @media (--viewport-tablet) {
            @mixin title;
        }
    }

    &:deep(.a-label) {
        border-radius: 8px;
        background: var(--av-gradient-info-lightest);

        span {
            color: var(--av-nav-primary);
            font-family: 'Open Sans', sans-serif;
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }

    &.event-countdown-home-page {
        .a-slice-header__title {
            @mixin display-accent;
            background: linear-gradient(90deg, #94CEF1 0%, #0065E3 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media (--viewport-mobile-wide) {
                @mixin hero-accent;
            }

            @media (--viewport-desktop) {
                @mixin large-accent;
            }
        }

        .s-message-highlights__text {
            color: var(--av-inversed-primary);
        }
    }
}

</style>
