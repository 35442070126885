<template>
    <div ref="scrollIndicator" class="root" :style="{ height: rootHeightStyle }">
        <div class="progress" :style="{ width: barWidthStyle }" />
    </div>
</template>

<script>
import { flow } from 'lodash';

export default {
    name: 'BlogProgressBar',

    props: {
        height: {
            type: Number,
            default: 2,
        },

        progress: {
            type: Number,
            required: true,
        },
    },

    computed: {
        rootHeightStyle() {
            return `${this.height}px`;
        },

        barWidthStyle() {
            return flow(
                Math.round,
                (x) => Math.max(x, 0),
                (x) => Math.min(x, 100),
                (x) => `${x}%`,
            )(this.progress || 0);
        },
    },
};
</script>

<style lang="postcss" scoped>
.root {
    position: relative;
    width: 100%;
    background: var(--av-brand-light);
}

.progress {
    position: absolute;
    height: 100%;
    inset-inline-start: 0;
    background: var(--av-nav-primary);
}
</style>
