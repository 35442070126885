export default {
    methods: {
        tag(settings, item) {
            const content = item || this.content || {};
            return {
                className: settings.name,
                isDangerous: settings.isDangerous,
                titleTag: content[`${settings.name}Tag`],
                title: content[settings.name] || content.label || '',
            };
        },
    },

    computed: {
        isAvailable() {
            if (!this.content.available) return true;
            return this.content.available.includes(this.content.tabActive);
        },
    },
};
