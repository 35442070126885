<template>
    <main class="page-container">
        <solutions-top-nav v-bind="pageSettings.topnav" />
        <solutions-header v-bind="pageSettings.header" />
        <solutions-integration-list
            ref="list"
            :active-category-code="activeCategoryCode"
            :title="activeCategory.title"
            :page="page"
            :page-size="pageSettings.integrations.pageSize || 9"
            :subtitle="activeCategory.subtitle"
            :categories="listedCategories"
            :cards="mapCardFields(filteredIntegrations)"
            :button-title="pageSettings.integrations.cardButtonTitle"
            :search-placeholder="pageSettings.integrations.searchPlaceholder"
            :no-integrations-title="pageSettings.integrations.noIntegrationsTitle"
            :all-cards-text="pageSettings.integrations.allCardsText"
            :no-integrations-text="pageSettings.integrations.noIntegrationsText"
            @category-select="onCategoryChange"
            @page-change="onPageChange"
        />
        <solutions-footer-message v-bind="pageSettings.footerMessage" />
        <s-global-footer v-bind="pageSettings.footer" />
    </main>
</template>

<script>
import solutions from '@core/mixins/solutions.js';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import SolutionsFooterMessage from '@core/slices/solutions/footer-message/footer-message.vue';
import SolutionsHeader from '@core/slices/solutions/header/header.vue';
import SolutionsIntegrationList from '@core/slices/solutions/integration-list/integration-list.vue';
import SolutionsTopNav from '@core/slices/solutions/topnav/topnav.vue';

export default {
    name: 'HomeComponent',

    components: {
        SolutionsFooterMessage,
        SolutionsHeader,
        SolutionsTopNav,
        SolutionsIntegrationList,
        SGlobalFooter,
    },

    mixins: [solutions],

    data() {
        return {
            page: 1,
        };
    },

    computed: {
        listedIntegrations() {
            return this.integrations
                .map(this.prepareIntegrationFields)
                .sort(this.sortByTags);
        },

        listedCategories() {
            const isNotEmptyCategory = (category) => this.listedIntegrations
                .some((integration) => (integration.categories.includes(category.code) || category.children?.length) && category.title);

            const categories = this.categories;
            const hashTable = {};
            const listedCategories = [];

            categories.forEach((category) => { hashTable[category.code] = { ...category, children: [] }; });
            categories.forEach((item) => {
                if (item.parent && !isNotEmptyCategory(item)) return;

                if (item.parent) {
                    hashTable[item.parent]?.children.push(hashTable[item.code]);
                } else {
                    listedCategories.push(hashTable[item.code]);
                }
            });
            return listedCategories;
        },

        filteredIntegrations() {
            if (!this.activeCategoryCode) return this.listedIntegrations;

            const category = this.listedCategories.find((item) => item.code === this.activeCategoryCode);

            // if selected category has children add items of every children
            if (category) {
                const activeCategories = category.children.map((item) => item.code);

                const allOfCategoryFilter = (integration) => integration.categories
                    .some((item) => activeCategories.includes(item) || item === category.code);

                return this.listedIntegrations.filter(allOfCategoryFilter);
            }

            return this.listedIntegrations
                .filter((integration) => integration.categories.includes(this.activeCategoryCode));
        },

        activeCategory() {
            return this.categories.find((category) => category.code === this.activeCategoryCode) || {};
        },

        activeCategoryCode() {
            return this.$route.params.subcategory || this.$route.params.category;
        },
    },

    created() {
        this.page = parseInt(this.$route.params.page, 10) || 1;
    },

    methods: {
        onCategoryChange(category) {
            const params = this.$router.params;

            if (!category?.code) {
                this.$router.replace({
                    name: 'solutions-home',
                    params,
                    hash: '/',
                });
                return;
            }

            const categoryCode = category.parent ? category.parent : category.code;
            const subcategoryCode = category.parent ? category.code : null;

            this.$router.replace({
                name: 'solutions-home-category',
                params: {
                    ...params,
                    category: categoryCode,
                    subcategory: subcategoryCode,
                },
                hash: '/',
            });
            this.onPageChange(1);
        },

        onPageChange(page) {
            this.page = page;
            this.scrollToTop();
        },

        scrollToTop() {
            if (typeof document === 'undefined') return;

            const formTop = this.$refs.list.$el.getBoundingClientRect().top;
            const scrollTop = document.documentElement.scrollTop;
            const position = formTop + scrollTop;

            document.scrollingElement.scrollTo({
                top: position,
                behavior: 'smooth',
            });
        },

        mapCardFields(integrations) {
            return integrations.map((integration) => ({
                title: integration.name,
                vendor: integration.vendor,
                description: integration.short_description,
                longDescription: integration.detailed_description,
                logo: integration.logo,
                slug: integration.slug,
                cta: integration.cta,
                tag: integration.tag,
            }));
        },
    },
};
</script>
