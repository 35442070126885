<template>
    <div class="menu-tab-resource-center">
        <menu-tab v-bind="$props" />
    </div>
</template>

<script lang="ts">
import MenuTabMixin from './menu-tab-mixin.vue';

export default {
    name: 'MenuTabResourceCenter',
    mixins: [MenuTabMixin],
};
</script>

<style lang="postcss" scoped>
.menu-tab-resource-center {
    &:deep(.s-menu-tab__body) {
        .s-menu-tab__linktitle {
            .a-link__content {
                @mixin title-accent;
            }
        }

        .s-menu-tab__description {
            @mixin body;
            color: var(--av-fixed-light);
            margin-top: 8px;
        }

        .s-menu-tab__cta {
            &:deep(.a-link__content) {
                @mixin body-accent;
            }

            &:deep(.s-menu-tab__cta-item-link) {
                &:hover {
                    .a-link__content {
                        color: rgba(0, 88, 198, 1);
                    }
                }
            }

        }

        .s-menu-tab__link-strong {
            @mixin body-accent;
        }

        .s-menu-tab__list {
            &:deep(.a-link__content) {
                @mixin body-accent;
            }
        }

        .s-menu-tab__section:nth-child(2),
        .s-menu-tab__section:nth-child(3) {
            .s-menu-tab__list {
                margin-bottom: 32px;
            }
        }
    }
}
</style>
