<template>
    <div class="root">
        <div class="solutions-container top-inner">
            <div class="logo-container">
                <a-dynamic-link
                    class="logo"
                    :to="logo.link"
                    :target="logo.target"
                    :rel="logo.rel"
                    :event="{ action: 'Acronis' }"
                >
                    <a-logo type="full" fill="white" />
                </a-dynamic-link>
            </div>

            <div class="nav">
                <div class="menu">
                    <a-dynamic-link
                        v-for="link in menuLinks"
                        :key="link.link"
                        class="link"
                        :to="link.link"
                        :event="{ action: link.text }"
                    >
                        {{ link.text }}
                    </a-dynamic-link>
                </div>
            </div>

            <div class="buttons">
                <a-button
                    v-for="button in buttons"
                    :key="button.link"
                    class="custom-button"
                    v-bind="button"
                    :to="button.link"
                    :size="button.size || 's'"
                    :event="{ category: 'Button click', action: button.text, label: 'top menu' }"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALogo from '@core/components/logo/logo.vue';

export default {
    name: 'SolutionsTopNav',

    components: {
        AButton,
        ADynamicLink,
        ALogo,
    },

    props: {
        logo: {
            type: Object,
            required: true,
        },
        menuLinks: {
            type: Array,
            required: true,
        },
        buttons: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="postcss" scoped>
.root {
    background: var(--av-nav-primary);
    padding-top: 32px;
    min-height: 88px;
    border-bottom: 1px solid rgba(0, 101, 227, 0.4);
    box-sizing: border-box;

    @media (--viewport-desktop) {
        min-height: 85px;
    }

    @media (--viewport-desktop-wide) {
        min-height: 96px;
    }

    @media (--viewport-desktop-large) {
        min-height: 88px;
    }
}

.top-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    @media (--viewport-desktop-wide) {
        justify-content: flex-start;
    }
}

.logo-container {
    width: calc(25% - 12px);
    @media (--viewport-desktop-wide) {
        width: calc(33.3333% - 10.6667px);
    }
}

.logo {
    height: 27px;
    width: 133px;
    @media (--viewport-desktop) {
        height: 32px;
        width: 158px;
    }
}

.nav {
    padding-inline-start: 24px;
    padding-inline-end: 24px;
    @media (--viewport-desktop-wide) {
        padding-inline-start: 40px;
        padding-inline-end: 40px;
    }
}

.menu {
    display: none;
    position: absolute;
    top: 100%;
    inset-inline-start: 0;
    width: 100%;
    height: 0;
    background: var(--av-inversed-primary);

    @media (--viewport-desktop) {
        position: static;
        height: auto !important;
        background: rgba(255, 255, 255, 0);
        display: flex;
        width: auto;
        margin-inline-end: auto;
    }
}

.link {
    padding: 10px 12px;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    color: var(--av-inversed-primary);

    &:hover,
    &:active {
        color: var(--av-link-inversed);
    }

    @media (--viewport-desktop) {
        padding: 0 12px;
    }
}

.buttons {
    display: none;
    margin: 0 0 0 auto;
    position: relative;
    align-items: center;

    @media (--viewport-mobile-wide) {
        display: flex;
    }
}

.buttons .custom-button:not(:last-child) {
    margin-inline-end: 16px;
}

.custom-button {
    border: 1px solid var(--av-brand-secondary);
    background: transparent;
}

.custom-button.a-button_type_main:hover {
    background-color: var(--av-button-main-hover);
    border-color: var(--av-button-main-hover);
}

.custom-button.a-button_type_action:hover {
    background-color: var(--av-fixed-success);
    border-color: var(--av-fixed-success);
}
</style>
