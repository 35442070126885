<template>
    <div class="resource-center-page">
        <s-locale-selector-ribbon :is-shown="isLocaleSelectorShown" />
        <div class="main-content">
            <s-main-header v-bind="sliceDataHeader" class="resource-page-header" />
            <slot />
        </div>
        <s-global-footer v-bind="sliceDataFooter" />
    </div>
</template>

<script lang="ts">
import localeRibbon from '@core/mixins/locale-ribbon.js';
import SGlobalFooter from '@core/slices/pages/global-footer/global-footer.vue';
import SLocaleSelectorRibbon from '@core/slices/pages/locale-selector-ribbon/locale-selector-ribbon.vue';
import SMainHeader from '@core/slices/pages/main-header/main-header.vue';
import '@core/styles/sections/resource-center.pcss';

export default {
    name: 'SResourcePage',

    components: {
        SGlobalFooter,
        SMainHeader,
        SLocaleSelectorRibbon,
    },

    mixins: [localeRibbon],

    computed: {
        sliceDataHeader() {
            return {
                ...this.$store.state.slices.items['s-main-header'],
                theme: 'light',
            };
        },

        sliceDataFooter() {
            return {
                ...this.$store.state.slices.items['s-global-footer'],
                type: 'short',
                theme: 'light',
            };
        },
    },
};
</script>

<style scoped>
.resource-page-header {
    /* WEB-43924 why do we use 401 here ? */
    z-index: 401;
}

.a-container {
    @media (--viewport-desktop-large) {
        max-width: 1440px;
    }
}
</style>
