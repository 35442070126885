<template>
    <s-basic-slice class="s-video-list" v-bind="$attrs">
        <ul class="s-video-list__list">
            <li v-for="(item, index) in items" :key="item.id" class="s-video-list__item">
                <s-video-item
                    v-bind="item"
                    :prev-title="getPrevTitle(items, index)"
                    :prev-id="getPrevId(items, index)"
                    :next-title="getNextTitle(items, index)"
                    :next-id="getNextId(items, index)"
                />
            </li>
        </ul>
    </s-basic-slice>
</template>

<script>
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import SVideoItem from './video-item.vue';

export default {
    name: 'SVideoList',

    components: {
        SBasicSlice,
        SVideoItem,
    },

    inheritAttrs: false,

    props: {
        items: {
            type: Array,
            required: true,
            default: () => [],
        },

        showMoreText: {
            type: String,
            required: false,
            default: 'Show more',
        },

        showLessText: {
            type: String,
            required: false,
            default: 'Show less',
        },
    },

    methods: {
        getPrevTitle(items, index) {
            if (index === 0) {
                return items[items.length - 1].title;
            }
            return items[index - 1].title;
        },

        getPrevId(items, index) {
            if (index === 0) {
                return items[items.length - 1].id;
            }
            return items[index - 1].id;
        },

        getNextTitle(items, index) {
            if (index === items.length - 1) {
                return items[0].title;
            }
            return items[index + 1].title;
        },

        getNextId(items, index) {
            if (index === items.length - 1) {
                return items[0].id;
            }
            return items[index + 1].id;
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-video-list {
    &:deep(.a-slice-header) {
        margin-bottom: 56px;
    }

    &__list {
        margin: 0 0 -16px;

        @media (--viewport-tablet) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -8px -16px;
        }
    }

    &__item {
        margin-bottom: 16px;

        @media (--viewport-tablet) {
            padding: 0 8px;
            @mixin colls 6;
        }

        @media (--viewport-desktop) {
            @mixin colls 12;
        }

        @media (--viewport-desktop-wide) {
            @mixin colls 6;
        }

        @media (--viewport-desktop-large) {
            @mixin colls 4;
        }
    }
}
</style>
