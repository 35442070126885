import {
    MOCK_SMALL_CARD_COMPANY,
    MOCK_SMALL_CARD_COMPANY_TRANSLATES,
    MOCK_SMALL_CARD_COMPANY_TRANSLATES_CARD,
    MOCK_SMALL_CARD_COMPANY_TRANSLATES_RATE,
} from '@core/components/small-card/mocks.js';

export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=52463-66130&t=LWcbKICEQRRYVaDf-4';

export const Default = {
    name: 'Default',
    args: {
        translations: {
            ...MOCK_SMALL_CARD_COMPANY_TRANSLATES,
            link: { text: 'Learn more' },
            cards: Array.from({ length: 12 }).map(() => ({
                ...MOCK_SMALL_CARD_COMPANY_TRANSLATES_CARD,
                iconAlt: 'Icon Title',
                logoAlt: 'Logo Alt',
                imageAlt: 'Image Alt',
                ...MOCK_SMALL_CARD_COMPANY_TRANSLATES_RATE,
            })),
            downloadFileArr: [
                {
                    desc: 'PDF, 0.9 MB',
                    title: 'Security at Acronis',
                },
            ],
        },
        leadIsVisible: true,
        isCompanyLayout: false,
        isRowLayout: false,
        isRateLayout: false,
        cards: Array.from({ length: 12 }).map((_, i) => ({
            ...MOCK_SMALL_CARD_COMPANY,
            to: i % 3 === 0 ? '#' : null,
            icon: '149',
            iconVariant: true,
            logo: '@71003d4ee3c6ac84146db1bf58873776',
            image: '@3ebd5147cf98e12eea516427eed4aaa0',
            rate: Array.from({ length: 4 }).map((__, j) => ({ points: j + 1 })),
        })),
        link: { to: '#' },
        downloadFileArr: [{ link: '#' }],
    },
};

export const Icon = {
    args: {
        translations: {
            ...MOCK_SMALL_CARD_COMPANY_TRANSLATES_CARD,
            title: 'Partner benefits',
            cards: Array.from({ length: 8 }).map(() => ({
                ...MOCK_SMALL_CARD_COMPANY_TRANSLATES_CARD,
                company: null,
                labels: null,
            })),
        },
        leadIsVisible: false,
        isCompanyLayout: false,
        isRowLayout: false,
        isRateLayout: false,
        cards: Array.from({ length: 8 }).map((_, i) => ({
            ...MOCK_SMALL_CARD_COMPANY,
            company: null,
            labels: null,
            to: i % 3 === 0 ? '#' : null,
            icon: (76 + i).toString(),
        })),
    },
};

export const Image = {
    args: {
        translations: {
            title: 'Partner benefits',
            cards: Array.from({ length: 8 }).map(() => ({
                title: 'Backup as a Service',
                text: '#1 hybrid cloud BaaS for any virtual, physical, and cloud environment',
                imageAlt: 'Alt',
            })),
        },
        leadIsVisible: false,
        isCompanyLayout: false,
        isRowLayout: false,
        isRateLayout: false,
        cards: Array.from({ length: 8 }).map((_, i) => ({
            to: i % 3 === 0 ? '#' : null,
            image: '/images/small-card/card-01',
        })),
    },
};

export const Logo = {
    args: {
        translations: {
            title: 'Partner benefits',
            cards: Array.from({ length: 8 }).map(() => ({
                title: 'Backup as a Service',
                text: '#1 hybrid cloud BaaS for any virtual, physical, and cloud environment',
            })),
        },
        leadIsVisible: false,
        isCompanyLayout: false,
        isRowLayout: false,
        isRateLayout: false,
        cards: Array.from({ length: 8 }).map((_, i) => ({
            to: i % 3 === 0 ? '#' : null,
            logo: '/images/logo/microsoft',
        })),
    },
};

export const Rate = {
    args: {
        translations: {
            ...MOCK_SMALL_CARD_COMPANY_TRANSLATES,
            link: { text: 'Learn more' },
            cards: [
                {
                    title: 'Registered',
                    text: 'Start by unlocking immediate discounts, training, and solution and technical support',
                    ...MOCK_SMALL_CARD_COMPANY_TRANSLATES_RATE,
                },
                {
                    title: 'Authorized',
                    text: `All of the benefits of a Registered Partner plus deeper discounts, marketing automation tools, 
                            a dedicated partner account manager, and more`,
                    ...MOCK_SMALL_CARD_COMPANY_TRANSLATES_RATE,
                },
                {
                    title: 'Gold',
                    text: `All of the benefits of an Authorized Partner, with bigger discounts, virtual credits, 
                            access to the MDF program, sports benefits, and more`,
                    ...MOCK_SMALL_CARD_COMPANY_TRANSLATES_RATE,
                },
                {
                    title: 'Platinum',
                    text: 'Full-service from top to bottom with the best discounts, enhanced support, and a dedicated team',
                    ...MOCK_SMALL_CARD_COMPANY_TRANSLATES_RATE,
                },
            ],
        },
        link: { to: '#' },
        leadIsVisible: true,
        isRowLayout: false,
        isRateLayout: true,
        isCompanyLayout: false,
        cards: Array.from({ length: 4 }).map((_, i) => ({
            rate: Array.from({ length: 4 }).map(() => ({ points: i + 2 })),
        })),
    },
};

export const Company = {
    args: {
        translations: {
            ...MOCK_SMALL_CARD_COMPANY_TRANSLATES,
            link: { text: 'Learn more' },
            cards: Array.from({ length: 4 }).map(() => ({
                ...MOCK_SMALL_CARD_COMPANY_TRANSLATES_CARD,
                title: 'Backup as a Service',
                text: '#1 hybrid cloud BaaS for any virtual, physical, and cloud environment',
            })),
        },
        link: { to: '#' },
        leadIsVisible: true,
        isCompanyLayout: true,
        isRowLayout: false,
        isRateLayout: false,
        cards: Array.from({ length: 4 }).map(() => (MOCK_SMALL_CARD_COMPANY)),
    },
};
