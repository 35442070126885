export const availableInBuilder: string[] = [
    's-awards',
    's-cards-small',
    's-cards-highlight',
    's-cta',
    's-cta-simple',
    's-customizable-header',
    's-features-large',
    's-features-small',
    's-header-message',
    's-header-product-new',
    's-intro',
    's-message-highlights',
    's-product-reviews',
    's-requirements',
    's-requirements-combo',
    's-resources',
    's-subscription',
    's-tabs',
    's-value-cards',
    's-video-featured',
    's-compete-page',
];
