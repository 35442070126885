<template>
    <div class="a-tooltip">
        <el-tooltip
            :placement="placement"
            :trigger="trigger"
            :open-delay="openDelay"
            :popper-class="popperClass"
        >
            <span class="tooltip-wrapper">
                <slot>
                    <a-glyph :name="glyph" />
                </slot>
            </span>
            <template #content>
                <a-dangerous-html v-if="isSimpleContent" class="text" :content="text" />
                <template v-else>
                    <div v-if="text.title" class="title">
                        {{ text.title }}
                    </div>
                    <a-dangerous-html v-if="text.content" class="content" :content="text.content" />
                    <template v-if="text.list">
                        <ul>
                            <li v-for="(item, i) in text.list" :key="i">
                                <a-link v-if="item.to" v-bind="item" />
                                <div v-else>
                                    {{ item.text }}
                                </div>
                            </li>
                        </ul>
                    </template>
                </template>
            </template>
        </el-tooltip>
    </div>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import { PLACEMENT, TRIGGER } from './constants.js';

export default {
    name: 'ATooltip',

    components: {
        ADangerousHtml,
        AGlyph,
        ALink,
        ElTooltip: () => import('@uikit/ui-kit/packages/tooltip'),
    },
    props: {
        text: {
            type: [String, Object],
            required: true,
        },

        placement: {
            type: String,
            default: 'top',
            validator: (val) => PLACEMENT.includes(val),
        },

        trigger: {
            type: String,
            default: 'hover',
            validator: (val) => TRIGGER.includes(val),
        },

        openDelay: {
            type: Number,
            default: 100,
        },

        popperClass: {
            type: String,
            default: '',
        },

        glyph: {
            type: String,
            default: 'tooltip-icon',
        },
    },
    computed: {
        isSimpleContent() {
            return typeof this.text === 'string';
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-tooltip {
    display: inline-block;

    &__title {
        font-weight: 600;
        margin-bottom: 8px;
    }

    .a-glyph {
        vertical-align: baseline;
    }
}
</style>
<style lang="postcss">
.el-tooltip {
    &__popper {
        max-width: 325px;
        border-radius: 4px;
        color: var(--av-inversed-primary);
        letter-spacing: 0;
        font-weight: 400;
        .text {
            @mixin caption;
        }
        .title {
            @mixin body-accent;
            margin-bottom: 8px;
        }
        .content {
            @mixin body;
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
        ul {
            list-style-position: inside;
            padding-inline-start: 16px;
            li {
                margin: 0;
            }
        }
    }
}

.tooltip-wrapper {
    cursor: pointer;
}
</style>
