<template>
    <div class="resource-home-header">
        <div class="container">
            <h1 class="title">
                {{ commonTranslations.homePageTitle }}
            </h1>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: 'ResourceHomeHeader',

    props: {
        commonTranslations: {
            type: Object,
            required: true,
        },
        gaSocialEvent: {
            type: Object,
            required: true,
        },
        socialLinks: {
            type: Array,
            required: true,
        },
    },

    data: () => ({}),

    computed: {},
};
</script>

<style lang="postcss" scoped>
.resource-home-header {
    width: 100%;

    .container {
        max-width: 1440px;
        margin: 32px auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        @media (--viewport-tablet) {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            padding: 0 32px;
        }

        @media (--viewport-desktop-wide) {
            padding: 0 64px;
        }
    }
}

.title {
    @mixin display-accent;
    color: var(--av-nav-primary);

    @media (--viewport-desktop) {
        @mixin hero;
    }
}

.follow-block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.follow-text {
    @mixin paragraph-accent;
    color: var(--av-nav-primary);
}

.a-social {
    margin-top: -6px;
}
</style>
