<template>
    <div class="resource-center-video">
        <div
            v-if="!locked"
            v-modal="ytId"
            class="video-content"
        >
            <img class="video-img" :src="image" loading="lazy" />
            <button
                ref="playButton"
                class="video-play"
                type="button"
                aria-label="Open video"
            >
                <a-glyph name="play" />
            </button>
        </div>
        <div v-else class="video-locked">
            <img class="video-img" :src="image" loading="lazy" />
            <div class="controls">
                <button
                    class="video-play"
                    type="button"
                    aria-label="Open video"
                >
                    <a-glyph name="play" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import Modal from '@core/directives/modal.js';

export default {
    name: 'ResourceVideo',

    components: {
        AGlyph,
    },

    directives: {
        Modal,
    },

    props: {
        ytId: {
            type: String,
            required: true,
        },

        locked: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        image() {
            return `//i3.ytimg.com/vi/${this.ytId}/maxresdefault.jpg`;
        },
    },

    methods: {
        triggerClick() {
            this.$refs.playButton?.click();
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-center-video {
    cursor: pointer;
    border-radius: 4px;
    aspect-ratio: 16/9;
    position: relative;
    overflow: hidden;
    &:hover {
        .video-play {
            background-color: var(--av-nav-secondary);
            transition: background-color ease-out 0.1s;
        }
    }

    .video-inner {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        height: 100%;
        width: 100%;
        border: var(--av-border);
        border-radius: 4px;
        overflow: hidden;
    }

    .video-img {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: transform 0.3s ease-out;
    }

    .video-play {
        position: absolute;
        top: 50%;
        inset-inline-start: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        display: flex;
        width: 48px;
        height: 48px;
        border: none;
        border-radius: 50%;
        background-color: var(--av-brand-secondary);
        transition: background-color ease-out 0.3s;
        cursor: pointer;

        @media (--viewport-desktop) {
            width: 72px;
            height: 72px;
        }

        .a-glyph {
            fill: var(--av-inversed-primary);
            margin: auto;
            width: 22px;
            height: 22px;

            @media (--viewport-desktop) {
                width: 32px;
                height: 32px;
            }
        }
    }

    .locked {
        display: none;
        position: absolute;
        inset-inline-start: 0;
        top: 0;
        inset-inline-end: 0;
        bottom: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--av-inversed-primary);
        cursor: auto;
    }
}
</style>
