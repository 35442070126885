<template>
    <main class="compete-page-wrapper">
        <CompeteHeader v-if="l10n.competeHeader" v-bind="l10n.competeHeader" />
        <TopHighlights v-if="l10n.topHighlights" v-bind="l10n.topHighlights" />
        <SMessageHighlights v-if="l10n.messageHighlights" class="message-highlight" v-bind="l10n.messageHighlights" />
        <G2ReviewsCard v-if="l10n.reviewsSummary" v-bind="l10n.reviewsSummary" />
        <ComparisonTables v-if="l10n.comparisonTables" v-bind="l10n.comparisonTables" />
        <STabs v-if="l10n.awardsTabs" class="tabs" v-bind="l10n.awardsTabs" />
        <STrialForm v-if="l10n.trialForm" class="trial-form" v-bind="l10n.trialForm" />
        <SResourceHighlight v-if="l10n.resourceHighlight" class="resource-highlight" v-bind="l10n.resourceHighlight" />
        <SFaq v-if="l10n.faq" v-bind="l10n.faq" />
    </main>
</template>

<script>
import Vue from 'vue';
import contentMixin from '@core/mixins/content.js';
import l10n from '@core/mixins/l10n.js';
import styleMixin from '@core/mixins/style.js';
import SAwards from '@core/slices/pages/awards/awards.vue';
import SFaq from '@core/slices/pages/faq/faq.vue';
import SMessageHighlights from '@core/slices/pages/message-highlights/message-highlights.vue';
import SResourceHighlight from '@core/slices/pages/resource-highlight/resource-highlight.vue';
import STabs from '@core/slices/pages/tabs/tabs.vue';
import STrialForm from '@core/slices/pages/trial-form/trial-form.vue';
import ComparisonTables from './components/comparison-tables.vue';
import CompeteHeader from './components/compete-header.vue';
import G2ReviewsCard from './components/g2-reviews-card.vue';
import TopHighlights from './components/top-highlights.vue';

Vue.component('SAwards', SAwards);

export default {
    components: {
        CompeteHeader,
        STabs,
        TopHighlights,
        G2ReviewsCard,
        ComparisonTables,
        SMessageHighlights,
        STrialForm,
        SResourceHighlight,
        SFaq,
    },
    mixins: [l10n, contentMixin, styleMixin],
    props: {
        competeHeader: {
            type: Object,
            default() {},
        },
        messageHighlights: {
            type: Object,
            default() {},
        },
        topHighlights: {
            type: Object,
            default() {},
        },
        reviewsSummary: {
            type: Object,
            default() {},
        },
        comparisonTables: {
            type: Object,
            default() {},
        },
        awardsTabs: {
            type: Object,
            default() {},
        },
        trialForm: {
            type: Object,
            default() {},
        },
        resourceHighlight: {
            type: Object,
            default() {},
        },
        faq: {
            type: Object,
            default() {},
        },
    },
    async serverPrefetch() {
        this.ssrRating = await this.$store.dispatch('products/getG2ProductRating', this.g2ProductId);
        this.ssrReviews = await this.$store.dispatch('products/getG2ProductReviews', this.g2ProductId);
    },
};
</script>

<style lang="postcss" scoped>
.compete-page-wrapper {
    position: relative;
    padding-top: 80px;

    @media (--viewport-desktop) {
        padding-top: 96px;
    }
    .resource-highlight {
        &:deep(.s-resource-highlight__header__content__title) {
            @mixin display-accent;
            @media (--viewport-desktop) {
                @mixin hero-accent;
            }
        }
    }
    .trial-form {
        &:deep(.s-info-blocks) {
            .title-item {
                @mixin display-accent;
                @media (--viewport-desktop) {
                    @mixin hero-accent;
                }
            }
            .info {
                margin-top:24px;
                .info-description {
                    @mixin title;
                    color: var(--av-fixed-primary);
                }
            }
        }
        &:deep(.el-form) {
            .a-button {
                border-radius: 12px;
                background: linear-gradient(90deg, #519EFF 0%, #0071FF 50%, #005BCC 100%);
                &:hover {
                    background: linear-gradient(90deg, #217DEE 0%, #0062DB 50%, #0054BC 100%);
                }
                &:active {
                    background: linear-gradient(90deg, #126EE1 0%, #005BCB 50%, #0051B6 100%);
                }
            }
        }
    }
    .message-highlight {
        &:deep(.a-slice-header__title) {
            @mixin display-accent;
            @media (--viewport-desktop) {
                @mixin hero-accent;
            }
        }
    }
    .tabs {
        padding: 64px 0;
        &:deep(.a-tabs) {
            .a-tabs__header {
                margin-top: 0;
            }
            .a-tabs-item__label {
                @mixin title-accent;
            }

            .a-slice-header__title {
                @mixin display-accent;
                @media (--viewport-tablet) {
                    @mixin hero-accent;
                }
            }
        }
    }
}
</style>
