<template>
    <div class="root">
        <page-not-found :footer="footer" v-bind="pageSettings" />
    </div>
</template>

<script>
import PageNotFound from '@core/slices/developer/page-not-found/page-not-found.vue';

import '@core/styles/sections/pages.pcss';

export default {
    name: 'Error404Component',

    components: {
        PageNotFound,
    },

    computed: {
        footer() {
            return {
                theme: 'dark',
                hasLocaleSelector: false,
                sliceSettingsName: 's-preset-developer-footer',
            };
        },

        pageSettings() {
            return this.$store.state.slices.items['developer-acronis-com-404'];
        },
    },
};
</script>
