<template>
    <main class="acronis-scs-main-page">
        <section v-if="intro" class="intro">
            <div class="a-container">
                <div class="wrapper">
                    <div class="content">
                        <h1 v-if="intro.title" class="title" v-html="intro.title" />
                        <p v-if="intro.lead" class="lead" v-html="intro.lead" />
                        <a-button
                            v-if="intro.button"
                            class="button"
                            v-bind="intro.button"
                            :type="intro.button.type || 'action'"
                        />
                        <div v-if="intro.certifications" class="certifications">
                            <h3
                                v-if="intro.certifications.title"
                                class="certifications-title"
                                v-html="intro.certifications.title"
                            />
                            <div class="logos">
                                <a-picture
                                    v-for="(logo, index) in intro.certifications.logos"
                                    :key="`certification_logos_${index}`"
                                    :link="logo"
                                />
                            </div>
                            <a-link
                                v-if="intro.certifications.link"
                                class="link"
                                v-bind="intro.certifications.link"
                                :glyph="intro.certifications.link.glyph || 'arrow'"
                                :size="intro.certifications.link.size || 'paragraph'"
                            />
                        </div>
                    </div>
                    <div class="media-wrapper">
                        <a-media v-if="intro.media" v-bind="intro.media" class="media" />
                    </div>
                </div>
            </div>
        </section>
        <section v-if="cta" class="cta">
            <div class="a-container">
                <div class="wrapper">
                    <p v-if="cta.text" v-html="cta.text" />
                    <a-button
                        v-if="cta.button"
                        class="button"
                        v-bind="cta.button"
                        :type="cta.button.type || 'action'"
                        :glyph="cta.button.glyph || 'arrow'"
                    />
                </div>
            </div>
        </section>
        <section v-if="content" class="content-blocks">
            <div class="a-container">
                <div class="head">
                    <h2 v-if="content.title" class="title" v-html="content.title" />
                    <p v-if="content.lead" class="lead" v-html="content.lead" />
                </div>
                <div v-if="content.sections && content.sections.length" class="sections">
                    <section
                        v-for="(section, index) in content.sections"
                        :key="`content_sections_${index}`"
                        class="section"
                        :class="sectionClassName(section)"
                    >
                        <div class="wrapper" :class="{ reversed: section.reversed }">
                            <div class="content">
                                <h4 v-if="section.title" class="title" v-html="section.title" />
                                <p
                                    v-if="section.text"
                                    class="text"
                                    :class="{ 'has-link': section.link }"
                                    v-html="section.text"
                                />
                                <a-link
                                    v-if="section.link && !isMobile"
                                    class="link"
                                    v-bind="section.link"
                                    :size="section.link.size || 'title'"
                                    :glyph="section.link.glyph || 'arrow'"
                                />
                                <a-link
                                    v-if="section.link && isMobile"
                                    class="link-mobile"
                                    v-bind="section.link"
                                    :size="section.link.size || 'lead'"
                                    :glyph="section.link.glyph || 'arrow'"
                                />
                            </div>
                            <div class="media-wrapper">
                                <a-media
                                    v-if="section.media"
                                    v-bind="section.media"
                                    class="media"
                                />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
        <section v-if="testimonials" class="testimonials">
            <div class="a-container">
                <div class="wrapper">
                    <div class="head">
                        <h3 v-if="testimonials.title" class="title">
                            {{ testimonials.title }}
                        </h3>
                        <p v-if="testimonials.lead" class="lead" v-html="testimonials.lead" />
                    </div>
                    <a-slider
                        v-if="testimonials.items"
                        class="slider-wrapper"
                        :options="{ ...sliderOptions, loop: testimonials.infinite }"
                        :class="{
                            'a-slider_off': testimonials.items.length === 1,
                        }"
                    >
                        <a-slide
                            v-for="(item, index) in testimonials.items"
                            :key="`testimonials_item_${index}`"
                        >
                            <div class="testimonial">
                                <p v-if="item.text" class="text" v-html="item.text" />
                                <div v-if="item.author" class="author">
                                    <p v-if="item.author.name" class="name">
                                        {{ item.author.name }}
                                    </p>
                                    <p v-if="item.author.title" class="author-title">
                                        {{ item.author.title }}
                                    </p>
                                </div>
                            </div>
                        </a-slide>
                    </a-slider>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import AMedia from '@core/components/media/media.vue';
import APicture from '@core/components/picture/picture.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'SAcronisScsMainPage',
    components: {
        APicture,
        AMedia,
        AButton,
        ALink,
        ASlider,
        ASlide,
    },
    mixins: [breakpoint],
    props: {
        intro: {
            type: Object,
            default: undefined,
        },
        cta: {
            type: Object,
            default: undefined,
        },
        content: {
            type: Object,
            default: undefined,
        },
        testimonials: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            sliderOptions: {
                init: this.testimonials.items.length > 1,
                showNavigation: true,
                // disabled due to Swiper conflict between the Fraction pagination and slidesPerView: auto
                showCounter: false,
                counterText: this.testimonials.counterText || 'of',
                sendEvent: true,
                slideName: 'testimonial',
                slidesPerView: 'auto',
            },
        };
    },
    methods: {
        sectionClassName(section) {
            return {
                'section_border-top': section.borderTop,
                'section_border-bottom': section.borderBottom,
                [`s-slice_border-top_${section.borderTop}`]: section.borderTop,
                [`s-slice_border-bottom_${section.borderBottom}`]: section.borderBottom,
                [section.padding]: section.padding,
                [section.margin]: section.margin,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
main.acronis-scs-main-page {
    section.intro {
        padding-top: 88px;
        padding-bottom: 48px;
        background: linear-gradient(180deg, #f4f7fc 0%, #fff 100%);
        @media (--viewport-mobile-wide) {
            padding-top: 128px;
        }
        @media (--viewport-tablet) {
            padding-top: 120px;
        }
        @media (--viewport-desktop) {
            padding-top: 144px;
        }
        .wrapper {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: flex-start;
            .content {
                @mixin colls 12;
                color: var(--av-nav-primary);
                @media (--viewport-desktop) {
                    @mixin colls 4;
                }
                .title {
                    @mixin display-accent;
                    margin-bottom: 16px;

                    @media (--viewport-mobile-wide) {
                        @mixin hero-accent;
                    }

                    @media (--viewport-desktop) {
                        @mixin large-accent;
                    }
                }
                .lead {
                    @mixin lead;
                    margin-bottom: 24px;
                    @media (--viewport-tablet) {
                        margin-bottom: 40px;
                    }
                }
                .button {
                    @mixin paragraph;
                    padding: 8px 32px;
                    font-weight: 400;
                    @media (--viewport-mobile-wide) {
                        padding: 8px 30px;
                    }
                }
                .certifications {
                    margin-top: 32px;
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    justify-content: space-between;
                    @media (--viewport-mobile-wide) {
                        margin-top: 40px;
                    }
                    @media (--viewport-desktop-wide) {
                        margin-top: 48px;
                    }
                    .certifications-title {
                        @mixin colls 12;
                        @mixin lead;
                        font-weight: 500;
                        margin-bottom: 16px;
                        @media (--viewport-mobile-wide) {
                            @mixin colls 4;
                            margin-bottom: 0;
                        }
                        @media (--viewport-tablet) {
                            width: auto;
                        }
                        @media (--viewport-desktop) {
                            @mixin colls 12;
                            margin-bottom: 16px;
                        }
                    }
                    .logos {
                        display: flex;
                        flex-flow: row nowrap;
                        gap: 16px;
                        align-items: flex-start;
                        @media (--viewport-desktop) {
                            @mixin colls 12;
                        }
                        &:deep(.a-picture__img) {
                            width: auto;
                            height: 48px;
                        }
                    }
                    .link {
                        @mixin colls 12;
                        margin-top: 16px;
                        @media (--viewport-tablet) {
                            width: auto;
                        }
                        @media (--viewport-desktop) {
                            @mixin colls 12;
                        }
                    }
                }
            }
            .media-wrapper {
                @mixin colls 12;
                margin-top: 40px;
                @media (--viewport-desktop) {
                    @mixin colls 8;
                    margin: 0;
                }
            }
        }
    }
    section.cta {
        background: var(--av-solid-brand-lightest);
        padding: 32px 16px;
        @media (--viewport-mobile-wide) {
            padding: 32px 0;
        }
        @media (--viewport-desktop) {
            padding: 44px 0;
        }
        .wrapper {
            display: flex;
            align-items: center;
            flex-flow: row wrap;
            justify-content: space-between;
            p {
                @mixin lead;
                width: 100%;
                color: var(--av-nav-primary);
                margin-bottom: 24px;
                @media (--viewport-mobile-wide) {
                    margin-bottom: 40px;
                }
                @media (--viewport-tablet) {
                    width: auto;
                    margin: 0;
                }
                &:deep(strong) {
                    @mixin title;
                    display: block;
                    font-weight: 700;
                    color: rgba(179, 25, 66, 1);
                    @media (--viewport-desktop) {
                        display: inline-block;
                    }
                }
            }
            .button {
                padding: 12px 16px;
                @mixin paragraph;
                font-weight: 700;
            }
        }
    }
    section.content-blocks {
        padding-top: 48px;
        color: var(--av-nav-primary);
        @media (--viewport-mobile-wide) {
            padding-top: 64px;
        }
        .head {
            @mixin colls 12;
            @media (--viewport-desktop) {
                @mixin colls 8;
            }
            .title {
                margin-bottom: 24px;
                @mixin display;
                @media (--viewport-mobile-wide) {
                    @mixin hero;
                }
                &:deep(br) {
                    @media (--viewport-desktop-wide) {
                        display: none;
                    }
                }
            }
            .lead {
                @mixin lead;
            }
        }
        .sections {
            padding-top: 48px;
            padding-bottom: 64px;
            @media (--viewport-mobile-wide) {
                padding-top: 64px;
                padding-bottom: 88px;
            }
            .section {
                @mixin border-top;
                @mixin border-bottom;
                padding: 48px 0;
                color: var(--av-nav-primary);
                @media (--viewport-mobile-wide) {
                    padding: 64px 0;
                }
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    padding-bottom: 0;
                }
                .wrapper {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    justify-content: center;
                    @media (--viewport-desktop) {
                        justify-content: space-between;
                    }
                    .content {
                        @mixin colls 12;
                        margin-bottom: 40px;
                        @media (--viewport-desktop) {
                            @mixin colls 6;
                            margin-bottom: 0;
                        }
                        .title {
                            @mixin lead;
                            font-weight: 700;
                            margin-bottom: 24px;
                            @media (--viewport-mobile-wide) {
                                @mixin title;
                                font-weight: 400;
                            }
                        }
                        .text {
                            @mixin paragraph;
                            &.has-link {
                                margin-bottom: 40px;
                            }
                        }
                        &:deep(.link),
                        &:deep(.link-mobile) {
                            .a-link__content {
                                font-weight: 700;
                            }
                        }
                    }
                    .media-wrapper {
                        @mixin colls 12;
                        @media (--viewport-mobile-wide) {
                            @mixin colls 8;
                        }
                        @media (--viewport-desktop) {
                            @mixin colls 5;
                        }
                    }
                    &.reversed {
                        flex-flow: row wrap-reverse;
                        @media (--viewport-desktop) {
                            flex-flow: row-reverse;
                        }
                        .content {
                            margin: 0;
                        }
                        .media-wrapper {
                            margin-bottom: 40px;
                            @media (--viewport-desktop) {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    section.testimonials {
        padding: 48px 0 64px;
        background: linear-gradient(180deg, #f4f7fc 0%, #fff 100%);
        color: var(--av-nav-primary);
        overflow: hidden;
        @media (--viewport-mobile-wide) {
            padding: 64px 0;
        }
        .head {
            @mixin colls 12;
            @media (--viewport-desktop) {
                @mixin colls 8;
            }
            .title {
                @mixin display;
                margin-bottom: 24px;
                @media (--viewport-mobile-wide) {
                    @mixin hero;
                }
            }
            .lead {
                @mixin lead;
                margin-bottom: 32px;
                @media (--viewport-mobile-wide) {
                    @mixin title;
                    margin-bottom: 40px;
                }
                @media (--viewport-tablet) {
                    margin-bottom: 48px;
                }
            }
        }
        &:deep(.a-slider) {
            @media (--viewport-tablet) {
                overflow: visible;
            }
            .a-slide {
                @mixin colls 12;
                @media (--viewport-tablet) {
                    @mixin colls 9;
                    margin-inline-end: calc(8.3333% + 17.3333px);
                }
                @media (--viewport-desktop) {
                    @mixin colls 8;
                }
                &:last-child {
                    @mixin colls 12;
                    margin-inline-end: 0;
                    .testimonial {
                        @mixin colls 12;
                        @media (--viewport-tablet) {
                            @mixin colls 9;
                        }
                        @media (--viewport-desktop) {
                            @mixin colls 8;
                        }
                    }
                }
                .testimonial {
                    @mixin colls 12;
                    @mixin paragraph;
                    color: var(--av-fixed-secondary);
                    .text {
                        margin-bottom: 32px;
                    }
                }
            }

            &:deep(.a-slider__nav) {
                margin: 0;
                margin-top: 32px;
                @media (--viewport-mobile-wide) {
                    margin-top: 48px;
                }
                @media (--viewport-tablet) {
                    margin-top: 40px;
                }
                @media (--viewport-desktop) {
                    margin-top: 48px;
                }

                .a-slider__counter {
                    &-current,
                    &-text,
                    &-total {
                        color: var(--av-fixed-light);
                        font-size: 18px;
                    }
                    &-current {
                        font-weight: 600;
                    }
                    &-text {
                        margin: 0 8px;
                    }
                }

            }
            &.a-slider_off {
                .a-slide {
                    opacity: 1;
                }
                .a-slider__nav {
                    display: none;
                }
            }
        }
    }
}
</style>
