<template>
    <div class="event-filters" :class="{ hidden: filtersHidden }">
        <client-only>
            <el-form ref="form" class="form" :model="form">
                <template v-if="categories">
                    <template v-if="isMobile || isTablet">
                        <div class="head-row">
                            <button class="filter-toggle" @click="toggleFilter">
                                {{ filterTitle }}
                                <a-glyph class="glyph" name="controls" />
                            </button>
                        </div>
                        <el-form-item prop="categoriesFilter">
                            <el-select
                                v-model="form.category"
                                class="select"
                                name="categoriesFilter"
                                :label="categories.title"
                                :placeholder="categories.placeholder"
                                :hide-on-resize="isDesktop"
                                popper-class="dropdown"
                                :change-on-options-removal="false"
                                clearable="clearable"
                                @change="filterChanged"
                            >
                                <el-option
                                    v-for="(item, i) in categories.items"
                                    :key="i"
                                    :label="item.title"
                                    :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </template>
                    <template v-else>
                        <event-tab-control
                            class="categories"
                            :tabs="categoriesForTabControl"
                            :active-tab-id="activeCategoryForTabControl"
                            @change="eventCategoryChange"
                        />
                    </template>
                </template>
                <div class="form-row">
                    <el-form-item prop="productsFilter">
                        <el-select
                            v-model="form.product"
                            class="select"
                            name="productsFilter"
                            :label="products.title"
                            :placeholder="products.placeholder"
                            :hide-on-resize="isDesktop"
                            popper-class="dropdown"
                            :change-on-options-removal="false"
                            clearable="clearable"
                            @change="filterChanged"
                        >
                            <el-option
                                v-for="(item, i) in products.items"
                                :key="i"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="dateFilter">
                        <el-date-picker
                            v-model="form.date"
                            class="datepicker"
                            type="daterange"
                            clearable="clearable"
                            :placeholder="date.placeholder"
                            :start-placeholder="date.startPlaceholder"
                            :end-placeholder="date.endPlaceholder"
                            :label="date.title"
                            popper-class="calendar"
                            @change="filterChanged"
                        />
                    </el-form-item>
                    <el-form-item v-if="audiences" prop="audiencesFilter">
                        <el-select
                            v-model="form.audience"
                            class="select"
                            name="audiencesFilter"
                            :label="audiences.title"
                            :placeholder="audiences.placeholder"
                            :hide-on-resize="isDesktop"
                            popper-class="dropdown"
                            :change-on-options-removal="false"
                            clearable="clearable"
                            @change="filterChanged"
                        >
                            <el-option
                                v-for="(item, i) in audiences.items"
                                :key="i"
                                :label="item.title"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="languageFilter">
                        <el-select
                            v-model="form.language"
                            class="select"
                            name="languageFilter"
                            :label="languages.title"
                            :placeholder="languages.placeholder"
                            :hide-on-resize="isDesktop"
                            popper-class="dropdown"
                            :change-on-options-removal="false"
                            clearable="clearable"
                            @change="filterChanged"
                        >
                            <el-option
                                v-for="(item, i) in languages.items"
                                :key="i"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                    <button v-if="isMobile || isTablet" class="event-button submit" @click="load">
                        {{ submitText }}
                    </button>
                </div>
            </el-form>
        </client-only>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ClientOnly from '@core/components/client-only/client-only.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import EventTabControl from '../shared-components/event-tab-control/event-tab-control.vue';

export default {
    name: 'EventFilters',
    components: {
        ElForm: () => import('@uikit/ui-kit/packages/form'),
        ElFormItem: () => import('@uikit/ui-kit/packages/form-item'),
        ElSelect: () => import('@uikit/ui-kit/packages/select'),
        ElOption: () => import('@uikit/ui-kit/packages/option'),
        ElDatePicker: () => import('@uikit/ui-kit/packages/date-picker'),
        ClientOnly,
        EventTabControl,
        AGlyph,
    },
    mixins: [breakpoint],
    props: {
        categories: {
            type: Object,
            default: undefined,
        },
        audiences: {
            type: Object,
            default: undefined,
        },
        date: {
            type: Object,
            required: true,
        },
        products: {
            type: Object,
            required: true,
        },
        languages: {
            type: Object,
            required: true,
        },
        sorting: {
            type: Object,
            required: true,
        },
        filterTitle: {
            type: String,
            required: true,
        },
        submitText: {
            type: String,
            required: true,
        },
        query: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['filterChange'],
    data() {
        return {
            form: {
                category: '',
                product: '',
                date: null,
                audience: '',
                language: '',
                sorting: 'recent',
            },
            filtersHidden: false,
        };
    },

    computed: {
        ...mapState({
            filterForm: (state) => state.events.filterForm,
        }),

        sortingButtons() {
            return [
                {
                    value: 'recent',
                    title: this.sorting.recent,
                },
                {
                    value: 'popular',
                    title: this.sorting.popular,
                },
            ];
        },

        // tabs need to have additional element for 'All events'
        categoriesForTabControl() {
            if (!this.categories) return [];

            return [
                {
                    id: 'all',
                    title: this.categories.placeholder,
                },
                ...this.categories.items,
            ];
        },

        activeCategoryForTabControl() {
            return this.form.category === '' ? 'all' : this.form.category.toString();
        },
    },

    watch: {
        filterForm(value) {
            this.form = { ...value };
        },
    },

    mounted() {
        ['category', 'product', 'audience', 'language'].forEach((key) => {
            this.form[key] = parseInt(this.query[key], 10) || '';
        });
        this.form.date = this.query.date || null;
        this.$store.dispatch('events/setFilterForm', this.form);
        this.load();
    },

    methods: {
        eventCategoryChange(item) {
            this.form.category = item.id === 'all' ? '' : parseInt(item.id, 10);
            this.filterChanged();
        },

        sortingChange(item) {
            this.form.sorting = item.value;
            this.filterChanged();
        },

        filterChanged() {
            this.$store.dispatch('events/setFilterForm', this.form);

            if (this.isDesktop) {
                this.load();
            }
        },

        load() {
            this.$emit('filterChange');
        },

        toggleFilter() {
            this.filtersHidden = !this.filtersHidden;
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-filters {
    overflow: hidden;
    max-height: 600px;
    transition: max-height ease 0.2s;

    &.hidden {
        max-height: 26px;

        @media (--viewport-desktop) {
            max-height: none;
        }
    }

    @media (--viewport-desktop) {
        overflow: visible;
    }

    .head-row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 24px;
    }

    .filter-toggle {
        background: transparent;
        border: 0;
        outline: none;
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        color: var(--av-nav-primary);
        display: flex;
        align-items: center;

        .glyph {
            margin-inline-start: 8px;
        }
    }

    .form-row {
        @media (--viewport-desktop) {
            display: grid;
            column-gap: 16px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            margin-bottom: 40px;
        }
    }

    &:deep(.el-form-item) {
        padding-bottom: initial;
    }

    .submit {
        width: 100%;
        padding: 12px;
        margin-top: 4px;
        color: var(--av-inversed-primary);
        @mixin paragraph;
        font-weight: 500;
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            margin-bottom: 40px;
        }
    }
}
</style>

<style lang="postcss">
.event-filters {
    .el-input__container {
        height: 46px;
    }
    .el-input__placeholder {
        color: var(--av-fixed-primary);
    }
    .el-input__icon {
        display: flex;
    }

    .el-select-dropdown__item {
        span:first-letter {
            text-transform: capitalize;
        }
    }
}

.el-picker-panel.calendar {
    border-color: var(--av-brand-secondary);
    width: 296px;
    inset-inline-start: 16px;
    inset-inline-end: 16px;
    margin-inline-start: auto;
    margin-inline-end: auto;

    @media (--viewport-mobile-wide) {
        width: auto;
        inset-inline-end: auto;
    }

    .el-picker-panel__body {
        flex-direction: column;
        align-items: center;

        @media (--viewport-mobile-wide) {
            flex-direction: row;
            align-items: normal;
        }
    }

    .el-date-range-picker {
        &__header {
            border-bottom: 1px solid var(--av-brand-secondary-light);
        }

        &__content {
            &:not(:first-child) {
                border-color: var(--av-brand-secondary-light);
            }
        }
    }

    .el-date-picker__header-btn {
        color: var(--av-brand-secondary);
    }

    .el-date-table {
        thead {
            border-bottom: 1px solid var(--av-brand-secondary-light);
        }

        tbody {
            td.available div:hover,
            td.in-range div,
            td.in-range div:hover {
                background: var(--av-brand-secondary-accent);
            }

            td.end-date,
            td.start-date {
                div,
                div:hover {
                    background: var(--av-brand-secondary-accent);
                }
            }
        }
    }
}
</style>
