<template>
    <s-basic-slice class="s-webinar" v-bind="$attrs">
        <template v-if="!isSliderUsed">
            <a-webinar-card class="s-webinar__card" v-bind="baseWebinar" />
            <div class="s-webinar__mini-cards">
                <a-webinar-card
                    v-for="(item, index) in miniWebinars"
                    :key="index"
                    class="s-webinar__card"
                    v-bind="item"
                />
            </div>
        </template>
        <template v-else>
            <div class="s-webinar-item">
                <a-webinar-card class="s-webinar__card" v-bind="baseWebinar" />
            </div>
            <a-slider :options="sliderOptions" :data-slides="miniWebinars.length">
                <a-slide v-for="(item, index) in miniWebinars" :key="index">
                    <a-webinar-card class="s-webinar__card" v-bind="item" />
                </a-slide>
            </a-slider>
        </template>
        <a-link
            v-if="link && hasTitle"
            class="s-webinar__link bottom-link"
            v-bind="link"
            :to="link.to || link"
            :type="link.type || 'direct'"
            :glyph="link.glyph || 'arrow'"
            :text="link.text || linkText"
            :size="link.size || 'title'"
        />
    </s-basic-slice>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import AWebinarCard from '@core/components/webinar-card/webinar-card.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SWebinar',

    components: {
        ALink,
        ASlide,
        ASlider,
        AWebinarCard,
        SBasicSlice,
    },

    mixins: [breakpoint],

    props: {
        link: {
            type: [Object, String],
            default: null,
        },

        linkText: {
            type: String,
            default: '',
        },

        webinar: {
            type: Array,
            required: true,
        },
    },

    data() {
        const length = this.webinar.length;
        const showPagination = length > 2;
        const sliderOptions = {
            showNavigation: showPagination,
            loop: showPagination,
            // disabled due to Swiper conflict between the Fraction pagination and slidesPerView: auto
            showCounter: false,
            slideName: 'webinar',
            slidesPerView: 'auto',
        };

        return {
            webinarList: this.webinar,
            sliderOptions,
        };
    },

    computed: {
        webinars() {
            return this.webinarList.map((el, i) => {
                const MAX_SYMBOLS_DESC = 200;
                const length = this.webinarList.length;
                const speakers = (el.speakers || []).slice(0, i === 0 ? 2 : 0);
                const largeText = el.desc && el.desc.length > MAX_SYMBOLS_DESC;
                const parsedText = el.desc && `${el.desc.slice(0, MAX_SYMBOLS_DESC)} ...`;

                return {
                    ...el,
                    // This is a compatibility hack for old pages until admin JSONs get updated
                    link: {
                        to: el.link.to || el.link,
                        text: el.link.text || el.linkText,
                    },
                    // every card except the first one is "miniCard"
                    miniCard: i !== 0,
                    cardShadow: !i || length === 2,
                    speakers,
                    desc: el.desc && largeText ? parsedText : el.desc,
                };
            });
        },
        hasTitle() {
            return this.$attrs.title;
        },
        isSliderUsed() {
            return (this.webinars.length === 3 && (this.isMobile || this.isTablet)) || this.webinars.length > 3;
        },
        baseWebinar() {
            return this.webinars[0];
        },
        miniWebinars() {
            return this.webinars.slice(1, this.webinars.length);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-webinar {
    &__link {
        margin-top: 40px;
        @media (--viewport-desktop) {
            position: absolute;
            margin-top: 0;
            top: 10px;
            inset-inline-end: 32px;
        }
        @media (--viewport-desktop-wide) {
            inset-inline-end: 64px;
        }
        @media (--viewport-desktop-large) {
            inset-inline-end: 0;
        }
    }

    &__mini-cards {
        display: flex;
        justify-content: flex-start;

        @media (--viewport-desktop-wide) {
            width: 85%;
        }

        .s-webinar__card {
            @media(--viewport-desktop-wide) {
                width: 100%;
            }
        }
    }

    &:deep(.a-slice-header) {
        margin-bottom: 24px;

        @media (--viewport-tablet) {
            margin-bottom: 40px;
        }
    }

    &:deep(.a-slider) {
        &__counter {
            &-total,
            &-text,
            &-current {
                color: var(--av-fixed-light);
                font-size: 18px;
            }
        }

        /* Swiper.js has a bug with looped slides when there are only 2 slides
         * when the same slide is visible 2 times, "next" button doesnt generate additional looped slides
         * Solution: only when there are 2 slides, give them each 50% width on desktop+ */
        &[data-slides='2'] {
            .a-slide {
                @media (--viewport-desktop) {
                    width: 50%;
                }
            }
        }
    }

    &:deep(.a-slide) {
        position: relative;
        inset-inline-start: 0;
        width: 85%;

        /* opacity: 0.3; */

        @media (--viewport-tablet) {
            width: 60%;
        }

        @media (--viewport-desktop) {
            width: 40%;

            &_active,
            &_next {
                opacity: 1;
            }
        }

        .a-slider_off & {
            width: 100%;
        }

        &_active {
            opacity: 1;
        }
    }
}
</style>
