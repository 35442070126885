import SchemaHandler from '@model/seo/schemaHandler';
import { ContactPointSchema, SchemaConfig } from '@model/seo/schemas';

export default class ContactPointHandler implements SchemaHandler {
    ctx: any;

    schemasConfig: SchemaConfig[];

    constructor(ctx: any, schemasConfig: SchemaConfig[]) {
        this.ctx = ctx;
        this.schemasConfig = schemasConfig;
    }

    getSchemaData(staticConfig: ContactPointSchema): Promise<ContactPointSchema> {
        return Promise.resolve(this.getRequiredFields(staticConfig));
    }

    getRequiredFields(staticConfig: ContactPointSchema): ContactPointSchema {
        return { ...staticConfig };
    }
}
