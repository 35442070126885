<template>
    <div class="event-tab-control">
        <div class="header">
            <div ref="list" class="list-wrapper">
                <ul class="list">
                    <li v-for="tab in tabs" :key="tab.id" class="item">
                        <button
                            :id="tab.id"
                            :ref="`tab${tab.id}`"
                            class="item-button"
                            type="button"
                            :class="{ active: isTabActive(tab.id) }"
                            @click="changeTab(tab.id)"
                        >
                            <div class="label">
                                {{ tab.title }}
                            </div>
                        </button>
                    </li>
                </ul>
                <div ref="underline" class="underline" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventTabControl',
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        activeTabId: {
            type: String,
            default: null,
        },
    },

    emits: ['change'],

    data() {
        return {};
    },

    computed: {
        activeTab() {
            return this.$refs?.[`tab${this.activeTabId}`]?.[0];
        },
    },

    watch: {
        activeTabId() {
            this.moveUnderline();
        },
    },

    mounted() {
        window.addEventListener('resize', this.moveUnderline);

        // size of 'tabs' is changed when fontface loads
        document.fonts.addEventListener('loadingdone', this.moveUnderline);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.moveUnderline);
        document.fonts.removeEventListener('loadingdone', this.moveUnderline);
    },

    methods: {
        isTabActive(id) {
            return this.activeTabId === id.toString();
        },

        changeTab(id) {
            this.$emit('change', { id: id.toString() });
        },

        moveUnderline() {
            if (!this.activeTab || !this.$refs.list) {
                return;
            }

            const activeTabRect = this.activeTab.getBoundingClientRect();
            const btnWidth = activeTabRect.width;
            const btnLeftCoord = activeTabRect.left;
            const tabsContainerLeftXCoord = this.$refs.list.getBoundingClientRect().left;
            const tabsContainerScrollLeft = this.$refs.list.scrollLeft;

            this.$refs.underline.style.width = `${btnWidth}px`;
            this.$refs.underline.style.left = `${
                btnLeftCoord - tabsContainerLeftXCoord + tabsContainerScrollLeft
            }px`;
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-tab-control {
    .header {
        margin-bottom: 40px;
        position: relative;
        display: flex;
        overflow-x: auto;
        overflow-y: visible;
        transition: transform 0.3s ease-out;
        scrollbar-width: none;
        margin-inline-end: -16px;
        padding-inline-end: 16px;

        &::-webkit-scrollbar {
            height: 0;
            background: transparent;
        }

        @media (--viewport-tablet) {
            margin-inline-end: -32px;
            padding-inline-end: 32px;
        }

        @media (--viewport-desktop) {
            margin-bottom: 24px;
        }
    }

    .list-wrapper {
        position: relative;
        flex: 1;

        &::before {
            content: '';
            position: absolute;
            inset-inline-start: 0;
            bottom: 0;
            z-index: 1;
            width: 100%;
            height: 2px;
            background-color: var(--av-brand-secondary-accent);
        }
    }

    .list {
        position: relative;
        display: flex;
        padding-bottom: 2px;
    }

    .item {
        &:not(:last-of-type) {
            margin-inline-end: 24px;

            @media (--viewport-mobile-wide) {
                margin-inline-end: 40px;
            }
        }
    }

    .item-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-bottom: 8px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        position: relative;

        &.active {
            pointer-events: none;

            .label {
                color: var(--av-nav-primary);
            }
        }
    }

    .label {
        @mixin lead;

        width: 100%;
        color: var(--av-brand-secondary);
        white-space: nowrap;
        font-weight: 500;

        &:hover {
            color: var(--av-brand-primary);
        }
    }

    .underline {
        display: block;
        background-color: var(--av-nav-primary);
        position: absolute;
        bottom: 0;
        z-index: 2;
        height: 2px;
        transition: left ease-out 0.3s, width ease-out 0.3s;
    }

    .hidden {
        display: none;
    }
}
</style>
