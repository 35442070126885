<template>
    <div class="resource-cta-card">
        <div class="title">
            <div class="logo-wrap">
                <a-logo type="full" fill="dark" />
                <span>{{ title }}</span>
            </div>
            <span class="subtitle">
                {{ subtitle }}
            </span>
        </div>

        <div class="cta-buttons">
            <a-link :to="learnMoreLink" :text="learnMoreText" />
            <a-button :to="tryNowLink" type="action" :text="tryNowText" />
        </div>
    </div>
</template>

<script lang="ts">
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';

export default {
    components: {
        AButton,
        ALink,
        ALogo,
    },

    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
        learnMoreText: {
            type: String,
            required: true,
        },
        learnMoreLink: {
            type: String,
            required: true,
        },
        tryNowText: {
            type: String,
            required: true,
        },
        tryNowLink: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.resource-cta-card {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    background: var(--av-inversed-primary);
    border: 2px solid var(--av-brand-secondary-light);
    box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
    border-radius: 4px;
    padding: 20px;

    @media (--viewport-mobile-wide) {
        padding: 10px 24px;
        justify-content: space-between;
        align-items: center;
    }

    @media (--viewport-tablet) {
        flex-direction: row;
        max-width: 790px;
        margin: 40px auto 0;
        padding: 10px 40px;
    }

    @media (--viewport-tablet) {
        max-width: 870px;
    }
}

.logo-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;

    @media (--viewport-desktop) {
        align-items: center;
    }
}

.logo-wrap svg {
    transform: translateY(1px);
    flex-shrink: 0;
}

.logo-wrap span {
    flex-shrink: 0;
}

.title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
    margin-bottom: 2px;
    font-weight: 300;
    font-size: 24px;
    line-height: 27px;
    color: var(--av-nav-primary);

    @media (--viewport-tablet) {
        text-align: start;
        align-items: flex-start;
    }

    &:deep(.a-logo) {
        width: 89px;
    }
}

.subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--av-brand-secondary);
}

.cta-buttons {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    @media (--viewport-tablet) {
        margin-top: 0;
    }
}

.cta-buttons .a-button {
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
}
</style>
