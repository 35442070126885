<template>
    <div class="academy">
        <div class="a-container">
            <div class="wrapper">
                <h3 v-if="title" class="title">
                    {{ title }}
                </h3>
                <div
                    v-if="description"
                    ref="descriptionContent"
                    class="description"
                    v-html="description"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
    },
    computed: {
        isWindows() {
            return navigator.platform.indexOf('Win') > -1; // Check if the platform is Windows
        },
    },
    mounted() {
        if (!this.isWindows) return;
        this.loadTwemojiScript();
    },
    methods: {
        loadTwemojiScript() {
            const script = document.createElement('script');
            script.src = 'https://unpkg.com/twemoji@14.0.2/dist/twemoji.min.js';
            script.onload = () => {
                this.applyTwemoji();
            };
            document.head.appendChild(script);
        },
        applyTwemoji() {
            const descriptionEl = this.$refs.descriptionContent;
            if (descriptionEl && window.twemoji) {
                descriptionEl.innerHTML = window.twemoji.parse(descriptionEl.innerHTML, {
                    folder: 'svg',
                    base: '/public/flags/',
                    ext: '.svg',
                });
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.academy {
    padding: 24px 16px;
    color: var(--av-nav-primary);
    text-align: center;
    position: relative;
    z-index: 3;
    background: linear-gradient(180deg, rgba(38, 104, 197, 0.05) 0%, rgba(38, 104, 197, 0) 100%);
    @media (--viewport-tablet) {
        padding: 24px 0;
    }
    .wrapper {
        margin: 0 auto;
        @media (--viewport-desktop) {
           @mixin colls 10;
        }
        .title {
            @mixin hero-accent;
            margin-bottom: 24px;
        }
        .description {
            @mixin title;
            color: var(--av-fixed-primary);
            &:deep(img.emoji) {
                width: 24px;
                height: 24px;
                vertical-align: middle;
                display: inline-block;
            }
        }
    }
}
</style>
