<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import { CARD_FIELDS_SUBSET, SECTION_ID_CPC } from '@model/const/blog';
import { HttpRejection } from '@model/http/rejection';
import { assertHttpErrors } from '@utils/api-response';
import { ACRONIS_OG_IMAGE_ID, getOgImage, getTextDirection, getHtmlLang } from '@utils/html-meta';
import BlogNewsComponent from './component.vue';

const CARDS_NUMBER_ON_PAGE = 7;

export default Vue.extend({
    name: 'BlogNewsContainer',

    async serverPrefetch(): Promise<void> {
        // ==== Init
        const locale = this.$route.params.locale;
        const page = parseInt(this.$route.params.page, 10) || 1;

        await this.$store.dispatch('locales/getLocaleData', { locale });
        const cpcIsActiveForLocale = this.$store.state.locales.locale.is_active_on_blog_cpc;
        if (!cpcIsActiveForLocale) {
            throw new HttpRejection('Premature rendering stop', StatusCodes.NOT_FOUND);
        }

        // ==== Get all entities
        const categoriesRequest = new ProductAPIQueryBuilder('categories')
            .setEntityPath('/api/blog/categories/')
            .setLocales([locale])
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .setOutputOnly(['id', 'section_id', 'slug', 'title'])
            .setCustomParam('process-macros', '1')
            .toObject();

        const translationsListRequest = new ProductAPIQueryBuilder('sectionTranslationsList')
            .setEntityPath('/api/blog/sections/translations/')
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .setOutputOnly(['locale'])
            .setPaginate(1, 20)
            .toObject();

        const newsRequest = new ProductAPIQueryBuilder('news')
            .setEntityPath('/api/blog/posts/')
            .setLocales([locale])
            .addMatchesAll('is_news', '=', '1')
            .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
            .setCustomParam('process-macros', '1')
            .setOutputOnly(CARD_FIELDS_SUBSET)
            .addSort('translation.published_at', 'desc')
            .addSort('id', 'desc')
            .setPaginate(page, CARDS_NUMBER_ON_PAGE)
            .toObject();

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 's-main-header', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'blog', locale }),
            this.$store.dispatch('blog/getEntity', { request: categoriesRequest }),
            this.$store.dispatch('blog/getEntity', { request: newsRequest }),
            this.$store.dispatch('blog/getEntity', { request: translationsListRequest }),
        ]);

        assertHttpErrors([
            { entity: this.$store.state.blog.categories, throwIfEmpty: true },
            { entity: this.$store.state.blog.sectionTranslationsList, throwIfEmpty: true },
            { entity: this.$store.state.blog.news, throwIfEmpty: true },
        ]);

        // Building page meta
        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
    },

    methods: {
        getMeta(): any {
            const locale = this.$route.params.locale;
            const uiStrings: any = this.$store.state.slices.items.blog || {};
            const canonical = `https://${this.$config.domain}${this.$route.path}`;
            const ogImage = getOgImage(`@${ACRONIS_OG_IMAGE_ID}`, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = `${uiStrings.newsArchive} | Acronis`;
            const description = uiStrings.cpcMetaDescription;

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogImage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogImage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'link', rel: 'image_src', href: ogImage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: getHtmlLang(locale),
                },
            };
        },

        async goToPage(page: number, year?: string, month?: string): Promise<boolean> {
            let req = new ProductAPIQueryBuilder('news')
                .setEntityPath('/api/blog/posts/')
                .setLocales([this.$route.params.locale])
                .addMatchesAll('is_news', '=', '1')
                .addMatchesAll('section_id', '=', SECTION_ID_CPC.toString())
                .setOutputOnly(CARD_FIELDS_SUBSET)
                .addSort('translation.published_at', 'desc')
                .addSort('id', 'desc')
                .setPaginate(page, CARDS_NUMBER_ON_PAGE);

            if (year) {
                req = req.addDateCompare('translation.published_at', 'year', year);
            }

            if (month) {
                req = req.addDateCompare('translation.published_at', 'month', month);
            }

            await this.$store.dispatch('blog/getEntity', { request: req.toObject() });

            return this.$store.state.blog.news.httpStatus === StatusCodes.OK;
        },
    },

    render(h) {
        const props = { dispatchGoToPage: this.goToPage };
        return h(BlogNewsComponent, { props });
    },
});
</script>
