/* eslint-disable max-len */
export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=18878-484&t=LWcbKICEQRRYVaDf-4';

export const Default = {
    args: {
        translations: {
            title: 'Benefits Slice Title',
            lead: 'Lead for benefits slice is a short paragraph of text that summarizes or introduces the story below. Standard lead includes brief answers to the questions of who, what, why, when, where, and how the key event in the story took place.',
            link: { text: 'Learn More' },
            cards: [
                {
                    imageAlt: 'Alt',
                    title: 'Benefit Card Title',
                    text: 'Short descriptorion provides a more detailed explanation of the marketing title above.',
                },
                {
                    imageAlt: 'Alt',
                    title: 'Benefit Card Title',
                    text: 'Short <a href="#">descriptorion</a> provides <strong>a more detailed</strong> explanation of the marketing title above. Short descriptorion provides a more detailed explanation of the marketing title above',
                },
                {
                    imageAlt: 'Alt',
                    title: 'Example of Longer Benefit Card Title',
                    text: 'Short descriptorion provides a more detailed explanation of the marketing title above.',
                },
                {
                    imageAlt: 'Alt',
                    title: 'Benefit Card Title',
                    text: 'Short descriptorion provides a more detailed explanation of the marketing title above.',
                },
                {
                    imageAlt: 'Alt',
                    title: 'Benefit Card Title',
                    text: 'Short descriptorion provides a more detailed explanation of the marketing title above.',
                },
                {
                    imageAlt: 'Alt',
                    title: 'Benefit Card Title',
                    text: 'Short descriptorion provides a more detailed explanation of the marketing title above.',
                },
            ],
        },
        link: { to: '#' },
        cards: [
            { image: '/images/value-card/card-01', to: '#' },
            { image: '/images/value-card/card-01' },
            { image: '/images/value-card/card-01', to: '#' },
            { image: '/images/value-card/card-01', to: '#' },
            { image: '/images/value-card/card-01', to: '#' },
            { image: '/images/value-card/card-01', to: '#' },
        ],
    },
};
