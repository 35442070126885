<template>
    <section :id="id" class="s-slice s-features-small-with-img" :class="{ ...className, ...basicClassName }">
        <div class="a-container">
            <a-slice-header :title="title" :lead="lead" />
            <div class="s-features-small-with-img__img-container">
                <a-media class="s-features-small-with-img__img" v-bind="media" />
            </div>
            <ul v-if="featureCards" class="s-features-small-with-img__list">
                <li v-for="(card, i) in featureCards" :key="i" class="s-features-small-with-img__item">
                    <a-feature-card
                        class="s-features-with-img__feature-card"
                        v-bind="card"
                        :title-tag="$attrs.title && 'h3'"
                        icon-small
                        size="xs"
                    />
                </li>
            </ul>
            <ul v-if="bulletList" class="s-features-small-with-img__list a-list">
                <li v-for="item in bulletList" :key="item" class="s-features-small-with-img__item a-list__item">
                    <div class="s-features-small-with-img__feature-item">
                        {{ item }}
                    </div>
                </li>
            </ul>
            <a-link
                v-if="link"
                class="s-features-small-with-img__link"
                v-bind="link"
                :type="link.type || 'direct'"
                :glyph="link.glyph || 'arrow'"
            />
        </div>
    </section>
</template>

<script>
import AFeatureCard from '@core/components/feature-card/feature-card.vue';
import ALink from '@core/components/link/link.vue';
import AMedia from '@core/components/media/media.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';

export default {
    name: 'SFeaturesSmallWithImage',

    components: {
        ASliceHeader,
        AFeatureCard,
        ALink,
        AMedia,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        featureCards: {
            type: Array,
            default: undefined,
        },

        /**
         * Feature list
         */
        bulletList: {
            type: Array,
            default: undefined,
        },

        media: {
            type: Object,
            required: true,
        },

        link: {
            type: Object,
            default: undefined,
        },
    },

    computed: {
        className() {
            const items = this.featureCards || this.bulletList;

            return {
                's-features-small-with-img_layout_2-columns': items.length % 2 === 0,
                's-features-small-with-img_layout_3-columns': items.length % 3 === 0,
                's-features-small-with-img_layout_with-link': this.link,
                's-features-small-with-img_layout_without-lead': !this.lead,
                's-features-small-with-img_layout_with-icons': items.every((item) => item.image),
                's-features-small-with-img_layout_with-bullet-list': this.bulletList,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-features-small-with-img {
    &__img-container {
        margin-bottom: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__img {
        width: 100%;
        height: 100%;
    }

    &__item {
        &:not(:last-of-type) {
            margin-bottom: 56px;
        }
    }

    &__link {
        margin-top: 56px;
    }

    &__feature-item {
        position: relative;
        padding-inline-start: 20px;
        color: var(--av-nav-primary);

        &::before {
            content: '';
            position: absolute;
            top: 11px;
            inset-inline-start: 0;
            width: 4px;
            height: 4px;
            background-color: var(--av-nav-primary);
            border-radius: 50%;
        }
    }

    &_layout {
        &_with-bullet-list {
            .s-features-small-with-img {
                &__item {
                    padding: 0;

                    &:not(:last-of-type) {
                        margin-bottom: 56px;
                    }
                }
            }
        }
    }

    @media (--viewport-mobile-wide) {
        &__img-container {
            margin: 0 auto 56px;
            width: 66.66%;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: -56px;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                margin-bottom: -56px;
            }
        }

        &_layout {
            &_2-columns {
                .s-features-small-with-img {
                    &__item {
                        width: 50%;
                        padding-inline-end: 32px;
                    }
                }
            }

            &_3-columns {
                .s-features-small-with-img {
                    &__item {
                        width: 33%;
                        padding-inline-end: 32px;
                    }
                }
            }

            &_with-link {
                .s-features-small-with-img {
                    &__img-container {
                        margin: 0 auto 56px;
                    }

                    &__item {
                        padding-inline-end: 32px;
                    }
                }
            }

            &_with-icons {
                .s-features-small-with-img {
                    &__item {
                        padding-inline-end: 32px;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop) {
        &:deep(.a-container) {
            display: grid;
            grid-template-columns: 1.2fr 1fr;
            -ms-grid-columns: 1.2fr 96px 1fr;
            grid-column-gap: 96px;
            align-items: center;
            justify-content: center;
        }

        &:deep(.a-slice-header) {
            -ms-grid-column: 1;
            margin-bottom: 64px;
            align-self: flex-end;
            width: 100%;
        }

        &:deep(.a-slice-header__lead) {
            padding-inline-end: 16px;
        }

        &__img-container {
            position: relative;
            grid-row: span 2;
            -ms-grid-span: 2;
            -ms-grid-column: 3;
            margin: 0;
            width: 100%;

            .a-picture__img {
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    position: absolute;
                    top: 50%;
                    inset-inline-start: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &__link {
            -ms-grid-row: 4;
        }

        &__list {
            align-self: flex-start;
            -ms-grid-row: 2;
        }

        &_layout {
            &_without-lead {
                .a-slice-header {
                    margin-bottom: 40px;
                }
            }

            &_with-icons {
                .a-slice-header {
                    margin-bottom: 56px;

                    &__lead {
                        padding-inline-end: 0;
                    }
                }
            }

            &_with-bullet-list {
                .a-slice-header {
                    margin-bottom: 56px;

                    &__lead {
                        padding-inline-end: 0;
                    }
                }
            }

            &_with-link {
                .s-features-small-with-img {
                    &__img-container {
                        margin-bottom: 0;
                        grid-row: span 3;
                        -ms-grid-row-span: 3;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop-wide) {
        .a-slice-header {
            margin-bottom: 56px;
        }

        &_layout {
            &_without-lead {
                .a-slice-header {
                    margin-bottom: 40px;
                }
            }
        }
    }

    @media (--viewport-desktop-large) {
        &:deep(.a-container) {
            grid-column-gap: 128px;
        }

        &:deep(.a-slice-header) {
            margin-bottom: 56px;
        }

        &:deep(.a-slice-header__lead) {
            padding-inline-end: 12px;
        }

        &_layout {
            &_without-lead {
                .a-slice-header {
                    margin-bottom: 40px;
                }
            }
        }
    }
}
</style>
