<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import qs from 'qs';
import Vue from 'vue';
import { HttpRejection } from '@model/http/rejection';

export default Vue.extend({
    name: 'CatchAllContainer',

    serverPrefetch(): void {
        const [localeSegment, ...restSegments] = this.$route.path.split('/').filter(Boolean);

        // If there's only one segment, we treat it as an integration slug from the old scheme (https://solutions.acronis.com/datto)
        if (restSegments?.length === 1) {
            const search = qs.stringify(this.$route.query);
            const isSearch = search ? '?' : '';
            const target = `/${localeSegment}/integrations/${restSegments[0]}/${isSearch}${search}`;

            const rejection = new HttpRejection('Premature rendering stop: old integration url scheme', StatusCodes.MOVED_PERMANENTLY);
            rejection.addHeader('Location', target);

            throw rejection;
        }

        // Otherwise, we treat the request as unknown, since everything else should be covered be real routes or middleware
        throw new HttpRejection('Premature rendering stop: not found', StatusCodes.NOT_FOUND);
    },

    // render() is required by Vue, don't remove even if it looks useless
    render() {
        return null;
    },
});
</script>
