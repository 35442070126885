<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { HttpRejection } from '@model/http/rejection';
import { getOgImage } from '@utils/html-meta';
import HomeComponent from './component.vue';

export default Vue.extend({
    name: 'HomeContainer',

    async serverPrefetch(): void {
        const { locale } = this.$route.params;

        if (locale !== LOCALE_DEFAULT) {
            throw new HttpRejection(`Premature rendering stop: '${locale}' not found`, StatusCodes.NOT_FOUND);
        }

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 'solutions-acronis-com-home', locale: LOCALE_DEFAULT }),
            this.$store.dispatch('solutions/getIntegrations'),
            this.$store.dispatch('solutions/getCategories'),
        ]);

        // Building page meta
        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
        pageSettings() {
            return this.$store.state.slices.items['solutions-acronis-com-home'];
        },
    },

    methods: {
        getMeta(): any {
            let title = this.pageSettings.pageMeta.title;
            let description = this.pageSettings.pageMeta.description;
            const ogimage = getOgImage(this.pageSettings.pageMeta.image, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const canonical = `https://${this.$config.domain}${this.$route.path}`;

            const { locale, category, subcategory } = this.$route.params;
            const page = parseInt(this.$route.params.page, 10) || 1;

            let urlBase = `https://${this.$config.domain}/${locale}/`;
            if (category) urlBase += `category/${category}/`;
            if (subcategory) urlBase += `${subcategory}/`;

            const categoryCode = subcategory || category;

            if (categoryCode) {
                const categories = this.$store.state.solutions.categories || [];
                const categoryItem = categories.find((item) => item.code === categoryCode);
                const categoryMeta = this.pageSettings.pageMeta.categories[categoryCode];

                title = categoryMeta?.title || categoryItem?.title || title;
                description = categoryMeta?.description || categoryItem?.subtitle || description;
            }

            const pageSize = this.pageSettings.integrations?.pageSize || 9;
            const filteredIntegrations = this.getFilteredIntegrations(category, subcategory);
            const totalPages = Math.ceil(filteredIntegrations.length / pageSize);
            const paginationLinks = [];

            if (page > totalPages) {
                throw new HttpRejection(`Premature rendering stop: '${page}' not found`, StatusCodes.NOT_FOUND);
            }
            if (page > 1) {
                const href = page === 2 ? urlBase : `${urlBase}page/${page - 1}/`;
                paginationLinks.push({ tag: 'link', rel: 'prev', href });
            }
            if (totalPages > page) {
                const href = `${urlBase}page/${page + 1}/`;
                paginationLinks.push({ tag: 'link', rel: 'next', href });
            }

            const meta = {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogimage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogimage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'meta', name: 'keywords', content: this.pageSettings.pageMeta.keywords },
                    { tag: 'link', rel: 'image_src', href: ogimage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                    ...paginationLinks,
                ],
                htmlAttrs: {
                    dir: 'ltr',
                    lang: 'en',
                },
            };

            return meta;
        },

        getFilteredIntegrations(categoryCode, subcategoryCode) {
            const integrations = this.$store.state.solutions.integrations || [];
            const categories = this.$store.state.solutions.categories || [];

            if (!categoryCode) return integrations;

            if (subcategoryCode) return categories.find((item) => item.code === subcategoryCode);

            // if selected category has children add items of every children
            const activeCategories = categories
                .filter((item) => item.parent === categoryCode)
                .map((item) => item.code);

            const allOfCategoryFilter = (integration) => integration.categories
                .some((item) => activeCategories.includes(item) || item === categoryCode);

            return integrations.filter(allOfCategoryFilter);
        },
    },

    render(h) {
        return h(HomeComponent);
    },
});
</script>
