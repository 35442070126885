import SchemaHandler from '@model/seo/schemaHandler';
import { PostalAddressSchema, SchemaConfig } from '@model/seo/schemas';
import { getUrlWithLocale } from '../helper';

export default class PostalAddressHandler implements SchemaHandler {
    ctx: any;

    schemasConfig: SchemaConfig[];

    constructor(ctx: any, schemasConfig: SchemaConfig[]) {
        this.ctx = ctx;
        this.schemasConfig = schemasConfig;
    }

    getSchemaData(staticConfig: PostalAddressSchema): Promise<PostalAddressSchema> {
        return Promise.resolve(this.getRequiredFields(staticConfig));
    }

    getRequiredFields(staticConfig: PostalAddressSchema): PostalAddressSchema {
        const url = getUrlWithLocale(this.ctx);

        // Locale depended, will be managed within the admin panel
        return {
            ...staticConfig,
            '@id': `${url}/#PostalAddress`,
        };
    }
}
