<template>
    <main class="sp-acpc-wrapper">
        <s-header-product-new v-bind="header" class="acpc-header-product-new" />
        <s-awards-ribbon v-if="awards" v-bind="awards" class="acpc-awards-ribbon" />
        <s-message-highlights v-if="highlight" v-bind="highlight" class="acpc-message-highlights" />
        <s-service-side-menu v-if="serviceSideMenu" v-bind="serviceSideMenu" />
        <s-feature-cards v-if="featureCards" v-bind="featureCards" />
        <s-success-story v-if="successStory" v-bind="successStory" />
        <s-features v-if="features" v-bind="features" />
        <s-cta v-if="cta" v-bind="cta" />
        <s-events v-if="webinarData" v-bind="webinarData" class="acpc-webinar" />
        <s-resources v-if="resources" v-bind="resources" />
        <s-addon-packs v-if="packs" v-bind="packs" />
    </main>
</template>

<script>
import SAwardsRibbon from '@core/slices/pages/awards-ribbon/awards-ribbon.vue';
import SEvents from '@core/slices/pages/events/events.vue';
import SHeaderProductNew from '@core/slices/pages/header-product-new/header-product-new.vue';
import SMessageHighlights from '@core/slices/pages/message-highlights/message-highlights.vue';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import SAddonPacks from './components/addon-packs.vue';
import SCta from './components/cta.vue';
import SFeatureCards from './components/feature-cards.vue';
import SFeatures from './components/features.vue';
import SResources from './components/resources.vue';
import SServiceSideMenu from './components/service-side-menu.vue';
import SSuccessStory from './components/success-story.vue';

export default {
    name: 'SCyberProtectCloud',
    components: {
        SHeaderProductNew,
        SAwardsRibbon,
        SMessageHighlights,
        SServiceSideMenu,
        SFeatureCards,
        SSuccessStory,
        SFeatures,
        SCta,
        SEvents,
        SResources,
        SAddonPacks,
    },
    props: {
        header: {
            type: Object,
            required: true,
        },
        awards: {
            type: Object,
            default: null,
        },
        highlight: {
            type: Object,
            default: null,
        },
        serviceSideMenu: {
            type: Object,
            default: null,
        },
        featureCards: {
            type: Object,
            default: null,
        },
        successStory: {
            type: Object,
            default: null,
        },
        features: {
            type: Object,
            default: null,
        },
        cta: {
            type: Object,
            default: null,
        },
        webinar: {
            type: Object,
            default: null,
        },
        resources: {
            type: Object,
            default: null,
        },
        packs: {
            type: Object,
            default: null,
        },
    },
    computed: {
        webinarData() {
            return this.$store.state.slices.items?.[this.webinar.dataFrom] || null;
        },
    },
    serverPrefetch() {
        if (!this.webinar.dataFrom) return;
        const locale = this.$route?.params.locale || LOCALE_DEFAULT;
        this.$store.dispatch('slices/getSyncedData', { slice: this.webinar.dataFrom, locale });
    },
};
</script>

<style scoped lang="postcss">
.acpc-header-product-new {
    &:deep(.s-header-with-navigation) {
        .caption {
            font-family: var(--font-family-default);
        }
        .top-buttons {
            .a-button {
                min-width: 125px;
            }
        }
        .buttons {
            .a-button {
                width: 100%;
                min-width: 200px;
                @media (--viewport-tablet) {
                    width: auto;
                }
            }
        }
        .sticky-bar-wrapper {
            margin-bottom: 48px;
        }
        .main-content .title {
            @mixin display;
            margin-bottom: 16px;
            text-align: center;
            @media (--viewport-tablet) {
                @mixin hero;
            }
            @media (--viewport-desktop) {
                text-align: start;
            }
        }
        .main-content .subtitle {
            @mixin colls 12;
            @mixin title;
            text-align: center;
            display: block;
            margin-bottom: 48px;
            @media (--viewport-desktop) {
                @mixin colls 6;
                text-align: start;
                margin-bottom: 80px;
            }
        }
        .cta-block {
            margin-top: 0;
            text-align: center;
            @media (--viewport-desktop) {
                text-align: start;
            }
            .action-links,
            .cta {
                display:none;
            }

            .cta-additional-label  {
                @mixin paragraph-accent;
                color: var(--av-fixed-success);
                margin-top: 24px;
            }
        }
    }
}
.acpc-awards-ribbon {
    &:deep(.s-awards-ribbon) {
        padding: 24px 0;
        &__title {
            text-align: center;
            margin-bottom: 16px;
            @media (--viewport-desktop) {
                margin-bottom: 0;
                text-align: start;
            }
        }
        &__list {
            justify-content: center;
            gap: 24px;
            @media (--viewport-tablet) {
                gap: 24px 56px;
            }
            @media (--viewport-desktop) {
                justify-content: flex-end;
            }
            @media (--viewport-desktop-wide) {
                gap: 24px 72px;
            }
        }
    }
}
.acpc-message-highlights {
    padding-top: 48px;
    padding-bottom: 48px;
}
.acpc-webinar {
    padding: 48px 0;

    &::after {
        display:none;
    }

    &:deep(.s-webinar-item) {
        .a-webinar-card {
            @mixin colls 12;
            margin: 0;
            padding: 16px 16px 32px;
            box-shadow: var(--av-shadow-regular);
            border-radius: 16px;
            border: 2px solid var(--av-brand-secondary-light);
            background: var(--av-solid-brand-accent);
            @media (--viewport-tablet) {
                padding: 24px;
            }
            @media (--viewport-desktop) {
                padding: 16px 16px 32px;
            }
            @media (--viewport-desktop-wide) {
                padding: 32px 16px;
            }
            .a-webinar-card__img {
                border-radius: 8px;
                overflow: hidden;
                max-width: 296px;
                max-height: 296px;
                margin: 0 auto 16px;
                @media (--viewport-tablet) {
                    display: none;
                    margin: 0;
                }
                @media (--viewport-desktop) {
                    display: block;
                    max-width: 256px;
                    max-height: 256px;
                }
            }
            .a-webinar-card {
                &__info {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                padding: 0;
                @media (--viewport-desktop) {
                    padding: 0 24px;
                    flex-flow: column;
                }
                }
                &__date .a-glyph {
                    height: 24px;
                }
                &__title {
                    font-weight: 400;
                }
                &__desc {
                    margin-bottom: 32px;
                }
                &__speakers {
                    margin-bottom: 32px;
                    @media (--viewport-tablet) {
                        margin-bottom: 0;
                    }
                }
                &__bottom {
                    width: 100%;
                    margin-top: auto;
                    display: flex;
                    flex-flow: row wrap;
                    align-items: flex-end;
                }
            }
        }
    }
    &:deep(.a-slider__counter) {
        display: none;
    }
}
</style>
