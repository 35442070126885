<template>
    <div class="s-form-support-confirmation">
        <div class="s-form-support-confirmation__title">
            {{ content.title }}
        </div>

        <a-picture
            v-if="content.image"
            class="s-form-support-confirmation__image"
            :link="content.image"
        />

        <div class="s-form-support-confirmation__subtitle">
            {{ content.subtitle }}
        </div>

        <a-button
            v-if="content.button"
            class="s-form-support-confirmation__button"
            v-bind="content.button"
            :to="content.redirectLink"
        />

        <div v-if="message" class="s-form-support-confirmation__message">
            <a-dangerous-html class="a-feature-card__text" :content="message" />
        </div>
    </div>
</template>

<script>

import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'FormSupportConfirmation',
    components: {
        AButton,
        ADangerousHtml,
        APicture,
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
        form: {
            type: Object,
            default: null,
        },
    },
    computed: {
        message() {
            return this.content.audienceMessage?.[this.form?.company_type];
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-form-support-confirmation {
    padding: 40px;
    background-color: var(--av-inversed-primary);

    &__title {
        @mixin lead-accent;
        color: var(--av-nav-primary);
        text-align: center;
    }

    &__image {
        margin: 24px auto;
        height: 243px;
        width: auto;
    }

    &__subtitle {
        @mixin paragraph;
        text-align: center;
        color: var(--av-fixed-primary);
        margin-bottom: 22px;
    }

    &__message {
        margin-top: 15px;
        text-align: center;
    }

    &__button {
        width: 100%;
    }
}

@media (--viewport-mobile-wide) {
    .s-form-support-confirmation {
        border-radius: 4px;
        box-shadow: var(--av-shadow-small);
    }
}
</style>
