<template>
    <section class="s-slice s-sport-partners">
        <a-picture
            class="s-sport-partners__background"
            :link="background"
            is-background
            fit="cover"
        />
        <div class="a-container s-sport-partners__container">
            <div class="s-sport-partners__content">
                <div class="s-sport-partners__subtitle">
                    {{ tag }}
                </div>
                <div class="s-sport-partners__title">
                    {{ title }}
                </div>
                <div class="s-sport-partners__logos">
                    <div v-for="logo in logos" :key="logo" class="s-sport-partners__logo">
                        <a-picture :link="logo" />
                    </div>
                </div>
                <div class="s-sport-partners__buttons">
                    <a-button
                        v-if="button"
                        class="s-sport-partners__button"
                        v-bind="button"
                        :to="button.link"
                        :type="button.type || 'action'"
                        target="_blank"
                    />
                    <a-link
                        v-if="link"
                        class="s-sport-partners__link"
                        v-bind="link"
                        :glyph="link.glyph || 'arrow'"
                        :to="link.link"
                        :target="link.target || '_blank'"
                        :size="link.size || 'paragraph'"
                        :type="link.type || 'light'"
                    />
                    <a-link
                        v-if="linkToVideo"
                        v-modal="'video-sport-modal'"
                        class="s-sport-partners__link"
                        v-bind="linkToVideo"
                        :glyph="linkToVideo.glyph || 'arrow'"
                        :text="linkToVideo.text"
                        :has-no-href="true"
                        :size="linkToVideo.size || 'paragraph'"
                        :type="linkToVideo.type || 'light'"
                    />
                </div>
            </div>
        </div>
        <a-modal
            v-if="linkToVideo"
            id="video-sport-modal"
            is-youtube
            type="youtube"
            :use-layout="false"
        >
            <a-youtube :yt-id="linkToVideo.link" :params="linkToVideo.ytParams || { autoplay: 1 }" />
        </a-modal>
    </section>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import AYoutube from '@core/components/youtube/youtube.vue';
import Modal from '@core/directives/modal.js';

export default {
    name: 'SSportPartners',
    components: {
        AYoutube,
        AButton,
        ALink,
        AModal,
        APicture,
    },
    directives: {
        Modal,
    },
    props: {
        background: {
            type: String,
            required: true,
        },
        tag: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        logos: {
            type: Array,
            required: true,
        },
        button: {
            type: Object,
            required: true,
        },
        link: {
            type: Object,
            required: false,
            default: undefined,
        },
        linkToVideo: {
            type: Object,
            required: false,
            default: undefined,
        },
        modalClassName: {
            type: String,
            required: false,
            default: '',
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-sport-partners {
    position: relative;
    background-color: var(--av-nav-primary);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: auto 100%;
    overflow: hidden;
    padding: 0;
    text-align: center;

    @media (--viewport-tablet) {
        text-align: start;
    }

    @media (--viewport-desktop) {
        background-position: 100px 0;
    }

    @media (--viewport-desktop-large) {
        background-position: right top;
    }

    &::before {
        position: absolute;
        top: 0;
        width: 100%;
        content: '';
        height: 100%;
        inset-inline-start: 0;
        z-index: 2;
        background: var(--av-nav-primary);

        @media (--viewport-tablet) {
            inset-inline-start: 40%;
            background: linear-gradient(90deg, #00204d 0%, rgba(0, 32, 77, 0) 90%);
            width: 30%;
        }
    }

    &__content {
        position: relative;

        @media (--viewport-tablet) {
            max-width: 572px;
        }
    }

    &__container.a-container {
        position: relative;
        padding-top: 63px;
        padding-bottom: 53px;
        z-index: 2;

        @media (--viewport-tablet) {
            padding-top: 56px;
            padding-bottom: 45px;
        }
    }

    &__background {
        position: absolute;
        top: 0;
        inset-inline-end: 0;
        height: 100%;
        width: 60%;
        display: none;

        @media (--viewport-tablet) {
            display: block;
        }
    }

    &__subtitle {
        @mixin lead;
        color: var(--av-link-inversed);
    }

    &__title {
        @mixin hero;

        margin-top: 24px;
        font-weight: bold;
        color: var(--av-inversed-primary);

        @media (--viewport-tablet) {
            text-align: start;
            max-width: 400px;
        }

        @media (--viewport-desktop-wide) {
            font-size: 48px;
            line-height: 56px;
            max-width: none;
        }
    }

    &__button {
        min-width: 178px;
        margin-bottom: 20px;

        @media (--viewport-mobile-wide) {
            margin-inline-end: 24px;
        }

        @media (--viewport-tablet) {
            margin-bottom: 0;
        }
    }

    &__link {
        cursor: pointer;
    }

    &__logos {
        margin: 24px -16px 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media (--viewport-tablet) {
            margin: 69px 0 69px;
            justify-content: flex-start;
        }

        @media (--viewport-desktop) {
            margin: 72px 0 48px;
        }

        @media (--viewport-desktop-wide) {
            margin: 48px 0 48px;
        }
    }

    &__logo {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 72px;
        margin: 16px 16px 0;

        @media (--viewport-tablet) {
            width: 54px;
            height: 54px;
            margin-inline-start: 29px;

            &:first-child {
                margin-inline-start: 0;
            }
        }

        @media (--viewport-desktop) {
            margin-inline-start: 32px;
            width: 72px;
            height: 72px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        flex-direction: column;

        @media (--viewport-tablet) {
            flex-direction: row;
        }
    }
}

[dir='rtl'] {
    .s-sport-partners::before {
        background: linear-gradient(-90deg, #00204d 0%, rgba(0, 32, 77, 0) 90%);
    }
}
</style>
