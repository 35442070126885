<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import AcademyCourse from '@core/slices/academy/course/course.vue';
import { HttpRejection } from '@model/http/rejection';
import { getOgImage } from '@utils/html-meta';

export default Vue.extend({
    name: 'AcademyCourseContainer',

    async serverPrefetch(): Promise<void> {
        const { locale } = this.$route.params;

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 's-academy-acronis-com', locale }),
            this.$store.dispatch('academy/getCourses'),
        ]);

        if (this.preset.locale !== locale) {
            throw new HttpRejection(`Premature rendering stop: '${locale}' not found`, StatusCodes.NOT_FOUND);
        }

        if (!this.course) {
            throw new HttpRejection(`Premature rendering stop: '${this.slug}' not found`, StatusCodes.NOT_FOUND);
        }

        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
        ...mapState({
            courses: (state: any) => state.academy?.courses || {},
            preset: (state: any) => state.slices.items['s-academy-acronis-com'],
        }),

        slug() {
            return this.$route.params.slug;
        },

        slugFromTitle() {
            return this.courses?.find((item: any) => item.slug === this.slug);
        },

        slugFromSettings() {
            const courseID = this.preset?.settings?.catalogue?.find((item: any) => item.slug === this.slug)?.id;
            return this.courses?.find((item: any) => item.id === courseID);
        },

        course() {
            return this.slugFromSettings || this.slugFromTitle;
        },
    },

    methods: {
        getMeta(): any {
            const settings = this.preset?.template?.page?.settings || {};
            const title = this.course?.name || 'Acronis';
            const keywords = settings.meta?.keywords || 'Acronis';
            const description = this.course?.description || 'Acronis';
            const path = this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE;
            const ogimage = getOgImage(settings.meta?.image || '@3fbd2844ee3d06babfe5ec118eb82911', path);
            const canonical = `https://${this.$config.domain}${this.$route.path}`;
            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogimage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogimage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'meta', name: 'keywords', content: keywords },
                    { tag: 'link', rel: 'image_src', href: ogimage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                ],
                htmlAttrs: {
                    dir: 'ltr',
                    lang: 'en',
                },
            };
        },
    },

    render(h) {
        return h(AcademyCourse);
    },
});
</script>
