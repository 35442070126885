<template>
    <div class="company-pages">
        <div class="s-company-latest">
            <section class="s-company-latest__blog">
                <s-blog-posts v-bind="blog" />
            </section>
            <section v-if="hasEvents" id="events" class="s-slice s-company-latest__events">
                <div class="a-container">
                    <div class="events-content">
                        <div class="events-content-text">
                            <div class="section-header">
                                {{ events.title }}
                            </div>
                            <div class="events-content-text__description">
                                {{ events.description }}
                            </div>
                        </div>
                        <a-link
                            class="events-content__more"
                            to="https://acronis.events/"
                            glyph="arrow"
                            :text="events.linkText"
                            type="direct"
                        />
                    </div>
                    <div v-if="eventCards.length" class="events__list">
                        <event-card
                            v-for="(event, i) in eventCards"
                            :key="i"
                            class="events__card"
                            v-bind="event"
                        />
                    </div>
                    <a-link
                        class="events__more"
                        to="https://acronis.events/"
                        :glyph="events.linkGlyph || 'arrow'"
                        :text="events.linkText"
                        type="direct"
                    />
                </div>
            </section>
            <section v-if="feed.pressReleases" class="s-company-latest__press">
                <div class="a-container">
                    <div class="s-company-latest__press__wrapper">
                        <div class="s-company-latest__press__sidebar">
                            <div class="s-company-latest__press__title" v-html="press.title" />
                            <a-link
                                v-if="press.link"
                                class="s-company-latest__press__link"
                                v-bind="press.link"
                                :glyph="press.link?.glyph || 'arrow'"
                                :type="press.link?.type || 'direct'"
                            />
                        </div>
                        <div class="s-company-latest__press__content">
                            <div
                                v-for="item in feed.pressReleases"
                                :key="item.link"
                                class="s-company-latest__press__content__article"
                            >
                                <a-link
                                    class="s-company-latest__press__content__article__link"
                                    v-bind="item"
                                    :to="item.link"
                                    :target="item.target || '_blank'"
                                    :rel="item.rel || 'noopener noreferrer'"
                                    :text="item.title"
                                />
                                <div class="s-company-latest__press__content__article__date">
                                    {{ getReadableDate(item.date) }}
                                </div>
                            </div>
                        </div>
                        <a-link
                            v-if="press.link"
                            class="s-company-latest__press__link __mobile"
                            v-bind="press.link"
                            :glyph="press.link?.glyph || 'arrow'"
                            :type="press.link?.type || 'direct'"
                        />
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { isValid as isDateValid } from 'date-fns';
import { mapState } from 'vuex';
import ALink from '@core/components/link/link.vue';
import EventCard from '@core/slices/events/shared-components/event-card/event-card.vue';
import SBlogPosts from '@core/slices/pages/blog-posts/blog-posts.vue';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import { formatToLocaleDate } from '@utils/locales';

export default {
    name: 'SCompanyPagesLatest',
    components: {
        ALink,
        SBlogPosts,
        EventCard,
    },
    props: {
        press: {
            type: Object,
            default: undefined,
        },
        events: {
            type: Object,
            default: undefined,
        },
        blog: {
            type: Object,
            default: undefined,
        },
    },
    async serverPrefetch() {
        const locale = this.$route?.params.locale;

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 'events', LOCALE_DEFAULT }),
            this.$store.dispatch('eventsFeed/getFeed', { limit: 3, locale }),
            this.$store.dispatch('publications/getFeed', { locale }),
        ]);
    },
    computed: {
        ...mapState({
            feed: (state) => state.publications.feed,
        }),

        eventTranslations() {
            return this.$store.state.slices.items.events;
        },

        eventCards() {
            if (!this.eventTranslations) return [];
            return this.$store.getters['eventsFeed/getEventCards'](this.events, this.eventTranslations, this.locale);
        },

        hasEvents() {
            return this.events && this.eventCards.length;
        },

        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },
    },
    methods: {
        getReadableDate(rawDate) {
            const date = new Date(rawDate);
            return isDateValid(date) ? formatToLocaleDate(rawDate, this.locale) : '';
        },
    },
};
</script>

<style lang="postcss">
.company-pages {
    .s-company-latest {
        &__blog,
        &__events {
            border-bottom: 1px solid var(--av-brand-secondary-light);
        }

        &__press,
        &__events {
            @mixin basic-slice-paddings;

            &__title {
                @mixin display;
                color: var(--av-nav-primary);

                @media (--viewport-tablet) {
                    @mixin hero;
                }
                @media (--viewport-desktop-wide) {
                    @mixin colls 11;
                    margin-bottom: 40px;
                }
                @media (--viewport-desktop-large) {
                    @mixin colls 12;
                }
            }
            &__wrapper {
                display: flex;
                flex-flow: row wrap;
                align-items: flex-start;
            }
            &__sidebar {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 40px;

                @media (--viewport-desktop-wide) {
                    @mixin colls 3;
                    margin-bottom: 0;
                }
            }
            &__content {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                @media (--viewport-desktop-wide) {
                    @mixin colls 9;
                }
                &__article {
                    width: 100%;
                    margin-bottom: 40px;

                    @media (--viewport-tablet) {
                        @mixin colls 6;
                    }
                    @media (--viewport-desktop) {
                        @mixin colls 4;
                    }
                    &__link {
                        @mixin lead;
                        color: var(--av-brand-secondary);
                        margin-bottom: 8px;
                    }
                    &__date {
                        @mixin body;
                        color: var(--av-fixed-primary);
                    }
                }
            }

            &__link {
                @mixin title-accent;
                font-weight: 500;
                display: none;
                color: var(--av-brand-secondary);

                @media (--viewport-tablet) {
                    display: block;
                }
                &.__mobile {
                    display: block;
                    margin-top: 8px;
                    flex-basis: 100%;

                    @media (--viewport-tablet) {
                        display: none;
                    }
                }
            }
        }
        &__events {
            background: var(--av-brand-lightest);
            overflow: hidden;
            .section-header {
                @mixin display;
                color: var(--av-nav-primary);

                @media (--viewport-desktop) {
                    @mixin hero;
                }
            }
            .events {
                &-content {
                    position: relative;
                    display: flex;
                    justify-content: space-between;

                    &-text {
                        @media (--viewport-desktop) {
                            max-width: 665px;
                        }

                        &__description {
                            margin-top: 40px;
                            margin-bottom: 24px;
                        }
                    }

                    &__more {
                        display: none;
                        @mixin title-accent;
                        font-weight: 500;
                        color: var(--av-brand-secondary);

                        @media (--viewport-desktop) {
                            display: block;
                        }
                    }
                }

                &__more {
                    @mixin title-accent;
                    font-weight: 500;
                    color: var(--av-brand-secondary);
                    margin-top: 56px;

                    @media (--viewport-desktop) {
                        display: none;
                    }
                }

                &__list {
                    position: relative;
                    display: grid;
                    column-gap: 16px;
                    row-gap: 32px;
                    grid-template-columns: 1fr;
                    grid-auto-rows: 1fr;

                    @media (--viewport-tablet) {
                        grid-template-columns: 1fr 1fr;
                        max-width: 868px;
                        row-gap: 56px;
                    }

                    @media (--viewport-desktop) {
                        max-width: none;
                        grid-template-columns: 1fr 1fr 1fr;
                    }
                }

                &__card {
                    background: var(--av-inversed-primary);
                    border-radius: 4px;
                    overflow: hidden;
                    box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
                }
            }
        }
    }
}

.company-pages+.s-global-footer--locale-selector-visible {
    z-index: 2004;
}
</style>
