<template>
    <div v-if="isVisible" class="s-purchasing-acp-features">
        <div class="a-container">
            <p v-if="title" class="title">
                {{ title }}
            </p>
            <div class="features">
                <div v-for="(feature, featureIndex) in features" :key="`feature-${featureIndex}`" class="feature">
                    <p class="feature-icon">
                        <a-icon v-if="feature.icon" :number="feature.icon" :alt="feature.title || ''" />
                    </p>
                    <p v-if="feature.title" class="feature-title">
                        {{ feature.title }}
                    </p>
                    <p v-if="feature.description" class="feature-description">
                        {{ feature.description }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AIcon from '@core/components/icon/icon.vue';

export default {
    name: 'SFeatures',

    components: {
        AIcon,
    },

    props: {
        settings: {
            type: Object,
            default: null,
        },
    },

    computed: {
        isVisible() {
            return this.settings?.features;
        },

        title() {
            return this.settings?.features?.title;
        },

        features() {
            return this.settings?.features?.list || {};
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-purchasing-acp-features {
    padding: 56px 0;

    @media (--viewport-tablet) {
        padding: 64px 0;
    }
}

.title {
    @mixin display;
    padding: 8px 16px;
    font-weight: 500;
    text-align: center;
    color: var(--av-nav-primary);
}

.features {
    display: flex;
    margin: 40px 0 0;
    flex-direction: column;

    @media (--viewport-tablet) {
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    }

    @media (--viewport-desktop) {
        flex-wrap: nowrap;
    }
}

.feature {
    padding: 16px;
    border-radius: 8px;
    background: var(--av-inversed-primary);
    box-shadow: 0 4px 8px 0 var(--av-fixed-invisible);
    border: 1px solid var(--av-brand-secondary-light);

    &:not(:first-child) {
        margin-top: 16px;
        @media (--viewport-tablet) {
            margin: 0;
        }
    }

    @media (--viewport-tablet) {
        display: flex;
        flex-direction: column;
        width: calc(50% - 16px);
        justify-content: space-between;
    }
}

.feature-icon {
    width: 48px;
    height: 48px;
}

.feature-title {
    @mixin lead-accent;
    margin: 8px 0 0;
    color: var(--av-fixed-primary);

    @media (--viewport-tablet) {
        margin-bottom: auto;
    }
}

.feature-description {
    @mixin body;
    margin: 8px 0 0;
    color: var(--av-fixed-secondary);
}
</style>
