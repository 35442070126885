<template>
    <iframe
        :id="iframeId"
        class="a-youtube"
        :src="src"
        :data-src="src"
        :data-title="title"
        :class="{ 'a-youtube--modal': isModal }"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
    />
</template>

<script>
import objectToQueryString from '@utils/objectToQueryString.js';

const defaultParams = {
    rel: 0,
    showinfo: 0,
    autoplay: 0,
    enablejsapi: 1,
};

export default {
    name: 'AYoutube',
    props: {
        ytId: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            default: null,
        },

        params: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['progressChanged'],

    data() {
        return {
            trackerId: null,
            playerPositionMonotonic: 0,
        };
    },

    computed: {
        iframeId() {
            return `yt-${this.ytId}`;
        },

        isModal() {
            return ['AModal'].includes(this.$parent.$options.name);
        },

        src() {
            const { ytId, params } = this;
            const newParams = { ...defaultParams, ...params, autoplay: 0 };
            const queryParams = objectToQueryString(newParams);
            return `https://www.youtube.com/embed/${ytId}${queryParams}`;
        },
    },
    beforeMount() {
        window.youtubePlayerReadyBus?.subscribe(() => {
            this.trackerId = setInterval((this.runTrackerRound), 10000);
        });
    },

    beforeDestroy() {
        clearInterval(this.trackerId);
        this.playerPositionMonotonic = 0;
    },

    methods: {
        runTrackerRound() {
            if (!window.YT) return;

            const player = window.YT.get(this.iframeId);
            if (!player?.playerInfo?.duration) return;

            const current = player.playerInfo.currentTime;
            const duration = player.playerInfo.duration;
            const progress = (current / duration) * 100;

            if (current <= this.playerPositionMonotonic) return;
            this.playerPositionMonotonic = current;

            // TODO: the first $emit causes a weird call to https://websiteapi.acronis.com/svc/v1/marketing/forms/leads/null
            this.$emit('progressChanged', {
                ytId: this.ytId,
                progress: progress.toFixed(2),
                current: current.toFixed(2),
                duration,
            });
        },
    },
};
</script>

<style lang="postcss">
.a-youtube {
    display: block;
    width: 100%;
    height: 100%;
    border: 0;

    &--modal {
        position: absolute;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
    }
}

.a-modal--youtube .a-modal__content {
    padding-top: 56%;
}
</style>
