<template>
    <div class="s-slice header-lp-two-products">
        <a-picture
            v-if="background"
            :background="background"
            fit="cover"
            is-background
        />
        <div class="header-lp-two-products__header">
            <div class="a-container">
                <div class="header-lp-two-products__header-inner">
                    <div class="header-lp-two-products__header-cell header-lp-two-products__header-cell_main">
                        <div class="header-lp-two-products__header-col">
                            <div class="header-lp-two-products__logo-wrap">
                                <dynamic-link class="header-lp-two-products__logo-link" :to="logoLink">
                                    <a-logo class="header-lp-two-products__logo" type="full" fill="white" />
                                </dynamic-link>
                            </div>
                            <h1 class="header-lp-two-products__title">
                                {{ title }}
                            </h1>
                            <div
                                v-if="isDesktop && currentBreakpoint !== 'desktop'"
                                class="header-lp-two-products__features"
                            >
                                <div v-for="(feature, i) in features" :key="i" class="header-lp-two-products__feature">
                                    <div class="header-lp-two-products__feature-title">
                                        {{ feature.title }}
                                    </div>
                                    <div class="header-lp-two-products__feature-text">
                                        {{ feature.text }}
                                    </div>
                                </div>
                            </div>
                            <div class="header-lp-two-products__feature-main">
                                <a-picture class="header-lp-two-products__feature-image" :link="featureMain.image" />
                                <div class="header-lp-two-products__feature-main-text-content">
                                    <div class="header-lp-two-products__feature-title">
                                        {{ featureMain.title }}
                                    </div>
                                    <div class="header-lp-two-products__feature-text">
                                        {{ featureMain.text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="(product) in products"
                        :key="product.productId"
                        class="header-lp-two-products__header-cell"
                    >
                        <a-product-card
                            v-bind="product"
                            :is-single-line-c-t-a-buttons="isSingleLineCTAButtons"
                        />
                    </div>
                </div>
                <div
                    v-if="!['desktopWide', 'desktopLarge'].includes(currentBreakpoint)"
                    class="header-lp-two-products__features-mobile"
                >
                    <div class="header-lp-two-products__features">
                        <div v-for="(feature, i) in features" :key="i" class="header-lp-two-products__feature">
                            <div class="header-lp-two-products__feature-title">
                                {{ feature.title }}
                            </div>
                            <div class="header-lp-two-products__feature-text">
                                {{ feature.text }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALogo from '@core/components/logo/logo.vue';
import APicture from '@core/components/picture/picture.vue';
import analytics from '@core/mixins/analytics.js';
import breakpoint from '@core/mixins/breakpoint.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import purchasing from '@core/mixins/purchasing.js';
import AProductCard from './components/product-card.vue';

export default {
    name: 'SHeaderLpTwoProducts',
    components: {
        DynamicLink,
        ALogo,
        AProductCard,
        APicture,
    },
    mixins: [breakpoint, purchasing, analytics, cleverbridge],
    props: {
        /**
         * Ability to change the link of the logo
         */
        logoLink: {
            type: String,
            default: '/',
            required: false,
        },
        isSingleLineCTAButtons: {
            type: Boolean,
            default: false,
        },
        featureMain: {
            type: Object,
            required: true,
        },
        features: {
            type: Array,
            required: true,
        },
        products: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        background: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    data() {
        return {
            EVENT_TIMEOUT: 10 * 1000,
        };
    },
    mounted() {
        setTimeout(() => {
            this.sendDataLayer({
                eventCategory: 'Timing',
                eventContext: 'timing',
                eventAction: this.$route.path,
                eventLabel: '10sec',
            });
        }, this.EVENT_TIMEOUT);
    },
    methods: {
        sendDataLayer(data) {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                event: 'Acronis',
                ...data,
            });
        },
    },

    async serverPrefetch() {
        await this.initSettingsCTA(this.products);
    },
};
</script>

<style lang="postcss" scoped>
.header-lp-two-products {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid var(--av-brand-secondary-accent);
    position: relative;

    &__header {
        padding: 32px 0 18px;
        color: var(--av-inversed-primary);
        background: linear-gradient(
            134.26deg,
            #193a83 1.43%,
            #143578 20.95%,
            #0e2f6d 40.47%,
            #082a63 59.98%,
            #042558 79.5%,
            #00204e 99.02%
        );

        @media (--viewport-tablet) {
            padding: 32px 0 32px;
        }

        @media (--viewport-desktop) {
            padding: 56px 0 48px;
        }
    }

    &__header-inner {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 48px;
        @media (--viewport-tablet) {
            margin-bottom: 64px;
        }

        @media (--viewport-desktop-wide) {
            flex-wrap: nowrap;
            margin-bottom: 0;
        }
    }

    &__header-cell {
        width: 100%;
        margin-bottom: 16px;

        &_main {
            margin-bottom: 0;
        }

        @media (--viewport-tablet) {
            margin-bottom: 0;
            @mixin colls 6;
            &_main {
                @mixin colls 12;
            }
        }

        @media (--viewport-desktop-wide) {
            @mixin colls 4;
        }
    }

    &__header-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        @media (--viewport-tablet) {
            display: block;
        }
        @media (--viewport-desktop-wide) {
            display: flex;
        }
    }

    &__logo-wrap {
        margin-bottom: 48px;
        @media (--viewport-tablet) {
            margin-bottom: 40px;
        }
    }

    &__logo {
        width: 118.49px;
        height: 24.06px;

        @media (--viewport-desktop) {
            width: 158px;
            height: 32px;
        }
    }

    &__title {
        @mixin display;
        margin-bottom: 40px;
        @media (--viewport-tablet) {
            margin-bottom: 48px;
            @mixin colls 8;
            display: inline-block;
            padding-inline-end: calc(8.3333% - 14.6667px);
        }
        @media (--viewport-desktop) {
            @mixin colls 9;
        }
        @media (--viewport-desktop-wide) {
            display: block;
            padding-inline-end: 0;
            margin-bottom: 64px;
            @mixin colls 10;
        }
        @media (--viewport-desktop-large) {
            width: auto;
        }
    }

    &__features {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 12px;

        @media (--viewport-desktop) {
            width: auto;
        }
    }

    &__feature {
        flex-grow: 1;
        @mixin colls 12;
        margin-bottom: 40px;
        @media (--viewport-tablet) {
            @mixin colls 6;
        }
        @media (--viewport-desktop) {
            @mixin colls 3;
        }
        @media (--viewport-desktop-wide) {
            @mixin colls 6;
        }
    }

    &__feature-title {
        color: var(--av-inversed-primary);
        @mixin lead;
        margin-bottom: 8px;
        font-weight: 600;
        @media (--viewport-desktop-wide) {
            margin-bottom: 8px;
        }
        @media (--viewport-desktop-large) {
            margin-bottom: 0;
        }
    }

    &__feature-text {
        color: var(--av-inversed-light);
        @media (--viewport-desktop) {
            max-width: 75%;
        }
    }

    &__feature-image {
        width: 56px;
        height: 56px;
        margin-top: 4px;
    }

    &__feature-image + &__feature-main-text-content {
        margin-inline-start: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__feature-main {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        margin-bottom: 64px;

        @media (--viewport-tablet) {
            @mixin colls 4;
            margin-bottom: 48px;
            display: inline-flex;
            align-items: center;
        }
        @media (--viewport-desktop) {
            @mixin colls 3;
        }
        @media (--viewport-desktop-wide) {
            width: auto;
            display: flex;
            align-items: unset;
        }
    }

    &__table {
        margin-bottom: 40px;
    }
}
</style>
