<template>
    <div class="company-pages">
        <section v-if="about" class="s-company-about-us">
            <div class="a-container">
                <div class="s-company-about-us__wrapper">
                    <div class="s-company-about-us__content">
                        <h1 class="s-company-about-us__content__title section-header">
                            {{ about.title }}
                        </h1>
                        <div v-if="about.description" class="s-company-about-us__content__description">
                            <p v-html="about.description" />
                        </div>
                    </div>
                    <div v-if="about.image_id" class="s-company-about-us__image">
                        <a-picture :link="about.image_id" />
                    </div>
                    <a-link
                        v-if="about.historyLink && about.historyLink.to"
                        class="s-company-about-us__link"
                        v-bind="about.historyLink"
                        :size="about.historyLink?.size || 'title'"
                    />
                </div>
            </div>
        </section>
        <section v-if="featuredVideo" class="s-company-featured-video">
            <div class="a-container">
                <div class="s-company-featured-video__wrapper">
                    <div class="s-company-featured-video__video__wrapper">
                        <div class="s-company-featured-video__video__wrapper__video">
                            <a-media v-if="featuredVideo.media" v-bind="featuredVideo.media" />
                            <a-video-card v-else v-bind="featuredVideo.video" />
                        </div>
                    </div>
                    <div class="s-company-featured-video__content">
                        <div class="s-company-featured-video__content__title">
                            {{ featuredVideo.title }}
                        </div>
                        <div class="s-company-featured-video__content__lead">
                            {{ featuredVideo.lead }}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section v-if="numbers" class="s-company-numbers">
            <div class="a-container">
                <ul class="s-company-numbers__list">
                    <li v-for="item in numbers" :key="item.title" class="s-company-numbers__list__item">
                        <div class="s-company-numbers__list__item__value">
                            {{ item.value }}
                        </div>
                        <div class="s-company-numbers__list__item__title">
                            {{ item.title }}
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <section class="s-company-tabs">
            <div class="a-container">
                <a-tabs>
                    <a-tab v-if="awards" :label="awards.label">
                        <div class="tab-wrap">
                            <div class="tab-wrap__sidebar">
                                <div class="tab-wrap__title">
                                    {{ awards.title }}
                                </div>
                                <a-link
                                    v-if="awards.link"
                                    class="tab-wrap__link"
                                    v-bind="awards.link"
                                    :size="awards.link?.size || 'title'"
                                />
                            </div>
                            <div class="tab-wrap-content tab-wrap__content awards">
                                <div
                                    v-for="(item, key) in filteredAwards"
                                    :key="item.title + key"
                                    class="tab-wrap__content__award"
                                >
                                    <a-picture class="tab-wrap__content__award__logo" :link="item.image" />
                                    <div class="tab-wrap__content__award__title">
                                        {{ item.title }}
                                    </div>
                                </div>
                            </div>
                            <a-link
                                v-if="awards.link"
                                class="tab-wrap__link __mobile"
                                v-bind="awards.link"
                                :size="awards.link?.size || 'title'"
                            />
                        </div>
                    </a-tab>
                    <a-tab :label="industryMemberships.label">
                        <div class="tab-wrap">
                            <div class="tab-wrap__sidebar">
                                <div class="tab-wrap__title">
                                    {{ industryMemberships.title }}
                                </div>
                                <div class="tab-wrap__buttons">
                                    <button
                                        v-for="(tab, key) in industryMemberships.items"
                                        :key="'btn ' + tab.title"
                                        class="tab-wrap__buttons__button"
                                        :disabled="activeIndustryMembershipsID === key || null"
                                        @click.stop.prevent="activeIndustryMembershipsID = key"
                                    >
                                        {{ tab.title }}
                                    </button>
                                </div>
                            </div>
                            <div
                                v-for="(section, key) in industryMemberships.items"
                                v-show="activeIndustryMembershipsID === key"
                                :key="'section ' + section.title"
                                class="tab-wrap-content tab-wrap__content industry"
                            >
                                <div v-for="logo in section.logos" :key="logo" class="tab-wrap__content__industry">
                                    <a-picture
                                        class="tab-wrap__content__industry__logo"
                                        :link="logo"
                                        :alt="section.title"
                                    />
                                </div>
                            </div>
                        </div>
                    </a-tab>
                </a-tabs>
            </div>
        </section>
        <s-sport-partners
            v-if="sport"
            class="s-company-sports"
            v-bind="sport"
            modal-class-name=".header__action-video"
        />
        <section v-if="foundation" class="s-company-foundation">
            <div class="a-container">
                <div class="s-company-foundation__content">
                    <div class="s-company-foundation__content__text">
                        <div class="s-company-foundation__content__text__title">
                            {{ foundation.title }}
                        </div>
                        <div class="s-company-foundation__content__text__description">
                            {{ foundation.description }}
                        </div>
                        <a-link
                            v-if="foundation.link"
                            class="s-company-foundation__content__text__more"
                            v-bind="foundation.link"
                            :glyph="foundation.link?.glyph || 'arrow'"
                            :size="foundation.link?.size || 'title'"
                        />
                    </div>
                    <div class="s-company-foundation__content__video__wrapper">
                        <div class="s-company-foundation__content__video__wrapper__video">
                            <a-media v-if="foundation.media" v-bind="foundation.media" />
                            <a-video-card v-else v-bind="foundation.video" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import AMedia from '@core/components/media/media.vue';
import APicture from '@core/components/picture/picture.vue';
import ATab from '@core/components/tabs/tab.vue';
import ATabs from '@core/components/tabs/tabs.vue';
import AVideoCard from '@core/components/video-card/video-card.vue';
import SSportPartners from '@core/slices/pages/sport-partners/sport-partners.vue';

export default {
    name: 'SCompanyPagesAbout',
    components: {
        ALink,
        APicture,
        AVideoCard,
        AMedia,
        ATabs,
        ATab,
        SSportPartners,
    },
    props: {
        about: {
            type: Object,
            default: undefined,
        },
        featuredVideo: {
            type: Object,
            default: undefined,
        },
        numbers: {
            type: Array,
            default: undefined,
        },
        awards: {
            type: Object,
            default: undefined,
        },
        industryMemberships: {
            type: Object,
            default: undefined,
        },
        sport: {
            type: Object,
            default: undefined,
        },
        foundation: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            activeIndustryMembershipsID: 0,
        };
    },
    computed: {
        filteredAwards() {
            const items = this.$store.state.awards?.items || [];

            return this.awards.list
                .map((id) => items[id])
                .filter(Boolean)
                .filter((x) => x.title); // TODO: how can it be no title in award?
        },
    },
    serverPrefetch() {
        const loadAwardByID = (id) => this.$store.dispatch('awards/getAward', id);
        this.awards.list.forEach(loadAwardByID);
    },
};
</script>

<style lang="postcss">
.company-pages {
    .s-company-about-us {
        @mixin basic-slice-paddings;

        &__wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-flow: row wrap;
        }

        &__content {
            width: 100%;
            @media (--viewport-desktop) {
                @mixin colls 6;
            }

            &__title {
                @mixin display;
                color: var(--av-nav-primary);

                @media (--viewport-desktop-wide) {
                    @mixin hero;
                }
            }

            &__description {
                @mixin paragraph;
                color: var(--av-fixed-secondary);
                margin: 24px 0 48px;
                @media (--viewport-tablet) {
                    margin: 24px 0 40px;
                }
                @media (--viewport-desktop) {
                    margin: 24px 0 35px;
                }
                @media (--viewport-desktop-wide) {
                    margin: 24px 0 56px;
                }
            }
        }

        &__image {
            width: 100%;
            margin-bottom: 56px;
            @media (--viewport-desktop) {
                @mixin colls 6;
                margin-bottom: 0;
            }
        }

        &__link {
            @mixin title-accent;
            font-weight: 500;
        }
    }

    .s-company-featured-video {
        @mixin basic-slice-paddings;

        &__wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-flow: row wrap;
        }
        &__video__wrapper {
            width: 100%;
            order: 2;
            @media (--viewport-desktop) {
                @mixin colls 6;
                order: 1;
            }
            &__video {
                display: block;
                max-width: 472px;
                margin: 0 auto;
                @media (--viewport-desktop-large) {
                    max-width: 537px;
                }
            }
        }

        &__content {
            width: 100%;
            order: 1;
            @media (--viewport-desktop) {
                @mixin colls 6;
                order: 2;
            }
            &__title {
                @mixin display;
                color: var(--av-nav-primary);
                margin-bottom: 24px;

                @media (--viewport-desktop) {
                    @mixin hero;
                }
            }
            &__lead {
                @mixin paragraph;
                color: var(--av-fixed-secondary);
                margin-bottom: 40px;
                @media (--viewport-mobile-wide) {
                    margin-bottom: 50px;
                }
                @media (--viewport-desktop) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .s-company-numbers {
        display: none;

        @media (--viewport-desktop) {
            padding-bottom: 48px;
            display: block;
        }
        &__list {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: flex-start;
            &__item {
                @mixin colls 3;

                &__value {
                    @mixin display-strong;
                    color: var(--av-brand-secondary);
                    margin-bottom: 8px;
                }
                &__title {
                    @mixin lead-accent;
                    color: var(--av-nav-primary);
                }
            }
        }
    }

    .s-company-tabs {
        @mixin basic-slice-paddings;

        .a-tabs__header {
            margin-bottom: 48px;
            @media (--viewport-desktop) {
                margin-bottom: 64px;
            }
        }
        .tab-wrap {
            position: relative;
            display: block;
            padding-bottom: 40px;

            @media (--viewport-tablet) {
                display: flex;
                align-items: flex-start;
                padding-bottom: 0;
                flex-flow: row wrap;
            }

            &__sidebar {
                width: 100%;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;

                @media (--viewport-desktop) {
                    @mixin colls 4;
                }
            }

            &__title {
                @mixin display;
                color: var(--av-nav-primary);
                margin-bottom: 40px;

                @media (--viewport-desktop-wide) {
                    @mixin hero;
                }
            }

            &__link {
                display: none;
                @mixin title-accent;
                font-weight: 500;
                align-self: flex-start;
                @media (--viewport-desktop) {
                    display: block;
                }
                &.__mobile {
                    display: none;
                    @media (--viewport-tablet) {
                        display: block;
                    }
                    @media (--viewport-desktop) {
                        display: none;
                    }
                }
            }

            &__buttons {
                display: flex;
                flex-wrap: wrap;
                margin-top: auto;
                margin-bottom: 32px;
                @media (--viewport-desktop) {
                    display: block;
                    margin-bottom: 0;
                }

                &__button {
                    @mixin paragraph;
                    flex-shrink: 0;
                    display: block;
                    border: none;
                    outline: none;
                    padding: 12px 16px;
                    background: var(--av-inversed-primary);
                    color: var(--av-fixed-primary);
                    cursor: pointer;
                    transition: all 0.2s ease;

                    @media (--viewport-tablet) {
                        margin-inline-end: 0;
                        min-width: 178px;
                    }

                    &:disabled {
                        cursor: default;
                        border-radius: 4px;
                        background: var(--av-brand-lightest);
                    }

                    &:hover:enabled {
                        color: var(--av-brand-secondary);
                    }
                }
            }

            &__content {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width: 100%;

                @media (--viewport-desktop) {
                    @mixin colls 8;
                }
                &.industry {
                    align-items: center;
                    justify-content: center;
                }

                &__award {
                    @mixin colls 6;
                    margin-bottom: 48px;

                    @media (--viewport-tablet) {
                        @mixin colls 3;
                    }
                    &__logo {
                        img {
                            object-position: left;
                            height: 48px;
                            width: auto;
                            max-width: 100%;
                            margin: 0;
                        }
                    }
                    &__title {
                        @mixin paragraph;
                        margin-top: 16px;
                        color: var(--av-fixed-primary);
                    }

                    @media (--viewport-tablet) {
                        justify-content: flex-start;
                    }
                }

                &__industry {
                    @mixin colls 6;
                    margin: 0 auto 48px;

                    @media (--viewport-tablet) {
                        @mixin colls 4;
                        margin: 0 0 48px;
                    }
                    &__logo {
                        max-width: 180px;
                        margin: 0 auto;
                    }
                }
            }
        }
    }

    .s-company-sports {
        .a-button {
            padding: 8px 16px;
        }
    }
    .s-company-foundation {
        @mixin basic-slice-paddings;

        &__content {
            display: block;
            @media (--viewport-desktop) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__text {
                width: 100%;
                @media (--viewport-desktop) {
                    @mixin colls 6;
                }
                &__title {
                    @mixin display;
                    color: var(--av-nav-primary);
                    margin-bottom: 24px;
                    @media (--viewport-desktop-wide) {
                        @mixin hero;
                    }
                }
                &__description {
                    margin-bottom: 40px;
                    @mixin paragraph;
                    color: var(--av-fixed-secondary);
                }

                &__more {
                    @mixin title-accent;
                    font-weight: 500;
                    display: none;
                    @media (--viewport-desktop) {
                        display: inline-block;
                    }
                }
            }
            &__video__wrapper {
                width: 100%;
                @media (--viewport-desktop) {
                    @mixin colls 6;
                }
                &__video {
                    display: block;
                    max-width: 472px;
                    margin: 0 auto;
                }
            }
        }
    }
}

.company-pages+.s-global-footer--locale-selector-visible {
    z-index: 2004;
}
</style>
