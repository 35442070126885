<template>
    <s-basic-slice :id="id" class="s-cta-cards" :class="[marginClasses, paddingClasses]">
        <div class="s-cta-cards__leftside">
            <a-logo class="s-cta-cards__logo" type="full" fill="white" />
            <h2 class="s-cta-cards__product-name">
                {{ productName }}
            </h2>
        </div>
        <div class="s-cta-cards__rightside">
            <div v-for="(item, index) in cards" :key="index" class="s-cta-cards__card">
                <a-product-card v-bind="item">
                    {{ item.text }}
                </a-product-card>
            </div>
        </div>
    </s-basic-slice>
</template>

<script>
import ALogo from '@core/components/logo/logo.vue';
import AProductCard from '@core/components/product-card/product-card.vue';
import spacingMixin from '@core/mixins/spacing-mixin.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SCtaCards',

    components: {
        ALogo,
        AProductCard,
        SBasicSlice,
    },

    mixins: [spacingMixin],

    props: {
        id: {
            type: String,
            required: true,
        },

        productName: {
            type: String,
            required: true,
        },

        cards: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-cta-cards {
    background: var(--av-nav-primary);
    color: var(--av-inversed-primary);

    &:deep(.a-container) {
        @media (--viewport-desktop) {
            display: flex;
        }
    }

    &__logo {
        margin-bottom: 16px;
        width: 127px;

        @media (--viewport-tablet) {
            width: 150px;
        }
    }

    &__product-name {
        @mixin hero-subtle;

        @media (--viewport-tablet) {
            @mixin large-subtle;
        }
    }

    &__leftside {
        margin-bottom: 40px;

        @media (--viewport-desktop) {
            @mixin colls 4;

            margin-inline-end: 16px;
        }
    }

    &__rightside {
        @media (--viewport-tablet) {
            display: flex;
        }

        @media (--viewport-desktop) {
            @mixin colls 8;
        }
    }

    &__card {
        &:not(:last-of-type) {
            margin-bottom: 16px;

            @media (--viewport-tablet) {
                margin-bottom: 0;
                margin-inline-end: 16px;
            }
        }

        @media (--viewport-tablet) {
            width: 50%;
        }
    }
}
</style>
