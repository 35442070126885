<template>
    <div class="s-sub-menu-product">
        <a-sub-menu
            v-sticky="{ parent: '.s-sub-menu-product' }"
            :product-name="getData('productName')"
            :buttons="getData('buttons')"
            :is-light="isLight"
            :always-visible="isSubmenuVisible"
            :not-glyph="notGlyph"
            :send-event="true"
            v-bind="getSubMenuData"
        />
    </div>
</template>

<script>
import ASubMenu from '@core/components/sub-menu/sub-menu.vue';
import Sticky from '@core/directives/sticky.js';
import productMixin from '@core/mixins/product.js';

export default {
    name: 'SSubMenuProduct',

    components: {
        ASubMenu,
    },

    directives: {
        Sticky,
    },

    mixins: [productMixin],

    props: {
        menu: {
            type: String,
            default: '',
        },

        isLight: {
            type: Boolean,
            default: false,
        },

        productName: {
            type: String,
            default: '',
        },

        buttons: {
            type: Array,
            required: false,
            default: () => [],
            validator: (buttons) => buttons.length < 3,
        },

        subMenu: {
            type: Object,
            default: () => ({
                menu: '',
                childPages: [],
            }),
        },
        notGlyph: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        childPages() {
            return this.hasItems(this.subMenu.childPages) || this.hasItems(this.productPages.children);
        },
        isSubmenuVisible() {
            return this.childPages && this.childPages.length > 1;
        },
        getSubMenuData() {
            const menu = this.menu || this.productPages.menu;

            return {
                childPages: this.childPages,
                menu,
            };
        },
    },
    methods: {
        hasItems(arr) {
            if (Array.isArray(arr) && arr.length) return arr;
            return false;
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-sub-menu-product {
    height: 64px;
    position: relative;
    top: 0;

    .a-submenu.a-submenu_always-visible {
        position: relative;
        top: 0;
        transition: none;

        &.is-sticky {
            position: fixed;
            top: 0;
            margin-bottom: 0;
        }
    }
}
</style>
