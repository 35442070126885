<template>
    <s-basic-slice class="s-videos" v-bind="$attrs">
        <div class="s-videos__content">
            <ul class="s-videos__list">
                <li v-for="(card, i) in cards" :key="i" class="s-videos__item">
                    <a-media v-if="card.media" v-bind="card.media" class="s-videos__card" />
                    <a-video-card v-else class="s-videos__card" v-bind="card" />
                </li>
            </ul>
            <a-link
                v-if="link"
                class="s-videos__link"
                v-bind="link"
                :type="link.type || 'direct'"
                :glyph="link.glyph || 'arrow'"
            />
        </div>
    </s-basic-slice>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import AMedia from '@core/components/media/media.vue';
import AVideoCard from '@core/components/video-card/video-card.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SVideos',

    components: {
        ALink,
        AMedia,
        AVideoCard,
        SBasicSlice,
    },

    inheritAttrs: false,

    props: {
        cards: {
            type: Array,
            required: true,
            validator: (cards) => cards.length > 1 && cards.length < 5,
        },

        link: {
            type: [Object, Boolean],
            default: false,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-videos {
    &__list {
        overflow-x: auto;
        white-space: nowrap;
        margin: 0 -16px -17px;
        padding: 0 8px 16px;
        scrollbar-width: none; /* Firefox */

        @media (--viewport-mobile-wide) {
            overflow-x: visible;
            white-space: normal;
            margin: -16px 0 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @media (--viewport-desktop-wide) {
            width: calc(((100% - 32px) / 3) * 2 + 16px);
        }

        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }

    &__item {
        display: inline-block;
        white-space: normal;
        width: 271px;
        margin: 0 8px;
        vertical-align: bottom;

        @media (--viewport-mobile-wide) {
            width: calc(50% - 8px);
            margin: 16px 0 0;
        }
    }

    &__link {
        margin-top: 56px;

        @media (--viewport-desktop) {
            position: absolute;
            top: 8px;
            inset-inline-end: var(--container-paddings-mobile-wide);
            margin-top: 0;
        }

        @media (--viewport-desktop-wide) {
            inset-inline-end: var(--container-paddings-desktop-wide);
        }

        @media (--viewport-desktop-large) {
            inset-inline-end: var(--container-paddings-desktop-large);
        }
    }
}
</style>
