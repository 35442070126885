<template>
    <div class="scs-page-not-found-wrapper">
        <s-acronis-scs-header />
        <a-picture
            v-if="data.image"
            class="background"
            :link="data.image"
            :fit="{ default: 'cover' }"
            position="center top"
        />
        <section class="scs-page-not-found" :class="{ ...basicClassName }">
            <div class="a-container">
                <div class="content">
                    <div class="top">
                        <a-slice-header
                            class="slice-header"
                            :title="data.title"
                            :lead="data.lead"
                        />
                        <a-button
                            class="button"
                            v-bind="data.homepageLink"
                            :type="data.homepageLink.type || 'main'"
                        />
                    </div>
                    <div class="bottom">
                        <div class="copyright">
                            {{ data.copyrightText.replace('{year}', new Date().getFullYear()) }}
                        </div>
                        <a-link class="privacy" v-bind="data.privacyLink" />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SAcronisScsHeader from '@core/slices/scs/acronis-scs-header/acronis-scs-header.vue';

export default {
    name: 'ScsPageNotFound',
    components: {
        AButton,
        ALink,
        APicture,
        ASliceHeader,
        SAcronisScsHeader,
    },
    mixins: [contentMixin, styleMixin],
    computed: {
        data() {
            return this.$store.state.slices.items['s-page-not-found-scs'] || {};
        },
    },
};
</script>

<style lang="postcss" scoped>
.scs-page-not-found-wrapper {
    background: linear-gradient(180deg, #f4f7fc 0%, #fff 100%);
    height: 100vh;
}
.background {
    position: absolute;
    height: 100%;
    width: 100%;
    inset-inline: 0;
    bottom: 0;
    max-width: 524px;
    max-height: 420px;
    margin: auto;

    @media (min-height: 800px) {
        max-height: 492px;
    }

    @media (min-height: 960px) {
        max-height: 620px;
    }

    :deep(.a-picture__img) {
        width: 100%;
        height: 100%;
    }
}
.scs-page-not-found {
    padding: 144px 0 24px;
    height: 100%;
    @media (--viewport-mobile-wide) {
        padding: 144px 0 40px;
    }
    .a-container {
        height: 100%;
    }
    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        @media (--viewport-desktop) {
            margin: 0 auto;
            width: 83.33%;
        }
    }
    .top {
        text-align: center;
        .button {
            padding: 8px 32px;
        }
    }
    .slice-header {
        margin-bottom: 24px;
        text-align: center;
        @media (--viewport-desktop) {
            width: 100%;
        }
        &:deep(.a-slice-header__title) {
            margin-bottom: 16px;
        }
    }
    .bottom {
        text-align: center;
    }
    .copyright {
        @mixin body;
        margin-bottom: 8px;
        color: var(--av-nav-primary);
    }
}
</style>
