<template>
    <section v-if="isVisible" v-show="isAvailable" class="main-page-services">
        <div class="a-container">
            <a-tabs type="tile">
                <a-tab v-for="(item, itemIndex) in content.list" :key="itemIndex" :label="item.label">
                    <div class="wrapper">
                        <a-picture v-if="item.picture?.link" v-bind="item.picture" />
                        <div class="content">
                            <div>
                                <s-main-page-tag v-bind="tag({name: 'title'}, item)" />
                                <s-main-page-tag v-bind="tag({name: 'description', isDangerous: true}, item)" />
                            </div>
                            <s-main-page-cta :content="item.cta" />
                        </div>
                    </div>
                </a-tab>
            </a-tabs>
        </div>
    </section>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';
import ATab from '@core/components/tabs/tab.vue';
import ATabs from '@core/components/tabs/tabs.vue';
import SMainPageCta from '../components/cta.vue';
import mixin from '../components/mixin.js';
import SMainPageTag from '../components/tag.vue';

export default {
    name: 'SMainPageServices',
    components: {
        APicture,
        ATab,
        ATabs,
        SMainPageCta,
        SMainPageTag,
    },
    mixins: [mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    computed: {
        isVisible() {
            return this.content?.list?.length;
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-services {
    margin: 48px 0 0;
    .wrapper {
        gap: 16px;
        display: flex;
        flex-direction: column-reverse;
        @media (--viewport-desktop) {
            flex-direction: row;
        }
    }
    .content {
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: space-between;
        @media (--viewport-desktop) {
            flex: 1 1 0;
            text-align: start;
        }
    }
    .title {
        @mixin lead-accent;
        color: var(--av-fixed-primary);
        @media (--viewport-tablet) {
            @mixin title-accent;
        }
    }
    .description {
        @mixin paragraph;
        margin-top: 16px;
        color: var(--av-fixed-secondary);
        @media (--viewport-tablet) {
            @mixin title;
        }
        @media (--viewport-desktop) {
             @mixin lead;
        }
        @media (--viewport-desktop-large) {
            @mixin title;
        }
    }
    .main-page-cta {
        gap: 16px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        justify-content: space-between;
        &:deep(.a-button) {
            box-shadow: none;
            border: 1px solid #2668c5;
            @media (--viewport-tablet) {
                flex: 1 1 0;
            }
        }
    }
    &:deep(.a-tabs) {
        .a-tabs__header {
            margin-bottom: 32px;
        }
    }
    &:deep(.a-picture) {
        .a-picture__img {
            width: 100%;
        }
        @media (--viewport-tablet) {
            @mixin colls 10;
            margin: 0 auto;
            @media (--viewport-desktop) {
                @mixin colls 6;
                height: 284px;
            }
            @media (--viewport-desktop-wide) {
                height: 342px;
            }
            @media (--viewport-desktop-large) {
                height: 390px;
            }
        }
    }
}
</style>
