<template>
    <div class="overview-program">
        <div class="wrapper a-container">
            <div class="cta-links">
                <div v-for="link of ctaLinks" :key="link.to" class="cta-link">
                    <a-dynamic-link :to="link.to" class="cta-anchor">
                        <div class="cta-title">
                            <span>{{ link.title }}</span>
                            <a-glyph name="arrow" size="m" />
                        </div>

                        <div class="cta-subtitle">
                            {{ link.subtitle }}
                        </div>
                    </a-dynamic-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ADynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'DeveloperOverviewProgram',

    components: {
        ADynamicLink,
        AGlyph,
    },

    props: {
        ctaLinks: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.overview-program {
    background: var(--av-nav-primary);
    color: var(--av-inversed-primary);
    padding: 8px 0 64px 0;
}

.cta-links {
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px;

    @media (--viewport-desktop) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.cta-link {
    max-width: auto;

    @media(--viewport-desktop) {
        max-width: 380px;
    }
}

.cta-title {
    @mixin title-accent;
    line-height: 24px;
    color: var(--av-link-inversed);
    margin-bottom: 8px;
}

.cta-title:deep(.a-glyph) {
    fill: var(--av-link-inversed);
}

.cta-subtitle {
    @mixin paragraph;
}
</style>
