<template>
    <div class="event-message-with-image">
        <h2 v-if="title" class="title">
            {{ title }}
        </h2>
        <div v-if="lead" class="lead">
            {{ lead }}
        </div>
        <a-picture
            class="image"
            :background="image"
            fit="cover"
            size="normal"
        />
    </div>
</template>

<script>
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'EventMessageWithImage',

    components: {
        APicture,
    },
    props: {
        title: {
            type: String,
            default: null,
        },

        lead: {
            type: String,
            default: null,
        },

        image: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-message-with-image {
    padding: 64px 0 116px;
    text-align: center;

    @media (--viewport-tablet) {
        padding: 64px 0 48px;
    }

    @media (--viewport-desktop) {
        padding: 88px 0 56px;
    }

    .title,
    .lead {
        @media (--viewport-desktop) {
            width: 67%;
            margin-inline-start: auto;
            margin-inline-end: auto;
        }
    }

    .title {
        @media (--viewport-tablet) {
            @mixin display-strong;
        }

        @media (--viewport-desktop) {
            @mixin hero-strong;
        }
    }

    .lead {
        margin-bottom: 40px;
    }

    .image {
        height: 328px;
        border-radius: 4px;
        overflow: hidden;

        @media (--viewport-tablet) {
            height: 461px;
        }
    }
}
</style>

<style lang="postcss">
.event-message-with-image {
    .image {
        .a-picture__img {
            object-position: top !important;
            width: 100%;
        }
    }
}
</style>
