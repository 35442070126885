<script>
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import { normalizeHrefsInHtmlFragment } from '@utils/normalize-href.js';

// This component is needed to work with a clean html that will come from admin.
export default {
    name: 'ADangerousHtml',

    props: {
        content: {
            type: String,
            required: true,
        },

        tag: {
            type: String,
            default: 'div',
        },

        refinements: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        normalizedRefinements() {
            return { injectHrefLocale: true, ...this.refinements };
        },

        injectedLocale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },

        refinedContent() {
            let result = this.content || '';

            if (this.normalizedRefinements.injectHrefLocale) {
                result = normalizeHrefsInHtmlFragment(result, this.injectedLocale);
            }
            return result;
        },
    },
    render(h) {
        return h(
            this.tag,
            {
                class: 'a-dangerous-html',
                domProps: {
                    innerHTML: this.refinedContent,
                },
            },
            this.$slots.default,
        );
    },
};
</script>

<style lang="postcss">
.a-dangerous-html {
    p {
        &:not(:last-of-type) {
            margin-bottom: 16px;
        }
    }

    ::marker {
        content: initial;
    }

    ul {
        li {
            margin-bottom: 16px;
            &:last-child {
                margin: 0;
            }
            &::before {
                content: '';
                width: 4px;
                height: 4px;
                border-radius: 100%;
                margin-inline-end: 16px;
                background: currentcolor;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    a {
        cursor: pointer;
        text-decoration: none;
        color: var(--av-brand-primary);

        &:hover {
            color: var(--av-brand-secondary);
        }
    }

    sup {
        font-size: 10px;
        vertical-align: super;
    }

    small {
        font-size: 10px;

        sup {
            font-size: 8px;
        }
    }
}
</style>
