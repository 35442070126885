<template>
    <div v-if="breadcrumbs.length" class="resource-breadcrumbs">
        <dynamic-link
            v-if="isMobile"
            class="nav-back"
            :to="breadcrumbs[0].to"
        >
            <a-glyph name="arrow-left" />
            <span>{{ commonTranslations.homePageBreadcrumb }}</span>
        </dynamic-link>
        <a-breadcrumbs
            v-else
            class="breadcrumbs"
            :links="breadcrumbs"
            glyph-desktop="icon-pointer-right"
            glyph-mobile="long-arrow-left"
            theme="light"
            sublinks
        />
    </div>
</template>

<script lang="ts">
import ABreadcrumbs from '@core/components/breadcrumbs/breadcrumbs.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import breakpoint from '@core/mixins/breakpoint.js';

export default {
    name: 'ResourceBreadcrumbs',

    components: {
        ABreadcrumbs,
        DynamicLink,
        AGlyph,
    },

    mixins: [breakpoint],

    props: {
        breadcrumbs: {
            type: Array,
            required: true,
        },
        commonTranslations: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.nav-back {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &:deep(.a-link__content) {
        padding-inline-start: 21px;
    }

    &:deep(.a-link__content span) {
        display: block;
        font-weight: 700;
        font-size: 12px;
    }

    &:deep(.a-glyph) {
        top: 8px;
    }
}

.nav-back span {
    @mixin paragraph;
    font-weight: 600;
    color: var(--av-brand-primary);
}

.breadcrumbs {
    padding: 0;

    &:deep(.a-container) {
        padding: 0;
    }
}
</style>
