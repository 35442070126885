<template>
    <section v-if="isVisible" class="main-page-header">
        <div class="a-container">
            <a-tabs type="tile" @click="$emit('setTabActive', $event)">
                <a-tab v-for="(item, itemIndex) in content.list" :key="itemIndex" v-bind="tab(item)">
                    <div class="wrapper">
                        <div class="content">
                            <s-main-page-tag v-bind="tag({name: 'title'}, item)" />
                            <s-main-page-tag v-bind="tag({name: 'description', isDangerous: true}, item)" />
                            <s-main-page-cta :content="item.cta" />
                        </div>
                        <a-media v-if="item.media" v-bind="item.media" class="media" />
                    </div>
                </a-tab>
            </a-tabs>
            <a-picture v-if="content.picture?.background" class="background" v-bind="content.picture" />
        </div>
    </section>
</template>

<script>
import AMedia from '@core/components/media/media.vue';
import APicture from '@core/components/picture/picture.vue';
import ATab from '@core/components/tabs/tab.vue';
import ATabs from '@core/components/tabs/tabs.vue';
import breakpoints from '@core/mixins/breakpoint';
import SMainPageCta from '../../components/cta.vue';
import mixin from '../../components/mixin.js';
import SMainPageTag from '../../components/tag.vue';

export default {
    name: 'SMainPageHeaderVariant3',
    components: {
        ATab,
        ATabs,
        AMedia,
        APicture,
        SMainPageCta,
        SMainPageTag,
    },
    mixins: [breakpoints, mixin],
    props: {
        content: {
            type: Object,
            default: null,
        },
    },
    emits: ['setTabActive'],
    data() {
        return {
            isReady: false,
        };
    },
    computed: {
        isVisible() {
            return this.isReady && this.content.list?.length;
        },
    },
    mounted() {
        this.isReady = true;
    },
    methods: {
        tab(item) {
            if (!item.tab.label) return {};
            if (this.isMobile) return { label: item.tab.label };
            return this.isTablet ? { ...item.tab, aLabel: null } : item.tab;
        },
    },
};
</script>

<style lang="postcss" scoped>
.main-page-header {
    padding: 96px 0 0;
    @media (--viewport-desktop) {
        padding-top: 104px;
    }
    .wrapper {
        gap: 32px;
        display: flex;
        flex-direction: column;
        @media (--viewport-tablet) {
            @mixin colls 10;
            margin: 0 auto;
        }
        @media (--viewport-desktop) {
            gap: 16px;
            width: 100%;
            flex-direction: row;
        }
    }
    .content {
        flex: 1 1 0;
        text-align: center;
        @media (--viewport-desktop) {
            width: 50%;
            text-align: start;
        }
        @media (--viewport-desktop-large) {
            width: auto;
            min-width: 538px;
        }
        .title {
            @mixin display-accent;
            font-weight: 700;
            color: var(--av-nav-primary);
            @media (--viewport-desktop-wide) {
                @mixin hero-accent;
            }
            @media (--viewport-desktop-large) {
                @mixin large;
            }
        }
        .description {
            @mixin title;
            margin-top: 16px;
            color: var(--av-fixed-secondary);
            @media (--viewport-tablet) {
                color: var(--av-fixed-light);
            }
            @media (--viewport-desktop-wide) {
                @mixin display;
            }
        }
        .main-page-cta {
            display: flex;
            flex-wrap: wrap;
            margin: 40px 0 0;
            flex-direction: row;
            &:deep(.a-button) {
                flex: 1 1 0;
            }
        }
    }
    .media {
        width: 100%;
        @media (--viewport-desktop) {
            width: 50%;
        }
        @media (--viewport-desktop-large) {
            width: 100%;
            padding: 0;
            padding-inline-start: 64px;
        }
    }
    .background {
        z-index: -1;
        top: 176px;
        width: 100%;
        height: 600px;
        position: absolute;
        inset-inline-start: 0;
        @media (--viewport-tablet) {
            top: 150px;
        }
        @media (--viewport-desktop) {
            top: 161px;
        }
        @media (--viewport-desktop-large) {
            border-top: 2px solid #c7dcfa;
        }
        &:deep(.a-picture__img) {
            width: 100%;
            height: 100%;
        }
    }
    &:deep(.a-container) {
        position: unset;
    }
    &:deep(.a-tabs) {
        .a-tabs__list-wrapper {
            padding: 0;
        }
        .a-tabs__list-wrapper__list {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: stretch;
        }
        .a-tabs__header {
            padding: 0 16px;
            margin: 0 -16px;
            overflow: hidden;
            border-bottom: 2px solid #c7dcfa;
            background: var(--av-inversed-primary);
            @media (--viewport-mobile-wide) {
                margin: 0 -32px;
                padding: 0 32px;
            }
            @media (--viewport-desktop-wide) {
                margin: 0 -64px;
                padding: 0 64px;
            }
            @media (--viewport-desktop-large) {
                border: none;
                background: transparent;
            }
        }
        .a-tabs__item {
            flex: unset;
            padding: 0;
            display: flex;
            box-shadow: none;
            @media (--viewport-tablet) {
                flex-grow: 1;
            }
            @media (--viewport-desktop) {
                flex-grow: unset;
            }
            @media (--viewport-desktop-large) {
                background: var(--av-inversed-primary);
            }
            &:first-of-type {
                .a-tabs-item {
                    border-start-start-radius: 16px;
                    border-end-start-radius: 0;
                }
            }
            &:last-of-type {
                .a-tabs-item {
                    border-start-end-radius: 16px;
                    border-end-end-radius: 0;
                }
            }
            .a-tabs-item {
                gap: 8px;
                height: 100%;
                display: flex;
                align-items: center;
                transition: unset;
                padding: 16px 8px;
                flex-direction: row;
                border-bottom: none;
                background: rgba(166, 203, 255, 0.25);
                &:hover {
                    .a-glyph {
                        fill: var(--av-nav-primary);
                    }
                }
                @media (--viewport-tablet) {
                    padding: 16px 24px;
                }
                .a-glyph {
                    transition: unset;
                    fill: var(--av-brand-primary);
                }
            }
            .a-tabs-item__label {
                gap: 12px;
                display: flex;
                font-weight: 400;
                transition: unset;
                align-items: center;
                @media (--viewport-tablet) {
                    font-weight: 700;
                }
            }
            .a-tabs-item_active {
                background: var(--av-nav-secondary);
                &:hover {
                    .a-glyph {
                        fill: var(--av-inversed-primary);
                    }
                }
                .a-glyph {
                    fill: var(--av-inversed-primary);
                }
                .a-tabs-item__label {
                    color: var(--av-inversed-primary) !important;
                }
            }
        }
        .a-tabs__content {
            padding: 40px 0 0;
            @media (--viewport-desktop) {
                padding: 64px 0 0;
            }
        }
    }

}
</style>
