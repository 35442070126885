<template>
    <div v-if="content" class="s-form-upsell">
        <div v-if="content.flag" class="s-form-upsell-flag">
            {{ content.flag }}
        </div>
        <div v-if="content.title" class="s-form-upsell-title">
            {{ content.title }}
        </div>
        <a-picture v-if="content.image" class="s-form-upsell-image" :link="content.image" />
        <div v-if="content.description" class="s-form-upsell-description">
            {{ content.description }}
        </div>
        <a-button
            v-if="content.button"
            class="s-form-upsell-button"
            to="#"
            :text="content.button"
            :event="{ doNotSendGA: true }"
            @click="onClick()"
        />
        <a-button
            v-if="content.link"
            class="s-form-upsell-link"
            :text="content.link"
            to="#"
            :event="{ doNotSendGA: true }"
            @click="onSubmit()"
        />
    </div>
</template>

<script>
import { get, isEmpty } from 'lodash';
import AButton from '@core/components/button/button.vue';
import APicture from '@core/components/picture/picture.vue';
import form from '@core/mixins/form.js';

export default {
    name: 'TrialFormUpsell',

    components: {
        AButton,
        APicture,
    },

    mixins: [form],

    props: {
        content: {
            type: Object,
            default: null,
        },
    },

    emits: ['submit', 'showNotification'],

    mounted() {
        this.sendDataLayer({
            eventCategory: 'Upsell',
            eventAction: 'show',
            eventLabel: 'dnl_typage_promo',
            formFieldA: 'Prosumer End-Customer',
        });
    },

    methods: {
        onSubmit() {
            this.sendDataLayer({
                eventCategory: 'Upsell',
                eventAction: 'click',
                eventLabel: 'Acronis Backup',
            });
            this.$emit('submit');
        },

        onClick() {
            const notification = get(this, 'content.notification', null);
            const url = `https://www.acronis.com/${this.locale}/homecomputing/thanks/home-office/`;

            this.sendDataLayer({
                eventCategory: 'Upsell',
                eventAction: 'click',
                eventLabel: 'Acronis True Image',
            });

            if (isEmpty(notification)) {
                window.location.assign(url);
                return;
            }

            if (notification.openLink) {
                window.open(url);
            }

            this.$emit('showNotification', notification);
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-form-upsell {
    @mixin body;
    text-align: center;
    padding: 40px 16px 56px;
    background: var(--av-inversed-primary);

    &-flag {
        @mixin note;
        color: #83ad00;
        min-width: 110px;
        padding: 4px 12px;
        border-radius: 12px;
        display: inline-block;
        background-color: rgba(141, 183, 14, 0.15);
    }

    &-title {
        @mixin title-accent;
        margin-top: 16px;
        color: var(--av-fixed-secondary);
    }

    &-image {
        margin: 24px auto 0;
        width: 248px;
        height: 168px;
    }

    &-description {
        margin-top: 24px;
        color: var(--av-fixed-secondary);
    }

    &-button {
        @mixin body;
        color: var(--av-inversed-primary);
        display: block;
        margin-top: 24px;
        padding: 4px 16px;
        background-color: #4875b3;

        &:hover {
            color: var(--av-inversed-primary);
            background-color: #4875b3;
        }
    }

    &-link {
        padding: 0;
        color: #0275d8;
        font-size: 14px;
        font-weight: 400;
        background: none;
        margin-top: 16px;
        line-height: 20px;
        white-space: unset;

        &:hover {
            background: none;
            color: #014c8c;
        }
    }

    &-link,
    &-button {
        border: none;
        box-shadow: none;
    }
}

@media (--viewport-mobile-wide) {
    .s-form-upsell {
        border-radius: 4px;
        box-shadow: var(--av-shadow-small);
        border: 1px solid var(--av-brand-light);
    }
}

@media (--viewport-tablet) {
    .s-form-upsell {
        padding: 40px 48px;
    }
}
</style>
