<template>
    <div class="blog-experts">
        <h1 v-if="title" class="title">
            {{ title }}
        </h1>
        <div class="experts">
            <div v-for="(author, i) in authors" :key="i" class="expert">
                <div class="top">
                    <a-picture class="photo" :link="author.photo || fallbackImage" fit="cover" />
                    <div class="info">
                        <a-link class="name" :to="author.link" :text="author.name" />
                        <div v-if="author.position" class="position">
                            {{ author.position }}
                        </div>
                        <div class="socials">
                            <dynamic-link
                                v-for="(social, j) in author.socials"
                                :key="j"
                                class="social"
                                :to="social.to"
                                native-type="a"
                            >
                                <a-glyph :name="social.glyph" />
                            </dynamic-link>
                        </div>
                    </div>
                </div>
                <div v-if="author.description" class="text">
                    {{ truncateDescription(author.description) }}
                </div>
                <a-link
                    class="link"
                    :text="linkText"
                    :to="author.link"
                    glyph="arrow"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { truncate } from 'lodash';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'BlogExperts',
    components: {
        APicture,
        ALink,
        AGlyph,
        DynamicLink,
    },
    props: {
        title: {
            type: String,
            default: undefined,
        },

        authors: {
            type: Array,
            required: true,
        },

        linkText: {
            type: String,
            required: true,
        },

        section: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            fallbackImage: 'fd3353668ea5bda3b807d3682fe6ac8e',
        };
    },
    methods: {
        truncateDescription(desc) {
            return truncate(desc, { length: 315 });
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-experts {
    .title {
        @mixin display;

        text-align: center;
        font-weight: bold;
        color: var(--av-nav-primary);
        margin-bottom: 40px;

        @media (--viewport-tablet) {
            @mixin hero;

            margin-bottom: 80px;
            text-align: start;
        }
    }

    .experts {
        @media (--viewport-tablet) {
            display: grid;
            column-gap: 16px;
            grid-template-columns: 1fr 1fr;
        }

        @media (--viewport-desktop) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .expert {
        margin-bottom: 64px;
        position: relative;

        @media (--viewport-tablet) {
            padding-bottom: 24px;
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
    }

    .photo {
        flex-grow: 0;
        flex-shrink: 0;
        width: 96px;
        height: 96px;
        border-radius: 4px;
        margin-inline-end: 16px;
        margin-bottom: 24px;
    }

    .info {
        flex-grow: 1;
    }

    .name {
        @mixin lead-accent;

        color: var(--av-brand-secondary);
        text-decoration: none;
        margin-bottom: 8px;
    }

    .position {
        @mixin paragraph;

        color: var(--av-fixed-secondary);
        margin-bottom: -6px;
    }

    .social {
        display: inline-block;
        margin-inline-end: 16px;
    }

    .text {
        color: var(--av-fixed-primary);
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .link {
        @mixin paragraph-accent;

        color: var(--av-brand-secondary);
        text-decoration: none;

        @media (--viewport-tablet) {
            position: absolute;
            bottom: 0;
            inset-inline-start: 0;
        }
    }
}
</style>
