<template>
    <s-basic-slice :id="id" class="s-cta" :class="{ ...className, ...basicClassName }">
        <div class="s-cta__description" :class="classForFlags">
            <div class="s-cta__left">
                <h2
                    v-if="isVisible('productName')"
                    class="s-cta__product-name"
                >
                    {{ fullProductName }}
                </h2>
                <p
                    v-if="isVisible('infoFlag')"
                    class="s-cta__info-flag"
                >
                    {{ getData('infoFlag') }}
                </p>

                <p class="s-cta__text">
                    {{ getData('title') }}
                </p>
            </div>
        </div>

        <div class="s-cta__info">
            <div class="s-cta__left">
                <a-product-cta :settings="{ hasLabel: true, hasDescription: true, ...productPageCTA }" />
                <div class="s-cta__buttons">
                    <template v-if="isVisible('buttons')">
                        <a-button
                            v-for="(button, i) in getData('buttons')"
                            :key="`${i}-${button.to}`"
                            class="s-cta__button"
                            :class="{ 's-cta__button_with-context': button.context }"
                            v-bind="button"
                            :is-context="Boolean(button.context)"
                            :glyph="button.glyph || 'arrow'"
                            :type="button.type || getButtonType(i)"
                            :event="setEvent(button)"
                        />
                    </template>
                    <div v-if="isVisible('actionLinks')" class="s-cta__links">
                        <a-link
                            v-for="(link, i) in getData('actionLinks')"
                            :key="`${i}-${link.to}`"
                            class="s-cta__link"
                            v-bind="link"
                            :type="version === 'dark' ? 'light' : 'regular'"
                        />
                    </div>
                </div>
            </div>
        </div>
    </s-basic-slice>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ALink from '@core/components/link/link.vue';
import AProductCta from '@core/components/product-cta/product-cta.vue';
import analytics from '@core/mixins/analytics.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import contentMixin from '@core/mixins/content.js';
import form from '@core/mixins/form.js';
import l10n from '@core/mixins/l10n.js';
import productMixin from '@core/mixins/product.js';
import purchasing from '@core/mixins/purchasing.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import { VERSIONS } from './constants.js';

export default {
    name: 'SCta',

    components: {
        AButton,
        AProductCta,
        ALink,
        SBasicSlice,
    },

    mixins: [l10n, contentMixin, styleMixin, productMixin, purchasing, form, analytics, cleverbridge],

    props: {
        id: {
            type: String,
            default: '',
        },
        productName: {
            type: String,
            default: '',
        },
        infoFlag: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        productPageCTA: {
            type: Object,
            default: undefined,
        },
        buttons: {
            type: Array,
            validator: (buttons) => buttons.length < 3,
            default: () => [],
        },
        actionLinks: {
            type: Array,
            validator: (actionLinks) => actionLinks.length < 3,
            default: () => [],
        },
        version: {
            type: String,
            validator: (value) => value === undefined || VERSIONS.includes(value),
            default: 'dark',
        },
        isContext: {
            type: Boolean,
            default: false,
        },
        event: {
            type: Object,
            default() {
                return {
                    category: 'Conversions',
                    location: 'Middle CTA',
                };
            },
        },
    },

    computed: {
        className() {
            return { 's-cta_version_light': this.version === 'light' };
        },
        classForFlags() {
            return {
                's-cta__description_with-label': this.isVisible('infoFlag'),
                's-cta__description_with-label-2': this.isVisible('infoFlag'),
            };
        },
        fullProductName() {
            return `Acronis ${this.getData('productName')}`;
        },
    },

    methods: {
        getButtonType(index) {
            if (index === 0) {
                if (this.buttons.length === 1) return 'action';
                return 'main';
            }

            return 'action';
        },
    },

    async serverPrefetch() {
        await this.getLicenses(this.productPageCTA);
    },
};
</script>

<style lang="postcss" scoped>
.s-cta {
    background-color: var(--av-nav-primary);
    color: var(--av-inversed-primary);

    &__description,
    &__info {
        @media (--viewport-tablet) {
            display: flex;
        }
    }

    &__description {
        @media (--viewport-tablet) {
            align-items: flex-end;
        }

        .s-cta__left {
            @media (--viewport-tablet) {
                padding-inline-end: 16px;
            }
        }

        .s-cta__right {
            @media (--viewport-tablet) {
                padding-bottom: 4px;
            }

            @media (--viewport-desktop-wide) {
                padding-bottom: 5px;
            }
        }

        &_with-label {
            .s-cta__product-name {
                margin-bottom: 8px;
            }

            .s-cta__right {
                @media (--viewport-tablet) {
                    margin-bottom: 32px;
                }
            }
        }

        &_with-label-2 {
            .s-cta__right {
                @media (--viewport-tablet) {
                    margin-bottom: 56px;
                }
            }
        }
    }

    &-button-contexts {
        @mixin caption;
        margin-top: 8px;
        color: var(--av-inversed-light);
        display: block;

        @media (--viewport-mobile-wide) {
            display: none;
        }
    }

    &__info {
        margin-top: 24px;

        @media (--viewport-tablet) {
            margin-top: 32px;
        }

        .s-cta__right {
            margin-top: 40px;

            @media (--viewport-tablet) {
                margin-top: 0;
            }

            @media (--viewport-desktop) {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
            }
        }
    }

    &__left {
        @media (--viewport-tablet) {
            margin-inline-end: 16px;
        }
    }

    &__right {
        margin-top: 32px;

        @media (--viewport-tablet) {
            margin-top: 0;
        }
    }

    &__logo {
        height: 24px;
        width: 113px;

        @media (--viewport-desktop) {
            width: 150px;
            height: 32px;
        }
    }

    &__product-name {
        @mixin lead;

        @media (--viewport-mobile-wide) {
            @mixin title;
            max-width: 85%;
        }
    }

    &__info-flag {
        color: var(--av-inversed-primary);
        opacity: 0.7;
    }

    &__text {
        @mixin display-accent;
        max-width: 869px;
        margin-top: 16px;
        @media (--viewport-mobile-wide) {
            @mixin hero-accent;
        }
    }

    &__buttons {
        @media (--viewport-mobile-wide) {
            display: flex;
            align-items: flex-start;
            margin-inline-end: 16px;
        }
    }

    &__button {
        display: flex;
        justify-content: center;
        width: 100%;

        @media (--viewport-mobile-wide) {
            width: calc(25% - 8px);
            min-width: 165px;
            display: inline-flex;
            justify-content: space-between;
        }

        @media (--viewport-tablet) {
            width: auto;
        }

        &:not(:first-child) {
            margin-top: 16px;

            @media (--viewport-mobile-wide) {
                margin-top: 0;
                margin-inline-start: 16px;
            }
        }

        &:deep(.a-glyph) {
            display: none;

            @media (--viewport-mobile-wide) {
                display: block;
            }
        }

        &_with-context {
            display: block;

            &:deep(.a-button-with-context__text) {
                @mixin caption;
                text-align: start;
                margin-top: 8px;
                display: block;
            }

            &:deep(.a-button) {
                justify-content: center;

                @media (--viewport-mobile-wide) {
                    justify-content: space-between;
                }
            }
        }
    }

    &__links {
        margin-top: 24px;
        margin-inline-start: 0;

        @media(--viewport-mobile-wide) {
            margin-top: 0;
            margin-inline-start: 24px;
        }

        @media(--viewport-tablet) {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @media (--viewport-desktop) {
            margin: 0;
            margin-inline-start: 24px;
            align-self: center;
        }
    }

    &__link {
        display: block;
    }

    &_version {
        &_light {
            background: var(--av-brand-lightest);
            color: var(--av-nav-primary);

            .s-cta__link {
                &_call {
                    span {
                        color: var(--av-nav-primary);
                    }
                }
            }

            .s-cta__info-flag {
                color: var(--av-nav-primary);
            }

            &:deep(.cta) {
                color: var(--av-nav-primary)!important;
            }

            &:deep(.cta-description) {
                del {
                    color: var(--av-nav-primary)!important;
                }
            }
        }
    }

    &:deep(.cta) {
        @mixin title;
        margin-bottom: 24px;
        color: var(--av-inversed-primary);
    }

    &:deep(.cta-label) {
        font: var(--font-default);
        font-weight: 600;
    }

    &:deep(.cta-description) {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        del {
            opacity: 0.7;
            color: var(--av-inversed-light);
        }
    }
}
</style>
