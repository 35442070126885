<template>
    <s-basic-slice class="s-technology" v-bind="$attrs" :class="className">
        <div class="s-technology__content">
            <a-slider class="s-technology__slider" :options="sliceSliderOptions">
                <a-slide v-for="(card, index) in cards" :key="index" class="s-technology__slide">
                    <a-technology-card class="s-technology__card" v-bind="card" />
                </a-slide>
            </a-slider>
            <ul class="s-technology__card-list">
                <li v-for="(card, index) in cards" :key="index" class="s-technology__card-list-item">
                    <a-technology-card v-bind="card" />
                </li>
            </ul>
        </div>
    </s-basic-slice>
</template>

<script>
import ASlide from '@core/components/slider/slide.vue';
import ASlider from '@core/components/slider/slider.vue';
import ATechnologyCard from '@core/components/technology-card/technology-card.vue';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'STechnology',

    components: {
        ASlide,
        ASlider,
        ATechnologyCard,
        SBasicSlice,
    },

    inheritAttrs: false,

    props: {
        cards: {
            type: Array,
            required: true,
        },

        sliderOptions: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            sliceSliderOptions: {
                slidesPerView: 'auto',
                showNavigation: true,
                ...this.sliderOptions,
            },
        };
    },

    computed: {
        className() {
            return {
                's-technology_layout_2-cards': this.cards.length === 2,
                's-technology_layout_3-cards': this.cards.length === 3,
                's-technology_layout_4-cards': this.cards.length >= 4,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-technology {
    &__slider {
        width: calc(100% + var(--container-paddings-mobile));
    }

    &__slide {
        width: 84%;
        padding-inline-end: 16px;
        user-select: none;
    }

    &__card-list {
        display: none;
    }

    &__card-list-item {
        padding-inline-end: 16px;
    }

    &__card {
        padding-inline-end: 16px;
    }

    @media (--viewport-mobile-wide) {
        &__card-list-item {
            padding-inline-end: 32px;
        }

        &__slider {
            width: calc(100% + var(--container-paddings-mobile-wide));
        }

        &__slide {
            width: 41.66%;
        }

        &__content {
            mask-image: none;
        }

        &_layout {
            &_2-cards {
                .s-technology {
                    &__slider {
                        display: none;
                    }

                    &__card-list {
                        margin-inline-end: -16px;
                        display: flex;
                    }

                    &__card-list-item {
                        width: 50%;
                    }
                }
            }

            &_3-cards {
                .s-technology {
                    &__slider {
                        display: none;
                    }

                    &__card-list {
                        margin-inline-end: -16px;
                        display: flex;
                    }

                    &__card-list-item {
                        width: 33.33%;
                    }
                }
            }

            &_4-cards {
                .s-technology {
                    &__slide {
                        width: 41%;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop) {
        &_layout {
            &_2-cards {
                .s-technology {
                    &__card-list-item {
                        width: 33.33%;
                    }
                }
            }
        }
    }

    @media (--viewport-desktop-wide) {
        &__slider {
            width: 100%;
        }

        &_layout {
            &_4-cards {
                .s-technology {
                    &__slide {
                        width: 40%;
                    }
                }
            }
        }
    }
}
</style>
