<template>
    <div class="root">
        <div class="header-main">
            <div class="solutions-container">
                <div class="header-inner">
                    <a-picture
                        class="bg"
                        fit="cover"
                        is-background
                        :background="background"
                    />
                    <div class="content">
                        <a-dynamic-link
                            class="product"
                            native-type="a"
                            :to="productLink"
                            :event="{ action: productTitle }"
                        >
                            <div class="name">
                                {{ productTitle }}
                            </div>
                            <div class="caption">
                                {{ productSubtitle }}
                            </div>
                        </a-dynamic-link>

                        <h1 class="title">
                            {{ title }}
                        </h1>

                        <div class="subtitle">
                            {{ ctaTitle }}
                        </div>

                        <div class="main-buttons">
                            <a-button
                                v-for="button in ctaButtons"
                                :key="button.link"
                                class="button"
                                v-bind="button"
                                :to="button.link"
                                :event="{ category: 'Button click', action: button.text, label: 'top menu' }"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SolutionsHeaderMain',

    components: {
        AButton,
        ADynamicLink,
        APicture,
    },

    props: {
        background: {
            type: Object,
            default: null,
        },
        productTitle: {
            type: String,
            required: true,
        },
        productSubtitle: {
            type: String,
            required: true,
        },
        productLink: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        ctaTitle: {
            type: String,
            default: '',
        },
        ctaButtons: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="postcss" scoped>
.root {
    background: var(--av-nav-primary);

    .main-buttons {
        .button:not(:last-child) {
            margin-inline-end: 16px;
        }
    }

    .header-main {
        position: relative;

        .content {
            padding-top: 12px;
            position: relative;
            padding-bottom: 48px;
            display: flex;
            flex-direction: column;
            min-height: 360px;
        }
    }

    .header-inner {
        position: relative;
    }

    .bg {
        display: none;

        @media (--viewport-desktop) {
            display: block;
            top: 0;
            position: absolute;
            inset-inline-start: 0;
            inset-inline-end: 0;
            width: 100%;
            margin: auto;
            height: 100%;
        }
    }

    .product {
        height: 56px;
        display: block;
        position: relative;

        .name {
            font-family: "Acronis Cyber", sans-serif;
            font-size: 22px;
            line-height: 32px;
            color: var(--av-inversed-primary);
            text-decoration: none;
            margin-top: 1px;
            margin-bottom: 4px;
        }

        .caption {
            position: absolute;
            color: var(--av-link-inversed);
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 4px;
            text-transform: uppercase;
            padding-inline-end: 48px;
            font-weight: 600;
        }
    }

    .title {
        font-size: 32px;
        line-height: 40px;
        margin-top: auto;
        margin-bottom: 40px;
        color: var(--av-inversed-primary);

        @media (--viewport-mobile-wide) {
            font-size: 40px;
            line-height: 48px;
        }

        @media (--viewport-desktop) {
            max-width: 50%;
        }

        @media (--viewport-desktop-wide) {
            font-size: 48px;
            line-height: 56px;
        }
    }

    .subtitle {
        display: flex;
        margin: 0 0 16px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        align-items: center;
        color: var(--av-fixed-success);
    }
}
</style>
