<template>
    <div class="s-purchasing-asd a-container">
        <h1 class="product-name">
            {{ productName }}
        </h1>
        <h2 v-if="title" class="title">
            {{ title }}
        </h2>
        <p v-if="lead" class="lead">
            {{ lead }}
        </p>
        <p v-if="hasPromoDescription" class="promo">
            <a-glyph v-if="promo.glyph" :name="promo.glyph" size="m" />
            {{ promo.description }}
        </p>
        <div class="wrapper">
            <div class="content">
                <div class="products">
                    <div
                        v-for="(product, index) in productList"
                        :key="`product-${index}`"
                        :class="classProduct(index)"
                        @click="slice.selectedProduct = index"
                    >
                        <p v-if="product.title" class="product-title">
                            {{ product.title }}
                        </p>
                        <div class="selector">
                            <p class="quantity-title">
                                {{ dictionary('Quantity') }}
                            </p>
                            <div class="quantity">
                                <el-num-picker
                                    v-model="product.selected.quantity"
                                    :step="1"
                                    :min="product.quantity.min || 0"
                                    :max="product.quantity.max || 200"
                                    :class="{'wide': product.selected.quantity > 99}"
                                    integer-only="integer-only"
                                />
                                <div class="multiplier">
                                    ×
                                </div>
                                <div class="license-price" v-html="productPrice(product, index)" />
                            </div>
                            <div class="period">
                                <p class="period-title">
                                    {{ dictionary('Subscription period') }}
                                </p>
                                <s-selector
                                    :list="listSelector(index)"
                                    :selected="Number(product.selected.period)"
                                    @change="product.selected.period = $event.target.value"
                                />
                            </div>
                            <p v-if="isBenefitVisible(product)" class="benefit" v-html="product.benefit" />
                            <div class="max-quantity-wrapper" :style="wrapperHeight">
                                <div v-if="hasMaxQuantity(product)" class="max-quantity">
                                    <a-glyph name="discount" />
                                    <p class="max-quantity-title">
                                        {{ dictionary(`${product.selected.period}Y-title`) }}
                                    </p>
                                    <p v-for="(item, i) in listPeriod(product)" :key="`max-quantity-${i}`" class="max-quantity-item">
                                        <span v-html="item.text" />
                                        <span v-html="productPrice(item)" />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <ul v-if="product.information" class="information">
                            <li v-for="(item, itemIndex) in product.information" :key="`information-${itemIndex}`" v-html="item" />
                        </ul>
                    </div>
                </div>
                <p v-if="slice.description" class="description" v-html="slice.description" />
            </div>
            <div v-if="slice.order" class="order">
                <p class="order-title">
                    {{ slice.order.title }}
                </p>
                <template v-if="selectedProducts.length">
                    <div v-for="(product, index) in selectedProducts" :key="`order-product-${index}`" class="order-product">
                        <div v-if="product.title" class="order-product-item">
                            <div class="order-product-title">
                                {{ product.title }}
                            </div>
                            <div class="order-product-price">
                                <div v-if="product.discount" class="order-product-price-full" v-html="productPriceFull(product)" />
                                <div class="order-product-price-current" v-html="productPriceCurrent(product)" />
                            </div>
                            <div class="order-product-remove" @click="product.selected.quantity = 0">
                                <a-glyph name="trash-o" />
                            </div>
                        </div>
                        <p class="order-product-quantity">
                            {{ dictionary('Quantity') }}: {{ product.selected.quantity }}
                        </p>
                        <p class="order-product-period">
                            {{ dictionary('Subscription period') }}:
                            <span>{{ dictionary(`${product.selected.period}Y-period`) }}</span>
                        </p>
                    </div>
                    <div v-if="slice.order.total" class="total">
                        <div class="total-item">
                            <p class="total-text">
                                {{ slice.order.total }}
                            </p>
                            <p class="total-vat">
                                {{ slice.order.vat }}
                            </p>
                        </div>
                        <div class="total-item">
                            <template v-if="hasDiscount()">
                                <div class="total-full" v-html="setCurrency(price + discount)" />
                                <div class="total-discount" v-html="priceDiscount" />
                            </template>
                            <div class="total-current" v-html="setCurrency(price)" />
                        </div>
                    </div>
                    <a-button
                        v-if="slice.order?.button?.title"
                        v-bind="slice.order.button"
                        :text="slice.order.button.title"
                        :type="slice.order.button?.type || 'action'"
                        :glyph="slice.order.button?.glyph || 'shopping-cart-o'"
                        @click="handleClick"
                    />
                </template>
                <div class="order-links">
                    <p v-for="(item, i) in slice.order.links" :key="`order-link-${i}`" class="order-links-item">
                        <template v-if="item.to">
                            <a-link
                                v-bind="item"
                                :to="setLocale(item.to)"
                                :text="item.title"
                            />
                        </template>
                        <span v-else v-html="item.title" />
                    </p>
                </div>
                <p v-for="(item, i) in listNotification" :key="`notification-${i}`" class="notifications">
                    <a-glyph v-if="item.glyph" :name="item.glyph" />
                    <span v-html="item.title || ''" />
                </p>
            </div>
        </div>
        <a-loader :is-visible="isVisibleSURL" />
    </div>
</template>

<script>
import { cloneDeep, merge, isInteger } from 'lodash';
import AButton from '@core/components/button/button.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import ALoader from '@core/components/loader/loader.vue';
import analytics from '@core/mixins/analytics.js';
import breakpoint from '@core/mixins/breakpoint.js';
import cleverbridge from '@core/mixins/cleverbridge.js';
import contentMixin from '@core/mixins/content.js';
import currency from '@core/mixins/currency.js';
import purchasing from '@core/mixins/purchasing.js';
import styleMixin from '@core/mixins/style.js';
import SSelector from './components/selector.vue';

export default {
    name: 'SPurchasingSnapDeploy',

    components: {
        AButton,
        AGlyph,
        ALink,
        ALoader,
        SSelector,
        ElNumPicker: () => import('@uikit/ui-kit/packages/num-picker'),
    },

    mixins: [breakpoint, contentMixin, styleMixin, purchasing, currency, analytics, cleverbridge],

    props: {
        title: {
            type: String,
            default: null,
        },

        lead: {
            type: String,
            default: null,
        },

        settings: {
            type: Object,
            default: null,
        },

        purchasePageCTA: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            coupon: '',
            promo: {},
            slice: [],
            price: 0,
            discount: 0,
        };
    },

    computed: {
        productList() {
            return this.slice.products || [];
        },

        listNotification() {
            return this.slice?.order?.notifications || [];
        },

        priceDiscount() {
            if (!this.promo.isPromoActive || !this.discount) return '';
            const label = this?.promo?.label || '';
            return label.replace('@price', this.setCurrency(this.discount));
        },

        productName() {
            return this.productItems[this.internalProductId]?.name || '';
        },

        hasPromoDescription() {
            return this.promo.isPromoActive && this.promo.description;
        },

        selectedProducts() {
            return this.productList.filter((product) => product.selected.quantity);
        },

        wrapperHeight() {
            const height = this.settings.height;
            return { height: this.isDesktop && height ? `${height}px` : 'auto' };
        },
    },

    created() {
        this.slice = cloneDeep(this.settings);
    },

    mounted() {
        this.coupon = this.$route.query?.coupon || '';

        if (this.coupon && this.activeCoupon.discount) {
            const list = this.activeCoupon.cleverBridgeCartList || '';
            this.promo = {
                ...this.promo,
                ...this.activeCoupon,
                isPromoActive: true,
                cleverBridgeCartList: list.split(',').map(Number),
            };
        } else {
            const isPurchase = true;
            const cta = merge(this.productCTA({ isPurchase }), this.purchasePageCTA);
            const isPromoActive = !cta.doNotApplyPromo && this.checkPromo({ ...cta.promo, isPurchase });

            this.promo = { ...cta.promo, isPurchase, isPromoActive };
        }
    },

    methods: {
        hasMaxQuantity(product) {
            return product.licenses.find((item) => item.maxQuantity);
        },

        hasDiscount() {
            this.price = this.selectedProducts
                .map((product) => product.price * product.selected.quantity || 0)
                .reduce((sum, price) => sum + price, 0);

            const discount = this.selectedProducts
                .map((product) => product.discount * product.selected.quantity || 0)
                .reduce((sum, price) => sum + price, 0);

            this.discount = discount || 0;

            return this.discount;
        },

        isBenefitVisible(product) {
            return !this.promo.isPromoActive && product.benefit;
        },

        classProduct(index) {
            return ['product-item', { 'product-item-active': this.slice.selectedProduct === index }];
        },

        dictionary(item) {
            return this.settings?.dictionary?.[item] || item;
        },

        setLocale(str) {
            return str ? str.replace(/LOCALE/g, this.locale) : '/';
        },

        findLicense(cleverBridgeCartID) {
            const license = this.licenseData({ cleverBridgeCartID, promo: this.promo });
            return { price: license.price || 0, discount: license.discount_absolute || 0 };
        },

        listSelector(index) {
            const product = this.productList[index];
            const license = product.licenses.find((item) => item.period === 1);
            const annual = this.findLicense(license.cleverBridgeCartID);
            const labels = product.licenses.map((item, i) => {
                if (item.maxQuantity || (!i && !this.promo.isPromoActive)) return '';
                const text = this.dictionary('Save XX%');
                const current = this.findLicense(item.cleverBridgeCartID);
                const price = this.promo.isPromoActive ? current.price + current.discount : annual.price * item.period;
                const result = Math.round((100 * (price - current.price)) / price);
                return result ? `<span class="promo">${text.replace('XX', result)}</span>` : '';
            });

            const result = product.licenses.map((item, i) => ({
                id: item.period,
                text: `<span>${this.dictionary(`${item.period}Y-period`)}</span>${labels[i]}`,
            }));

            return [...new Map(result.map((item) => [item.id, item])).values()];
        },

        listPeriod(product) {
            const period = Number(product.selected.period);
            return product.licenses.filter((item) => item.period === period);
        },

        productPrice(product, index) {
            const period = product.period || product.selected.period;
            let current = (product?.licenses || []).find((item) => item.period === Number(period)) || product;

            if (isInteger(index) && this.hasMaxQuantity(product)) {
                current = this.listPeriod(product).find((item) => product.selected.quantity < item.maxQuantity);
            }

            const cleverBridgeCartID = current.cleverBridgeCartID;
            const { price, discount } = this.findLicense(cleverBridgeCartID);

            if (isInteger(index)) {
                this.productList[index].price = price;
                this.productList[index].discount = discount;
                this.productList[index].cleverBridgeCartID = cleverBridgeCartID;
            }

            const currentPrice = `<span>${this.setCurrency(price)}</span>`;
            const fullPrice = `<del>${this.setCurrency(price + discount)}</del>`;
            return discount ? `${fullPrice}${currentPrice}` : currentPrice;
        },

        productPriceCurrent(product) {
            return this.setCurrency(product.price * product.selected.quantity);
        },

        productPriceFull(product) {
            return this.setCurrency((product.price + product.discount) * product.selected.quantity);
        },

        handleClick() {
            const options = this.storeOptionsSURL({
                shopId: 837,
                segment: 'corporate',
                params: { scope: 'checkout' },
            });

            if (this.promo?.isPromoActive && this.promo?.coupon) {
                options.params.coupon = this.promo.coupon;
            }

            if (this.activeCoupon.coupon) {
                options.params.coupon = this.activeCoupon.coupon;
            }

            this.selectedProducts.forEach((product) => {
                const separator = options.params.cart ? ',' : '';
                options.params.cart += `${separator}${product.cleverBridgeCartID}`;
                options.params[`quantity_${product.cleverBridgeCartID}`] = product.selected.quantity;

                const minQuantity = Math.min(...product.licenses.map((item) => item.maxQuantity));
                if (minQuantity && product.selected.quantity >= minQuantity) {
                    options.params.lockcart = true;
                }
            });

            this.redirectToCleverbridge(options);
        },
    },

    async serverPrefetch() {
        await this.findCleverBridgeCart(this.settings.products, { ...this.purchasePageCTA, isPurchase: true });
    },
};
</script>

<style lang="postcss" scoped>
.s-purchasing-asd {
    padding-top: 88px;
    padding-bottom: 48px;
}

.product-name {
    @mixin large;
    color: var(--av-nav-primary);
}

.title {
    @mixin display;
    margin-top: 56px;
    color: var(--av-nav-primary);

    @media (--viewport-tablet) {
        @mixin hero
    }
}

.lead {
    @mixin lead;
    margin-top: 16px;
    color: var(--av-fixed-secondary);
}

.promo {
    @mixin paragraph-accent;
    margin-top: 32px;
    margin-bottom: -24px;
    color: var(--av-fixed-success);

    .a-glyph {
        margin-inline-end: 8px;
        fill: var(--av-fixed-success);
    }
}

.wrapper {
    @media (--viewport-tablet) {
        display: flex;
        margin: 40px 0 0;
        justify-content: space-between;
    }
}

.content {
    margin: 40px 0 0;

    @media (--viewport-tablet) {
        margin: 0;
        width: 50%;
        margin-inline-end: 16px;
    }

    @media (--viewport-desktop) {
        width: 100%;
    }
}

.products {
    overflow: hidden;
    border-radius: 4px;
    color: var(--av-nav-primary);
    background: var(--av-inversed-primary);
    border: 1px solid var(--av-brand-light);
    box-shadow: 0 4px 8px rgba(36, 49, 67, 0.1);

    @media (--viewport-desktop) {
        display: flex;
    }

    @media (--viewport-desktop-wide) {
        margin-inline-end: 40px;
    }
}

.product-item-active {
    background: var(--av-solid-brand-lightest);
}

.product-item {
    padding: 0 32px;
    user-select: none;

    &:not(:first-child) {
        border-top: 1px solid var(--av-brand-light);
    }

    @media (--viewport-desktop) {
        width: 50%;

        &:not(:first-child) {
            border-top: none;
            border-inline-start: 1px solid var(--av-brand-light);
        }
    }
}

.license-price {
    @mixin title-accent;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--av-nav-primary);

    @media (--viewport-desktop-large) {
        flex-direction: row;
        align-items: center;
    }

    &:deep(del) {
        @mixin lead-accent;
        position: relative;
        margin-bottom: -6px;
        text-decoration: none;
        color: var(--av-fixed-light);

        @media (--viewport-desktop-large) {
            margin-bottom: 0;
            margin-inline-end: 8px;
        }

        &:before {
            inset-inline-start: 0;
            top: 50%;
            height: 1px;
            content: "";
            width: 100%;
            position: absolute;
            background: var(--av-fixed-light);
        }
    }
}

.product-title {
    @mixin title-accent;
    padding: 24px 0;
}

.selector {
    padding: 32px;
    margin: 0 -32px;
    border-top: 1px solid var(--av-brand-light);
    border-bottom: 1px solid var(--av-brand-light);

    @media (--viewport-desktop-wide) {
        .selector {
            min-height: 446px;
        }
    }
}

.quantity {
    height: 48px;
    display: flex;
    user-select: none;
    align-items: center;

    &:deep(.el-num-picker) {
        &__container {
            display: flex;
            max-width: 114px;
            line-height: 46px;
            border-radius: 4px;
            background: var(--av-inversed-primary);
            border: 1px solid var(--av-brand-light);
        }

        &.wide {
            .el-num-picker__container {
                max-width: 132px;
            }
        }

        &__decrease,
        &__increase {
            display: flex;
            cursor: pointer;
            min-width: 40px;
            align-items: center;
            justify-content: center;

            .svg {
                fill: var(--av-brand-primary);
                &--size-16 {
                    height: 18px;
                    width: 18px;
                }
            }

            &.is-disabled {
                cursor: default;
                .svg {
                    fill: var(--av-fixed-lighter);
                }
            }
        }
    }

    &:deep(.el-input) {
        &--small {
            .el-input {
                &__container {
                    border: none;
                    height: auto;
                    display: inline-block;
                }

                &__wrapper {
                    padding: 0;
                }

                &__editor {
                    @mixin lead-strong;
                    text-align: center;
                }
            }
        }
    }
}

.quantity-title {
    @mixin lead-accent;
    margin-bottom: 16px;
    color: var(--av-nav-primary);
}

.multiplier {
    margin: 0 8px;
}

.period {
    margin-top: 24px;

    &:deep(.el-input__label) {
        margin-top: 2px;
    }
}

.benefit {
    @mixin caption-accent;

    color: #7c9b1e;
    margin-top: 8px;
}

.period-title {
    @mixin lead-accent;
    margin-bottom: 16px;
    color: var(--av-nav-primary);
}

.max-quantity {
    margin-top: 24px;
    position: relative;
    padding: 16px 24px;
    padding-inline-start: 48px;
    background: var(--av-fixed-success-accent);

    .a-glyph {
        top: 20px;
        inset-inline-start: 24px;
        position: absolute;
        fill: var(--av-fixed-success);
    }

}

.max-quantity-title {
    @mixin body-accent;
    margin-bottom: -4px;
}

.max-quantity-item {
    @mixin caption;
    display: flex;
    margin-top: 4px;
    color: var(--av-fixed-secondary);
    justify-content: space-between;

    &:deep(del) {
        margin-inline-end: 8px;
    }
}

.information {
    @mixin caption;
    padding: 24px 0;
    color: var(--av-fixed-secondary);
}

.description {
    @mixin lead;
    margin: 40px 0 0;
    color: var(--av-nav-primary);

    @media (--viewport-desktop-wide) {
       width: 90%;
    }

    &:deep(b) {
        font-weight: 600;
    }
}

.order {
    margin-top: 56px;
    color: var(--av-nav-primary);

    @media (--viewport-tablet) {
        margin: 0;
        width: 50%;
    }

    @media (--viewport-desktop) {
        width: 100%;
        max-width: 309px;
    }

    @media (--viewport-desktop-wide) {
        max-width: 349px;
    }

    @media (--viewport-desktop-large) {
        max-width: 403px;
    }

}

.order-title {
    @mixin title-accent;
    margin-bottom: 24px;
}

.order-product {
    padding: 24px 0 16px;
    border-top: 1px solid var(--av-brand-secondary-light);

    &:not(:first-child) {
        padding-top: 16px;
    }

    del {
        @mixin lead;
        color: var(--av-fixed-secondary);
    }
}

.order-product-item {
    display: flex;
    justify-content: space-between;
}

.order-product-title {
    @mixin lead-accent;
    width: 100%;
}

.order-product-price {
    @mixin lead-strong;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.order-product-price-full {
    @mixin lead;
    font-weight: 400;
    position: relative;
    color: var(--av-fixed-secondary);

    &:before {
        inset-inline-start: 0;
        top: 58%;
        height: 1px;
        content: "";
        width: 100%;
        position: absolute;
        background: var(--av-fixed-light);
    }
}

.order-product-price-current {
    @mixin lead-accent;
    color: var(--av-nav-primary);
}

.order-product-remove {
    margin-inline-start: 4px;

    .a-glyph {
        width: 32px;
        height: 32px;
        padding: 8px;
        display: block;
        cursor: pointer;
        margin-top: -4px;
        border-radius: 4px;
        fill: var(--av-brand-primary);

        &:hover {
            background-color: var(--av-brand-secondary-accent);
        }

        &:active {
            background-color: var(--av-brand-secondary-light);
        }
    }
}

.order-product-quantity {
    color: var(--av-fixed-secondary);
}

.order-product-period {
    color: var(--av-fixed-secondary);
    span {
        white-space: nowrap;
    }
}

.total {
    display: flex;
    padding: 16px 0 0;
    align-items: flex-start;
    justify-content: space-between;
    border-top: 1px solid var(--av-brand-secondary-light);
}

.total-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:not(:first-child) {
        align-items: flex-end;
    }
}

.total-text {
    @mixin title-accent;
    color: var(--av-nav-primary);
}

.total-vat {
    @mixin paragraph;
    color: var(--av-fixed-secondary);
}

.total-full {
    @mixin lead;
    position: relative;
    color: var(--av-fixed-secondary);

    &:before {
        inset-inline-start: 0;
        top: 58%;
        height: 1px;
        content: "";
        width: 100%;
        position: absolute;
        background: var(--av-fixed-light);
    }
}

.total-discount {
    @mixin lead;
    color: var(--av-fixed-success);
}

.total-current {
    @mixin display-strong;
}

.a-button {
    width: 100%;
    margin: 24px 0 0;
    justify-content: center;
}

.order-links {
    margin: 40px 0 0;
}

.order-links-item {
    &:not(:first-child) {
        margin: 8px 0 0;
    }

    &:deep(a) {
        white-space: nowrap;
        text-decoration: none;
        color: var(--av-brand-primary);

        &:hover {
            color: var(--av-brand-secondary);
        }
    }

    &:deep(.a-link__content) {
        @mixin paragraph-accent;

        .a-glyph {
            margin-inline-start: 8px;
        }
    }
}

.notifications {
    @mixin paragraph;
    margin: 24px 0 0;
    position: relative;
    padding: 16px 24px;
    padding-inline-start: 48px;
    background: var(--av-solid-brand-secondary-accent);

    .a-glyph {
        top: 20px;
        inset-inline-start: 24px;
        position: absolute;
    }

    &:deep(b) {
        font-weight: 600;
    }

    &:deep(a) {
        text-decoration: none;
        color: var(--av-brand-primary);

        &:hover {
            color: var(--av-brand-secondary);
        }
    }
}
</style>
