import monochrome from '@dist/svg-sprite/monochrome.sprite.json';
import multicolor from '@dist/svg-sprite/multicolor.sprite.json';
import websprite from '@dist/svg-sprite/web.sprite.json';

/**
 * Glyph sizes
 *
 * @return {string[]}
 */

const addPrefix = (sprite, prefix) => sprite.symbols.map((i) => prefix + i);

export const GLYPHS = [...addPrefix(monochrome, 'i-'), ...addPrefix(multicolor, 'clr-'), ...websprite.symbols];

export const SIZES = ['s', 'm', 'l', 'xxxl'];

export const COLORS = [
    'blue',
    'orange',
    'azure',
    'white',
    'brand-light',
    'brand-primary',
    'brand-secondary',
    'fixed-success',
    'nav-secondary',
    'orange-bright',
];
