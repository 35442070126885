<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import structuredData from '@core/mixins/structuredData';
import { PR_MAX_RELEASES_PEG_PAGE } from '@model/const/press-releases';
import { HttpRejection } from '@model/http/rejection';
import { getOgImage, getTextDirection, ACRONIS_OG_IMAGE_ID } from '@utils/html-meta';
import { handleSearchHashString } from '@utils/resource-center';
import PRHomePageComponent from './component.vue';

export default Vue.extend({
    name: 'PRHomePageContainer',

    async serverPrefetch(): Promise<void> {
        const locale = this.$route.params.locale;
        const page = this.page;

        await this.$store.dispatch('locales/getLocaleData', { locale });
        const localeData = this.$store.state.locales?.locale;
        if (!localeData?.is_active_on_public_relations) {
            throw new HttpRejection('Premature rendering stop', StatusCodes.NOT_FOUND);
        }

        await this.$store.dispatch('locales/getAll');

        const releasesRequest = new ProductAPIQueryBuilder('pressReleases')
            .setEntityPath('/api/press-releases/press-releases/')
            .setLocales([locale])
            .setOutputOnly(['id', 'slug', 'title', 'published_at'])
            .setCustomParam('process-macros', '1')
            .addSort('translation.published_at', 'desc')
            .setPaginate(page, PR_MAX_RELEASES_PEG_PAGE)
            .toObject();

        const seoPromises = [
            this.$store.dispatch('seo/getSyncedData', { key: 'routes-config', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-webpage', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-organization', locale }),
        ];

        await Promise.all([
            this.$store.dispatch('pressReleases/getEntity', { request: releasesRequest }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-main-header', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-global-footer', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'press-releases', locale }),
            ...seoPromises,
        ]);

        // Building page meta
        this.$ssrContext.res.meta = await this.getMeta();
    },

    mixins: [structuredData],

    computed: {
        ...mapGetters('config', ['$config']),
        ...mapGetters('seo', ['getRoutesConfig', 'getStaticConfigs']),
        page() {
            return parseInt(this.$route.params.page, 10) || 1;
        },
    },

    methods: {
        async getMeta(): any {
            const locale = this.$route.params.locale;
            const uiStrings = this.$store.state.slices.items['press-releases'] || {};
            const canonical = `https://${this.$config.domain}${this.$route.path}`;

            const title = uiStrings.pressReleases || 'Acronis';
            const seoTitle = uiStrings.pressCenterMetaTitle || '';
            const seoDescription = uiStrings.pressCenterMetaDescription || '';

            const ogImage = getOgImage(`@${ACRONIS_OG_IMAGE_ID}`, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);

            const ldJsonSchema = await this.getStructuredDataMarkup({
                siteID: this.$config.siteID,
                domain: this.$config.domain,
                schemasConfig: this.getStaticConfigs,
                routesConfig: this.getRoutesConfig,
            });

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: seoTitle },
                    { tag: 'meta', name: 'description', content: seoDescription },
                    { tag: 'meta', property: 'og:title', content: seoTitle },
                    { tag: 'meta', property: 'og:description', content: seoDescription },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', property: 'og:image', content: ogImage },
                    { tag: 'meta', name: 'twitter:title', content: seoTitle },
                    { tag: 'meta', name: 'twitter:description', content: seoDescription },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'meta', name: 'twitter:image', content: ogImage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                    { tag: 'link', rel: 'image_src', href: ogImage },
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: locale.split('-')[0],
                },
                ldJsonSchema,
            };
        },
    },

    render(h) {
        const searchHashData = handleSearchHashString(this.$route.hash);
        searchHashData.page = this.page;
        delete searchHashData.sort;
        const props = { searchHashData };
        return h(PRHomePageComponent, { props });
    },
});
</script>
