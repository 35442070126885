<script lang="ts">
import Vue from 'vue';
import { getHtmlLang, getTextDirection } from '@utils/html-meta';
import Error404Component from './component.vue';

export default Vue.extend({
    name: 'Error404Container',

    async serverPrefetch(): Promise<void> {
        const locale = this.$route.params.locale;
        await this.$store.dispatch('slices/getSyncedData', { slice: 's-page-not-found-ti', locale });
        this.$ssrContext.public.isNotFoundTrap = true;
        this.$ssrContext.res.meta = this.getMeta();
    },

    methods: {
        getMeta(): any {
            const locale = this.$route.params.locale;
            const uiStrings = this.$store.state.slices.items['s-page-not-found-ti'] || {};
            const title = uiStrings.meta?.title || 'Page Not Found (404) | Trueimage.com';

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'robots', content: 'noindex, nofollow' },
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: getHtmlLang(locale),
                },
            };
        },
    },

    render(h) {
        return h(Error404Component);
    },
});
</script>
