<template>
    <span v-if="isInputValid">{{ formattedDate }}</span>
    <span v-else>{{ 'invalid date' }}</span>
</template>

<script>
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import { formatToLocaleDate } from '@utils/locales';

export default {
    name: 'BlogDateLocalized',

    props: {
        date: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            datetime: new Date(this.date),
        };
    },

    computed: {
        locale() {
            return this.$route?.params.locale || LOCALE_DEFAULT;
        },
        isInputValid() {
            return !Number.isNaN(this.datetime.valueOf());
        },
        formattedDate() {
            const date = new Date(this.datetime);
            return formatToLocaleDate(date.toISOString(), this.locale);
        },
    },
};
</script>
