<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import structuredData from '@core/mixins/structuredData';
import { HttpRejection } from '@model/http/rejection';
import { getOgImage, getTextDirection, ACRONIS_OG_IMAGE_ID } from '@utils/html-meta';
import PressReleaseComponent from './component.vue';

export default Vue.extend({
    name: 'PressReleaseContainer',

    async serverPrefetch(): Promise<void> {
        const { year, locale, slug } = this.$route.params;

        await this.$store.dispatch('locales/getLocaleData', { locale });
        const localeData = this.$store.state.locales?.locale;
        if (!localeData?.is_active_on_public_relations) {
            throw new HttpRejection('Premature rendering stop', StatusCodes.NOT_FOUND);
        }

        const releaseRequest = new ProductAPIQueryBuilder('pressRelease')
            .setEntityPath('/api/press-releases/press-releases/')
            .addMatchesAll('slug', '=', slug)
            .setCustomParam('pressrelease', this.previewKey ? { key: this.previewKey } : null)
            .setCustomParam('process-macros', '1')
            .setLocales([locale])
            .toObject();

        await this.$store.dispatch('pressReleases/getEntity', { request: releaseRequest });

        // Get the rest of entities
        if (this.previewKey) {
            this.$ssrContext.res.headers.push({ key: 'Cache-Control', val: 'no-store' });
        }

        const release = this.$store.state.pressReleases.pressRelease.items[0];
        const releaseYear = parseInt(release.published_at, 10);
        if (parseInt(year, 10) !== releaseYear) {
            throw new HttpRejection('Premature rendering stop', StatusCodes.NOT_FOUND);
        }

        const authorRequest = new ProductAPIQueryBuilder('pressReleaseAuthor')
            .setEntityPath('/api/press-releases/authors/')
            .addMatchesAll('id', '=', release.author_id)
            .setLocales([locale])
            .toObject();

        const localesRequest = new ProductAPIQueryBuilder('availableLocales')
            .setEntityPath('/api/press-releases/press-releases/translations')
            .addMatchesAll('press_release_id', '=', release.id)
            .setPaginate(1, 50)
            .setOutputOnly(['locale', 'published_at'])
            .toObject();

        const seoPromises = [
            this.$store.dispatch('seo/getSyncedData', { key: 'routes-config', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-website', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-webpage', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-organization', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-contact-point', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-offer', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-postal-address', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-video-object', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-faq-page', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-breadcrumbs', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-job-posting', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-product', locale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-article', locale }),
        ];

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 's-main-header', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-global-footer', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'press-releases', locale }),
            this.$store.dispatch('pressReleases/getEntity', { request: authorRequest, httpRejectEmptyState: false }),
            this.$store.dispatch('pressReleases/getEntity', { request: localesRequest, httpRejectEmptyState: false }),
            ...seoPromises,
        ]);

        // Building page meta
        this.$ssrContext.res.meta = await this.getMeta(release);
    },

    mixins: [structuredData],

    computed: {
        ...mapGetters('config', ['$config']),
        ...mapGetters('seo', ['getRoutesConfig', 'getStaticConfigs']),
        previewKey() {
            return this.$route.query?.key;
        },
    },

    methods: {
        async getMeta(release: any): any {
            const locale = this.$route.params.locale;
            const canonical = `https://${this.$config.domain}${this.$route.path}`;

            const title = release.title || 'Acronis';
            const seoTitle = release.seo_title || '';
            const seoDescription = release.seo_description || '';

            const ogImage = getOgImage(`@${ACRONIS_OG_IMAGE_ID}`, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);

            const ldJsonSchema = await this.getStructuredDataMarkup({
                siteID: this.$config.siteID,
                domain: this.$config.domain,
                schemasConfig: this.getStaticConfigs,
                routesConfig: this.getRoutesConfig,
            });

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: seoTitle },
                    { tag: 'meta', name: 'description', content: seoDescription },
                    { tag: 'meta', property: 'og:title', content: seoTitle },
                    { tag: 'meta', property: 'og:description', content: seoDescription },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', property: 'og:image', content: ogImage },
                    { tag: 'meta', name: 'twitter:title', content: seoTitle },
                    { tag: 'meta', name: 'twitter:description', content: seoDescription },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'meta', name: 'twitter:image', content: ogImage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                    { tag: 'link', rel: 'image_src', href: ogImage },
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: locale.split('-')[0],
                },
                ldJsonSchema,
            };
        },
    },

    render(h) {
        const props = { year: this.$route.params.year };
        return h(PressReleaseComponent, { props });
    },
});
</script>
