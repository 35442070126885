/* eslint-disable max-len */
export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=3260-12745&t=LWcbKICEQRRYVaDf-4';

export const Default = {
    args: {
        translations: {
            title: 'Features Small With Icons',
            lead: 'At its core, Acronis Files Connect is an AFP server that runs on your Windows server and enables Macs to connect to file shares and NAS via Apple Filing Protocol (AFP).',
            link: { text: 'Optional Link' },
            cards: [
                {
                    title: 'AFP Connection to File Shares',
                    content: 'With Acronis Files Connect, Macs connect to and mount file shares on Windows file servers and NAS as native AFP volumes. This allows Mac users to continue using the same tools.',
                    tags: [
                        'DETECTION',
                        'Prevention',
                        'Response',
                        'recovery',
                        'MANAGEMENT',
                    ],
                },
                {
                    title: 'Full Content Spotlight Searching',
                    content: 'Acronis Files Connect links Mac Spotlight to a server-side index that seamlessly integrates with the native Windows Search service or a <a href="#">built-in Acronis content indexer</a>.',
                    label: 'Exclusive',
                },
                {
                    title: 'File Access for Mobile Devices',
                    content: '<a href="#">Acronis Files Connect</a> isn’t limited to Macs. Seamless file access is also provided for mobile devices (iOS, Android, and Windows Phone) with intuitive browsing, searching, previewing.',
                },
                {
                    title: 'Time Machine Backup to File Shares',
                    content: 'With Acronis Files Connect, Mac users’ documents can be automatically backed up to a Windows file server or NAS, and users can selectively restore previous versions directly.',
                },
                {
                    title: 'AFP Connection to File Shares',
                    content: 'With Acronis Files Connect, Macs connect to and mount file shares on Windows file servers and NAS as native AFP volumes. This allows Mac users to continue using the same tools.',
                },
                {
                    title: 'Full Content Spotlight Searching',
                    content: 'Acronis Files Connect links Mac Spotlight to a server-side index that seamlessly integrates with the native Windows Search service or a <a href="#">built-in Acronis content indexer</a>.',
                    label: 'Exclusive',
                },
                {
                    title: 'File Access for Mobile Devices',
                    content: '<a href="#">Acronis Files Connect</a> isn’t limited to Macs. Seamless file access is also provided for mobile devices (iOS, Android, and Windows Phone) with intuitive browsing, searching, previewing.',
                },
                {
                    title: 'Time Machine Backup to File Shares',
                    content: 'With Acronis Files Connect, Mac users’ documents can be automatically backed up to a Windows file server or NAS, and users can selectively restore previous versions directly.',
                },
            ],
        },
        link: { to: '#' },
        cards: [
            { icon: '17', iconVariant: true },
            { icon: '71', iconVariant: true },
            { icon: '74', iconVariant: true },
            { icon: '72', iconVariant: true },
            { icon: '1', iconVariant: true },
            { icon: '71' },
            { icon: '74' },
            { icon: '111' },
        ],
    },
};
