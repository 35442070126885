<script lang="ts">
import { StatusCodes } from 'http-status-codes';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder, Storage } from '@api/builders/product';
import { RC_MAX_ITEMS_PEG_PAGE } from '@model/const/resource-center';
import { HttpRejection } from '@model/http/rejection';
import { assertHttpErrors } from '@utils/api-response';
import { getTextDirection, getHtmlLang } from '@utils/html-meta';
import { handleSearchQuery, generateSearchQuery, assertLocaleIsAllowed } from '@utils/resource-center';
import ResourceCenterSearchComponent from './component.vue';

export default Vue.extend({
    name: 'ResourceCenterSearchContainer',

    async serverPrefetch(): Promise<void> {
        const locale = this.$route.params.locale;
        const page = parseInt(this.$route.params.page, 10) || 1;

        assertLocaleIsAllowed(this.$store, locale);

        const searchData = {
            ...handleSearchQuery(this.$route.query),
            page,
        };

        const resourceRequest: Storage = generateSearchQuery(searchData, locale);

        await this.$store.dispatch('resourceCenter/getEntity', { request: resourceRequest });

        const resourcesState = this.$store.state.resourceCenter.resources;

        assertHttpErrors([{ entity: resourcesState }]);

        const tagsRequest = new ProductAPIQueryBuilder('tags')
            .setEntityPath('/api/resources/tags/')
            .setLocales([locale])
            .setPaginate(1, RC_MAX_ITEMS_PEG_PAGE)
            .toObject();

        const typesRequest = new ProductAPIQueryBuilder('types')
            .setEntityPath('/api/resources/types/')
            .setLocales([locale])
            .setPaginate(1, RC_MAX_ITEMS_PEG_PAGE)
            .toObject();

        const audiencesRequest = new ProductAPIQueryBuilder('audiences')
            .setEntityPath('/api/resources/audiences/')
            .setLocales([locale])
            .setPaginate(1, RC_MAX_ITEMS_PEG_PAGE)
            .toObject();

        const industriesRequest = new ProductAPIQueryBuilder('industries')
            .setEntityPath('/api/core/industries/')
            .setLocales([locale])
            .setPaginate(1, RC_MAX_ITEMS_PEG_PAGE)
            .toObject();

        const localesRequest = new ProductAPIQueryBuilder('locales')
            .setEntityPath('/api/gis/locales/')
            .addMatchesAll('is_active_on_resource_center', '=', '1')
            .toObject();

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 's-main-header', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 's-global-footer', locale }),
            this.$store.dispatch('slices/getSyncedData', { slice: 'resource-center', locale }),
            this.$store.dispatch('products/getProducts', locale),
            this.$store.dispatch('resourceCenter/getEntity', { request: tagsRequest }),
            this.$store.dispatch('resourceCenter/getEntity', { request: typesRequest }),
            this.$store.dispatch('resourceCenter/getEntity', { request: audiencesRequest }),
            this.$store.dispatch('resourceCenter/getEntity', { request: industriesRequest }),
            this.$store.dispatch('resourceCenter/getEntity', { request: localesRequest }),
        ]);

        assertHttpErrors([
            { entity: this.$store.state.resourceCenter.locales, throwIfEmpty: true },
            { entity: this.$store.state.resourceCenter.tags },
            { entity: this.$store.state.resourceCenter.types },
            { entity: this.$store.state.resourceCenter.audiences },
        ]);

        // Building page meta
        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),

        uiStrings() {
            return this.$store.state.slices.items['resource-center'];
        },
    },

    methods: {
        getMeta(): any {
            const locale = this.$route.params.locale;
            const uiStrings = this.uiStrings;

            const title = uiStrings.searchMetaTitle || '';
            const description = uiStrings.searchMetaDescription || '';

            const page = parseInt(this.$route.params.page, 10) || 1;
            const totalPages = this.$store.state.resourceCenter.resources.pagination?.pages_total || 0;
            const urlBase = `https://${this.$config.domain}/${locale}/resource-center/search/`;
            const canonical = page > 1 ? `${urlBase}page/${page}/` : urlBase;
            const paginationLinks = [];

            if (page > 1 && page > totalPages) {
                throw new HttpRejection(`Premature rendering stop: '${page}' not found`, StatusCodes.NOT_FOUND);
            }
            if (page > 1) {
                const href = page === 2 ? urlBase : `${urlBase}page/${page - 1}/`;
                paginationLinks.push({ tag: 'link', rel: 'prev', href });
            }
            if (totalPages > page) {
                const href = `${urlBase}page/${page + 1}/`;
                paginationLinks.push({ tag: 'link', rel: 'next', href });
            }
            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'link', rel: 'canonical', href: canonical },
                    ...paginationLinks,
                ],
                htmlAttrs: {
                    dir: getTextDirection(locale),
                    lang: getHtmlLang(locale),
                },
            };
        },
    },

    render(h) {
        const props = { page: parseInt(this.$route.params.page, 10) || 1 };
        return h(ResourceCenterSearchComponent, { props });
    },
});
</script>
