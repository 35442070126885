function open(element, dropdown, event) {
    event.preventDefault();
    event.stopPropagation();

    if (element.offsetHeight) {
        return false;
    }

    element.style.height = `${element.scrollHeight + 32}px`;

    if (dropdown) {
        dropdown.classList.add('is-dropdown-animate');

        setTimeout(() => {
            dropdown.classList.remove('is-dropdown-animate');
            dropdown.classList.add('is-dropdown');
        }, 300);
    } else {
        element.classList.add('is-dropdown');
    }
    return true;
}

function close(element, dropdown, event) {
    if (!element.offsetHeight) {
        return false;
    }

    if (event.type !== 'click' || !element.contains(event.target)) {
        element.style.height = 0;

        if (dropdown) {
            dropdown.classList.remove('is-dropdown-animate', 'is-dropdown');
        } else {
            element.classList.remove('is-dropdown');
        }
    }
    return false;
}

export default {
    inserted(el, { value }) {
        const element = document.getElementById(value);
        el.dropdown = element.closest('.a-dropdown');

        el.onDropdownClick = open.bind(null, element, el.dropdown);
        el.addEventListener('click', el.onDropdownClick, { capture: true });

        el.close = close.bind(null, element, el.dropdown);
        document.addEventListener('click', el.close);
        window.addEventListener('scroll', el.close, { passive: true });
        window.addEventListener('resize', el.close, { capture: true, passive: true });
    },

    unbind(el) {
        el.removeEventListener('click', el.onDropdownClick, { capture: true });

        document.removeEventListener('click', el.close);
        window.removeEventListener('scroll', el.close, { passive: true });
        window.removeEventListener('resize', el.close, { capture: true, passive: true });
    },
};
