<template>
    <div class="menu-dropdown" :class="{ sticky: isSticky }">
        <ul class="menu">
            <li v-for="link of menuLinks" :key="link.link" class="menu-item">
                <a-dynamic-link
                    class="link"
                    :class="{ active: isActiveLink(link.link) }"
                    :to="link.link"
                    :event="{ action: link.link }"
                >
                    {{ link.text }}
                </a-dynamic-link>
            </li>

            <li v-if="contactUs" class="menu-separator">
                &nbsp;
            </li>

            <a-dynamic-link
                class="login-link"
                :to="login.link"
                :target="login.target"
                :event="{ action: login.link }"
            >
                <span>{{ login.text }}</span>
                <a-glyph class="glyph" name="group" fill="brand-primary" />
            </a-dynamic-link>

            <div v-for="cta of stickyCTA" :key="cta.to" class="cta-wrap">
                <a-button class="cta" v-bind="cta" :event="{ action: cta.to }" />
            </div>
            <div class="cta-wrap">
                <a-button
                    v-if="contactUs"
                    class="cta"
                    v-bind="contactUs"
                    :to="contactUs.link"
                    :type="contactUs.type || 'main'"
                    :size="contactUs.size || 's'"
                    :event="{ action: contactUs.link }"
                />
            </div>
        </ul>
    </div>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'DeveloperMenuDropdown',

    components: {
        ADynamicLink,
        AButton,
        AGlyph,
    },

    props: {
        menuLinks: {
            type: Array,
            required: true,
        },

        login: {
            type: Object,
            required: true,
        },

        contactUs: {
            type: Object,
            required: false,
            default: null,
        },

        isSticky: {
            type: Boolean,
            default: false,
        },

        stickyCTA: {
            type: Array,
            required: false,
            default: () => ([]),
        },
    },

    emits: ['menu-close'],

    mounted() {
        document.body.addEventListener('click', this.onOutsideClick);
    },

    beforeDestroy() {
        document.body.removeEventListener('click', this.onOutsideClick);
    },

    methods: {
        isActiveLink(link) {
            const path = this.$route?.params?.pathMatch;

            if (!path && link === '/') return true;

            return path && link.includes(path);
        },

        onOutsideClick(e) {
            if (!this.$el.contains(e.target)) {
                this.$emit('menu-close');
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.menu-dropdown {
    position: absolute;
    top: 80px;
    inset-inline-end: 0;
    z-index: 121; /* it should be above anchor-menu width z-index 120 */
    width: 100%;
    padding: 32px 16px 24px;
    background: var(--av-inversed-primary);
    box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);

    &.sticky {
        position: fixed;
    }

    @media (--viewport-mobile-wide) {
        max-width: 360px;
        inset-inline-end: 16px;
        border-radius: 4px;
    }

    @media (--viewport-desktop) {
        display: none;
    }
}

.menu-item {
    padding: 12px 8px;
}

 .menu-separator {
    height: 1px;
    background: var(--av-brand-secondary-light);
    margin: 24px 0 16px;
}

.link {
    @mixin lead-accent;
    color: var(--av-brand-primary);

    &.active {
        color: var(--av-nav-primary);
    }
}

.login-link {
    @mixin paragraph-accent;
    display: flex;
    align-items: center;
    margin-inline-start: 8px;
    margin-bottom: 56px;

    .a-glyph {
        margin-inline-start: 8px;
    }
}

.cta-wrap {
    margin-bottom: 16px;
}

.cta {
    width: 100%;
}

</style>
