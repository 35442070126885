export const Figma = 'https://www.figma.com/design/eM4W6JS2MjG1gp5YhKoMp7Dd/Slices?node-id=41593-60313&t=YdHBHy6Bx0MHVIeQ-4';

export const Default = {
    args: {
        translations: {
            title: 'Want to learn more? Join our weekly live demo!',
            lead: `To learn more about our solutions you can join the weekly 
                    Acronis Cyber Cloud demo webinar led by our solution engineers.`,
            paragraph: `To learn more about our solutions you can join the weekly 
                    Acronis Cyber Cloud demo webinar led by our solution engineers.`,
            buttons: [
                { text: 'Try now' },
                { text: 'Buy now' },
            ],
            productPageCTA: {
                promo: {
                    description: 'From <del>@price</del> <b>@priceFull</b>',
                },
            },
        },
        buttons: [
            {
                to: '#',
                type: 'main',
            },
            {
                type: 'action',
                event: {
                    ecommerce: {
                        type: 'click',
                    },
                },
                optionsSURL: {
                    shopId: 882,
                    targetPathname: 'purl-consumer-standard-US',
                    params: {
                        task: 'WEB-42890',
                        cart: 's9033:247538',
                        'x-createmyaccount': 'NO',
                    },
                },
            },
        ],
        productPageCTA: {
            productId: 64,
            cleverBridgeCartID: 236611,
            promo: {
                glyph: 'discount-fill',
                cleverBridgeCartList: [236611],
                coupon: 'basic',
            },
        },
    },
};
