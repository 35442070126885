<template>
    <resource-page>
        <resource-home-header
            class="home-header"
            :social-links="socialLinks"
            :ga-social-event="gaSocialEvent"
            :common-translations="commonTranslations"
        />
        <resource-home-page-navigation :links="homePageNavigationLinks" :common-translations="commonTranslations" />
        <resource-news-list
            v-if="newsList.length"
            :common-translations="commonTranslations"
            :list="newsList"
            :banner="banner"
        />
        <resource-home-search-panel
            :common-translations="commonTranslations"
            :audiences="audiences"
        />
        <resource-home-specific-type-list
            v-if="featuredResources.length"
            :list="featuredResources"
            :title="commonTranslations.featured"
            :common-translations="commonTranslations"
            :browse-title="commonTranslations.browseAllResources"
        />
        <blog-container theme="dark-navy">
            <blog-insights
                v-if="insightsNewsList.length"
                :title="commonTranslations.homePageInsightTitle"
                :lead="commonTranslations.homePageInsightLead"
                :link="{ title: commonTranslations.homePageInsightsLink, to: '/cyber-protection-center/' }"
                :news-video-label="commonTranslations.videoLabel"
                :news="insightsNewsList"
            />
        </blog-container>
        <resource-home-specific-type-list
            v-if="caseStudyResources.length"
            :list="caseStudyResources"
            :title="commonTranslations.caseStudies"
            :common-translations="commonTranslations"
            :browse-title="commonTranslations.browseAllCaseStudies"
            :link-url="caseStudyResources[0].type.to"
        />
        <resource-home-analyst-reports
            v-if="analystReportResources.length"
            :list="analystReportResources"
            :common-translations="commonTranslations"
        />
        <event-container class="events-block">
            <div id="resource-center-webinars-list" class="events-block-container">
                <div class="events-block-texts-wrapper">
                    <h2 class="events-block-title">
                        {{ commonTranslations.webinarsAndVirtualEvents }}
                    </h2>
                    <a-link
                        :text="commonTranslations.browseAllEvents"
                        glyph="arrow"
                        to="https://acronis.events/"
                    />
                </div>
                <event-tab-control
                    class="events-block-types"
                    :tabs="eventsTypes"
                    :active-tab-id="webinarsCategoryId"
                    @change="webinarsAndVirtualEventsTypeChange"
                />
                <event-card-list
                    :cards="webinarsAndVirtualEvents"
                    :learn-more-title="commonTranslations.learnMoreTitle"
                    :limit="WEBINARS_AND_VIRTUAL_TO_SHOW"
                    :not-found-title="commonTranslations.noEventsFoundTitle"
                    :is-loading="webinarsAndVirtualEventsLoading"
                />
            </div>
        </event-container>
        <!--    TODO: commented while the press center will not be ready
        <resource-home-engage-socials
            :social-links="socialLinks"
            :ga-social-event="gaSocialEvent"
            :common-translations="commonTranslations"
        />
        -->
    </resource-page>
</template>

<script lang="ts">
import ALink from '@core/components/link/link.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import BlogInsights from '@core/slices/blog/blog-insights/blog-insights.vue';
import BlogContainer from '@core/slices/blog/shared-components/blog-container/blog-container.vue';
import EventCardList from '@core/slices/events/event-card-list/event-card-list.vue';
import EventContainer from '@core/slices/events/shared-components/event-container/event-container.vue';
import EventTabControl from '@core/slices/events/shared-components/event-tab-control/event-tab-control.vue';
/* import ResourceHomeEngageSocials
    from '@core/slices/resource-center/resource-home-engage-socials/resource-home-engage-socials.vue'; */
import ResourceHomeAnalystReports
    from '@core/slices/resource-center/resource-home-analyst-reports/resource-home-analyst-reports.vue';
import ResourceHomeHeader from '@core/slices/resource-center/resource-home-header/resource-home-header.vue';
import ResourceHomePageNavigation
    from '@core/slices/resource-center/resource-home-page-navigation/resource-home-page-navigation.vue';
import ResourceHomeSearchPanel
    from '@core/slices/resource-center/resource-home-search-panel/resource-home-search-panel.vue';
import ResourceHomeSpecificTypeList
    from '@core/slices/resource-center/resource-home-specific-type-list/resource-home-specific-type-list.vue';
import ResourceNewsList from '@core/slices/resource-center/resource-news-list/resource-news-list.vue';
import ResourcePage from '@core/slices/resource-center/shared-components/resource-page.vue';
import {
    GA_SOCIAL_EVENT,
    RC_ROOT_URL,
} from '@model/const/resource-center';
import socials from '@model/const/socials';
import eventUtils from '@utils/events.js';
import { formatToLocaleDate } from '@utils/locales';
import { extendResourceData } from '@utils/resource-center';
import '@core/styles/sections/resource-center.pcss';

export default {
    name: 'ResourceCenterHome',

    components: {
        ALink,
        EventCardList,
        EventTabControl,
        EventContainer,
        BlogContainer,
        BlogInsights,
        ResourceHomePageNavigation,
        ResourceHomeAnalystReports,
        // ResourceHomeEngageSocials,
        ResourceHomeHeader,
        ResourceHomeSearchPanel,
        ResourceHomeSpecificTypeList,
        ResourceNewsList,
        ResourcePage,
    },

    mixins: [breakpoint],

    props: {
        dispatchLoadWebinars: {
            type: Function,
            required: true,
        },

        locale: {
            type: String,
            default: 'en-us',
        },

        countryId: {
            type: Number,
            default: null,
        },
    },

    data: () => ({
        webinarsCategoryId: 'all',
        showAllUpcomingEvents: false,
        WEBINARS_AND_VIRTUAL_TO_SHOW: 3,
    }),

    computed: {
        commonTranslations() {
            return this.$store.state.slices.items['resource-center'] || {};
        },

        gaSocialEvent() {
            return GA_SOCIAL_EVENT;
        },

        socialLinks() {
            return socials.links;
        },

        banner() {
            return this.$store.state.resourceCenter.banners.items[0] || {};
        },

        audiences() {
            return this.$store.state.resourceCenter.audiences.items.map((audience) => ({
                ...audience,
                to: `${RC_ROOT_URL}/audience/${audience.slug}/`,
            }));
        },

        featuredResources() {
            const fn = (item) => extendResourceData(item, this.locale);
            return this.$store.state.resourceCenter.featuredResources.items?.map(fn);
        },

        caseStudyResources() {
            const fn = (item) => extendResourceData(item, this.locale);
            return this.$store.state.resourceCenter.caseStudyResources.items?.map(fn);
        },

        analystReportResources() {
            const fn = (item) => extendResourceData(item, this.locale);
            return this.$store.state.resourceCenter.analystReportsResources.items?.map(fn);
        },

        homePageNavigationLinks() {
            return this.$store.state.slices.items['resource-home-page-navigation']?.items || [];
        },

        insightsNewsList() {
            const items = this.$store.state.blog.insightsNews?.items || [];
            return items.map((item) => ({ ...item, link: item.pathname }));
        },

        webinarsAndVirtualEvents() {
            const virtualEvents = this.$store.state.events.virtualEvents.items || [];
            return virtualEvents
                .map((ve) => eventUtils.getEventCardFields(ve, this.commonTranslations))
                // fixing links to acronis.events
                .map((ve) => ({
                    ...ve,
                    link: `https://acronis.events${ve.link}`,
                }));
        },

        webinarsAndVirtualEventsLoading() {
            return this.$store.state.events.virtualEvents.pagination?.loading;
        },

        eventsTypes() {
            const categories = this.$store.state.events.categories.items || [];
            return [
                { id: 'all', title: this.commonTranslations.allEventsTitle },
                ...categories,
            ];
        },

        newsList() {
            const cpcNews = this.$store.state.blog.insightsNews?.items?.slice(0, 2) || [];
            const blogNews = this.$store.state.blog.latestBlogPosts.items;

            // if there is no cpc news, then return only blog news
            return [...blogNews.slice(0, blogNews.length - cpcNews.length), ...cpcNews]
                .map((news) => ({
                    ...news,
                    published_at: formatToLocaleDate(news.published_at, this.locale),
                }));
        },
    },

    methods: {
        webinarsAndVirtualEventsTypeChange({ id }) {
            this.webinarsCategoryId = id;
            this.dispatchLoadWebinars({ countryId: this.countryId, webinarsCategoryId: this.webinarsCategoryId });
        },
    },
};
</script>

<style lang="postcss" scoped>
.home-header {
    margin-bottom: 32px;
}

.blog-insights {
    &:deep(.title-wrap) {
        .a-link {
            @media (--viewport-tablet) {
                transform: translateY(-16px);
            }
        }

        .a-link__content {
            @mixin lead-accent;
        }
    }

    &:deep(.news-items) {
        border: 0 !important;
        padding-top: 0 !important;

        @media (--viewport-tablet) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        .news-item {
            &:nth-child(4) {
                display: inherit;
            }

            .title {
                color: var(--av-link-inversed);
            }
        }

        button {
            text-align: center;
        }
    }
}

.events-block {
    width: 100%;
    border: 0;

    .events-block-container {
        max-width: 1440px;
        margin: 0 auto;
        padding: 64px 16px;

        @media (--viewport-tablet) {
            padding: 64px 32px;
        }

        @media (--viewport-desktop-wide) {
            padding: 64px;
        }
    }

    &:deep(.container) {
        padding-inline-start: 0 !important;
        padding-inline-end: 0 !important;
    }

    &-texts-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 24px;

        @media (--viewport-tablet) {
            flex-flow: row wrap;
            align-items: baseline;
            justify-content: space-between;
        }

        &:deep(.a-link__content) {
            @mixin lead-accent;
        }
    }

    &-title {
        @mixin hero;
        color: var(--av-nav-primary);

        @media (--viewport-tablet) {
            @mixin display-accent;
            text-align: start;
        }

        @media (--viewport-desktop) {
            @mixin hero;
        }
    }

    &-types {
        margin-top: 40px !important;

        &:deep(.item-button .label) {
            font-size: 18px !important;
            line-height: 32px !important;
            font-weight: 500 !important;
            color: var(--av-nav-primary) !important;
        }

        &:deep(.item-button.active .label) {
            font-weight: 700 !important;
            color: var(--av-nav-secondary) !important;
        }

        &:deep(.underline) {
            background-color: var(--av-nav-secondary) !important;
        }
    }
}
</style>

<style lang="postcss">
#resource-center-webinars-list {
    .event-card-list {
        .title {
            @mixin paragraph;
            font-weight: 500;
        }

        .items {
            @media (--viewport-tablet) {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }

        .event-card {
            max-width: 544px;
        }

        .event-card  .image {
            display: block;

            @media (--viewport-tablet) {
                margin-bottom: 24px;
            }

            .a-picture__img {
                height: 100%;
            }
        }

        .date,
        .details {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
        }
    }
}
</style>
