<template>
    <div class="s-info-blocks">
        <template v-for="(block, i) in blocks">
            <div v-if="block.name === 'logo'" :key="`block_${i}`" class="logo">
                <p v-if="block.partner?.logo" class="logo-partner">
                    <a-picture v-if="!block.partner?.link" :link="block.partner.logo" />
                    <a v-else class="a-link" :href="block.partner.link">
                        <a-picture :link="block.partner.logo" />
                    </a>
                </p>
                <p v-else-if="block.link">
                    <a class="a-link" :href="replaceLocale(block.link)">
                        <a-logo type="full" />
                        <span v-if="block.title" class="logo-title">{{ block.title }}</span>
                    </a>
                </p>
                <p v-else>
                    <a-logo type="full" />
                    <span v-if="block.title" class="logo-title">{{ block.title }}</span>
                </p>
            </div>

            <div v-else-if="block.name === 'title'" :key="`block_${i}`" :class="classBlock(block)">
                <a-label v-if="block.label?.text" v-bind="{...block.label, text: ''}">
                    {{ block.label.text }}
                </a-label>
                <template v-if="block.text">
                    <div v-if="block.textLink" class="title-item">
                        <a-link :text="block.text" :to="block.textLink" />
                    </div>
                    <div v-else class="title-item" v-html="block.text" />
                </template>
            </div>

            <div v-else-if="block.name === 'subtitle' && block.text" :key="`block_${i}`" :class="classBlock(block)">
                <p v-html="block.text" />
            </div>

            <div v-else-if="block.name === 'picture'" :key="`block_${i}`" :class="classBlock(block)">
                <a-media
                    v-if="block.enlargedPicture"
                    :link="block.picture"
                    :enlarged-image="block.enlargedPicture"
                    type="screenshot"
                />
                <a-picture v-else :link="block.picture" />
            </div>

            <div v-else-if="block.name === 'link'" :key="`block_${i}`" :class="classBlock(block)">
                <a-link v-if="block.to" v-bind="block" />
                <template v-else-if="block.ytId">
                    <a-link v-modal="block.ytId" v-bind="block" />
                    <a-modal :id="block.ytId" type="youtube" is-youtube>
                        <a-youtube :yt-id="block.ytId" :params="block.ytParams || { autoplay: 1 }" />
                    </a-modal>
                </template>
            </div>

            <div v-else-if="block.name === 'list'" :key="`block_${i}`" :class="classBlock(block)">
                <div v-for="(item, itemList) in block.items" :key="`list-item-${itemList}`">
                    <div class="list-item-title">
                        <a-glyph name="checkmark-success" />
                        <span>{{ item.title }}</span>
                    </div>
                    <a-dangerous-html :content="item.text" class="list-item-text" />
                </div>
            </div>

            <div v-else-if="block.name === 'info'" :key="`block_${i}`" :class="classBlock(block)">
                <p v-if="block.title" class="info-title" v-html="block.title" />
                <p v-if="block.description" class="info-description" v-html="block.description" />
                <div v-if="block.text" class="info-text">
                    <p v-for="(item, itemText) in block.text" :key="`info-text-${itemText}`" v-html="item" />
                    <template v-if="block.phoneFromGeo">
                        <p v-html="block.phoneFromGeo?.[country] || block.phoneFromGeo?.default" />
                    </template>
                </div>
                <ul v-if="block.list?.items" class="info-list">
                    <template v-for="(item, itemListInfo) in block.list.items">
                        <li :key="`info-list-item-${itemListInfo}`" class="info-list-item" v-html="item" />
                    </template>
                </ul>
                <p v-if="block.links?.title" class="info-list-title" v-html="block.links.title" />
                <div v-if="block.links?.items">
                    <template v-for="(item, itemListLink) in block.links.items">
                        <p :key="`info-list-link-${itemListLink}`" class="info-list-link" v-html="item" />
                    </template>
                </div>
            </div>

            <div v-else-if="block.name === 'video'" :key="`block_${i}`" :class="classBlock(block)">
                <a-video-card v-bind="block" />
            </div>

            <div v-else-if="block.name === 'prices'" :key="`block_${i}`" :class="classBlock(block)">
                <div v-modal="block.popupData.id" @close="sendPricesEvent('Close')" @click="sendPricesEvent('Open')">
                    <a-glyph name="upsell" class="prices-icon-upsell" />
                    <div class="prices-breakdown">
                        <div class="prices-title">
                            {{ block.title }}
                        </div>
                        <div class="prices-button">
                            <span>{{ block.linkText }}</span>
                            <a-glyph name="arrow-left" />
                        </div>
                        <info-prices-breakdown-modal :data="block.popupData" />
                    </div>
                </div>
            </div>

            <div v-else-if="block.name === 'awards'" :key="`block_${i}`">
                <s-info-content-awards
                    :content="block"
                    :list="block.list"
                    :has-title="block.hasTitle"
                />
            </div>

            <div v-else-if="block.name === 'reviews'" :key="`block_${i}`" :class="classBlock(block)">
                <div v-if="block.items" class="reviews-wrapper">
                    <div v-for="(item, itemReview) in block.items" :key="`review-item-${itemReview}`" class="review-item">
                        <a-glyph :name="`logo-${item.type}`" class="review-item-icon" />
                        <div class="review-item-title">
                            {{ item.title }}
                        </div>
                        <div class="review-item-rating">
                            <a-glyph name="rating-star" />
                            <a-dangerous-html :content="item.text" />
                        </div>
                    </div>
                </div>
                <div v-if="block.comment" class="reviews-comment">
                    <a-picture v-if="block.comment" class="reviews-comment-bg" :link="block.comment.background" />
                    <a-dangerous-html :content="block.comment.text" class="reviews-comment-text" />
                    <div v-if="block.comment.author" class="reviews-comment-author">
                        <a-picture :link="block.comment.author.picture" />
                        <div class="reviews-comment-author-info">
                            <b>{{ block.comment.author.name }}</b>
                            <span>{{ block.comment.author.position }}</span>
                        </div>
                    </div>
                    <a-media v-if="block.comment.media" v-bind="block.comment.media" class="reviews-comment-picture" />
                    <a-picture
                        v-if="!block.comment.media && block.comment.picture"
                        :link="block.comment.picture"
                        class="reviews-comment-picture"
                    />
                </div>
            </div>
            <div v-if="block.media" :key="`block_${i}`" :class="classBlock({...block, name: 'media'})">
                <a-media v-bind="block.media" />
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';
import AMedia from '@core/components/media/media.vue';
import AModal from '@core/components/modal/modal.vue';
import APicture from '@core/components/picture/picture.vue';
import AVideoCard from '@core/components/video-card/video-card.vue';
import AYoutube from '@core/components/youtube/youtube.vue';
import Modal from '@core/directives/modal.js';
import breakpoint from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';
import SInfoContentAwards from './info-content-awards.vue';
import InfoPricesBreakdownModal from './info-prices-breakdown-modal.vue';

export default {
    name: 'SInfoBlocks',

    components: {
        ADangerousHtml,
        AGlyph,
        ALink,
        ALogo,
        APicture,
        AMedia,
        ALabel,
        AVideoCard,
        AModal,
        AYoutube,
        SInfoContentAwards,
        InfoPricesBreakdownModal,
    },

    directives: {
        Modal,
    },

    mixins: [breakpoint, form],

    props: {
        blocks: {
            type: Array,
            default: null,
        },
    },

    computed: {
        ...mapState({
            location: (state) => state.geolocation?.location || null,
        }),
        country() {
            return this.location?.data?.country?.code;
        },
    },

    methods: {
        classBlock(item) {
            return {
                [`${item.name}`]: item.name,
                'picture-border': item.hasBorder,
                'without-indent': item.withoutIndent,
                [`link-glyph-size-${item.glyphSize}`]: item.glyphSize,

            };
        },

        sendPricesEvent(eventType) {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                event: 'Acronis',
                eventCategory: 'Interactions',
                eventAction: 'Click',
                eventLabel: `See the price breakdown - ${eventType}`,
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-info-blocks {
    color: var(--av-fixed-secondary);

    &:deep(a) {
        color: var(--av-brand-primary);

        &:hover {
            color: var(--av-brand-secondary);
        }
    }

    .logo {
        text-align: start;

        &:deep(.a-logo) {
            width: 158px;
            height: 32px;
        }

        .logo-title {
            @mixin display;
            font-weight: 400;
            margin-bottom: 8px;
            color: var(--av-nav-primary);
            display: block;
        }

        .logo-partner {
            &:deep(.a-picture__img) {
                margin: 0;
            }
        }
    }

    .title {
        @mixin title;
        display: flex;
        flex-wrap: wrap;
        margin-top: 32px;
        color: var(--av-nav-primary);

        @media (--viewport-desktop) {
            @mixin hero;
        }

        &:deep(.a-link__content) {
            @mixin title;
            color: var(--av-nav-primary);

            @media (--viewport-desktop) {
                @mixin hero;
            }
        }
    }

    .title-item {
        width: 100%;
    }

    .subtitle {
        @mixin paragraph-accent;
        color: var(--av-brand-primary);

        @media (--viewport-tablet) {
            @mixin lead-accent;
        }
    }

    .video {
        margin-top: 32px;

        &:deep(.a-picture__img) {
            margin: 0;
            max-width: 100%;
        }
    }

    .picture {
        margin-top: 32px;

        &:deep(.a-picture__img) {
            margin: 0;
            max-width: 100%;
        }
    }

    .picture-border {
        &:deep(.a-picture__img) {
            border-radius: 4px;
            box-shadow: var(--av-shadow-regular);
            border: 1px solid var(--av-brand-light);
        }
    }

    .link {
        margin-top: 32px;

        &:deep(.a-link__content) {
            @mixin paragraph-accent;

            @media (--viewport-tablet) {
                @mixin lead-accent;
            }

            .a-glyph_size_m {
                top: 0;
            }
        }
    }

    .link-glyph-size-m {
        .a-link_glyph_left {
            &:deep(.a-link__content) {
                padding-inline-start:  20px;

                @media (--viewport-tablet) {
                    padding-inline-start:  28px;
                }
            }
        }
    }

    .list {
        gap: 16px;
        display: flex;
        margin-top: 32px;
        flex-direction: column;
        color: var(--av-nav-primary);

        .list-item-title {
            @mixin paragraph-accent;
            display: flex;
            align-items: flex-start;
            gap: 8px;

            @media (--viewport-tablet) {
                @mixin lead-accent;
            }

            .a-glyph {
                margin: 4px 0;
                min-width: 16px;
            }
        }

        .list-item-text {
            @mixin paragraph;
            margin-top: 8px;
            color: var(--av-fixed-secondary);
        }
    }

    .info {
        margin-top: 32px;

        .info-title {
            @mixin lead-accent;
            color: var(--av-nav-primary);

            @media (--viewport-tablet) {
                @mixin title-accent;
            }
        }

        .info-description {
            @mixin lead;
            margin-top: 24px;

            @media (--viewport-tablet) {
                @mixin title;
            }
        }

        .info-text {
            @mixin paragraph;

            & > p {
                margin-top: 16px;
            }
        }

        .info-list {
            margin-top: 16px;

            .info-list-item {
                position: relative;
                padding-inline-start: 16px;

                &::before {
                    top: 10px;
                    content: '';
                    width: 4px;
                    height: 4px;
                    display: block;
                    border-radius: 2px;
                    position: absolute;
                    inset-inline-start: 0;
                    background: var(--av-fixed-secondary);
                }
            }
        }

        .info-list-title {
            @mixin paragraph-accent;
            margin-top: 16px;
        }

        .info-list-link {
            margin-top: 8px;
        }
    }

    .prices {
        gap: 8px;
        display: flex;
        margin-top: 32px;
        padding: 8px 40px;
        position: relative;
        border-radius: 16px;
        align-items: flex-start;
        background: var(--av-brand-secondary-accent);

        &:hover {
            cursor: pointer;
        }

        .prices-icon-upsell {
            position: absolute;
            inset-inline-start: 16px;
            top: 16px;
            flex-shrink: 0;
        }

        .prices-title {
            @mixin lead-accent;
            color: var(--av-fixed-primary);
        }

        .prices-button {
            cursor: pointer;
            display: flex;
            gap: 8px;
            align-items: center;
            @mixin lead-accent;
            color: var(--av-brand-primary);

            .a-glyph {
                flex-shrink: 0;
                transform: scale(-1);
            }
        }
    }

    .awards {
        &:deep(.list-item) {
            color: var(--av-nav-primary);
        }
    }

    .reviews {
        margin-top: 32px;

        .reviews-wrapper {
            gap: 32px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 32px;

            @media (--viewport-tablet) {
                flex-direction: row;
            }
        }

        .review-item {
            display: flex;
            flex-direction: column;
            align-items: center;

            .review-item-icon {
                width: 48px;
                height: 48px;
            }

            .review-item-title {
                @mixin paragraph-accent;
                color: var(--av-fixed-primary);
            }

            .review-item-rating {
                @mixin paragraph;
                display: flex;
                align-items: center;
                gap: 4px;
                color: var(--av-fixed-secondary);
            }
        }

        .reviews-comment {
            position: relative;
            padding: 32px 16px;
            border-radius: 16px;
            overflow: hidden;
            z-index: 1;

            @media (--viewport-desktop) {
                padding: 32px;
            }

            .reviews-comment-bg {
                position: absolute;
                top: 0;
                inset-inline-start: 0;
                z-index: -1;
                width: 100%;
                height: 100%;

                &:deep(img) {
                    width: 100%;
                    height: 100%;
                }
            }

            .reviews-comment-text {
                @mixin lead;
                color: var(--av-inversed-primary);

                @media (--viewport-tablet) {
                    @mixin title;
                }
            }

            .reviews-comment-author {
                margin-top: 24px;
                display: flex;
                gap: 16px;
                align-items: center;

                .a-picture {
                    display: none;
                    flex-shrink: 0;
                    width: 80px;
                    height: 80px;

                    @media (--viewport-tablet) {
                        display: block;
                    }

                    &:deep(img) {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .reviews-comment-author-info {
                @mixin paragraph;
                display: flex;
                flex-direction: column;
                padding-inline-end: 24px;
                color: var(--av-inversed-primary);
            }

            .reviews-comment-picture {
                margin-top: 32px;

                @media (--viewport-desktop) {
                    margin-top: 64px;
                }
            }
        }
    }

    .media {
        margin-top: 32px;
    }

    .without-indent {
        margin-top: 0;
    }
}

.s-slice_background_dark-blue {
    .s-info-blocks {
        color: var(--av-inversed-primary);

        &:deep(.a-link) {
            .a-glyph {
                fill: var(--av-link-inversed);
            }

            .a-link__content {
                color: var(--av-link-inversed);
            }
        }

        &:deep(.a-logo) {
            fill: var(--av-inversed-primary);
        }

        .list {
            color: var(--av-inversed-primary);

            .list-item-text {
                color: var(--av-inversed-primary);
            }
        }

        .title {
            color: var(--av-inversed-primary);

            &:deep(.a-link__content) {
                color: var(--av-inversed-primary);
            }
        }

        .info {
            .info-title {
                color: var(--av-inversed-primary);
            }
            .info-list {
                .info-list-item {
                    &::before {
                        background: var(--av-inversed-primary);
                    }
                }
            }
        }

        .prices {
            .prices-title {
                color: var(--av-inversed-primary);
            }
            .prices-button {
                color: var(--av-link-inversed);

                &:deep(.a-glyph) {
                    fill: var(--av-link-inversed);
                }
            }
            .prices-breakdown-modal {
                color: var(--av-fixed-secondary);
            }
        }

        .awards {
            &:deep(.list-item){
                color:var(--av-inversed-primary);
            }
        }

        .reviews {
            .review-item {
                .review-item-title,
                .review-item-rating {
                    color: var(--av-inversed-primary);
                }
            }
        }

        &:deep(a) {
            color: var(--av-link-inversed);
        }
    }
}

[dir='rtl'] {
    .s-info-blocks {
        .form-error__icon {
            margin-right: 0;
            margin-inline-end: 8px;
        }
        &:deep(.el-checkbox__content-label) {
            padding-inline-start: 8px;
        }
    }
}
</style>
