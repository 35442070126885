<template>
    <div class="event-message">
        <div class="cols">
            <div class="text">
                <h2 v-if="title" class="title">
                    {{ title }}
                </h2>
                <div v-if="lead" class="lead">
                    {{ lead }}
                </div>
            </div>
            <div v-if="link" class="link-wrap">
                <a class="event-link link" :href="link.to">{{ link.text }} →</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventMessage',

    props: {
        title: {
            type: String,
            default: null,
        },

        lead: {
            type: String,
            default: null,
        },

        link: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.event-message {
    @mixin basic-slice-paddings;

    .cols {
        @media (--viewport-desktop) {
            display: grid;
            column-gap: 16px;
            grid-template-columns: 2fr 1fr;
        }
    }

    .text,
    .link-wrap {
        @media (--viewport-desktop) {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .lead {
        @mixin title;
        margin-bottom: 40px;

        @media (--viewport-desktop) {
            margin-bottom: 0;
        }
    }

    .link {
        @mixin lead-strong;
        cursor: pointer;

        @media (--viewport-desktop) {
            text-align: end;
        }
    }
}
</style>
