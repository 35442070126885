import { get, isEmpty } from 'lodash';
import { mapState } from 'vuex';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';

export default {
    computed: {
        ...mapState({
            licensesItems: (state) => state.licenses.data,
            pageCategory: (state) => state.pages?.page?.types,
            productItems: (state) => state.products.items,
            productID: (state) => state.pages?.page?.product_id,
            pageAudiences: (state) => state.pages?.page?.audiences,
        }),

        locale() {
            return get(this, '$route.params.locale', LOCALE_DEFAULT);
        },

        brandName() {
            return this.productItems?.[this.productID]?.parameters?.common?.productName || '(not set)';
        },
    },

    methods: {
        licenseCouponCode({ cleverBridgeCartID, promo }) {
            if (!promo?.isPromoActive) {
                return null;
            }
            const coupon = promo.coupon || null;
            return this.hasLicensePromo({ cleverBridgeCartID, promo }) ? coupon : null;
        },

        hasLicensePromo({ cleverBridgeCartID, promo }) {
            return (promo?.cleverBridgeCartList || []).includes(Number(cleverBridgeCartID));
        },

        licenseData({ cleverBridgeCartID, promo }) {
            const resultDefault = { price: 0, discount_percentage: 0 };
            if (!cleverBridgeCartID) return resultDefault;

            const idToCompare = cleverBridgeCartID.toString();

            if (this.promo?.discount && this.promo?.coupon) {
                const result = this.licensesItems.find((i) => i.cleverbridge_id === idToCompare);
                if (!result) return resultDefault;
                const hasDiscount = this.promo.cleverBridgeCartList.includes(cleverBridgeCartID);
                const price = result.discount_absolute ? result.price + result.discount_absolute : result.price;
                const discount = hasDiscount ? price * (this.promo.discount / 100) : 0;
                return {
                    ...result,
                    coupon: hasDiscount ? this.promo.coupon : '',
                    discount_percentage: hasDiscount ? this.promo.discount : '',
                    discount_absolute: hasDiscount ? ((discount * 100) / 100) : 0,
                    price: hasDiscount ? ((price - discount) * 100) / 100 : price,
                };
            }

            const couponToCompare = this.licenseCouponCode({ cleverBridgeCartID, promo });
            const result = this.licensesItems.find((i) => i.cleverbridge_id === idToCompare && i.coupon === couponToCompare);
            return result || resultDefault;
        },

        ecommerceSendEvent({ event, license }) {
            if (!license?.cleverbridge_id) {
                return;
            }

            const result = {
                event: 'AcronisEcommerce',
                eventLabel: event.eventLabel || license.name || '',
                eventAction: event.eventAction || 'Add to cart',
                eventContext: event.eventContext || 'click',
                eventCategory: event.eventCategory || 'Conversions',
                eventContent: this.productItems?.[this.productID]?.name || '(not set)',
                eventLocation: `${this.pageCategory}`,
                eventProductName: license.name || '',
                eventProductPrice: license.price || '',
                eventProductId: license.cleverbridge_id || '',
                eventPosition: 1,
                ecommerce: {},
            };

            const quantity = event.quantity || 1;
            const type = event?.ecommerce?.type || '';
            const products = [this.ecommerceLicense(license, quantity)];

            result.ecommerce[type] = {
                products,
                currencyCode: this.productCurrency,
                actionField: { list: this.pageCategory },
            };

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(result);
        },

        ecommerceProducts(licenses) {
            let count = 0;
            let position = 0;
            let quantity = 1;
            const result = [];

            this.licensesItems.forEach((license) => {
                if (!isEmpty(licenses)) {
                    if (!Object.keys(licenses).includes(license.cleverbridge_id)) {
                        return;
                    }
                    quantity = licenses[license.cleverbridge_id];
                }

                if (!result[count]) {
                    result[count] = [];
                }

                position += 1;

                result[count].push(this.ecommerceLicense(license, quantity, position));

                if (isEmpty(licenses) && !(position % 10)) {
                    count++;
                }
            });

            return result;
        },

        ecommerceLicense(license, quantity, position) {
            const name = license?.name || '(not set)';
            const type = name.match(/.*(\d\syears?).*/i) || '';
            const result = /cloud/i.test(name) ? 'Subscription' : 'Perpetual';
            const variant = type ? `Subscription ${type[1]}` : result;

            return {
                list: license.item_list_name || '',
                position: position || 1,
                quantity: quantity || 1,
                brand: this.brandName,
                price: license.price,
                category: this.pageAudiences,
                id: license.cleverbridge_id || null,
                name,
                variant,
                dimension129: license.discount_absolute || null,
            };
        },
    },
};
