import { render, staticRenderFns } from "./resource-search-sort-dropdown.vue?vue&type=template&id=24ed2e27&scoped=true"
import script from "./resource-search-sort-dropdown.vue?vue&type=script&lang=ts"
export * from "./resource-search-sort-dropdown.vue?vue&type=script&lang=ts"
import style0 from "./resource-search-sort-dropdown.vue?vue&type=style&index=0&id=24ed2e27&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ed2e27",
  null
  
)

export default component.exports