<template>
    <div class="integration-details">
        <div class="a-container top-row">
            <a-link
                class="back-link"
                v-bind="backLink"
                :glyph="backLink.glyph || 'arrow-left'"
                :glyph-position="backLink.glyphPosition || 'left'"
            />
            <a-link class="support-link" v-bind="supportLink" />
        </div>
        <div class="main">
            <s-integration-header v-bind="header" />
            <s-integration-summary v-if="summary.title || summary.lead || summary.links.length" v-bind="summary" />
            <s-integration-features v-bind="features" />
            <s-integration-body v-bind="body" />
            <s-integration-company v-bind="company" />
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import SIntegrationBody from './components/integration-body.vue';
import SIntegrationCompany from './components/integration-company.vue';
import SIntegrationFeatures from './components/integration-features.vue';
import SIntegrationHeader from './components/integration-header.vue';
import SIntegrationSummary from './components/integration-summary.vue';

export default {
    name: 'SolutionsIntegrationDetails',

    components: {
        SIntegrationHeader,
        SIntegrationSummary,
        SIntegrationBody,
        SIntegrationCompany,
        SIntegrationFeatures,
        ALink,
    },

    props: {
        header: {
            type: Object,
            required: true,
        },

        summary: {
            type: Object,
            required: true,
        },

        body: {
            type: Object,
            default: null,
        },

        features: {
            type: Object,
            required: true,
        },

        company: {
            type: Object,
            required: true,
        },

        backLink: {
            type: Object,
            required: true,
        },

        supportLink: {
            type: Object,
            default: null,
        },
    },
};
</script>

<style lang="postcss" scoped>
.integration-details {
    color: var(--av-fixed-primary);
}

.top-row {
    @mixin body-accent;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;
    padding-top: 24px;

    @media (--viewport-mobile-wide) {
        flex-direction: row;
    }
}

.main {
    background: var(--av-brand-lightest);
    padding-bottom: 48px;
}
</style>
