<template>
    <div class="blog-tag-list">
        <div class="all-tags-title">
            {{ title }}
        </div>
        <div class="tags">
            <div v-for="(tag, i) in sortedTags" :key="i" class="tag">
                <dynamic-link class="tag-title" :to="tag.link" native-type="a">
                    {{ tag.title }}
                </dynamic-link>
            </div>
        </div>
    </div>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';

export default {
    name: 'BlogTagList',
    components: {
        DynamicLink,
    },
    props: {
        title: {
            type: String,
            required: true,
        },

        tags: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        sortedTags() {
            return [...this.tags]
                .filter((x) => x.posts_count > 0)
                .sort((a, b) => String(a.title).localeCompare(b.title));
        },
    },
};
</script>

<style lang="postcss">
.blog-tag-list {
    .all-tags-title {
        @mixin display;

        color: var(--av-nav-primary);
        font-weight: 500;
        margin-bottom: 40px;
        text-align: center;

        @media (--viewport-mobile-wide) {
            @mixin hero;

            text-align: start;
        }
    }

    .tags {
        display: grid;
        column-gap: 16px;
        row-gap: 16px;
        grid-template-columns: 1fr;

        @media (--viewport-mobile-wide) {
            grid-template-rows: repeat(15, 1fr);
            grid-template-columns: 1fr 1fr;
            grid-auto-flow: column;
        }

        @media (--viewport-desktop) {
            grid-template-rows: repeat(10, 1fr);
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .tag {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .tag-title {
        padding: 0 8px;
        border-radius: 4px;
        background: var(--av-brand-accent);
        letter-spacing: 1px;
        text-transform: uppercase;
        color: var(--av-brand-primary);
        font-size: 11px;
        line-height: 16px;
        font-weight: bold;
        text-decoration: none;
        display: flex;
        align-items: center;
        margin-inline-end: 32px;
    }

    .tag-count {
        @mixin caption;

        color: var(--av-fixed-light);
    }
}
</style>
