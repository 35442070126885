<template>
    <div class="blog-list" :class="className">
        <div class="items">
            <div v-if="hasSidebar" class="sidebar">
                <slot name="sidebar" />
            </div>
            <blog-card
                v-for="card in cards"
                :key="card.id"
                class="item"
                v-bind="{ ...card, layout: cardLayout, theme }"
                :show-section-label="showSectionLabel"
            />
            <div
                v-if="isLoading"
                v-loading="true"
                class="loading"
                el-loading-size="48"
            />
        </div>
    </div>
</template>

<script>
import Loading from '@uikit/ui-kit/packages/loading/src/directive';
import BlogCard from '../blog-card/blog-card.vue';

export default {
    name: 'BlogList',
    components: {
        BlogCard,
    },
    directives: {
        Loading,
    },
    props: {
        cards: {
            type: Array,
            required: true,
        },

        isLoading: {
            type: Boolean,
            default: false,
        },

        showOnlyOneRow: {
            type: Boolean,
            default: false,
        },

        showSectionLabel: {
            type: Boolean,
            default: false,
        },

        cardLayout: {
            type: String,
            default: 'default',
        },

        theme: {
            type: String,
            default: 'light',
        },
    },
    computed: {
        hasSidebar() {
            return Boolean(this.$slots.sidebar);
        },
        className() {
            return {
                'row-layout': this.cardLayout === 'row',
                'one-row': this.showOnlyOneRow,
            };
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-list {
    &.row-layout {
        .items {
            grid-template-columns: 1fr;
            row-gap: 32px;
        }
    }

    &.one-row {
        .item {
            @media (--viewport-tablet) {
                &:nth-child(4) {
                    display: none;
                }
            }

            @media (--viewport-desktop-wide) {
                &:nth-child(4) {
                    display: block;
                }
            }
        }
    }

    .items {
        position: relative;

        @media (--viewport-tablet) {
            display: grid;
            column-gap: 16px;
            row-gap: 48px;
            grid-template-columns: 1fr 1fr 1fr;
            grid-auto-rows: 1fr;
        }

        @media (--viewport-desktop-wide) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    .loading {
        position: absolute;
        inset-inline-start: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
    }

    .item {
        margin-bottom: 40px;

        @media (--viewport-tablet) {
            margin-bottom: 0;
        }
    }

    .sidebar {
        display: none;

        @media (--viewport-desktop) {
            display: block;
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 4;
        }

        @media (--viewport-desktop-wide) {
            grid-column-start: 4;
            grid-column-end: 5;
            grid-row-start: 1;
            grid-row-end: 4;
        }
    }
}
</style>
