export const ALIGNMENTS = ['left', 'center', 'left-right'];
export const THEMES = ['light', 'dark'];
export const BACKGROUNDS = [
    'white',
    'light-blue',
    'dark-blue',
    'l-b-gradient-top',
    'l-b-gradient-bottom',
];
export const BORDERS = ['none', 'short', 'content-wide', 'full-width'];

export default { ALIGNMENTS, THEMES, BACKGROUNDS, BORDERS };
