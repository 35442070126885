import { render, staticRenderFns } from "./total.vue?vue&type=template&id=22e18878&scoped=true"
import script from "./total.vue?vue&type=script&lang=js"
export * from "./total.vue?vue&type=script&lang=js"
import style0 from "./total.vue?vue&type=style&index=0&id=22e18878&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22e18878",
  null
  
)

export default component.exports