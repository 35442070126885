<template>
    <section :id="id" class="s-slice s-side-menu" :class="{ ...className, ...basicClassName }">
        <div class="a-container">
            <a-slice-header
                v-if="title || lead"
                :title="title"
                :lead="lead"
                :body-text="bodyText"
            />
            <div
                class="s-side-menu__leftside"
                :class="{
                    's-side-menu__leftside--sticky': sticky,
                    's-side-menu__leftside--sticky-active': stickyActive
                }"
                :style="{ top: stickyTopOffset + 'px' }"
            >
                <s-side-menu-control
                    :type="type"
                    :slices="slices"
                    :active-element="activeSlice || activeElement"
                    @tab-change="tabChange"
                />
                <div v-if="links" class="s-side-menu__links">
                    <a-link
                        v-for="(link, i) in links"
                        :key="i"
                        class="s-side-menu__link"
                        v-bind="link"
                        :to="link.link"
                        :class="link.class || ''"
                        :glyph-position="link.glyphPosition || 'left'"
                        :size="link.size || 'paragraph'"
                        @click="tabChange(link.id, link.text)"
                    />
                </div>
            </div>
            <div class="s-side-menu__rightside">
                <s-side-menu-content
                    ref="content"
                    :type="type"
                    :slices="slices"
                    :active-element="activeElement"
                />
            </div>
        </div>
    </section>
</template>

<script>
import { throttle } from 'lodash';
import ALink from '@core/components/link/link.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SSideMenuContent from './side-menu-content.vue';
import SSideMenuControl from './side-menu-control.vue';

export default {
    name: 'SSideMenu',

    components: {
        SSideMenuControl,
        SSideMenuContent,
        ALink,
        ASliceHeader,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        /**
         * Content slice data
         */
        slices: {
            type: Array,
            required: true,
        },

        /**
         * Type of side menu
         */
        type: {
            type: String,
            default: 'tabs',
        },

        /**
         * Is wide layout
         */
        isWide: {
            type: Boolean,
            default: false,
        },

        /**
         * Extra links after menu
         */
        links: {
            type: Array,
            default: undefined,
        },

        /**
         * Title's lead text
         */
        lead: {
            type: String,
            required: false,
            default: undefined,
        },

        /**
         * Title's body text
         */
        bodyText: {
            type: String,
            default: undefined,
        },

        sticky: Boolean,

        /**
         * Used to pull scroll up in case there are other sticky elements on the page
         */
        scrollTopOffset: {
            type: Number,
            default: 0,
        },

        /**
         * Used to push sticky dropdown and menu down in case there are other sticky elements on the page
         */
        stickyTopOffset: {
            type: Number,
            default: 58,
        },

        isMobileCompact: Boolean,

        activeSlice: {
            type: String,
            default: '',
        },
    },

    emits: ['click'],

    data() {
        return {
            isScrolled: false,
            stickyActive: false,
            activeElement: '',
        };
    },

    computed: {
        className() {
            return {
                's-side-menu_layout_wide': this.isWide,
                's-side-menu_layout_narrow': !this.isWide,
                's-side-menu_layout_mobile-compact': this.isMobileCompact,
            };
        },
    },

    mounted() {
        if (this.type === 'links' || this.sticky) {
            this.activeElement = this.activeSlice || this.$route?.hash.replace('#', '');
            window.addEventListener('load', this.registerObserver);
        }

        if (this.type === 'tabs') {
            window.addEventListener('load', this.checkTabAnchors);
        }

        if (this.type === 'combined') {
            this.activeElement = this.slices[0]?.info?.id;
        }
    },

    beforeDestroy() {
        if (this.callback) {
            window.removeEventListener('scroll', this.callback);
        }
        window.removeEventListener('load', this.checkTabAnchors);
        window.removeEventListener('load', this.registerObserver);
    },

    methods: {
        checkTabAnchors() {
            const hash = (this.$route?.hash || this.activeElement).replace('#', '');

            this.activeElement = this.activeSlice || '';
            this.tabChange(hash);

            // validate given hash and set default as fallback
            const tabIds = this.slices.map((slice) => slice.info.id);
            this.activeElement = tabIds.includes(this.activeElement) ? this.activeElement : tabIds[0];
        },

        tabChange(id, name) {
            if (!id || (this.activeElement === id && !this.activeSlice)) {
                return;
            }

            this.activeElement = id;
            this.$emit('click', id, name);

            // Sticky side-menu becomes a fixed dropdown on top on mobile and covers content
            // that's why we subtract its height from the scroll's pixel amount
            const dropdownHeight = this.sticky ? 82 : 0;
            const element =
                this.type === 'links'
                    ? this.$refs.content.$refs[id][0].$el
                    : this.$refs.content.$el;
            const elCoords = this.getOffset(element);

            this.$nextTick(() => {
                if (this.type === 'tabs') this.$router?.replace({ hash: `#${id}` });
                if (this.isScrolled) window.scrollTo({ top: elCoords.top - dropdownHeight - this.scrollTopOffset });
            });
        },
        getOffset(el) {
            const rect = el.getBoundingClientRect();

            return {
                left: rect.left + window.scrollX,
                top: rect.top + window.scrollY,
            };
        },
        // When we scroll, active element tab should switch if we are in it's viewport
        registerObserver() {
            this.callback = throttle(this.handleScroll, 200);

            const observer = new IntersectionObserver((entries) => {
                const intersected = entries[0].isIntersecting;

                if (intersected) {
                    window.addEventListener('scroll', this.callback);
                } else {
                    window.removeEventListener('scroll', this.callback);
                }
            });

            observer.observe(this.$refs.content.$el);
        },
        handleScroll() {
            if (this.sticky) {
                const stickyElement = document.querySelector('.s-side-menu__leftside--sticky');
                const offsetParent = stickyElement.offsetParent.offsetTop;
                const stickyTop = stickyElement.offsetTop + offsetParent - this.stickyTopOffset;
                this.stickyActive = window.pageYOffset >= stickyTop;
                this.isScrolled = window.pageYOffset >= stickyTop;
            }

            const SCROLL_OFFSET = 250;

            const activeElement = Object.entries(this.$refs.content.$refs)
                .find((pair) => pair[1][0].$el.getBoundingClientRect().bottom > SCROLL_OFFSET);

            if (activeElement) {
                this.activeElement = activeElement[0];
            }
        },
    },
};
</script>

<style lang="postcss">
.s-side-menu {
    &_layout {
        &_wide {
            .s-side-menu {
                &__leftside {
                    width: 100%;

                    @media (--viewport-mobile-wide) {
                        .s-side-menu-control {
                            width: 50%;
                        }
                    }

                    @media (--viewport-desktop) {
                        .s-side-menu-control {
                            width: 100%;
                        }
                        width: 33.33%;
                    }

                    @media (--viewport-desktop-wide) {
                        width: 25%;
                    }
                }

                &__rightside {
                    @media (--viewport-desktop) {
                        width: 66.66%;
                    }

                    @media (--viewport-desktop-wide) {
                        width: 75%;
                    }
                }
            }
        }

        &_narrow {
            .s-side-menu {
                &__leftside {
                    @media (--viewport-desktop) {
                        width: 25%;
                    }

                    @media (--viewport-desktop-wide) {
                        width: 16.66%;
                    }
                }

                &__rightside {
                    @media (--viewport-desktop) {
                        width: 75%;
                    }

                    @media (--viewport-desktop-wide) {
                        width: 83.34%;
                    }
                }
            }
        }

        &_mobile-compact {
            .a-container {
                .a-slice-header {
                    margin-bottom: 40px;
                }
            }
            .s-side-menu {
                &__leftside {
                    margin-bottom: -16px;
                }
            }
        }
    }

    .a-slice-header {
        width: 100%;
        margin-bottom: 56px;

        @media (--viewport-desktop) {
            &__lead,
            &__title {
                max-width: 635px;
            }
        }

        @media (--viewport-desktop-wide) {
            &__lead,
            &__title {
                max-width: 763px;
            }
        }

        @media (--viewport-desktop-large) {
            &__lead,
            &__title {
                max-width: 869px;
            }
        }
    }

    & > .a-container {
        @media (--viewport-desktop) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__leftside {
        width: 100%;
        padding-inline-end: 16px;
        padding: 0 0 16px;
        inset-inline-start: 0;

        &--sticky {
            position: sticky;
            @media only screen and (max-width: 1023px) {
                background: var(--av-inversed-primary);
                position: sticky;
                width: calc(100% + 32px) !important;
                margin: 0 -32px;
                padding: 16px 32px;
                inset-inline-start: 0;

                @media (--viewport-mobile-wide) {
                    width: calc(100% + 64px) !important;
                    margin: 0 -64px;
                }

                @media (--viewport-desktop) {
                    margin: 0;
                }

                .s-side-menu-control {
                    width: 100%;

                    @media (--viewport-tablet) {
                        width: 50%;
                    }
                }
            }
        }

        &--sticky-active {
            z-index: 10;
            background-color: white;
            box-shadow: 0 7px 7px var(--av-fixed-invisible);
        }

        @media (--viewport-desktop) {
            padding-inline-end: 32px;
            box-shadow: none;

            &--sticky {
                position: static;

                .s-side-menu-control {
                    width: unset;
                    position: sticky;
                    top: 80px; /* 80px due to fixed submenu covering it otherwise */
                }
            }
        }
    }

    &__links {
        padding-top: 24px;

        @media (--viewport-desktop) {
            border-top: 1px solid var(--av-brand-secondary-light);
            margin-top: 36px;
        }
    }

    &__link {
        display: block;
        padding-inline-start: 16px;
        margin-bottom: 16px;

        .a-link__glyph {
            width: 16px;
            float: left;
            margin-inline-end: 8px;
            margin-inline-start: 0;
            position: relative;
            top: 6px;
        }
    }

    .s-video-list {
        .a-slice-header {
            &__title {
                font-size: 24px;

                @media (--viewport-tablet) {
                    font-size: 32px;
                }
            }

            &__lead {
                font-size: 16px;
            }
        }

        &__item {
            @media (--viewport-tablet) {
                padding: 0 8px;
                width: 50%;
            }

            @media (--viewport-desktop) {
                width: 50%;
            }

            @media (--viewport-desktop-wide) {
                width: 33.33%;
            }
        }
    }
}

.el-select-dropdown.el-popper {
    background: var(--av-inversed-primary);
}
</style>
