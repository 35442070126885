<template>
    <s-basic-slice :id="id" class="s-three-steps" :class="{ ...basicClassName }">
        <div class="s-three-steps__wrapper">
            <div class="s-three-steps__leftside">
                <a-slice-header :title="title" />
                <ul class="s-three-steps__list">
                    <li v-for="(item, key) in items" :key="`item-${key}`" class="s-three-steps__item s-three-steps-item">
                        <div class="s-three-steps-item__title">
                            {{ item.title }}
                        </div>
                        <div class="s-three-steps-item__desc">
                            {{ item.desc }}
                        </div>
                        <div class="s-three-steps-item__icon-wrapper">
                            <a-glyph class="s-three-steps-item__icon" name="check-icon" />
                        </div>
                    </li>
                </ul>
            </div>
            <div class="s-three-steps__rightside">
                <div class="s-three-steps-info">
                    <div class="s-three-steps-info__title">
                        {{ info.title }}
                    </div>
                    <div class="s-three-steps-info__img-container">
                        <a-picture class="s-three-steps-info__img" :link="info.imgPath" :alt="info.imgAlt" />
                    </div>
                    <a-link class="s-three-steps-info__link" :to="info.linkTo" :text="info.linkText" />
                </div>
            </div>
        </div>
    </s-basic-slice>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';
import ASliceHeader from '@core/components/slice-header/slice-header.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';

export default {
    name: 'SThreeSteps',

    components: {
        AGlyph,
        ALink,
        APicture,
        ASliceHeader,
        SBasicSlice,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        items: {
            type: Array,
            required: true,
        },

        info: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.s-three-steps {
    padding: 0;

    &:deep(.a-slice-header) {
        &__title {
            @mixin title;

            margin-bottom: 56px;
            text-align: center;

            @media (--viewport-mobile-wide) {
                @mixin hero;

                margin-bottom: 48px;
                text-align: start;
            }

            @media (--viewport-desktop) {
                margin-bottom: 40px;
            }

            @media (--viewport-desktop-wide) {
                width: 88.88%;
            }
        }
    }

    &__wrapper {
        padding: 32px 24px 40px;
        border: 1px solid var(--av-brand-secondary-accent);
        border-radius: 4px;
        background-color: var(--av-brand-lightest);

        @media (--viewport-mobile-wide) {
            margin: 0 -24px;
        }

        @media (--viewport-desktop) {
            margin: 0 -32px;
            padding: 40px 32px 48px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }

        @media (--viewport-desktop-wide) {
            margin: 0 -24px;
            padding: 40px 24px 48px;
        }
    }

    &__leftside {
        @media (--viewport-desktop) {
            width: 75%;
        }
    }

    &__rightside {
        @media (--viewport-desktop) {
            width: calc(25% - 16px);
        }

        @media (--viewport-desktop-wide) {
            display: flex;
            justify-content: center;
        }
    }

    &__list {
        margin: 0 -24px;

        @media (--viewport-mobile-wide) {
            margin: 0;
            display: flex;
        }

        @media (--viewport-desktop-wide) {
            margin: 0 56px 0 24px;
        }
    }

    &__item {
        position: relative;
        padding: 27px 57px 50px;

        @media (--viewport-mobile-wide) {
            margin-inline-end: 16px;
            width: 33.33%;
            padding: 27px 0 0;
        }

        @media (--viewport-desktop-wide) {
            margin-inline-end: 78px;
        }

        &:last-child {
            padding-bottom: 0;

            @media (--viewport-mobile-wide) {
                margin-inline-end: 0;

                &::before {
                    display: none;
                }
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 2px;
            background-color: var(--av-brand-secondary-accent);

            @media (--viewport-mobile-wide) {
                inset-inline-start: 8px;
                width: calc(100% + 16px);
            }

            @media (--viewport-desktop-wide) {
                width: calc(100% + 78px);
            }
        }
    }

    .s-three-steps-item {
        &__title {
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
        }

        &__desc {
            font-size: 14px;
            line-height: 20px;
            color: var(--av-fixed-primary);
        }

        &__icon-wrapper {
            position: absolute;
            top: -12px;
            inset-inline-start: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: var(--av-brand-secondary);

            @media (--viewport-mobile-wide) {
                inset-inline-start: 0;
            }
        }

        &__icon {
            fill: var(--av-inversed-primary);
            width: 12px;
            height: 10px;
        }
    }

    .s-three-steps-info {
        display: none;

        @media (--viewport-desktop) {
            @mixin lead;

            margin-top: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 24px 18px 18px;
            border: 1px solid var(--av-brand-secondary-accent);
            border-radius: 6px;
            background-color: var(--av-inversed-secondary);
        }

        @media (--viewport-desktop-wide) {
            width: 255px;
        }

        &__title {
            margin-bottom: 32px;
            font-weight: 700;
        }

        &__img-container {
            margin-bottom: 32px;
        }

        &__img {
            max-width: 100%;
        }

        &__link {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
</style>
