import {
    getSiteUrl,
    getUrlWithLocale,
    ROUTE_NAME_BLOG_POST, ROUTE_NAME_PR_ARTICLE,
    ROUTE_NAME_RC_ARTICLE,
    SITE_ID_EVENTS,
} from '@core/mixins/structuredData/helper';
import { RC_ROOT_BREADCRUMB, RC_ROOT_URL } from '@model/const/resource-center';
import SchemaHandler from '@model/seo/schemaHandler';
import { BreadcrumbListSchema, SchemaConfig } from '@model/seo/schemas';

const HEADER_CONFIG_SLICE_NAME = 's-header-message';
const CUSTOMIZABLE_HEADER_CONFIG_SLICE_NAME = 's-customizable-header';
const BREADCRUMBS_EVENT = [
    {
        '@type': 'ListItem',
        position: 1,
        name: 'Acronis',
        item: 'https://www.acronis.com/',
    },
    {
        '@type': 'ListItem',
        position: 2,
        name: 'Acronis Events',
        item: 'https://www.acronis.events/',
    },
];
const BREADCRUMBS_COMMON = [
    {
        '@type': 'ListItem',
        position: 1,
        name: 'Acronis',
        item: 'https://www.acronis.com/',
    },
];

export default class BreadcrumbListHandler implements SchemaHandler {
    ctx: any;

    schemasConfig: SchemaConfig[];

    constructor(ctx: any, schemasConfig: SchemaConfig[]) {
        this.ctx = ctx;
        this.schemasConfig = schemasConfig;
    }

    getSchemaData(staticConfig: BreadcrumbListSchema): Promise<BreadcrumbListSchema> {
        return Promise.resolve(this.getRequiredFields(staticConfig));
    }

    getRequiredFields(staticConfig: BreadcrumbListSchema): BreadcrumbListSchema {
        const siteID = this.ctx.$store.getters['config/$config'].siteID;
        const routeName = this.ctx.$route.name;

        if (siteID === SITE_ID_EVENTS) {
            return this.getEventBreadcrumbs(staticConfig);
        }

        if (routeName === ROUTE_NAME_BLOG_POST) {
            return this.getBlogPostBreadcrumbs(staticConfig);
        }

        if (routeName === ROUTE_NAME_RC_ARTICLE) {
            return this.getRcArticleBreadcrumbs(staticConfig);
        }

        if (routeName === ROUTE_NAME_PR_ARTICLE) {
            return this.getPrArticleBreadcrumbs(staticConfig);
        }

        return this.getMainSiteBreadcrumbs(staticConfig);
    }

    getEventBreadcrumbs(data) {
        const res = { ...data };
        const event = this.ctx.$store.state.events.event.items[0];

        res.itemListElement = [
            ...BREADCRUMBS_EVENT,
            {
                '@type': 'ListItem',
                position: BREADCRUMBS_EVENT.length + 1,
                name: event.seo_title || event.name,
                item: `https://acronis.events${this.ctx.$route.path}`,
            },
        ];

        return res;
    }

    getBlogPostBreadcrumbs(data) {
        const res = { ...data };
        const post = this.ctx.$store.state.blog.post.items?.[0];
        const translations = this.ctx.$store.state.slices.items.blog || {};

        res.itemListElement = [
            ...BREADCRUMBS_COMMON,
            {
                '@type': 'ListItem',
                position: 2,
                name: translations?.acronisBlog || 'Acronis Blog',
                item: `${getUrlWithLocale(this.ctx)}/pr/`,
            },
            {
                '@type': 'ListItem',
                position: 3,
                name: post.seo_title,
                item: `${getSiteUrl(this.ctx)}${this.ctx.$route.path}`,
            },
        ];

        return res;
    }

    getRcArticleBreadcrumbs(data: any) {
        const res = { ...data };
        const resource = this.ctx.$store.state.resourceCenter.resource.items[0];
        const assetType = {
            text: `Resource center - ${resource.type.title}`,
            to: `${getSiteUrl(this.ctx)}${RC_ROOT_URL}/category/${resource.type.slug}/`,
        };

        res.itemListElement = [
            ...BREADCRUMBS_COMMON,
            {
                '@type': 'ListItem',
                position: 2,
                name: RC_ROOT_BREADCRUMB.text,
                item: `${getUrlWithLocale(this.ctx)}${RC_ROOT_BREADCRUMB.to}`,
            },
            {
                '@type': 'ListItem',
                position: 3,
                name: assetType.text,
                item: assetType.to,
            },
            {
                '@type': 'ListItem',
                position: 4,
                name: resource.title,
                item: `${getSiteUrl(this.ctx)}${this.ctx.$route.path}`,
            },
        ];

        return res;
    }

    getPrArticleBreadcrumbs(data: any) {
        const res = { ...data };
        const release = this.ctx.$store.state.pressReleases.pressRelease.items[0];
        const releaseYear = parseInt(release.published_at, 10);
        const prHomeLink = `${getUrlWithLocale(this.ctx)}/pr/`;

        res.itemListElement = [
            ...BREADCRUMBS_COMMON,
            {
                '@type': 'ListItem',
                position: 2,
                name: 'Acronis press releases',
                item: prHomeLink,
            },
            {
                '@type': 'ListItem',
                position: 3,
                name: `Press releases for ${releaseYear}`,
                item: `${prHomeLink}${releaseYear}/`,
            },
            {
                '@type': 'ListItem',
                position: 4,
                name: release.seo_title,
                item: `${getSiteUrl(this.ctx)}${this.ctx.$route.path}`,
            },
        ];

        return res;
    }

    getMainSiteBreadcrumbs(data: any) {
        const res = { ...data };
        const slices = this.ctx.$store.state.pages?.page?.body || [];
        const headerConfig = slices.find((slice) => slice.name === HEADER_CONFIG_SLICE_NAME);
        const customHeaderConfig = slices.find((slice) => slice.name === CUSTOMIZABLE_HEADER_CONFIG_SLICE_NAME);
        const breadcrumbs = headerConfig?.data?.breadcrumbs || customHeaderConfig?.data?.breadcrumbs;

        if (!breadcrumbs?.length) return null;

        res.itemListElement = breadcrumbs
            .filter((breadcrumb) => !breadcrumb.sublinks)
            .map((breadcrumb, index) => {
                const isEmptyBreadcrumb = breadcrumb.to === '#' || breadcrumb.to.startsWith('javascript');
                const id = isEmptyBreadcrumb
                    ? `${getSiteUrl(this.ctx)}${this.ctx.$route.path}`
                    : `${getUrlWithLocale(this.ctx)}${breadcrumb.to}`;

                return {
                    '@type': 'ListItem',
                    position: index + 1,
                    item: id,
                    name: breadcrumb.text || 'Acronis',
                };
            });

        return res;
    }
}
