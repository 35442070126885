<template>
    <s-basic-slice class="legal" :class="{ ...basicClassName }" :title="title">
        <div class="legal__wrapper">
            <div class="legal__nav-wrapper">
                <nav class="section-nav">
                    <ul v-for="(item, index) in navList" :key="index" class="section-nav__list">
                        <li class="section-nav__item">
                            <a-link
                                class="section-nav__link"
                                v-bind="item"
                                :to="`#${item.to}`"
                                :class="getClass(index)"
                                :size="item.size || 'paragraph'"
                                @click.native="changeActiveItem(index)"
                            />
                        </li>
                    </ul>
                </nav>
            </div>
            <div ref="content" class="legal__content">
                <s-word-formatter
                    v-if="jsonData?.length"
                    :json-data="jsonData"
                    set-heading-anchors
                />
                <template v-else>
                    <section
                        v-for="(item, index) in list"
                        :id="item.to"
                        :key="index"
                        class="legal__section"
                    >
                        <h2 class="legal__section-title">
                            {{ item.text }}
                        </h2>
                        <section v-for="(section, sectionKey) in item.section" :key="sectionKey" class="text-section">
                            <h3 v-if="section.title" class="text-section__title">
                                {{ section.title }}
                            </h3>
                            <a-dangerous-html :content="section.text" tag="p" />
                            <template v-if="section.table">
                                <a-table-new v-bind="section.table" />
                            </template>
                        </section>
                    </section>
                </template>
            </div>
        </div>
    </s-basic-slice>
</template>

<script>
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import ALink from '@core/components/link/link.vue';
import ATableNew from '@core/components/table-new/table-new.vue';
import contentMixin from '@core/mixins/content.js';
import styleMixin from '@core/mixins/style.js';
import SBasicSlice from '@core/slices/pages/basic-slice/basic-slice.vue';
import SWordFormatter from '@core/slices/pages/word-formatter/word-formatter.vue';
import commonUtils from '@utils/common';

export default {
    name: 'SLegal',

    components: {
        SWordFormatter,
        ADangerousHtml,
        ALink,
        ATableNew,
        SBasicSlice,
    },

    mixins: [contentMixin, styleMixin],

    props: {
        list: {
            type: Array,
            default: () => ([]),
        },
        jsonData: {
            type: String,
            default: undefined,
        },
    },

    data: () => ({
        activeItemIndex: 0,
        otLink: null,
        headingOffsets: [],
    }),

    computed: {
        parsedHeadings() {
            if (!this.jsonData) return [];

            let data;

            try {
                data = JSON.parse(this.jsonData);
            } catch (e) {
                data = [];
            }

            const headings = data.filter((item) => item.type === 'header' && item.data?.level === 2);
            return headings.map((item) => ({
                text: item.data.text,
                to: commonUtils.slugify(item.data.text),
            }));
        },

        navList() {
            if (this.parsedHeadings?.length) return this.parsedHeadings;
            return this.list || [];
        },

        itemHash() {
            return this.$route.hash?.replace('#', '');
        },
    },

    mounted() {
        this.checkOneTrustLink();
        this.setActiveItem();
        this.scrollToActiveItem();
        this.calculateItemOffsets();

        window.addEventListener('scroll', this.findActiveItem);
    },

    beforeDestroy() {
        this.otLink?.removeEventListener('click', this.openOneTrustPopup);
        window.removeEventListener('scroll', this.findActiveItem);
    },

    methods: {
        calculateItemOffsets() {
            if (!this.parsedHeadings?.length) return;

            const anchors = this.$refs.content?.querySelectorAll('.heading-anchor') || [];
            const anchorsArr = Array.from(anchors);

            for (let i = 0; i < anchorsArr.length; i++) {
                const from = anchorsArr[i].offsetTop;
                const to = anchorsArr[i + 1]?.offsetTop || Infinity;
                this.headingOffsets.push({ from, to });
            }
        },

        findActiveItem() {
            if (!this.parsedHeadings?.length) return;

            const pageOffset = window.pageYOffset;
            const index = this.headingOffsets.findIndex((offset) => pageOffset >= offset.from && pageOffset <= offset.to);

            this.activeItemIndex = Math.max(index, 0);

            if (this.itemHash !== this.navList[index].to) {
                this.$router.replace({ hash: `#${this.navList[index].to}` });
            }
        },

        async scrollToActiveItem() {
            await this.$nextTick();
            const element = document.getElementById(this.itemHash);
            element?.scrollIntoView({ behavior: 'smooth' });
        },

        setActiveItem() {
            const index = this.navList.findIndex((item) => item.to === this.itemHash);
            this.activeItemIndex = Math.max(index, 0);
        },

        getClass(index) {
            return { 'section-nav__link_active': this.activeItemIndex === index };
        },

        changeActiveItem(index) {
            this.activeItemIndex = index;
        },

        checkOneTrustLink() {
            this.otLink = document.getElementById('one-trust-link');
            this.otLink?.addEventListener('click', this.openOneTrustPopup);
        },

        openOneTrustPopup(event) {
            event.preventDefault();
            if (typeof window.OneTrust === 'undefined') return;
            window.OneTrust.ToggleInfoDisplay();
        },
    },
};
</script>

<style lang="postcss" scoped>
.legal {
    &:deep(.a-slice-header) {
        @mixin colls 12;
        margin-bottom: 0;

        &__title {
            padding: 48px 0 64px 0;
        }
    }

    b {
        font-weight: bold;
    }

    .a-section-header__title {
        margin-bottom: 64px;
    }

    &__wrapper {
        @media (--viewport-desktop-wide) {
            display: flex;
        }
    }

    &__nav-wrapper {
        display: none;
        width: 25%;
        padding-inline-end: 32px;

        @media (--viewport-desktop-wide) {
            display: block;
        }
    }

    &__content {
        @media (--viewport-desktop-wide) {
            width: 75%;
        }
    }

    &__section {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }

    &__section-title {
        @mixin title;

        @media (--viewport-tablet) {
            @mixin display;
        }

        margin-bottom: 16px;
        color: var(--av-nav-primary);
    }

    &__other-versions {
        margin-top: 88px;
    }

    &:deep(.section-nav) {
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        @media (--viewport-desktop-wide) {
            position: sticky;
            top: 12px;
            max-height: 95vh;
            overflow-y: auto;
            padding-bottom: 12px;
        }

        &__link {
            display: block;
            padding: 12px 32px 12px 16px;
            border-radius: 4px;
            text-decoration: none;
            .a-link__content {
                color: var(--av-nav-primary);
            }
            &:hover,
            &:active,
            &:focus,
            &_active {
                background-color: var(--av-brand-light);
                .a-link__content {
                    background: none;
                    color: var(--av-nav-primary);
                }
            }
        }
    }

    &:deep(.text-section) {
        &:not(:last-of-type) {
            margin-bottom: 32px;
        }

        &__title {
            margin-bottom: 16px;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            color: var(--av-nav-primary);
        }

        p {
            color: var(--av-fixed-secondary);

            &:not(:last-of-type) {
                margin-bottom: 16px;
            }
        }

        a {
            color: var(--av-link-primary);
            text-decoration: none;
            word-wrap: break-word;

            &:hover {
                color: var(--av-link-primary-hover);
            }
            &:active {
                color: var(--av-link-primary-active);
                background: rgba(255, 255, 255, 0);
            }
        }

        .a-list_type_bullet {
            margin: 16px 0 0 8px;
        }

        &:deep(.a-link-list) {
            margin: 16px 0 0;
        }
    }

    .word-formatter {
        padding: 0;
        margin-top: -24px;

        &:deep(.article-body) {
            padding: 0;
            max-width: initial;
        }
    }
}
</style>
