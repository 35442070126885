<template>
    <div class="integration-card">
        <div class="tag-wrap">
            <a-label v-if="tag" v-bind="tag" :size="tag.size || 's'">
                {{ tag.text }}
            </a-label>
        </div>
        <img class="logo" :src="logo" :alt="title" />
        <div class="title-wrap">
            <div class="title">
                {{ title }}
            </div>
            <div v-if="vendor" class="vendor">
                {{ vendor }}
            </div>
        </div>
        <v-clamp
            v-if="description"
            class="description"
            autoresize
            :max-lines="6"
        >
            {{ description }}
        </v-clamp>
        <div class="cta">
            <a-button
                class="button"
                type="main"
                :text="buttonTitle"
                :to="`/integrations/${slug}/`"
                size="s"
            />
        </div>
    </div>
</template>

<script>
import VClamp from 'vue-clamp';
import AButton from '@core/components/button/button.vue';
import ALabel from '@core/components/label/label.vue';

export default {
    name: 'SIntegrationCard',

    components: {
        AButton,
        VClamp,
        ALabel,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        vendor: {
            type: String,
            default: null,
        },

        description: {
            type: String,
            default: null,
        },

        logo: {
            type: String,
            required: true,
        },

        slug: {
            type: String,
            default: null,
        },

        cta: {
            type: Array,
            default: () => [],
        },

        tag: {
            type: Object,
            default: null,
        },

        buttonTitle: {
            type: String,
            default: 'Learn more',
        },
    },
};
</script>

<style lang="postcss" scoped>
.integration-card {
    min-height: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px;
    border: 1px solid var(--av-brand-light);
    border-radius: 4px;
    background: var(--av-inversed-primary);
    color: var(--av-fixed-primary);
}

.tag-wrap {
    height: 16px;
    margin-bottom: 20px;
}

.arrow {
    fill: var(--av-brand-primary);
}

.logo {
    height: 48px;
    width: 100%;
    margin-bottom: 20px;
    object-fit: contain;
    object-position: left top;
}

.arrow {
    position: absolute;
    inset-inline-end: 24px;
    top: 24px;
}

.title-wrap {
    min-height: 65px;
    margin-bottom: 16px;
}

.title {
    @mixin lead-strong;
}

.vendor {
    @mixin caption;

    margin-top: 8px;
    color: var(--av-fixed-light);
}

.description {
    @mixin body;

    border-top: 1px solid var(--av-brand-accent);
    padding-top: 16px;
    margin-bottom: 24px;
}

.cta {
    margin-top: auto;
}

.button {
    @mixin paragraph;

    width: 100%;

    + .button {
        margin-top: 16px;
    }
}

</style>
