// Placement options are defined as a type in uikit package and cannot be imported as array of strings
export const PLACEMENT = [
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
    'right',
    'right-start',
    'right-end',
];

export const TRIGGER = [
    'hover',
    'click',
];
