<template>
    <div v-show="links.length" class="release-available-locales">
        <div class="snippet-title">
            {{ commonTranslations.alsoAvailableOn }}
        </div>
        <a-link
            v-for="(link, i) in links"
            :key="i"
            class="resource-link"
            v-bind="link"
        />
    </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex';
import ALink from '@core/components/link/link.vue';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { getLinksToAvailableLocales } from '@utils/locales';

export default {
    name: 'PrAvailableLocales',

    components: { ALink },

    props: {
        localesData: {
            type: Array,
            required: true,
        },
        commonTranslations: {
            type: Object as any,
            required: true,
        },
    },

    computed: {
        ...mapGetters('slices', ['getLocales']),

        locale() {
            return this.$route.params.locale || LOCALE_DEFAULT;
        },

        links() {
            const links = getLinksToAvailableLocales({
                localeList: this.localesData.map((item) => item.locale),
                localesConfig: this.getLocales,
                urlTemplate: `/{{LOCALE}}/pr/{{YEAR}}/${this.$route.params.slug}/`,
            });

            // additionally set the correct year for each locale
            return links.map((link) => {
                const config = this.localesData.find((item) => item.locale === link.locale);
                const to = link.to.replace('{{YEAR}}', config.year);
                return { ...link, to };
            });
        },
    },
};
</script>

<style lang="postcss" scoped>
.release-available-locales {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin: 0 auto 16px;
    max-width: fit-content;
    padding: 16px;
    border: 1px solid var(--av-brand-light);
    border-radius: 4px;

    @media (--viewport-desktop) {
        flex-wrap: nowrap;
    }

    .snippet-title {
        @mixin body;
        font-weight: 500;
        color: var(--av-fixed-light);
        width: 100%;
        text-align: center;

        @media (--viewport-desktop) {
            width: auto;
        }
    }

    &:deep(.a-link__content) {
        @mixin body;
        font-weight: 500;
        color: var(--av-brand-secondary);
    }

    &--gated {
        flex-wrap: wrap;
        justify-content: flex-start;
        column-gap: 24px;
        row-gap: 8px;
        margin-bottom: 40px;
        max-width: initial;

        .snippet-title {
            width: auto;
        }
    }
}
</style>
