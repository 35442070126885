<template>
    <div class="blog-post-head" :class="className">
        <div class="head-top-container">
            <a-link
                class="back-link"
                :to="parentLink"
                :text="parentLinkTitle"
                glyph="arrow-left"
                glyph-position="left"
                size="paragraph"
                type="light"
                accent
            />
        </div>
        <div class="head-container">
            <div class="head-container-inner">
                <div class="date">
                    <blog-date-localized :date="publishedAt" />
                    <template v-if="author">
                        &nbsp;—&nbsp;
                        <a-link
                            class="author"
                            :to="author.link"
                            :text="author.name"
                            size="paragraph"
                            type="light"
                        />
                    </template>
                </div>
                <div v-if="categories" class="categories">
                    <dynamic-link
                        v-for="(category, i) in categories"
                        :key="i"
                        class="category"
                        :to="category.link"
                        native-type="a"
                    >
                        {{ category.title }}
                    </dynamic-link>
                </div>
            </div>
        </div>
        <div class="title-container">
            <h1 class="title">
                {{ title }}
            </h1>
        </div>
    </div>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import ALink from '@core/components/link/link.vue';
import BlogDateLocalized from '../shared-components/blog-date-localized/blog-date-localized.vue';

export default {
    name: 'BlogPostHead',
    components: {
        ALink,
        DynamicLink,
        BlogDateLocalized,
    },
    props: {
        author: {
            type: Object,
            required: true,
        },

        categories: {
            type: Array,
            default: () => [],
        },

        publishedAt: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        parentLink: {
            type: String,
            required: true,
        },

        parentLinkTitle: {
            type: String,
            required: true,
        },

        theme: {
            type: String,
            default: 'light',
        },
    },
};
</script>

<style lang="postcss" scoped>
.blog-post-head {
    position: relative;
    margin-bottom: 16px;

    .head-top-container {
        position: relative;
        padding-top: 16px;
        text-align: center;

        @media (--viewport-mobile-wide) {
            padding-top: 32px;
            text-align: start;
        }

        @media (--viewport-desktop-wide) {
            padding: 32px 64px 0;
        }
    }
    .post-title {
        @mixin paragraph;
        font-weight: 700;
        margin-top: 36px;
        margin-bottom: 24px;
        color: var(--av-nav-primary);

        &:is(h3, h4, h5, h6) {
            @mixin lead;
            font-weight: 700;
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }

    .image:deep(img) {
        width: 100%;
    }

    .paragraph a {
        color: var(--av-brand-secondary);

        &:hover {
            color: var(--av-brand-primary);
        }
    }

    .back-link {
        margin-bottom: 24px;
        text-decoration: none;

        &:deep(.a-link__content) {
            color: var(--av-brand-secondary);
        }

        &:deep(.a-glyph) {
            fill: var(--av-brand-secondary);
        }

        @media (--viewport-mobile-wide) {
            position: absolute;
            inset-inline-start: 0;
            z-index: 9;
        }
    }

    .head-container {
        position: relative;
    }

    .head-container-inner {
        text-align: center;
    }

    .title-container {
        @media (--viewport-mobile-wide) {
            display: grid;
            column-gap: 16px;
            grid-template-columns: 1fr 10fr 1fr;
        }

        @media (--viewport-desktop-wide) {
            grid-template-columns: 1fr 4fr 1fr;
        }
    }

    .date {
        @mixin paragraph-accent;

        text-decoration: none;
        color: var(--av-brand-secondary);
        margin-bottom: 24px;
        display: inline-block;

        @media (--viewport-mobile-wide) {
            margin-bottom: 48px;
        }

        &:deep(.a-link__content) {
            color: var(--av-brand-secondary);
        }
    }

    .title {
        @mixin title-accent;

        color: var(--av-nav-primary);
        text-align: center;
        margin-bottom: 24px;

        @media (--viewport-mobile-wide) {
            @mixin display;

            grid-column-start: 2;
            grid-column-end: 3;
        }

        @media (--viewport-desktop) {
            @mixin hero;
        }
    }

    .categories {
        text-align: center;
        margin-bottom: 16px;

        @media (--viewport-mobile-wide) {
            margin-bottom: 0;
        }
    }

    .category {
        @mixin note-heading;

        display: inline-block;
        color: var(--av-brand-primary);
        padding: 0 8px;
        border-radius: 4px;
        background: var(--av-brand-accent);
        margin: 0 4px 8px;
        text-decoration: none;
    }
}
</style>
