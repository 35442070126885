<template>
    <div v-if="list.length" class="warnings">
        <div
            v-for="(warning, warningIndex) in list"
            :key="`warning-${warningIndex}`"
            :class="`warning warning-type-${warning.type || 'default'}`"
        >
            <a-glyph v-if="warning.glyph" :name="warning.glyph" />
            <div>
                <span v-html="warning.text" />
                <a-tooltip v-if="warning.tooltip" glyph="info-circle" :text="warning.tooltip" />
            </div>
        </div>
    </div>
</template>

<script>
import { toNumber } from 'lodash';
import AGlyph from '@core/components/glyph/glyph.vue';
import ATooltip from '@core/components/tooltip/tooltip.vue';
import analytics from '@core/mixins/analytics.js';
import mixin from '../mixin.js';

export default {
    name: 'SWarnings',
    components: {
        AGlyph,
        ATooltip,
    },

    mixins: [analytics, mixin],

    props: {
        list: {
            type: Array,
            default: null,
        },
    },

    emits: ['addCloud', 'changePage', 'changeQuantity', 'changeItem', 'cartRecommendation'],

    updated() {
        this.$el.addEventListener('click', this.handleClick);
    },

    beforeDestroy() {
        this.$el.removeEventListener('click', this.handleClick);
    },

    mounted() {
        this.$el.addEventListener('click', this.handleClick);
    },

    methods: {
        quantity(license) {
            const quantity = license.quantity || 1;
            const multiplier = license.license?.multiplier || 1;
            if (license.license?.isSeats) {
                const result = quantity / multiplier;
                if (quantity % multiplier === 0) return result;
                return parseInt(result, 10) + 1;
            }
            return quantity;
        },

        handleClick(event) {
            const link = event.target.dataset?.link;
            if (link === 'cloud-storage') {
                this.$emit('addCloud');
            }
            if (link === 'products') {
                this.$emit('changePage');
            }
            if (link === 'license') {
                const cartID = toNumber(event.target.dataset?.license);
                const current = this.list.find((item) => item.license?.cleverBridgeCartID === cartID);
                this.$emit('changeItem', current.license);
                this.$emit('changeQuantity', {
                    ...current.license,
                    isChecked: true,
                    quantity: this.quantity(current),
                    setPeriod: current.license.period,
                });
                this.$emit('cartRecommendation', { add: current.license });
                this.sendEcommerceAdd({ ...current.license, quantity: 1, item_list_name: 'cart recommendation' });
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.warning {
    @mixin body;
    display: flex;
    padding: 8px;
    border-radius: 8px;
    color: var(--av-fixed-primary);

    &:not(:first-child) {
        margin: 8px 0 0;
    }

    .a-glyph {
        width: 16px;
        height: 16px;
        min-width: 16px;
        margin-top: 4px;
        margin-inline-end: 8px;
    }

    &:deep(.a-link) {
        display: inline;
        font-weight: 700;
    }

    &:deep(.a-tooltip) {
        .a-glyph {
            margin: 0 0 4px;
            vertical-align: middle;
        }
    }
}

.warning-type-default {
    border: 1px solid var(--av-fixed-critical);
    background: var(--av-fixed-warning-accent);
    .a-glyph {
        fill: var(--av-fixed-critical);
    }
}

.warning-type-promo {
    border: 1px solid var(--av-fixed-success-dark);
    background: var(--av-fixed-success-accent);
    .a-glyph {
        fill: var(--av-fixed-success-dark);
    }
}

.warning-type-recommendation {
    border: 1px solid var(--av-fixed-success-dark);
    background: var(--av-fixed-success-accent);
}
</style>
