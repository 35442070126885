/**
 * Logo types
 *
 * @return {string[]}
 */
export const TYPES = ['full'];

/**
 * Logo colors
 *
 * @return {string[]}
 */
export const COLORS = ['white', 'dark'];
