<template>
    <section :id="id" class="acpc-addon-packs">
        <div class="a-container">
            <div class="wrapper">
                <h6 v-if="title" class="title">
                    {{ title }}
                </h6>
                <p v-if="desc" class="desc">
                    {{ desc }}
                </p>
                <div class="packs">
                    <a-dynamic-link
                        v-for="(item, index) in items"
                        :key="`acpc-addon-pack_${index}`"
                        :to="item.to"
                        class="pack"
                    >
                        <a-picture
                            class="image"
                            :link="item.image"
                            :is-background="true"
                            fit="contain"
                        />
                        <span
                            v-if="item.text"
                            class="text"
                            v-html="item.text"
                        />
                    </a-dynamic-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ADynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'SProductAddonPacks',
    components: {
        ADynamicLink,
        APicture,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        desc: {
            type: String,
            default: '',
        },
        items: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" scoped>
.acpc-addon-packs {
    @mixin basic-slice-paddings;

    .wrapper {
        color: var(--av-nav-primary);
        .title {
            @mixin colls 12;
            @mixin display;
            margin-bottom: 24px;
            @media (--viewport-desktop) {
                @mixin hero;
            }
            @media (--viewport-desktop-wide) {
                @mixin colls 11;
            }
        }
        .desc {
            @mixin colls 12;
            @mixin title;
            margin-bottom: 32px;
            @media (--viewport-tablet) {
                margin-bottom: 40px;
            }
            @media (--viewport-desktop-wide) {
                @mixin colls 11;
            }
        }
        .packs {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            gap: 16px;
            .pack {
                @mixin colls 12;
                height: 333px;
                position: relative;
                border-radius: 8px;
                overflow: hidden;
                background: #00175a;
                @media (--viewport-tablet) {
                    @mixin colls 3;
                }
                .text {
                    @mixin title-accent;
                    position: absolute;
                    inset-inline-start: 50%;
                    transform: translateX(-50%);
                    bottom: 32px;
                    width: 232px;
                    max-width: 100%;
                    z-index: 5;
                    color: var(--av-inversed-primary);
                    text-align: center;
                    @media (--viewport-tablet) {
                        @mixin lead-accent;
                    }
                    @media (--viewport-desktop-wide) {
                        @mixin title-accent;
                    }
                }
            }
        }
    }
}
</style>
