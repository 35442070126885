<template>
    <div class="event-video-list" :style="cssVars">
        <h2 v-if="title" class="title">
            {{ title }}
        </h2>
        <div v-if="lead" class="section-lead">
            {{ lead }}
        </div>
        <div class="videos">
            <div
                v-for="category in videosCategories"
                :id="category === 'null' ? 'without-category' : slugify(category)"
                :key="category"
                class="category-wrap"
            >
                <div class="category-title">
                    {{ category === 'null' ? translations.additionalVideos : category }}
                </div>
                <div v-if="isMounted" class="videos-wrapper">
                    <event-video
                        v-for="(video, i) in getVideosByCategory(category)"
                        :key="i"
                        v-bind="video"
                        :ref="`video-${video.ytId}`"
                        @scrollToFormClick="$emit('scrollToFormClick')"
                    />
                </div>
                <event-show-all-button
                    v-if="getVideosByCategory(category, false).length > VIDEOS_LIMIT"
                    :show-all-text="translations?.showAllVideosTitle"
                    :show-less-text="translations?.showLessVideosTitle"
                    @toggle="($event) => toggleList(category, $event)"
                />
            </div>
        </div>
        <a-modal
            v-for="(video, i) in videos"
            :id="video.ytId"
            :key="`${i}_${video.ytId}`"
            type="youtube"
            is-youtube
            is-overlay-close
        >
            <a-youtube
                :yt-id="video.ytId"
                :params="video.ytParams || { autoplay: 1 }"
                @progressChanged="$emit('progressChanged', $event)"
            />
        </a-modal>
    </div>
</template>

<script>
import { groupBy } from 'lodash';
import AModal from '@core/components/modal/modal.vue';
import AYoutube from '@core/components/youtube/youtube.vue';
import EventShowAllButton from '@core/slices/events/shared-components/event-show-all-button/event-show-all-button.vue';
import { VIDEOS_LIMIT } from '@model/const/events.ts';
import commonUtils from '@utils/common';
import EventVideo from '../shared-components/event-video/event-video.vue';

export default {
    name: 'EventVideoList',

    components: {
        EventShowAllButton,
        AModal,
        AYoutube,
        EventVideo,
    },

    props: {
        title: {
            type: String,
            default: null,
        },

        lead: {
            type: String,
            default: null,
        },

        videos: {
            type: Array,
            required: true,
        },

        limit: {
            type: Number,
            default: 0,
        },

        columnCount: {
            type: Number,
            default: 3,
        },

        translations: {
            type: Object,
            required: true,
        },
    },

    emits: ['progressChanged', 'scrollToFormClick'],

    data: () => ({
        VIDEOS_LIMIT,
        expanded: {},
        isMounted: false,
    }),

    computed: {
        videosByCategories() {
            return groupBy(this.videos, 'group') || [];
        },

        videosCategories() {
            return Object.keys(this.videosByCategories).sort((a, b) => (b === 'null' ? -1 : 0));
        },

        cssVars() {
            return {
                '--video-list-columns': this.columnCount,
            };
        },
    },

    created() {
        this.videosCategories.forEach((c) => {
            this.expanded[c] = false;
        });
    },

    mounted() {
        this.isMounted = true;
    },

    methods: {
        slugify(input) {
            return commonUtils.slugify(input);
        },

        getVideosByCategory(category, doTrim = true) {
            const videos = this.videosByCategories[category] || [];
            let trimEnd;

            if (doTrim) {
                trimEnd = this.expanded[category] ? videos.length : this.VIDEOS_LIMIT;
            } else {
                trimEnd = videos.length;
            }

            return videos.slice(0, trimEnd);
        },

        toggleList(category, state) {
            this.expanded[category] = state;
            this.$forceUpdate();
        },
    },
};
</script>

<style lang="postcss" scoped>
.category-wrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.category-title {
    @mixin lead;
    font-weight: 500;
    color: var(--av-nav-primary);
}

.videos {
    display: flex;
    flex-direction: column;
    gap: 56px;

    @media (--viewport-tablet) {
        gap: 40px;
    }
}

.videos-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (--viewport-tablet) {
        display: grid;
        grid-template-columns: repeat(var(--video-list-columns), 1fr);
    }
}
</style>
