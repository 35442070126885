<template>
    <div class="menu-tab-products">
        <menu-tab v-bind="$props" />
    </div>
</template>

<script lang="ts">
import MenuTabMixin from './menu-tab-mixin.vue';

export default {
    name: 'MenuTabProducts',
    mixins: [MenuTabMixin],
};
</script>

<style lang="postcss" scoped>
.menu-tab-products {
    .s-menu-tab.s-menu-tab_mini {
        &:deep(.s-menu-tab__body_type_products) {
            @media (--viewport-tablet) {
                display: grid;
                padding: 8px 48px 8px 32px;
                grid-template-columns: 2fr 1fr 1fr;
                grid-column-gap: 40px;
            }

            > * {
                padding: 32px 0;
                grid-row: auto;
                order: initial;
            }

            @media (--viewport-desktop-wide) {
                padding: 8px 112px 8px 64px;
                grid-column-gap: 80px;
            }

            @media (--viewport-desktop-large) {
                padding: 8px 0 8px 0;
            }
        }

        &:deep(.s-menu-tab__section_type_products) {
            &.s-menu-tab__section_full-width {
                .s-menu-tab__text {
                    @media (--viewport-tablet) {
                        margin-top: 0;
                    }
                }
            }

            .s-menu-tab {
                &__linktitle {
                    margin-top: 8px;
                }

                &__link-strong {
                    .a-link__content {
                        @mixin caption-accent;
                        color: var(--av-nav-primary);
                        @media (--viewport-desktop) {
                            @mixin body;
                        }
                    }
                }
            }

            @media (--viewport-tablet) {
                &:nth-child(4) {
                    border-top: 1px solid var(--av-brand-accent);
                }
            }

            @media (--viewport-desktop) {
                &:nth-child(1) {
                    .s-menu-tab {
                        &__description {
                            @mixin body;
                        }

                        &__linktitle {
                            .a-link__content {
                                @mixin title;
                            }
                        }
                    }
                }
            }
        }
    }

    &:deep(.s-menu-tab__body_type_products) {
        @media (--viewport-tablet) {
            padding: 0 32px 8px;
            grid-column-gap: 40px;
            grid-template-columns: repeat(3, 200px);
        }

        @media (--viewport-desktop) {
            grid-column-gap: 32px;
            grid-template-columns: 2fr 1fr 1fr;
        }

        @media (--viewport-desktop-wide) {
            padding: 0 64px 8px;
            grid-column-gap: 40px;
            grid-template-columns: 546px 250px 250px;
        }

        @media (--viewport-desktop-large) {
            padding: 0 0 8px;
            grid-column-gap: 48px;
            grid-template-columns: 618px 284px 320px;
        }
    }

    &:deep(.s-menu-tab__section_type_products) {
        .s-menu-tab {
            &__glyphlist-item-link {
                .a-link__content {
                    @mixin lead-accent;
                }
            }
        }

        &.s-menu-tab__section_full-width {
            grid-column: 1 / 4;

            .s-menu-tab__text {
                margin-top: 0;
            }

            @media (--viewport-tablet) {
                .a-glyph__products {
                    vertical-align: middle;
                    margin-top: -1px;
                }
            }
        }

        .s-menu-tab {
            &__linktitle {
                margin-top: 8px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;

                .a-link {
                    display: inline;
                    margin-inline-end: 4px;

                    .a-link__content {
                        display: inline;
                    }
                }

                .a-label {
                    transform: translateY(-1px);
                }
            }

            &__link-strong {
                @mixin body-accent;
                margin-bottom: 8px;

                @media (--viewport-desktop) {
                    @mixin body;
                }
            }
        }

        @media (--viewport-tablet) {
            &:nth-child(1) {
                grid-row: 1 / span 2;

                .s-menu-tab {
                    &__list {
                        margin: 0;
                    }
                }
            }

            &:nth-child(3) {
                order: 4;
                padding: 16px 0 40px;
                grid-column: span 3;
            }

            &:nth-child(2),
            &:nth-child(4) {
                grid-row: 1 / span 3;

                .s-menu-tab {
                    &__list {
                        margin: 40px 0 0;
                    }
                }
            }

            &:nth-child(2),
            &:nth-child(4) {
                @media (--viewport-desktop) {
                    max-width: 250px;
                }

                @media (--viewport-desktop-wide) {
                    max-width: initial;
                }
            }

            &:nth-child(5) {
                order: 5;
                border-top: 1px solid var(--av-brand-accent);
                grid-column: span 3;
            }
        }

        @media (--viewport-desktop) {
            &:nth-child(1) {
                .s-menu-tab {
                    &__description {
                        @mixin paragraph;
                    }

                    &__linktitle {
                        .a-link__content {
                            @mixin title;
                        }
                    }

                    &__cta {
                        display: flex;
                    }

                    &__cta-item {
                        &:not(:first-child) {
                            margin-top: 0;
                            margin-inline-start: 40px;
                        }
                    }
                }
            }

            &:nth-child(3) {
                grid-column: span 1;

                .s-menu-tab__text {
                    @mixin paragraph;
                }
            }

            .s-menu-tab {
                &__description {
                    @mixin body;
                }

                &__description {
                    margin: 8px 0 0;
                }
            }
        }

        @media (--viewport-desktop-wide) {
            &:nth-child(1) {
                .s-menu-tab {
                    &__list {
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: row;
                        align-items: flex-start;
                        justify-content: flex-start;
                        margin: 8px 0 0;
                    }

                    &__list-item {
                        min-width: 216px;
                        max-width: 256px;

                        &:first-child {
                            margin: 0;
                        }
                    }
                }
            }

            &:nth-child(3) {
                padding-top: 8px;
            }

            .s-menu-tab {
                &__text {
                    max-width: 617px;
                }
            }
        }

        @media (--viewport-desktop-large) {
            &:nth-child(2),
            &:nth-child(4) {
                .s-menu-tab {
                    &__description {
                        max-width: 269px;
                    }
                }
            }
        }
    }
}
</style>
