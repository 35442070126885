/* eslint-disable max-len */

export const Figma = 'https://www.figma.com/file/LSk5K8T48OkHYFkBukIzRn/Campaigns---Custom-headers?type=design&node-id=746-2988&t=9pXvWYZYSrm3RaPs-0';

export const AlignLeft = {
    name: 'align: left',
    args: {
        translations: {
            productTitle: 'Cyber Protect Cloud',
            title: { text: 'There has never been a better time to upgrade  to Acronis Cyber Protect' },
            lead: {
                text: 'Acronis continues invest in the success of hosting and cloud service providers. Acronis continues invest in the success of hosting and cloud service providers cloud service providers cloud service providers',
            },
            buttons: [
                { text: 'CTA Button 1' },
                { text: 'CTA Button 2' },
            ],
            link: { text: 'CTA Link 1' },
            media: {
                text: 'Real-time demo of ransomware protection',
                imageAlt: 'video',
            },
        },
        alignment: 'left',
        theme: 'dark',
        background: 'dark-blue',
        borderBottom: 'none',
        isHeaderTag: true,
        title: {
            size: 70,
            height: 48,
        },
        lead: {
            size: 16,
            height: 24,
        },
        buttons: [
            {
                to: '#',
                type: 'main',
            },
            {
                to: '#',
                type: 'action',
            },
        ],
        link: {
            to: '#',
        },
        backgroundImage: {
            desktop: '@3b87b0f21e5fd0856a148f21ccd88b61',
            tablet: '@1411ec6a3830e738ce022160cb6e81a2',
            mobile: '@fd4c5107735b0948e62102fe9c40adee',
        },
        media: {
            type: 'video',
            ytId: 'kSLEGX-j9Jg',
            time: '1:20:34',
            image: '/images/video_car/video_car',
        },
    },
};

export const AlignCenter = {
    name: 'align: center',
    args: {
        translations: {
            productTitle: 'Cyber Protect Cloud',
            title: { text: 'Easy to run' },
            titleSecondary: { text: 'Hard to crack' },
            lead: { text: 'Itroducing the first data leak protection that’s simple to implement' },
            buttons: [
                { text: 'CTA Button 1' },
                { text: 'CTA Button 2' },
            ],
        },
        alignment: 'center',
        theme: 'dark',
        background: 'dark-blue',
        borderBottom: 'none',
        isHeaderTag: true,
        buttons: [
            {
                to: '#',
                type: 'main',
            },
            {
                to: '#',
                type: 'action',
            },
        ],
        backgroundImage: {
            desktop: '@b759246a3f56ecd4887a7300ecc44ed9',
            tablet: '@9bd2616dc71a9164c4cfedb6ee9a2303',
            mobile: '@5d5eb9ae977b099503e37116d6f05d1a',
        },
    },
};

export const AlignLeftRight = {
    name: 'align: left-right',
    args: {
        translations: {
            productTitle: 'Cyber Protect Cloud',
            title: { text: 'Security in<br />one place ...' },
            titleSecondary: { text: '... not all over<br /> the place.' },
            lead: {
                text: 'Acronis continues invest in the success of hosting and cloud service providers. Acronis continues invest in the success of hosting and cloud service providers',
            },
            buttons: [
                { text: 'CTA Button 1' },
                { text: 'CTA Button 2' },
            ],
            link: { text: 'CTA Link 1' },
        },
        alignment: 'left-right',
        theme: 'dark',
        background: 'dark-blue',
        borderBottom: 'none',
        isHeaderTag: true,
        buttons: [
            {
                to: '#',
                type: 'main',
            },
            {
                to: '#',
                type: 'action',
            },
        ],
        link: {
            to: '#',
            type: 'light',
        },
        backgroundImage: {
            desktop: '@eb376609d693d33b89f8f9ab534819a8',
            tablet: '@e699944cb6d66aab3a23f098c754b9af',
            mobile: '@98dd5414ea3d5b86c6413c8b0e2a4da0',
        },
    },
};

export const BackgroundImage = {
    name: 'background: image',
    args: {
        ...AlignLeft.args,
        theme: 'dark',
        backgroundImage: {
            desktop: '@b37ce9bb115f0967b8dc3c192de0ea5e',
            tablet: '@b37ce9bb115f0967b8dc3c192de0ea5e',
            mobile: '@b37ce9bb115f0967b8dc3c192de0ea5e',
        },
        link: {
            to: '#',
            type: 'light',
        },
    },
};

export const BackgroundVideo = {
    name: 'background: video',
    args: {
        translations: {
            productTitle: 'Cyber Protect Cloud',
            title: { text: 'Looking for Datto alternatives?' },
            lead: { text: 'Compare Acronis Cyber Protect Cloud to Datto' },
            buttons: [{ text: 'Get the offer now' }],
        },
        alignment: 'left',
        theme: 'dark',
        background: 'dark-blue',
        borderBottom: 'none',
        title: {
            size: 64,
            height: 72,
        },
        lead: {
            size: 32,
            height: 40,
        },
        buttons: [
            {
                to: '',
                type: 'action',
            },
        ],
        videoBackground: {
            poster: 'https://staticfiles.acronis.com/images/background/bd3c0e0ea433fe2e9e9fcdff1eac8265.webp',
            sources: [
                {
                    type: 'webm',
                    link: '//staticfiles.acronis.com/videos/s-customizable-header.webm',
                },
                {
                    type: 'mp4',
                    link: '//staticfiles.acronis.com/videos/s-customizable-header.mp4',
                },
            ],
        },
        videoOverlay: {
            visible: true,
            mobile: 'linear-gradient(180deg, #00204d 34.31%, rgba(0, 32, 77, 0) 74.2%)',
            desktop: 'linear-gradient(90deg, #00204d 29.45%, transparent 60.35%)',
        },
    },
};
