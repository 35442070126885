<template>
    <SCardsProduct
        :title="getTitle"
        :lead="getLead"
        :link="getLink"
        :cards="getCards"
    />
</template>

<script>
import SCardsProduct from '@core/slices/pages/cards-product/cards-product.vue';

export default {
    name: 'SCardsProductList',

    components: {
        SCardsProduct,
    },

    props: {
        title: {
            type: String,
            default: undefined,
        },

        link: {
            type: Object,
            default: undefined,
        },

        lead: {
            type: String,
            default: undefined,
        },

        cards: {
            type: Array,
            default: undefined,
        },

        productId: {
            type: Number,
            default: undefined,
        },

        products: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        getCards() {
            return (
                this.cards ||
                this.products.map((id) => {
                    const params = this.$store.state.products.items?.[id]?.parameters?.common || {};
                    const { productName, learnLink = {}, card = {} } = params;
                    delete card.labelTarget;

                    return {
                        ...card,
                        title: `Acronis ${productName}`,
                        learnLink,
                    };
                })
            );
        },
        product() {
            return this.$store.state.products.items?.[this.productId]?.parameters?.common || {};
        },
        getTitle() {
            return this.title || `Acronis ${this.product.productName || ''}`;
        },
        getLead() {
            return this.lead || this.product.card?.text || '';
        },
        getLink() {
            if (this.link) {
                return this.link;
            }
            const to = this.product.learnLink?.to;
            const text = this.product.learnLink?.text || 'Learn more';

            if (!to) {
                return null;
            }

            return {
                to,
                text,
            };
        },
    },
};
</script>
