<template>
    <section class="s-contact-us">
        <div class="s-contact-us__inner">
            <div class="s-contact-us__sidebar">
                <a-picture
                    class="s-contact-us__bg"
                    :background="background"
                    is-background
                    fit="cover"
                    position="left top"
                />
                <div class="s-contact-us__sidebar-head">
                    <dynamic-link to="/partners/">
                        <a-logo class="s-contact-us__logo" type="full" fill="white" />
                    </dynamic-link>
                    <h1 class="s-contact-us__title">
                        {{ head.title }}
                    </h1>
                </div>
                <div
                    class="s-contact-us__language-selector"
                    :class="{ 's-contact-us__language-selector_active': languageSelectOpen }"
                >
                    <div class="s-contact-us__language-button" @click.prevent.stop="openLanguageSelect">
                        <a-glyph class="s-contact-us__language-glyph" name="language-select2" />
                    </div>
                    <div class="s-contact-us__language-dropdown">
                        <a-link
                            v-for="language in languages"
                            :key="language.name"
                            class="s-contact-us__language"
                            :class="{ 's-contact-us__language_active': isLanguageSelected(language) }"
                            :to="getLocaleLink(language)"
                            :text="language.name"
                        />
                    </div>
                </div>
                <div class="s-contact-us__sidebar-footer">
                    <div class="s-contact-us__copyright">
                        © {{ currentYear }} Acronis International GmbH
                    </div>
                    <div v-if="footer.links" class="s-contact-us__links">
                        <a-link
                            v-for="(item, i) in footer.links"
                            :key="i"
                            v-bind="item"
                            class="s-contact-us__link"
                            :size="item.size || 'caption'"
                        />
                    </div>
                </div>
            </div>
            <div v-if="form" class="s-contact-us__main">
                <div class="s-contact-us__main-inner">
                    <s-form-container :form="form" />
                </div>
            </div>
            <div class="s-contact-us__footer">
                <div class="s-contact-us__copyright">
                    © {{ currentYear }} Acronis International GmbH
                </div>
                <div v-if="footer.links" class="s-contact-us__links">
                    <a-link
                        v-for="(item, i) in footer.links"
                        :key="i"
                        v-bind="item"
                        class="s-contact-us__link"
                        :size="item.size || 'caption'"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AGlyph from '@core/components/glyph/glyph.vue';
import ALink from '@core/components/link/link.vue';
import ALogo from '@core/components/logo/logo.vue';
import APicture from '@core/components/picture/picture.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import form from '@core/mixins/form.js';
import SFormContainer from '@core/slices/pages/trial-form/components/form/form.vue';
import { LOCALE_DEFAULT } from '@model/const/locales.ts';
import languages from './languages.js';

export default {
    name: 'SContactUs',
    components: {
        DynamicLink,
        AGlyph,
        ALogo,
        ALink,
        APicture,
        SFormContainer,
    },
    mixins: [breakpoint, form],
    props: {
        background: {
            type: Object,
            required: true,
        },

        head: {
            type: Object,
            required: true,
        },

        footer: {
            type: Object,
            required: true,
        },

        form: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            languages,
            currentYear: new Date().getFullYear(),
            languageSelectOpen: false,
        };
    },

    methods: {
        openLanguageSelect() {
            this.languageSelectOpen = true;
            this.bindLanguageCloseClick();
        },

        closeLanguageSelect() {
            this.languageSelectOpen = false;
            this.unbindLanguageCloseClick();
        },

        bindLanguageCloseClick() {
            document.addEventListener('click', this.closeLanguageSelect);
        },

        unbindLanguageCloseClick() {
            document.removeEventListener('click', this.closeLanguageSelect);
        },

        getLocaleLink(language) {
            return this.$route
                ? this.$route.path.replace(/^\/[\w]{2}-[\w]{2}\//, `/${language.locale}/`)
                : LOCALE_DEFAULT;
        },

        isLanguageSelected(language) {
            return language.locale.match(new RegExp(`^${this.countryCode}-[\\w]{2}$`));
        },
    },

    serverPrefetch() {
        this.$store.dispatch('countries/getCountries');
    },
};
</script>

<style lang="postcss">
.el-select-dropdown {
    &.el-select-contact-us {
        .el-input__editor,
        .el-input__placeholder,
        .el-select-dropdown__item, {
            @mixin body;
        }
    }
}
</style>

<style lang="postcss" scoped>
.s-contact-us {
    display: flex;
    min-height: 100vh;

    @media (--viewport-tablet) {
        min-height: 100%;
        height: auto;
    }

    &__inner {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        width: 100%;

        @media (--viewport-tablet) {
            height: auto;
            flex-direction: row;
        }
    }

    &__sidebar {
        position: relative;
        background: var(--av-nav-primary);
        background-position: left top;
        display: flex;
        flex-direction: column;
        padding: 17px 28px 17px 25px;

        @media (--viewport-tablet) {
            height: 100%;
            width: 40%;
            padding: 15px 28px 24px 24px;
        }
    }

    &__bg {
        max-height: 826px;
        position: absolute;
        height: 100%;
        width: 100%;
        inset-inline-start: 0;
        top: 0;
        display: none;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 160px;
            inset-inline-start: 0;
            width: 100%;
            background: linear-gradient(180deg, rgba(0, 32, 77, 0) 0%, #00204d 100%);
        }

        @media (--viewport-tablet) {
            display: block;
        }
    }

    &__sidebar-head {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 5;
        color: var(--av-inversed-primary);

        @media (--viewport-tablet) {
            display: block;
            margin-bottom: 55px;
        }
    }

    &__sidebar-content {
        display: none;
        position: relative;
        z-index: 5;
        color: var(--av-inversed-light);
        max-width: 80%;
        margin: 0 auto;

        @media (--viewport-tablet) {
            display: block;
        }
    }

    &__sidebar-footer {
        @mixin caption;
        display: none;
        position: relative;
        z-index: 5;
        margin-top: auto;
        color: var(--av-inversed-light);

        @media (--viewport-tablet) {
            display: block;
        }

        &:deep(.a-link) {
            margin-inline-end: 8px;
            .a-link__content {
                color: var(--av-inversed-primary);
                &:hover,
                &:focus {
                    color: inherit;
                }
            }
        }
    }

    &__logo {
        width: 82px;
        height: 22px;
    }

    &__title {
        position: relative;
        top: 2px;
        margin-inline-start: 9px;
        display: block;
        font-size: 24px;
        line-height: 22px;
        font-weight: 300;
        letter-spacing: 0.3px;

        @media (--viewport-tablet) {
            top: 0;
            margin-inline-start: 0;
            line-height: 27px;
        }
    }

    &__list-item {
        @mixin body;

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 327px;
        margin: 0 auto 16px;
    }

    &__list-content {
        padding-inline-end: 30px;

        > a {
            white-space: nowrap;
        }
    }

    &__glyph {
        position: relative;
        top: 3px;
        margin-inline-end: 8px;
        fill: var(--av-inversed-light);
    }

    &__main {
        color: var(--av-nav-primary);
        background: var(--av-solid-brand-lightest);
        position: relative;
        height: 100%;
        flex: 1;
        max-height: 100%;
        overflow: auto;
        padding: 40px 16px;

        @media (--viewport-tablet) {
            padding: 40px 32px;
        }
    }

    &__main-inner {
        margin: 0 auto;
        height: 100%;
        padding: 0;
        display: flex;
        align-items: flex-start;
    }

    &__language-selector {
        position: absolute;
        z-index: 10;
        top: 12px;
        inset-inline-end: 12px;

        &_active {
            .s-contact-us {
                &__language-button {
                    background: var(--av-inversed-invisible);
                }

                &__language-dropdown {
                    display: block;
                }
            }
        }

        @media (--viewport-tablet) {
            top: 16px;
            inset-inline-end: 20px;
        }
    }

    &__language-button {
        height: 32px;
        width: 32px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background: var(--av-inversed-lighter);
        }
    }

    &__language-glyph {
        fill: var(--av-inversed-primary);
    }

    &__language-dropdown {
        display: none;
        position: absolute;
        inset-inline-end: 0;
        top: 40px;
        width: 120px;
        padding: 8px 0;
        background: var(--av-inversed-primary);
        border: 1px solid var(--av-brand-primary);
        box-shadow: 0 10px 20px rgba(36, 49, 67, 0.2);
        border-radius: 4px;
    }

    &__language {
        padding: 4px 10px;
        text-align: center;
        cursor: pointer;
        display: block;
        text-decoration: none;
        color: var(--av-fixed-primary);

        &:hover {
            background: var(--av-brand-accent);
        }

        &_active {
            font-weight: 600;
        }
    }

    &__footer {
        @mixin caption;

        border-top: 1px solid var(--av-brand-accent);
        padding: 24px 0 24px;
        text-align: center;
        background: var(--av-solid-brand-lightest);
        color: var(--av-fixed-light);

        @media (--viewport-tablet) {
            display: none;
        }

        &:deep(.a-link) {
            margin-inline-end: 8px;

            &__content {
                color: var(--av-brand-primary);
            }
        }
    }

    &:deep(.s-form-container) {
        width: 100%;
        display: flex;
        margin: auto;
        justify-content: center;

        .s-form-container__loading {
            top: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            background: var(--av-solid-brand-lightest);
        }

        .s-form-error {
            padding: 0;
            border: none;
            box-shadow: none;
            background: transparent;
        }

        .s-form-success {
            padding: 0;
            border: none;
            box-shadow: none;
            background: transparent;

            .title {
                margin: 0;
                font-weight: 400;
            }

            .description {
                @mixin body;
                padding: 16px;
                margin: 24px 0;
                border-radius: 4px;
                text-align: center;
                background: var(--av-fixed-success-accent);
            }
        }

        .s-form-registration {
            padding: 0;
            border: none;
            box-shadow: none;
            background: transparent;

            @media (--viewport-desktop) {
                min-width: 510px;
            }

            .s-form-registration__title {
                font-weight: 400;
                margin-bottom: 32px;
            }

            .s-form-registration__loading {
                background: var(--av-solid-brand-lightest);
            }

            .el-form {
                .el-form-item__error-holder {
                    min-height: 56px;
                }

                .el-input {
                    .el-input__container {
                        height: 32px;
                        .el-input__wrapper {
                            padding: 0;
                        }
                    }
                    .el-input__label {
                        @mixin body;
                        padding: 0;
                        cursor: default;
                        margin-top: -30px;
                        background: transparent;
                        margin-inline-start: -8px;

                        &.is-active {
                            font-weight: 400;
                        }
                    }
                    .el-input__editor {
                        @mixin body;
                        height: 100%;
                    }
                    .el-input__placeholder {
                        @mixin body;
                        height: 100%;
                    }
                }

                .el-textarea {
                    .el-textarea__label {
                        @mixin body;
                        padding: 0 8px;
                        cursor: default;
                        margin-top: -30px;
                        background: transparent;
                        margin-inline-start: -8px;

                        &.is-active {
                            font-weight: 400;
                        }
                    }
                    .el-textarea__editor {
                        height: 96px!important;
                        min-height: 96px!important;
                    }
                }
            }
        }
    }
}
</style>
