import { render, staticRenderFns } from "./label.vue?vue&type=template&id=a9efcccc&scoped=true"
import script from "./label.vue?vue&type=script&lang=ts"
export * from "./label.vue?vue&type=script&lang=ts"
import style0 from "./label.vue?vue&type=style&index=0&id=a9efcccc&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9efcccc",
  null
  
)

export default component.exports